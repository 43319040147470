import React, { CSSProperties, FC } from "react";
import { Button, Card } from "react-bootstrap";
import { ChainPropertySaleType } from "../../../../../../../api/modules/property";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    thisProperty: boolean;
    displayPosition: number;
    title?: string;
    index: number;
    chainLength: number;
    saleType: ChainPropertySaleType;
    toggleChainItemCollapse?: () => void;
    addAbove: () => void;
    addBelow: () => void;
}

const ChainHeader: FC<IProps> = ({
    toggleChainItemCollapse,
    index,
    displayPosition,
    thisProperty,
    title,
    chainLength,
    saleType,
    addAbove,
    addBelow
}) => {
    let saleTypeLabel: string | undefined = undefined;
    const headerStyle: CSSProperties = { cursor: "pointer" };
    let headerClasses = "";
    let headerTitleClasses = "mb-0 d-flex justify-content-between";
    const btnVariant = thisProperty === true ? "light" : "primary";

    if (thisProperty === true) {
        headerStyle.padding = "2rem 1.25rem";
        headerClasses = "bg-primary";
        headerTitleClasses += " text-white";
    } else {
        headerTitleClasses += " text-primary";
    }

    if (index === chainLength - 1) {
        if (saleType === ChainPropertySaleType.CashBuyer) {
            saleTypeLabel = "Cash buyer";
        } else if (saleType === ChainPropertySaleType.FirstTimeBuyer) {
            saleTypeLabel = "First time buyer";
        } else if (saleType === ChainPropertySaleType.Investor) {
            saleTypeLabel = "Investor";
        }
    }

    return (
        <Card.Header
            className={headerClasses}
            style={headerStyle}
            onClick={toggleChainItemCollapse}
        >
            <Card.Title className={headerTitleClasses}>
                Position {displayPosition}{" "}
                {saleTypeLabel !== undefined
                    ? ` - ${saleTypeLabel}`
                    : title && ` - ${title}`}
                <div className="mb-2 d-flex flex-direction-row justify-content-end">
                    <Button variant={btnVariant} size={"sm"} className="mr-1" onClick={
                        (e: any) => {e.stopPropagation(); addAbove()}
                    }>
                        <FontAwesomeIcon icon={faArrowUp} className="mr-1"/>
                        Add link above
                    </Button>
                    {
                        displayPosition !== 1 && (
                            <Button variant={btnVariant}  size={"sm"} onClick={
                                (e: any) => {e.stopPropagation(); addBelow()}
                            }>
                                <FontAwesomeIcon icon={faArrowDown} className="mr-1"/>
                                Add link below
                            </Button>
                        )
                    }
                </div>
            </Card.Title>
        </Card.Header>
    );
};

export default ChainHeader;
