import React, { Component, FormEvent } from "react";
import { Modal, Button } from "react-bootstrap";
import { AsyncActionState } from "../../../redux/utils/asyncAction";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Form } from "react-bootstrap";
import { ButtonVariant } from "../../../lib/bootstrap/types";

interface IActionPromptAction {
    title: string;
    onClick: () => void;
    btnVariant?: ButtonVariant;
    asyncStatus?: AsyncActionState;
    useConfirm?: boolean;
    onSuccess?: () => void;
    onError?: () => void;
}

interface IProps extends RouteComponentProps {
    title: string;
    bodyText: string;
    show: boolean;
    cancel: () => void;
    confirmText?: string;
    confirmTextLabel?: string;
    cancelButtonText?: string;
    testid?: string;
    actions: IActionPromptAction[];
}
interface IState {
    confirmTextInput: string;
}

class ActionPrompt extends Component<IProps, IState> {
    state: IState = {
        confirmTextInput: ""
    };

    componentDidUpdate(prevProps: IProps) {
        const { actions } = this.props;

        if (prevProps.show === false && this.props.show === true) {
            this.setState({
                confirmTextInput: ""
            });
        }
        actions.forEach((action, index) => {
            const prevAction = prevProps.actions[index];
            if (
                prevAction.asyncStatus === AsyncActionState.Pending &&
                action.asyncStatus !== AsyncActionState.Pending
            ) {
                if (action.asyncStatus === AsyncActionState.Success) {
                    if (action.onSuccess !== undefined) {
                        action.onSuccess();
                    }
                } else if (action.asyncStatus === AsyncActionState.Error) {
                    if (action.onError !== undefined) {
                        action.onError();
                    }
                }
            }
        });
    }

    updateConfirmTextInput = (event: FormEvent<any>) => {
        this.setState({
            confirmTextInput: event.currentTarget.value as string
        });
    };

    confirmInputOk = () => {
        const { confirmText } = this.props;
        const { confirmTextInput } = this.state;
        if (confirmText === undefined || confirmText === "") {
            return true;
        } else if (confirmText === confirmTextInput) {
            return false;
        }
        return true;
    };

    render() {
        const {
            show,
            cancel,
            title,
            bodyText,
            cancelButtonText = "Cancel",
            confirmText,
            confirmTextLabel,
            actions,
            testid
        } = this.props;
        const { confirmTextInput } = this.state;
        return (
            <Modal show={show} onHide={cancel} centered backdrop="static" data-testid={testid}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p style={{ whiteSpace: "pre-line" }}>{bodyText}</p>

                    {confirmText !== undefined && confirmText !== "" && (
                        <>
                            <Form.Label className="font-weight-bold my-2">
                                {confirmTextLabel}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={confirmTextInput}
                                onChange={this.updateConfirmTextInput}
                            />
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="outline-dark" onClick={cancel}>
                        {cancelButtonText}
                    </Button>
                    {actions.map((action, index) => (
                        <Button
                            key={`btn-${index}`}
                            variant={action.btnVariant}
                            onClick={action.onClick}
                            disabled={
                                action.asyncStatus ===
                                    AsyncActionState.Pending ||
                                (action.useConfirm === true &&
                                    this.confirmInputOk() === true)
                            }
                        >
                            {action.title}
                        </Button>
                    ))}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(ActionPrompt);
