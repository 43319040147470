import React, { FC, useState, useEffect } from "react";
import { Button, Form, Modal, ModalProps } from "react-bootstrap";
import { ButtonVariant } from "../../../lib/bootstrap/types";
import usePrevious from "../../../lib/utils/hooks/usePrevious";
import { AsyncActionState } from "../../../redux/utils/asyncAction";

type MProps = Pick<ModalProps, "onShow">

interface IProps extends MProps {
    show: boolean;
    rows?: number;
    title: string;
    asyncStatus: AsyncActionState;
    autoCloseOnSuccess?: boolean;
    initialValue?: string;
    onSubmit: (newValue: string) => void;
    close: () => void;
}

const ModalTextInput:FC<IProps> = ({show,  rows, asyncStatus, onShow, title, close,onSubmit, autoCloseOnSuccess, initialValue}) => {
    
    const [newValue, setNewValue] = useState("");
    const submit = onSubmit.bind(undefined, newValue);
    const prevStatus = usePrevious(asyncStatus);
    const buttonDisabled =
    newValue.length <= 0 || asyncStatus === AsyncActionState.Pending;
    const buttonVariant: ButtonVariant =
        asyncStatus === AsyncActionState.Error ? "danger" : "primary";
    const getSubmitButtonText = (asyncStatus: AsyncActionState) => {
        if (asyncStatus === AsyncActionState.Pending) {
            return "Please wait...";
        }
        if (asyncStatus === AsyncActionState.Error) {
            return "Error, please try again.";
        }
        return "Save";
    }

    useEffect(() => {
        if (initialValue !== undefined) {
            setNewValue(initialValue);
        }
    }, [initialValue]);
    
    useEffect(() => {
        if (
            autoCloseOnSuccess === true && 
            (prevStatus === AsyncActionState.Pending &&
            asyncStatus === AsyncActionState.Success)
        ) {
            setNewValue("");
            close();
        }
    }, [close, newValue, asyncStatus, prevStatus, autoCloseOnSuccess]);

    return (
        <Modal
            show={show}
            onShow={onShow}
            onHide={close}
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Control
                    as="textarea"
                    rows={rows}
                    value={newValue}
                    // Auto focus is buggy inside the modal, so using ref to achieve it
                    ref={(node:any) => setTimeout(() => {node?.focus()}, 200)}
                    onChange={(e) => setNewValue(e.currentTarget.value)}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant={buttonVariant}
                    onClick={submit}
                    disabled={buttonDisabled}
                >
                    {getSubmitButtonText(asyncStatus)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalTextInput.defaultProps = {
    rows: 10,
    autoCloseOnSuccess: true
}

export default ModalTextInput;