import React, { Component } from "react";
import "./lib/bootstrap/themes/gya/bootstrap.min.css";
import "./lib/bootstrap/themes/gya/gya_custom.css";
import "./lib/bootstrap/themes/gya/check_radio.scss";
import NavigationRouter from "./navigation";
import { Provider } from "react-redux";
import reduxStore from "./redux/store";
import { parseCookies, setCookie } from "./lib/utils/cookies";
import auth from "./api/modules/auth";
import { setAuthenticateResponse } from "./redux/acreators/auth";
import { getMe } from "./redux/api/user";
import { PersistGate } from "redux-persist/integration/react";

const { store, persistor } = reduxStore();

interface IProps {}
interface IState {
    loadingFinished: boolean;
}

class App extends Component<IProps, IState> {
    state: IState = {
        loadingFinished: false
    };
    cookieCheckInterval?: NodeJS.Timeout;
    cookieCheckIntervalTime = 30000;

    async componentDidMount() {
        this.handleLaunchingWithAuthCookie();

        await getMe()(store.dispatch, store.getState);

        this.cookieCheckInterval = setInterval(
            this.performCookieCheck,
            this.cookieCheckIntervalTime
        );
    }

    componentWillUnmount() {
        if (this.cookieCheckInterval !== undefined) {
            clearInterval(this.cookieCheckInterval);
        }
    }

    handleLaunchingWithAuthCookie = () => {
        const cookies = parseCookies();

        if (process.env.REACT_APP_LOGOUT_REDIRECT_URL === undefined) {
            throw new Error(
                "Required REACT_APP_LOGOUT_REDIRECT_URL environment variable."
            );
        }

        this.performCookieCheck();

        try {
            const decodedAuthCookie = auth.decodeSessionInfoCookie(
                cookies.sessionInfo
            );
            store.dispatch(setAuthenticateResponse(decodedAuthCookie));
        } catch (e) {}
    };

    performCookieCheck = () => {
        const cookies = parseCookies();
        if (
            cookies.hasOwnProperty("sessionInfo") === false &&
            cookies.sessionInfo !== ""
        ) {
            persistor.purge();
            setCookie("sessionInfo", undefined);
            window.location.href = process.env
                .REACT_APP_LOGOUT_REDIRECT_URL as string;
        }
    };

    render() {
        const { loadingFinished } = this.state;
        return (
            <Provider store={store}>
                <PersistGate loading={!loadingFinished} persistor={persistor}>
                    <NavigationRouter />
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
