import React from "react";
import { Form, InputGroup, Button, Spinner } from "react-bootstrap";
import "./styles.scss";

interface IProps {
    email: string;
    updateEmail: (email: string) => void;
    send: () => void;
    sendPending: boolean;
}

export default function SendMiPdfForm(props: IProps) {
    return (
        <Form>
            <Form.Group>
                <InputGroup className="mb-3">
                    <Form.Control
                        type="email"
                        placeholder="Enter your email to have this sent to your inbox"
                        onChange={(e: any) => {
                            props.updateEmail(e.currentTarget.value as string);
                        }}
                        required
                        value={props.email}
                    />
                    <InputGroup.Append>
                        <Button
                            disabled={
                                props.email === undefined ||
                                props.email.length < 3
                            }
                            variant="primary"
                            type="button"
                            onClick={props.send}
                        >
                            {props.sendPending === true ? (
                                <Spinner animation="border" size="sm" />
                            ) : (
                                "Send"
                            )}
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Form.Group>
        </Form>
    );
}
