import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import property from "../../../../../../api/modules/property";
import {default as companyApi} from "../../../../../../api/modules/company";
import {
    IBranch,
    IPagingParams,
    IProperty,
    IPropertyGetListFilters,
    IPropertyTimelineTemplate, PropertySortOptionType
} from "../../../../../../api/_types";
import { downloadFileToClient } from "../../../../../../lib/utils/files";
import sessionStorage from "../../../../../../lib/utils/sessionStorage";
import routes, { IRouteDefinition } from "../../../../../../navigation/routes";
import { getBranchById } from "../../../../../../redux/api/branch";
import { getCompanyProperties } from "../../../../../../redux/api/company";
import {
    getProperties,
    getPropertyById,
    resetViewingProperty
} from "../../../../../../redux/api/property";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import PropertyListHeaderOptions from "../../components/PropertyListHeaderOptions";
import PropertyListOtherWarningModal from "../../components/PropertyListOtherWarningModal";
import PropertyListWarningModal from "../../components/PropertyListWarningModal";
import PropertyTable from "../../components/PropertyTable";
import { buildFilterObj, IPropertyFilterKeyVal } from "./filters";

interface IState {
    searchText: string;
    filter: IPropertyFilterKeyVal[];
    filterBranches: string[];
    itemsPerPage: number;
    sortOption: PropertySortOptionType;
    viewWarningProperty?: IProperty;
    viewOtherWarningProperty?: IProperty;
}

const mapStateToProps = ({ company, branch, property }: IStore) => ({
    company: company.viewingCompany ? company.viewingCompany._id : "",
    branchId: branch.viewingBranch ? branch.viewingBranch._id : "",
    branches: branch.branches.branches,
    properties: property.properties.properties,
    propertiesPaging: property.properties.paging,
    getPropertiesStatus: property.getPropertiesStatus,
    getCompanyPropertiesStatus: company.getCompanyPropertiesStatus,
    companyProperties: company.properties.properties,
    companyPropertiesPaging: company.properties.paging,
    branchTimelineTemplate: branch.viewingBranch?.timelineTemplate as IPropertyTimelineTemplate
});

const connector = connect(
    mapStateToProps,
    {
        getProperties,
        getPropertyById,
        resetViewingProperty,
        getCompanyProperties,
        getBranchById
    }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {
    type: "company" | "branch";
    companyId: string;
    solicitorCompanyId?: string;
    hideTopControls?: boolean;
    noPropertyDisplay?: React.ReactElement
}

class PropertyListContainer extends Component<
    IProps & RouteComponentProps,
    IState
> {
    state: IState;

    constructor(props: IProps & RouteComponentProps) {
        super(props);

        // Load cached settings for the property query, e.g. filters
        const {
            existingFilter,
            existingItemsPerPage,
            existingSortOption
        } = this.loadCachedSearchParams();

        this.state = {
            searchText: "",
            filter: existingFilter || [{ key: "active", value: "1" }, {key: "complete", value: "0"}],
            filterBranches: [],
            itemsPerPage: existingItemsPerPage || 10,
            sortOption: existingSortOption || "default",
            viewWarningProperty: undefined
        };
    }
    componentDidMount() {
        this.getProperties();
    }

    loadCachedSearchParams() {
        let existingFilter: any;
        let existingItemsPerPage: any;
        let existingSortOption: any;

        try {
            existingFilter = sessionStorage.getItem("properties-filter");
            existingItemsPerPage = sessionStorage.getItem("properties-ipp");
            existingSortOption = sessionStorage.getItem("properties-sort");
        } catch (e) {}

        if (
            existingItemsPerPage !== undefined &&
            existingItemsPerPage !== null
        ) {
            existingItemsPerPage = parseInt(existingItemsPerPage, 10);
        }

        return { existingFilter, existingItemsPerPage, existingSortOption };
    }

    getProperties = (
        paging: IPagingParams = {
            currentPage: 0,
            itemsPerPage: this.state.itemsPerPage
        }
    ) => {
        const { getCompanyProperties, getProperties, type, solicitorCompanyId } = this.props;
        const { searchText, filter, filterBranches, sortOption } = this.state;

        const filters = buildFilterObj<IPropertyGetListFilters>(filter);

        if (type === "company") {
            getCompanyProperties(
                paging,
                searchText,
                filters,
                filterBranches,
                sortOption,
                solicitorCompanyId
            );
        } else {
            getProperties(paging, searchText, filters, sortOption);
        }
    };

    getPropertiesCSV = async () => {
        const { type, solicitorCompanyId, company, branchId } = this.props;
        const { searchText, filter, filterBranches, sortOption } = this.state;

        const filters = buildFilterObj<IPropertyGetListFilters>(filter);

        
        try {
            let res;
            if (type === "company") {
                res = await companyApi.getCompanyPropertiesCsv(company!, {} as IPagingParams, searchText, filters, filterBranches, sortOption, solicitorCompanyId);
            } else {
                res = await property.getPropertiesCsv(branchId!, company!, {} as IPagingParams, searchText, filters, sortOption)
            }
            downloadFileToClient(res.data, "Properties.csv");
        } catch (e) {
            alert("Error downloading CSV.");
        }
    };

    updateSearchText = (searchText: string) => {
        this.setState({
            searchText
        });
    };

    updateSortOption = (sortOption: PropertySortOptionType) => {
        // The filter gets set in session storage, so that it's remembered
        sessionStorage.setItem("properties-sort", sortOption);

        this.setState(
            {
                sortOption
            },
            this.getProperties
        );
    };

    updateItemsPerPage = (itemsPerPage: number) => {
        // The items per page gets set in session storage, so that it's remembered
        sessionStorage.setItem("properties-ipp", itemsPerPage);
        this.setState({ itemsPerPage }, this.getProperties);
    };

    updateFilterBranches = (branches: IBranch[] | null) => {
        this.setState(
            {
                filterBranches:
                    branches !== null ? branches.map((b: any) => b.value) : []
            },
            this.getProperties
        );
    };

    updatePropertyFilter = (filter: IPropertyFilterKeyVal[]) => {
        // The filter gets set in session storage, so that it's remembered
        sessionStorage.setItem("properties-filter", filter);

        this.setState(
            {
                filter
            },
            this.getProperties
        );
    };

    navigateToPage = (
        propertyId: string,
        branchId: string,
        route: IRouteDefinition
    ) => {
        const {
            type,
            resetViewingProperty,
            getPropertyById,
            history
        } = this.props;

        resetViewingProperty();
        if (type === "company") {
            getPropertyById(propertyId, branchId);
        } else {
            getPropertyById(propertyId);
        }
        history.push(route.url);
    };

    handlePageProperties = (desiredPage: number) => {
        const { propertiesPaging } = this.props;

        this.getProperties({
            currentPage: desiredPage,
            itemsPerPage: propertiesPaging.itemsPerPage
        });
    };

    goToBranch = (branch: IBranch) => {
        const { getBranchById, companyId, history } = this.props;
        getBranchById(branch._id as string, companyId);
        history.push(routes.propertyManage.url);
    };

    closeWarningModal = () => this.setState({viewWarningProperty: undefined})
    
    closeOtherWarningModal = () => this.setState({viewOtherWarningProperty: undefined})

    navigateToTimeline= () => 
        this.navigateToPage(
            this.state.viewWarningProperty?._id as string,
            this.state.viewWarningProperty?.branch as string,
            routes.propertyTimeline
        );

    render() {
        const {
            branches,
            properties,
            propertiesPaging,
            companyProperties,
            companyPropertiesPaging,
            getPropertiesStatus,
            getCompanyPropertiesStatus,
            type,
            hideTopControls = false,
            noPropertyDisplay
        } = this.props;
        const { filter, sortOption } = this.state;
        let getAsyncStatus = getPropertiesStatus;
        let paging = propertiesPaging;
        let propertyData = properties || [];

        if (type === "company") {
            paging = companyPropertiesPaging;
            propertyData = companyProperties;
            getAsyncStatus = getCompanyPropertiesStatus;
        }

        return (
            <LoadingContainer
                loading={getAsyncStatus === AsyncActionState.Pending}
            >
                {hideTopControls === false && (
                    <PropertyListHeaderOptions
                        type={type}
                        activeFilter={filter}
                        activeSortOption={sortOption}
                        updatePropertyFilter={this.updatePropertyFilter}
                        updateFilterBranches={this.updateFilterBranches}
                        updateSearchText={this.updateSearchText}
                        branches={branches}
                        getProperties={this.getProperties}
                        updatePropertySort={this.updateSortOption}
                        getPropertiesCSV={this.getPropertiesCSV}
                        numProperties={paging.count}
                    />
                )}

                {propertyData?.length <= 0 && noPropertyDisplay !== undefined ? noPropertyDisplay : (
                    <PropertyTable
                       type={type}
                        paging={paging}
                        onPagerClick={this.handlePageProperties}
                        updateItemsPerPage={this.updateItemsPerPage}
                        properties={propertyData}
                        getAsyncStatus={getAsyncStatus}
                        goToBranch={this.goToBranch}
                        navigateToPage={this.navigateToPage}
                        setViewWarningProperty={(item:any) => {
                            this.setState({
                                viewWarningProperty: item
                            })
                        }}
                        setViewOtherWarningProperty={(item:any) => {
                            this.setState({
                                viewOtherWarningProperty: item
                            })
                        }}
                    />
                )}

                <PropertyListWarningModal 
                    show={this.state.viewWarningProperty !== undefined} 
                    property={this.state.viewWarningProperty} 
                    branchTimelineTemplate={this.props.branchTimelineTemplate} 
                    close={this.closeWarningModal} 
                    navigateToTimeline={this.navigateToTimeline}
                />

                {this.state.viewOtherWarningProperty && (
                    <PropertyListOtherWarningModal
                        show={this.state.viewOtherWarningProperty !== undefined} 
                        property={this.state.viewOtherWarningProperty} 
                        close={this.closeOtherWarningModal} 
                    />
                )}
            </LoadingContainer>
        );
    }
}

export default connector(withRouter<any, any>(PropertyListContainer));