import React from "react";
import { IPagingResponse, IUser } from "../../../../../../api/_types";
import DataTable from "../../../../../_sharedComponents/DataTable";

interface IProps {
    users: IUser[];
    paging: IPagingResponse;
    pageUsers: (desiredIndex: number) => void;
}

export default function GYAAdminList(props: IProps) {
    const { users, paging, pageUsers } = props;
    return (
        <DataTable
            data={users}
            fields={[
                {
                    field: "",
                    displayName: "Name",
                    mutator: (item: IUser) =>
                        `${item.firstName} ${item.lastName}`
                },
                {
                    field: "email",
                    displayName: "Email"
                }
            ]}
            showPager={true}
            pagingElement={paging}
            onPagerPageClick={pageUsers}
        />
    );
}
