import moment, { Moment } from "moment";
import React from "react";
import { Button, Form } from "react-bootstrap";
import {
    ICompanyContactPOC,
    IPropertyTimelineEntity
} from "../../../../../../../../api/_types";
import { IPropertyTimelineEntityState } from "../../../../../../../../redux/_types/property/types";
import DatePicker from "../../../../../../../_sharedComponents/DatePicker";
import PopulatedContact from "../../../../../contacts/components/PopulatedContact";
import ContactCard from "../../../../../contacts/containers/ContactCard";
import { IAddSurveyAction } from "../AddSurvey.reducer";

interface IProps {
    survey: IPropertyTimelineEntity;
    surveyStateDispatch: (params: IAddSurveyAction) => void;
}

export default function SurveyFields(props: IProps) {
    const { survey, surveyStateDispatch } = props;
    const surveyComplete =
        survey.state === IPropertyTimelineEntityState.COMPLETE;
    return (
        <>
            <Form.Group>
                <label>Date the survey will take place</label>
                <DatePicker
                    inputProps={{
                        title: "Survey date input",
                        disabled:
                            surveyComplete === true ||
                            survey.surveyData.awaitingReports === true
                    }}
                    timeFormat={false}
                    onChange={(e: string | Moment) => {
                        let momentTime = typeof e === "string" ? moment(e) : e;

                        surveyStateDispatch({
                            type: "updateDate",
                            payload: momentTime.toISOString()
                        });
                    }}
                    defaultValue={
                        survey.surveyData.date !== undefined
                            ? moment(survey.surveyData.date)
                            : undefined
                    }
                />
            </Form.Group>

            <Form.Group data-testid="survey-surveyor-form-group">
                {survey.surveyData.contactPOC !== undefined && (
                    <>
                        <label>
                            Who is performing this survey?
                            <Button
                                variant="link"
                                onClick={() =>
                                    surveyStateDispatch({
                                        type: "updateContact",
                                        payload: undefined
                                    })
                                }
                                disabled={surveyComplete === true}
                            >
                                clear
                            </Button>
                        </label>

                        <PopulatedContact
                            selectedPOC={
                                survey.surveyData
                                    .contactPOC as ICompanyContactPOC
                            }
                        />
                    </>
                )}
                {survey.surveyData.contactPOC === undefined && (
                    <>
                        <label>
                            Who is performing this survey{" "}
                            <small className="form-text d-inline">
                                (optional)
                            </small>
                        </label>

                        <ContactCard
                            onSelect={(contactSet) => {
                                surveyStateDispatch({
                                    type: "updateContact",
                                    payload: contactSet.poc as ICompanyContactPOC
                                });
                            }}
                            existingPOC={undefined}
                        />
                    </>
                )}
            </Form.Group>

            <Form.Group>
                <label>
                    Notes{" "}
                    <small className="form-text d-inline">(optional)</small>
                </label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    data-testid="add-survey-ref-input"
                    defaultValue={survey.surveyData.reference}
                    disabled={surveyComplete === true}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        surveyStateDispatch({
                            type: "updateRef",
                            payload: e.currentTarget.value
                        })
                    }
                />
            </Form.Group>
        </>
    );
}
