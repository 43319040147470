import React from "react";
import {
    ICreatePropertyNoteFields,
    IPropertyNote
} from "../../../../../../api/_types";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ModalTextInput from "../../../../../_sharedComponents/ModalTextInput";

type SaveNoteFunction = (note: IPropertyNote) => void;
type UpdateNoteFunction = (note: ICreatePropertyNoteFields) => void;

interface IProps {
    title: string;
    show: boolean;
    noteToEdit?: IPropertyNote;
    asyncStatus: AsyncActionState;
    timelineKey?: number;
    saveNote: SaveNoteFunction | UpdateNoteFunction;
    close: () => void;
}

export default function ManageNote(props: IProps) {
    const {
        show,
        close,
        saveNote,
        asyncStatus,
        title,
        noteToEdit,
        timelineKey
    } = props;
    // This handles saving both a new note, and existing note
    const save = (newNote: string) =>
        saveNote(
            Object.assign({}, noteToEdit, { message: newNote, timelineKey })
        );

    return (
        <ModalTextInput
            title={title}
            show={show}
            asyncStatus={asyncStatus}
            close={close}
            onSubmit={save}
            initialValue={noteToEdit?.message}
        />
    );
}
