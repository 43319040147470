import React, { Component } from "react";
import { connect } from "react-redux";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import { IStore } from "../../../../../../redux/store";
import { IProperty } from "../../../../../../api/_types";
import CreateNote from "../CreateNote";
import { Button } from "react-bootstrap";
import {
    faPlusSquare,
    faArrowLeft,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter, RouteComponentProps } from "react-router-dom";
import routes from "../../../../../../navigation/routes";
import _ from "lodash";

interface IProps {
    property?: IProperty;
}

interface IState {
    showCreateNote: boolean;
}

class PropertyTitle extends Component<IProps & RouteComponentProps, IState> {
    state: IState = {
        showCreateNote: false
    };
    backToBranch = () => {
        this.props.history.push(routes.propertyManage.url);
    };
    backToWorkflow = () => {
        this.props.history.push(routes.branchMIReport.url);
    };

    showNoteModal = () => {
        this.setState({ showCreateNote: true });
    };
    closeNoteModal = () => {
        this.setState({ showCreateNote: false });
    };

    render() {
        const { showCreateNote } = this.state;
        const { property } = this.props;
        if (property === undefined) {
            return null;
        }

        const truncatedLine1 = _.truncate(property.address.line1, {
            length: 25
        });
        const addressString = `${truncatedLine1}, ${property.address.postcode}`;

        return (
            <>
                <EntityTitle
                    entityName={addressString}
                    content={
                        <>
                            <TitleAction
                                title="Go to branch"
                                onPress={this.backToBranch}
                                icon={faArrowLeft}
                            />
                            <TitleAction
                                title="Go to workflow"
                                onPress={this.backToWorkflow}
                                icon={faArrowLeft}
                            />
                            <TitleAction
                                title="Add a note"
                                onPress={this.showNoteModal}
                                icon={faPlusSquare}
                            />
                        </>
                    }
                />

                <CreateNote show={showCreateNote} close={this.closeNoteModal} />
            </>
        );
    }
}

interface ITitleActionProps {
    onPress: () => void;
    title: string;
    icon: IconDefinition;
}
function TitleAction(props: ITitleActionProps) {
    const { onPress, title, icon } = props;
    return (
        <Button
            variant="link"
            className="ml-2 p-0"
            onClick={onPress}
            style={{ textDecoration: "none" }}
        >
            <FontAwesomeIcon icon={icon} />
            <span className="ml-1">{title}</span>
        </Button>
    );
}

const mapStateToProps = ({ property }: IStore) => ({
    property: property.viewingProperty
});

export default connect(
    mapStateToProps,
    {}
)(withRouter<any, any>(PropertyTitle));
