import React from "react";
import { CardDeck, Card } from "react-bootstrap";
import { IMIKeyStatsResponse } from "../../../../../../../api/_types";

interface IProps {
    stats: IMIKeyStatsResponse;
}

export default function InfoDeck(props: IProps) {
    const {
        count,
        agencyFeeSum,
        agencyFeePercentAvg,
        proceedablePercent,
        unknownPercent,
        notProceedablePercent
    } = props.stats;

    return (
        <CardDeck>
            <Card className="align-items-center justify-content-center pt-3 text-center ">
                <h5>Properties</h5>
                <p>{count.toLocaleString()}</p>
            </Card>
            <Card className="align-items-center justify-content-center pt-3 text-center ">

                <h5>Average Fee <br/><small>EXC deductions</small></h5>
                <p>{agencyFeePercentAvg?.toFixed(2) || 0} %</p>

            </Card>
            <Card className="align-items-center justify-content-center pt-3 text-center ">
                <h5>Fees expected <br/><small>INC deductions</small></h5>
                <p>£{agencyFeeSum.toLocaleString()}</p>
            </Card>
            <Card className="align-items-center justify-content-center pt-3 text-center     ">
                <h5>Chain Status </h5>
                <p className="text-success">
                    Proceedable: {(proceedablePercent || 0).toFixed(2)}%
                </p>
                <p className="text-danger">
                    Not procedable: {(notProceedablePercent || 0).toFixed(2)}
                    %
                </p>
                <p className="text-warning">
                    Unknown: {(unknownPercent || 0).toFixed(2)}%
                </p>
            </Card>
        </CardDeck>
    );
}
