import React, { Component } from "react";
import { connect } from "react-redux";
import {
    IPropertyOffer,
    IPropertyOfferPayload
} from "../../../../../../api/_types";
import {
    resetUpdatePropertyOfferStatus,
    setPropertyOfferToEdit,
    updatePropertyOffer
} from "../../../../../../redux/api/property";
import { selectPropertyOffers } from "../../../../../../redux/api/property/selectors/property";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import HorizontalTabNabSubPage from "../../../../../_sharedComponents/HorizontalTabNavSubPage";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import CreateOfferForm from "../../components/CreateOfferForm";
import { canAcceptOffer } from "../../../../../../lib/core/offers";
import OfferHistoryList from "../../components/OfferHistoryList";

interface IProps {
    offer?: IPropertyOffer;
    offers: IPropertyOffer[];
    updatePropertyOfferStatus: AsyncActionState;
    updatePropertyOffer: (offer: IPropertyOfferPayload) => void;
    resetUpdatePropertyOfferStatus: () => void;
    setPropertyOfferToEdit: (offer: IPropertyOffer) => void;
}
interface IState {
    offer: IPropertyOfferPayload;
}

class EditOfferFormContainer extends Component<IProps, IState> {
    generateStateOffer = () => {
        return Object.assign({}, this.props.offer, {
            amendAmount: undefined,
            amendReason: undefined,
            mip: this.props.offer && this.props.offer.mip === true ? "1" : "0",
            cashBuy:
                this.props.offer && this.props.offer.cashBuy === true
                    ? "1"
                    : "0"
        });
    };

    state: IState = {
        offer: this.generateStateOffer()
    };

    componentDidUpdate(prevProps: IProps) {
        // Reset form state when a offer has been edited
        if (
            prevProps.updatePropertyOfferStatus !== AsyncActionState.Success &&
            this.props.updatePropertyOfferStatus === AsyncActionState.Success
        ) {
            this.setState({
                offer: this.generateStateOffer()
            });
            this.handleGoBack();
        }

        // If cash buy is selected, reset deposit size to 0.
        if (
            (this.state.offer.cashBuy as any) === "1" &&
            this.state.offer.depositSize > 0
        ) {
            this.setState({
                offer: Object.assign({}, this.state.offer, {
                    depositSize: 0
                })
            });
        }
    }

    componentWillUnmount() {
        this.props.resetUpdatePropertyOfferStatus();
    }

    handleGoBack = () => {
        this.props.setPropertyOfferToEdit(undefined as any);
    };

    handleSave = () => {
        const { offer } = this.state;
        const offerPayload = Object.assign({}, offer);

        if (typeof offerPayload.chain === "string") {
            if (offerPayload.chain === "0" || offerPayload.chain === "") {
                offerPayload.chain = false;
            } else if (offerPayload.chain === "1") {
                offerPayload.chain = true;
            }
        }

        if (typeof offerPayload.mip === "string") {
            if (offerPayload.mip === "0" || offerPayload.mip === "") {
                offerPayload.mip = false;
            } else if (offerPayload.mip === "1") {
                offerPayload.mip = true;
            }
        }

        if (typeof offerPayload.cashBuy === "string") {
            if (offerPayload.cashBuy === "0" || offerPayload.cashBuy === "") {
                offerPayload.cashBuy = false;
            } else if (offerPayload.cashBuy === "1") {
                offerPayload.cashBuy = true;
            }
        }

        this.props.updatePropertyOffer(offerPayload);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "offer"
        );
    };

    render() {
        const { offer } = this.state;
        const { offers, updatePropertyOfferStatus } = this.props;
        if (this.props.offer === undefined) {
            return null;
        }
        const userCanAcceptOffer = canAcceptOffer(offer, offers);

        return (
            <HorizontalTabNabSubPage
                loading={
                    this.props.offer === undefined ||
                    updatePropertyOfferStatus === AsyncActionState.Pending
                }
                goBack={this.handleGoBack}
            >
                <CreateOfferForm
                    offer={offer}
                    save={this.handleSave}
                    onChangeInputField={this.onChangeInputField}
                    canAcceptOffer={userCanAcceptOffer}
                    saveOfferStatus={updatePropertyOfferStatus}
                    resetFormState={this.props.resetUpdatePropertyOfferStatus}
                />
                <hr />
                <h4>Amends history</h4>
                <p>You can see any historical changes to this offer below.</p>
                <OfferHistoryList history={offer.history} />
            </HorizontalTabNabSubPage>
        );
    }
}

const mapStateToProps = ({ property }: IStore) => ({
    offer: property.offerToEdit,
    offers: selectPropertyOffers(property),
    updatePropertyOfferStatus: property.updatePropertyOfferStatus
});

export default connect(
    mapStateToProps,
    {
        updatePropertyOffer,
        resetUpdatePropertyOfferStatus,
        setPropertyOfferToEdit
    }
)(EditOfferFormContainer);
