import React from "react";
import TabNav from "../../../../../_sharedComponents/TabNav";

interface IProps {
    links: Array<{ to: string; displayTitle: string }>;
}

function EditBranchTopNav(props: IProps) {
    const { links } = props;
    return <TabNav links={links} />;
}

export default EditBranchTopNav;
