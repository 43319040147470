import React, { Component } from "react";
import { IUser } from "../../../../../../api/_types";
import EditBranchTopNav from "../../components/EditBranchTopNav";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
// import { UserTypes } from "../../../../../../redux/_types/user/types";

interface IProps {
    me?: IUser;
}

class EditBranchTopNavContainer extends Component<IProps> {
    render() {
        // const { me } = this.props;
        const links = [
            {
                to: `/branch/edit`,
                displayTitle: "Branch"
            },
            {
                to: `/property/create`,
                displayTitle: "Add Property"
            },
            {
                to: `/property/manage`,
                displayTitle: "Properties"
            },
            {
                to: `/branch/users`,
                displayTitle: "Users"
            },
            {
                to: `/branch/report`,
                displayTitle: "Workflow"
            }
        ];

        // if (
        //     me !== undefined &&
        //     [
        //         UserTypes.GYA_ADMIN,
        //         UserTypes.COMPANY_ADMIN,
        //         UserTypes.BRANCH_ADMIN
        //     ].includes(me.type) === true
        // ) {
        //     links.push({
        //         to: `/branch/timeline`,
        //         displayTitle: "Customise timeline"
        //     });
        // }
        return <EditBranchTopNav links={links} />;
    }
}

const mapStateToProps = ({ user }: IStore) => ({
    me: user.me
});

export default connect(
    mapStateToProps,
    {}
)(EditBranchTopNavContainer);
