import * as actions from "../../actions/companyContacts";
import { ICompanyContactsGetListResponse } from "../../../api/modules/companyContacts";
import { AsyncActionState } from "../../utils/asyncAction";
import { ICompanyContact } from "../../../api/_types";

export const setViewingCompanyContact = (
    payload: ICompanyContact | undefined
) =>
    ({
        type: actions.CompanyContactTypes.SET_VIEWING_COMPANY_CONTACT,
        payload
    } as actions.SetViewingCompanyContact);

export const setCompanyContacts = (payload: ICompanyContactsGetListResponse) =>
    ({
        type: actions.CompanyContactTypes.SET_COMPANY_CONTACTS,
        payload
    } as actions.SetCompanyContacts);

export const getCompanyContactsStatus = (payload: AsyncActionState) =>
    ({
        type: actions.CompanyContactTypes.GET_COMPANY_CONTACTS_STATUS,
        payload
    } as actions.SetCompanyContactsStatus);

export const createCompanyContactsStatus = (payload: AsyncActionState) =>
    ({
        type: actions.CompanyContactTypes.CREATE_COMPANY_CONTACT_STATUS,
        payload
    } as actions.CreateCompanyContactsStatus);

export const editCompanyContactsStatus = (payload: AsyncActionState) =>
    ({
        type: actions.CompanyContactTypes.EDIT_COMPANY_CONTACT_STATUS,
        payload
    } as actions.EditCompanyContactsStatus);

export const deleteCompanyContactStatus = (payload: AsyncActionState) =>
    ({
        type: actions.CompanyContactTypes.DELETE_COMPANY_CONTACT_STATUS,
        payload
    } as actions.SetDeleteCompanyContactStatus);

export const resetCreateCompanyContactsStatus = createCompanyContactsStatus.bind(
    undefined,
    AsyncActionState.Reset
);

export const resetEditCompanyContactsStatus = editCompanyContactsStatus.bind(
    undefined,
    AsyncActionState.Reset
);
