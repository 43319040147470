import * as actions from "../../actions/property/documents";
import { AsyncActionState } from "../../utils/asyncAction";

export interface IPropertyDocumentsState {
    createDocumentStatus: AsyncActionState;
    updateDocumentStatus: AsyncActionState;
    deleteDocumentStatus: AsyncActionState;
    emailDocumentStatus: AsyncActionState;
}

const initialState: IPropertyDocumentsState = {
    createDocumentStatus: AsyncActionState.Reset,
    updateDocumentStatus: AsyncActionState.Reset,
    deleteDocumentStatus: AsyncActionState.Reset,
    emailDocumentStatus: AsyncActionState.Reset
};

export default function propertyNotesReducer(
    state: IPropertyDocumentsState = initialState,
    action:
        | actions.CreateDocumentStatus
        | actions.DeleteDocumentStatus
        | actions.UpdateDocumentStatus
        | actions.EmailDocumentStatus
) {
    switch (action.type) {
        case actions.PropertyDocumentTypes.CREATE_DOCUMENT_STATUS: {
            return Object.assign({}, state, {
                createDocumentStatus: action.payload
            } as IPropertyDocumentsState);
        }

        case actions.PropertyDocumentTypes.UPDATE_DOCUMENT_STATUS: {
            return Object.assign({}, state, {
                updateDocumentStatus: action.payload
            } as IPropertyDocumentsState);
        }

        case actions.PropertyDocumentTypes.DELETE_DOCUMENT_STATUS: {
            return Object.assign({}, state, {
                deleteDocumentStatus: action.payload
            } as IPropertyDocumentsState);
        }

        case actions.PropertyDocumentTypes.EMAIL_DOCUMENT_STATUS: {
            return Object.assign({}, state, {
                emailDocumentStatus: action.payload
            } as IPropertyDocumentsState);
        }

        default: {
            return state;
        }
    }
}
