import { AxiosResponse } from "axios";

export default {
    onSuccess: function(response: AxiosResponse) {
        // Do something with response data
        return response;
    },
    onError: function invalidatedSessionError(err: any) {
        console.log(err.response);

        //Session has expired
        // @TODO make this a bit cleaner, it's just a long check to ensure we're not trying to
        // access a property on undefined.
        // @TODO Refactor literals for error codes out of the expression
        if (
            err.response &&
            err.response.data &&
            err.response.data.code &&
            ["INVALID_SESSION", "NO_SESSION"].includes(
                err.response.data.code
            ) === true &&
            process.env.REACT_APP_LOGOUT_REDIRECT_URL !== undefined
        ) {
            window.location.href = process.env.REACT_APP_LOGOUT_REDIRECT_URL;
        }

        return Promise.reject(err);
    }
};
