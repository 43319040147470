import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { MIReportSortOption } from "../../../../../../api/modules/property";
import { IWorkflowFilters } from "../../../../../../api/_types";
import { sendMiPdf } from "../../../../../../redux/api/property";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import { ChainStatus } from "../../../../../../redux/_types/property/types";
import {
    buildFilterObj,
    IPropertyFilterKeyVal
} from "../../../property/containers/PropertyList/filters";
import SendMiPdfForm from "../../components/SendMiPdfForm";
import { IVisibleGroups } from "../MIReport";

const mapStateToProps = ({ property }: IStore) => ({
    sendMiPdfStatus: property.sendMiPdfStatus
});

const connector = connect(
    mapStateToProps,
    { sendMiPdf }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {
    branches: string[];
    bStaffIds: string[];
    chainStatus: ChainStatus[];
    sortOption: MIReportSortOption;
    visibleGroups: IVisibleGroups;
    filter: IPropertyFilterKeyVal[];
    startDate: string | Date;
    endDate: string | Date;
}
interface IState {
    email: string;
}

class SendMiPdf extends Component<IProps, IState> {
    state: IState = {
        email: ""
    };

    componentDidUpdate(prevProps: IProps) {
        if (
            prevProps.sendMiPdfStatus === AsyncActionState.Pending &&
            this.props.sendMiPdfStatus !== AsyncActionState.Pending
        ) {
            if (this.props.sendMiPdfStatus === AsyncActionState.Error) {
                alert("Error sending Workflow Report PDF.");
            } else {
                this.setState({
                    email: ""
                });
            }
        }
    }

    updateEmail = (email: string) => {
        this.setState({
            email
        });
    };

    sendPdf = () => {
        const {
            branches,
            chainStatus,
            sortOption,
            visibleGroups,
            startDate,
            endDate,
            filter,
            bStaffIds
        } = this.props;
        this.props.sendMiPdf({
            to: [this.state.email],
            filters: {
                ...buildFilterObj<IWorkflowFilters>(filter),
                dateStart: startDate,
                dateEnd: endDate,
                branchIds: branches,
                chainStatus: chainStatus,
                bStaffIds
            },
            sortOption: sortOption,
            filter: visibleGroups
        });

        return false;
    };

    render() {
        const { email } = this.state;
        const { sendMiPdfStatus } = this.props;
        return (
            <>
                <SendMiPdfForm
                    email={email}
                    updateEmail={this.updateEmail}
                    send={this.sendPdf}
                    sendPending={sendMiPdfStatus === AsyncActionState.Pending}
                />
            </>
        );
    }
}

export default connector(SendMiPdf);
