import React, { FC } from "react";
import { Button } from "react-bootstrap";

interface IProps {
    showNoteInput: (val: boolean) => void;
}

const NewFeedbackNote: FC<IProps> = ({ showNoteInput }) => {
    const onClick = () => showNoteInput(true);
    return (
        <Button variant="light" size="sm" onClick={onClick}>
            Add feedback
        </Button>
    );
};

export default NewFeedbackNote;
