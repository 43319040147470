import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { Button, Image, Row, Col } from "react-bootstrap";
import AddressForm from "../../../../../_sharedComponents/AddressForm";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import cdn from "../../../../../../api/modules/cdn";
import UserSelection from "../../../common/UserSelection";
import ACLUserType from "../../../../../_sharedComponents/ACLUserType";
import { IProperty, IUser } from "../../../../../../api/_types";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import routes from "../../../../../../navigation/routes";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";
import OwnershipSelect from "./OwnershipSelect";
import BoardStatusSelect from "./BoardStatusSelect";
import { BoardStatus } from "../../../../../../api/modules/property";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import FeedbackAlert from "../../../../../_sharedComponents/FeedbackAlert";
import SaleResetBtnRow from "../../containers/SaleResetBtnRow";
import LeaseHoldPackSelect from "./LeaseholdPack";
import BedroomsInput from "./BedroomsInput";
import Home from "../../../../../_sharedComponents/Icons/v2/Home";

interface IProps extends RouteComponentProps {
    property?: IProperty;
    vendorSearchResults?: IUser[];
    createPropertyPending: boolean;
    createPropertyError: boolean;
    createPropertySuccess: boolean;
    createPropertyVendorRef?: string | IUser;
    saveText?: string;
    createProperty: (property: IProperty, file?: File) => void;
    resendVendorEmailVerification?: (token: string) => void;
    resendVendorEmailVerificationStatus?: AsyncActionState;
    resetManagePropertyFormState: () => void;
    resetPropertyVendorRef: () => void;
    deleteProperty?: () => void;
    resetSearchVendorResults: () => void;
    resetResendEmailVerificationStatus?: () => void;
    deletePhoto?: () => void;
}

interface IAdditionalPropertyFields {}

interface IStateProperty extends IProperty, IAdditionalPropertyFields {}

interface IState {
    property: IStateProperty;
    previewURL: string;
    file?: File;
    dirty: boolean;
}

class ManagePropertyForm extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            property: this.generateInitialProperty(this.props.property),
            file: undefined,
            previewURL: "",
            dirty: false
        };
    }

    componentDidUpdate(prevProps: IProps) {
        if (
            prevProps.createPropertyPending === true &&
            this.props.createPropertySuccess === true
        ) {
            this.props.resetPropertyVendorRef();
            this.props.resetSearchVendorResults();
            this.resetFormState(() => {
                this.props.history.push(routes.propertyEdit.url);
            });
        }

        if (
            prevProps.createPropertyVendorRef !==
            this.props.createPropertyVendorRef
        ) {
            this.updateUserFromVendorSearch();
        }

        // If the owner reference just got reset, reset local state copy of it back to the default.
        if (
            prevProps.createPropertyVendorRef !== undefined &&
            this.props.createPropertyVendorRef === undefined
        ) {
            this.resetPropertyOwner();
        }
    }

    componentWillMount() {
        this.props.resetManagePropertyFormState();
    }

    componentWillUnmount() {
        this.props.resetManagePropertyFormState();
    }

    generateInitialProperty = (property?: IProperty): IStateProperty => {
        let obj = property || {
            name: "",
            properties: [],
            // @TODO reference branch
            branch: "",
            address: {
                line1: "",
                line2: "",
                town: "",
                county: "",
                country: "",
                postcode: ""
            },
            boardStatus: BoardStatus.NoBoard,
            company: "",
            viewings: [],
            offers: [],
            ownership: "unknown",
            owner: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                type: UserTypes.VENDOR,
                confirmPassword: ""
            } as IUser,
            leaseholdPackSent: "unknown",
            notes: [],
            documents: []
        };

        return {
            ...obj
            // Place any psuedo fields here, e.g. adding to an array
        };
    };

    resetPropertyOwner = () => {
        const defaultState = this.generateInitialProperty(this.props.property);
        const property = this.state.property;

        property.owner = defaultState.owner;

        this.setState({
            property
        });
    };

    clearVendorButtonAction = () => {
        this.resetPropertyOwner();
        this.props.resetPropertyVendorRef();
    };

    updateUserFromVendorSearch = () => {
        const property = this.state.property;
        property.owner = this.props.createPropertyVendorRef as IUser;
        this.setState({
            property
        });
    };

    resetFormState = (callback?: () => void) => {
        this.setState(
            {
                property: this.generateInitialProperty(this.props.property),
                file: undefined,
                previewURL: "",
                dirty: false
            },
            callback
        );
    };

    handleFormSubmit = () => {
        const { property, file } = this.state;
        const { createProperty } = this.props;
        // The radio buttons can only bind to string values, so we need to proccess these back to booleans before submission.
        if (typeof property.active === "string") {
            if (property.active === "0" || property.active === "") {
                property.active = false;
            } else if (property.active === "1") {
                property.active = true;
            }
        }

        // IF we're creating a new property
        if (property._id === undefined || property._id === "") {
            const handleError = () =>
                alert(
                    "Please add an existing vendor, or create a new record using the 'New vendor' tab."
                );
            if (property.owner === undefined) {
                return handleError();
            }

            if (typeof property.owner === "string") {
                if (property.owner === "") {
                    return handleError();
                }
            }
            if (typeof property.owner === "object") {
                if (
                    property.owner.firstName === "" ||
                    property.owner.lastName === "" ||
                    property.owner.email === ""
                ) {
                    return handleError();
                }
            }
        }

        createProperty(property, file);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "property"
        );
    };

    handleFileChange = (event: any) => {
        this.setState({
            file: (event.target as any).files[0],
            previewURL: window.URL.createObjectURL(event.target.files[0])
        });
    };

    handlePressFileInput = () => {
        let fileInput = document.getElementById("propertyFileInput");

        if (fileInput !== null) {
            fileInput.click();
        }
    };

    render() {
        const {
            deleteProperty,
            createPropertyError,
            createPropertySuccess,
            resetManagePropertyFormState,
            saveText = "Save property",
            deletePhoto,
            resendVendorEmailVerification,
            resendVendorEmailVerificationStatus,
            resetResendEmailVerificationStatus
        } = this.props;
        const { property, dirty } = this.state;
        const owner = property.owner as IUser;

        return (
            <>
                <NavigationPrompt when={dirty === true} />

                <FormContainer
                    submit={(e) => {
                        e.preventDefault();
                        this.handleFormSubmit();
                    }}
                    actions={
                        <>
                            <Button size="lg" type="submit">
                                {saveText}
                            </Button>
                            {this.props.property !== undefined && (
                                <>

                                   <SaleResetBtnRow/>

                                    <ACLUserType
                                        types={[
                                            UserTypes.GYA_ADMIN,
                                            UserTypes.COMPANY_ADMIN
                                        ]}
                                    >
                                        <Button
                                            size="lg"
                                            variant="danger"
                                            className="ml-2"
                                            onClick={deleteProperty}
                                        >
                                            Delete property
                                        </Button>
                                    </ACLUserType>
                                </>
                            )}
                        </>
                    }
                    showSuccess={createPropertySuccess}
                    showError={createPropertyError}
                    success={{
                        heading: "Property saved",
                        message:
                            "You can now use this property for anything you like, you superhero!"
                    }}
                    error={{
                        heading: "Error saving property",
                        message:
                            "Your property was not created due to an error with the submission to the API."
                    }}
                    onFeedbackMessageClose={resetManagePropertyFormState}
                >
                    <Form.Row>
                        <Col md={6}>

                            <AddressForm
                                address={property.address}
                                onChangeInputField={this.onChangeInputField}
                            />

                            <div className="my-2">
                                <BedroomsInput
                                    bedrooms={property.bedrooms}
                                    onChangeInputField={this.onChangeInputField(
                                        "bedrooms"
                                    )}
                                />
                            </div>

                            <div className="my-2">
                                <OwnershipSelect
                                    current={property.ownership}
                                    onChangeInputField={this.onChangeInputField(
                                        "ownership"
                                    )}
                                />

                            </div>
                            <div className="my-2">

                                {property.ownership === "leasehold" && (
                                    <LeaseHoldPackSelect
                                        current={property.leaseholdPackSent}
                                        onChangeInputField={this.onChangeInputField("leaseholdPackSent")}
                                    />
                                )}
                            </div>
                            <div className="my-2">
                                <BoardStatusSelect
                                    current={property.boardStatus}
                                    onChangeInputField={this.onChangeInputField(
                                        "boardStatus"
                                    )}
                                />
                            </div>

                            {property._id !== undefined && property._id !== "" && (
                                <>
                                    <Form.Label>
                                        Is this an active sale?
                                    </Form.Label>
                                    <Form.Check
                                        label="Yes"
                                        type="radio"
                                        id="active-yes"
                                        name="active"
                                        checked={
                                            (property.active as any) === "1" ||
                                            property.active === true
                                        }
                                        onChange={
                                            this.onChangeInputField(
                                                "active"
                                            ) as any
                                        }
                                        value={"1"}
                                        custom={true}
                                    />
                                    <Form.Check
                                        label="No"
                                        type="radio"
                                        id="active-no"
                                        name="active"
                                        checked={
                                            (property.active as any) === "0" ||
                                            property.active === false ||
                                            property.active === undefined
                                        }
                                        onChange={
                                            this.onChangeInputField(
                                                "active"
                                            ) as any
                                        }
                                        value={"0"}
                                        custom={true}
                                    />
                                </>
                            )}
                        </Col>
                        <Col md={6}>
                            <div className="ml-2">
                                <Row>
                                    <Col style={{ marginBottom: 5 }}>
                                        {this.state.previewURL !== "" ||
                                        (this.props.property &&
                                            this.props.property.logo) ? (
                                            <Image
                                                thumbnail
                                                style={{ maxHeight: "250px" }}
                                                src={
                                                    this.state.previewURL === ""
                                                        ? cdn.getObjectURL(
                                                              //@ts-ignore
                                                              this.props
                                                                  .property.logo
                                                                  .Key
                                                          )
                                                        : this.state.previewURL
                                                }
                                            />
                                        ) : (
                                            <Home
                                                size={200}
                                            />
                                        )}
                                        <div className="mt-2">
                                            <Button
                                                className="mr-2"
                                                onClick={
                                                    this.handlePressFileInput
                                                }
                                            >
                                                Select image
                                            </Button>
                                            {this.state.previewURL !== "" && (
                                                <Button
                                                    variant="danger"
                                                    onClick={() =>
                                                        this.setState({
                                                            file: undefined,
                                                            previewURL: ""
                                                        })
                                                    }
                                                >
                                                    Clear
                                                </Button>
                                            )}
                                            {property._id !== undefined &&
                                                property.logo !== undefined &&
                                                this.state.previewURL ===
                                                    "" && (
                                                    <Button
                                                        variant="danger"
                                                        onClick={deletePhoto}
                                                    >
                                                        Delete image
                                                    </Button>
                                                )}
                                        </div>

                                        <Form.Control
                                            id="propertyFileInput"
                                            hidden
                                            as="input"
                                            type="file"
                                            onChange={this.handleFileChange}
                                        />
                                        {property._id !== undefined &&
                                            property.owner !== undefined && (
                                                <>
                                                    <hr />
                                                    <div>
                                                        <h5>Vendor Details</h5>
                                                        <div>
                                                            <span>Name: </span>
                                                            <span>
                                                                {
                                                                    owner.firstName
                                                                }{" "}
                                                                {owner.lastName}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span>Email: </span>
                                                            <a
                                                                href={`mailto:${owner.email}`}
                                                            >
                                                                {owner.email}
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <span>Tel: </span>
                                                            <span>
                                                                {owner.tel}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    {(property?.owner as IUser)?.accountActivationToken !== undefined && resendVendorEmailVerification && resendVendorEmailVerificationStatus !== AsyncActionState.Success && (
                                                        <Button
                                                            className={"mt-1"}
                                                            size="sm"
                                                            disabled={resendVendorEmailVerificationStatus === AsyncActionState.Pending}
                                                            variant="light"
                                                            onClick={
                                                                () => resendVendorEmailVerification((property.owner as IUser).accountActivationToken as string)}>
                                                                    {resendVendorEmailVerificationStatus === AsyncActionState.Pending ? "Sending...": "Resend email activation"}

                                                        </Button>
                                                    )}
                                                    {resendVendorEmailVerificationStatus === AsyncActionState.Success && (
                                                        <FeedbackAlert variant="success" message="New email verification was sent succesfully." onClose={resetResendEmailVerificationStatus}  />
                                                    )}

                                                    {resendVendorEmailVerificationStatus === AsyncActionState.Error && (
                                                        <FeedbackAlert variant="success" message="An error occured sending the email verification." onClose={resetResendEmailVerificationStatus}  />
                                                    )}
                                                </>
                                            )}
                                    </Col>
                                </Row>
                                {this.props.property === undefined &&
                                    property !== undefined && (
                                        <Row>
                                            <Col className="vendor-details">
                                                <h4>Vendor Details</h4>

                                                <UserSelection
                                                    type="Vendor"
                                                    showNewUserForm={
                                                        !!(
                                                            property.owner &&
                                                            (property.owner as IUser)
                                                                ._id ===
                                                                undefined
                                                        )
                                                    }
                                                    updateUserInputField={
                                                        this.onChangeInputField
                                                    }
                                                    newUserModel={
                                                        property.owner
                                                    }
                                                    newUserModelFields={{
                                                        email: "owner.email",
                                                        firstName:
                                                            "owner.firstName",
                                                        lastName:
                                                            "owner.lastName",
                                                        tel: "owner.tel",
                                                        password:
                                                            "owner.password",
                                                        confirmPassword:
                                                            "owner.confirmPassword"
                                                    }}
                                                    clearNewUserModel={
                                                        this
                                                            .clearVendorButtonAction
                                                    }
                                                    newUserLabel="New vendor"
                                                    existingUserLabel="Existing vendor"
                                                />

                                            </Col>
                                        </Row>
                                    )}
                            </div>
                        </Col>
                    </Form.Row>
                </FormContainer>
            </>
        );
    }
}

export default withRouter<any, any>(ManagePropertyForm);
