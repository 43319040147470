import React from "react";
import { connect } from "react-redux";
import { IPropertyNote } from "../../../../../../api/_types";
import {
    resetUpdateNoteStatus,
    setNoteToEdit
} from "../../../../../../redux/acreators/property/notes";
import { updateNote } from "../../../../../../redux/api/property/notes";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ManageNote from "../../components/ManageNote";

interface IProps {
    note?: IPropertyNote;
    updateNoteStatus: AsyncActionState;
    updateNote: (note: IPropertyNote) => void;
    resetNoteToEdit: () => void;
    resetUpdateNoteStatus: () => void;
}

function EditNote(props: IProps) {
    const {
        updateNote,
        updateNoteStatus,
        note,
        resetNoteToEdit,
        resetUpdateNoteStatus
    } = props;

    const close = () => {
        resetUpdateNoteStatus();
        resetNoteToEdit();
    };

    return (
        <ManageNote
            title="Update this note"
            show={note !== undefined}
            asyncStatus={updateNoteStatus}
            noteToEdit={note}
            saveNote={updateNote}
            close={close}
        />
    );
}

const mapStateToProps = ({ notes }: IStore) => ({
    updateNoteStatus: notes.updateNoteStatus,
    note: notes.noteToEdit
});

export default connect(
    mapStateToProps,
    {
        updateNote,
        resetNoteToEdit: setNoteToEdit.bind(undefined, undefined),
        resetUpdateNoteStatus
    }
)(EditNote);
