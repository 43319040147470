import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { ChainStatus, IPropertyTimelineEntityState } from "../../../../../../../redux/_types/property/types";
import { IPropertyFilterKeyVal } from "../../../containers/PropertyList/filters";
import { TimelineKeys } from "../../../../../../../api/modules/property";

interface IProps {
    active: IPropertyFilterKeyVal[];
    updatePropertyFilter: (filter: IPropertyFilterKeyVal[]) => void;
}
interface IState {}

export default class PropertyFilter extends Component<IProps, IState> {
    filterValues = [
        {
            label: "All active properties",
            value: JSON.stringify([
                { key: "active", value: "1" },
                { key: "complete", value: "0" }
            ])
        },
        {
            label: "All warnings",
            value: JSON.stringify([
                { key: "warning", value: "1" },
                { key: "active", value: "1" },
                { key: "complete", value: "0" },
                { key: "warning", value: "1" }
            ])
        },
        {
            label: "All setup warnings",
            value: JSON.stringify([
                { key: "otherWarnings", value: "1" },
                { key: "active", value: "1" }
            ])
        },
        {
            label: "Pre for sale",
            value: JSON.stringify([
                { key: "preForSale", value: "1" },
                { key: "active", value: "1" },
                { key: "complete", value: "0" }
            ])
        },
        {
            label: "Pre for sale - with warnings",
            value: JSON.stringify([
                { key: "preForSale", value: "1" },
                { key: "active", value: "1" },
                { key: "complete", value: "0" },
                { key: "warning", value: "1" }
            ])
        },
        {
            label: "For sale",
            value: JSON.stringify([
                { key: "sold", value: "0" },
                { key: "preForSale", value: "0" },
                { key: "active", value: "1" }
            ])
        },
        {
            label: "For sale - with warnings",
            value: JSON.stringify([
                { key: "sold", value: "0" },
                { key: "preForSale", value: "0" },
                { key: "active", value: "1" },
                { key: "warning", value: "1" }
            ])
        },
        {
            label: "Sold",
            value: JSON.stringify([
                { key: "sold", value: "1" },
                { key: "active", value: "1" },
                { key: "complete", value: "0" }
            ])
        },
        {
            label: "Sold - warnings",
            value: JSON.stringify([
                { key: "sold", value: "1" },
                { key: "active", value: "1" },
                { key: "complete", value: "0" },
                { key: "warning", value: "1" }
            ])
        },
        {
            label: "Sold - chain",
            value: JSON.stringify([
                { key: "sold", value: "1" },
                { key: "active", value: "1" },
                { key: "chain", value: "1" },
                { key: "complete", value: "0" }
            ])
        },
        {
            label: "Sold - no chain",
            value: JSON.stringify([
                { key: "sold", value: "1" },
                { key: "active", value: "1" },
                { key: "chain", value: "0" },
                { key: "complete", value: "0" }
            ])
        },
        {
            label: "Sold - chain incomplete",
            value: JSON.stringify([
                { key: "sold", value: "1" },
                { key: "active", value: "1" },
                { key: "chainStatus", value: ChainStatus.IncompleteChain },
                { key: "complete", value: "0" }
            ])
        },
        {
            label: "Sold - chain complete",
            value: JSON.stringify([
                { key: "sold", value: "1" },
                { key: "active", value: "1" },
                {
                    key: "chainStatus",
                    value: [ChainStatus.NoChain, ChainStatus.CompletedChain]
                },
                { key: "complete", value: "0" }
            ])
        },
        {
            label: "SSTC - Not proceedable",
            value: JSON.stringify([
                { key: "sold", value: "1" },
                { key: "active", value: "1" },
                {
                    key: "chainStatus",
                    value: ChainStatus.IncompleteChain
                },
                {
                    key: "timelineStates",
                    value: [{
                        key: TimelineKeys.MemoOfSaleSent,
                        state: [
                            IPropertyTimelineEntityState.BLOCKED,
                            IPropertyTimelineEntityState.IN_PROGRESS
                        ]
                    }]
                },
                { key: "complete", value: "0" }
            ])
        },
        {
            label: "Sale complete",
            value: JSON.stringify([
                { key: "complete", value: "1" },
                { key: "active", value: "1" }
            ])
        },
        {
            label: "Freehold",
            value: JSON.stringify([
                { key: "ownership", value: "freehold" },
                { key: "active", value: "1" }
            ])
        },
        {
            label: "Leasehold",
            value: JSON.stringify([
                { key: "ownership", value: "leasehold" },
                { key: "active", value: "1" }
            ])
        },
        {
            label: "Chain unknown",
            value: JSON.stringify([
                { key: "chainStatus", value: ChainStatus.Unknown },
                { key: "complete", value: "0" },
                { key: "active", value: "1" }
            ])
        },
        {
            label: "Pending offers",
            value: JSON.stringify([
                { key: "pendingOffers", value: "1" },
                { key: "complete", value: "0" },
                { key: "active", value: "1" }
            ])
        },
        {
            label: "Archived",
            value: JSON.stringify([{ key: "active", value: "0" }])
        },
        {
            label: "All",
            value: JSON.stringify([])
        }
    ];

    onSelectOption = (event: React.FormEvent<any>) => {
        const { updatePropertyFilter } = this.props;

        const value = JSON.parse(event.currentTarget.value);

        // Check for correct format
        if (Array.isArray(value)) {
            updatePropertyFilter(value as IPropertyFilterKeyVal[]);
        }
    };

    render() {
        const { active } = this.props;
        return (
            <Form.Group controlId="propertyFilter">
                <Form.Control
                    as="select"
                    value={JSON.stringify(active)}
                    onChange={this.onSelectOption}
                >
                    {this.filterValues.map((item, index) => (
                        <option key={`filter-${index}`} value={item.value}>
                            {item.label}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
        );
    }
}
