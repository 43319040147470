import React from "react";
import PropertySearchInput from "../PropertySearchInput";
import Select from "react-select";
import PropertyFilter from "./PropertyFilter";
import { IBranch, PropertySortOptionType } from "../../../../../../api/_types";
import "./styles.scss";
import PropertySort from "./PropertySort";
import { IPropertyFilterKeyVal } from "../../containers/PropertyList/filters";
import PropertyListActions from "./PropertyActions";

interface IProps {
    branches: IBranch[];
    type: "company" | "branch";
    updateSearchText: (text: string) => void;
    getProperties: () => void;
    updateFilterBranches: (branches: IBranch[] | null) => void;
    activeFilter: IPropertyFilterKeyVal[];
    activeSortOption: PropertySortOptionType;
    updatePropertyFilter: (filter: IPropertyFilterKeyVal[]) => void;
    updatePropertySort: (sortOption: PropertySortOptionType) => void;
    getPropertiesCSV: () => Promise<void>;
    numProperties?: number;
}

export default function PropertyListHeaderOptions(props: IProps) {
    const {
        type,
        branches,
        updateSearchText,
        getProperties,
        updateFilterBranches,
        updatePropertyFilter,
        updatePropertySort,
        activeFilter,
        activeSortOption,
        getPropertiesCSV,
        numProperties = 0
    } = props;
    return (
        <>
            <label>Properties: {numProperties}</label>
            <div className="d-flex flex-column flex-xl-row">
                <div className="searchInputContainer">
                    <PropertySearchInput
                        updateSearchText={updateSearchText}
                        search={getProperties}
                    />
                </div>
                {type === "company" && (
                    <div className="filterBranchesContainer">
                        <Select
                            className="mb-2"
                            placeholder="Filter by branch"
                            isMulti={true}
                            options={branches.map((branch: IBranch) => ({
                                value: branch._id,
                                label: branch.name
                            }))}
                            styles={{
                                container: (provided: any) => ({
                                    ...provided,
                                    zIndex: 20
                                })
                            }}
                            onChange={updateFilterBranches as any}
                        />
                    </div>
                )}
                <div className="activeFilterContainer">
                    <PropertyFilter
                        active={activeFilter}
                        updatePropertyFilter={updatePropertyFilter}
                    />
                </div>
                <div className="activeSortContainer">
                    <PropertySort
                        active={activeSortOption}
                        updatePropertySort={updatePropertySort}
                    />
                </div>
                <div className="actionsContainer">
                    <PropertyListActions exportCsv={getPropertiesCSV} />
                </div>
            </div>
        </>
    );
}
