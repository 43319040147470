import axios from "axios";
import auth from "./modules/auth";
import cdn from "./modules/cdn";
import branch from "./modules/branch";
import company from "./modules/company";
import companyContacts from "./modules/companyContacts";
import companyContactPOCs from "./modules/companyContactPOCs";
import property from "./modules/property";
import user from "./modules/user";
import invalidatedSession from "./interceptors/response/invalidatedSession";
export const BASE_URL = "https://api.gya.fendorio.uk/";
export const REQUEST_TIMEOUT = 60000;
export const CUSTOM_HEADERS = {
    common: {
        Accept: "application/json, text/plain, */*",
        Authorization: "",
        "x-client": "Web"
    }
};
export const api = axios.create({
    baseURL: BASE_URL,
    timeout: REQUEST_TIMEOUT,
    headers: CUSTOM_HEADERS,
    withCredentials: true,
    //@ts-ignore
    crossDomain: true
});

api.interceptors.response.use(
    invalidatedSession.onSuccess,
    invalidatedSession.onError
);

const setAuthorizationHeader = (token: string) => {
    const val: string = token ? `Bearer ${token}` : "";
    api.defaults.headers.common.Authorization = val;
};

export default {
    auth,
    cdn,
    user,
    company,
    companyContacts,
    companyContactPOCs,
    branch,
    property,
    setAuthorizationHeader
};
