import * as actions from "../../actions/company";
import {
    ICompany,
    ISearchCompanyMembersResponse,
    IUser
} from "../../../api/_types";
import { AsyncActionState } from "../../utils/asyncAction";
import { IGetCompaniesResponse } from "../../../api/modules/company";
import { IGetPropertiesResponse } from "../../../api/modules/property";

export const setCompanies = (companies: IGetCompaniesResponse) => {
    return {
        type: actions.CompanyTypes.SET_COMPANIES,
        payload: companies
    } as actions.SetCompanies;
};

export const setViewingCompany = (company?: ICompany) => {
    return {
        type: actions.CompanyTypes.SET_VIEWING_COMPANY,
        payload: company
    } as actions.SetViewingCompany;
};

export const setCreateCompanyStatus = (status: AsyncActionState) => {
    return {
        type: actions.CompanyTypes.SAVE_COMPANIES_STATUS,
        payload: status
    } as actions.SaveCompanyStatus;
};

export const setGetCompaniesStatus = (status: AsyncActionState) => {
    return {
        type: actions.CompanyTypes.GET_COMPANIES_STATUS,
        payload: status
    } as actions.GetCompaniesStatus;
};

export const setGetCompanyStatus = (status: AsyncActionState) => {
    return {
        type: actions.CompanyTypes.GET_COMPANY_STATUS,
        payload: status
    } as actions.GetCompanyStatus;
};

export const setDeleteCompanyStatus = (status: AsyncActionState) => {
    return {
        type: actions.CompanyTypes.DELETE_COMPANY_STATUS,
        payload: status
    } as actions.DeleteCompanyStatus;
};

export const setUpdateCompanyTimelineStatus = (status: AsyncActionState) => {
    return {
        type: actions.CompanyTypes.UPDATE_COMPANY_TIMELINE_STATUS,
        payload: status
    } as actions.UpdateCompanyTimelineStatus;
};

export const setSearchCompanyUsersStatus = (status: AsyncActionState) => {
    return {
        type: actions.CompanyTypes.SEARCH_COMPANY_USERS_STATUS,
        payload: status
    } as actions.SetSearchCompanyUsersStatus;
};

export const setCompanyUsers = (payload: ISearchCompanyMembersResponse) => {
    return {
        type: actions.CompanyTypes.SET_COMPANY_USERS,
        payload
    } as actions.SetCompanyUsers;
};

export const setCreateCompanyAdminStatus = (status: AsyncActionState) => {
    return {
        type: actions.CompanyTypes.CREATE_COMPANY_ADMIN_STATUS,
        payload: status
    } as actions.SetCreateCompanyAdminStatus;
};

export const setUpdateCompanyAdminStatus = (status: AsyncActionState) => {
    return {
        type: actions.CompanyTypes.EDIT_COMPANY_ADMIN_STATUS,
        payload: status
    } as actions.EditCompanyAdminStatus;
};

export const setUpdateCompanyAdmin = (user?: IUser) => {
    return {
        type: actions.CompanyTypes.SET_EDIT_COMPANY_ADMIN,
        payload: user
    } as actions.SetEditCompanyAdmin;
};

export const setDeleteCompanyMemberStatus = (status: AsyncActionState) => {
    return {
        type: actions.CompanyTypes.DELETE_MEMBER_STATUS,
        payload: status
    } as actions.DeleteCompanyMemberStatus;
};

export const setDeleteCompanyPhotoStatus = (status: AsyncActionState) => {
    return {
        type: actions.CompanyTypes.DELETE_COMPANY_PHOTO_STATUS,
        payload: status
    } as actions.DeleteCompanyPhotoStatus;
};

export const setGetCompanyPropertiesStatus = (status: AsyncActionState) => {
    return {
        type: actions.CompanyTypes.GET_COMPANY_PROPERTIES_STATUS,
        payload: status
    } as actions.GetCompanyPropertiesStatus;
};

export const setCompanyProperties = (payload: IGetPropertiesResponse) => {
    return {
        type: actions.CompanyTypes.SET_COMPANY_PROPERTIES,
        payload
    } as actions.SetCompanyProperties;
};
