import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { ICompany, ICompanyContactPOC } from "../../../../../../api/_types";
import { resetCreateCompanyContactPOCStatus } from "../../../../../../redux/acreators/companyContactPOCs";
import { createCompanyContactPOC } from "../../../../../../redux/api/companyContactPOCs";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import CreateContactPOCForm from "../../components/CreateContactPOCForm";

interface IProps {
    company?: ICompany;
    createCompanyContactPOCStatus: AsyncActionState;
    createCompanyContactPOC: (poc: ICompanyContactPOC) => void;
    resetCreateCompanyContactPOCStatus: () => void;
}

interface IState {
    poc: {
        name: string;
        tel: string;
        email: string;
        note: string;
    };
    dirty: boolean;
}

class CreateContactPOC extends Component<IProps, IState> {
    generateInitialContactPOC = () => ({
        name: "",
        tel: "",
        email: "",
        note: ""
    });

    state: IState = {
        poc: this.generateInitialContactPOC(),
        dirty: false
    };

    componentDidUpdate(prevProps: IProps) {
        if (
            prevProps.createCompanyContactPOCStatus ===
                AsyncActionState.Pending &&
            this.props.createCompanyContactPOCStatus ===
                AsyncActionState.Success
        ) {
            this.setState({
                poc: this.generateInitialContactPOC(),
                dirty: false
            });
        }
    }

    componentWillUnmount() {
        this.props.resetCreateCompanyContactPOCStatus();
    }

    save = (e: React.FormEvent) => {
        const { poc } = this.state;
        const { company, createCompanyContactPOC } = this.props;

        e.preventDefault();

        if (company === undefined) {
            return;
        }

        createCompanyContactPOC({
            name: poc.name,
            tel: poc.tel,
            email: poc.email,
            note: poc.note,
            company: company._id as string
        });
    };

    onChangeInputField = (key: string) => {
        return getStateFromOnChangeUpdate(this, this.state, key, "poc");
    };

    render() {
        const { poc, dirty } = this.state;
        const {
            createCompanyContactPOCStatus,
            resetCreateCompanyContactPOCStatus
        } = this.props;
        return (
            <LoadingContainer
                loading={
                    createCompanyContactPOCStatus === AsyncActionState.Pending
                }
            >
                <NavigationPrompt when={dirty === true} />

                <FormContainer
                    submit={this.save}
                    actions={[
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    ]}
                    onFeedbackMessageClose={resetCreateCompanyContactPOCStatus}
                    showError={
                        createCompanyContactPOCStatus === AsyncActionState.Error
                    }
                    showSuccess={
                        createCompanyContactPOCStatus ===
                        AsyncActionState.Success
                    }
                    success={{
                        heading: "Contact saved",
                        message: "The contact has been saved successfully."
                    }}
                    error={{
                        heading: "Something went wrong",
                        message:
                            "Something went wrong saving this contact, please try again."
                    }}
                >
                    <CreateContactPOCForm
                        poc={poc as ICompanyContactPOC}
                        onChangeInputField={this.onChangeInputField}
                    />
                </FormContainer>
            </LoadingContainer>
        );
    }
}

const mapStateToProps = ({ company, companyContactPOCs }: IStore) => ({
    company: company.viewingCompany,
    createCompanyContactPOCStatus:
        companyContactPOCs.createCompanyContactPOCStatus
});

export default connect(
    mapStateToProps,
    {
        createCompanyContactPOC,
        resetCreateCompanyContactPOCStatus
    }
)(CreateContactPOC);
