import React, { Component, ReactNode } from "react";
import { InputGroup } from "react-bootstrap";
import { AsyncTypeahead, AsyncTypeaheadProps } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import BasicTypeaheadItem from "./TypeaheadItem";

type InheritedTypeaheadProps = Pick<AsyncTypeaheadProps<any>, 'labelKey'| "delay" | "isLoading" | "minLength" | "id" | 'emptyLabel' | 'multiple'>;

interface IProps extends InheritedTypeaheadProps {
    data: any[];
    innerRef?: React.Ref<AsyncTypeahead<any>>;
    displayMessage?: string;
    displayMessageClasses?: string;
    displayTitle?: string;
    inputPlaceholder?: string;
    formatItemDisplay: (item: any) => string;
    handleOnChange: (data: any) => void;
    handleOnInputChange: (input: string) => void;
    append?: React.ReactNode | React.ReactNode[];
    renderItem?: (option: any, props: any, index: number) => ReactNode;
}
interface IState {}

class Typeahead extends Component<IProps, IState> {
    render() {
        const {
            append,
            data,
            delay = 800,
            minLength = 3,
            isLoading,
            displayMessage,
            displayTitle,
            inputPlaceholder,
            displayMessageClasses = "text-muted",
            emptyLabel = "No results found.",
            labelKey,
            multiple = false,
            id = "typeahead",
            handleOnChange,
            handleOnInputChange,
            formatItemDisplay,
            renderItem,
            innerRef
        } = this.props;

        const defaultRenderItem = (
            option: any,
            props: any,
            index: number
        ) => (
            <BasicTypeaheadItem
                key={`typeahead-item-${index}`}
                item={option}
                display={formatItemDisplay}
                />
            );
                
        const appliedRenderItem = renderItem ??  defaultRenderItem;

        return (
            <div>
                {displayTitle !== undefined && <label>{displayTitle}</label>}
                {displayMessage !== undefined && (
                    <p className={displayMessageClasses}>{displayMessage}</p>
                )}

                <InputGroup>
                    <AsyncTypeahead
                        multiple={multiple}
                        id={id}
                        ref={innerRef}
                        isLoading={isLoading}
                        delay={delay}
                        minLength={minLength}
                        emptyLabel={emptyLabel}
                        placeholder={inputPlaceholder}
                        labelKey={labelKey}
                        onChange={handleOnChange}
                        onSearch={handleOnInputChange}
                        options={data}
                        useCache={false}
                        filterBy={typeof labelKey === "string" ? (option, props) => option[labelKey].trim() : undefined}
                        renderMenuItemChildren={appliedRenderItem}
                    />
                    {append && <InputGroup.Append>{append}</InputGroup.Append>}
                </InputGroup>
            </div>
        );
    }
}

//@ts-ignore
export default React.forwardRef((props:IProps, ref: React.Ref<AsyncTypeahead<any>|null>) => <Typeahead {...props} innerRef={ref} />);