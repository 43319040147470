import React from "react";
import TabNav from "../../../../../_sharedComponents/TabNav";
import routes from "../../../../../../navigation/routes";
import PropertyTitle from "../../containers/PropertyTitle";

interface IProps {}

function EditPropertyTopNav(props: IProps) {
    return (
        <>
            <PropertyTitle />
            <TabNav
                links={[
                    {
                        to: routes.propertyEdit.url,
                        displayTitle: "Manage"
                    },
                    {
                        to: routes.propertyMiReport.url,
                        displayTitle: "Info"
                    },
                    {
                        to: routes.propertyAskingPrice.url,
                        displayTitle: "Asking Price"
                    },
                    {
                        to: routes.propertyViewings.url,
                        displayTitle: "Viewings"
                    },
                    {
                        to: routes.propertyOffers.url,
                        displayTitle: "Offers"
                    },
                    {
                        to: routes.propertyTimeline.url,
                        displayTitle: "Timeline"
                    },
                    {
                        to: routes.propertyContacts.url,
                        displayTitle: "Contacts"
                    },
                    {
                        to: routes.propertyChain.url,
                        displayTitle: "Chain"
                    },
                    {
                        to: routes.propertyNotes.url,
                        displayTitle: "Notes"
                    },
                    {
                        to: routes.propertyDocuments.url,
                        displayTitle: "Documents"
                    }
                ]}
            />
        </>
    );
}

export default EditPropertyTopNav;
