import React, { FC, useRef } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { IProperty } from "../../../../../../api/_types";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux-utils";
import { searchProperties } from "../../../../../../redux/reducers/propertySearch";
import Typeahead from "../../../../../_sharedComponents/Typeahead";
import PropertySearchItem from "./SearchItem";

interface IProps {
    onSelect: (property: IProperty[]) => void;
}

const PropertySearch: FC<IProps> = ({onSelect}) => {
    const dispatch = useAppDispatch();
    const typeaheadRef = useRef<AsyncTypeahead<any>>(null);
    const {companyId, branchId, properties, property} = useAppSelector(({company, branch, property, propertySearch}) => ({
        companyId: company.viewingCompany?._id, 
        branchId: branch.viewingBranch?._id,
        properties: propertySearch.results.properties,
        property: property.viewingProperty
    }));

    const formatItemDisplay = (item: IProperty) => `${item.address?.line1} ${item.address?.postcode}`;

    const onKeyboardInput = (textSearch: string) => {
        const excludeIds = [property?._id!];
        property?.chain?.items?.forEach((item) => {
            if (item.propertyRef !== undefined) {
                if (typeof item.propertyRef === "string") {
                    excludeIds.push(item.propertyRef);
                } else {
                    excludeIds.push(item.propertyRef?._id!);
                }
            }
        });
        if (companyId && branchId) {
            dispatch(searchProperties({companyId, branchId, textSearch, excludeIds}));
        }
    }        

    const renderItem = (item: IProperty, props: any, index: number) => {
        return (
            <PropertySearchItem 
                addressLine1={item.address?.line1} 
                addressPostcode={item.address?.postcode}
                value={item.mi?.agreedPrice}
            />
        );
    }   

    return (
        <Typeahead 
            id="property-search"
            data={properties}
            labelKey="_id"
            isLoading={false}
            inputPlaceholder="Search properties..."
            ref={typeaheadRef}
            handleOnChange={(properties) => {
                if (typeaheadRef.current !== null) {
                    //@ts-ignore
                    typeaheadRef.current?.getInstance()?.clear?.();
                }
                onSelect(properties)
            }}
            formatItemDisplay={formatItemDisplay}
            handleOnInputChange={onKeyboardInput}
            renderItem={renderItem}
        />
    );
};

export default PropertySearch;
