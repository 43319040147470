import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { IPropertyOffer } from "../../../../../../../api/_types";

type OfferFields = Pick<IPropertyOffer, "email">;

interface IProps extends OfferFields {
    onChange: (key: string) => (e: any) => void;
}

const OfferEmail: FC<IProps> = ({ email, onChange }) => {
    return (
        <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control onChange={onChange("email")} value={email} />
        </Form.Group>
    );
};

export default OfferEmail;
