/**
 * Company Screens
 */
import CreateCompanyScreen from "../components/modules/core/company/screens/Create";
import ManageCompanyScreen from "../components/modules/core/company/screens/Manage";
import EditCompanyScreen from "../components/modules/core/company/screens/Edit";
import MIReportScreen from "../components/modules/core/company/screens/MIReport";
import ContactsScreen from "../components/modules/core/company/screens/Contacts";
import ContactPOCsScreen from "../components/modules/core/company/screens/ContactPOCs";

/**
 * Branch Screens
 */
import CreateBranchScreen from "../components/modules/core/branch/screens/Create";
import ManageBranchScreen from "../components/modules/core/branch/screens/Manage";
import EditBranchScreen from "../components/modules/core/branch/screens/Edit";
import ManageBranchUsersScreen from "../components/modules/core/branch/screens/ManageUsers";
import CompanyTimelineScreen from "../components/modules/core/company/screens/CompanyTimeline";
import BranchTimelineScreen from "../components/modules/core/branch/screens/BranchTimeline";
import BranchMIReportScreen from "../components/modules/core/branch/screens/MIReport";

/**
 * Property Screens
 */
import CreatePropertyScreen from "../components/modules/core/property/screens/Create";
import ManagePropertyScreen from "../components/modules/core/property/screens/Manage";
import EditPropertyScreen from "../components/modules/core/property/screens/Edit";
import PropertyTimeline from "../components/modules/core/property/screens/Timeline";
import PropertyAskingPrice from "../components/modules/core/property/screens/AskingPrice";
import PropertyContacts from "../components/modules/core/property/screens/Contacts";
import PropertyMIReport from "../components/modules/core/property/screens/MiReport";
import PropertyOffers from "../components/modules/core/property/screens/Offers";
import PropertyNotes from "../components/modules/core/property/screens/Notes";
import PropertyDocuments from "../components/modules/core/property/screens/Documents";
import PropertyViewings from "../components/modules/core/property/screens/Viewings";
import PropertyChain from "../components/modules/core/property/screens/Chain";

/**
 * User Screens
 */
import UserProfileScreen from "../components/modules/core/user/screens/UserProfile";
import ManageGYAAdminsScreen from "../components/modules/core/user/screens/ManageGYAAdmins";
import DashboardMainScreen from "../components/modules/core/dashboard/screens/main";
import ManageCompanyMembers from "../components/modules/core/company/screens/ManageCompanyMembers";
import EditUserScreen from "../components/modules/core/branch/screens/EditUser";
import AddGYAAdminScreen from "../components/modules/core/user/screens/AddGYAAdmin";
import PropertiesScreen from "../components/modules/core/company/screens/Properties";

export interface IRouteDefinition {
    url: string;
    screen: any;
}

export interface IRouteDefinitionDict {
    companyManage: IRouteDefinition;
    companyCreate: IRouteDefinition;
    companyEdit: IRouteDefinition;
    companyTimeline: IRouteDefinition;
    companyMIReport: IRouteDefinition;
    companyUsers: IRouteDefinition;
    companyCreateUser: IRouteDefinition;
    companyProperties: IRouteDefinition;
    companyContacts: IRouteDefinition;
    companyCreateContact: IRouteDefinition;
    companyContactPOCs: IRouteDefinition;
    companyCreateContactPOC: IRouteDefinition;

    // companyEditAdmin: IRouteDefinition;
    branchManage: IRouteDefinition;
    branchCreate: IRouteDefinition;
    branchEdit: IRouteDefinition;
    branchTimeline: IRouteDefinition;
    branchUsers: IRouteDefinition;
    branchCreateUser: IRouteDefinition;
    branchAddExistingUser: IRouteDefinition;
    branchMIReport: IRouteDefinition;
    propertyManage: IRouteDefinition;
    propertyCreate: IRouteDefinition;
    propertyEdit: IRouteDefinition;
    propertyTimeline: IRouteDefinition;
    propertyAskingPrice: IRouteDefinition;
    propertyCreateAskingPrice: IRouteDefinition;
    propertyContacts: IRouteDefinition;
    propertyMiReport: IRouteDefinition;
    propertyOffers: IRouteDefinition;
    propertyNotes: IRouteDefinition;
    propertyDocuments: IRouteDefinition;
    propertyCreateOffer: IRouteDefinition;
    propertyViewings: IRouteDefinition;
    propertyChain: IRouteDefinition;
    propertyCreateViewing: IRouteDefinition;
    userProfile: IRouteDefinition;
    userEdit: IRouteDefinition;
    dashboard: IRouteDefinition;
    gyaAdminUsers: IRouteDefinition;
    gyaAddAdminUser: IRouteDefinition;
}

const routes: IRouteDefinitionDict = {
    companyManage: {
        url: "/company/manage",
        screen: ManageCompanyScreen
    },
    companyCreate: {
        url: "/company/create",
        screen: CreateCompanyScreen
    },
    companyEdit: {
        url: "/company/edit",
        screen: EditCompanyScreen
    },
    companyTimeline: {
        url: "/company/timeline",
        screen: CompanyTimelineScreen
    },
    companyMIReport: {
        url: "/company/report",
        screen: MIReportScreen
    },
    companyUsers: {
        url: "/company/users",
        screen: ManageCompanyMembers
    },
    companyCreateUser: {
        url: "/company/user-create",
        screen: ManageCompanyMembers
    },
    companyProperties: {
        url: "/company/properties",
        screen: PropertiesScreen
    },
    companyContacts: {
        url: "/company/contacts",
        screen: ContactsScreen
    },
    companyCreateContact: {
        url: "/company/create-contact",
        screen: ContactsScreen
    },
    companyContactPOCs: {
        url: "/company/pocs",
        screen: ContactPOCsScreen
    },
    companyCreateContactPOC: {
        url: "/company/create-poc",
        screen: ContactPOCsScreen
    },

    // companyEditAdmin: {
    //     url: "/company/users/edit",
    //     screen: EditCompanyAdminScreen
    // },
    branchManage: {
        url: "/branch/manage",
        screen: ManageBranchScreen
    },
    branchCreate: {
        url: "/branch/create",
        screen: CreateBranchScreen
    },
    branchEdit: {
        url: "/branch/edit",
        screen: EditBranchScreen
    },
    branchTimeline: {
        url: "/branch/timeline",
        screen: BranchTimelineScreen
    },
    branchUsers: {
        url: "/branch/users",
        screen: ManageBranchUsersScreen
    },
    branchCreateUser: {
        url: "/branch/user-create",
        screen: ManageBranchUsersScreen
    },
    branchAddExistingUser: {
        url: "/branch/existing-user",
        screen: ManageBranchUsersScreen
    },

    branchMIReport: {
        url: "/branch/report",
        screen: BranchMIReportScreen
    },
    propertyManage: {
        url: "/property/manage",
        screen: ManagePropertyScreen
    },
    propertyCreate: {
        url: "/property/create",
        screen: CreatePropertyScreen
    },
    propertyEdit: {
        url: "/property/edit",
        screen: EditPropertyScreen
    },
    propertyTimeline: {
        url: "/property/timeline",
        screen: PropertyTimeline
    },
    propertyAskingPrice: {
        url: "/property/askingprice",
        screen: PropertyAskingPrice
    },
    propertyCreateAskingPrice: {
        url: "/property/askingprice-create",
        screen: PropertyAskingPrice
    },
    propertyContacts: {
        url: "/property/contacts",
        screen: PropertyContacts
    },
    propertyMiReport: {
        url: "/property/mireport",
        screen: PropertyMIReport
    },
    propertyOffers: {
        url: "/property/offers",
        screen: PropertyOffers
    },
    propertyNotes: {
        url: "/property/notes",
        screen: PropertyNotes
    },
    propertyDocuments: {
        url: "/property/documents",
        screen: PropertyDocuments
    },
    propertyCreateOffer: {
        url: "/property/offer-create",
        screen: PropertyOffers
    },
    propertyViewings: {
        url: "/property/viewings",
        screen: PropertyViewings
    },
    propertyChain: {
        url: "/property/chain",
        screen: PropertyChain
    },
    propertyCreateViewing: {
        url: "/property/viewing-create",
        screen: PropertyViewings
    },
    userProfile: {
        url: "/profile",
        screen: UserProfileScreen
    },
    userEdit: {
        url: "/user-edit",
        screen: EditUserScreen
    },
    dashboard: {
        url: "/dashboard",
        screen: DashboardMainScreen
    },
    gyaAdminUsers: {
        url: "/gya/users",
        screen: ManageGYAAdminsScreen
    },
    gyaAddAdminUser: {
        url: "/gya/add-user",
        screen: AddGYAAdminScreen
    }
};

export default routes;
