import React from "react";
import { IPersonLite } from "../../../../../../../../api/_types";
import { Row, Col, Form } from "react-bootstrap";

interface IProps {
    contact: IPersonLite;
}

export default function ContactLiteCard(props: IProps) {
    const { contact } = props;
    return (
        <div data-testid="contactcard-lite">
            <Row>
                <Col>
                    <Form.Label>
                        <strong>Name</strong>
                    </Form.Label>
                    <p>{contact.name}</p>
                </Col>
                <Col>
                    <Form.Label>
                        <strong>Email</strong>
                    </Form.Label>
                    <p>
                        <a href={`mailto:${contact.email}`}>{contact.email}</a>
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label>
                        <strong>Telephone</strong>
                    </Form.Label>
                    <p>{contact.tel}</p>
                </Col>
                <Col />
            </Row>
        </div>
    );
}
