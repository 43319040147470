import React from "react";
import { FC } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";

interface IProps {
    exportCsv: () => Promise<void>;
}

const PropertyListActions: FC<IProps> = ({ exportCsv }) => {
    return (
        <DropdownButton
            id="property-list-actions"
            variant="light"
            title="Actions"
            drop="start"
        >
            <Dropdown.Item onClick={exportCsv}>Export</Dropdown.Item>
        </DropdownButton>
    );
};

export default PropertyListActions;
