import React from "react";
import { Form } from "react-bootstrap";
import { IPropertyTimelineEntity } from "../../../../../../../api/_types";
import {
    IPropertyTimelineEntityState,
    TIMELINE_STATE_NAMES
} from "../../../../../../../redux/_types/property/types";

interface IProps {
    timelineItem: IPropertyTimelineEntity;
    checkboxState: IPropertyTimelineEntityState;
    index: number;
    editingStates: boolean;
    disabled?: boolean;
    onChangeItemState: (
        timelineEntity: IPropertyTimelineEntity,
        newState: IPropertyTimelineEntityState
    ) => void;
}

export default function StateRadio(props: IProps) {
    const {
        timelineItem,
        index,
        checkboxState,
        editingStates,
        onChangeItemState,
        disabled
    } = props;
    return (
        <Form.Check
            key={`custom-${timelineItem.key}-${index}`}
            custom={true}
            type="radio"
            id={`custom-${timelineItem.key}-${index}`}
            name={`custom-${timelineItem.key}`}
            disabled={editingStates === false || disabled === true}
            label={TIMELINE_STATE_NAMES[checkboxState]}
            onChange={onChangeItemState.bind(null, timelineItem, checkboxState)}
            value={checkboxState}
            checked={checkboxState === timelineItem.state}
        />
    );
}
