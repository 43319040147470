export default {
    decodeSessionInfoCookie: (cookieString: string) => {
        const decoded = decodeURIComponent(cookieString);
        try {
            return JSON.parse(decoded);
        } catch (e) {
            throw e;
        }
    }
};
