import React, { Component, CSSProperties } from "react";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import userAvatarStyles from "./userAvatarStyles";
import { IUser } from "../../../../../../api/_types";
import UserAvatar from "../../components/Avatar";

interface IProps {
    user?: IUser;
    avatarContainerStyle?: CSSProperties
    rounded?: boolean;
}
interface IState {}

class UserAvatarContainer extends Component<IProps, IState> {
    render() {
        const { user, avatarContainerStyle = {}, rounded}  = this.props;

        if (user === undefined) {
            return null;
        }

        return (
            <span
                style={{
                    display: "inline-block",
                    marginRight: userAvatarStyles.userPanelPhoto.marginRight
                }}
            >
                <UserAvatar user={user} size={80} containerStyle={avatarContainerStyle} rounded={rounded}/>
            </span>
        );
    }
}

const mapStateToProps = ({ user }: IStore) => ({
    user: user.me
});

export default connect(
    mapStateToProps,
    {}
)(UserAvatarContainer);
