import React, { Component } from "react";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import EditBranchTopNav from "../../../branch/containers/EditBranchTopNav";
import BranchTimeline from "../../containers/BranchTimeline";
import TabBody from "../../../../../_sharedComponents/TabBody";

interface IProps {}

export default class BranchTimelineScreen extends Component<IProps> {
    render() {
        return (
            <PageWrap>
                <EntityTitle entityName={"Branch Timeline"} />
                <EditBranchTopNav />
                <TabBody>
                    <p>
                        This template holds the branch's default property sales
                        timeline values.
                    </p>
                    <p>
                        Changes made here will only affect data created after
                        this point.
                    </p>
                    <BranchTimeline />
                </TabBody>
            </PageWrap>
        );
    }
}
