import React, { Component, FormEvent } from "react";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import { Col, Button, Form } from "react-bootstrap";
import FormContainer from "../../../../../_sharedComponents/FormContainer";

interface IProps {
    formData: {
        password: string;
        passwordConfirm: string;
    };
    savePending: boolean;
    saveSuccess: boolean;
    saveError: boolean;
    onChangeInput: (key: string) => (e: FormEvent<any>) => void;
    handleFormSubmit: () => void;
    resetFormData: () => void;
}

interface IState {}

export default class AccountActivationForm extends Component<IProps, IState> {
    render() {
        const {
            saveError,
            savePending,
            formData,
            handleFormSubmit,
            onChangeInput,
            resetFormData
        } = this.props;
        const { password, passwordConfirm } = formData;
        return (
            <LoadingContainer loading={savePending}>
                <FormContainer
                    actions={
                        <Button size="lg" onClick={handleFormSubmit}>
                            Save your new password
                        </Button>
                    }
                    showError={saveError}
                    error={{
                        heading: "Error updating password",
                        message: "Please try again."
                    }}
                    onFeedbackMessageClose={resetFormData}
                    useSecondaryActionBar={true}
                >
                    <Form.Row>
                        <Col md={6}>
                            <h4>You need to reset your password</h4>
                            <p>
                                Because we sent your password via email, it's
                                mandatory for your own security that your
                                password is changed before proceeding to use the
                                application.
                            </p>
                            <p>
                                Once you have done this, you'll be directed to
                                the application.
                            </p>
                            <Form.Group id="createPropertyForm.password">
                                <Form.Label>Your new password</Form.Label>
                                <Form.Control
                                    type="password"
                                    onChange={onChangeInput("password")}
                                    value={password}
                                />
                            </Form.Group>
                            <Form.Group id="createPropertyForm.confirmPassword">
                                <Form.Label>
                                    Confirm your new password
                                </Form.Label>
                                <Form.Control
                                    type="password"
                                    onChange={onChangeInput("passwordConfirm")}
                                    value={passwordConfirm}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </FormContainer>
            </LoadingContainer>
        );
    }
}
