import React, { Component } from "react";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import UserProfile from "../../components/UserProfile";
import {
    updateMe,
    resetUpdateMeState,
    deleteAccount,
    deleteUserPhoto
} from "../../../../../../redux/api/user";
import { IUser } from "../../../../../../api/_types";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import { logout } from "../../../../../../redux/api/auth";
import DeletePhotoPrompt from "../../../../../_sharedComponents/DeletePhotoPrompt";

interface IProps {
    me?: IUser;
    updateMeStatus: AsyncActionState;
    deleteMeStatus: AsyncActionState;
    deletePhotoStatus: AsyncActionState;
    logout: () => void;
    resetUpdateMeState: () => void;
    deleteAccount: () => void;
    updateMe: (user: IUser, file?: File) => void;
    deleteUserPhoto: () => void;
}
interface IState {
    showDeletePrompt: boolean;
    showDeletePhotoPrompt: boolean;
}

class EditUserProfile extends Component<IProps, IState> {
    state: IState = {
        showDeletePrompt: false,
        showDeletePhotoPrompt: false
    };

    triggerDeletePhoto = () => this.setState({ showDeletePhotoPrompt: true });
    cancelDeletePhoto = () => this.setState({ showDeletePhotoPrompt: false });

    deletePhotoSuccess = () => {
        this.cancelDeletePhoto();
        setTimeout(() => {
            alert("Your photo was removed successfully.");
        });
    };

    deletePhotoError = () => {
        this.cancelDeletePhoto();
        setTimeout(() => {
            alert("Something went wrong deleting your photo.");
        });
    };

    handleDeleteAccount = () => this.props.deleteAccount();

    triggerDeleteAccount = () => this.setState({ showDeletePrompt: true });

    cancelDeleteToday = () =>
        this.setState({
            showDeletePrompt: false
        });

    onDeleteAcccountSuccess = () => {
        this.setState({ showDeletePrompt: false });
        this.props.logout();
    };

    onDeleteAcccountError = () => {
        alert(
            "Something went wrong deleting your account, please contact an administrator."
        );
        this.setState({
            showDeletePrompt: false
        });
    };

    render() {
        const { showDeletePrompt } = this.state;
        const {
            me,
            updateMe,
            updateMeStatus,
            resetUpdateMeState,
            deleteMeStatus,
            deletePhotoStatus
        } = this.props;

        const { showDeletePhotoPrompt } = this.state;

        // @todo integrate loading screen
        if (me === undefined) {
            return null;
        }

        return (
            <>
                <EntityTitle entityName={me.firstName + " " + me.lastName} />
                <hr />
                <UserProfile
                    user={me}
                    save={updateMe}
                    saveError={updateMeStatus === AsyncActionState.Error}
                    savePending={updateMeStatus === AsyncActionState.Pending}
                    saveSuccess={updateMeStatus === AsyncActionState.Success}
                    resetUpdateMeState={resetUpdateMeState}
                    deleteAccount={this.triggerDeleteAccount}
                    deleteMeStatus={deleteMeStatus}
                    deletePhoto={this.triggerDeletePhoto}
                />

                <DeletePhotoPrompt
                    show={showDeletePhotoPrompt}
                    deleteFunction={this.props.deleteUserPhoto}
                    cancel={this.cancelDeletePhoto}
                    deleteStatus={deletePhotoStatus}
                    onSuccess={this.deletePhotoSuccess}
                    onError={this.deletePhotoError}
                />

                <ActionPrompt
                    title="Delete your account."
                    bodyText="Are you sure you want to do this? This action cannot be undone, your account will be permanently deleted."
                    confirmTextLabel="To confirm you want to do this, please type both your first and last name into the text box below."
                    show={showDeletePrompt}
                    actions={[
                        {
                            title: "Confirm",
                            btnVariant: "danger",
                            onClick: this.handleDeleteAccount,
                            onSuccess: this.onDeleteAcccountSuccess,
                            onError: this.onDeleteAcccountError,
                            asyncStatus: deleteMeStatus,
                            useConfirm: true
                        }
                    ]}
                    confirmText={me.fullName}
                    cancel={this.cancelDeleteToday}
                />
            </>
        );
    }
}

const mapStateToProps = ({ user }: IStore) => ({
    me: user.me,
    updateMeStatus: user.updateMeStatus,
    deleteMeStatus: user.deleteMeStatus,
    deletePhotoStatus: user.deletePhotoStatus
});

export default connect(
    mapStateToProps,
    {
        updateMe,
        resetUpdateMeState,
        logout,
        deleteAccount,
        deleteUserPhoto
    }
)(EditUserProfile);
