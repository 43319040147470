import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { IProperty } from "../../../../../../../api/_types";
import Alert from "../../../../../../_sharedComponents/Icons/v2/Alert";

interface IProps {
    property: IProperty;
    warningCount: number;
    hasRedAlert?: boolean;
    onClick: (property: IProperty) => void;
}

const WarningButton: FC<IProps> = ({
    warningCount,
    hasRedAlert,
    property,
    onClick
}) => {
    let alertBtnVariant: "success" | "warning" | "danger" = "success";

    if (warningCount > 0) {
        //@ts-ignore
        if (hasRedAlert === true) {
            alertBtnVariant = "danger";
        } else {
            alertBtnVariant = "warning";
        }
    }

    return (
        <>
            {warningCount > 0 ? (
                <span>
                    <Button
                        size="sm"
                        variant={alertBtnVariant}
                        className="w-100 d-flex align-items-center justify-content-center"
                        onClick={() => onClick(property)}
                    >
                         <Alert
                             size={15}
                             fill={hasRedAlert ? "#fff" : "#000"}
                         />
                        <span style={{marginLeft: 5}}>
                            View {warningCount}
                        </span>
                    </Button>
                </span>
            ) : (
                <Button
                    size="sm"
                    variant={alertBtnVariant}
                    className="w-100"
                    style={{ pointerEvents: "none", display: "flex", alignItems: "center", justifyContent: "center" }}
                >

                    <FontAwesomeIcon
                        style={{
                            textAlign: "center",
                            marginRight: 5
                        }}
                        icon={faCheck}
                        color={"#fff"}
                    />
                    None
                </Button>
            )}
        </>
    );
};

export default WarningButton;
