import React, { FC } from "react";
import { Form } from "react-bootstrap";
import {
    ICompanyContactPOC,
    ICompany,
    IBranch,
    IPropertyChainItem,
    ICompanyContact
} from "../../../../../../../api/_types";
import PopulatedContact from "../../../../contacts/components/PopulatedContact";
import ContactCard from "../../../../contacts/containers/ContactCard";

interface IProps {
    item: IPropertyChainItem;
    branch: IBranch;
    company: ICompany;
    hasPropertyRef?: boolean;
    updateItem: (
        item: IPropertyChainItem,
        key: keyof IPropertyChainItem,
        value: any
    ) => void;
}

const ChainAgent: FC<IProps> = ({
    item,
    branch,
    company,
    hasPropertyRef,
    updateItem
}) => {
    const companyContact = (company as any) as ICompanyContact;
    const key = item?._id|| new Date().getTime().toString();
    let output;
    

    if (item.thisProperty === true || hasPropertyRef === true) {
        output = (
            <PopulatedContact
                key={key}
                selectedPOC={
                    {
                        ...branch,
                        companyContact
                    } as ICompanyContactPOC
                }
            />
        );
    } else {
        output = (
            <ContactCard
                key={key}
                onSelect={(contactSet) => updateItem(item, "agent", contactSet)}
                existingPOC={item.agent.poc}
            />
        );
    }
    return (
        <>
            <h5>Agent</h5>
            <Form.Group className="mt-1">{output}</Form.Group>
        </>
    );
};

export default ChainAgent;
