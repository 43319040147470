export default {
    userPanelContent: {
        display: "inline-block",
        verticalAlign: "top",
        lineHeight: "5px",
        color: "rgb(58,58,58)"
    },

    userPanelText: {
        marginTop: 15
    },
    small: {
        fontSize: "0.7em"
    }
};
