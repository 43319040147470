import React, { Component } from "react";
import ManagePropertyForm from "../../components/ManagePropertyForm";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import {
    saveProperty,
    getPropertyById,
    resetViewingProperty,
    deleteProperty,
    resetCreatePropertyVendorRef,
    resetSaleStatus,
    restoreSaleStatus,
    deleteRestoreSale,
    deletePhoto
} from "../../../../../../redux/api/property";
import { resetManagePropertyFormState } from "../../../../../../redux/api/property";
import { IProperty, IUser } from "../../../../../../api/_types";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import { resendEmailVerification, resetResendEmailVerificationStatus, resetSearchVendorResults } from "../../../../../../redux/api/user";
import { withRouter, RouteComponentProps } from "react-router-dom";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import DeletePhotoPrompt from "../../../../../_sharedComponents/DeletePhotoPrompt";


interface IProps {
    propertyId: string;
    companyId: string;
    branchId: string;
    viewingProperty?: IProperty;
    createPropertyStatus: AsyncActionState;
    deletePropertyStatus: AsyncActionState;
    resendEmailVerificationStatus: AsyncActionState;
    getPropertyById: (_id: string, branchId?: string) => void;
    saveProperty: (property: IProperty, file?: File) => void;
    resetManagePropertyFormState: () => void;
    resetViewingProperty: () => void;
    deleteProperty: (propertyId: string) => void;
    resetCreatePropertyVendorRef: () => void;
    resetSearchVendorResults: () => void;
    resendEmailVerification: (token: string) => void;
    resetResendEmailVerificationStatus: () => void;
    deletePhotoStatus: AsyncActionState;
    deletePhoto: () => void;
}

interface IState {
    showDeletePrompt: boolean;
    showDeletePhotoPrompt: boolean;
}

class EditPropertyContainer extends Component<
    IProps & RouteComponentProps,
    IState
> {
    state: IState = {
        showDeletePrompt: false,
        showDeletePhotoPrompt: false
    };

    componentDidMount () {
        this.props.resetResendEmailVerificationStatus();
    }

    triggerDeletePhoto = () => this.setState({ showDeletePhotoPrompt: true });
    cancelDeletePhoto = () => this.setState({ showDeletePhotoPrompt: false });

    deletePhotoSuccess = () => {
        this.cancelDeletePhoto();
        setTimeout(() => {
            alert("Property photo was removed successfully.");
        });
    };

    deletePhotoError = () => {
        this.cancelDeletePhoto();
        setTimeout(() => {
            alert("Something went wrong deleting the properties photo.");
        });
    };

    handleDeleteProperty = () => {
        const {
            viewingProperty,
            deleteProperty = Function.prototype
        } = this.props;
        if (
            viewingProperty === undefined ||
            viewingProperty._id === undefined
        ) {
            return;
        }

        deleteProperty(viewingProperty._id);
    };

    triggerDelete = () => {
        this.setState({
            showDeletePrompt: true
        });
    };
    cancelDelete = () => {
        this.setState({
            showDeletePrompt: false
        });
    };

    resendVendorEmailVerification = () => {
        const {viewingProperty, resendEmailVerification} = this.props;
        let token = (viewingProperty?.owner as IUser)?.accountActivationToken;
        if (viewingProperty && token) {
            resendEmailVerification(token);
        }
    }

    render() {
        const {
            viewingProperty,
            saveProperty,
            createPropertyStatus,
            resetManagePropertyFormState,
            deletePropertyStatus,
            resetSearchVendorResults,
            resetCreatePropertyVendorRef,
            deletePhotoStatus,
            resendEmailVerificationStatus,
            
        } = this.props;
        const {
            showDeletePrompt
        } = this.state;

        if (!viewingProperty) {
            return null;
        }

        return (
            <LoadingContainer
                loading={
                    createPropertyStatus === AsyncActionState.Pending ||
                    deletePropertyStatus === AsyncActionState.Pending
                }
            >
                <ManagePropertyForm
                    property={viewingProperty}
                    deleteProperty={this.triggerDelete}
                    createProperty={saveProperty}
                    createPropertyPending={
                        createPropertyStatus === AsyncActionState.Pending
                    }
                    createPropertyError={
                        createPropertyStatus === AsyncActionState.Error
                    }
                    createPropertySuccess={
                        createPropertyStatus === AsyncActionState.Success
                    }
                    resetManagePropertyFormState={resetManagePropertyFormState}
                    resetPropertyVendorRef={resetCreatePropertyVendorRef}
                    resetSearchVendorResults={resetSearchVendorResults}
                    deletePhoto={this.triggerDeletePhoto}
                    resendVendorEmailVerification={this.resendVendorEmailVerification}
                    resendVendorEmailVerificationStatus={resendEmailVerificationStatus}
                    resetResendEmailVerificationStatus={this.props.resetResendEmailVerificationStatus}
                    
                />
                <ActionPrompt
                    actions={[
                        {
                            title: "Delete",
                            btnVariant: "danger",
                            onClick: this.handleDeleteProperty,
                            asyncStatus: deletePropertyStatus,
                            onSuccess: () => this.props.history.goBack(),
                            useConfirm: true
                        }
                    ]}
                    show={showDeletePrompt}
                    cancel={this.cancelDelete}
                    title="Delete this property"
                    bodyText="Are you sure you want to delete this property? This action cannot be undone."
                    confirmText={viewingProperty.address.line1}
                    confirmTextLabel="Please enter the first line of address to delete this property"
                />
                

                <DeletePhotoPrompt
                    show={this.state.showDeletePhotoPrompt}
                    deleteFunction={this.props.deletePhoto}
                    cancel={this.cancelDeletePhoto}
                    deleteStatus={deletePhotoStatus}
                    onSuccess={this.deletePhotoSuccess}
                    onError={this.deletePhotoError}
                />

            </LoadingContainer>
        );
    }
}

const mapStateToProps = ({ property, user }: IStore) => ({
    viewingProperty: property.viewingProperty,
    createPropertyStatus: property.createPropertyStatus,
    deletePropertyStatus: property.deletePropertyStatus,
    deletePhotoStatus: property.deletePhotoStatus,
    resendEmailVerificationStatus: user.resendEmailVerificationStatus
});

export default connect(
    mapStateToProps,
    {
        saveProperty,
        getPropertyById,
        deleteProperty,
        resetManagePropertyFormState,
        resetViewingProperty,
        resetCreatePropertyVendorRef,
        resetSearchVendorResults,
        resetSaleStatus,
        restoreSaleStatus,
        deleteRestoreSale,
        deletePhoto,
        resendEmailVerification,
        resetResendEmailVerificationStatus,
        
    }
)(withRouter<any, any>(EditPropertyContainer));
