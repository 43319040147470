import * as actions from "../../actions/property/notes";
import { AsyncActionState } from "../../utils/asyncAction";
import { IPropertyNote } from "../../../api/_types";

export interface IPropertyNotesState {
    noteToEdit?: IPropertyNote;
    createNoteStatus: AsyncActionState;
    updateNoteStatus: AsyncActionState;
    deleteNoteStatus: AsyncActionState;
}

const initialState: IPropertyNotesState = {
    noteToEdit: undefined,
    createNoteStatus: AsyncActionState.Reset,
    updateNoteStatus: AsyncActionState.Reset,
    deleteNoteStatus: AsyncActionState.Reset
};

export default function propertyNotesReducer(
    state: IPropertyNotesState = initialState,
    action:
        | actions.SetNoteToEdit
        | actions.CreateNoteStatus
        | actions.DeleteNoteStatus
        | actions.UpdateNoteStatus
) {
    switch (action.type) {
        case actions.PropertyNotesTypes.SET_NOTE_TO_EDIT: {
            return Object.assign({}, state, {
                noteToEdit: action.payload
            } as IPropertyNotesState);
        }
        case actions.PropertyNotesTypes.CREATE_NOTE_STATUS: {
            return Object.assign({}, state, {
                createNoteStatus: action.payload
            } as IPropertyNotesState);
        }
        case actions.PropertyNotesTypes.UPDATE_NOTE_STATUS: {
            return Object.assign({}, state, {
                updateNoteStatus: action.payload
            } as IPropertyNotesState);
        }
        case actions.PropertyNotesTypes.DELETE_NOTE_STATUS: {
            return Object.assign({}, state, {
                deleteNoteStatus: action.payload
            } as IPropertyNotesState);
        }

        default: {
            return state;
        }
    }
}
