import React, { FC, FormEvent } from "react";
import { Form } from "react-bootstrap";
import { IProperty, IPropertyChainItem } from "../../../../../../../api/_types";

interface IProps {
    item: IPropertyChainItem;
    title?: string;
    updateItem: (
        item: IPropertyChainItem,
        key: keyof IPropertyChainItem,
        value: any
    ) => void;
}

const ChainPropertyAddress: FC<IProps> = ({ item, title, updateItem }) => {
    let readonly = false;
    let plaintext = false;

    if (item.thisProperty  === true || (item.propertyRef as IProperty)?._id !== undefined  ) {
        readonly = true;
        plaintext = true;
    }
    return (
        <Form.Group>
            <Form.Label>
                <strong>
                    Enter property address
                </strong>
            </Form.Label>
            <Form.Control
                as="textarea"
                rows={2}
                plaintext={plaintext}
                readOnly={readonly}
                onChange={(e: FormEvent<HTMLInputElement>) =>
                    updateItem(item, "propertyName", e.currentTarget.value)
                }
                value={title}
            />
        </Form.Group>
    );
};

export default ChainPropertyAddress;
