import React, { Component, FormEvent } from "react";
import { Button, Form, Col } from "react-bootstrap";
import styles from "./styles";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import { IUser } from "../../../../../../api/_types";
import _ from "lodash";

interface IProps {
    user: IUser;
    readonly?: boolean;
    userTypes?: UserTypes[];
    validateFields?: boolean;
    clearUser?: () => void;
    updateUserFirstName?: (event: FormEvent<any>) => void;
    updateUserLastName?: (event: FormEvent<any>) => void;
    updateUserEmail?: (event: FormEvent<any>) => void;
    updateUserTel?: (event: FormEvent<any>) => void;
    updateUserPassword?: (event: FormEvent<any>) => void;
    updateUserConfirmPassword?: (event: FormEvent<any>) => void;
    updateUserType?: (event: FormEvent<any>) => void;
}

interface IState {
    confirmPassword: string;
}

export default class UserCard extends Component<IProps, IState> {
    state: IState = {
        confirmPassword: ""
    };

    render() {
        const {
            clearUser,
            user,
            userTypes,
            readonly = false,
            updateUserFirstName,
            updateUserLastName,
            updateUserEmail,
            updateUserPassword,
            updateUserConfirmPassword,
            updateUserType,
            updateUserTel,
            validateFields = false
        } = this.props;
        return (
            <div style={styles.container}>
                {userTypes !== undefined && updateUserType !== undefined && (
                    <Form.Group controlId="user-card-type">
                        <Form.Label>Type</Form.Label>
                        <Form.Control
                            as="select"
                            value={user.type}
                            onChange={updateUserType}
                        >
                            {userTypes.map((type, index) => (
                                <option key={`user-type-${index}`} value={type}>
                                    {_.startCase(_.camelCase(type))}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                )}

                <Form.Row>
                    <Col md="6">
                        <Form.Group controlId="user-card-email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                value={user.email}
                                readOnly={readonly}
                                onChange={updateUserEmail}
                                required={validateFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="user-card-email">
                            <Form.Label>Telephone</Form.Label>
                            <Form.Control
                                type="tel"
                                value={user.tel}
                                readOnly={readonly}
                                onChange={updateUserTel}
                                required={false}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col md="6">
                        <Form.Group controlId="user-card-first-name">
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstName"
                                value={user.firstName}
                                readOnly={readonly}
                                onChange={updateUserFirstName}
                                required={validateFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="user-card-last-name">
                            <Form.Label>Last name</Form.Label>

                            <Form.Control
                                type="text"
                                value={user.lastName}
                                name="user.lastName"
                                readOnly={readonly}
                                onChange={updateUserLastName}
                                required={validateFields}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>

                {updateUserPassword !== undefined && (
                    <>
                        <p>
                            If you do not specify a password, the new user will
                            be sent an email containing their new temporary
                            password, upon logging in they will be prompted to
                            change this.
                        </p>
                        <Form.Row>
                            <Col md="6">
                                <Form.Group controlId="user-card-password">
                                    <Form.Label>Password</Form.Label>

                                    <Form.Control
                                        type="password"
                                        value={user.password}
                                        readOnly={readonly}
                                        name="user.password"
                                        onChange={updateUserPassword}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group controlId="user-card-confirm-password">
                                    <Form.Label>Confirm password</Form.Label>

                                    <Form.Control
                                        type="password"
                                        value={user.confirmPassword}
                                        readOnly={false}
                                        onChange={updateUserConfirmPassword}
                                        name="user.confirmPassword"
                                        isInvalid={
                                            (user.password !== "" &&
                                                user.confirmPassword === "") ||
                                            user.confirmPassword !==
                                                user.password
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </>
                )}

                {clearUser !== undefined && (
                    <Button
                        variant="danger"
                        size="sm"
                        type="button"
                        onClick={clearUser}
                    >
                        Clear
                    </Button>
                )}
            </div>
        );
    }
}
