import moment, { Moment } from "moment";
import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { IPropertyOffer } from "../../../../../../../api/_types";
import DatePicker from "../../../../../../_sharedComponents/DatePicker";

type OfferFields = Pick<IPropertyOffer, "when">;

interface IProps extends OfferFields {
    onChange: (key: string) => (e: any) => void;
}

const WhenOfferSubmitted: FC<IProps> = ({ when, onChange }) => {
    const onChangeDate = (e: string | Moment) => {
        let momentTime = typeof e === "string" ? moment(e) : e;
        onChange("when")({
            currentTarget: {
                value: momentTime.toISOString()
            }
        } as any);
    };

    return (
        <Form.Group controlId="effectiveDate">
            <Form.Label>When was the offer submitted?</Form.Label>

            <DatePicker
                timeFormat={false}
                onChange={onChangeDate}
                defaultValue={moment(when)}
            />
        </Form.Group>
    );
};

export default WhenOfferSubmitted;
