import React from "react";
import { IPropertyTimelineEntity } from "../../../../../../../api/_types";
import {
    IPropertyTimelineEntityState,
    TIMELINE_STATE_NAMES
} from "../../../../../../../redux/_types/property/types";
import DataLabel from "../DataLabel";
import DateLabel from "../DateLabel";

interface IProps {
    item?: IPropertyTimelineEntity;
}

export default function DebugFields(props: IProps) {
    const { item } = props;

    if (item === undefined) {
        return null;
    }

    const stateOutput =
        TIMELINE_STATE_NAMES[item.state as IPropertyTimelineEntityState];
    return (
        <div style={{ color: "#0c81af" }}>
            <label className="mt-2">
                <strong>Debug fields </strong>
            </label>

            {item.state && <DataLabel data={stateOutput} label="State" />}
            {item.warningDate !== undefined && (
                <DateLabel
                    date={item.warningDate}
                    label="Warning on"
                    momentFormat="DD/MM/YYYY hh:mma"
                />
            )}
            {item.daysToComplete !== undefined && (
                <DataLabel
                    data={item.daysToComplete}
                    label="Days to complete"
                />
            )}

            {item.startedOn !== undefined && (
                <DateLabel date={item.startedOn} label="Started on" />
            )}
            {item.completedOn !== undefined && (
                <DateLabel date={item.completedOn} label="Completed on" />
            )}
            {item.actioned !== undefined && (
                <DateLabel date={item.actioned} label="Last actioned" />
            )}
        </div>
    );
}
