import React, { Component } from "react";
import { connect } from "react-redux";
import { IBranch } from "../../../../../../api/_types";
import { IStore } from "../../../../../../redux/store";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import TabBody from "../../../../../_sharedComponents/TabBody";
import EditBranchTopNav from "../../containers/EditBranchTopNav";
import EditMemberForm from "../../containers/EditMemberForm";

interface IProps {
    branch?: IBranch;
}
interface IState {}

class EditUserScreen extends Component<IProps, IState> {
    render() {
        const { branch } = this.props;

        if (branch === undefined) {
            return null;
        }

        return (
            <PageWrap>
                <EntityTitle entityName={branch.name} />

                <EditBranchTopNav />
                <TabBody>
                    <EditMemberForm />
                </TabBody>
            </PageWrap>
        );
    }
}

const mapStateToProps = ({ branch }: IStore) => ({
    branch: branch.viewingBranch
});

export default connect(
    mapStateToProps,
    {}
)(EditUserScreen);
