import React, { Component } from "react";
import UserCard from "../../../user/components/UserCard";
import { FormEventType } from "../../../../../../lib/types/forms";
import { Button } from "react-bootstrap";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import { IUser } from "../../../../../../api/_types";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";

interface IProps {
    member: IUser;
    updateField: (field: string) => (event: FormEventType) => void;
    saveUser: () => void;
    deleteUser?: () => void;
    saveUserStatus: AsyncActionState;
    resetSaveUserState: () => void;
}
interface IState {}

export default class AddMemberForm extends Component<IProps, IState> {
    render() {
        const {
            member,
            updateField,
            saveUser,
            deleteUser,
            saveUserStatus,
            resetSaveUserState
        } = this.props;
        return (
            <FormContainer
                showError={saveUserStatus === AsyncActionState.Error}
                showSuccess={saveUserStatus === AsyncActionState.Success}
                success={{
                    heading: "User details saved",
                    message: "Your changes have been saved successfully."
                }}
                error={{
                    heading: "Something went wrong",
                    message: "Your changes were not saved successfully."
                }}
                onFeedbackMessageClose={resetSaveUserState}
                actions={
                    <>
                        <hr />
                        <Button
                            variant="primary"
                            className="mr-2"
                            onClick={saveUser}
                        >
                            Save
                        </Button>

                        {deleteUser !== undefined && (
                            <Button variant="danger" onClick={deleteUser}>
                                Delete user
                            </Button>
                        )}
                    </>
                }
            >
                <>
                    <UserCard
                        user={member}
                        userTypes={[
                            UserTypes.BRANCH_ADMIN,
                            UserTypes.BRANCH_STAFF
                        ]}
                        updateUserEmail={updateField("email")}
                        updateUserFirstName={updateField("firstName")}
                        updateUserLastName={updateField("lastName")}
                        updateUserPassword={
                            member._id === undefined
                                ? updateField("password")
                                : undefined
                        }
                        updateUserTel={updateField("tel")}
                        updateUserConfirmPassword={updateField(
                            "confirmPassword"
                        )}
                        updateUserType={
                            member._id === undefined
                                ? updateField("type")
                                : undefined
                        }
                    />
                </>
            </FormContainer>
        );
    }
}
