import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Card, Col, Collapse, Row, Form } from "react-bootstrap";
import { ISurveyData } from "../../../../../../api/_types";
import {
    IPropertyTimelineEntityState,
    IPropertyTimelineEntityType
} from "../../../../../../redux/_types/property/types";
import SurveyFields from "../Timeline/AddSurvey/SurveyFields";
import CompletedOn from "./CompletedOn";
import TimelineItemDescripion from "./Description";
import DTCInput from "./DTCInput";
import TimelineItemHeader from "./Header";
import StateIcon from "./StateIcon";
import StateRadio from "./StateRadio";
import "./timelineItemStyleSheet.css";
import { IProps, IState } from "./types";
import DebugFields from "./DebugFields";
import NewFeedbackNote from "./NewFeedbackNote";
import GoToVendorFeedback from "./GoToVendorFeedback";
import { ButtonGroup } from "react-bootstrap";
import { TimelineKeys } from "../../../../../../api/modules/property";
import DatePicker from "../../../../../_sharedComponents/DatePicker";
import moment, { Moment } from "moment";

export default class TimelineItem extends Component<IProps, IState> {
    size: number = 112;
    colors = {
        [IPropertyTimelineEntityType.FINANCIAL]: "#FFCD00",
        [IPropertyTimelineEntityType.SURVEY]: "#6f6f6f",
        [IPropertyTimelineEntityType.COMPLETING]: "#6f6f6f",
        [IPropertyTimelineEntityType.SELLING]: "#6f6f6f",
        [IPropertyTimelineEntityType.LEGAL]: "#00A9CE",
        blocked: "#F24236"
    };
    state: IState = {
        collapsed:
            this.props.defaultCollapsedValue !== undefined
                ? this.props.defaultCollapsedValue
                : true
    };

    handleHeaderClick = () => {
        if (this.props.collapsible === true) {
            this.setState({
                collapsed: !this.state.collapsed
            });
        }
    };

    render() {
        const {
            item,
            editing,
            editingTemplate = false,
            editingDescription = false,
            editingTitle = false,
            editingStates = false,
            title,
            description,
            collapsible,
            hasNotes,
            onChangeDaysTillComplete,
            showNoteInput,
            completionDateSet
        } = this.props;
        let additionalOuterClasses = "";

        const isInProgress =
            item.state === IPropertyTimelineEntityState.IN_PROGRESS;

        if (isInProgress === true) {
            additionalOuterClasses = "timeline-element-in-progress";
        }

        const timelineItemStates = [
            // IPropertyTimelineEntityState.NOT_STARTED,
            IPropertyTimelineEntityState.IN_PROGRESS,
            IPropertyTimelineEntityState.COMPLETE,
            IPropertyTimelineEntityState.BLOCKED
        ];

        // Only financial items can be N/A status
        // if (item.type === IPropertyTimelineEntityType.FINANCIAL) {
        //     timelineItemStates.push(IPropertyTimelineEntityState.NA);
        // }

        return (
            <Card style={{ marginBottom: 25 }}>
                <Card.Header
                    style={{ fontWeight: 600 }}
                    onClick={this.handleHeaderClick}
                    className="d-flex align-items-center "
                >
                    <TimelineItemHeader
                        item={item}
                        title={title}
                        editingTitle={editingTitle}
                        collapsible={collapsible}
                        collapsed={this.state.collapsed}
                        onChangeTitle={this.props.onChangeTitle}
                    />
                </Card.Header>
                <Collapse in={this.state.collapsed}>
                    <Card.Body>

                        {editingTemplate === false && item.key === TimelineKeys.Exchanged && (
                            <>
                                <small>Set a completion date before completing this step. </small>
                                <DatePicker
                                    className="mb-2"
                                    timeFormat={false}
                                    onChange={(e: string | Moment) => {
                                        this.props.onChangeCompletionDate?.(moment(e).toDate());
                                    }}
                                    defaultValue={this.props.completionDate ? moment(this.props.completionDate) : undefined}
                                    inputProps={{autoComplete: "false"}}
                                />
                            </>
                        )}

                        <Row>
                            <Col
                                className={`${additionalOuterClasses} d-flex align-items-center justify-content-center`}
                            >
                                <StateIcon
                                    colors={this.colors}
                                    item={item}
                                    size={this.size}
                                />
                            </Col>
                            <Col>

                               
                                
                                {timelineItemStates.map(
                                    (
                                        itemState: IPropertyTimelineEntityState,
                                        index
                                    ) => (
                                        <StateRadio
                                            timelineItem={item}
                                            checkboxState={itemState}
                                            index={index}
                                            onChangeItemState={this.props.onChangeItemState.bind(
                                                null,
                                                item,
                                                itemState
                                            )}
                                            editingStates={editingStates}
                                            key={`sr-${itemState}`}
                                            disabled={item.key === TimelineKeys.Exchanged  && completionDateSet === false &&  itemState === IPropertyTimelineEntityState.COMPLETE}
                                        />
                                    )
                                )}
                                {item.type === IPropertyTimelineEntityType.SURVEY && ( 

                                    <Form.Check
                                        label="Reports required"
                                        id={`a-reports-${item.key}`}
                                        name={`awaiting-reports-${item.key}`}
                                        data-testid="add-survey-awaiting-reports"
                                        onClick={() => {
                                            this.props.onChangeSurveyData(
                                                item,
                                                "awaitingReports",
                                                !item.surveyData.awaitingReports
                                            );
                                        }}
                                        custom={true}
                                        checked={item?.surveyData.awaitingReports}
                                        disabled={
                                            item.state ===
                                            IPropertyTimelineEntityState.COMPLETE
                                        }
                                    />
                                )}
                                {editingTemplate === true && (
                                    <DTCInput
                                        onChange={onChangeDaysTillComplete}
                                        item={item}
                                    />
                                )}
                                {item.completedOn !== undefined && (
                                    <CompletedOn item={item} dateFormat="ll" />
                                )}
                            </Col>
                        </Row>
                        {editing !== true && (
                            <Row>
                                <Col>
                                    <DebugFields item={item} />
                                    {showNoteInput && (
                                        <ButtonGroup size="sm">
                                            {hasNotes === true && <GoToVendorFeedback itemKey={item.key}/>}
                                            <NewFeedbackNote  showNoteInput={showNoteInput}/>
                                        </ButtonGroup>
                                    )}
                                    
                                </Col>
                            </Row>
                        )}

                        {editingDescription === true && (
                            <Row className="mt-2">
                                <Col>
                                    <TimelineItemDescripion
                                        item={item}
                                        description={description}
                                        onChange={
                                            this.props.onChangeDescription
                                        }
                                    />
                                </Col>
                            </Row>
                        )}

                        
                        {item.type === IPropertyTimelineEntityType.SURVEY && (
                            <div className="mt-3">
                                <SurveyFields
                                    survey={item}
                                    surveyStateDispatch={(action) => {
                                        let key: keyof ISurveyData | undefined;
                                        if (action.type === "updateContact") {
                                            key = "contactPOC";
                                        } else if (
                                            action.type === "updateRef"
                                        ) {
                                            key = "reference";
                                        } else if (
                                            action.type === "updateDate"
                                        ) {
                                            key = "date";
                                        }

                                        if (key !== undefined) {
                                            this.props.onChangeSurveyData(
                                                item,
                                                key,
                                                action.payload
                                            );
                                        }
                                    }}
                                />
                                <Button
                                    variant="danger"
                                    className="mt-1"
                                    size="sm"
                                    onClick={() =>
                                        this.props.onRemoveSurvey(item)
                                    }
                                >
                                    Delete survey
                                </Button>
                            </div>
                        )}
                    </Card.Body>
                </Collapse>
            </Card>
        );
    }
}