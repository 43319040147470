import React, { Component, FormEvent } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { IPropertyOfferPayload } from "../../../../../../api/_types";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import DepositOrCashBuy from "./DepositOrCashBuy";
import Mip from "./Mip";
import OfferNotes from "./Notes";
import OfferAmount from "./OfferAmount";
import OfferEmail from "./OfferEmail";
import OfferName from "./OfferName";
import OfferStateForm from "./OfferStateForm";
import OfferTel from "./OfferTel";
import PartOfChain from "./PartOfChain";
import WhenOfferSubmitted from "./WhenOfferSubmitted";

interface IProps {
    offer: IPropertyOfferPayload;
    canAcceptOffer?: boolean;
    resetFormState: () => void;
    onChangeInputField: (stateField: string) => (event: FormEvent<any>) => void;
    save: () => void;
    saveOfferStatus: AsyncActionState;
}
interface IState {}

export default class CreateOfferForm extends Component<IProps, IState> {
    handleSubmit = (event: any) => {
        event.preventDefault();
        this.props.save();
    };

    render() {
        const {
            onChangeInputField,
            offer,
            canAcceptOffer = true,
            saveOfferStatus,
            resetFormState,
            save
        } = this.props;
        return (
            <FormContainer
                submit={this.handleSubmit}
                actions={[
                    <Button className="mr-2" variant="primary" type="submit">
                        Save
                    </Button>
                ]}
                success={{
                    heading: "Offer saved",
                    message: "The offer has been saved successfully."
                }}
                error={{
                    heading: "Something went wrong",
                    message:
                        "Something went wrong saving the offer, please try again."
                }}
                showSuccess={saveOfferStatus === AsyncActionState.Success}
                showError={saveOfferStatus === AsyncActionState.Error}
                onFeedbackMessageClose={resetFormState}
            >
                <Form.Row>
                    <Col>
                        <OfferAmount
                            offerState={offer.state}
                            amount={offer.amount}
                            onChange={onChangeInputField}
                            increasedOn={offer.increasedOn}
                            save={save}
                            amendAmount={offer.amendAmount}
                            amendReason={offer.amendReason}
                        />
                    </Col>
                    <Col>
                        <OfferStateForm
                            state={offer.state}
                            onChange={onChangeInputField}
                            canAcceptOffer={canAcceptOffer}
                        />
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col>
                        <OfferName
                            name={offer.name}
                            onChange={onChangeInputField}
                        />
                    </Col>
                    <Col>
                        <WhenOfferSubmitted
                            when={offer.when}
                            onChange={onChangeInputField}
                        />
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col>
                        <OfferTel
                            tel={offer.tel}
                            onChange={onChangeInputField}
                        />
                    </Col>
                    <Col>
                        <OfferEmail
                            email={offer.email}
                            onChange={onChangeInputField}
                        />
                    </Col>
                </Form.Row>

                <DepositOrCashBuy
                    depositSize={offer.depositSize}
                    cashBuy={offer.cashBuy}
                    onChange={onChangeInputField}
                />

                <Form.Row>
                    <Col>
                        <PartOfChain
                            chain={offer.chain}
                            onChange={onChangeInputField}
                            canAcceptOffer={canAcceptOffer}
                        />
                    </Col>
                    <Col>
                        <Mip mip={offer.mip} onChange={onChangeInputField} />
                    </Col>
                </Form.Row>

                <OfferNotes notes={offer.notes} onChange={onChangeInputField} />
            </FormContainer>
        );
    }
}
