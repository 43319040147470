import React, { Component } from "react";

interface IProps {
    content?: React.ReactNode;
    entityName: string;
}

interface IState {}

export default class EntityTitle extends Component<IProps, IState> {
    render() {
        const { content, entityName } = this.props;
        return (
            <div
                style={{
                    position: "fixed",
                    top: "0px",
                    width: "100%",
                    backgroundColor: "#fbfbfb",
                    zIndex: 21,
                    paddingTop: "5px"
                }}
            >
                <div className="d-flex flex-direction-row align-items-center mb-2">
                    <h2 className="d-inline-block mb-0">{entityName}</h2>

                    {content && content}

                </div>

            </div>
        );
    }
}
