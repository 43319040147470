import React, { Fragment, useState } from "react";
import { Button, Card, Col, Collapse, Row } from "react-bootstrap";
import {
    IBranch,
    ICompany,
    IProperty,
    IPropertyChain,
    IPropertyChainItem
} from "../../../../../../api/_types";
import { setViewingCompanyContact } from "../../../../../../redux/api/companyContacts";
import ChainAgent from "./Agent";
import ChainHeader from "./Header";
import ChainNotes from "./Notes";
import ChainPropertyAddress from "./PropertyAddress";
import ChainPropertySearch from "./PropertySearch";
import SaleTypeDropdown from "./SaleTypeDropdown";
import Separator from "./Separator";
import ChainSolicitor from "./Solicitor";
import ThisPropertyCheck from "./ThisPropertyCheck";
import TimelineChecks from "./TimelineChecks";
import ChainVendorsName from "./VendorsName";

interface IProps {
    company: ICompany;
    branch: IBranch;
    property: IProperty;
    chain: IPropertyChain;
    thisPropertyInChain: boolean;
    addNewItem: (atIndex: number) => void;
    updateItem: (
        item: IPropertyChainItem,
        key: keyof IPropertyChainItem,
        value: any
    ) => void;
    moveItem: (fromIndex: number, toIndex: number) => void;
    deleteItem: (indexToDelete: number) => void;
    deletePropertyRef: (indexToDelete: number) => void;
    addPropertyRef: (
        indexToAddPropertyRef: number,
        pendingPropertyRef: IProperty
    ) => void
    setViewingCompanyContact: typeof setViewingCompanyContact

}


export default function Chain(props: IProps) {
    const {
        chain,
        company,
        branch,
        property,
        thisPropertyInChain,
        updateItem,
        deleteItem,
        addNewItem,
        deletePropertyRef,
        addPropertyRef,
        setViewingCompanyContact
    } = props;

    const [expandedPositions, setExpandedPositions] = useState<string[]>(
        chain.items.map((item) => item._id)
    );

    const onSelectPropertyRef = (index: number, properties: IProperty[]) => {

        if (properties?.length <= 0) {
            return;
        }

        addPropertyRef(index, properties[0])
    };

    const renderChainItem = (
        item: IPropertyChainItem,
        index: number,
        arr: IPropertyChainItem[]
    ) => {

        // The start of the array is the end of the chain
        const displayPosition = arr.length - index;
        const hasPropertyRef = (item.propertyRef as IProperty)?._id !== undefined;
        let salesProgressLabel;
        let title = item.propertyName;
        const firstInChain = index === arr.length - 1;
        let secondPropertyIsKrivaManaged = false;
        let secondProperty: IProperty | undefined;

        // Only need these fields for the first property in the chain
        if (arr.length > 1 && firstInChain === true) {
            const secondChainItem = arr[arr.length - 2];
            if (secondChainItem.thisProperty === true) {
                secondProperty = property;
                secondPropertyIsKrivaManaged = true;
            } else if (secondChainItem.propertyRef !== undefined) {
                secondProperty = secondChainItem.propertyRef as IProperty;
                secondPropertyIsKrivaManaged = true;
            }
        }

        if (item.thisProperty === true) {
            title = `${property.address.line1} ${property.address.postcode}`;
            salesProgressLabel = `Sales progress for ${property.address.line1}`;
        }

        if (hasPropertyRef === true) {
            const property = (item.propertyRef as IProperty);
            title = `${property.address?.line1} ${property.address?.postcode}`
            salesProgressLabel = `Sales progress for ${property.address?.line1}`;
        }

        if (salesProgressLabel === undefined && item.propertyName) {
            salesProgressLabel = `Sales progress for ${item.propertyName}`;
        }

        const toggleChainItemCollapse = () => {
            let _expandedPositions = [...expandedPositions];
            const expandedIndex = _expandedPositions.indexOf(item._id);

            if (expandedIndex !== -1) {
                _expandedPositions.splice(expandedIndex, 1);
            } else {
                _expandedPositions.push(item._id);
            }
            setExpandedPositions(_expandedPositions);
        };

        return (
            <Fragment key={`chain-item-${index}`}>
                <Card
                    id={`chain-item-${index}`}
                    className="d-flex flex-direction-row"
                    style={{ flex: 1 }}
                >
                    <ChainHeader
                        title={title}
                        index={index}
                        saleType={item.saleType}
                        chainLength={arr.length}
                        toggleChainItemCollapse={toggleChainItemCollapse}
                        thisProperty={item.thisProperty}
                        displayPosition={displayPosition}
                        addAbove={() => addNewItem(arr.length - index)}
                        addBelow={() => addNewItem(arr.length - (index + 1))}
                    />
                    <Collapse
                        in={expandedPositions.includes(item._id) === false}
                    >
                        <Card.Body className="px-2 pt-3 pb-2">
                            <h6 className="m-0 mr-2 text-primary float-right">
                                Position {displayPosition}
                            </h6>

                            <Row>
                                <Col>
                                    <h5>Details</h5>

                                    {firstInChain  && (
                                        <SaleTypeDropdown
                                            item={item}
                                            updateItem={updateItem}
                                        />
                                    )}


                                    <ChainVendorsName
                                        firstInChain={firstInChain}
                                        secondPropertyIsKrivaManaged={secondPropertyIsKrivaManaged}
                                        buyersName={secondProperty?.buyer?.name}
                                        item={item}
                                        property={property}
                                        updateItem={updateItem}
                                    />


                                    {firstInChain === false && (
                                        <>
                                            <ChainPropertyAddress
                                                item={item}
                                                title={title}
                                                updateItem={updateItem}
                                            />
                                            {(hasPropertyRef  === false && ((thisPropertyInChain === true && item.thisProperty === true) || thisPropertyInChain === false)) && (
                                                <ThisPropertyCheck
                                                    item={item}
                                                    property={property}
                                                    updateItem={updateItem}
                                                    thisPropertyInChain={
                                                        thisPropertyInChain
                                                    }
                                                />
                                            )}

                                            {item.thisProperty !== true &&
                                                firstInChain === false && hasPropertyRef === false && !item.propertyName && !item.thisProperty && (
                                                    <ChainPropertySearch
                                                        onSelect={(properties) => onSelectPropertyRef(index, properties)}
                                                    />
                                                )}
                                       </>
                                    )}

                                </Col>
                                <Col>
                                    <ChainSolicitor
                                        item={item}
                                        property={property}
                                        updateItem={updateItem}
                                        setViewingCompanyContact={setViewingCompanyContact}
                                        secondProperty={secondProperty}
                                        secondPropertyIsKrivaManaged={secondPropertyIsKrivaManaged}
                                    />
                                </Col>
                                <Col>

                                    <ChainAgent
                                        item={item}
                                        branch={branch}
                                        company={company}
                                        updateItem={updateItem}
                                        hasPropertyRef={hasPropertyRef}
                                    />
                                </Col>
                            </Row>

                           {firstInChain === false && (

                                <Row>
                                    <Col>
                                    <div style={{padding: "0px 15px"}}>
                                        <h5>{salesProgressLabel}</h5>
                                        <TimelineChecks chainItem={item} updateItem={updateItem} property={property}/>
                                    </div>
                                    </Col>

                                </Row>
                           )}
                            <Row>
                                <Col>

                                    <ChainNotes
                                        item={item}
                                        updateItem={updateItem}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md="5">
                                    {hasPropertyRef === true && (
                                        <Button
                                            className="float-left mx-2 mb-2"
                                            variant="warning"
                                            onClick={() => deletePropertyRef(index)}
                                        >
                                            Clear property link
                                        </Button>
                                    )}
                                    <Button
                                        className="float-left mx-2"
                                        variant="danger"
                                        onClick={() => deleteItem(index)}
                                    >
                                        Remove from chain
                                    </Button>
                                </Col>

                            </Row>
                        </Card.Body>
                    </Collapse>
                </Card>

                {index !== arr.length - 1 && <Separator />}
            </Fragment>
        );
    };

    return <div className="mx-1">{chain.items.map(renderChainItem)}</div>;
}
