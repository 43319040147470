export const createPager = (options: {
    itemsPerPage?: number;
    count?: number;
    currentPage?: number;
    totalPages?: number;
    nextPage?: number;
    prevPage?: number;
}) => {
    const {
        itemsPerPage = 0,
        count = 0,
        currentPage = 0,
        totalPages = 0,
        nextPage = 0,
        prevPage = 0
    } = options;

    return {
        itemsPerPage,
        count,
        currentPage,
        totalPages,
        nextPage,
        prevPage
    };
};
