import React, { Component } from "react";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import {
    addBranchMember,
    resetAddBranchMembersStatus
} from "../../../../../../redux/api/branch";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import AddMemberForm from "../../components/AddMemberForm";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import { ICompany, IUser } from "../../../../../../api/_types";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";
import { RouteComponentProps, withRouter } from "react-router-dom";
import routes from "../../../../../../navigation/routes";

interface IProps {
    company?: ICompany;
    addBranchMemberStatus: AsyncActionState;
    addBranchMember: (member: string | IUser) => void;
    resetAddBranchMembersStatus: () => void;
}
interface IState {
    member: IUser;
    dirty: boolean;
}

class AddMemberFormContainer extends Component<IProps & RouteComponentProps, IState> {
    constructor(props: IProps & RouteComponentProps) {
        super(props);

        this.state = {
            member: this.generateInitialMember(),
            dirty: false
        };
    }

    componentDidUpdate(prevProps: IProps) {
        if (
            prevProps.addBranchMemberStatus === AsyncActionState.Pending &&
            this.props.addBranchMemberStatus !== AsyncActionState.Pending
        ) {
            if (this.props.addBranchMemberStatus === AsyncActionState.Success) {
                this.resetFormState(() => {
                    this.props.history.push(routes.branchUsers.url);
                });
            }
        }
    }

    componentWillUnmount() {
        this.props.resetAddBranchMembersStatus();
    }

    generateInitialMember = () => {
        return {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            tel: "",
            type: UserTypes.BRANCH_STAFF,
            company: this.props.company ? this.props.company._id : ""
        } as IUser;
    };

    addMember = () => {
        const { addBranchMember } = this.props;

        addBranchMember(this.state.member);
    };

    resetFormState = (callback?: () => void) => {
        this.setState({
            member: this.generateInitialMember(),
            dirty: false
        }, callback);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "member"
        );
    };

    render() {
        const {
            addBranchMemberStatus,
            resetAddBranchMembersStatus
        } = this.props;
        const { member, dirty } = this.state;
        return (
            <>
                <NavigationPrompt when={dirty === true} />
                <LoadingContainer
                    loading={addBranchMemberStatus === AsyncActionState.Pending}
                >
                    <AddMemberForm
                        member={member}
                        updateField={this.onChangeInputField}
                        saveUser={this.addMember}
                        saveUserStatus={addBranchMemberStatus}
                        resetSaveUserState={resetAddBranchMembersStatus}
                    />
                </LoadingContainer>
            </>
        );
    }
}

const mapStateToProps = ({ branch, company }: IStore) => ({
    addBranchMemberStatus: branch.addBranchMemberStatus,
    company: company.viewingCompany
});

export default connect(
    mapStateToProps,
    {
        addBranchMember,
        resetAddBranchMembersStatus
    }
)(withRouter<any, any>(AddMemberFormContainer));
