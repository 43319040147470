import _ from "lodash";
import React, { Component, ComponentClass } from "react";
import { TimelineKeys } from "../../../../../../../api/modules/property";
import {
    IProperty,
    IPropertyTimeline,
    IPropertyTimelineEntity,
    IPropertyTimelineEntityTemplate,
    IPropertyTimelineTemplate,
    ISurveyData
} from "../../../../../../../api/_types";
import { IProcessedTimeline } from "../../../../../../../redux/reducers/property";
import { AsyncActionState } from "../../../../../../../redux/utils/asyncAction";
import {
    IPropertyTimelineEntityState
} from "../../../../../../../redux/_types/property/types";
import { findNextLogicalTimelineSteps } from "../../../../../../../lib/core/timeline/findNextTimelineSteps";

interface IProps {
    isProperty?: boolean;
    property?: IProperty;
    editing?: boolean;
    timeline: IPropertyTimeline;
    timelineTemplate: IPropertyTimelineTemplate;
    editingTemplate?: boolean;
    editingTitle?: boolean;
    editingDescription?: boolean;
    editingStates?: boolean;
    timelineUpdateStatus?: AsyncActionState;
    updateTimelineStatus?: AsyncActionState;
    onChangeCompletionDate?: (completionDate?: string | Date) => void;
    onChangeItemState?: (
        timelineEntity: IPropertyTimelineEntity,
        newState: IPropertyTimelineEntityState
    ) => void;
    onChangeDaysTillComplete?: (
        timelineEntity: IPropertyTimelineEntity,
        daysTillComplete: number
    ) => void;
    onChangeTitle?: (
        timelineEntity: IPropertyTimelineEntity,
        title: string
    ) => void;
    onChangeDescription?: (
        timelineEntity: IPropertyTimelineEntity,
        description: string
    ) => void;
    onChangeSurveyData?: (
        timelineEntity: IPropertyTimelineEntity,
        key: keyof ISurveyData,
        value: any
    ) => void;
    onAddSurvey?: (newSurvey: IPropertyTimelineEntity) => void;
    onRemoveSurvey?: (surveyToRemove: IPropertyTimelineEntity) => void;
    saveTimeline?: (
        timeline: IPropertyTimelineEntity[],
        completionDate?: string | Date
    ) => void;
    resetTimelineUpdateStatus?: () => void;
}
interface IState {
    timeline?: IProcessedTimeline;
    completionDate?: Date | string;
    ready: boolean;
}

export default function withTimeline(inputComponent: ComponentClass<IProps>) {
    class WithTimelineClass extends Component<IProps, IState> {
        constructor(props: IProps) {
            super(props);

            this.state = {
                timeline: this.generateInStateTimeline(),
                ready: false,
                completionDate: props.property?.completionDate
            };
        }

        componentDidUpdate(prevProps: IProps) {
            if (prevProps.timeline !== this.props.timeline) {
                
                this.setState({
                    timeline: this.generateInStateTimeline(),
                    completionDate: this.props.property?.completionDate
                });
            }
        } 

        generateInStateTimeline = (): IProcessedTimeline | undefined => {
            const timeline = this.props.timeline;

            if (timeline === undefined) {
                return undefined;
            }

            const unpackedTimeline = this.unpackTimeline(timeline);

            // Handle properties without any surveys
            if (unpackedTimeline.S === undefined) {
                unpackedTimeline.S = [];
            }

            return unpackedTimeline;
        };

        unpackTimeline = (timeline: IPropertyTimeline) => {
            return _.groupBy(timeline.data, "type") as any;
        };

        packTimeline = (timeline: IProcessedTimeline) => {
            return { data: _.flatMap(timeline) };
        };

        /**
         * @function findEntityFromTimeline Returns a reference to a timeline item
         */
        findEntityFromTimeline = (timelineEntity: IPropertyTimelineEntity) => {
            const timeline = this.state.timeline;
            const track: IPropertyTimelineEntity[] = (timeline as any)[
                timelineEntity.type
            ];

            const updatedElement = _.find(
                track,
                (t) => t.key === timelineEntity.key
            );
            if (updatedElement === undefined) {
                throw new Error(
                    "[Timeline] Trying to update element that is undefined."
                );
            }
            return updatedElement;
        };

        onChangeItemState = (
            timelineEntity: IPropertyTimelineEntity,
            newState: IPropertyTimelineEntityState
        ) => {
            const updatedElement = this.findEntityFromTimeline(timelineEntity);

            const wasSetToCompleteInThisSession =
                updatedElement.state === IPropertyTimelineEntityState.COMPLETE;

            updatedElement.state = newState;
            updatedElement.dirty = true;

            // Set the next logical step(s) to in progress IF a step is being set
            // to complete for the first time.
            if (newState === IPropertyTimelineEntityState.COMPLETE) {
                const steps = findNextLogicalTimelineSteps(updatedElement, this.state.timeline!);
                steps.forEach((step) => {
                    // Set the next step to in progress if it hasn't been started already
                    if (
                        step.state === IPropertyTimelineEntityState.NOT_STARTED
                    ) {
                        step.state = IPropertyTimelineEntityState.IN_PROGRESS;
                        step.dirty = true;
                        step.wasAutoSetToInProgress = true;
                    }
                });
            }

            // If the user completed this step, and they've now set it to not complete, before saving
            if (
                this.state.timeline !== undefined &&
                wasSetToCompleteInThisSession === true &&
                newState !== IPropertyTimelineEntityState.COMPLETE
            ) {
                // // Need to reset the steps that were automatically set to complete.
                const steps = [
                    ...this.state.timeline.C,
                    ...this.state.timeline.F,
                    ...this.state.timeline.L,
                    ...this.state.timeline.PS,
                    ...this.state.timeline.S
                ];

                steps.forEach((step) => {
                    // Set the next step to in progress if it hasn't been started already
                    if (step.wasAutoSetToInProgress === true) {
                        step.state = IPropertyTimelineEntityState.NOT_STARTED;
                        step.dirty = false;
                        step.wasAutoSetToInProgress = false;
                    }
                });

                alert(
                    "Due to your correction, all steps that were automatically set to 'In progress' during your current edits have been reset to not started. \n\nPlease use the 'Show all steps in sales process' checkbox to review the timeline for correctness before saving."
                );
            }

            this.setState({
                timeline: this.state.timeline
            });
        };

        onChangeCompletionDate = (completionDate: string | Date) => {
            // If we set completion date, mark completed step as dirty so the warning date gets updated by the API 
            let completionItem = this.state.timeline?.C.find(item => item.key === TimelineKeys.Completed);
            if (completionItem !== undefined) {
                completionItem.dirty = true;
            }
            this.setState({ completionDate });
        };

        onChangeDaysTillComplete = (
            timelineEntity: IPropertyTimelineEntity,
            daysTillComplete: number
        ) => {
            const updatedElement = this.findEntityFromTimeline(timelineEntity);

            updatedElement.daysToComplete = daysTillComplete;
            updatedElement.dirty = true;

            this.setState({
                timeline: this.state.timeline
            });
        };

        onChangeTitle = (
            timelineEntity: IPropertyTimelineEntity,
            title: string
        ) => {
            const updatedElement = this.findEntityFromTimeline(timelineEntity);

            (updatedElement as IPropertyTimelineEntityTemplate).title = title;
            updatedElement.dirty = true;

            this.setState({
                timeline: this.state.timeline
            });
        };

        onChangeDescription = (
            timelineEntity: IPropertyTimelineEntity,
            description: string
        ) => {
            const updatedElement = this.findEntityFromTimeline(timelineEntity);

            (updatedElement as IPropertyTimelineEntityTemplate).description = description;
            updatedElement.dirty = true;

            this.setState({
                timeline: this.state.timeline
            });
        };

        onChangeSurveyData = (
            timelineEntity: IPropertyTimelineEntity,
            key: keyof ISurveyData,
            value: any
        ) => {
            const updatedElement = this.findEntityFromTimeline(timelineEntity);

            (updatedElement as IPropertyTimelineEntityTemplate).surveyData[
                key
            ] = value;
            updatedElement.dirty = true;

            this.setState({
                timeline: this.state.timeline
            });
        };

        handleAddSurvey = (newSurvey: IPropertyTimelineEntity) => {
            const timeline = this.state.timeline;
            if (timeline === undefined) {
                return;
            }

            newSurvey.dirty = true;
            newSurvey.state = IPropertyTimelineEntityState.IN_PROGRESS;

            if (newSurvey.appMarkedDelete === true) {
                newSurvey.appMarkedDelete = undefined;
            }

            // Remove any existing survey which has already been marked for deletion
            // i.e. for when a survey has been deletedd ,but then added again
            if (
                _.find(timeline.S, (s) => s.key === newSurvey.key) !== undefined
            ) {
                _.remove(timeline.S, (r) => r.key === newSurvey.key);
            }

            timeline.S.push(newSurvey);

            this.setState({
                timeline
            });
        };

        handleRemoveSurvey = (surveyToRemove: IPropertyTimelineEntity) => {
            const updatedElement = this.findEntityFromTimeline(surveyToRemove);

            (updatedElement as IPropertyTimelineEntityTemplate).appMarkedDelete = true;
            updatedElement.dirty = true;

            this.setState({
                timeline: this.state.timeline
            });
        };

        handleSaveTimeline = (timeline: IPropertyTimeline) => {
            const { saveTimeline } = this.props;
            if (
                timeline !== undefined &&
                saveTimeline !== undefined &&
                this.state.timeline !== undefined
            ) {
                const packedTimeline = this.packTimeline(this.state.timeline);
                const onlyDirtyItems = packedTimeline.data.filter(
                    (item: IPropertyTimelineEntityTemplate) =>
                        item.dirty === true
                );

                saveTimeline(onlyDirtyItems, this.state.completionDate);
            }
        };

        render() {
            const { timeline } = this.state;
            const RenderedComponent: ComponentClass<any> = inputComponent;
            const completionDateChanged =
                this.props.property?.completionDate !== this.state.completionDate;

            return (
                <RenderedComponent
                    {...this.props}
                    timeline={timeline}
                    onChangeItemState={this.onChangeItemState}
                    onChangeDaysTillComplete={this.onChangeDaysTillComplete}
                    onChangeTitle={this.onChangeTitle}
                    onChangeDescription={this.onChangeDescription}
                    onChangeSurveyData={this.onChangeSurveyData}
                    onAddSurvey={this.handleAddSurvey}
                    onRemoveSurvey={this.handleRemoveSurvey}
                    saveTimeline={this.handleSaveTimeline}
                    onChangeCompletionDate={this.onChangeCompletionDate}
                    completionDateChanged={completionDateChanged}
                    completionDateSet={this.state.completionDate !== undefined}
                />
            );
        }
    }

    return WithTimelineClass;
}
