import React from "react";

interface IProps {
    fill?: string;
    size?: number
}

export default function ManageCompanies({size = 28, fill}: IProps) {
    return (
        <svg
            id="_x31__px"
            enableBackground="new 0 0 24 24"
            height={size}
            viewBox="0 0 24 24"
            width={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill={fill} d="m18.5 24h-14c-1.378 0-2.5-1.122-2.5-2.5v-17c0-1.378 1.122-2.5 2.5-2.5h14c1.378 0 2.5 1.122 2.5 2.5v17c0 1.378-1.122 2.5-2.5 2.5zm-14-21c-.827 0-1.5.673-1.5 1.5v17c0 .827.673 1.5 1.5 1.5h14c.827 0 1.5-.673 1.5-1.5v-17c0-.827-.673-1.5-1.5-1.5z"/>
            <path fill={fill} d="m6.5 5c-.276 0-.5-.224-.5-.5v-4c0-.276.224-.5.5-.5s.5.224.5.5v4c0 .276-.224.5-.5.5z"/>
            <path fill={fill} d="m12 5c-.276 0-.5-.224-.5-.5v-4c0-.276.224-.5.5-.5s.5.224.5.5v4c0 .276-.224.5-.5.5z"/>
            <path fill={fill} d="m16.5 5c-.276 0-.5-.224-.5-.5v-4c0-.276.224-.5.5-.5s.5.224.5.5v4c0 .276-.224.5-.5.5z"/>
            <path fill={fill} d="m17.5 18h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11c.276 0 .5.224.5.5s-.224.5-.5.5z"/>
            <path fill={fill} d="m17.5 14h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11c.276 0 .5.224.5.5s-.224.5-.5.5z"/>
            <path fill={fill} d="m12.5 10h-6c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h6c.276 0 .5.224.5.5s-.224.5-.5.5z"/>
        </svg>
    );
}
