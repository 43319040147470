import { IStore } from "../../store";
import { IProperty, ICompany, IBranch } from "../../../api/_types";

interface IValidatePropertyEntitiesResponse {
    company: ICompany;
    branch: IBranch;
    property: IProperty;
}

export function validatePropertyEntities(
    state: IStore,
    checks: {
        company?: boolean;
        branch?: boolean;
        property?: boolean;
    }
): false | IValidatePropertyEntitiesResponse {
    const { company, branch, property } = state;
    if (checks.property === true && property.viewingProperty === undefined) {
        return false;
    }
    if (checks.branch === true && branch.viewingBranch === undefined) {
        return false;
    }

    if (checks.company === true && company.viewingCompany === undefined) {
        return false;
    }

    return {
        company: company.viewingCompany as ICompany,
        branch: branch.viewingBranch as IBranch,
        property: property.viewingProperty as IProperty
    };
}
