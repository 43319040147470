import * as actions from "../../actions/auth";

export interface IAuthState {
    credentials?: IAuthenticateResponse;
}

const initialState: IAuthState = {
    credentials: undefined
};

export default function authReducer(
    state: IAuthState = initialState,
    action: actions.SetAuthenticateResponse
) {
    switch (action.type) {
        case actions.AuthenticateTypes.SET_AUTHENTICATE_RESPONSE: {
            return Object.assign({}, state, {
                credentials: action.payload
            } as IAuthState);
        }

        default: {
            return state;
        }
    }
}
