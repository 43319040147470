import React, { FormEvent, useReducer, useRef } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select, { ValueType } from "react-select";
import { TimelineKeys } from "../../../../../../../api/modules/property";
import {
    IPropertyTimelineEntity,
    IPropertyTimelineEntityTemplate,
    IPropertyTimelineTemplate
} from "../../../../../../../api/_types";
import { IPropertyTimelineEntityState } from "../../../../../../../redux/_types/property/types";
import DebugFields from "../../TimelineItem/DebugFields";
import {
    addSurveyReducer,
    IAddSurveyState,
    initialState
} from "./AddSurvey.reducer";
import SurveyFields from "./SurveyFields";

interface IProps {
    surveys: IPropertyTimelineEntityTemplate[];
    addSurveyItem?: IPropertyTimelineEntity;
    addSurveyItemTemplate?: IPropertyTimelineEntityTemplate;
    existingSuveys?: IPropertyTimelineEntity[];
    addSurvey: (survey: IAddSurveyState) => void;
    saveTimeline?: () => void;
    updateItemState?: (item: IPropertyTimelineEntity, newState: IPropertyTimelineEntityState) => void
}

export default function AddSurvey(props: IProps) {
    const { addSurveyItem, addSurveyItemTemplate, surveys, addSurvey, existingSuveys = [], updateItemState, saveTimeline } = props;
    const surveyTypeRef = useRef(null);
    const [surveyState, surveyStateDispatch] = useReducer(
        addSurveyReducer,
        initialState
    );

    const submit = (e: FormEvent) => {
        e.preventDefault();

        const clearSurveyWarning = () => {

            if ( updateItemState !== undefined && addSurveyItem?.state !== IPropertyTimelineEntityState.COMPLETE) {

                updateItemState(addSurveyItem as IPropertyTimelineEntity, IPropertyTimelineEntityState.COMPLETE);
            }
        };

        if (surveyState.survey !== undefined) {

            clearSurveyWarning();
            if (surveyState?.survey?.key === TimelineKeys.NotHavingSurvey) {
                if (saveTimeline !== undefined ) {
                    saveTimeline();
                }
                return;
            }

            addSurvey(surveyState);
            surveyStateDispatch({ type: "reset" });

            if (surveyTypeRef !== null && surveyTypeRef.current !== null) {
                (surveyTypeRef.current as any).select.clearValue();
            }
        }
    };

    // Filter surveys, only displaying the survey that haven't already been accepted.
    // Also show surveys that have been marked as to be deleted
    const filteredSurveys = surveys.filter((survey) => {
        const existingSurvey = existingSuveys.find((e) => e.key === survey.key);
        // Check for the timeline already having this survey
        if (existingSurvey === undefined) {
            return true;
        }

        if (existingSurvey.appMarkedDelete === true) {
            return true;
        }

        return false;
    });

    if (addSurveyItemTemplate !== undefined && addSurveyItem?.state !== IPropertyTimelineEntityState.COMPLETE) {
        filteredSurveys.unshift(addSurveyItemTemplate);
    }


    return (
        <Card style={{ marginBottom: 25 }} data-testid="add-survey">
            <Card.Header
                style={{ fontWeight: 600 }}
                className="d-flex align-items-center "
            >
                Add survey
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Form id="add-survey-form" onSubmit={submit}>
                            <Form.Group data-testid="add-survey-select">
                                <label htmlFor="add-survey-select">
                                    Select the type of survey to add
                                </label>
                                <Select
                                    ref={surveyTypeRef}
                                    inputId="add-survey-select"
                                    aria-label="Select survey"
                                    options={
                                        filteredSurveys.map(
                                            (
                                                survey: IPropertyTimelineEntityTemplate
                                            ) => ({
                                                ...survey,
                                                label: survey.title,
                                                value: survey.key
                                            })
                                        ) as any
                                    }
                                    onChange={(
                                        value: ValueType<
                                            IPropertyTimelineTemplate
                                        >
                                    ) =>
                                        surveyStateDispatch({
                                            type: "updateSurvey",
                                            payload: (value as any) || undefined
                                        })
                                    }
                                />
                            </Form.Group>



                            {surveyState.survey !== undefined && surveyState.survey?.key !== TimelineKeys.NotHavingSurvey && (
                                <SurveyFields
                                    survey={surveyState.survey}
                                    surveyStateDispatch={surveyStateDispatch}
                                />
                            )}

                            {
                                surveyState.survey !== undefined && (
                                    <>
                                    {surveyState.survey?.key === TimelineKeys.NotHavingSurvey ?
                                        <Button variant="primary" type="submit" disabled={surveyState.survey?.state === IPropertyTimelineEntityState.COMPLETE}>
                                            Save
                                        </Button>
                                    :
                                        <Button variant="primary" type="submit" disabled={surveyState.survey?.surveyData.date === undefined}>
                                            Add
                                        </Button>
                                    }

                                        <p className="mb-0 mt-2">
                                            <small>
                                                {surveyState.survey?.key === TimelineKeys.NotHavingSurvey ? "Saving" : "Adding"} this survey will save the timeline
                                                automatically.
                                            </small>
                                        </p>
                                    </>
                                )
                            }
                        </Form>

                        <DebugFields item={addSurveyItem}/>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}
