import React, { FC, FormEvent, useReducer } from "react";
import { Button, Form, Modal, ModalProps } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { reducer, initialState, IDocumentEmailState } from "./reducer";
import EmailInput from "../../../../../_sharedComponents/EmailInput";

type MProps = Pick<ModalProps, "show" | "onShow">;
interface IProps extends MProps {
    documentName: string;
    emailInProgress?: boolean;
    send: (state: IDocumentEmailState) => void;
    close: () => void;
}

const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

const DocumentEmailForm: FC<IProps> = ({
    documentName,
    show,
    close,
    onShow,
    send
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const disabled = state.to?.length === 0 || state.message?.length <= 0;  
    const isEmailFieldInvalid = (
        value: string,
        key: keyof typeof initialState
    ) => (state[key].length && validateEmail(value) === false ? true : false);

    return (
        <Modal
            show={show}
            onShow={onShow}
            onHide={close}
            centered    
            backdrop="static"
            size="lg"
        >
            <Form
                onSubmit={(e: FormEvent) => {
                    e.preventDefault();
                    send(state);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Email this document</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Label>To* </Form.Label>

                    <EmailInput
                        invalidEmail="No duplicate emails allowed"
                        onChange={(emails) => {
                            dispatch({
                                type: "to",
                                payload: emails
                            });
                        }}
                        isValid={(email) => {
                            if (state.cc.includes(email) || state.bcc.includes(email)) {
                                return false;
                            }
                            return true;    
                        }}
                    />
                    
                    <Form.Label>Reply to</Form.Label>
                    <Form.Control
                        value={state.replyTo}
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            dispatch({
                                type: "replyTo",
                                payload: e.currentTarget.value
                            })
                        }
                        isInvalid={isEmailFieldInvalid(
                            state.replyTo,
                            "replyTo"
                        )}
                    />

                    <Form.Label>CC</Form.Label>
                    <EmailInput
                        onChange={(emails) => {
                            dispatch({
                                type: "cc",
                                payload: emails
                            });
                        }}
                        invalidEmail="No duplicate emails allowed"
                        isValid={(email) => {
                            if (state.to.includes(email) || state.bcc.includes(email) ) {
                                return false;
                            }
                            return true;
                        }}
                    />
                    {/* 
                    <Form.Label>BCC</Form.Label>
                    <EmailInput
                        onChange={(emails) => {
                            dispatch({
                                type: "bcc",
                                payload: emails
                            });
                        }}
                    /> */}

                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                        value={state.subject}
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            dispatch({
                                type: "subject",
                                payload: e.currentTarget.value
                            })
                        }
                    />

                    <Form.Label>Message*</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={7}
                        // Auto focus is buggy inside the modal, so using ref to achieve it
                        value={state.message}
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            dispatch({
                                type: "message",
                                payload: e.currentTarget.value
                            })
                        }
                    />

                    <div className="file-container">
                        <div className="file-description">
                            This file will be attached as a ZIP file in the
                            email that you send.
                        </div>

                        <div className="file-preview">
                            <FontAwesomeIcon
                                className="text-primary"
                                icon={faFileAlt}
                                size="2x"
                            />
                            <span className="ml-2">{documentName}</span>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="outline-primary" onClick={close}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="primary" disabled={disabled}>
                        Send email
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default DocumentEmailForm;
