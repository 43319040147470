import React, { FC, ReactNode } from "react";
import { Form } from "react-bootstrap";
import {
    ICompanyContactPOC,
    IProperty,
    IPropertyChainItem
} from "../../../../../../../api/_types";
import { setViewingCompanyContact } from "../../../../../../../redux/api/companyContacts";
import PopulatedContact from "../../../../contacts/components/PopulatedContact";
import ContactCard from "../../../../contacts/containers/ContactCard";

interface IProps {
    item: IPropertyChainItem;
    property: IProperty;
    secondPropertyIsKrivaManaged?: boolean;
    secondProperty?: IProperty;
    updateItem: (
        item: IPropertyChainItem,
        key: keyof IPropertyChainItem,
        value: any
    ) => void;
    setViewingCompanyContact: typeof setViewingCompanyContact
}

const ChainSolicitor: FC<IProps> = ({ item, property, secondProperty, secondPropertyIsKrivaManaged, updateItem, setViewingCompanyContact }) => {

    const propertyRef = (item.propertyRef as IProperty);

    let output: ReactNode;


    if (secondPropertyIsKrivaManaged === true && secondProperty !== undefined) {
        output = (
            <PopulatedContact
                selectedPOC={
                    secondProperty.contacts?.buyerDetails?.solicitor.poc as ICompanyContactPOC
                }
            />
        )
    }
    else if (item.thisProperty !== true && propertyRef?._id === undefined) {
        output = <ContactCard
            onSelect={(contactSet) =>
                updateItem(item, "solicitor", contactSet)
            }
            existingPOC={item.solicitor.poc}
        />
    } else if (item.thisProperty === true) {
        output = <>
            {property.contacts?.solicitor.poc !== undefined && (
                <PopulatedContact
                    selectedPOC={
                        property.contacts.solicitor
                            .poc as ICompanyContactPOC
                    }
                    setViewingCompanyContact={setViewingCompanyContact}
                />
            )}
        </>
    } else {
        output = (
            <>
                {(item.propertyRef as IProperty)?.contacts?.solicitor.poc !== undefined && (
                    <PopulatedContact
                        selectedPOC={
                            Object.assign({}, propertyRef?.contacts?.solicitor.poc as ICompanyContactPOC, {
                                companyContact: propertyRef?.contacts?.solicitor.contact!,
                            } )
                        }
                        setViewingCompanyContact={setViewingCompanyContact}

                    />
                )}
            </>
        );
    }


    return (
        <>
            <h5>Solicitor</h5>
            <Form.Group className="mt-1">
                {output}
            </Form.Group>
        </>
    );
};

export default ChainSolicitor;
