import React from "react";
import { IPagingResponse, IUser } from "../../../../../../api/_types";
import DataTable from "../../../../../_sharedComponents/DataTable";
import { IDataTableAction } from "../../../../../_sharedComponents/DataTable/types";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import { RouteComponentProps, withRouter } from "react-router-dom";
import UserAvatar from "../../../user/components/Avatar";

interface IProps extends RouteComponentProps {
    me: IUser;
    users: IUser[];
    paging: IPagingResponse;
    pageUsers: (desiredPage: number) => void;
    setUpdateCompanyAdmin: (admin: IUser) => void;
}

function CompanyUserList(props: IProps) {
    const { me, users, paging, pageUsers } = props;
    const actions: IDataTableAction[] = [];

    if (
        [UserTypes.GYA_ADMIN, UserTypes.COMPANY_ADMIN].includes(me.type) ===
        true
    ) {
        actions.push({
            action: (user: IUser) => {
                props.setUpdateCompanyAdmin(user);
            },
            btnTitle: "Manage",
            btnVariant: "light"
        });
    }

    return (
        <DataTable
            data={users}
            fields={[
                {
                    field: "",
                    displayName: "Name",
                    mutator: (user) => (
                        <div className="d-flex flex-direction-row align-items-center">
                            <UserAvatar
                                user={user}
                                size={40}
                                rounded={true}
                                containerStyle={{ marginRight: 10 }}
                            />
                            {user.firstName} {user.lastName}
                        </div>
                    )
                },
                {
                    field: "email",
                    displayName: "Email"
                }
            ]}
            showPager={true}
            pagingElement={paging}
            onPagerPageClick={pageUsers}
            actions={actions}
        />
    );
}

export default withRouter<any, any>(CompanyUserList);
