import { Dispatch } from "redux";
import { IStore } from "../../store";
import api from "../../../api";
import * as acreators from "../../acreators/company";
import {
    ICompany,
    IPagingParams,
    IPropertyTimelineEntity,
    ISearchCompanyMembersResponse,
    IUser,
    IPropertyGetListFilters,
    PropertySortOptionType
} from "../../../api/_types";
import { AsyncActionState } from "../../utils/asyncAction";
import { AxiosResponse } from "axios";
import { createPager } from "../../../lib/utils/pager";
import { UserTypes } from "../../_types/user/types";

export const getCompanies = (
    params: IPagingParams = {
        currentPage: 0,
        itemsPerPage: 10
    }
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { itemsPerPage, currentPage } = params;
        dispatch(acreators.setGetCompaniesStatus(AsyncActionState.Pending));
        try {
            const res = await api.company.getCompanies({
                currentPage,
                itemsPerPage
            });
            dispatch(acreators.setCompanies(res.data));
            dispatch(acreators.setGetCompaniesStatus(AsyncActionState.Success));
        } catch (e) {
            console.log("Error getting companies", e);
            dispatch(acreators.setGetCompaniesStatus(AsyncActionState.Error));
        }
    };
};

export const getCompanyById = (_id: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(acreators.setGetCompanyStatus(AsyncActionState.Pending));

        try {
            const company = await api.company.getCompanyById(_id);

            if (company.data.data === undefined || company.data.data === null) {
                alert("No company found.");
                return;
            }

            dispatch(
                acreators.setViewingCompany((company as any).data.data.company)
            );
            dispatch(acreators.setGetCompanyStatus(AsyncActionState.Success));
        } catch (e) {
            console.log("Error getting company", e);
            dispatch(acreators.setGetCompanyStatus(AsyncActionState.Error));
        }
    };
};

export const resetViewingCompany = () => {
    return acreators.setViewingCompany(undefined);
};

export const saveCompany = (company: ICompany, file?: File) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(acreators.setCreateCompanyStatus(AsyncActionState.Pending));
        const apiMethod =
            company._id === undefined
                ? api.company.createCompany
                : api.company.editCompany;

        try {
            let response: AxiosResponse<{ data: ICompany }> = await apiMethod(
                company,
                file
            );

            if (company._id !== undefined) {
                // Go refresh the list of categories
                await getCompanyById(company._id as string)(dispatch);
            } else {
                dispatch(acreators.setViewingCompany(response.data.data));
            }

            dispatch(
                acreators.setCreateCompanyStatus(AsyncActionState.Success)
            );
        } catch (error) {
            return dispatch(
                acreators.setCreateCompanyStatus(AsyncActionState.Error)
            );
        }
    };
};

export const deleteCompany = (companyId: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();

        if (company.viewingCompany === undefined) {
            return dispatch(
                acreators.setDeleteCompanyStatus(AsyncActionState.Error)
            );
        }
        if (company.viewingCompany === undefined) {
            return dispatch(
                acreators.setDeleteCompanyStatus(AsyncActionState.Error)
            );
        }

        dispatch(acreators.setDeleteCompanyStatus(AsyncActionState.Pending));

        try {
            await api.company.deleteCompany(company.viewingCompany
                ._id as string);

            dispatch(
                acreators.setDeleteCompanyStatus(AsyncActionState.Success)
            );
            // Go refresh the list of companies
            await getCompanies()(dispatch, getState);
        } catch (error) {
            dispatch(acreators.setDeleteCompanyStatus(AsyncActionState.Error));
        }
    };
};

export const resetCreateCompanyFormState = () => {
    return acreators.setCreateCompanyStatus(AsyncActionState.Reset);
};

export const resetCompanies = () => {
    return acreators.setCompanies({
        companies: [],
        paging: createPager({ itemsPerPage: 1 })
    });
};

export const resetGetCompanies = () => {
    return acreators.setGetCompaniesStatus(AsyncActionState.Reset);
};

export const updateCompanyTimeline = (
    timelineItems: IPropertyTimelineEntity[]
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();

        dispatch(
            acreators.setUpdateCompanyTimelineStatus(AsyncActionState.Pending)
        );

        if (company.viewingCompany === undefined) {
            return dispatch(
                acreators.setUpdateCompanyTimelineStatus(AsyncActionState.Error)
            );
        }

        try {
            await api.company.updateTimelineTemplate(timelineItems, company
                .viewingCompany._id as string);
        } catch (e) {
            return dispatch(
                acreators.setUpdateCompanyTimelineStatus(AsyncActionState.Error)
            );
        }

        try {
            await getCompanyById(company.viewingCompany._id as string)(
                dispatch
            );
        } catch (e) {
            return dispatch(
                acreators.setUpdateCompanyTimelineStatus(AsyncActionState.Error)
            );
        }

        dispatch(
            acreators.setUpdateCompanyTimelineStatus(AsyncActionState.Success)
        );
    };
};

export const searchCompanyMembers = (
    searchString: string = "",
    paging: IPagingParams = createPager({ itemsPerPage: 10 }),
    type?:
        | UserTypes.BRANCH_ADMIN
        | UserTypes.BRANCH_STAFF
        | UserTypes.COMPANY_ADMIN
        | UserTypes[],
    excludeUsersFromBranches: string[] = [],
    idsIn?: string[]
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();
        const activeCompany = company.viewingCompany;
        if (activeCompany === undefined) {
            return;
        }

        dispatch(
            acreators.setSearchCompanyUsersStatus(AsyncActionState.Pending)
        );

        try {
            const searchResult: AxiosResponse<
                ISearchCompanyMembersResponse
            > = await api.company.searchMembers(
                activeCompany._id as string,
                paging,
                searchString,
                type,
                excludeUsersFromBranches,
                idsIn
            );

            if (searchResult.data !== undefined) {
                dispatch(acreators.setCompanyUsers(searchResult.data));
            }
            dispatch(
                acreators.setSearchCompanyUsersStatus(AsyncActionState.Success)
            );
        } catch (error) {
            return dispatch(
                acreators.setSearchCompanyUsersStatus(AsyncActionState.Error)
            );
        }
    };
};

export const createCompanyAdmin = (user: IUser) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();
        const activeCompany = company.viewingCompany;

        if (activeCompany === undefined) {
            return;
        }

        dispatch(
            acreators.setCreateCompanyAdminStatus(AsyncActionState.Pending)
        );

        try {
            await api.company.createCompanyAdmin(
                user,
                activeCompany._id as string
            );
        } catch (e) {
            return dispatch(
                acreators.setCreateCompanyAdminStatus(AsyncActionState.Error)
            );
        }

        try {
            await searchCompanyMembers("")(dispatch, getState);
        } catch (e) {}

        dispatch(
            acreators.setCreateCompanyAdminStatus(AsyncActionState.Success)
        );
    };
};

export const editCompanyAdmin = (admin: IUser) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();

        dispatch(
            acreators.setUpdateCompanyAdminStatus(AsyncActionState.Pending)
        );

        if (
            company.viewingCompany === undefined ||
            company.viewingCompany._id === undefined
        ) {
            return dispatch(
                acreators.setUpdateCompanyAdminStatus(AsyncActionState.Error)
            );
        }

        try {
            let userRes = await api.company.editCompanyAdmin(
                admin,
                company.viewingCompany._id
            );
            dispatch(acreators.setUpdateCompanyAdmin(userRes.data));
        } catch (e) {
            return dispatch(
                acreators.setUpdateCompanyAdminStatus(AsyncActionState.Error)
            );
        }

        return dispatch(
            acreators.setUpdateCompanyAdminStatus(AsyncActionState.Success)
        );
    };
};

export const deleteCompanyMember = (member: IUser) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();

        dispatch(
            acreators.setDeleteCompanyMemberStatus(AsyncActionState.Pending)
        );

        if (
            company.viewingCompany === undefined ||
            company.viewingCompany._id === undefined
        ) {
            return dispatch(
                acreators.setDeleteCompanyMemberStatus(AsyncActionState.Error)
            );
        }

        try {
            await api.company.deleteCompanyMember(
                member,
                company.viewingCompany._id
            );
        } catch (e) {
            return dispatch(
                acreators.setDeleteCompanyMemberStatus(AsyncActionState.Error)
            );
        }

        return dispatch(
            acreators.setDeleteCompanyMemberStatus(AsyncActionState.Success)
        );
    };
};

export const deletePhoto = () => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();

        if (company.viewingCompany === undefined) {
            return;
        }

        dispatch(
            acreators.setDeleteCompanyPhotoStatus(AsyncActionState.Pending)
        );

        try {
            await api.company.deletePhoto(company.viewingCompany._id as string);
            await getCompanyById(company.viewingCompany._id as string)(
                dispatch
            );
        } catch (e) {
            return dispatch(
                acreators.setDeleteCompanyPhotoStatus(AsyncActionState.Error)
            );
        }

        return dispatch(
            acreators.setDeleteCompanyPhotoStatus(AsyncActionState.Success)
        );
    };
};

export const getCompanyProperties = (
    paging: IPagingParams = {
        currentPage: 0,
        itemsPerPage: 10
    },
    textSearch?: string,
    filters?: IPropertyGetListFilters,
    branchIds?: string[],
    sortOption: PropertySortOptionType = "default",
    solicitorCompanyId?: string
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();

        if (company.viewingCompany === undefined) {
            return dispatch(
                acreators.setGetCompanyPropertiesStatus(AsyncActionState.Error)
            );
        }

        dispatch(
            acreators.setGetCompanyPropertiesStatus(AsyncActionState.Pending)
        );

        try {
            const res = await api.company.getCompanyProperties(
                company.viewingCompany._id as string,
                paging,
                textSearch,
                filters,
                branchIds,
                sortOption,
                solicitorCompanyId
            );

            dispatch(acreators.setCompanyProperties(res.data));
            dispatch(
                acreators.setGetCompanyPropertiesStatus(
                    AsyncActionState.Success
                )
            );
        } catch (e) {
            dispatch(
                acreators.setGetCompanyPropertiesStatus(AsyncActionState.Error)
            );
        }
    };
};

export const resetCreateCompanyAdminStatus = () => {
    return acreators.setCreateCompanyAdminStatus(AsyncActionState.Reset);
};

export const resetEditCompanyAdminStatus = () => {
    return acreators.setUpdateCompanyAdminStatus(AsyncActionState.Reset);
};

export const resetDeleteCompanyMemberStatus = () => {
    return acreators.setDeleteCompanyMemberStatus(AsyncActionState.Reset);
};

export const resetDeleteCompanyPhotoStatus = () => {
    return acreators.setDeleteCompanyPhotoStatus(AsyncActionState.Reset);
};
