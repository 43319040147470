import moment from "moment";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
    IPropertyAskingPrice,
    IPropertyManagementInformation
} from "../../../../../../api/_types";
import { setPropertyAskingPriceToEdit } from "../../../../../../redux/acreators/property";
import {
    removeMIAskingPrice,
    resetSavePropertyMI,
    savePropertyMI
} from "../../../../../../redux/api/property";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import DataTable from "../../../../../_sharedComponents/DataTable";
import FixedBottomActionBar from "../../../../../_sharedComponents/FixedBottomActionBar";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import EditAskingPriceForm from "../EditAskingPriceForm";

const mapStateToProps = ({ property }: IStore) => ({
    property: property.viewingProperty,
    askingPriceToEdit: property.askingPriceToEdit,
    removeMIAskingPriceStatus: property.removeMIAskingPriceStatus,
    saveMIStatus: property.saveMIStatus
});

const connector = connect(
    mapStateToProps,
    {
        removeMIAskingPrice,
        setPropertyAskingPriceToEdit,
        savePropertyMI,
        resetSavePropertyMI
    }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {
    miModel: IPropertyManagementInformation;
}

interface IState {
    showDeleteModal: boolean;
    askingPriceToDelete?: IPropertyAskingPrice;
    miModel: IPropertyManagementInformation;
    dirty: boolean;
}
export class AskingPriceListContainer extends Component<IProps, IState> {
    getPropertyMi = () => {
        return Object.assign(
            {},
            this.props.property && this.props.property.mi
                ? this.props.property.mi
                : ({} as IPropertyManagementInformation)
        );
    };

    state: IState = {
        showDeleteModal: false,
        askingPriceToDelete: undefined,
        miModel: this.getPropertyMi(),
        dirty: false
    };

    componentDidUpdate(prevProps: IProps) {
        if (
            prevProps.saveMIStatus === AsyncActionState.Pending &&
            this.props.saveMIStatus === AsyncActionState.Success
        ) {
            this.setState({
                dirty: false
            });
        }
    }

    componentWillUnmount() {
        this.props.resetSavePropertyMI();
    }

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "miModel"
        );
    };

    handleSaveMI = () => {
        const { savePropertyMI } = this.props;
        const { miModel } = this.state;

        return savePropertyMI(miModel);
    };

    showDeleteModal = (askingPriceToDelete: IPropertyAskingPrice) => {
        this.setState({
            showDeleteModal: true,
            askingPriceToDelete
        });
    };

    hideDeleteModal = () => {
        this.setState({
            showDeleteModal: false,
            askingPriceToDelete: undefined
        });
    };

    handleRemoveAskingPrice = () => {
        const { askingPriceToDelete } = this.state;
        if (
            askingPriceToDelete === undefined ||
            askingPriceToDelete._id === undefined
        ) {
            alert("Invalid asking price.");
            return;
        }
        this.props.removeMIAskingPrice(askingPriceToDelete._id);
    };

    handleUpdateAskingPrice = (item: IPropertyAskingPrice) => {
        this.props.setPropertyAskingPriceToEdit(item);
    };

    renderEditForm = () => {
        return <EditAskingPriceForm />;
    };

    render() {
        const {
            property,
            removeMIAskingPriceStatus,
            askingPriceToEdit
        } = this.props;
        const { showDeleteModal } = this.state;
        if (
            property === undefined ||
            property.mi === undefined ||
            property.mi.askingPrice.length <= 0
        ) {
            return null;
        }

        if (askingPriceToEdit !== undefined) {
            return this.renderEditForm();
        }

        return (
            <FixedBottomActionBar
                successFeedback={{
                    show: this.props.saveMIStatus === AsyncActionState.Success,
                    heading: "Success",
                    message: "Property sold price saved.",
                    onClose: this.props.resetSavePropertyMI
                }}
                errorFeedback={{
                    show: this.props.saveMIStatus === AsyncActionState.Error,
                    heading: "Error",
                    message: "Something went wrong updating the sold price.",
                    onClose: this.props.resetSavePropertyMI
                }}
                elements={null}
            >
                <DataTable
                    data={property.mi.askingPrice}
                    showLoadingSpinner={
                        removeMIAskingPriceStatus === AsyncActionState.Pending
                    }
                    fields={[
                        {
                            field: "price",
                            displayName: "Price",
                            mutator: (item: IPropertyAskingPrice) =>
                                `£${item.price.toLocaleString()}`
                        },
                        {
                            field: "effectiveDate",
                            displayName: "Effective date",
                            mutator: (item: IPropertyAskingPrice) =>
                                moment(item.effectiveDate).format("ll")
                        }
                    ]}
                    actions={[
                        {
                            btnTitle: "Manage",
                            btnVariant: "light",
                            action: this.handleUpdateAskingPrice
                        },
                        {
                            btnTitle: "Remove",
                            btnVariant: "danger",
                            action: (item: IPropertyAskingPrice) => {
                                this.showDeleteModal(item);
                            }
                        }
                    ]}
                />

                <ActionPrompt
                    actions={[
                        {
                            title: "Delete",
                            btnVariant: "danger",
                            onClick: this.handleRemoveAskingPrice,
                            asyncStatus: removeMIAskingPriceStatus,
                            onSuccess: this.hideDeleteModal
                        }
                    ]}
                    title="Delete this asking price"
                    bodyText="Are you sure you want to delete this asking price? This cannot be undone."
                    show={showDeleteModal}
                    cancel={this.hideDeleteModal}
                />
            </FixedBottomActionBar>
        );
    }
}

export default connector(AskingPriceListContainer);
