import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { ICompanyContact, IAddress } from "../../../../../../api/_types";
import { resetEditCompanyContactsStatus } from "../../../../../../redux/acreators/companyContacts";
import {
    editCompanyContact,
    setViewingCompanyContact
} from "../../../../../../redux/api/companyContacts";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import HorizontalTabNabSubPage from "../../../../../_sharedComponents/HorizontalTabNavSubPage";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import CreateContactForm from "../../components/CreateContactForm";
import { withRouter, RouteComponentProps } from "react-router-dom";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";

interface IProps {
    contact?: ICompanyContact;
    editCompanyContactStatus: AsyncActionState;
    editCompanyContact: (payload: {
        name?: string;
        tel?: string;
        email?: string;
        address?: IAddress;
    }) => void;
    setViewingCompanyContact: (contact?: ICompanyContact) => void;
    resetEditCompanyContactsStatus: () => void;
    onCancel: () => void;
}

interface IState {
    contact: ICompanyContact;
    dirty: boolean;
}

class EditContactFormContainer extends Component<
    IProps & RouteComponentProps,
    IState
> {
    generateStateContact = () => {
        return Object.assign({}, this.props.contact);
    };

    componentDidUpdate(prevProps: IProps) {
        if (
            prevProps.editCompanyContactStatus === AsyncActionState.Pending &&
            this.props.editCompanyContactStatus === AsyncActionState.Success
        ) {
            this.setState({
                dirty: false
            });
        }
    }
    componentDidMount() {
        const { history, resetEditCompanyContactsStatus } = this.props;
        history.listen(() => {
            resetEditCompanyContactsStatus();
        });
    }

    state: IState = {
        contact: this.generateStateContact(),
        dirty: false
    };

    handleGoBack = () => {
        this.props.onCancel();
        this.props.resetEditCompanyContactsStatus();
    };

    handleSave = () => {
        const { contact } = this.state;
        const payload = Object.assign(
            {},
            {
                name: contact.name,
                email: contact.email,
                tel: contact.tel,
                address: contact.address
            }
        );

        this.props.editCompanyContact(payload);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "contact"
        );
    };

    render() {
        const {
            editCompanyContactStatus,
            resetEditCompanyContactsStatus
        } = this.props;
        const { contact, dirty } = this.state;

        return (
            <HorizontalTabNabSubPage
                loading={
                    this.props.contact === undefined ||
                    editCompanyContactStatus === AsyncActionState.Pending
                }
                goBack={this.handleGoBack}
            >
                <NavigationPrompt when={dirty === true} />

                <FormContainer
                    actions={
                        <Button variant="primary" onClick={this.handleSave}>
                            Save
                        </Button>
                    }
                    showSuccess={
                        editCompanyContactStatus === AsyncActionState.Success
                    }
                    showError={
                        editCompanyContactStatus === AsyncActionState.Error
                    }
                    success={{
                        heading: "Contact updated",
                        message: "Your changes have been saved"
                    }}
                    error={{
                        heading: "An error occurred",
                        message: "Please try again"
                    }}
                    onFeedbackMessageClose={resetEditCompanyContactsStatus}
                >
                    <CreateContactForm
                        contact={contact}
                        onChangeInputField={this.onChangeInputField}
                    />
                </FormContainer>
            </HorizontalTabNabSubPage>
        );
    }
}

const mapStateToProps = ({ companyContacts }: IStore) => ({
    contact: companyContacts.viewingContact,
    editCompanyContactStatus: companyContacts.editCompanyContactStatus
});

export default connect(
    mapStateToProps,
    {
        editCompanyContact,
        setViewingCompanyContact,
        resetEditCompanyContactsStatus
    }
)(withRouter<any, any>(EditContactFormContainer));
