import React, { PureComponent } from "react";
import { Pie } from "react-chartjs-2";
import "../charts.css";
import colours from "../ChartColours";
import { ICompany } from "../../../../api/_types";

interface IProps {
    companies: any;
}

interface IAdditionalCompanyFields {}

interface IStateCompany extends ICompany, IAdditionalCompanyFields {}

interface IState {
    company: IStateCompany;
}

let data = {
    labels: [] as string[],
    datasets: [
        {
            label: "Branches Per Company",
            backgroundColor: colours.chartAreaColours,
            borderColor: colours.chartBorderColour,
            borderWidth: 5,
            hoverBackgroundColor: colours.chartAreaColoursHover,
            hoverBorderColor: colours.chartBorderColour,
            data: [] as number[]
        }
    ]
};

export class BranchesPerCompanyChart extends PureComponent<IProps, IState> {
    dataToChart = () => {
        this.props.companies.forEach((company: ICompany) => {
            data.labels.push(company.name);
            data.datasets[0].data.push(company.branches.length);
        });
    };

    render() {
        this.dataToChart();
        return (
            <div className="chartWrapper">
                <Pie
                    data={data}
                    width={50}
                    height={50}
                    options={{
                        maintainAspectRatio: true
                    }}
                />
            </div>
        );
    }
}
