export default {
    container: (
        size: number,
        color: string,
        filled: boolean,
        slightlyOpaque: boolean = false
    ) => {
        let opacity = "FF";
        let itemColor: string;

        if (slightlyOpaque === true) {
            opacity = "99";
        }

        itemColor = `${color}${opacity}`;

        return {
            width: size,
            height: size,
            borderRadius: size / 2,
            borderColor: itemColor,
            borderWidth: 8,
            borderStyle: "solid",
            // opacity: slightlyOpaque === true ? 0.7 : 1,
            backgroundColor: filled ? itemColor : "transparent"
        } as React.CSSProperties;
    }
};
