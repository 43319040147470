import React, { ComponentClass, FC } from "react";
import TabBody from "../TabBody";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { RouteComponentProps, withRouter, NavLink } from "react-router-dom";
import "./tabs.scss";

export interface IHorizontalTabsItem {
    key: string;
    title: string;
    content: ComponentClass<any, any> | FC<any>;
}
interface IProps {
    id: string;
    defaultKey: string;
    items: IHorizontalTabsItem[];
}
function HorizontalTabs(props: IProps & RouteComponentProps) {
    const { id, defaultKey, items } = props;

    return (
        <TabBody>
            <Tab.Container
                id={id}
                defaultActiveKey={defaultKey}
                unmountOnExit={true}
            >
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            {items.map((item, index) => {
                                return (
                                    <NavLink to={item.key}>
                                        <Nav.Item
                                            className={`htab-item ${
                                                item.key === props.defaultKey
                                                    ? "active"
                                                    : ""
                                            }`}
                                            key={`ti-${index}`}
                                        >
                                            <span className="htab-item-link">
                                                {item.title}
                                            </span>
                                        </Nav.Item>
                                    </NavLink>
                                );
                            })}
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            {items.map((item, index) => {
                                const TabContent = item.content;
                                return (
                                    <Tab.Pane
                                        unmountOnExit={true}
                                        eventKey={item.key}
                                        key={`ti-${index}`}
                                    >
                                        <TabContent />
                                    </Tab.Pane>
                                );
                            })}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </TabBody>
    );
}

export default withRouter<any, any>(HorizontalTabs);
