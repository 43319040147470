import { Dispatch } from "redux";
import { IStore } from "../../store";
import api from "../../../api";
import * as acreators from "../../acreators/user";
import { AsyncActionState } from "../../utils/asyncAction";
import * as userActions from "../../actions/user";
import { getCompanyById } from "../company";
import {
    IUser,
    ISearchGYAAdminsResponse,
    IPagingParams
} from "../../../api/_types";
import { AxiosResponse } from "axios";
import { getPropertyById } from "../property";

export const getMe = () => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(acreators.setGetMeStatus(AsyncActionState.Pending));

        try {
            const response = await api.user.me();
            const user = (response as any).data.user;

            if (user.company !== undefined) {
                try {
                    await getCompanyById(user.company)(dispatch);
                } catch (e) {
                    console.log(e);
                }
            }

            dispatch(acreators.setMe(user));
            dispatch(acreators.setGetMeStatus(AsyncActionState.Success));
        } catch (e) {
            dispatch(acreators.setGetMeStatus(AsyncActionState.Error));

            console.log("Error getting me", e);
        }
    };
};

export const updateMe = (user: IUser, file?: File) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(acreators.setUpdateMeStatus(AsyncActionState.Pending));
        const apiMethod =
            user._id === undefined ? api.user.createUser : api.user.updateMe;

        try {
            await apiMethod(user, file);

            dispatch(acreators.setUpdateMeStatus(AsyncActionState.Success));

            // Go refresh the user record with the latest changes from the API
            await getMe()(dispatch, getState);
        } catch (error) {
            dispatch(acreators.setUpdateMeStatus(AsyncActionState.Error));
        }
    };
};

export const searchVendors = (searchString: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(acreators.setSearchVendorsStatus(AsyncActionState.Pending));

        try {
            const searchResult = await api.user.searchVendors(searchString);

            if (searchResult.data !== undefined) {
                dispatch(
                    acreators.setSearchVendorsResult(searchResult.data.users)
                );
            }
            dispatch(
                acreators.setSearchVendorsStatus(AsyncActionState.Success)
            );
        } catch (error) {
            return dispatch(
                acreators.setSearchVendorsStatus(AsyncActionState.Error)
            );
        }
    };
};

export const activateAccount = (password: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(acreators.setActivateAccountStatus(AsyncActionState.Pending));

        try {
            const searchResult = await api.user.activateAccount(password);

            if (searchResult.data.success === true) {
                try {
                    await getMe()(dispatch, getState);
                } catch (e) {}
            }
            dispatch(
                acreators.setActivateAccountStatus(AsyncActionState.Success)
            );
        } catch (error) {
            return dispatch(
                acreators.setActivateAccountStatus(AsyncActionState.Error)
            );
        }
    };
};

export const searchGYAAdmins = (
    searchString: string = "",
    paging: IPagingParams = {
        currentPage: 0,
        itemsPerPage: 10
    }
) => {
    return async (dispatch: Dispatch) => {
        dispatch(
            acreators.setSearchGYAAdminUsersStatus(AsyncActionState.Pending)
        );

        try {
            const searchResult: AxiosResponse<
                ISearchGYAAdminsResponse
            > = await api.user.searchGYAAdmins(searchString, paging);

            if (searchResult.data !== undefined) {
                dispatch(acreators.setGYAAdminUsers(searchResult.data));
            }
            dispatch(
                acreators.setSearchGYAAdminUsersStatus(AsyncActionState.Success)
            );
        } catch (error) {
            return dispatch(
                acreators.setSearchGYAAdminUsersStatus(AsyncActionState.Error)
            );
        }
    };
};

export const createGYAAdmin = (user: IUser) => {
    return async (dispatch: Dispatch) => {
        dispatch(acreators.setCreateGYAAdminStatus(AsyncActionState.Reset));

        try {
            await api.user.createUser(user);

            await searchGYAAdmins("")(dispatch);
        } catch (e) {
            return dispatch(
                acreators.setCreateGYAAdminStatus(AsyncActionState.Error)
            );
        }

        dispatch(acreators.setCreateGYAAdminStatus(AsyncActionState.Success));
    };
};

export const deleteAccount = () => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { user } = getState();
        if (user.me === undefined) {
            return;
        }

        dispatch(acreators.setDeleteMeStatus(AsyncActionState.Pending));

        try {
            await api.user.deleteAccount();
        } catch (e) {
            return dispatch(
                acreators.setDeleteMeStatus(AsyncActionState.Error)
            );
        }

        return dispatch(acreators.setDeleteMeStatus(AsyncActionState.Success));
    };
};

export const deleteUserPhoto = () => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { user } = getState();
        if (user.me === undefined) {
            return;
        }

        dispatch(acreators.setDeleteUserPhotoStatus(AsyncActionState.Pending));

        try {
            await api.user.deletePhoto();
            await getMe()(dispatch, getState);
        } catch (e) {
            return dispatch(
                acreators.setDeleteUserPhotoStatus(AsyncActionState.Error)
            );
        }

        return dispatch(
            acreators.setDeleteUserPhotoStatus(AsyncActionState.Success)
        );
    };
};

export const resendEmailVerification = (token: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {

        const {property} = getState();

        dispatch(
            acreators.setResendEmailVerificationStatus(AsyncActionState.Pending)
        );

        try {
            await api.user.resendEmailVerification(token);

            // Reload the property so we refresh the owner's email activation token in state 
            if (property.viewingProperty?._id !== undefined) {
                await getPropertyById(property.viewingProperty?._id)(dispatch, getState);
            }
        } catch (error) {
            return dispatch(
                acreators.setResendEmailVerificationStatus(
                    AsyncActionState.Error
                )
            );
        }

        dispatch(
            acreators.setResendEmailVerificationStatus(AsyncActionState.Success)
        );
    };
};

export const setSearchVendorsStatus = (status: AsyncActionState) => {
    return {
        type: userActions.UserAccountTypes.SEARCH_VENDORS_STATUS,
        payload: status
    } as userActions.SearchVendorsStatus;
};

export const resetGetMeState = () => {
    return acreators.setGetMeStatus(AsyncActionState.Reset);
};

export const resetUpdateMeState = () => {
    return acreators.setUpdateMeStatus(AsyncActionState.Reset);
};

export const resetSearchVendorResults = () => {
    return acreators.setSearchVendorsResult([]);
};

export const resetCreateUserStatus = () => {
    return acreators.setCreateGYAAdminStatus(AsyncActionState.Reset);
};

export const resetDeleteUserPhotoStatus = () => {
    return acreators.setDeleteUserPhotoStatus(AsyncActionState.Reset);
};

export const resetResendEmailVerificationStatus = () => {
    return acreators.setResendEmailVerificationStatus(AsyncActionState.Reset);
};
