import React, { Component } from "react";
import { FormControl, Button } from "react-bootstrap";
import { InputGroup, Form } from "react-bootstrap";
import "./styles.scss";

interface IProps {
    inlineForm?: boolean;
    initialValue?: string;
    search: () => void;
    updateSearchText: (str: string) => void;
}
interface IState {}

export default class PropertySearchInput extends Component<IProps, IState> {
    search = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.search();
    };

    render() {
        const { initialValue, inlineForm = true } = this.props;

        const formContent = (
            <InputGroup className="">
                <FormControl
                    onChange={(e: any) =>
                        this.props.updateSearchText(e.currentTarget
                            .value as string)
                    }
                    defaultValue={initialValue!}
                    placeholder={"Search address line 1 + postcode"}
                    autoComplete="off"
                />
                <InputGroup.Append>
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={() => this.props.search()}
                    >
                        Search
                    </Button>
                </InputGroup.Append>
            </InputGroup>
        );

        if (inlineForm === false) {
            return formContent;
        }

        return (
            <Form
                onSubmit={this.search}
                data-testid="psearch-input"
                className="searchForm"
            >
                {formContent}
            </Form>
        );
    }
}
