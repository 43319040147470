import { CSSProperties } from "react";

export default {
    container: {
        position: "relative"
    } as CSSProperties,
    loadingContainer: {
        position: "absolute",
        zIndex: 99999,
        backgroundColor: "white",
        opacity: 0.8,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: 200
    } as CSSProperties,
    loadingContent: {},
    spinnerStyle: {
        width: "3em",
        height: "3em"
    }
};
