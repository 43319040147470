import { IProperty } from "../../../api/_types";
import moment from "moment";

export const hasLeaseHoldWarning = ({createdon, leaseholdPackSent, ownership}: IProperty) => {
    const isLeasehold = ownership === "leasehold";
    let result = true;

    if (isLeasehold === false || (isLeasehold === true && leaseholdPackSent === "yes")) {
        result = false;
    }

    return {
        result,
        date: moment(createdon)
    };
};

export const hasVendorSolicitorWarning = ({contacts, createdon}: IProperty) => ({
    result: contacts?.solicitor?.contact === undefined,
    date: moment(createdon)
});

export const hasVendorMortgageBrokerWarning = ({contacts, createdon}: IProperty) => ({
    result: contacts?.mBroker?.contact === undefined,
    date: moment(createdon)
});
