import React from "react";
import { connect } from "react-redux";
import { ICreatePropertyNoteFields } from "../../../../../../api/_types";
import { setNoteToEdit } from "../../../../../../redux/acreators/property/notes";
import { createNote } from "../../../../../../redux/api/property/notes";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ManageNote from "../../components/ManageNote";

interface IProps {
    show: boolean;
    createNoteStatus: AsyncActionState;
    timelineKey?: number;
    createNote: (note: ICreatePropertyNoteFields) => void;
    close: () => void;
}

function CreateNote(props: IProps) {
    const { show, createNote, createNoteStatus, close, timelineKey } = props;
    return (
        <ManageNote
            title="Add a new note"
            show={show}
            saveNote={createNote}
            asyncStatus={createNoteStatus}
            timelineKey={timelineKey}
            close={close}
        />
    );
}

const mapStateToProps = ({ notes }: IStore) => ({
    createNoteStatus: notes.createNoteStatus
});

export default connect(
    mapStateToProps,
    { createNote, resetNoteToEdit: setNoteToEdit.bind(undefined, undefined) }
)(CreateNote);
