import React, { Component } from "react";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import { IUser } from "../../../../../../api/_types";

interface IProps {
    user?: IUser;
}
interface IState {}

class UsernameContainer extends Component<IProps, IState> {
    render() {
        const { user } = this.props;

        if (user === undefined) {
            return null;
        }
        return (
            <span>
                {user.firstName} {user.lastName}
            </span>
        );
    }
}

const mapStateToProps = ({ user }: IStore) => ({
    user: user.me
});

export default connect(
    mapStateToProps,
    {}
)(UsernameContainer);
