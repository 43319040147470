import React, { Component } from "react";
import TabNav from "../../../../../_sharedComponents/TabNav";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import { IUser } from "../../../../../../api/_types";
import routes from "../../../../../../navigation/routes";

interface IProps {
    user?: IUser;
}

class EditCompanyTopNav extends Component<IProps> {
    render() {
        const { user } = this.props;

        if (user === undefined) {
            return null;
        }

        const links = [
            {
                to: routes.companyEdit.url,
                displayTitle: "Company"
            },
            {
                to: routes.branchManage.url,
                displayTitle: "Branches"
            },
            {
                to: routes.companyProperties.url,
                displayTitle: "Properties"
            },
            {
                to: routes.companyContacts.url,
                displayTitle: "Contacts"
            }
        ];

        if (
            [UserTypes.GYA_ADMIN, UserTypes.COMPANY_ADMIN].includes(
                user.type
            ) === true
        ) {
            links.splice(2, 0, {
                to: routes.branchCreate.url,
                displayTitle: "Add Branch"
            });
            links.push(
                {
                    to: routes.companyMIReport.url,
                    displayTitle: "Workflow"
                },
                {
                    to: routes.companyUsers.url,
                    displayTitle: "Admins"
                },
                {
                    to: routes.companyTimeline.url,
                    displayTitle: "Timeline"
                }
            );
        }

        return <TabNav links={links} />;
    }
}

const mapStateToProps = ({ user }: IStore) => ({
    user: user.me
});

export default connect(
    mapStateToProps,
    {}
)(EditCompanyTopNav);
