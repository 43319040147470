import React, { useMemo } from "react";
import Select from "react-select";
import { PropertyOwnership } from "../../../../../../../api/_types";
import { FormEventType } from "../../../../../../../lib/types/forms";

interface IProps {
    current?: PropertyOwnership;
    onChangeInputField: (e: FormEventType) => void;
}

export default function OwnershipSelect(props: IProps) {
    const { onChangeInputField, current } = props;
    const labels: any = useMemo(() => {
        return {
            unknown: "Unknown",
            freehold: "Freehold",
            leasehold: "Leasehold"
        };
    }, []);
    return (
        <>
            <label>Ownership status</label>
            <Select
                value={
                    {
                        label: current ? labels[current] || current : current,
                        value: current
                    } as any
                }
                options={
                    [
                        {
                            label: "Unknown",
                            value: "unknown"
                        },
                        {
                            label: "Leasehold",
                            value: "leasehold"
                        },
                        {
                            label: "Freehold",
                            value: "freehold"
                        }
                    ] as Array<{
                        label: string;
                        value: PropertyOwnership;
                    }>
                }
                //@ts-ignore
                onChange={(option: {
                    label: string;
                    value: PropertyOwnership;
                }) => {
                    onChangeInputField({
                        currentTarget: {
                            value: option.value
                        }
                    } as any);
                }}
            />
        </>
    );
}
