import React, { Component } from "react";
import { Pagination } from "react-bootstrap";
import { pagination } from "../../../lib/utils/pager/utils";

interface IProps {
    currentPage: number;
    numPages: number;
    pageClick?: (pageIndex: number) => void;
    style?: React.CSSProperties;
}

export default class Pager extends Component<IProps> {
    handlePageClick = (pageIndex: number) => {
        const { pageClick } = this.props;
        if (pageClick !== undefined) {
            pageClick(pageIndex);
        }
    };

    render() {
        const { currentPage, numPages, style } = this.props;
        const pages = pagination(currentPage + 1, numPages + 1);
        return (
            <div style={style}>
                <Pagination>
                    {pages.map((page: string | number, index: number) => {
                        if (typeof page === "string") {
                            return <Pagination.Ellipsis />;
                        }

                        return (
                            <Pagination.Item
                                key={`pager-${index}`}
                                active={page - 1 === currentPage}
                                onClick={() => this.handlePageClick(page - 1)}
                            >
                                {page}
                            </Pagination.Item>
                        );
                    })}
                </Pagination>
            </div>
        );
    }
}
