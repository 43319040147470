import React, { Component } from "react";
import styles from "./UserPanelStyle";
import { Link } from "react-router-dom";
import Username from "../../../../modules/core/user/containers/Username";
import UserType from "../../../../modules/core/user/containers/UserType";
import UserAvatar from "../../../../modules/core/user/containers/UserAvatar";

interface IProps {}
interface IState {}

export default class UserPanel extends Component<IProps, IState> {
    render() {
        return (
            <div className="userPanel" style={{
                backgroundColor: "#f3f3f3",
                borderRadius: 8,
                padding: "5px"
            }}>
                <UserAvatar avatarContainerStyle={{display: "block"}} rounded={true} />
                <div
                    style={Object.assign(
                        {},
                        styles.userPanelText,
                        styles.userPanelContent
                    )}
                >
                    <p style={styles.small}>Logged in as:</p>
                    <Link to={"/profile"}>
                        <p>
                            <Username />
                        </p>
                    </Link>
                    <p style={styles.small}>
                        <UserType />
                    </p>
                </div>
            </div>
        );
    }
}
