import * as actions from "../../actions/user";
import { AsyncActionState } from "../../utils/asyncAction";
import { IUser, ISearchGYAAdminsResponse } from "../../../api/_types";

export const setMe = (user: IUser) => {
    return {
        type: actions.UserAccountTypes.SET_ME,
        payload: user
    } as actions.SetMe;
};

export const setUpdateMeStatus = (status: AsyncActionState) => {
    return {
        type: actions.UserAccountTypes.SAVE_ME_STATUS,
        payload: status
    } as actions.UpdateMeStatus;
};

export const setGetMeStatus = (status: AsyncActionState) => {
    return {
        type: actions.UserAccountTypes.GET_ME_STATUS,
        payload: status
    } as actions.GetMeStatus;
};

export const setSearchVendorsStatus = (payload: AsyncActionState) => {
    return {
        type: actions.UserAccountTypes.SEARCH_VENDORS_STATUS,
        payload
    } as actions.SearchVendorsStatus;
};

export const setSearchVendorsResult = (payload: IUser[]) => {
    return {
        type: actions.UserAccountTypes.SET_SEARCH_VENDORS_RESULT,
        payload
    } as actions.SetSearchVendorsResult;
};

export const setActivateAccountStatus = (payload: AsyncActionState) => {
    return {
        type: actions.UserAccountTypes.SET_ACTIVATE_ACCOUNT_STATUS,
        payload
    } as actions.SetActivateAccountStatus;
};

export const resetActivateAccountStatus = () => {
    return {
        type: actions.UserAccountTypes.SET_ACTIVATE_ACCOUNT_STATUS,
        payload: AsyncActionState.Reset
    } as actions.SetActivateAccountStatus;
};

export const setGYAAdminUsers = (payload: ISearchGYAAdminsResponse) => {
    return {
        type: actions.UserAccountTypes.SET_GYA_ADMIN_USERS,
        payload
    } as actions.SetSearchGYAdminUsers;
};

export const setSearchGYAAdminUsersStatus = (status: AsyncActionState) => {
    return {
        type: actions.UserAccountTypes.SEARCH_GYA_ADMIN_USERS_STATUS,
        payload: status
    } as actions.SetSearchGYAAdminSearchStatus;
};

export const setCreateGYAAdminStatus = (status: AsyncActionState) => {
    return {
        type: actions.UserAccountTypes.CREATE_GYA_ADMIN_STATUS,
        payload: status
    } as actions.SetCreateGYAAdminStatus;
};

export const setEditUserUpdateStatus = (status: AsyncActionState) => {
    return {
        type: actions.UserAccountTypes.SET_EDIT_USER_UPDATE_STATUS,
        payload: status
    } as actions.SetEditUserUpdateStatus;
};

export const setEditUser = (user?: IUser) => {
    return {
        type: actions.UserAccountTypes.SET_EDIT_USER,
        payload: user
    } as actions.SetEditUser;
};

export const setDeleteMeStatus = (status: AsyncActionState) => {
    return {
        type: actions.UserAccountTypes.DELETE_ME_STATUS,
        payload: status
    } as actions.DeleteMeStatus;
};

export const setDeleteUserPhotoStatus = (status: AsyncActionState) => {
    return {
        type: actions.UserAccountTypes.DELETE_USER_PHOTO_STATUS,
        payload: status
    } as actions.DeleteUserPhotoStatus;
};

export const setResendEmailVerificationStatus = (status: AsyncActionState) => {
    return {
        type: actions.UserAccountTypes.RESEND_EMAIL_VERIFICATION_STATUS,
        payload: status
    } as actions.ResendEmailVerificationStatus;
};
