import React, { CSSProperties, FC, useEffect, useState } from "react";
import { Image, Spinner } from "react-bootstrap";
import { default as userApi } from "../../../../../../api/modules/user";
import { IUser } from "../../../../../../api/_types";
import UserAvatarPlaceholder from "../../../../../_sharedComponents/UserAvatarPlaceholder";

interface IProps {
    user: IUser;
    size?: number;
    rounded?: boolean;
    containerStyle?: CSSProperties;
}

const UserAvatar: FC<IProps> = ({ user, size, rounded, containerStyle }) => {
    const sizedStyle: CSSProperties = { width: size, height: size };
    const [img, setImg] = useState<string>();
    const [imgLoading, setImgLoading] = useState(true);

    const style: CSSProperties = {
        ...sizedStyle,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...containerStyle
    };

    if (rounded === true) {
        sizedStyle.borderRadius = 4;
    }

    useEffect(() => {
        if (!user.avatar) {
            setImgLoading(false);
            return;
        }
        async function loadImage() {
            let downloadedImage;

            setImgLoading(true);

            try {
                downloadedImage = await userApi.getAvatar(user._id!);
            } catch (e) {
                setImgLoading(false);
                return;
            }
            setImgLoading(false);

            let binary = [downloadedImage.data];
            setImg(
                URL.createObjectURL(new Blob(binary, { type: "image/jpeg" }))
            );
        }

        loadImage();
    }, [user]);

    if (imgLoading === true) {
        return (
            <div style={style}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }
    if (!user.avatar || !img) {
        return (
            <div style={style}>
                <UserAvatarPlaceholder size={size} />
            </div>
        );
    }

    return (
        <div style={style}>
            <Image style={sizedStyle} src={img} />
        </div>
    );
};

UserAvatar.defaultProps = { size: 250 };

export default UserAvatar;
