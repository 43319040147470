import React, { FC } from "react";
import { Accordion, Button, Card } from "react-bootstrap";

interface IProps {
    title: string;
}
const ContactAccordionItem:FC<IProps> =  ({title, children}) => {
    return (
        <Card>
            <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey={title}>
                {title}
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={title}>
                <Card.Body>
                    {children}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default ContactAccordionItem;
