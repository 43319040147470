import { AsyncActionState } from "../../utils/asyncAction";
import {
    IProperty,
    IUser,
    IPropertyOffer,
    IPropertyViewing,
    IPropertyAskingPrice,
    IMIReportResponse,
    IMIKeyStatsResponse
} from "../../../api/_types";
import { IGetPropertiesResponse } from "../../../api/modules/property";

export enum PropertyTypes {
    SET_PROPERTIES = "SET_PROPERTIES",
    SET_VIEWING_PROPERTY = "SET_VIEWING_PROPERTY",
    CREATE_PROPERTIES_STATUS = "CREATE_PROPERTIES_STATUS",
    DELETE_PROPERTIES_STATUS = "DELETE_PROPERTIES_STATUS",
    GET_PROPERTY_STATUS = "GET_PROPERTY_STATUS",
    GET_PROPERTIES_STATUS = "GET_PROPERTIES_STATUS",
    ADD_PROPERTY_VIEWING_STATUS = "ADD_PROPERTY_VIEWING_STATUS",
    REMOVE_PROPERTY_VIEWING_STATUS = "REMOVE_PROPERTY_VIEWING_STATUS",
    SAVE_MI_STATUS = "SAVE_MI_STATUS",
    ADD_MI_ASKING_PRICE_STATUS = "ADD_MI_ASKING_PRICE_STATUS",
    REMOVE_MI_ASKING_PRICE_STATUS = "REMOVE_MI_ASKING_PRICE_STATUS",
    UPDATE_PROPERTY_TIMELINE_STATUS = "UPDATE_PROPERTY_TIMELINE_STATUS",
    SET_CREATE_PROPERTY_VENDOR_REF = "SET_CREATE_PROPERTY_VENDOR_REF",
    SET_ADD_PROPERTY_OFFER_STATUS = "SET_ADD_PROPERTY_OFFER_STATUS",
    SET_REMOVE_PROPERTY_OFFER_STATUS = "SET_REMOVE_PROPERTY_OFFER_STATUS",
    SET_UPDATE_PROPERTY_OFFER_STATUS = "SET_UPDATE_PROPERTY_OFFER_STATUS",
    SET_PROPERTY_OFFER_TO_EDIT = "SET_PROPERTY_OFFER_TO_EDIT",
    SET_UPDATE_PROPERTY_VIEWING_STATUS = "SET_UPDATE_PROPERTY_VIEWING_STATUS",
    SET_PROPERTY_VIEWING_TO_EDIT = "SET_PROPERTY_VIEWING_TO_EDIT",
    SET_UPDATE_PROPERTY_ASKING_PRICE_STATUS = "SET_UPDATE_PROPERTY_ASKING_PRICE_STATUS",
    SET_PROPERTY_ASKING_PRICE_TO_EDIT = "SET_PROPERTY_ASKING_PRICE_TO_EDIT",
    GET_MI_REPORT_STATUS = "GET_MI_REPORT_STATUS",
    SET_MI_REPORT_DATA = "SET_MI_REPORT_DATA",
    SET_MI_REPORT_KEYSTATS = "SET_MI_REPORT_KEYSTATS",
    SET_UPDATE_PROPERTY_CONTACTS_STATUS = "SET_UPDATE_PROPERTY_CONTACTS_STATUS",
    SET_RESET_PROPERTY_SALE_STATUS = "SET_RESET_PROPERTY_SALE_STATUS",
    SET_RESTORE_PROPERTY_SALE_STATUS = "SET_RESTORE_PROPERTY_SALE_STATUS",
    SET_DELETE_RESTORE_PROPERTY_SALE_STATUS = "SET_DELETE_RESTORE_PROPERTY_SALE_STATUS",
    SEND_MI_PDF_STATUS = "SEND_MI_PDF_STATUS",
    DELETE_PROPERTY_PHOTO_STATUS = "DELETE_PROPERTY_PHOTO_STATUS"
}

export interface SetProperties extends ReduxAction {
    type: PropertyTypes.SET_PROPERTIES;
    payload: IGetPropertiesResponse;
}

export interface SetViewingProperty extends ReduxAction {
    type: PropertyTypes.SET_VIEWING_PROPERTY;
    payload: IProperty | undefined;
}

export interface CreatePropertyStatus extends ReduxAction {
    type: PropertyTypes.CREATE_PROPERTIES_STATUS;
    payload: AsyncActionState;
}

export interface DeletePropertyStatus extends ReduxAction {
    type: PropertyTypes.DELETE_PROPERTIES_STATUS;
    payload: AsyncActionState;
}

export interface GetPropertiesStatus extends ReduxAction {
    type: PropertyTypes.GET_PROPERTIES_STATUS;
}

export interface GetPropertyStatus extends ReduxAction {
    type: PropertyTypes.GET_PROPERTY_STATUS;
    payload: AsyncActionState;
}

export interface AddPropertyViewingStatus extends ReduxAction {
    type: PropertyTypes.ADD_PROPERTY_VIEWING_STATUS;
    payload: AsyncActionState;
}

export interface RemovePropertyViewingStatus extends ReduxAction {
    type: PropertyTypes.REMOVE_PROPERTY_VIEWING_STATUS;
    payload: AsyncActionState;
}

export interface SaveMIStatus {
    type: PropertyTypes.SAVE_MI_STATUS;
    payload: AsyncActionState;
}

export interface AddMIAskingPriceStatus {
    type: PropertyTypes.ADD_MI_ASKING_PRICE_STATUS;
    payload: AsyncActionState;
}

export interface RemoveMIAskingPriceStatus {
    type: PropertyTypes.REMOVE_MI_ASKING_PRICE_STATUS;
    payload: AsyncActionState;
}

export interface UpdatePropertyTimelineStatus {
    type: PropertyTypes.UPDATE_PROPERTY_TIMELINE_STATUS;
    payload: AsyncActionState;
}

export interface SetCreatePropertyVendorRef {
    type: PropertyTypes.SET_CREATE_PROPERTY_VENDOR_REF;
    payload: IUser | undefined;
}

export interface SetAddPropertyOfferStatus {
    type: PropertyTypes.SET_ADD_PROPERTY_OFFER_STATUS;
    payload: AsyncActionState;
}

export interface SetRemovePropertyOfferStatus {
    type: PropertyTypes.SET_REMOVE_PROPERTY_OFFER_STATUS;
    payload: AsyncActionState;
}

export interface SetUpdatePropertyOfferStatus {
    type: PropertyTypes.SET_UPDATE_PROPERTY_OFFER_STATUS;
    payload: AsyncActionState;
}

export interface SetPropertyOfferToEdit {
    type: PropertyTypes.SET_PROPERTY_OFFER_TO_EDIT;
    payload: IPropertyOffer;
}

export interface SetUpdatePropertyViewingStatus {
    type: PropertyTypes.SET_UPDATE_PROPERTY_VIEWING_STATUS;
    payload: AsyncActionState;
}

export interface SetPropertyViewingToEdit {
    type: PropertyTypes.SET_PROPERTY_VIEWING_TO_EDIT;
    payload: IPropertyViewing;
}

export interface SetUpdatePropertyAskingPriceStatus {
    type: PropertyTypes.SET_UPDATE_PROPERTY_ASKING_PRICE_STATUS;
    payload: AsyncActionState;
}

export interface SetPropertyAskingPriceToEdit {
    type: PropertyTypes.SET_PROPERTY_ASKING_PRICE_TO_EDIT;
    payload: IPropertyAskingPrice;
}

export interface GetMIReportStatus {
    type: PropertyTypes.GET_MI_REPORT_STATUS;
    payload: AsyncActionState;
}

export interface SetMIReportData {
    type: PropertyTypes.SET_MI_REPORT_DATA;
    payload: IMIReportResponse;
}

export interface SetMIReportKeyStats {
    type: PropertyTypes.SET_MI_REPORT_KEYSTATS;
    payload: IMIKeyStatsResponse;
}

export interface UpdatePropertyContactsStatus {
    type: PropertyTypes.SET_UPDATE_PROPERTY_CONTACTS_STATUS;
    payload: AsyncActionState;
}

export interface ResetPropertySaleStatus {
    type: PropertyTypes.SET_RESET_PROPERTY_SALE_STATUS;
    payload: AsyncActionState;
}

export interface RestorePropertySaleStatus {
    type: PropertyTypes.SET_RESTORE_PROPERTY_SALE_STATUS;
    payload: AsyncActionState;
}

export interface DeleteRestorePropertySaleStatus {
    type: PropertyTypes.SET_DELETE_RESTORE_PROPERTY_SALE_STATUS;
    payload: AsyncActionState;
}

export interface SendMiPdfStatus {
    type: PropertyTypes.SEND_MI_PDF_STATUS;
    payload: AsyncActionState;
}

export interface DeletePropertyPhotoStatus {
    type: PropertyTypes.DELETE_PROPERTY_PHOTO_STATUS;
    payload: AsyncActionState;
}
