import React, { Component } from "react";
import { connect } from "react-redux";
import { IBranch, IPropertyTimelineEntity } from "../../../../../../api/_types";
import { IStore } from "../../../../../../redux/store";
import Timeline from "../../../property/components/Timeline";
import { updateBranchTimeline } from "../../../../../../redux/api/branch";

interface IProps {
    branch?: IBranch;
    updateBranchTimeline: (timelineItems: IPropertyTimelineEntity[]) => void;
}
interface IState {}

class BranchTimelineContainer extends Component<IProps, IState> {
    render() {
        const { branch, updateBranchTimeline } = this.props;
        if (branch === undefined || branch.timelineTemplate === undefined) {
            return null;
        }

        return (
            //@ts-ignore
            <Timeline
                timeline={branch.timelineTemplate}
                timelineTemplate={branch.timelineTemplate}
                saveTimeline={updateBranchTimeline}
                editingTemplate={false}
                editingTitle={false}
                editingDescription={true}
            />
        );
    }
}

const mapStateToProps = ({ branch }: IStore) => ({
    branch: branch.viewingBranch
});

export default connect(
    mapStateToProps,
    { updateBranchTimeline }
)(BranchTimelineContainer);
