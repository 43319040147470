import React, { FC } from "react";
import "./styles.scss";

interface IProps {
    addressLine1: string;
    addressPostcode: string;
    value?: number;
}

const PropertySearchItem: FC<IProps> = ({
    addressLine1,
    addressPostcode,
    value
}) => {
    return (
        <div className="search-item">
            <div>
                {addressLine1} {addressPostcode}
            </div>
            {/* <p><small>£{value?.toLocaleString?.()}</small></p> */}
        </div>
    );
};

export default PropertySearchItem;
