import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { IPropertyTimelineEntity } from "../../../../../../../api/_types";
import {
    IPropertyTimelineEntityState, IPropertyTimelineEntityType
} from "../../../../../../../redux/_types/property/types";
import styles from "../styles";
import localStyles from "./styles";

interface IProps {
    item: any;
    timelineEntityKey: number;
    overrideValue?: string | Date | number;
    rawVal?: string;
}

export default class TimlineTableItem extends Component<IProps> {
    render() {
        const { item, timelineEntityKey, overrideValue, rawVal } = this.props;
        let cellStyle: React.CSSProperties;
        let hoursOverdue = 0;
        let timelineItem: IPropertyTimelineEntity;

        if (overrideValue === undefined) {
            timelineItem = _.find(
                item.timeline.data,
                (i) => i.key === timelineEntityKey
            );
        } else {
            timelineItem = {
                completedOn: overrideValue
            } as any;
        }

        if (rawVal !== undefined) {
            return <div className="text-center">{rawVal }</div>;
        }

        if (
            timelineItem !== undefined &&
            timelineItem.type === IPropertyTimelineEntityType.FINANCIAL &&
            timelineItem.state === IPropertyTimelineEntityState.NA
        ) {
            return <div className="text-center">N/A</div>;
        }

        if ((timelineItem && timelineItem.startedOn !== undefined) || timelineItem.type === IPropertyTimelineEntityType.SURVEY) {
            const expectedCompletionDate = moment(timelineItem.warningDate);

            hoursOverdue = moment().diff(expectedCompletionDate, "hours");
        }

        
        if (
            timelineItem === undefined ||
            (hoursOverdue <= 0 && timelineItem.completedOn === undefined) ||
            overrideValue === null
        ) {
            if (timelineItem?.warningDate !== undefined) {
                const diffInDays = moment(timelineItem.warningDate).diff(
                    moment(),
                    "days"
                );


                return (
                    <div className="text-center">{`${diffInDays} days`}</div>
                );
            }
            return <></>;
        }

        if (hoursOverdue <= 0) {
            let recentlyUpdatedStyle = {};
            let timeDiffInHours = moment().diff(moment(timelineItem.completedOn), "hours"); 
            if (timeDiffInHours <= 24) {
                recentlyUpdatedStyle = styles.updatedInLastDay;
            }
            else if (timeDiffInHours <= 168) {
                recentlyUpdatedStyle = styles.updatedInLastWeek;
            }

            cellStyle = { ...localStyles.cellStyle, ...recentlyUpdatedStyle };
        }

        return (
            <div
                className="d-flex align-items-center justify-content-center text-center"
                // @ts-ignore
                style={cellStyle}
            >
                <div className="d-flex">
                    {hoursOverdue > 0 ? (
                        <WarningCalendar
                            title={`Overdue by ${Math.round(
                                hoursOverdue / 24
                            )} days`}
                        />
                    ) : (
                        moment(timelineItem.completedOn).format("DD-MMM")
                    )}
                </div>
            </div>
        );
    }
}

function WarningCalendar(props: { title: string; fill?: string }) {
    const { title, fill = "#F24236" } = props;

    return (
        <svg
            width="28"
            height="28"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 253 253"
        >
            <title>{title}</title>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path
                        fill={fill}
                        d="M221.38,31.63H210.83V10.54A10.54,10.54,0,0,0,200.29,0H189.75a10.54,10.54,0,0,0-10.54,10.54V31.63H73.79V10.54A10.54,10.54,0,0,0,63.25,0H52.71A10.54,10.54,0,0,0,42.17,10.54V31.63H31.63A31.66,31.66,0,0,0,0,63.25V221.38A31.66,31.66,0,0,0,31.63,253H221.38A31.66,31.66,0,0,0,253,221.38V63.25A31.66,31.66,0,0,0,221.38,31.63Zm10.54,189.75a10.57,10.57,0,0,1-10.54,10.54H31.63a10.57,10.57,0,0,1-10.55-10.54V105.84H231.92Z"
                    />
                    <path
                        fill={fill}
                        d="M109.54,209q0-6.75,4.36-10.2t12.7-3.46q8,0,12.45,3.54T143.46,209A12.25,12.25,0,0,1,139,219q-4.45,3.66-12.4,3.65-8.14,0-12.6-3.57T109.54,209Zm25.56-25.66H118.29a3.66,3.66,0,0,1-3.66-3.41l-4.41-60.83a3.67,3.67,0,0,1,3.66-3.93h25.63a3.67,3.67,0,0,1,3.66,3.93l-4.41,60.83A3.68,3.68,0,0,1,135.1,183.34Z"
                    />
                </g>
            </g>
        </svg>
    );
}
