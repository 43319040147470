import React from "react";
import { Form } from "react-bootstrap";

interface IProps {
    checked: boolean;
    onClick: () => void;
}

export default function ShowAllCheck(props: IProps) {
    const { checked, onClick } = props;
    return (
        <Form.Check
            data-testid="show-all-check"
            id="lfSHow"
            label="Show all steps in sales process"
            checked={checked}
            onChange={onClick}
            custom={true}
        />
    );
}
