import React, { FormEvent, useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
    IAdditionalContact,
    IBuyer,
    ICompanyContact,
    ICompanyContactPOC,
    IPagingResponse,
    IPropertyContacts,
    IUser
} from "../../../../../../../api/_types";
import { IOnContactSelectResult } from "../../../../contacts/containers/ContactCard/types";
import BuyersContacts from "./BuyersContacts";
import VendorContactFields from "./VendorContactFields";
import { Nav } from "react-bootstrap";

export interface IVendorContactsProps {
    contacts: IPropertyContacts;
    buyer: IBuyer;
    vendor: IUser;
    branchMembers: IUser[];
    branchMembersPaging: IPagingResponse;
    onChangeInputField: (stateField: string) => (event: FormEvent<any>) => void;
    onChangeContact: (contacts: IPropertyContacts) => void;
    checkUser: (item: IUser) => void;
    pageBranchMembers: (desiredPage: number) => void;
    saveContacts: () => void;
}

export default function VendorContacts(props: IVendorContactsProps) {
    const {
        onChangeInputField,
        contacts,
        branchMembers,
        branchMembersPaging,
        pageBranchMembers,
        checkUser,
        onChangeContact,
        buyer,
        vendor
    } = props;

    const [showVendorContacts, setShowVendorContacts] = useState(true);

    const setSolicitor = useCallback(
        ({ contact, poc }) => {
            const _contacts = { ...contacts };
            _contacts.solicitor = {
                contact: contact as ICompanyContact,
                poc: poc as ICompanyContactPOC
            };

            onChangeContact(_contacts);
        },
        [contacts, onChangeContact]
    );

    const setMortgageBroker = useCallback(
        ({ contact, poc }) => {
            const _contacts = { ...contacts };
            _contacts.mBroker = {
                contact: contact as ICompanyContact,
                poc: poc as ICompanyContactPOC
            };

            onChangeContact(_contacts);
        },
        [contacts, onChangeContact]
    );

    const setBuyerDetailsContact = useCallback(
        (key: "buyer" | "solicitor" | "mBroker") => {
            return ({ contact, poc }: IOnContactSelectResult) => {
                const _contacts = { ...contacts };
                _contacts.buyerDetails[key] = {
                    contact: contact as ICompanyContact,
                    poc: poc as ICompanyContactPOC
                };
                onChangeContact(_contacts);
            };
        },
        [contacts, onChangeContact]
    );

    const addAdditionalContact = (contact: IAdditionalContact) => {
        const additionalContacts = contacts.additionalContacts;
        additionalContacts.push(contact);
        onChangeContact({...contacts, additionalContacts})
    };

    const removeAdditionalContact = (obj: IAdditionalContact) => {
        const additionalContacts = contacts.additionalContacts.filter(c => c !== obj);
        onChangeContact({...contacts, additionalContacts})
    };

    const editAdditionalContact = (editedContact: IAdditionalContact) => {
        const additionalContacts = contacts.additionalContacts.map((c) => {
            return c === editedContact ? editedContact : c;
        });
        onChangeContact({...contacts, additionalContacts})
    };

    return (
        <Row data-testid="vendor-contacts">
            <Col sm={3}>
                <Nav className="flex-column">
                    <Nav.Link
                        className={`htab-item ${showVendorContacts ? "active" : ""}`}
                        onClick={() => setShowVendorContacts(true)}
                    >
                        <span className="htab-item-link">
                            Vendor contacts
                        </span>
                    </Nav.Link>
                    <Nav.Link
                        className={`htab-item ${showVendorContacts ? "" : "active"}`}
                        onClick={() => setShowVendorContacts(false)}
                    >
                        <span className="htab-item-link">
                            Buyer contacts
                        </span>
                    </Nav.Link>
                </Nav>
            </Col>

            <Col sm={9}>
                {
                    showVendorContacts
                        ?
                            <VendorContactFields
                                vendor={vendor}
                                contacts={contacts}
                                setSolicitor={setSolicitor}
                                setMortgageBroker={setMortgageBroker}
                                onChangeInputField={onChangeInputField}
                                branchMembers={branchMembers}
                                branchMembersPaging={branchMembersPaging}
                                pageBranchMembers={pageBranchMembers}
                                checkUser={checkUser}
                                saveContacts={props.saveContacts}
                                addAdditionalContact={addAdditionalContact}
                                removeAdditionalContact={removeAdditionalContact}
                                editAdditionalContact={editAdditionalContact}
                            />
                        :
                            <BuyersContacts
                                buyer={buyer}
                                setBuyerDetailsContact={setBuyerDetailsContact}
                                contacts={contacts}
                                onChangeInputField={onChangeInputField}
                                saveContacts={props.saveContacts}
                            />
                }
            </Col>
        </Row>
    );
}
