import React, { Component } from "react";
import { IStore } from "../../redux/store";
import { connect } from "react-redux";
import AccountActivationScreen from "../../components/modules/core/user/screens/AccountActivation";
import { BrowserRouter as Router } from "react-router-dom";
import SideBar from "../../components/_sharedComponents/Navigation/SideBar/SideBar";

interface IProps {}
interface IState {}

class NavigationRouter extends Component<IProps, IState> {
    render() {
        let contentMargin = 75;
        let sideBarLeft = -225;
        return (
            <Router>
                <div>
                    <div
                        style={{
                            position: "absolute",
                            width: 300,
                            left: sideBarLeft
                        }}
                    >
                        <SideBar
                            menuOpen={true}
                            hideSidebarNav={true}
                            hideAll={true}
                            hideToggle={true}
                        />
                    </div>
                    <div
                        style={{
                            marginLeft: contentMargin,
                            paddingTop: 15,
                            paddingBottom: 75,
                            paddingLeft: 0,
                            paddingRight: 0
                        }}
                    >
                        <AccountActivationScreen />
                    </div>
                </div>
            </Router>
        );
    }
}

const mapStateToProps = ({ user }: IStore) => ({
    user: user.me
});

export default connect(
    mapStateToProps,
    {}
)(NavigationRouter);
