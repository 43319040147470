import * as actions from "../../actions/companyContactPOCs";
import { AsyncActionState } from "../../utils/asyncAction";
import { ICompanyContactPOCGetListResponse } from "../../../api/modules/companyContactPOCs";
import { ICompanyContactPOC } from "../../../api/_types";

export const setViewingCompanyContactPOC = (
    payload: ICompanyContactPOC | undefined
) =>
    ({
        type: actions.CompanyContactPOCTypes.SET_VIEWING_COMPANY_CONTACT_POC,
        payload
    } as actions.SetViewingCompanyContactPOC);

export const setCompanyContactPOCs = (
    payload: ICompanyContactPOCGetListResponse
) =>
    ({
        type: actions.CompanyContactPOCTypes.SET_COMPANY_CONTACT_POCS,
        payload
    } as actions.SetCompanyContactPOCS);

export const getCompanyContactPOCsStatus = (payload: AsyncActionState) =>
    ({
        type: actions.CompanyContactPOCTypes.GET_COMPANY_CONTACT_POCS_STATUS,
        payload
    } as actions.SetCompanyContactPOCStatus);

export const createCompanyContactPOCStatus = (payload: AsyncActionState) =>
    ({
        type: actions.CompanyContactPOCTypes.CREATE_COMPANY_CONTACT_POC_STATUS,
        payload
    } as actions.CreateCompanyContactPOCStatus);

export const editCompanyContactPOCStatus = (payload: AsyncActionState) =>
    ({
        type: actions.CompanyContactPOCTypes.EDIT_COMPANY_CONTACT_POC_STATUS,
        payload
    } as actions.EditCompanyContactPOCStatus);

export const deleteCompanyContactPOCStatus = (payload: AsyncActionState) =>
    ({
        type: actions.CompanyContactPOCTypes.SET_DELETE_POC_STATUS,
        payload
    } as actions.SetDeleteCompanyContactPOCStatus);

export const resetCreateCompanyContactPOCStatus = createCompanyContactPOCStatus.bind(
    undefined,
    AsyncActionState.Reset
);
export const resetEditCompanyContactPOCStatus = editCompanyContactPOCStatus.bind(
    undefined,
    AsyncActionState.Reset
);
