import { AxiosResponse } from "axios";
import React, { FC, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Props as ReactSelectProps, ValueType } from "react-select";
import Select from "react-select/async";
import api from "../../../../../../api";
import {
    ISearchCompanyMembersResponse
} from "../../../../../../api/_types";
import usePrevious from "../../../../../../lib/utils/hooks/usePrevious";
import { createPager } from "../../../../../../lib/utils/pager";
import { IStore } from "../../../../../../redux/store";

type SelectProps = Pick<ReactSelectProps, "styles">;

const mapStateToProps = ({ company }: IStore) => ({
    company: company.viewingCompany,
});

const connector = connect(
    mapStateToProps
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends SelectProps, ReduxProps {
    bStaffIds: Array<{label: string, value: string}>;
    onChange: (users: Array<{label: string, value: string}>) => void;
}

const CompanyUserSelect: FC<IProps> = ({
    company,
    styles,
    bStaffIds,
    onChange
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const paging = createPager({itemsPerPage: 15});
    const ref = useRef<Select<SelectProps>>();
    const prevBStaffIds = usePrevious(bStaffIds)
    const loadUsers = async (inputValue: string) => {

        try {
            const searchResult: AxiosResponse<
                ISearchCompanyMembersResponse
            > = await api.company.searchMembers(
                company?._id as string,
                paging,
                inputValue,
            );

            const users = searchResult?.data?.users?.map(user => ({
                label: user.fullName,
                value: user._id
            }));

            return [{label: "Unassigned", value: null}, ...users];
        } catch (error) {} finally {
            setIsLoading(false);
        }
    };
    const onChangeOption = (value: Array<{label: string, value: string;}>) => {   

        onChange(value)
    }

    // Reset branch ref
    useEffect(() => {

        if (prevBStaffIds?.length > 0 && bStaffIds?.length === 0) {
            // @ts-ignore
            ref.current?.select?.select?.clearValue?.();
        }
    }, [prevBStaffIds, bStaffIds]);

    return (
        //@ts-ignore
        <Select
            ref={ref}
            isMulti
            styles={styles}
            loadOptions={loadUsers}
            isLoading={isLoading}
            defaultOptions={true}   
            onChange={onChangeOption}  
            defaultValue={bStaffIds as ValueType<SelectProps>[]}
        />
    );
};

export default connector(CompanyUserSelect);
