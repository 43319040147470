import React, { FC } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { IPropertyOffer } from "../../../../../../../api/_types";

type OfferFields = Pick<IPropertyOffer, "cashBuy" | "depositSize">;

interface IProps extends OfferFields {
    onChange: (key: string) => (e: any) => void;
}

const DepositOrCashBuy: FC<IProps> = ({ cashBuy, depositSize, onChange }) => {
    return (
        <Form.Group controlId="depositOrCashBuy">
            <Form.Row>
                <Col md={6}>
                    <Form.Label>Cash buy or mortgage?</Form.Label>
                    <Form.Check
                        custom={true}
                        type="radio"
                        id="cashBuyYes"
                        name="cashBuy"
                        label={"Cash buy"}
                        required
                        onChange={onChange("cashBuy") as any}
                        checked={(cashBuy as any) === "1"}
                        value={"1"}
                    />
                    <Form.Check
                        custom={true}
                        type="radio"
                        id="cashBuyNo"
                        name="cashBuy"
                        label={"Mortgage"}
                        required
                        onChange={onChange("cashBuy") as any}
                        checked={(cashBuy as any) === "0"}
                        value={"0"}
                    />
                </Col>
                <Col>
                    <div
                        className={`${
                            (cashBuy as any) === "0" ? "" : "invisible"
                        }`}
                    >
                        <Form.Label>Deposit size</Form.Label>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>&#163;</InputGroup.Text>
                            </InputGroup.Prepend>

                            <Form.Control
                                type="number"
                                onChange={onChange("depositSize")}
                                value={
                                    depositSize ? depositSize.toString() : ""
                                }
                                disabled={(cashBuy as any) === "1"}
                                min={0}
                            />
                        </InputGroup>
                    </div>
                </Col>
            </Form.Row>
        </Form.Group>
    );
};

export default DepositOrCashBuy;
