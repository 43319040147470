import { IPropertyTimelineEntity } from "../../../api/_types";
import { IProcessedTimeline } from "../../../redux/reducers/property";
import _ from "lodash";
import { IPropertyTimelineEntityState, IPropertyTimelineEntityType } from "../../../redux/_types/property/types";
import { TimelineKeys } from "../../../api/modules/property";

export const findNextLogicalTimelineSteps = (
    item: IPropertyTimelineEntity,
    timeline: IProcessedTimeline
): IPropertyTimelineEntity[] => {
    const steps: IPropertyTimelineEntity[] = [];
    const find = (
        k: keyof IProcessedTimeline,
        predicate: any
    ): IPropertyTimelineEntity | undefined =>
        _.find((timeline as any)[k], predicate);
    // IF pre-sale, move the next point to in-progress, unless it's the last step,
    // in that case, make the first financial and legal steps in progress
    let nextItem: IPropertyTimelineEntity | undefined = find(
        item.type,
        (i: IPropertyTimelineEntity) => i.order === item.order + 1
    );

    // If there's a step found, then this is the next logical step.
    if (nextItem !== undefined) {
        steps.push(nextItem);
        return steps;
    }

    // IF no next item was found, we need to detirmine where we are,
    // in case a new track needs starting.
    if (item.type === IPropertyTimelineEntityType.SELLING) {
        // If the last selling point has just been complete, we want both
        // the first financial & legal first items to be set to in progress
        const firstFinancial = find(
            IPropertyTimelineEntityType.FINANCIAL,
            (i: IPropertyTimelineEntity) =>
                i.key === TimelineKeys.MortgageAppSubmitted
        );
        const firstLegal = find(
            IPropertyTimelineEntityType.LEGAL,
            (i: IPropertyTimelineEntity) =>
                i.key === TimelineKeys.DraftContractsIssued
        );
        const addSurveyItem = find(
            IPropertyTimelineEntityType.SURVEY,
            (i: IPropertyTimelineEntity) =>
                i.key === TimelineKeys.NotHavingSurvey
        );

        if (
            addSurveyItem !== undefined &&
            addSurveyItem.state !==
            IPropertyTimelineEntityState.COMPLETE
        ) {
            steps.push(addSurveyItem);
        }
        if (
            firstFinancial !== undefined &&
            firstFinancial.state !==
            IPropertyTimelineEntityState.COMPLETE
        ) {
            steps.push(firstFinancial);
        }
        if (
            firstLegal !== undefined &&
            firstLegal.state !== IPropertyTimelineEntityState.COMPLETE
        ) {
            steps.push(firstLegal);
        }

        return steps;
    }

    if (
        item.type === IPropertyTimelineEntityType.FINANCIAL ||
        item.type === IPropertyTimelineEntityType.LEGAL
    ) {
        // Check if all legal items are complete, if so, move the first
        // item of the completing phase into in progrress

        // If the current item is LEGAL, then otherTypee will be FINANCIAL, and vice-versa
        const otherType =
            item.type === IPropertyTimelineEntityType.LEGAL
                ? IPropertyTimelineEntityType.FINANCIAL
                : IPropertyTimelineEntityType.LEGAL;
        const lastLegalKey = TimelineKeys.EnquiriesAnswered;
        const lastFinancialKey = TimelineKeys.MortgageApproved;
        const keyToMatch =
            otherType === IPropertyTimelineEntityType.LEGAL
                ? lastLegalKey
                : lastFinancialKey;
        let otherStep = find(
            otherType,
            (i: IPropertyTimelineEntity) => i.key === keyToMatch
        );

        if (
            otherStep !== undefined &&
            otherStep.state === IPropertyTimelineEntityState.COMPLETE
        ) {
            let completingStep = find(
                IPropertyTimelineEntityType.COMPLETING,
                (i: IPropertyTimelineEntity) => i.order === 0
            );

            if (
                completingStep !== undefined &&
                completingStep.state !==
                IPropertyTimelineEntityState.COMPLETE
            ) {
                steps.push(completingStep);
                return steps;
            }
        }
    }

    return steps;
};