import moment, { Moment } from "moment";
import React, { Component, FormEvent } from "react";
import { Button, Form } from "react-bootstrap";
import { IPropertyViewing } from "../../../../../../api/_types";
import DatePicker from "../../../../../_sharedComponents/DatePicker";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";

interface IProps {
    // createViewing: (viewing: IPropertyViewing) => void;
    viewing: IPropertyViewing;
    saveViewingStatus: AsyncActionState;
    onChangeInputField: (stateField: string) => (event: FormEvent<any>) => void;
    save: () => void;
    resetFormState: () => void;
}
interface IState {}

export default class CreateViewingForm extends Component<IProps, IState> {
    handleSubmit = (event: any) => {
        event.preventDefault();
        this.props.save();
    };

    render() {
        const {
            onChangeInputField,
            viewing,
            saveViewingStatus,
            resetFormState
        } = this.props;
        return (
            <FormContainer
                submit={this.handleSubmit}
                success={{
                    heading: "Viewing saved",
                    message: "This viewing has been saved successfully."
                }}
                error={{
                    heading: "Something went wrong",
                    message:
                        "Something went wrong saving this viewing, please try again."
                }}
                onFeedbackMessageClose={resetFormState}
                showError={saveViewingStatus === AsyncActionState.Error}
                showSuccess={saveViewingStatus === AsyncActionState.Success}
                actions={
                    <Button className="mr-2" variant="primary" type="submit">
                        Save viewing
                    </Button>
                }
            >
                <Form.Group controlId="name">
                    <Form.Label>Client name</Form.Label>
                    <Form.Control
                        onChange={onChangeInputField("name")}
                        value={viewing.name}
                    />

                    <Form.Text className="text-muted">
                        Who was viewing the property?
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="when">
                    <Form.Label>When</Form.Label>

                    <DatePicker
                        timeFormat={false}
                        onChange={(e: string | Moment) => {
                            let momentTime =
                                typeof e === "string" ? moment(e) : e;
                            onChangeInputField("when")({
                                currentTarget: {
                                    value: momentTime.toISOString()
                                }
                            } as any);
                        }}
                        defaultValue={moment(viewing.when)}
                    />
                    <Form.Text className="text-muted">
                        When was the viewing conducted?
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="time">
                    <Form.Label>Time</Form.Label>
                    <DatePicker
                        closeOnSelect={true}
                        dateFormat={false}
                        timeConstraints={{
                            minutes: { step: 5, min: 0, max: 59 }
                        }}
                        strictParsing={false}
                        defaultValue={moment(viewing.time).format("hh:mm a")}
                        onChange={(time: string | Moment) => {
                            let momentTime: Moment =
                                typeof time === "string" ? moment(time) : time;

                            onChangeInputField("time")({
                                currentTarget: {
                                    value: momentTime.toDate().toISOString()
                                }
                            } as any);
                        }}
                    />

                    <Form.Text className="text-muted">
                        What time was the viewing conducted?
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="notes">
                    <Form.Label>Feedback</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={onChangeInputField("notes")}
                        value={viewing.notes}
                    />
                    <Form.Text className="text-muted">
                        Feedback regarding the viewing.
                    </Form.Text>
                </Form.Group>
            </FormContainer>
        );
    }
}
