import React, { Component } from "react";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import { IUser } from "../../../../../../api/_types";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import UserCard from "../../../user/components/UserCard";

import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import { Button } from "react-bootstrap";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import {
    createCompanyAdmin,
    resetCreateCompanyAdminStatus
} from "../../../../../../redux/api/company";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";
import { RouteComponentProps, withRouter } from "react-router-dom";
import routes from "../../../../../../navigation/routes";

interface IProps {
    user?: IUser;
    createUserStatus: AsyncActionState;
    createCompanyAdmin: (user: IUser) => void;
    resetCreateCompanyAdminStatus: () => void;
}
interface IState {
    user: IUser;
    dirty: boolean;
}

class CreateCompanyUserContainer extends Component<IProps & RouteComponentProps> {
    readonly defaultUser: IUser = {
        firstName: "",
        lastName: "",
        type: UserTypes.COMPANY_ADMIN,
        email: "",
        tel: "",
        password: "",
        confirmPassword: ""
    };

    state: IState = {
        user: Object.assign({}, this.defaultUser),
        dirty: false
    };

    componentDidUpdate(prevProps: IProps) {
        if (
            prevProps.createUserStatus === AsyncActionState.Pending &&
            this.props.createUserStatus === AsyncActionState.Success
        ) {
            this.setState({
                user: Object.assign({}, this.defaultUser),
                dirty: false
            }, ( )=> {

                this.props.history.push(routes.companyUsers.url);
            });

        }
    }
    componentDidMount() {
        this.props.resetCreateCompanyAdminStatus();
    }
    componentWillUnmount() {
        this.props.resetCreateCompanyAdminStatus();
    }

    onChangeInput = (stateField: string) => {
        return getStateFromOnChangeUpdate(this, this.state, stateField, "user");
    };

    createUser = () => {
        this.props.createCompanyAdmin(this.state.user);
    };

    render() {
        const { createUserStatus, resetCreateCompanyAdminStatus } = this.props;
        return (
            <>
                <NavigationPrompt when={this.state.dirty === true} />
                <FormContainer
                    actions={[
                        <Button variant="primary" onClick={this.createUser}>
                            Save
                        </Button>
                    ]}
                    showSuccess={createUserStatus === AsyncActionState.Success}
                    showError={createUserStatus === AsyncActionState.Error}
                    onFeedbackMessageClose={resetCreateCompanyAdminStatus}
                    success={{
                        heading: "User account created",
                        message: "This user can now login to the platform."
                    }}
                    error={{
                        heading: "Error creating user account",
                        message:
                            "Something went wrong creating the user account."
                    }}
                >
                    <UserCard
                        user={this.state.user}
                        updateUserFirstName={this.onChangeInput("firstName")}
                        updateUserLastName={this.onChangeInput("lastName")}
                        updateUserEmail={this.onChangeInput("email")}
                        updateUserPassword={this.onChangeInput("password")}
                        updateUserConfirmPassword={this.onChangeInput(
                            "confirmPassword"
                        )}
                        updateUserTel={this.onChangeInput("tel")}
                    />
                </FormContainer>
            </>
        );
    }
}

const mapStateToProps = ({ company }: IStore) => ({
    createUserStatus: company.createCompanyAdminStatus
});

export default connect(
    mapStateToProps,
    { createCompanyAdmin, resetCreateCompanyAdminStatus }
)(withRouter<any, any>(CreateCompanyUserContainer));

