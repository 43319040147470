
import React, { FC } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { IProperty } from "../../../../../../api/_types";
import { hasLeaseHoldWarning, hasVendorMortgageBrokerWarning, hasVendorSolicitorWarning } from "../../../../../../lib/core/warnings";
import WarningIcon from "./WarningIcon";

interface IProps {
    show: boolean;
    property: IProperty;
    close: () => void;
}

const PropertyListOtherWarningModal: FC<IProps> = ({
    close,
    property,
    show
}) => {
    const leaseholdWarning = hasLeaseHoldWarning(property);
    const vendorSolicitorWarning = hasVendorSolicitorWarning(property);
    const vendorMortgageWarning = hasVendorMortgageBrokerWarning(property);
    
    return (
        <Modal show={show} size="lg">
            <Modal.Header>
                <Modal.Title>Setup warnings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered variant="">
                    <thead className="thead-dark">
                        <th></th>
                        <th>Item</th>
                        <th>Time elapsed</th>
                    </thead>
                    <tbody>
                        {leaseholdWarning.result === true && (
                            <tr>
                                <td>
                                    <WarningIcon/>
                                </td>
                                <td>Leasehold pack has not been sent.</td>
                                <td>{leaseholdWarning.date?.fromNow()}</td>
                            </tr>
                        )}

                        {vendorMortgageWarning.result === true && (
                            <tr>
                                <td>
                                    <WarningIcon/>
                                </td>
                                <td>No mortgage broker assigned to vendor.</td>
                                <td>{vendorMortgageWarning.date?.fromNow()}</td>
                            </tr>
                        )}

                        {vendorSolicitorWarning.result === true && (
                            <tr>
                                <td>
                                    <WarningIcon/>
                                </td>
                                <td>No solicitor assigned to vendor.</td>
                                <td>{vendorSolicitorWarning.date?.fromNow()}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={close}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PropertyListOtherWarningModal;
