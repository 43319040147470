import React, { useReducer } from "react";
import { Table, TableProps } from "react-bootstrap";
import { IDataTableField, IDataTableAction } from "./types";
import DataTableTHead from "./THead";
import DataTableTBody from "./TBody";
import Pager from "../Pager";
import { IPagingResponse } from "../../../api/_types";
import Select from "react-select";
import "./stylesheet.css";
import { columnWidthReducer, ColumnWidthState } from "./utils";
import { FC } from "react";

interface IDataTableProps extends TableProps {
    data: any[];
    fields: IDataTableField[];
    actions?: IDataTableAction[];
    showLoadingSpinner?: boolean;
    showPager?: boolean;
    pagingElement?: IPagingResponse;
    hover?: boolean;
    className?: string;
    smallHeader?: boolean;
    centeredHeader?: boolean;
    aboveTheadContent?: React.ReactChild | React.ReactChild[];
    belowTableContent?: React.ReactChild | React.ReactChild[];
    pagerStyles?: React.CSSProperties;
    itemsPerPage?: number;
    stickyHorizontalCols?: number;
    onPagerPageClick?: (pageIndex: number) => void;
    onItemsPerPageChange?: (itemsPerPage: number) => void;
}

const DataTable: FC<IDataTableProps> = ({
    data,
    variant,
    fields,
    actions,
    size = "sm",
    hover,
    showPager = false,
    onPagerPageClick,
    className,
    smallHeader,
    centeredHeader,
    pagingElement = {} as IPagingResponse,
    aboveTheadContent,
    belowTableContent,
    pagerStyles,
    onItemsPerPageChange,
    stickyHorizontalCols
}) => {
    const createOptionLabel = (value: number) => `${value} per page`;
    const [columnWidths, dispatchColumnWidths] = useReducer(
        columnWidthReducer,
        {} as ColumnWidthState
    );

    return (
        <>
            <Table
                striped={true}
                hover={hover}
                variant={variant}
                size={size}
                responsive={true}
                className={`${className} tableFixHead`}
            >
                <thead>
                    {aboveTheadContent && aboveTheadContent}
                    <DataTableTHead
                        fields={fields}
                        actions={actions}
                        small={smallHeader}
                        centered={centeredHeader}
                        stickyHorizontalCols={stickyHorizontalCols}
                        columnWidths={columnWidths}
                    />
                </thead>
                <DataTableTBody
                    data={data}
                    fields={fields}
                    actions={actions}
                    stickyHorizontalCols={stickyHorizontalCols}
                    columnWidths={columnWidths}
                    dispatchColumnWidths={dispatchColumnWidths}
                />
                {belowTableContent && belowTableContent}
            </Table>

            <div className="d-flex flex-row">
                {onItemsPerPageChange && (
                    <div style={{ minWidth: 200, marginRight: 15 }}>
                        <Select
                            className="mb-2"
                            placeholder="Items per page"
                            width="200px"
                            value={
                                {
                                    value: pagingElement.itemsPerPage || 0,
                                    label:
                                        createOptionLabel(
                                            pagingElement.itemsPerPage
                                        ) || ""
                                } as { value: number; label: string }
                            }
                            options={
                                [
                                    {
                                        value: 10,
                                        label: createOptionLabel(10)
                                    },
                                    {
                                        value: 25,
                                        label: createOptionLabel(25)
                                    },
                                    {
                                        value: 50,
                                        label: createOptionLabel(50)
                                    },
                                    {
                                        value: 75,
                                        label: createOptionLabel(75)
                                    },
                                    {
                                        value: 100,
                                        label: createOptionLabel(100)
                                    }
                                ] as Array<{ value: number; label: string }>
                            }
                            //@ts-ignore
                            onChange={(option: {
                                value: number | string;
                                label: string | number;
                            }) => {
                                onItemsPerPageChange(option.value as number);
                            }}
                        />
                    </div>
                )}
                {showPager === true && pagingElement.totalPages > 0 && (
                    <Pager
                        numPages={pagingElement.totalPages}
                        currentPage={pagingElement.currentPage}
                        pageClick={onPagerPageClick}
                        style={pagerStyles}
                    />
                )}
            </div>
        </>
    );
};

export default DataTable;
