import React, { Component } from "react";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import ManageGYAAdmins from "../../containers/ManageGYAAdmins";
import ManageAdminTabs from "../../components/ManageAdminTabs";
import TabBody from "../../../../../_sharedComponents/TabBody";

interface IProps {}
interface IState {}

export default class ManageGYAAdminsScreen extends Component<IProps, IState> {
    render() {
        return (
            <PageWrap>
                <EntityTitle entityName="Manage administrators" />
                <ManageAdminTabs />
                <TabBody>
                    <ManageGYAAdmins />
                </TabBody>
            </PageWrap>
        );
    }
}
