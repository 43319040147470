import { faChevronDown, faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IPropertyTimelineEntity } from "../../../../../../../api/_types";
import { IPropertyTimelineEntityState } from "../../../../../../../redux/_types/property/types";
import styles from "./styles";

interface IProps {
    item: IPropertyTimelineEntity;
    size: number;
    colors: any;
}

export default function StateIcon(props: IProps) {
    const { item, colors, size } = props;
    const isInProgress =
        item.state === IPropertyTimelineEntityState.IN_PROGRESS;
    let inProgressElementStyle;

    const buildItemStyle = (params: {
        itemSize?: number;
        circleFilled?: boolean;
        slightlyOpaque?: boolean;
    }) => {
        const { item, size } = props;
        const {
            itemSize = size,
            circleFilled = false,
            slightlyOpaque = false
        } = params;
        let isCircleFilled = circleFilled;
        let color = (colors as any)[item.type];

        if (
            item.state === IPropertyTimelineEntityState.COMPLETE ||
            item.state === IPropertyTimelineEntityState.BLOCKED
        ) {
            isCircleFilled = true;
        }
        if (item.state === IPropertyTimelineEntityState.BLOCKED) {
            color = colors.blocked;
        }

        return Object.assign(
            {},
            styles.container(itemSize, color, isCircleFilled, slightlyOpaque)
        );
    };

    const itemStyle = buildItemStyle({
        slightlyOpaque:
            (item.state === IPropertyTimelineEntityState.IN_PROGRESS) === true
    });

    if (isInProgress === true) {
        inProgressElementStyle = buildItemStyle({
            itemSize: size - 40,
            circleFilled: true,
            slightlyOpaque: false
        });
    }

    return (
        <div
            className=" d-flex align-items-center justify-content-center"
            style={itemStyle}
        >
            {isInProgress === true && (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={inProgressElementStyle}
                >
                    <FontAwesomeIcon
                        data-testid="ip-icon"
                        icon={faChevronDown}
                        size="3x"
                        color="white"
                    />
                </div>
            )}
            {item.state === IPropertyTimelineEntityState.BLOCKED && (
                <FontAwesomeIcon
                    data-testid="blocked-icon"
                    icon={faBan}
                    size="3x"
                    color="white"
                />
            )}
        </div>
    );
}
