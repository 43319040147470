import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
    IPagingParams,
    IPagingResponse,
    IUser
} from "../../../../../../api/_types";
import { setUpdateBranchMember } from "../../../../../../redux/acreators/branch";
import {
    searchMembers,
    removeBranchMember
} from "../../../../../../redux/api/branch";
import { IStore } from "../../../../../../redux/store";
import DataTable from "../../../../../_sharedComponents/DataTable";
import BranchMemberTableInput from "../../components/BranchMemberTableInput";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import routes from "../../../../../../navigation/routes";
import { IDataTableAction } from "../../../../../_sharedComponents/DataTable/types";
import { UserTypeLabels } from "../../../../../../lib/utils/user";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import UserAvatar from "../../../user/components/Avatar";

interface IProps {
    me?: IUser;
    members: IUser[];
    membersPaging: IPagingResponse;
    removeBranchMemberStatus: AsyncActionState;
    removeBranchMember: (memberId: string) => void;
    setUpdateBranchMember: (user?: IUser) => void;
    searchMembers: (search: string, paging?: IPagingParams) => void;
}

interface IState {
    searchString: string;
    memberToRemove?: IUser;
}

class BranchMemberTableContainer extends Component<
    IProps & RouteComponentProps,
    IState
> {
    state: IState = {
        searchString: "",
        memberToRemove: undefined
    };

    componentDidMount() {
        this.props.searchMembers("", { itemsPerPage: 30, currentPage: 0 });
    }

    componentDidUpdate(prevProps: IProps) {
        if (
            prevProps.removeBranchMemberStatus !== AsyncActionState.Success &&
            this.props.removeBranchMemberStatus === AsyncActionState.Success
        ) {
            this.handleSearch();
        }
    }

    setMemberToRemove = (memberToRemove: IUser) => {
        this.setState({ memberToRemove });
    };

    removeMemberToRemove = () => {
        this.setState({ memberToRemove: undefined });
    };

    removeUserFromBranch = () => {
        const member = this.state.memberToRemove;
        if (member !== undefined) {
            this.props.removeBranchMember(member._id as string);
        }
    };

    handleSearch = (searchString: string = "") => {
        this.setState({ searchString }, () => {
            this.props.searchMembers(searchString, {
                itemsPerPage: 10,
                currentPage: 0
            });
        });
    };

    handlePageUsers = (desiredPage: number) => {
        const { searchMembers, membersPaging } = this.props;
        searchMembers(this.state.searchString, {
            currentPage: desiredPage,
            itemsPerPage: membersPaging.itemsPerPage
        });
    };

    render() {
        const { me, members = [], membersPaging } = this.props;
        const { memberToRemove } = this.state;
        const actions: IDataTableAction[] = [];

        if (me === undefined) {
            return null;
        }
        if (
            [UserTypes.GYA_ADMIN, UserTypes.COMPANY_ADMIN].includes(me.type) ===
            true
        ) {
            actions.push(
                {
                    action: (user: IUser) => {
                        this.props.setUpdateBranchMember(user);
                        this.props.history.push(routes.userEdit.url);
                    },
                    btnTitle: "Manage",
                    btnVariant: "light",
                    condition: (user: IUser) => user._id !== me._id
                },
                {
                    action: (user: IUser) => this.setMemberToRemove(user),
                    btnTitle: "Remove",
                    btnVariant: "danger",
                    condition: (user: IUser) =>
                        user.type !== UserTypes.COMPANY_ADMIN
                }
            );
        }
        return (
            <>
                <div className="mt-2 mb-2">
                    <BranchMemberTableInput search={this.handleSearch} />
                </div>

                <DataTable
                    data={members}
                    showPager={true}
                    pagingElement={membersPaging}
                    onPagerPageClick={this.handlePageUsers}
                    fields={[
                        {
                            field: "firstName",
                            displayName: "Name",
                            mutator: (user) => (
                                <div className="d-flex flex-direction-row align-items-center">
                                    <UserAvatar
                                        user={user}
                                        size={40}
                                        rounded={true}
                                        containerStyle={{ marginRight: 10 }}
                                    />
                                    {user.firstName} {user.lastName}
                                </div>
                            )
                        },
                        {
                            field: "email",
                            displayName: "Email address"
                        },
                        {
                            field: "type",
                            displayName: "Type",
                            mutator: (dataItem: IUser) =>
                                UserTypeLabels[dataItem.type]
                        }
                    ]}
                    actions={actions}
                />

                <ActionPrompt
                    show={memberToRemove !== undefined}
                    title="Are you sure?"
                    bodyText="This user will lose access to this branch, and all of it's properties."
                    cancel={this.removeMemberToRemove}
                    actions={[
                        {
                            title: "Confirm",
                            btnVariant: "primary",
                            onClick: this.removeUserFromBranch,
                            asyncStatus: this.props.removeBranchMemberStatus,
                            onSuccess: this.removeMemberToRemove,
                            onError: this.removeMemberToRemove
                        }
                    ]}
                />
            </>
        );
    }
}

const mapStateToProps = ({ branch, user }: IStore) => ({
    me: user.me,
    members: branch.searchBranchMembersResults.users,
    membersPaging: branch.searchBranchMembersResults.paging,
    removeBranchMemberStatus: branch.removeBranchMemberStatus
});

export default connect(
    mapStateToProps,
    {
        searchMembers,
        removeBranchMember,
        setUpdateBranchMember
    }
)(withRouter<any, any>(BranchMemberTableContainer));
