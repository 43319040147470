import React, { useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ICompanyContactPOC } from "../../../../../../api/_types";
import usePrevious from "../../../../../../lib/utils/hooks/usePrevious";
import { setViewingCompanyContactPOC } from "../../../../../../redux/acreators/companyContactPOCs";
import {
    deleteCompanyContactPOC,
    getCompanyContactPOCs
} from "../../../../../../redux/api/companyContactPOCs";
import { setViewingCompanyContact } from "../../../../../../redux/api/companyContacts";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import DataTable from "../../../../../_sharedComponents/DataTable";
import { IDataTableAction } from "../../../../../_sharedComponents/DataTable/types";
import ContactListHeaderOptions from "../../components/ContactListHeaderOptions";
import CompanyPropertiesContainer from "../CompanyProperties";
import EditContactPOCForm from "../EditContactPOCForm";

const mapStateToProps = ({ companyContactPOCs, companyContacts }: IStore) => ({
    viewingPOC: companyContactPOCs.viewingContactPOC,
    pocs: companyContactPOCs.pocs.pocs,
    paging: companyContactPOCs.pocs.paging,
    deleteCompanyContactPOCStatus:
        companyContactPOCs.deleteCompanyContactPOCStatus,
    viewingContact: companyContacts.viewingContact
});

const connector = connect(
    mapStateToProps,
    {
        getCompanyContactPOCs,
        deleteCompanyContactPOC,
        setViewingCompanyContactPOC,
        setViewingCompanyContact
    }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {}

function ContactPOCList(props: IProps) {
    const {
        getCompanyContactPOCs,
        pocs,
        paging,
        deleteCompanyContactPOCStatus,
        viewingContact
    } = props;
    const [searchText, setSearchText] = useState<string>("");
    const [showDeletePrompt, setShowDeletePrompt] = useState<{
        item?: ICompanyContactPOC;
        show: boolean;
    }>({ show: false });
    const prevDeletePOCStatus = usePrevious(deleteCompanyContactPOCStatus);
    const tableFields = [
        {
            field: "name",
            displayName: "Name"
        },
        {
            field: "email",
            displayName: "Email"
        },
        {
            field: "tel",
            displayName: "Telephone"
        }
    ];

    const getContactPOCs = useCallback(
        () => getCompanyContactPOCs(searchText, paging),
        [searchText, getCompanyContactPOCs, paging]
    );

    const pageContacts = useCallback(
        (desiredPage: number) => {
            getCompanyContactPOCs(searchText, {
                currentPage: desiredPage,
                itemsPerPage: paging.itemsPerPage
            });
        },
        [searchText, getCompanyContactPOCs, paging]
    );

    const actions: IDataTableAction[] = [
        {
            btnTitle: "Manage",
            btnVariant: "light",
            action: (dataItem: ICompanyContactPOC) => {
                props.setViewingCompanyContactPOC(dataItem);
                // this.navigateToPage(dataItem._id, routes.branchManage);
            }
        },
        {
            btnTitle: "Delete",
            btnVariant: "danger",
            action: (dataItem: ICompanyContactPOC) => {
                setShowDeletePrompt({ show: true, item: dataItem });
            }
        }
    ];

    useEffect(() => {

        props.setViewingCompanyContactPOC(undefined);

        getContactPOCs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            prevDeletePOCStatus === AsyncActionState.Pending &&
            deleteCompanyContactPOCStatus === AsyncActionState.Success
        ) {
            setShowDeletePrompt({ show: false, item: undefined });
        }
    }, [deleteCompanyContactPOCStatus, prevDeletePOCStatus]);

    if (props.viewingPOC !== undefined) {
        return <EditContactPOCForm />;
    }

    return (
        <>
            <ContactListHeaderOptions
                updateSearchText={setSearchText}
                getContacts={getContactPOCs}
                placeholder="Search by contact name"
            />

            <DataTable
                data={pocs}
                fields={tableFields}
                actions={actions}
                showPager={true}
                pagingElement={paging}
                onPagerPageClick={pageContacts}
            />

            <hr/>
            <h5 className="mb-3">Acting as solicitor for the following properties</h5>
            <CompanyPropertiesContainer
                solicitorContactId={viewingContact?._id}
                hideTopControls={true}
                noPropertyDisplay={<p>This contact is not acting as the solicitors for any active properties in the pipeline.</p>}
            />

            <ActionPrompt
                show={showDeletePrompt.show === true}
                title="Are you sure?"
                bodyText="This cannot be undone. The users account will be deleted, and be removed as the contact for any property to which they are currently assigned."
                confirmText={
                    showDeletePrompt.item && showDeletePrompt.item.name
                }
                confirmTextLabel="Please enter the contacts full name to confirm."
                cancel={() => {
                    setShowDeletePrompt({ show: false, item: undefined });
                }}
                actions={[
                    {
                        title: "Confirm",
                        btnVariant: "danger",
                        useConfirm: true,
                        asyncStatus: deleteCompanyContactPOCStatus,
                        onClick: () => {
                            if (
                                showDeletePrompt.item !== undefined &&
                                showDeletePrompt.item._id !== undefined
                            ) {
                                props.deleteCompanyContactPOC(
                                    showDeletePrompt.item._id
                                );
                            }
                        }
                    }
                ]}
            />
        </>
    );
}

export default connector(ContactPOCList);
