import * as actions from "../../actions/property/chain";
import { AsyncActionState } from "../../utils/asyncAction";

export interface IPropertyChainState {
    updateChainStatus: AsyncActionState;
}

const initialState: IPropertyChainState = {
    updateChainStatus: AsyncActionState.Reset
};

export default function propertyNotesReducer(
    state: IPropertyChainState = initialState,
    action: actions.UpdateChainStatus
) {
    switch (action.type) {
        case actions.PropertyNotesTypes.UPDATE_CHAIN_STATUS: {
            return Object.assign({}, state, {
                updateChainStatus: action.payload
            } as IPropertyChainState);
        }

        default: {
            return state;
        }
    }
}
