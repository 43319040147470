import { api, BASE_URL } from "../index";
import { AxiosResponse } from "axios";
import {
    IBranch,
    IUser,
    IPagingParams,
    IPagingResponse,
    IPropertyTimelineEntity
} from "../_types";

export interface IGetBranchesResponse {
    paging: IPagingResponse;
    branches: IBranch[];
}

export interface ISearchBranchMembersResponse {
    paging: IPagingResponse;
    users: IUser[];
}

const buildSubmitBranchPayload = (branch: IBranch, file?: File): FormData => {
    const data = new FormData();
    const admin = branch.administrator;
    if (branch._id !== undefined || branch._id !== null) {
        data.append("_id", branch._id as string);
    }

    data.append(`name`, branch.name);

    data.append(`tel`, branch.tel);
    data.append(`email`, branch.email);
    data.append(`displayText`, branch.displayText as string);

    data.append(`address[line1]`, branch.address.line1);
    data.append(`address[line2]`, branch.address.line2);
    data.append(`address[postcode]`, branch.address.postcode);
    data.append(`address[town]`, branch.address.town);
    data.append(`address[county]`, branch.address.county);
    data.append(`address[country]`, branch.address.country);

    if (branch.useBranchDeduction !== undefined) {
        data.append("useBranchDeduction", branch.useBranchDeduction === true ? "1" : "0");
    }
    if (branch.feeDeduction !== undefined) {
        data.append("feeDeduction", branch.feeDeduction.toString());
    }

    if (admin !== undefined) {
        // Only add admin at this point if fields have been input,
        if (typeof admin === "string") {
            data.append(`administrator`, admin);
        } else if (admin._id !== undefined && admin._id !== "") {
            data.append(`administrator`, admin._id);
        } else if (admin !== undefined) {
            if (
                admin.email !== "" &&
                admin.firstName !== "" &&
                admin.lastName !== ""
            ) {
                data.append(`administrator[firstName]`, admin.firstName);
                data.append(`administrator[lastName]`, admin.lastName);
                data.append(`administrator[email]`, admin.email);
                data.append(`administrator[tel]`, admin.tel);
                if (admin.password !== undefined && admin.password !== "") {
                    data.append(`administrator[password]`, admin.password);
                }
            }
        }
    }

    if (file !== undefined) {
        data.append("file", file);
    }

    return data;
};
export default {
    getBranchById: (branchId: string, companyId: string): Promise<any> => {
        return api.get(BASE_URL + `branch/${companyId}/${branchId}`);
    },
    getBranches: (
        companyId: string,
        params: IPagingParams = { itemsPerPage: 10, currentPage: 0 }
    ): Promise<AxiosResponse<IGetBranchesResponse>> => {
        return api.get(BASE_URL + `branch/list/${companyId}`, {
            params
        });
    },
    createBranch: (
        branch: IBranch,
        companyId: string,
        file?: File
    ): Promise<any> => {
        const data = buildSubmitBranchPayload(branch, file);

        return api({
            url: BASE_URL + `branch/${companyId}`,
            method: "POST",
            data,
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    editBranch: (
        branch: IBranch,
        companyId: string,
        file?: File
    ): Promise<any> => {
        const data = buildSubmitBranchPayload(branch, file);

        return api({
            url: BASE_URL + `branch/${companyId}`,
            method: "PUT",
            data,
            headers: { "Content-Type": "multipart/form-data" }
        });
    },
    deleteBranch: (branchId: string, companyId: string): Promise<any> => {
        return api.delete(BASE_URL + `branch/${companyId}/${branchId}`);
    },

    addMember: (branchId: string, member: string | IUser): Promise<any> => {
        return api.post(BASE_URL + `branch/members/${branchId}`, {
            member: member
        });
    },
    removeMember: (
        branchId: string,
        memberId: string
    ): Promise<{ success: boolean }> => {
        return api.delete(BASE_URL + `branch/members/${branchId}/${memberId}`);
    },
    editMember: (branchId: string, member: IUser): Promise<any> => {
        return api.patch(
            BASE_URL + `branch/members/${branchId}/${member._id}`,
            member
        );
    },
    searchMembers: (
        branchId: string,
        textSearch: string,
        paging: IPagingParams = { itemsPerPage: 10, currentPage: 0 }
    ): Promise<AxiosResponse<ISearchBranchMembersResponse>> => {
        return api.get(BASE_URL + `branch/members/${branchId}`, {
            params: {
                textSearch,
                ...paging
            }
        });
    },
    updateTimelineTemplate: (
        timelineEntities: IPropertyTimelineEntity[],
        companyId: string,
        branchId: string
    ): Promise<AxiosResponse<any>> => {
        return api.patch(
            `${BASE_URL}branch/${companyId}/${branchId}/timeline`,
            {
                timeline: timelineEntities
            }
        );
    },
    deletePhoto: (
        companyId: string,
        branchId: string
    ): Promise<AxiosResponse<IBranch>> => {
        return api.delete(`${BASE_URL}branch/photo/${companyId}/${branchId}`);
    }
};
