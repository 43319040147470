import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import TabBody from "../../../../../_sharedComponents/TabBody";
import EditPropertyTopNav from "../../components/EditPropertyTopNav";
import ChainContainer from "../../containers/Chain";

interface IProps {}
interface IState {}

export default class Chain extends Component<
    IProps & RouteComponentProps,
    IState
> {
    render() {
        return (
            <PageWrap>
                <EditPropertyTopNav />
                <TabBody>
                    <ChainContainer />
                </TabBody>
            </PageWrap>
        );
    }
}
