import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { IProperty } from "../../../../../../../api/_types";
import PropertySearch from "../../../containers/PropertySearch";

interface IProps {
    onSelect: (property: IProperty[]) => void;
}

const ChainPropertySearch: FC<IProps> = ({ onSelect }) => {
    return (
        <Form.Group>
            <Form.Label>
                <strong>Or link an existing property</strong>
            </Form.Label>
            <PropertySearch onSelect={onSelect} />
        </Form.Group>
    );
};

export default ChainPropertySearch;
