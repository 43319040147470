import React from "react";
import { IAddress } from "../../../../../../../api/_types";
import { Button } from "react-bootstrap";

interface IProps {
    address: IAddress;
    open: () => void;
}

export default function AddNoteLink(props: IProps) {
    const { address, open } = props;
    return (
        <>
            <p>
                To add a note for {address.line1}{" "}
                {address.postcode ? `, ${address.postcode}` : ""}
                <Button
                    variant="primary"
                    onClick={open}
                    style={{
                        marginLeft: 5,
                        marginTop: -5
                    }}
                    size={"sm"}
                >
                    click here
                </Button>
            </p>
        </>
    );
}
