import React, { useMemo } from "react";
import Select from "react-select";
import { LeaseHoldPackSent } from "../../../../../../../api/_types";
import { FormEventType } from "../../../../../../../lib/types/forms";

interface IProps {
    current?: LeaseHoldPackSent;
    onChangeInputField: (e: FormEventType) => void;
}

export default function LeaseHoldPackSelect(props: IProps) {
    const { onChangeInputField, current } = props;
    const labels: any = useMemo(() => {
        return {
            yes: "Yes",
            no: "No",
            unknown: "Unknown"
        };
    }, []);
    return (
        <>
            <label>Leasehold pack sent?</label>
            <Select
                value={
                    {
                        label: current ? labels[current] || current : current,
                        value: current
                    } as any
                }
                options={
                    [
                        {
                            label: "Unknown",
                            value: "unknown"
                        },
                        {
                            label: "Yes",
                            value: "yes"
                        },
                        {
                            label: "No",
                            value: "no"
                        }
                    ] as Array<{
                        label: string;
                        value: LeaseHoldPackSent;
                    }>
                }
                //@ts-ignore
                onChange={(option: {
                    label: string;
                    value: LeaseHoldPackSent;
                }) => {
                    onChangeInputField({
                        currentTarget: {
                            value: option.value
                        }
                    } as any);
                }}
            />
        </>
    );
}
