import React from "react";

interface IProps {
    fill?: string;
    size?: number
}

export default function CreateCompany({size = 28, fill}: IProps) {
    return (
        <svg
            id="_x31__px"
            enableBackground="new 0 0 24 24"
            height={size}
            viewBox="0 0 24 24"
            width={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill={fill} d="m9.02 21h-6.52c-1.378 0-2.5-1.121-2.5-2.5v-14c0-1.379 1.122-2.5 2.5-2.5h12c1.378 0 2.5 1.121 2.5 2.5v4.06c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-4.06c0-.827-.673-1.5-1.5-1.5h-12c-.827 0-1.5.673-1.5 1.5v14c0 .827.673 1.5 1.5 1.5h6.52c.276 0 .5.224.5.5s-.224.5-.5.5z"/>
            <path fill={fill} d="m3.5 5c-.276 0-.5-.224-.5-.5v-4c0-.276.224-.5.5-.5s.5.224.5.5v4c0 .276-.224.5-.5.5z"/>
            <path fill={fill} d="m8.5 5c-.276 0-.5-.224-.5-.5v-4c0-.276.224-.5.5-.5s.5.224.5.5v4c0 .276-.224.5-.5.5z"/>
            <path fill={fill} d="m13.5 5c-.276 0-.5-.224-.5-.5v-4c0-.276.224-.5.5-.5s.5.224.5.5v4c0 .276-.224.5-.5.5z"/>
            <path fill={fill} d="m10.02 13h-6.52c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h6.52c.276 0 .5.224.5.5s-.224.5-.5.5z"/>
            <path fill={fill} d="m8.5 9h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5c.276 0 .5.224.5.5s-.224.5-.5.5z"/>
            <path fill={fill} d="m17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.033 0-5.5 2.468-5.5 5.5s2.467 5.5 5.5 5.5 5.5-2.468 5.5-5.5-2.467-5.5-5.5-5.5z"/>
            <path fill={fill} d="m17.5 21c-.276 0-.5-.224-.5-.5v-6c0-.276.224-.5.5-.5s.5.224.5.5v6c0 .276-.224.5-.5.5z"/>
            <path fill={fill} d="m20.5 18h-6c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h6c.276 0 .5.224.5.5s-.224.5-.5.5z"/>
        </svg>

    );
}
