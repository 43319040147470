import React from "react";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import EditCompanyTopNav from "../../components/EditCompanyTopNav";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import { ICompany } from "../../../../../../api/_types";
import ContactPOCList from "../../containers/ContactPOCList";
import { withRouter, RouteComponentProps } from "react-router-dom";
import routes from "../../../../../../navigation/routes";
import CreateContactPOC from "../../containers/CreateContactPOC";
import HorizontalTabs from "../../../../../_sharedComponents/HorizontalTabNav";

interface IProps {
    company?: ICompany;
}

function CompanyContactPOCsScreen(props: IProps & RouteComponentProps) {
    const { company } = props;

    if (company === undefined) {
        return null;
    }
    return (
        <PageWrap>
            <EntityTitle entityName={company.name} />
            <EditCompanyTopNav />
            <HorizontalTabs
                id="asking-price-htabs"
                defaultKey={props.match.url}
                items={[
                    {
                        key: routes.companyContactPOCs.url,
                        title: "POC list",
                        content: ContactPOCList
                    },
                    {
                        key: routes.companyCreateContactPOC.url,
                        title: "Create POC",
                        content: CreateContactPOC
                    }
                ]}
            />
        </PageWrap>
    );
}

const mapStateToProps = ({ company }: IStore) => ({
    company: company.viewingCompany
});

export default connect(
    mapStateToProps,
    {}
)(withRouter<any, any>(CompanyContactPOCsScreen));
