import * as types from "../../../actions/property/documents";
import { AsyncActionState } from "../../../utils/asyncAction";

export const setCreateDocumentStatus = (status: AsyncActionState) =>
    ({
        type: types.PropertyDocumentTypes.CREATE_DOCUMENT_STATUS,
        payload: status
    } as types.CreateDocumentStatus);

export const setUpdateDocumentStatus = (status: AsyncActionState) =>
    ({
        type: types.PropertyDocumentTypes.UPDATE_DOCUMENT_STATUS,
        payload: status
    } as types.UpdateDocumentStatus);

export const setDeleteDocumentStatus = (status: AsyncActionState) =>
    ({
        type: types.PropertyDocumentTypes.DELETE_DOCUMENT_STATUS,
        payload: status
    } as types.DeleteDocumentStatus);

export const setEmailDocumentStatus = (status: AsyncActionState) =>
    ({
        type: types.PropertyDocumentTypes.EMAIL_DOCUMENT_STATUS,
        payload: status
    } as types.EmailDocumentStatus);
