import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

export default function Separator() {
    return (
        <div className="text-center my-2">
            <FontAwesomeIcon icon={faArrowUp} size="2x" color={"#00A9CE20"} />
        </div>
    );
}
