import moment from "moment";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IPropertyViewing } from "../../../../../../api/_types";
import { setPropertyViewingToEdit } from "../../../../../../redux/acreators/property";
import { removePropertyViewing } from "../../../../../../redux/api/property";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import DataTable from "../../../../../_sharedComponents/DataTable";
import EditViewingForm from "../EditViewingForm";

const mapStateToProps = ({ property }: IStore) => ({
    property: property.viewingProperty,
    viewingToEdit: property.viewingToEdit,
    removePropertyViewingStatus: property.removePropertyViewingStatus
});

const connector = connect(
    mapStateToProps,
    {
        removePropertyViewing,
        setPropertyViewingToEdit
    }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {}

interface IState {
    showDeleteModal: boolean;
    viewingToDelete?: IPropertyViewing;
}

export class ViewingsListContainer extends Component<IProps, IState> {
    state: IState = {
        showDeleteModal: false,
        viewingToDelete: undefined
    };

    handleRemoveViewing = () => {
        const { viewingToDelete } = this.state;
        if (
            viewingToDelete === undefined ||
            viewingToDelete._id === undefined
        ) {
            alert("Invalid viewing.");
            return;
        }
        this.props.removePropertyViewing(viewingToDelete._id);
    };

    handleUpdateViewing = (item: IPropertyViewing) => {
        this.props.setPropertyViewingToEdit(item);
    };

    showDeleteModal = (viewingToDelete: IPropertyViewing) => {
        this.setState({
            showDeleteModal: true,
            viewingToDelete
        });
    };

    hideDeleteModal = () => {
        this.setState({
            showDeleteModal: false,
            viewingToDelete: undefined
        });
    };

    renderEditForm = () => {
        return <EditViewingForm />;
    };

    render() {
        const {
            property,
            removePropertyViewingStatus,
            viewingToEdit
        } = this.props;
        const { showDeleteModal } = this.state;
        if (property === undefined || property.viewings.length <= 0) {
            return null;
        }

        if (viewingToEdit !== undefined) {
            return this.renderEditForm();
        }

        return (
            <>
                <DataTable
                    data={property.viewings}
                    showLoadingSpinner={
                        removePropertyViewingStatus === AsyncActionState.Pending
                    }
                    fields={[
                        {
                            field: "name",
                            displayName: "Client name"
                        },
                        {
                            field: "when",
                            displayName: "When",
                            mutator: (item: IPropertyViewing) =>
                                moment(item.when).format("ll")
                        },
                        {
                            field: "when",
                            displayName: "When",
                            mutator: (item: IPropertyViewing) =>
                                item.time
                                    ? moment(item.time).format("hh:mm A")
                                    : "N/A"
                        }
                    ]}
                    actions={[
                        {
                            btnTitle: "Manage",
                            btnVariant: "light",
                            action: this.handleUpdateViewing
                        },
                        {
                            btnTitle: "Remove",
                            btnVariant: "danger",
                            action: (item: IPropertyViewing) => {
                                this.showDeleteModal(item);
                            }
                        }
                    ]}
                />
                <ActionPrompt
                    actions={[
                        {
                            title: "Delete",
                            btnVariant: "danger",
                            onClick: this.handleRemoveViewing,
                            asyncStatus: removePropertyViewingStatus,
                            onSuccess: this.hideDeleteModal
                        }
                    ]}
                    title="Delete this viewing"
                    bodyText="Are you sure you want to delete this viewing? This cannot be undone."
                    show={showDeleteModal}
                    cancel={this.hideDeleteModal}
                />
            </>
        );
    }
}

export default connector(ViewingsListContainer);
