import { ICompanyContact, IPagingResponse } from "../../../api/_types";
import { createPager } from "../../../lib/utils/pager";
import * as actions from "../../actions/companyContacts";
import { AsyncActionState } from "../../utils/asyncAction";

export interface ICompanyContactsState {
    viewingContact?: ICompanyContact;
    contacts: {
        paging: IPagingResponse;
        contacts: ICompanyContact[];
    };
    getCompanyContactsStatus: AsyncActionState;
    createCompanyContactStatus: AsyncActionState;
    editCompanyContactStatus: AsyncActionState;
    deleteCompanyContactStatus: AsyncActionState;
}

const initialState: ICompanyContactsState = {
    contacts: {
        paging: createPager({ itemsPerPage: 10 }),
        contacts: []
    },
    getCompanyContactsStatus: AsyncActionState.Reset,
    createCompanyContactStatus: AsyncActionState.Reset,
    editCompanyContactStatus: AsyncActionState.Reset,
    deleteCompanyContactStatus: AsyncActionState.Reset
};

export default function companyContactsReducer(
    state: ICompanyContactsState = initialState,
    action:
        | actions.CreateCompanyContactsStatus
        | actions.SetCompanyContacts
        | actions.SetCompanyContactsStatus
        | actions.SetViewingCompanyContact
        | actions.SetDeleteCompanyContactStatus
        | actions.EditCompanyContactsStatus
) {
    switch (action.type) {
        case actions.CompanyContactTypes.SET_VIEWING_COMPANY_CONTACT: {
            return Object.assign({}, state, {
                viewingContact: action.payload
            } as ICompanyContactsState);
        }
        case actions.CompanyContactTypes.DELETE_COMPANY_CONTACT_STATUS: {
            return Object.assign({}, state, {
                deleteCompanyContactStatus: action.payload
            } as ICompanyContactsState);
        }
        case actions.CompanyContactTypes.EDIT_COMPANY_CONTACT_STATUS: {
            return Object.assign({}, state, {
                editCompanyContactStatus: action.payload
            } as ICompanyContactsState);
        }
        case actions.CompanyContactTypes.CREATE_COMPANY_CONTACT_STATUS: {
            return Object.assign({}, state, {
                createCompanyContactStatus: action.payload
            } as ICompanyContactsState);
        }
        case actions.CompanyContactTypes.SET_COMPANY_CONTACTS: {
            return Object.assign({}, state, {
                contacts: action.payload
            } as ICompanyContactsState);
        }
        case actions.CompanyContactTypes.GET_COMPANY_CONTACTS_STATUS: {
            return Object.assign({}, state, {
                getCompanyContactsStatus: action.payload
            } as ICompanyContactsState);
        }

        default: {
            return state;
        }
    }
}
