import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { IProperty, IPropertyChainItem } from "../../../../../../../api/_types";

interface IProps {
    item: IPropertyChainItem;
    property: IProperty;
    thisPropertyInChain: boolean;
    updateItem: (
        item: IPropertyChainItem,
        key: keyof IPropertyChainItem,
        value: any
    ) => void;
}

const ThisPropertyCheck: FC<IProps> = ({
    item,
    property,
    thisPropertyInChain,
    updateItem
}) => {
    return (
        <Form.Group>
            <Form.Label>
                <strong>Is this {property.address.line1} ?</strong>
            </Form.Label>
            <Form.Check
                label={
                    thisPropertyInChain === true && item.thisProperty === false
                        ? "Property is already in the chain"
                        : "Yes"
                }
                custom={true}
                type="checkbox"
                id={"thisProperty-" + item._id}
                disabled={
                    item.thisProperty === false && thisPropertyInChain === true
                }
                checked={item.thisProperty === true}
                onChange={() =>
                    updateItem(item, "thisProperty", !item.thisProperty)
                }
            />
        </Form.Group>
    );
};

export default ThisPropertyCheck;
