import React, { ReactElement, FormEvent } from "react";
import { Form } from "react-bootstrap";
import FixedBottomActionBar from "../FixedBottomActionBar";
import ActionBar from "../ActionBar";

interface FeedbackMessage {
    heading?: string;
    message: string;
    customContent?: any;
}
interface IProps {
    onFeedbackMessageClose?: () => void;
    showSuccess?: boolean;
    spellCheck?: boolean;
    success?: FeedbackMessage;
    showError?: boolean;
    validated?: boolean;
    error?: FeedbackMessage;
    children: ReactElement | ReactElement[];
    actions?: ReactElement | ReactElement[];
    submit?: (e: FormEvent) => void;
    useSecondaryActionBar?: boolean;
}

export default function FormContainer(props: IProps) {
    const {
        showError = false,
        showSuccess = false,
        children,
        onFeedbackMessageClose,
        success = { heading: "", message: "" },
        error = { heading: "", message: "" },
        actions,
        submit,
        validated = false,
        spellCheck = true,
        useSecondaryActionBar = false
    } = props;

    const ContainerElement =
        useSecondaryActionBar === true ? ActionBar : FixedBottomActionBar;

    return (
        <Form validated={validated} onSubmit={submit} spellCheck={spellCheck}>
            <ContainerElement
                elements={actions}
                successFeedback={{
                    show: showSuccess === true && success !== undefined,
                    heading: success.heading as string,
                    message: success.message as string,
                    customContent: success.customContent,
                    onClose: onFeedbackMessageClose
                }}
                errorFeedback={{
                    show: showError === true && error !== undefined,
                    heading: error.heading as string,
                    message: error.message as string,
                    customContent: error.customContent,
                    onClose: onFeedbackMessageClose
                }}
            >
                {children}
            </ContainerElement>
        </Form>
    );
}
