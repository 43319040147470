import React, { FC } from "react";
import Select from "react-select";
import { IBranch } from "../../../../../../api/_types";

interface IProps {
    branches: IBranch[];
    selectedBranches: Array<{ label: string; value: string }>;
    onChange: (branches: Array<{ label: string; value: string }>) => void;
}

const WorkflowBranchSelect: FC<IProps> = ({
    branches,
    selectedBranches,
    onChange
}) => {
    const onChangeOption = (value: Array<{ label: string; value: string }>) => {
        onChange(value);
    };

    console.log("SB", selectedBranches);

    return (
        //@ts-ignore
        <Select
            className="mb-2"
            placeholder="Filter by branch"
            isMulti={true}
            options={branches.map((branch) => {
                return {
                    value: branch._id,
                    label: branch.name
                };
            })}
            styles={{
                container: (provided: any) => {
                    return { ...provided, zIndex: 20 };
                }
            }}
            onChange={onChangeOption as any}
            value={selectedBranches}
        />
    );
};

export default WorkflowBranchSelect;
