import React, { Component } from "react";
import CreateBranchContainer from "../../containers/CreateBranch";
import EditCompanyTopNav from "../../../company/components/EditCompanyTopNav";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import { ICompany } from "../../../../../../api/_types";

interface IProps {
    company?: ICompany;
}
interface IState {}

class CreateBranchScreen extends Component<IProps, IState> {
    render() {
        const { company } = this.props;
        if (company === undefined) {
            return null;
        }
        return (
            <PageWrap>
                <EntityTitle entityName={company.name} />
                <EditCompanyTopNav />

                <CreateBranchContainer />
            </PageWrap>
        );
    }
}

const mapStateToProps = ({ company }: IStore) => ({
    company: company.viewingCompany
});
export default connect(
    mapStateToProps,
    {}
)(CreateBranchScreen);
