/**
 * Session storage wrapper, handles any data structure that can be stringified by the JSON api.
 */
export default {
    setItem: <T>(key: string, item: T) => {
        let stringified: string;
        try {
            stringified = JSON.stringify(item);
        } catch (e) {
            throw e;
        }

        sessionStorage.setItem(key, stringified);
    },
    getItem: <T>(key: string) => {
        let item = sessionStorage.getItem(key);
        let parsed: T;

        if (item === null || item === undefined) {
            return undefined;
        }

        try {
            parsed = JSON.parse(item);
        } catch (e) {
            throw e;
        }

        return parsed;
    },
    removeItem: <T>(key: string) => sessionStorage.removeItem(key),
    clear: () => sessionStorage.clear()
};
