import React from "react";

interface IProps {
    size?: number;
}

export default function BranchImagePlaceholder(props: IProps) {
    const { size = 450 } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 450 450"
        >
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    {/*<g id="Layer_1-2-2" data-name="Layer 1-2">*/}
                    {/*    <rect fill="#ddd" width="450" height="450" rx="31" />*/}
                    {/*</g>*/}
                    <path
                        id="sitemap-solid"
                        fill="rgb(88,88,88)"
                        d="M117.86,278.66H64.2a17.78,17.78,0,0,0-17.78,17.78V350A17.78,17.78,0,0,0,64.2,367.82h53.66A17.78,17.78,0,0,0,135.64,350V296.53A17.78,17.78,0,0,0,118,278.66ZM104.47,234H211.64v26.8h26.81V234H345.62v26.8h26.79V228.66A21.45,21.45,0,0,0,351,207.22H238.45V171.5h22.33a17.79,17.79,0,0,0,17.79-17.78V100a17.79,17.79,0,0,0-17.79-17.78H189.3A17.78,17.78,0,0,0,171.52,100v53.67a17.78,17.78,0,0,0,17.78,17.78h22.34v35.81H99.12a21.45,21.45,0,0,0-21.44,21.44v32.15h26.79Zm147.37,44.65H198.25a17.78,17.78,0,0,0-17.78,17.78V350a17.78,17.78,0,0,0,17.78,17.78h53.59A17.78,17.78,0,0,0,269.62,350V296.53a17.78,17.78,0,0,0-17.69-17.87h-.09Zm134,0H332.21a17.78,17.78,0,0,0-17.78,17.78V350a17.78,17.78,0,0,0,17.78,17.78H385.8A17.78,17.78,0,0,0,403.58,350V296.53a17.78,17.78,0,0,0-17.69-17.87h-.09Z"
                    />
                </g>
            </g>
        </svg>
    );
}
