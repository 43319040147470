import React, { Component } from "react";
import TabBody from "../../../../../_sharedComponents/TabBody";
import ManagementInformationFormContainer from "../../containers/ManagementInformationForm";
import EditPropertyTopNav from "../../components/EditPropertyTopNav";
import PageWrap from "../../../../../_sharedComponents/PageWrap";

interface IProps {}

class MiReport extends Component<IProps> {
    render() {
        return (
            <PageWrap>
                <EditPropertyTopNav />

                <TabBody>
                    <ManagementInformationFormContainer />
                </TabBody>
            </PageWrap>
        );
    }
}

export default MiReport;
