import React, { FormEvent, FC } from "react";
import { Accordion } from "react-bootstrap";
import {
    IUser,
    IPropertyContacts,
    IPagingResponse, IAdditionalContact
} from "../../../../../../../../api/_types";
import ContactCard from "../../../../../contacts/containers/ContactCard";
import ContactLiteCard from "../ContactLiteCard";
import ContactAccordionItem from "../ContactAccordionItem";
import OtherContacts from "./OtherContacts";
import BStaffEntry from "./BStaffEntry";
import AdditionalContacts from "./AdditionalContacts";

interface IVendorContactsProps {
    vendor: IUser;
    contacts: IPropertyContacts;
    setSolicitor: ({ contact, poc }: any) => void;
    setMortgageBroker: ({ contact, poc }: any) => void;
    branchMembers: IUser[];
    branchMembersPaging: IPagingResponse;
    pageBranchMembers: (desiredPage: number) => void;
    checkUser: (item: IUser) => void;
    onChangeInputField: (stateField: string) => (event: FormEvent<any>) => void;
    saveContacts: () => void;
    addAdditionalContact: (contact: IAdditionalContact) => void;
    editAdditionalContact: (contact: IAdditionalContact) => void;
    removeAdditionalContact: (contact: IAdditionalContact) => void;
}
const VendorContactFields: FC<IVendorContactsProps> = ({
    vendor,
    contacts,
    branchMembers,
    branchMembersPaging,
    checkUser,
    saveContacts,
    setSolicitor,
    setMortgageBroker,
    pageBranchMembers,
    onChangeInputField,
    addAdditionalContact,
    removeAdditionalContact,
    editAdditionalContact
}) => {

    return (
        <Accordion defaultActiveKey="Vendor">
            <ContactAccordionItem title="Vendor">
                <ContactLiteCard
                    contact={{
                        name: `${vendor.firstName} ${vendor.lastName}`,
                        ...vendor
                    }}
                />
            </ContactAccordionItem>
            <ContactAccordionItem title="Additional contacts">
                <AdditionalContacts
                    addContact={addAdditionalContact}
                    removeContact={removeAdditionalContact}
                    editContact={editAdditionalContact}
                    contacts={contacts.additionalContacts || []}
                />
            </ContactAccordionItem>

            <ContactAccordionItem title="Solicitor">
                <ContactCard
                    onSelect={setSolicitor}
                    existingPOC={contacts.solicitor.poc}
                    onCreateSuccess={saveContacts}
                />
            </ContactAccordionItem>
            <ContactAccordionItem title="Mortgage broker">
                <ContactCard
                    onSelect={setMortgageBroker}
                    existingPOC={contacts.mBroker.poc}
                    onCreateSuccess={saveContacts}
                />
            </ContactAccordionItem>
            <ContactAccordionItem title="Agent Contact">
                <BStaffEntry
                    contacts={contacts}
                    branchMembers={branchMembers}
                    branchMembersPaging={branchMembersPaging}
                    checkUser={checkUser}
                    pageBranchMembers={pageBranchMembers}
                />
            </ContactAccordionItem>
            <ContactAccordionItem title="Notes">
                <OtherContacts
                    value={contacts.other}
                    onChangeInputField={onChangeInputField}
                />
            </ContactAccordionItem>
        </Accordion>
    );
};

export default VendorContactFields;
