import React, { Component } from "react";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import { IUser, ICompany, IBranch } from "../../../../../../api/_types";
import EditBranchTopNav from "../../containers/EditBranchTopNav";
import MIReport from "../../../company/containers/MIReport";

interface IProps {
    user?: IUser;
    company?: ICompany;
    branch?: IBranch;
}

interface IState {}

class MIReportScreen extends Component<IProps, IState> {
    render() {
        const { company, branch } = this.props;
        if (
            company === undefined ||
            company === null ||
            company._id === undefined ||
            branch === undefined ||
            branch === null ||
            branch._id === undefined
        ) {
            return null;
        }
        return (
            <PageWrap>
                <EntityTitle entityName={branch.name} />
                <EditBranchTopNav />
                <MIReport
                    type="branch"
                    branchFilter={{ value: branch._id, label: branch.name }}
                />
            </PageWrap>
        );
    }
}

const mapStateToProps = ({ user, branch, company }: IStore) => ({
    user: user.me,
    company: company.viewingCompany,
    branch: branch.viewingBranch
});

export default connect(
    mapStateToProps,
    {}
)(MIReportScreen);
