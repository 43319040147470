import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import property, {
    IGetPropertiesResponse
} from "../../../api/modules/property";
import { IProperty } from "../../../api/_types";
import { createPager } from "../../../lib/utils/pager";

interface IPropertySearchState {
    results: IGetPropertiesResponse;
}

const initialState = {
    results: {
        properties: [],
        paging: createPager({ itemsPerPage: 15 })
    }
} as IPropertySearchState;

export const searchProperties = createAsyncThunk(
    "propertySearch/search",
    async (params: {
        companyId: string;
        branchId: string;
        textSearch?: string;
        excludeIds?: string[];
    }) => {
        const { companyId, branchId, textSearch, excludeIds } = params;

        const res = await property.getProperties(
            branchId,
            companyId,
            undefined,
            textSearch,
            undefined,
            undefined,
            excludeIds
        );

        return res.data;
    }
);

// Then, handle actions in your reducers:
const propertySearchSlice = createSlice({
    name: "propertySearch",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(searchProperties.fulfilled, (state, action) => {
            state.results.paging = action.payload.paging;
            (state.results
                .properties as IProperty[]) = action.payload.properties;
        });
    }
});

export default propertySearchSlice;
