import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import VendorTypeahead from "../../user/containers/VendorTypeahead";
import BranchMemberTypeahead from "../../branch/containers/BranchAdminTypeahead";
import UserCard from "../../user/components/UserCard";
import { FormEventType } from "../../../../../lib/types/forms";
import { IUser } from "../../../../../api/_types";

interface IProps {
    type: "Vendor" | "Branch";
    showNewUserForm: boolean;
    newUserModel: any;
    newUserModelFields?: {
        email: string;
        firstName: string;
        lastName: string;
        tel: string;
        password: string;
        confirmPassword: string;
    };
    existingUserLabel?: string;
    newUserLabel?: string;

    updateUserInputField: (userKey: string) => (e: FormEventType) => void;
    clearNewUserModel?: () => void;
}
interface IState {}

export default class UserSelection extends Component<IProps, IState> {
    render() {
        const {
            type,
            showNewUserForm,
            updateUserInputField,
            newUserModel,
            clearNewUserModel,
            newUserModelFields,
            existingUserLabel = "Existing user",
            newUserLabel = "New user"
        } = this.props;
        return (
            <Tabs id="owner-tabs" unmountOnExit={true}>
                <Tab
                    eventKey="owner-tabs"
                    title={existingUserLabel}
                    tabClassName="mb-2"
                >
                    {type === "Vendor" && newUserModel && <VendorTypeahead />}
                    {type === "Branch" && <BranchMemberTypeahead />}
                </Tab>
                {showNewUserForm === true && newUserModelFields !== undefined && (
                    <Tab eventKey="new-user" title={newUserLabel}>
                        {showNewUserForm === true && (
                            <UserCard
                                user={newUserModel as IUser}
                                validateFields={showNewUserForm === true}
                                clearUser={clearNewUserModel}
                                updateUserEmail={updateUserInputField(
                                    newUserModelFields.email
                                )}
                                updateUserFirstName={updateUserInputField(
                                    newUserModelFields.firstName
                                )}
                                updateUserLastName={updateUserInputField(
                                    newUserModelFields.lastName
                                )}
                                updateUserPassword={updateUserInputField(
                                    newUserModelFields.password
                                )}
                                updateUserTel={updateUserInputField(
                                    newUserModelFields.tel
                                )}
                                updateUserConfirmPassword={updateUserInputField(
                                    newUserModelFields.confirmPassword
                                )}
                            />
                        )}
                    </Tab>
                )}
            </Tabs>
        );
    }
}
