import React from "react";
import styles from "./styles";

interface IProps {
    header: string;
}

export default function TimelineHeader(props: IProps) {
    const { header } = props;

    return <h2 style={styles.header}>{header}</h2>;
}
