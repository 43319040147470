import React, { Component } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import cdn from "../../../../../../api/modules/cdn";
import { IBranch, IUser } from "../../../../../../api/_types";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import ACLUserType from "../../../../../_sharedComponents/ACLUserType";
import AddressForm from "../../../../../_sharedComponents/AddressForm";
import BranchImagePlaceholder from "../../../../../_sharedComponents/BranchImagePlaceholder";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import UserSelection from "../../../common/UserSelection";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import { Link } from "react-router-dom";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";

interface IProps {
    user: IUser;
    branch?: IBranch;
    createBranchPending: boolean;
    createBranchError: boolean;
    createBranchSuccess: boolean;
    deleteBranchPending?: boolean;
    deleteBranchError?: boolean;
    deleteBranchSuccess?: boolean;
    createBranchAdministratorRef?: IUser | string;
    saveText?: string;
    createBranch: (branch: IBranch, file?: File) => void;
    resetManageBranchFormState: () => void;
    deleteBranch?: () => void;
    resetBranchAdminSearchResults: () => void;
    resetCreateBranchAdminRef: () => void;
    deletePhoto?: () => void;
}

interface IAdditionalBranchFields {}

interface IStateBranch extends IBranch, IAdditionalBranchFields {}

interface IState {
    branch: IStateBranch;
    previewURL: string;
    file?: File;
    dirty: boolean;
}

export default class ManageBranchForm extends Component<IProps, IState> {
    generateInitialBranch = (branch?: IBranch): IStateBranch => {
        let obj = branch || {
            name: "",
            members: [],
            properties: [],
            address: {
                line1: "",
                line2: "",
                town: "",
                county: "",
                country: "",
                postcode: ""
            },
            useBranchDeduction: false,
            company: "",
            tel: "",
            email: "",
            displayText: ""
        };
        return {
            ...obj,
            administrator: {
                firstName: "",
                lastName: "",
                tel: "",
                type: UserTypes.BRANCH_ADMIN,
                password: "",
                confirmPassword: "",
                email: ""
            }
            // Place any psuedo fields here, e.g. adding to an array
        };
    };

    state: IState = {
        branch: this.generateInitialBranch(this.props.branch),
        file: undefined,
        previewURL: "",
        dirty: false
    };

    componentDidUpdate(prevProps: IProps) {
        // Reset for create branch
        if (
            this.props.branch === undefined &&
            prevProps.createBranchPending === true &&
            this.props.createBranchSuccess === true
        ) {
            this.props.resetBranchAdminSearchResults();
            this.props.resetCreateBranchAdminRef();
            this.resetFormState();
        }

        if (
            prevProps.createBranchAdministratorRef !==
            this.props.createBranchAdministratorRef
        ) {
            this.updateUserFromVendorSearch();
        }

        // Reset for editing a branch
        if (
            this.props.branch !== undefined &&
            prevProps.branch !== undefined &&
            this.props.branch !== prevProps.branch
        ) {
            this.resetFormState();
        }
    }
    componentWillMount() {
        this.props.resetManageBranchFormState();
        this.resetFormState();
    }

    updateUserFromVendorSearch = () => {
        const branch = this.state.branch;
        branch.administrator = this.props.createBranchAdministratorRef as IUser;
        this.setState({
            branch
        });
    };

    resetFormState = () => {
        this.setState({
            branch: this.generateInitialBranch(this.props.branch),
            file: undefined,
            previewURL: "",
            dirty: false
        });
    };

    handleFormSubmit = () => {
        const { branch, file } = this.state;
        const { createBranch } = this.props;

        createBranch(branch, file);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "branch"
        );
    };

    handleFileChange = (event: any) => {
        this.setState({
            file: (event.target as any).files[0],
            previewURL: window.URL.createObjectURL(event.target.files[0])
        });
    };

    handlePressFileInput = () => {
        let fileInput = document.getElementById("branchFileInput");

        if (fileInput !== null) {
            fileInput.click();
        }
    };

    render() {
        const {
            createBranchError,
            createBranchSuccess,
            deleteBranchPending,
            user,
            deleteBranch,
            resetManageBranchFormState,
            saveText = "Save branch",
            deletePhoto
        } = this.props;
        const { branch, dirty } = this.state;
        const existingBranch = branch._id !== undefined;
        const inputsEnabled = [
            UserTypes.GYA_ADMIN,
            UserTypes.COMPANY_ADMIN,
            UserTypes.BRANCH_ADMIN
        ].includes(user.type);
        return (
            <LoadingContainer loading={deleteBranchPending === true}>
                <NavigationPrompt when={dirty === true} />
                <FormContainer
                    submit={(e) => {
                        e.preventDefault();
                        this.handleFormSubmit();
                    }}
                    showError={createBranchError}
                    showSuccess={createBranchSuccess}
                    success={{
                        heading: "Branch saved",
                        message:
                            "You can now use this branch for anything you like, you superhero!",
                        customContent: (
                            <p>
                                <Link
                                    to="/property/create"
                                    style={{ color: "white" }}
                                    href="#"
                                >
                                    Add a new property
                                </Link>{" "}
                                to this branch?
                            </p>
                        )
                    }}
                    error={{
                        heading: "Error saving branch",
                        message:
                            "Your branch was not created due to an error with the submission to the API."
                    }}
                    onFeedbackMessageClose={resetManageBranchFormState}
                    actions={
                        <>
                            <ACLUserType
                                types={[
                                    UserTypes.BRANCH_ADMIN,
                                    UserTypes.GYA_ADMIN,
                                    UserTypes.COMPANY_ADMIN
                                ]}
                            >
                                <Button size="lg" type="submit">
                                    {saveText}
                                </Button>
                            </ACLUserType>
                            {this.props.branch !== undefined && (
                                <ACLUserType
                                    types={[
                                        UserTypes.GYA_ADMIN,
                                        UserTypes.COMPANY_ADMIN
                                    ]}
                                >
                                    <Button
                                        size="lg"
                                        variant="danger"
                                        className="ml-2"
                                        onClick={deleteBranch}
                                    >
                                        Delete branch
                                    </Button>
                                </ACLUserType>
                            )}
                        </>
                    }
                >
                    <Form.Row>
                        <Col md={6}>
                            <Form.Group id="createBranchForm.name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={this.onChangeInputField("name")}
                                    value={branch.name}
                                    placeholder=""
                                    required
                                    name="name"
                                    disabled={inputsEnabled === false}
                                />
                            </Form.Group>
                            <Form.Group id="createBranchForm.tel">
                                <Form.Label>Telephone number</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={this.onChangeInputField("tel")}
                                    value={branch.tel}
                                    placeholder=""
                                    name="tel"
                                    disabled={inputsEnabled === false}
                                />
                            </Form.Group>
                            <Form.Group id="createBranchForm.email">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    onChange={this.onChangeInputField("email")}
                                    value={branch.email}
                                    placeholder=""
                                    name="email"
                                    disabled={inputsEnabled === false}
                                />
                            </Form.Group>
                            <AddressForm
                                address={
                                    this.props.branch
                                        ? this.props.branch.address
                                        : branch.address
                                }
                                onChangeInputField={this.onChangeInputField}
                                disabled={inputsEnabled === false}
                            />
                            <Form.Group id="createBranchForm.feeDeduction">
                                <Form.Label>Fee deduction </Form.Label>
                                <div>
                                    <Form.Check
                                        id="branch-fee-deduction"
                                        className="d-inline mr-2"
                                        label="Override company fee deduction"
                                        checked={branch.useBranchDeduction}
                                        onClick ={() => {
                                            const value = !branch.useBranchDeduction;
                                            this.onChangeInputField(
                                                "useBranchDeduction"
                                            )({currentTarget: {value}} as any);
                                         }}
                                        custom={true}
                                    />
                                </div>
                                <Form.Text className="text-muted mb-2">
                                    Branch specific fee percentage deducted from your the property agency fees, for example franchise fee.
                                </Form.Text>
                                <Form.Control
                                    type="number"
                                    required={false}
                                    min={0}
                                    max={100}
                                    step=".01"
                                    onChange={this.onChangeInputField(
                                        "feeDeduction"
                                    )}
                                    value={branch.feeDeduction?.toString()}
                                    disabled={!branch.useBranchDeduction}
                                />
                            </Form.Group>
                            <Form.Label>Display Text</Form.Label>
                            <p className="text-muted">
                                This is displayed alongside the branch in the
                                app.
                            </p>
                            <Form.Control
                                as="textarea"
                                rows="5"
                                onChange={this.onChangeInputField(
                                    "displayText"
                                )}
                                value={branch.displayText}
                                disabled={inputsEnabled === false}
                                maxLength={1750}
                            />
                        </Col>
                        <Col md={6}>
                            <div className="ml-2">
                                <Row>
                                    <Col style={{ marginBottom: 5 }}>
                                        {this.state.previewURL !== "" ||
                                        (this.props.branch &&
                                            this.props.branch.logo) ? (
                                            <Image
                                                thumbnail
                                                style={{ maxHeight: "250px" }}
                                                src={
                                                    this.state.previewURL === ""
                                                        ? cdn.getObjectURL(
                                                              //@ts-ignore
                                                              this.props.branch
                                                                  .logo.Key
                                                          )
                                                        : this.state.previewURL
                                                }
                                            />
                                        ) : (
                                            <BranchImagePlaceholder
                                                size={200}
                                            />
                                        )}
                                        <ACLUserType
                                            types={[
                                                UserTypes.GYA_ADMIN,
                                                UserTypes.COMPANY_ADMIN,
                                                UserTypes.BRANCH_ADMIN
                                            ]}
                                        >
                                            <div className="mt-2">
                                                <Button
                                                    className="mr-2"
                                                    onClick={
                                                        this
                                                            .handlePressFileInput
                                                    }
                                                >
                                                    Select image
                                                </Button>
                                                {this.state.previewURL !==
                                                    "" && (
                                                    <Button
                                                        variant="danger"
                                                        onClick={() =>
                                                            this.setState({
                                                                file: undefined,
                                                                previewURL: ""
                                                            })
                                                        }
                                                    >
                                                        Clear
                                                    </Button>
                                                )}
                                                {branch._id !== undefined &&
                                                    branch.logo !== undefined &&
                                                    this.state.previewURL ===
                                                        "" && (
                                                        <Button
                                                            variant="danger"
                                                            onClick={
                                                                deletePhoto
                                                            }
                                                        >
                                                            Delete image
                                                        </Button>
                                                    )}
                                            </div>

                                            <Form.Control
                                                id="branchFileInput"
                                                hidden
                                                as="input"
                                                type="file"
                                                onChange={this.handleFileChange}
                                            />
                                        </ACLUserType>
                                    </Col>
                                </Row>
                                <Row>
                                    {existingBranch === false && (
                                        <Col>
                                            <h4>Branch administrator</h4>
                                            <p>
                                                <small>
                                                    All company administrators
                                                    will automatically become
                                                    administrators of this
                                                    branch.
                                                </small>
                                            </p>
                                            <UserSelection
                                                type="Branch"
                                                showNewUserForm={
                                                    !!(
                                                        this.state.branch
                                                            .administrator &&
                                                        (this.state.branch
                                                            .administrator as IUser)
                                                            ._id === undefined
                                                    )
                                                }
                                                updateUserInputField={
                                                    this.onChangeInputField
                                                }
                                                newUserModel={
                                                    this.state.branch
                                                        .administrator
                                                }
                                                newUserModelFields={{
                                                    email:
                                                        "administrator.email",
                                                    firstName:
                                                        "administrator.firstName",
                                                    tel: "administrator.tel",
                                                    lastName:
                                                        "administrator.lastName",
                                                    password:
                                                        "administrator.password",
                                                    confirmPassword:
                                                        "administrator.confirmPassword"
                                                }}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Col>
                    </Form.Row>
                </FormContainer>
            </LoadingContainer>
        );
    }
}
