import {
    ICompanyContact,
    ICompanyContactPOC
} from "../../../../../../api/_types";
import _ from "lodash";

export const companyContactReducer = (
    state: ICompanyContact,
    action: {
        key: keyof ICompanyContact;
        value: any;
    }
) => {
    const updatedState = { ...state };

    _.set(updatedState, action.key, action.value);

    return updatedState;
};

export const pocReducer = (
    state: ICompanyContactPOC,
    action: {
        key: keyof ICompanyContactPOC;
        value: any;
    }
) => {
    const updatedState = { ...state };

    _.set(updatedState, action.key, action.value);

    return updatedState;
};
