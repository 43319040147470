import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { stripQueryStringFromUrl } from "../../../lib/utils/url";
import styles from "./TabNavStyle";
import "./styles.scss";
import { connect } from "react-redux";
import { IStore } from "../../../redux/store";

interface INavLinkItem {
    displayTitle: string;
    to: string;
}
interface IProps {
    sidebarOpen: boolean;
    links: INavLinkItem[];
}
interface IState {}

const TabDivider = () => <div className={"tab-divider"} style={{position: "absolute", right: 0, top: "50%", transform: "translateY(-50%)", width: 1, height: "60%",     backgroundColor: "#9a9a9a"}}/>

class TabNav extends Component<IProps, IState> {
    containerRef: HTMLDivElement | undefined;

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.sidebarOpen !== this.props.sidebarOpen) {
            this.resizeEventHandler();
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.resizeEventHandler);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeEventHandler);
    }

    resizeEventHandler = () => this.setState({});

    isActive = (link: INavLinkItem): boolean => {
        return (
            stripQueryStringFromUrl(window.location.pathname) ===
            stripQueryStringFromUrl(link.to)
        );
    };
    render() {
        const { links = [] } = this.props;
        return (
            <div
                ref={(ref: HTMLDivElement) => {
                    const firstSet = this.containerRef === undefined;
                    this.containerRef = ref;
                    if (firstSet === true) {
                        this.setState({});
                    }
                }}
            >
                <ul
                    className="nav nav-tabs mb-0"
                    style={styles.tabNav(this.containerRef)}
                >
                    {links.map((link, index, arr) => (
                        <li
                            className="nav-item"
                            key={`tn-${index}`}
                            style={styles.tabNavItem}
                        >
                            <NavLink
                                // TODO: Make Branches link active if the tab content is anything related to Branches
                                isActive={this.isActive.bind(this, link) as any}
                                to={link.to}
                                className="nav-link position-relative"
                            >
                                {link.displayTitle}
                                {index !== arr.length - 1 && (
                                    <TabDivider />
                                )}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

const mapStateToProps = ({ sidebar }: IStore) => ({
    sidebarOpen: sidebar.isOpen
});

export default connect(
    mapStateToProps,
    {}
)(TabNav);
