export const fileSizeLabel = (bytes: number) => {
    if (bytes < 1000) {
        return `${bytes} b`;
    }

    const kb = Math.round(bytes * 0.001);
    return `${kb} kb`;
};

export const downloadFileToClient = async (data: any, fileName: string) => {
    // Create Blob from file so tht we have it locally on the user's device
    const blob = new Blob([data], { type: "application/octet-stream" });
    // Get a URL for the blob
    const objectUrl = URL.createObjectURL(blob);

    // Create an anchor tag, asign the href to the object url, and programatically click it
    // to trigger a Download.
    const a = document.createElement("a");
    a.href = objectUrl;

    a.download = fileName;

    a.style.display = "none";
    a.target = "blank";
    document.body.appendChild(a);
    a.click();

    // Revoke the object URl
    URL.revokeObjectURL(objectUrl);
};
