import React, { Component } from "react";
import CreateCompanyContainer from "../../containers/CreateCompany";
import PageWrap from "../../../../../_sharedComponents/PageWrap";

interface IProps {}
interface IState {}

class CreateCompanyScreen extends Component<IProps, IState> {
    render() {
        return (
            <PageWrap>
                <h2>Create company</h2>

                <CreateCompanyContainer />
            </PageWrap>
        );
    }
}

export default CreateCompanyScreen;
