import React, { Component } from "react";
import { connect } from "react-redux";
import {
    ICompany,
    IPropertyTimelineEntity
} from "../../../../../../api/_types";
import { IStore } from "../../../../../../redux/store";
import Timeline from "../../../property/components/Timeline";
import { updateCompanyTimeline } from "../../../../../../redux/api/company";

interface IProps {
    company?: ICompany;
    updateCompanyTimeline: (timelineItems: IPropertyTimelineEntity[]) => void;
}
interface IState {}

class CompanyTimelineContainer extends Component<IProps, IState> {
    render() {
        const { company, updateCompanyTimeline } = this.props;
        if (company === undefined || company.timelineTemplate === undefined) {
            return;
        }
        const companyHasNoBranches = company.branches.length <= 0;
        return (
            //@ts-ignore
            <Timeline
                isProperty={false}
                timeline={company.timelineTemplate}
                timelineTemplate={company.timelineTemplate}
                saveTimeline={updateCompanyTimeline}
                editing={true}
                editingTemplate={companyHasNoBranches === true}
                editingDescription={companyHasNoBranches === true}
                editingTitle={companyHasNoBranches === true}
                editingStates={companyHasNoBranches === true}
            />
        );
    }
}

const mapStateToProps = ({ company }: IStore) => ({
    company: company.viewingCompany
});

export default connect(
    mapStateToProps,
    { updateCompanyTimeline }
)(CompanyTimelineContainer);
