export type ColumnWidthState = {
    [index: number]: number;
};

export const columnWidthReducer = function(
    state: ColumnWidthState = {},
    action: { type: "setWidth"; payload: { index: number; width: number } }
) {
    switch (action.type) {
        case "setWidth":
            return { ...state, [action.payload.index]: action.payload.width };
        default:
            return state;
    }
};

export const calcHorizotalStickyleft = (
    currentIndex: number,
    state: ColumnWidthState
) => {
    if (currentIndex === 0) {
        return 0;
    }

    let startIndex = currentIndex - 1;
    let width = 0;

    while (startIndex >= 0) {
        width += state[startIndex] || 0;
        startIndex--;
    }

    return width + "px";
};
