import React from "react";
import { IProperty, IPropertyTimelineTemplate } from "../../../../../../api/_types";
import moment from "moment";
import { Modal, Table, Button } from "react-bootstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
    show: boolean;
    property?: IProperty;
    branchTimelineTemplate: IPropertyTimelineTemplate;
    navigateToTimeline: () => void;
    close: () => void;
}

export default function (props: IProps) {
    const {branchTimelineTemplate, close, navigateToTimeline, property, show} = props;

    return (
        <Modal show={show} size="lg">
            <Modal.Header>
                <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p>The following tasks are overdue.</p>

                <Table striped bordered variant="">
                    <thead className="thead-dark">
                        <th></th>
                        <th>Item</th>
                        <th>Time elapsed</th>
                    </thead>
                    <tbody>
                    {
                    property?.timeline?.data.map((x) => {
                        const warningDate = x.warningDate;
                        const templateItem = branchTimelineTemplate.data.find((t) => t.key === x.key );
                        const timeElapsed = moment(warningDate).fromNow();
                        if (warningDate !== undefined && moment(warningDate).isBefore(moment()) && x.surveyData !== undefined && x.surveyData.awaitingReports !== true) {
                            return (
                                <tr>
                                    <td>
                                        <FontAwesomeIcon
                                            style={{
                                                textAlign: "center",
                                                marginRight: 5
                                            }}
                                            icon={faExclamationTriangle}
                                            color={"#F24236"}
                                        />
                                    </td>
                                    <td>{templateItem?.title}</td>
                                    <td>{timeElapsed}</td>
                                </tr>
                            )
                        }
                        else if (warningDate !== undefined && moment(warningDate).isBefore(moment()) && x.surveyData !== undefined && x.surveyData.awaitingReports === true) {
                            return (
                                <tr>
                                    <td>
                                        <FontAwesomeIcon
                                            style={{
                                                textAlign: "center",
                                                marginRight: 5
                                            }}
                                            icon={faExclamationTriangle}
                                            color="#FFCD00"
                                        />
                                    </td>
                                    <td>{templateItem?.title} - awaiting reports</td>
                                    <td>{timeElapsed}</td>
                                </tr>
                            )

                        }
                        else {
                            return null
                        }
                    })
                }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={close}>
                    Close
                </Button>
                <Button variant="primary" onClick={navigateToTimeline}>
                    Go to timeline
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
