import React from "react";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EditUserProfile from "../../containers/EditUserProfile";

interface IProps {}

function UserProfileScreen() {
    return (
        <PageWrap>
            <EditUserProfile />
        </PageWrap>
    );
}

export default UserProfileScreen;
