import moment from "moment";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { OfferState } from "../../../../../../api/modules/property";
import { IPropertyOffer } from "../../../../../../api/_types";
import {
    removePropertyOffer,
    setPropertyOfferToEdit
} from "../../../../../../redux/api/property";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import DataTable from "../../../../../_sharedComponents/DataTable";
import EditOfferForm from "../EditOfferForm";

const mapStateToProps = ({ property }: IStore) => ({
    property: property.viewingProperty,
    removePropertyOfferStatus: property.removePropertyOfferStatus,
    offerToEdit: property.offerToEdit
});

const connector = connect(
    mapStateToProps,
    {
        removePropertyOffer,
        setPropertyOfferToEdit
    }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {}

interface IState {
    showDeleteModal: boolean;
    offerToDelete?: IPropertyOffer;
}

class OffersListContainer extends Component<IProps, IState> {
    state: IState = {
        showDeleteModal: false,
        offerToDelete: undefined
    };

    componentWillMount() {
        this.props.setPropertyOfferToEdit(undefined as any);
    }

    showDeleteModal = (offerToDelete: IPropertyOffer) => {
        this.setState({
            showDeleteModal: true,
            offerToDelete
        });
    };

    hideDeleteModal = () => {
        this.setState({
            showDeleteModal: false,
            offerToDelete: undefined
        });
    };

    handleUpdateOffer = (item: IPropertyOffer) => {
        this.props.setPropertyOfferToEdit(item);
    };

    handleRemoveOffer = () => {
        const { offerToDelete } = this.state;
        if (offerToDelete === undefined || offerToDelete._id === undefined) {
            alert("Invalid offer.");
            return;
        }
        this.props.removePropertyOffer(offerToDelete._id);
    };

    renderEditForm = () => {
        return <EditOfferForm />;
    };

    render() {
        const { property, removePropertyOfferStatus, offerToEdit } = this.props;
        const { showDeleteModal } = this.state;
        if (property === undefined || property.offers.length <= 0) {
            return null;
        }

        if (offerToEdit !== undefined) {
            return this.renderEditForm();
        }

        return (
            <>
                <DataTable
                    data={property.offers}
                    showLoadingSpinner={
                        removePropertyOfferStatus === AsyncActionState.Pending
                    }
                    fields={[
                        {
                            field: "name",
                            displayName: "Name"
                        },
                        {
                            field: "amount",
                            displayName: "Amount",
                            mutator: (item: IPropertyOffer) =>
                                `£${item.amount.toLocaleString()}`
                        },
                        {
                            field: "when",
                            displayName: "When",
                            mutator: (item: IPropertyOffer) =>
                                moment(item.when).format("ll")
                        },
                        {
                            field: "accepted",
                            displayName: "Status",
                            mutator: (item: IPropertyOffer) => {
                                switch (item.state) {
                                    case OfferState.Accepted: {
                                        return `Accepted on ${moment(
                                            item.acceptedOn
                                        ).format("ll")}`;
                                    }
                                    case OfferState.Pending: {
                                        return "Pending";
                                    }
                                    case OfferState.Withdrawn: {
                                        return "Withdrawn";
                                    }
                                    case OfferState.Rejected: {
                                        return "Rejected";
                                    }
                                }
                            }
                        }
                    ]}
                    actions={[
                        {
                            btnTitle: "Manage",
                            btnVariant: "light",
                            action: this.handleUpdateOffer
                        },
                        {
                            btnTitle: "Remove",
                            btnVariant: "danger",
                            action: (item: IPropertyOffer) => {
                                this.showDeleteModal(item);
                            }
                        }
                    ]}
                />

                <ActionPrompt
                    actions={[
                        {
                            title: "Delete",
                            btnVariant: "danger",
                            onClick: this.handleRemoveOffer,
                            asyncStatus: removePropertyOfferStatus,
                            onSuccess: this.hideDeleteModal
                        }
                    ]}
                    title="Delete this offer"
                    bodyText="Are you sure you want to delete this offer? This cannot be undone."
                    show={showDeleteModal}
                    cancel={this.hideDeleteModal}
                />
            </>
        );
    }
}

export default connector(OffersListContainer);
