import React, { useReducer, useCallback } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CreateContactForm from "../../../company/components/CreateContactForm";
import {
    ICompanyContact,
    ICompanyContactPOC,
    IAddress,
    ICompany
} from "../../../../../../api/_types";
import { Row } from "react-bootstrap";
import CreateContactPOCForm from "../../../company/components/CreateContactPOCForm";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import { companyContactReducer, pocReducer } from "./reducers";
import { ICreateContactModalResult } from "./types";
import api from "../../../../../../api";

interface IProps extends RouteComponentProps {
    type: "contactAndPOC" | "POCOnly";
    company?: ICompany;
    contact?: ICompanyContact;
    show: boolean;
    cancel: () => void;
    onSuccess: (result: ICreateContactModalResult) => void;
    onError: () => void;
}

function CreateContactModal(props: IProps) {
    const {
        company = { _id: "" },
        contact,
        show,
        cancel,
        type,
        onSuccess,
        onError
    } = props;

    const size: "lg" | "sm" | "xl" | undefined =
        type === "POCOnly" ? undefined : "xl";

    const [companyContact, companyContactDispatch] = useReducer(
        companyContactReducer,
        {
            address: { line1: "", postcode: "" } as IAddress,
            company: company._id as string,
            name: "",
            tel: "",
            email: "",
            pocs: []
        }
    );
    const [poc, pocDispatch] = useReducer(pocReducer, {
        company: company._id as string,
        name: "",
        email: "",
        tel: ""
    });
    const save = useCallback(async () => {
        let newContact: ICompanyContact | undefined;
        let newPOC: ICompanyContactPOC | undefined;
        const companyId = company ? (company._id as string) : "";
        let contactId = contact ? (contact._id as string) : "";
        const handleError = () => {
            cancel();
            onError();
        };

        if (type === "contactAndPOC") {
            try {
                const result = await api.companyContacts.createContact(
                    companyId,
                    companyContact
                );

                newContact = result.data.data;
                contactId = newContact._id as string;
            } catch (e) {
                return handleError();
            }
        }

        try {
            const result = await api.companyContactPOCs.createPOC(
                companyId,
                contactId,
                {
                    ...poc,
                    company: companyId,
                    companyContact: contactId
                }
            );

            newPOC = result.data.data;
            // Populate the contact so the UI can display this detail alongside the POC info
            newPOC.companyContact = (contact as ICompanyContact) || newContact;

            // Populate new contact if we're just populating the POC
            if (newContact === undefined) {
                newContact = contact;
            }
        } catch (e) {
            return handleError();
        }

        return onSuccess({
            poc: newPOC,
            contact: newContact
        });
    }, [
        cancel,
        company,
        companyContact,
        contact,
        onError,
        onSuccess,
        poc,
        type
    ]);

    const createContactPOCOnInputChange = useCallback((key: string) => {
        return (event: React.FormEvent<HTMLInputElement>) => {
            const value: string = event.currentTarget.value;

            pocDispatch({
                key: key as keyof ICompanyContactPOC,
                value
            });
        };
    }, []);
    const createContactOnInputChange = useCallback((key: string) => {
        return (event: React.FormEvent<HTMLInputElement>) => {
            const value: string = event.currentTarget.value;

            companyContactDispatch({
                key: key as keyof ICompanyContact,
                value
            });
        };
    }, []);

    return (
        <Modal
            size={size}
            show={show}
            onHide={cancel}
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Creating contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {type !== "POCOnly" && (
                        <Col>
                            <h4>Company</h4>
                            <CreateContactForm
                                contact={{ address: {} } as ICompanyContact}
                                onChangeInputField={createContactOnInputChange}
                            />
                        </Col>
                    )}
                    <Col>
                        <h4>Point of contact</h4>
                        <CreateContactPOCForm
                            poc={poc as ICompanyContactPOC}
                            onChangeInputField={createContactPOCOnInputChange}
                        />
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-primary" onClick={cancel}>
                    Cancel
                </Button>
                <Button variant="primary" type="button" onClick={save}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = ({ company }: IStore) => ({
    company: company.viewingCompany
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(CreateContactModal));
