import React, { Component, FormEvent } from "react";
import { FormControl, Button } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";

interface IProps {
    search: (searchString: string) => void;
}
interface IState {
    searchString: string;
}

export default class BranchMemberTableInput extends Component<IProps, IState> {
    state: IState = {
        searchString: ""
    };
    updateInput = (event: FormEvent<any>) => {
        this.setState({
            searchString: event.currentTarget.value as string
        });
    };
    handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        this.props.search(this.state.searchString);
    };
    render() {
        return (
            <Form onSubmit={this.handleSearch}>
                <InputGroup>
                    <FormControl
                        onChange={this.updateInput}
                        placeholder={"Search user's name"}
                        autoComplete="off"
                    />
                    <InputGroup.Append>
                        <Button variant="primary" type="submit">
                            Search
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Form>
        );
    }
}
