import { IPropertyState } from "../../../reducers/property";

export const selectPropertyOffers = (state: IPropertyState) => {
    return state.viewingProperty && state.viewingProperty.offers
        ? state.viewingProperty.offers
        : [];
};

export const selectPropertyViewings = (state: IPropertyState) => {
    return state.viewingProperty && state.viewingProperty.viewings
        ? state.viewingProperty.viewings
        : [];
};
