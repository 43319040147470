import React from "react";

interface IProps {
    fill?: string;
    size?: number
}

export default function ManageUsers({size = 28, fill}: IProps) {
    return (
        <svg
            id="_x31__px"
            enableBackground="new 0 0 24 24"
            height={size}
            width={size}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={fill}
                d="m19.5 11h-5.02c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5.02c.275 0
                .5-.224.5-.5v-2.27c0-.179.096-.345.251-.434.156-.088.348-.088.502.002.222.13.487.202.747.202.827
                0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5c-.26 0-.525.072-.747.202-.155.09-.347.092-.502.002-.155-.089-.251-.255-.251-.434v-2.27c0-.276-.225-.5-.5-.5h-1.551c.034.164.051.331.051.5 0 1.378-1.121 2.5-2.5 2.5s-2.5-1.122-2.5-2.5c0-.169.017-.336.051-.5h-1.551c-.275 0-.5.224-.5.5v2.5c0
                .276-.224.5-.5.5s-.5-.224-.5-.5v-2.5c0-.827.673-1.5 1.5-1.5h2.27c.179 0
                .345.096.434.251s.089.347-.002.501c-.131.222-.202.487-.202.748 0 .827.673 1.5
                1.5 1.5s1.5-.673 1.5-1.5c0-.261-.071-.526-.201-.747-.091-.155-.091-.346-.002-.501.089-.156.255-.252.433-.252h2.27c.827
                0 1.5.673 1.5 1.5v1.551c.164-.034.331-.051.5-.051 1.379 0 2.5 1.122 2.5 2.5s-1.121 2.5-2.5 2.5c-.169 0-.336-.017-.5-.051v1.551c0
                .827-.673 1.5-1.5 1.5z"
            />
            <path
                fill={fill}
                d="m8 14c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-7c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3z"
            />
            <path
                fill={fill}
                d="m15.5 24c-.276 0-.5-.224-.5-.5v-3c0-1.93-1.57-3.5-3.5-3.5h-7c-1.93 0-3.5 1.57-3.5 3.5v3c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-3c0-2.481
                2.019-4.5 4.5-4.5h7c2.481 0 4.5 2.019 4.5 4.5v3c0 .276-.224.5-.5.5z"
            />
        </svg>
    );
}
