import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { Store } from "redux";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PersistConfig,
    Persistor,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../reducers";

export const persistConfig: PersistConfig = {
    key: "kriva_root",
    storage,
    blacklist: ["auth"],
    migrate: (state) => {
        return Promise.resolve(state);
    }
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const createStore = () => {
    return configureStore({
        reducer: persistedReducer,
        middleware: getDefaultMiddleware({
            immutableCheck: false,
            thunk: true,
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER
                ]
            }
        })
    });
};

type AppStore = ReturnType<typeof createStore>;

// Infer the `IStore` and `AppDispatch` types from the store itself
export type IStore = ReturnType<AppStore["getState"]>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore["dispatch"];

export default (): { store: Store; persistor: Persistor } => {
    const store = createStore();
    const persistor = persistStore(store);

    return { store, persistor };
};
