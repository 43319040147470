import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
    IBranch,
    IPropertyTimelineTemplate
} from "../../../../../../api/_types";
import {
    resetUpdatePropertyTimeline,
    updatePropertyTimeline
} from "../../../../../../redux/api/property";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import { filterTimeline } from "../../../utils/timeline";
import Timeline from "../../components/Timeline";

const mapStateToProps = ({ branch, property }: IStore) => ({
    property: property.viewingProperty,
    branch: branch.viewingBranch,
    updateTimelineStatus: property.updateTimelineStatus
});

const connector = connect(
    mapStateToProps,
    { updatePropertyTimeline, resetUpdatePropertyTimeline }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {}

class TimelineContainer extends Component<IProps> {
    componentWillUnmount() {
        this.props.resetUpdatePropertyTimeline();
    }

    componentDidUpdate(prevProps: IProps) {
        const { resetUpdatePropertyTimeline, property } = this.props;
        const prevProperty = prevProps.property;

        if (
            prevProperty !== undefined &&
            property !== undefined &&
            prevProperty !== property
        ) {
            resetUpdatePropertyTimeline();
        }
    }

    render() {
        const {
            property,
            branch,
            updateTimelineStatus,
            updatePropertyTimeline,
            resetUpdatePropertyTimeline
        } = this.props;

        if (property === undefined || property.timeline === undefined) {
            return null;
        }
        let timeline = property.timeline;

        if (branch !== undefined && branch.timelineTemplate !== undefined) {
            timeline = Object.assign(
                {},
                filterTimeline(timeline, {
                    checkBranchTemplate: true,
                    branch
                })
            );
        }

        return (
            <LoadingContainer
                loading={
                    property === undefined ||
                    updateTimelineStatus === AsyncActionState.Pending
                }
            >
                {property && timeline && (
                    <Timeline
                        isProperty={true}
                        property={property}
                        timeline={timeline}
                        timelineTemplate={
                            (property.branch as IBranch)
                                .timelineTemplate as IPropertyTimelineTemplate
                        }
                        saveTimeline={updatePropertyTimeline}
                        updateTimelineStatus={updateTimelineStatus}
                        resetTimelineUpdateStatus={resetUpdatePropertyTimeline}
                        editingStates={true}
                    />
                )}
            </LoadingContainer>
        );
    }
}

export default connector(TimelineContainer);
