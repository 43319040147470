import React from "react";

interface IProps {
    size?: number;
}

export default function CompanyImagePlaceholder(props: IProps) {
    const { size = 450 } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 450 450"
        >
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    {/*<g id="Layer_1-2-2" data-name="Layer 1-2">*/}
                    {/*    <rect fill="#ddd" width="450" height="450" rx="31" />*/}
                    {/*</g>*/}
                    <path
                        id="building-solid"
                        fill="rgb(88,88,88)"
                        d="M361.1,368.78H348.22V76a15.41,15.41,0,0,0-15.38-15.41H117.14A15.4,15.4,0,0,0,101.74,76V368.78H88.85a7.71,7.71,0,0,0-7.65,7.7v12.89H368.8V376.48a7.7,7.7,0,0,0-7.7-7.7ZM163.37,109.42a7.69,7.69,0,0,1,7.7-7.7h25.68a7.7,7.7,0,0,1,7.7,7.7h0v25.65a7.7,7.7,0,0,1-7.7,7.7H171.07a7.7,7.7,0,0,1-7.7-7.7h0Zm0,61.63a7.7,7.7,0,0,1,7.7-7.71h25.68a7.71,7.71,0,0,1,7.7,7.71h0v25.68a7.7,7.7,0,0,1-7.7,7.7H171.07a7.7,7.7,0,0,1-7.7-7.7h0Zm33.38,95H171.07a7.7,7.7,0,0,1-7.7-7.71h0V232.69a7.69,7.69,0,0,1,7.7-7.7h25.68a7.7,7.7,0,0,1,7.7,7.7h0v25.68a7.7,7.7,0,0,1-7.7,7.7h0Zm48.79,102.71H204.45V314.86a7.7,7.7,0,0,1,7.7-7.7h25.68a7.7,7.7,0,0,1,7.71,7.7h0Zm41.08-110.42a7.71,7.71,0,0,1-7.7,7.71H253.25a7.71,7.71,0,0,1-7.7-7.71h0V232.69a7.7,7.7,0,0,1,7.7-7.7h25.68a7.69,7.69,0,0,1,7.7,7.7h0Zm0-61.62a7.7,7.7,0,0,1-7.7,7.7H253.25a7.7,7.7,0,0,1-7.7-7.7h0V171.05a7.71,7.71,0,0,1,7.7-7.71h25.68a7.7,7.7,0,0,1,7.7,7.71h0Zm0-61.63a7.7,7.7,0,0,1-7.7,7.7H253.25a7.69,7.69,0,0,1-7.71-7.69V109.42a7.69,7.69,0,0,1,7.7-7.7h25.68a7.7,7.7,0,0,1,7.7,7.7h0Z"
                    />
                </g>
            </g>
        </svg>
    );
}
