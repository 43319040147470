import React, { Component } from "react";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import {
    IPagingResponse,
    IUser,
    IPagingParams
} from "../../../../../../api/_types";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import { searchGYAAdmins } from "../../../../../../redux/api/user";
import GYAAdminList from "../../components/GYAAdminList";

interface IProps {
    gyaUsers: IUser[];
    gyaUsersPaging: IPagingResponse;
    searchStatus: AsyncActionState;
    searchGYAAdmins: (searchString?: string, paging?: IPagingParams) => void;
}

class ManageGYAAdminsContainer extends Component<IProps> {
    componentDidMount() {
        this.searchUsers();
    }
    searchUsers = (paging?: IPagingResponse) => {
        const { searchGYAAdmins, gyaUsersPaging } = this.props;
        searchGYAAdmins("", paging || gyaUsersPaging);
    };

    pageUsers = (desiredPage: number) => {
        const { gyaUsersPaging } = this.props;
        this.searchUsers({
            currentPage: desiredPage,
            itemsPerPage: gyaUsersPaging.itemsPerPage
        } as IPagingResponse);
    };
    render() {
        const { gyaUsers, gyaUsersPaging } = this.props;

        return (
            <GYAAdminList
                users={gyaUsers}
                paging={gyaUsersPaging}
                pageUsers={this.pageUsers}
            />
        );
    }
}

const mapStateToProps = ({ user }: IStore) => {
    return {
        gyaUsers: user.gyas.users,
        gyaUsersPaging: user.gyas.paging,
        searchStatus: user.searchGYAAdminUsersStatus
    };
};

export default connect(
    mapStateToProps,
    { searchGYAAdmins }
)(ManageGYAAdminsContainer);
