import React, { useState } from "react";
import { Form } from "react-bootstrap";
import AddressForm from "../../../../../_sharedComponents/AddressForm";
import { ICompanyContact } from "../../../../../../api/_types";

interface IProps {
    contact: ICompanyContact;
    onChangeInputField: (key: string) => (event: React.FormEvent<any>) => void;
}

export default function CreateContactForm(props: IProps) {
    const { contact, onChangeInputField } = props;
    const [validateAddress, setValidateAddress] = useState(true);

    return (
        <>
            <Form.Group id="contact.name">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                    onChange={onChangeInputField("name")}
                    value={contact.name}
                    required={true}
                />
            </Form.Group>
            <Form.Group id="contact.email">
                <Form.Label>Primary email address</Form.Label>
                <Form.Control
                    type="email"
                    onChange={onChangeInputField("email")}
                    value={contact.email}
                />
            </Form.Group>
            <Form.Group id="contact.tel">
                <Form.Label>Primary telephone</Form.Label>
                <Form.Control
                    type="tel"
                    onChange={onChangeInputField("tel")}
                    value={contact.tel}
                />
            </Form.Group>

            <Form.Check
                className="mb-2"
                label="Address is known at this time."
                checked={validateAddress === true}
                id="address-not-known-check"
                onClick={() => setValidateAddress(!validateAddress)}
            />
            <AddressForm
                address={contact.address}
                onChangeInputField={onChangeInputField}
                validateAddress={validateAddress === true}
            />
        </>
    );
}
