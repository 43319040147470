import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import api from "../../../../../../api";
import {
    ICompany,
    ICompanyContact,
    ICompanyContactPOC
} from "../../../../../../api/_types";
import { IStore } from "../../../../../../redux/store";
import CompanyTypeahead from "../../components/CompanyTypeahead";
import PopulatedContact from "../../components/PopulatedContact";
import {
    CompanyInputArray,
    CompanyInputDropdownItemModelType,
    ICompanyInputDropdownItemModelNewRecord,
    ICompanyInputDropdownItemModelRecord,
    NewContactRequired,
    IOnContactSelectResult
} from "./types";
import CreateContactModal from "../CreateContactModal";
import { ICreateContactModalResult } from "../CreateContactModal/types";
import usePrevious from "../../../../../../lib/utils/hooks/usePrevious";
import { setViewingCompanyContact } from "../../../../../../redux/api/companyContacts";

interface IProps {
    company?: ICompany;
    existingPOC?: ICompanyContactPOC;
    onSelect: (result: IOnContactSelectResult) => void;
    setViewingCompanyContact?: typeof setViewingCompanyContact;
    onCreateSuccess?: () => void;
}

export function ContactCardContainer(props: IProps) {
    const { company, onSelect, existingPOC, setViewingCompanyContact, onCreateSuccess } = props;
    let [showCreateCompanyPOC, setShowCreateCompanyPOC] = useState<
        NewContactRequired
    >(NewContactRequired.None);
    let [pocs, setPocs] = useState<CompanyInputArray>([]);
    let [selectedPOC, setSelectedPOC] = useState<
        ICompanyContactPOC | undefined
    >(existingPOC);
    let [newPOCContact, setNewPOCContact] = useState<ICompanyContact>();
    const linkedEntity = selectedPOC === undefined;
    const [showUserSearch, setShowUserSearch] = useState<boolean>(
        selectedPOC === undefined
    );
    const prevSelecedPOC = usePrevious(selectedPOC);

    useEffect(() => {
        // If the POC has just been selected...
        if (prevSelecedPOC === undefined && selectedPOC !== undefined) {
            setShowUserSearch(false);
        }
        // Selected POC has just changed
        if (
            prevSelecedPOC !== undefined &&
            selectedPOC !== undefined &&
            prevSelecedPOC !== selectedPOC
        ) {
            setShowUserSearch(false);
        }
    }, [prevSelecedPOC, selectedPOC]);

    const formatDisplay = useCallback(
        (
            poc:
                | ICompanyInputDropdownItemModelRecord
                | ICompanyInputDropdownItemModelNewRecord
        ) => {
            if (
                poc.type === CompanyInputDropdownItemModelType.NewContactAndPOC
            ) {
                return "New company contact";
            } else if (poc.type === CompanyInputDropdownItemModelType.NewPOC) {
                const newRecord: ICompanyInputDropdownItemModelNewRecord = poc;
                const _contact = newRecord.contact as ICompanyContact;
                return `New point of contact for ${_contact.name}`;
            }
            let _poc = poc as ICompanyInputDropdownItemModelRecord;
            const contact = _poc.companyContact as ICompanyContact;

            return `${contact.name} - ${_poc.name}`;
        },
        []
    );
    const handleOnInputChange = useCallback(
        async (textSearch: string) => {
            if (company === undefined) {
                return;
            }

            let lastCompanyContactId: string;
            let _pocs: CompanyInputArray = [
                {
                    _name: textSearch,
                    type: CompanyInputDropdownItemModelType.NewContactAndPOC
                }
            ];

            try {
                let result = await api.companyContacts.getContacts(
                    company._id as string,
                    {
                        itemsPerPage: 99,
                        currentPage: 0
                    },
                    textSearch.trim()
                );

                result.data.data.contacts.forEach((contact) => {
                    const items: CompanyInputArray = contact.pocs.map(
                        (poc: ICompanyContactPOC) => {
                            return {
                                ...poc,
                                _name: (poc.companyContact as ICompanyContact)
                                    .name,
                                type: CompanyInputDropdownItemModelType.Record
                            };
                        }
                    );

                    if (items.length <= 0) {
                        items.push({
                            type: CompanyInputDropdownItemModelType.NewPOC,
                            _name: textSearch,
                            contact: contact,
                            companyContact: contact
                        });
                    }
                    _pocs = _pocs.concat(items);
                });
            } catch (e) {}

            _pocs
                .filter((item) => item._name !== textSearch)
                .forEach((_poc, index) => {
                    if (
                        _poc.type !==
                        CompanyInputDropdownItemModelType.NewContactAndPOC
                    ) {
                        let poc = _poc as ICompanyContactPOC;
                        let companyContact = poc.companyContact as ICompanyContact;
                        if (
                            lastCompanyContactId === undefined ||
                            companyContact._id !== lastCompanyContactId
                        ) {
                            _pocs.splice(index, 0, {
                                type: CompanyInputDropdownItemModelType.NewPOC,
                                _name: textSearch,
                                contact: poc.companyContact as ICompanyContact,
                                companyContact: poc.companyContact as ICompanyContact
                            });
                        }

                        lastCompanyContactId = companyContact._id as string;
                    }
                });

            setPocs(_pocs);
        },
        [company]
    );

    const handleOnChange = useCallback(
        (item: CompanyInputArray) => {
            if (item.length <= 0) {
                return;
            }
            const selectedItem:
                | ICompanyInputDropdownItemModelNewRecord
                | ICompanyInputDropdownItemModelRecord = item[0];

            switch (selectedItem.type) {
                case CompanyInputDropdownItemModelType.NewContactAndPOC: {
                    setShowCreateCompanyPOC(NewContactRequired.ContactAndPOC);
                    return;
                }
                case CompanyInputDropdownItemModelType.NewPOC: {
                    const selectedItemAsPOC = selectedItem as ICompanyContactPOC;
                    const selectedItemContact = selectedItemAsPOC.companyContact as ICompanyContact;
                    setNewPOCContact(selectedItemContact);
                    setShowCreateCompanyPOC(NewContactRequired.POCOnly);
                    return;
                }
                case CompanyInputDropdownItemModelType.Record: {
                    let selected = selectedItem as ICompanyContactPOC;
                    setSelectedPOC(selected);
                    onSelect({
                        contact: selected.companyContact as ICompanyContact,
                        poc: selected
                    });
                    return;
                }
            }
        },
        [onSelect]
    );

    const cancelCreateContact = useCallback(
        () => setShowCreateCompanyPOC(NewContactRequired.None),
        []
    );

    const onCreateEntitySuccess = useCallback(
        (result: ICreateContactModalResult) => {
            const { contact, poc } = result;

            cancelCreateContact();
            setSelectedPOC(poc);
            onSelect({
                contact: contact as ICompanyContact,
                poc: poc as ICompanyContactPOC
            });

            onCreateSuccess?.();
        },
        [cancelCreateContact, onSelect, onCreateSuccess]
    );

    const onCreateEntityError = useCallback(() => {
        cancelCreateContact();
    }, [cancelCreateContact]);

    return (
        <>
            {showUserSearch === true && (
                <CompanyTypeahead
                    data={pocs}
                    handleOnChange={handleOnChange}
                    handleOnInputChange={handleOnInputChange}
                    formatDisplay={formatDisplay}
                />
            )}
            {linkedEntity === false && (
                <PopulatedContact
                    selectedPOC={selectedPOC as ICompanyContactPOC}
                    showUserSearch={setShowUserSearch}
                    isShowingUserSearch={showUserSearch}
                    setViewingCompanyContact={setViewingCompanyContact}
                />
            )}

            <CreateContactModal
                type={
                    showCreateCompanyPOC === NewContactRequired.POCOnly
                        ? "POCOnly"
                        : "contactAndPOC"
                }
                show={showCreateCompanyPOC !== NewContactRequired.None}
                contact={newPOCContact}
                cancel={cancelCreateContact}
                onSuccess={onCreateEntitySuccess}
                onError={onCreateEntityError}
            />
        </>
    );
}

const mapStateToProps = ({ company, companyContactPOCs }: IStore) => ({
    pocs: companyContactPOCs.pocs.pocs,
    company: company.viewingCompany
});

export default connect(
    mapStateToProps,
    {
        setViewingCompanyContact
    }
)(ContactCardContainer);
