import moment from "moment";
import React, { FC } from "react";
import { ChainMortgageOffer, ChainSurveyBooked, OfferState, TimelineKeys } from "../../../../../../../api/modules/property";
import { IProperty, IPropertyChainItem, IPropertyTimeline } from "../../../../../../../api/_types";
import { IPropertyTimelineEntityState, IPropertyTimelineEntityType } from "../../../../../../../redux/_types/property/types";
import TimelineChecksForm from "./TimelineChecksForm";

interface IProps {
    chainItem: IPropertyChainItem;
    property: IProperty;
    updateItem: (
        item: IPropertyChainItem,
        key: keyof IPropertyChainItem,
        value: any
    ) => void;
}

const isRefTimelineItemComplete = (timeline: IPropertyTimeline, key: TimelineKeys) => 
    timeline.data.find(x => x.key === key)?.state === IPropertyTimelineEntityState.COMPLETE;
 
const TimelineChecks: FC<IProps> = ({property, chainItem, updateItem}) => {
    
    const {propertyRef} = chainItem;
    const krivaProperty = (propertyRef as IProperty)

    if ((krivaProperty?._id !== undefined && krivaProperty.timeline !== undefined) || chainItem.thisProperty === true) {
        const propertyVal = chainItem.thisProperty === true ? property :  krivaProperty;
        const timeline = propertyVal.timeline as IPropertyTimeline;
        const cSigned = propertyVal.mi?.cSigned;
        const notHavingSurveyStep = timeline.data.find((item) => item.key === TimelineKeys.NotHavingSurvey);
        const notHavingSurveyCompleted = notHavingSurveyStep?.state === IPropertyTimelineEntityState.COMPLETE;
        const surveys = timeline.data.filter((item) => item.type === IPropertyTimelineEntityType.SURVEY && item.key !== TimelineKeys.NotHavingSurvey);
        const acceptedOffer = propertyVal.offers.find((o) => o.state === OfferState.Accepted);
        const mortgageOfferCompleted = timeline.data.find(item => item.key === TimelineKeys.MortgageApproved && item.state === IPropertyTimelineEntityState.COMPLETE) !== undefined;
        
        let nextSurvey = undefined; 
        let nextSurveyDate = "";
        
        if (surveys.length > 0) {
            nextSurvey = surveys.reduce((prev, current) => moment(prev.surveyData.date).isBefore(current.surveyData.date) ? prev : current);
            nextSurveyDate = moment(nextSurvey?.surveyData?.date).format("DD/MM/YYYY");
        }
        
        let isHavingSurveyLabel:ChainSurveyBooked = ChainSurveyBooked.NA;
        if (notHavingSurveyCompleted === true && surveys.length <= 0) {
            isHavingSurveyLabel = ChainSurveyBooked.No;
        }
        else if (notHavingSurveyCompleted === false) {
            isHavingSurveyLabel = ChainSurveyBooked.NA;
        }
        else if (surveys.length > 0) {
            isHavingSurveyLabel = ChainSurveyBooked.Yes;
        }

        let mortgageOfferLabel = ChainMortgageOffer.No;
        if (acceptedOffer?.cashBuy === true) {
            mortgageOfferLabel = ChainMortgageOffer.Cash;
        } else if (mortgageOfferCompleted === true) {
            mortgageOfferLabel = ChainMortgageOffer.Yes;
        }

        return (
            <TimelineChecksForm     
                readonly={true}
                mosSent={isRefTimelineItemComplete(timeline, TimelineKeys.MemoOfSaleSent)} 
                mortgageOffer={mortgageOfferLabel} 
                searchesApplied={isRefTimelineItemComplete(timeline, TimelineKeys.SearchesAppliedFor)} 
                searchesBack={isRefTimelineItemComplete(timeline, TimelineKeys.SearchesBack)} 
                surveyDate={nextSurveyDate} 
                surveyBooked={isHavingSurveyLabel} 
                draftContracts={isRefTimelineItemComplete(timeline, TimelineKeys.DraftContractsIssued)}
                depositPaid={isRefTimelineItemComplete(timeline, TimelineKeys.DocumentsAndDepositsReceived)}
                enquiriesAnswered={isRefTimelineItemComplete(timeline, TimelineKeys.EnquiriesAnswered)} 
                enquiriesRaised={isRefTimelineItemComplete(timeline, TimelineKeys.EnquiriesRaised)} 
                contractSigned={cSigned !== undefined}
            />
        );
    }


    return (
        <TimelineChecksForm 
            readonly={false}
            mosSent={chainItem.timeline.mosSent} 
            mortgageOffer={chainItem.timeline.mortgageOffer} 
            searchesApplied={chainItem.timeline.searchesApplied} 
            searchesBack={chainItem.timeline.searchesBack} 
            surveyDate={chainItem.timeline.surveyDate} 
            surveyBooked={chainItem.timeline.surveyBooked} 
            draftContracts={chainItem.timeline.draftContracts} 
            depositPaid={chainItem.timeline.depositPaid}
            enquiriesAnswered={chainItem.timeline.enquiriesAnswered} 
            enquiriesRaised={chainItem.timeline.enquiriesRaised} 
            contractSigned={chainItem.timeline.contractSigned}
            updateItem={(key, value) => updateItem(chainItem, key as keyof IPropertyChainItem, value)}
        />
    );

}

export default TimelineChecks;