import { AsyncActionState } from "../../utils/asyncAction";

export enum PropertyDocumentTypes {
    CREATE_DOCUMENT_STATUS = "CREATE_DOCUMENT_STATUS",
    UPDATE_DOCUMENT_STATUS = "UPDATE_DOCUMENT_STATUS",
    DELETE_DOCUMENT_STATUS = "Delete_DOCUMENT_STATUS",
    EMAIL_DOCUMENT_STATUS = "EMAIL_DOCUMENT_STATUS"
}

export interface CreateDocumentStatus extends ReduxAction {
    type: PropertyDocumentTypes.CREATE_DOCUMENT_STATUS;
    payload: AsyncActionState;
}

export interface UpdateDocumentStatus extends ReduxAction {
    type: PropertyDocumentTypes.UPDATE_DOCUMENT_STATUS;
    payload: AsyncActionState;
}

export interface DeleteDocumentStatus extends ReduxAction {
    type: PropertyDocumentTypes.DELETE_DOCUMENT_STATUS;
    payload: AsyncActionState;
}

export interface EmailDocumentStatus extends ReduxAction {
    type: PropertyDocumentTypes.EMAIL_DOCUMENT_STATUS;
    payload: AsyncActionState;
}
