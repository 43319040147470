export default {
    titleStyles: {
        financial: {
            borderBottom: "2px solid #FFCD0095"
        } as React.CSSProperties,
        legal: {
            borderBottom: "2px solid #00A9CE95"
        } as React.CSSProperties,
        survey: {
            borderBottom: "2px solid #aaa"
        } as React.CSSProperties
    },
    sortableTh: { cursor: "pointer" }
};
