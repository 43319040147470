import React, { Component } from "react";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import { IUser } from "../../../../../../api/_types";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import UserCard from "../../components/UserCard";
import {
    createGYAAdmin,
    resetCreateUserStatus
} from "../../../../../../redux/api/user";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import { Button } from "react-bootstrap";
import FormContainer from "../../../../../_sharedComponents/FormContainer";

interface IProps {
    createUserStatus: AsyncActionState;
    createGYAAdmin: (user: IUser) => void;
    resetCreateUserStatus: () => void;
}
interface IState {
    user: IUser;
}

class CreateGYAAdminFormContainer extends Component<IProps> {
    readonly defaultUser: IUser = {
        firstName: "",
        lastName: "",
        type: UserTypes.GYA_ADMIN,
        email: "",
        tel: "",
        password: "",
        confirmPassword: ""
    };

    state: IState = {
        user: Object.assign({}, this.defaultUser)
    };

    componentDidUpdate(prevProps: IProps) {
        if (
            prevProps.createUserStatus !== AsyncActionState.Success &&
            this.props.createUserStatus === AsyncActionState.Success
        ) {
            this.setState({
                user: Object.assign({}, this.defaultUser)
            });
        }
    }
    componentDidMount() {
        this.props.resetCreateUserStatus();
    }
    componentWillUnmount() {
        this.props.resetCreateUserStatus();
    }

    onChangeInput = (stateField: string) => {
        return getStateFromOnChangeUpdate(this, this.state, stateField, "user");
    };

    createUser = () => {
        this.props.createGYAAdmin(this.state.user);
    };

    render() {
        const { createUserStatus, resetCreateUserStatus } = this.props;
        return (
            <FormContainer
                actions={[
                    <Button variant="primary" onClick={this.createUser}>
                        Save
                    </Button>
                ]}
                showSuccess={createUserStatus === AsyncActionState.Success}
                showError={createUserStatus === AsyncActionState.Error}
                onFeedbackMessageClose={resetCreateUserStatus}
                success={{
                    heading: "User account created",
                    message: "This user can now login to the platform."
                }}
                error={{
                    heading: "Error creating user account",
                    message: "Something went wrong creating the user account."
                }}
            >
                <UserCard
                    user={this.state.user}
                    updateUserFirstName={this.onChangeInput("firstName")}
                    updateUserLastName={this.onChangeInput("lastName")}
                    updateUserEmail={this.onChangeInput("email")}
                    updateUserPassword={this.onChangeInput("password")}
                    updateUserConfirmPassword={this.onChangeInput(
                        "confirmPassword"
                    )}
                />
            </FormContainer>
        );
    }
}

const mapStateToProps = ({ user }: IStore) => ({
    createUserStatus: user.createGYAAdminStatus
});

export default connect(
    mapStateToProps,
    { createGYAAdmin, resetCreateUserStatus }
)(CreateGYAAdminFormContainer);
