import React, { Component } from "react";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import {
    editBranchMember,
    resetEditBranchMembersStatus
} from "../../../../../../redux/api/branch";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import AddMemberForm from "../../components/AddMemberForm";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import { ICompany, IUser } from "../../../../../../api/_types";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import { deleteCompanyMember } from "../../../../../../redux/api/company";

interface IProps extends RouteComponentProps {
    company?: ICompany;
    member?: IUser;
    me?: IUser;
    deleteCompanyMemberStatus: AsyncActionState;
    deleteCompanyMember: (member: IUser) => void;
    editBranchMemberStatus: AsyncActionState;
    editBranchMember: (member: IUser) => void;
    resetEditBranchMembersStatus: () => void;
}

interface IState {
    showDelete: boolean;
    member: IUser;
}

class EditMemberFormContainer extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showDelete: false,
            member: this.generateInitialMember(this.props.member)
        };
    }

    generateInitialMember = (user?: IUser) => {
        return (
            user ||
            ({
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
                type: UserTypes.BRANCH_STAFF,
                company: this.props.company ? this.props.company._id : ""
            } as IUser)
        );
    };

    componentDidUpdate(prevProps: IProps) {
        const currentState = this.props.deleteCompanyMemberStatus;
        if (
            prevProps.deleteCompanyMemberStatus === AsyncActionState.Pending &&
            currentState !== AsyncActionState.Pending
        ) {
            if (currentState === AsyncActionState.Success) {
                this.deleteMemberSuccess();
            } else if (currentState === AsyncActionState.Error) {
                this.deleteMemberError();
            }
        }
    }

    triggerDeleteMember = () => this.setState({ showDelete: true });

    cancelDeleteMember = () => this.setState({ showDelete: false });

    deleteMember = () => {
        this.props.deleteCompanyMember(this.state.member);
    };

    deleteMemberSuccess = () => {
        this.cancelDeleteMember();
        this.props.history.goBack();
    };
    deleteMemberError = () => {
        alert("Something went wrong. Please try again.");
    };

    editMember = () => {
        const { editBranchMember } = this.props;

        editBranchMember(this.state.member);
    };

    resetFormState = () => {
        this.setState({
            member: this.generateInitialMember()
        });
        this.props.resetEditBranchMembersStatus();
        this.props.history.goBack();
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "member"
        );
    };

    render() {
        const {
            editBranchMemberStatus,
            deleteCompanyMemberStatus,
            me,
            resetEditBranchMembersStatus
        } = this.props;
        const { member, showDelete } = this.state;
        if (me === undefined) {
            return null;
        }
        const canDeleteViaUserType = [
            UserTypes.GYA_ADMIN,
            UserTypes.COMPANY_ADMIN
        ].includes(me.type);

        const canDelete =
            canDeleteViaUserType === true && me._id !== member._id;

        return (
            <LoadingContainer
                loading={editBranchMemberStatus === AsyncActionState.Pending}
            >
                <AddMemberForm
                    member={member}
                    updateField={this.onChangeInputField}
                    saveUser={this.editMember}
                    deleteUser={
                        canDelete ? this.triggerDeleteMember : undefined
                    }
                    saveUserStatus={editBranchMemberStatus}
                    resetSaveUserState={resetEditBranchMembersStatus}
                />

                {canDelete && (
                    <ActionPrompt
                        show={showDelete}
                        title="Are you sure?"
                        bodyText="This cannot be undone. The users account will be deleted, and be removed as the contact for any property to which they are currently assigned."
                        confirmText={`${member.firstName} ${member.lastName}`}
                        confirmTextLabel="Please enter the users full name to confirm."
                        cancel={this.cancelDeleteMember}
                        actions={[
                            {
                                title: "Confirm",
                                btnVariant: "danger",
                                useConfirm: true,
                                asyncStatus: deleteCompanyMemberStatus,
                                onClick: this.deleteMember
                            }
                        ]}
                    />
                )}
            </LoadingContainer>
        );
    }
}

const mapStateToProps = ({ branch, company, user }: IStore) => ({
    me: user.me,
    editBranchMemberStatus: branch.editBranchMemberStatus,
    company: company.viewingCompany,
    member: branch.editMember,
    deleteCompanyMemberStatus: company.deleteCompanyMemberStatus
});

export default connect(
    mapStateToProps,
    {
        editBranchMember,
        resetEditBranchMembersStatus,
        deleteCompanyMember
    }
)(withRouter<any, any>(EditMemberFormContainer));
