import React from "react";
import PropertyListContainer from "../../../property/containers/PropertyList";

interface IProps {
    solicitorContactId?: string;
    hideTopControls?: boolean;
    noPropertyDisplay?: React.ReactElement;
}
export default function CompanyPropertiesContainer(props: IProps) {
    return (
        <PropertyListContainer
            type="company"
            solicitorCompanyId={props.solicitorContactId}
            hideTopControls={props.hideTopControls}
            noPropertyDisplay={props.noPropertyDisplay}
        />
    );
}
