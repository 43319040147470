import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { TimelineKeys } from "../../../../../../api/modules/property";
import {
    IPropertyTimelineEntity,
    IPropertyTimelineEntityTemplate
} from "../../../../../../api/_types";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import {
    IPropertyTimelineEntityState,
    IPropertyTimelineEntityType
} from "../../../../../../redux/_types/property/types";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import FixedBottomActionBar from "../../../../../_sharedComponents/FixedBottomActionBar";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";
import CreateNote from "../../containers/CreateNote";
import SaleResetBtnRow from "../../containers/SaleResetBtnRow";
import TimelineItem from "../TimelineItem";
import withTimeline from "../_hoc/withTimeline";
import AddSurvey from "./AddSurvey";
import TimelineHeader from "./Header";
import HorizontalBar from "./HorizontalBar";
import ShowAllCheck from "./ShowAllCheck";
import styles from "./styles";
import Track from "./Track";
import { IProps, IState } from "./types";

class Timeline extends Component<IProps, IState> {
    containerRef?: any;
    state: IState = {
        showAllSteps: this.props.isProperty === true ? false : true,
        surveyToDelete: undefined,
        newNoteTimelineKey: undefined
    };
    propertySold: boolean = true;

    componentWillMount() {
        for (const item of this.props.timeline.PS) {
            if (item.state !== IPropertyTimelineEntityState.COMPLETE) {
                this.propertySold = false;
            }
        }
    }

    renderPreSaleItem = (item: IPropertyTimelineEntity, index: number) => {
        if (this.shouldRenderItem("SELLING", item, index) === false) {
            return null;
        }

        return this.item(item, index, {
            collapsible: this.propertySold === true ? true : undefined,
            defaultCollapsedValue:
                this.propertySold === true ? false : undefined
        });
    };

    renderSurveyItem = (item: IPropertyTimelineEntity, index: number) =>
        this.item(item, index, {
            collapsible: true,
            defaultCollapsedValue: false
        });

    shouldRenderItem = (
        type: "FINANCIAL" | "LEGAL" | "COMPLETING" | "SELLING",
        timelineItem: IPropertyTimelineEntity,
        index: number
    ) => {
        const { timeline } = this.props;
        const keys = {
            FINANCIAL: "F",
            LEGAL: "L",
            COMPLETING: "C",
            SELLING: "PS"
        };
        const key = keys[type] as "F" | "L" | "C" | "PS";

        if (this.state.showAllSteps === true) {
            return true;
        }

        if (
            timelineItem.type === IPropertyTimelineEntityType[type] &&
            index !== undefined &&
            index > 0 &&
            timeline[key][index - 1].state !==
                IPropertyTimelineEntityState.COMPLETE &&
            timeline[key][index - 1].state !== IPropertyTimelineEntityState.NA
        ) {
            return false;
        }

        return true;
    };

    setNewTimelineNoteKey = (key?: number) =>
        this.setState({ newNoteTimelineKey: key });

    triggerDeleteSurvey = (surveyToDelete: IPropertyTimelineEntity) =>
        this.setState({ surveyToDelete });

    cancelDeleteSurvey = () => this.setState({ surveyToDelete: undefined });

    confirmDeleteSurvey = () => {
        const { surveyToDelete } = this.state;
        const { onRemoveSurvey } = this.props;
        if (surveyToDelete !== undefined && onRemoveSurvey !== undefined) {
            onRemoveSurvey(surveyToDelete);
            this.cancelDeleteSurvey();
        }
    };

    item = (
        timelineItem: IPropertyTimelineEntity,
        index: number,
        options:
            | { collapsible?: boolean; defaultCollapsedValue?: boolean }
            | undefined = {
            collapsible: false,
            defaultCollapsedValue: false
        }
    ) => {
        const {
            editing,
            editingTemplate = false,
            editingTitle = false,
            editingDescription = false,
            editingStates = false,
            onChangeItemState,
            onChangeDaysTillComplete,
            onChangeTitle,
            onChangeDescription,
            onChangeSurveyData,
            timelineTemplate,
            property
        } = this.props;
        const { showAllSteps } = this.state;
        const templateItem = timelineTemplate.data.find(
            (item) => item.key === timelineItem.key
        );
        const shouldRenderItem = this.shouldRenderItem;

        if (templateItem === undefined) {
            return null;
        }

        // Hide the next financial & legal steps if the proceeding point has not been complete or is not set to not applicable
        if (showAllSteps === false) {
            if (shouldRenderItem("FINANCIAL", timelineItem, index) === false) {
                return null;
            }
            if (shouldRenderItem("LEGAL", timelineItem, index) === false) {
                return null;
            }
            if (shouldRenderItem("COMPLETING", timelineItem, index) === false) {
                return null;
            }
        }

        const title = templateItem.title as string;
        const description = templateItem.description as string;
        const hasNotes = property?.notes?.find(n => n.timelineKey === templateItem.key) !== undefined;

        return (
            <TimelineItem
                item={timelineItem}
                key={timelineItem.key}
                title={title}
                description={description}
                onChangeItemState={onChangeItemState as any}
                onChangeDaysTillComplete={onChangeDaysTillComplete as any}
                onChangeTitle={onChangeTitle as any}
                onChangeDescription={onChangeDescription as any}
                onRemoveSurvey={this.triggerDeleteSurvey}
                editing={editing}
                editingTemplate={editingTemplate}
                editingDescription={editingDescription}
                editingTitle={editingTitle}
                editingStates={editingStates}
                onChangeSurveyData={onChangeSurveyData}
                collapsible={options.collapsible}
                defaultCollapsedValue={options.defaultCollapsedValue}
                showNoteInput={() =>
                    this.setNewTimelineNoteKey(timelineItem.key)
                }
                hasNotes={hasNotes}
                onChangeCompletionDate={this.props.onChangeCompletionDate}
                completionDate={this.props.property?.completionDate}
                completionDateSet={this.props.completionDateSet}

            />
        );
    };

    render() {
        const {
            isProperty = true,
            property,
            timeline,
            saveTimeline,
            updateTimelineStatus,
            onChangeSurveyData,
            resetTimelineUpdateStatus,
            timelineTemplate,
            completionDateChanged
        } = this.props;
        const { showAllSteps, surveyToDelete, newNoteTimelineKey } = this.state;
        const rowClasses = "";
        const noChangesMadeToForm =
            [
                ...timeline.PS,
                ...timeline.L,
                ...timeline.S,
                ...timeline.F,
                ...timeline.C
            ].find((item: any) => item.dirty === true) === undefined && completionDateChanged === false;

        let completingSaleStarted =
            isProperty === false
                ? true
                : [
                      ...timeline.F,
                      ...timeline.L,
                      ...timeline.S.filter((x) => x.key !== TimelineKeys.NotHavingSurvey)
                  ]
                      .filter(
                          (item: IPropertyTimelineEntity) =>
                              item.state !== IPropertyTimelineEntityState.NA
                      )
                      .find(
                          (item: IPropertyTimelineEntity) =>
                              item.state !==
                              IPropertyTimelineEntityState.COMPLETE
                      ) === undefined;

        const isPropertyAndCashBuyer =
            isProperty === true &&
            property !== undefined &&
            property.buyer !== undefined &&
            property.buyer.cashBuyer === true;

        const toggleShowAll = () =>
            this.setState({
                showAllSteps: !showAllSteps
            });

        const hasPropertySold =
            isProperty &&
            timeline.PS.find(
                (item) => item.state !== IPropertyTimelineEntityState.COMPLETE
            ) === undefined;

        const surveys = timelineTemplate.data.filter(
            (item) => item.type === IPropertyTimelineEntityType.SURVEY
        );
        const addSurveyItem = timeline.S.find(
            (item) => item.key === TimelineKeys.NotHavingSurvey
        ) as IPropertyTimelineEntity;

        const addSurveyItemTemplate = timelineTemplate.data.find(
            (x) => x.key === TimelineKeys.NotHavingSurvey
        ) as IPropertyTimelineEntityTemplate;

        // Number of surveys that have been marked for deletion
        // Minusing this from the length of the survys gives the true
        // number of surveys this property currently has
        const numSurveysForDeletion = timeline.S.filter(
            (s) => s.appMarkedDelete === true
        ).length;

        const trueNumberOfSurveys = timeline.S.length - numSurveysForDeletion;

        // console.log(timeline);
        return (
            <>
                <ActionPrompt
                    show={surveyToDelete !== undefined}
                    title="Delete survey"
                    bodyText="Are you sure you want to delete this survey? You must save the timeline to execute this change."
                    actions={[
                        {
                            btnVariant: "danger",
                            title: "Confirm",
                            onClick: this.confirmDeleteSurvey
                        }
                    ]}
                    cancel={this.cancelDeleteSurvey}
                />

                <CreateNote
                    timelineKey={newNoteTimelineKey}
                    show={newNoteTimelineKey !== undefined}
                    close={() => this.setNewTimelineNoteKey(undefined)}
                />

                <NavigationPrompt when={noChangesMadeToForm === false} />
                <FixedBottomActionBar
                    successFeedback={{
                        show: updateTimelineStatus === AsyncActionState.Success,
                        heading: "Timeline saved",
                        message: "The timeline was successfully saved.",
                        onClose: resetTimelineUpdateStatus
                    }}
                    errorFeedback={{
                        show: updateTimelineStatus === AsyncActionState.Error,
                        heading: "Error saving",
                        message: "An error occured saving the timeline.",
                        onClose: resetTimelineUpdateStatus
                    }}
                    elements={
                        <>

                          
                            {saveTimeline !== undefined && (
                                <Button
                                    size="lg"
                                    disabled={noChangesMadeToForm === true}
                                    onClick={() => saveTimeline(timeline)}
                                >
                                    Save
                                </Button>
                            )}
                              <SaleResetBtnRow/>
                        </>
                    }
                >
                    {timeline && (
                        <>
                            {isProperty === true && (
                                <ShowAllCheck
                                    checked={showAllSteps === true}
                                    onClick={toggleShowAll}
                                />
                            )}
                            <div style={styles.timelineContainer}>
                                <Row>
                                    <Col
                                        sm="4"
                                        className={`${rowClasses} offset-sm-4 offset-sm-4`}
                                        style={{ marginBottom: 25 }}
                                    >
                                        <Track />

                                        {timeline.PS.map(
                                            this.renderPreSaleItem
                                        )}
                                    </Col>
                                </Row>
                                {(hasPropertySold === true ||
                                    showAllSteps === true) && (
                                    <>
                                        <Row>
                                            <Col sm="4" className={rowClasses}>
                                                <Track color="#00A9CE" />

                                                <HorizontalBar
                                                    color="#00A9CE"
                                                    side="right"
                                                />

                                                <TimelineHeader header="Legal" />

                                                {timeline.L.map(
                                                    //@ts-ignore
                                                    this.item
                                                )}
                                            </Col>
                                            <Col
                                                sm="4"
                                                className={rowClasses}
                                                style={styles.highTrack}
                                            >
                                                <Track />

                                                <TimelineHeader header="Survey" />
                                                {trueNumberOfSurveys <
                                                    surveys.length && (
                                                    <AddSurvey
                                                        existingSuveys={
                                                            timeline.S
                                                        }
                                                        addSurveyItem={
                                                            addSurveyItem
                                                        }
                                                        addSurveyItemTemplate={
                                                            addSurveyItemTemplate
                                                        }
                                                        surveys={surveys}
                                                        updateItemState={
                                                            this.props
                                                                .onChangeItemState
                                                        }
                                                        saveTimeline={() => {
                                                            if (
                                                                this.props
                                                                    .saveTimeline !==
                                                                undefined
                                                            ) {
                                                                this.props.saveTimeline(
                                                                    timeline
                                                                );
                                                            }
                                                        }}
                                                        addSurvey={(survey) => {
                                                            if (
                                                                this.props
                                                                    .onAddSurvey !==
                                                                undefined
                                                            ) {
                                                                this.props.onAddSurvey(
                                                                    survey.survey as IPropertyTimelineEntity
                                                                );
                                                            }

                                                            if (
                                                                this.props
                                                                    .saveTimeline !==
                                                                undefined
                                                            ) {
                                                                this.props.saveTimeline(
                                                                    timeline
                                                                );
                                                            }
                                                        }}
                                                    />
                                                )}
                                                {timeline.S.filter(
                                                    (s) =>
                                                        s.appMarkedDelete !==
                                                            true && s.key !== TimelineKeys.NotHavingSurvey
                                                ).map(this.renderSurveyItem)}
                                            </Col>
                                            <Col sm="4" className={rowClasses}>
                                                <HorizontalBar
                                                    color="#FFCD00"
                                                    side="left"
                                                />
                                                <Track color="#FFCD00" />
                                                <TimelineHeader header="Financial" />

                                                {isPropertyAndCashBuyer ===
                                                true ? (
                                                    <TimelineItem
                                                        item={
                                                            {
                                                                type:
                                                                    IPropertyTimelineEntityType.FINANCIAL,
                                                                state:
                                                                    IPropertyTimelineEntityState.COMPLETE
                                                            } as IPropertyTimelineEntity
                                                        }
                                                        key={Date.now().toString()}
                                                        title={
                                                            "Cash buy (No action)"
                                                        }
                                                        description={""}
                                                        onChangeItemState={() => {}}
                                                        onChangeDaysTillComplete={() => {}}
                                                        onChangeTitle={() => {}}
                                                        onChangeDescription={() => {}}
                                                        onRemoveSurvey={() => {}}
                                                        onChangeSurveyData={
                                                            onChangeSurveyData
                                                        }
                                                        collapsible={false}
                                                    />
                                                ) : (
                                                    timeline.F.map(
                                                        //@ts-ignore
                                                        this.item
                                                    )
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="4" className={rowClasses}>
                                                <HorizontalBar
                                                    color="#00A9CE"
                                                    side="right"
                                                    top="bottom"
                                                />
                                            </Col>
                                            <Col
                                                sm="4"
                                                className={rowClasses}
                                                style={styles.highTrack}
                                            >
                                                <Track />

                                                {(showAllSteps === true ||
                                                    completingSaleStarted) &&
                                                    timeline.C.map(
                                                        //@ts-ignore

                                                        this.item
                                                    )}
                                            </Col>
                                            <Col sm="4" className={rowClasses}>
                                                <HorizontalBar
                                                    color="#FFCD00"
                                                    side="left"
                                                    top="bottom"
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </FixedBottomActionBar>
            </>
        );
    }
}

//@ts-ignore
export default withTimeline(Timeline);
