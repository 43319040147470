import React from "react";

interface IProps {
    label: string;
    data: string | number;
}

export default function DataLabel(props: IProps) {
    const { data, label } = props;
    return (
        <p data-testid="ti-wdate">
            <strong>{label}</strong>: {data}
        </p>
    );
}
