import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { IPropertyOffer } from "../../../../../../../api/_types";

type OfferFields = Pick<IPropertyOffer, "mip">;

interface IProps extends OfferFields {
    onChange: (key: string) => (e: any) => void;
}

const Mip: FC<IProps> = ({ mip, onChange }) => {
    return (
        <Form.Group>
            <Form.Label>Mortgage in principle?</Form.Label>
            <Form.Check
                custom={true}
                type="checkbox"
                id="mipYes"
                name="mip"
                label={"Yes"}
                onChange={onChange("mip") as any}
                checked={(mip as any) === "1" || mip === true}
                value={"1"}
            />
            <Form.Check
                custom={true}
                type="checkbox"
                id="mipNo"
                name="mip"
                label={"No"}
                onChange={onChange("mip") as any}
                checked={(mip as any) === "0" || mip === false}
                value={"0"}
            />
        </Form.Group>
    );
};

export default Mip;
