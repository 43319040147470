import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import {default as companyApi} from "../../../../../../api/modules/company";
import { IProperty } from "../../../../../../api/_types";
import {
    resetCreatePropertyVendorRef,
    resetManagePropertyFormState,
    saveProperty
} from "../../../../../../redux/api/property";
import moment from "moment";
import { resetSearchVendorResults } from "../../../../../../redux/api/user";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import ManagePropertyForm from "../../components/ManagePropertyForm";

const mapStateToProps = ({ property, branch, company }: IStore) => ({
    createPropertyStatus: property.createPropertyStatus,
    createPropertyVendorRef: property.createPropertyVendorRef,
    viewingCompany: company.viewingCompany,
    branchIds: branch.branches.branches.map((b) => b._id!)
});

const connector = connect(
    mapStateToProps,
    {
        saveProperty,
        resetManagePropertyFormState,
        resetCreatePropertyVendorRef,
        resetSearchVendorResults
    }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {}

interface IState {
    showDuplicatePropertyModal: boolean,
    propertyToSave?: IProperty,
    fileToSave?: File
}

class CreatePropertyContainer extends Component<IProps, IState> {

    constructor (props: IProps) {
        super(props);

        this.state = {
            showDuplicatePropertyModal: false,
            propertyToSave: undefined,
            fileToSave: undefined
        }
    }

    handleSaveProperty = async (property: IProperty, file?: File | undefined) => {

        const {resetManagePropertyFormState, saveProperty, viewingCompany, branchIds} = this.props;
        let existingProperty: IProperty|undefined;
        // Validate another property does not already have the address
        try {
            const res = await companyApi.getCompanyProperties(viewingCompany?._id!, undefined, undefined, undefined, branchIds, undefined, undefined, property.address.postcode);
            if (res.data?.properties?.length > 0) {
                existingProperty = res.data.properties[0];
            }
        } catch (e) {
            resetManagePropertyFormState();
            alert("Error saving property");
            return;
        }

        if (existingProperty !== undefined) {
            this.setState({
                showDuplicatePropertyModal: true,
                propertyToSave: property,
                fileToSave: file
            });
        } else {
            saveProperty(property, file);
        }
    };

    cancelDuplicatePropertyPrompt = () => 
        this.setState({
            showDuplicatePropertyModal: false,
            propertyToSave: undefined,
            fileToSave: undefined
        });
    confirmSaveDuplicateProperty = () => {
        const {propertyToSave, fileToSave} = this.state;

        this.props.saveProperty(propertyToSave!, fileToSave);
    }
    

    
    render() {
        const {
            createPropertyStatus,
            createPropertyVendorRef,
            resetManagePropertyFormState,
            resetCreatePropertyVendorRef,
            resetSearchVendorResults
        } = this.props;

        return (
            <LoadingContainer
                loading={createPropertyStatus === AsyncActionState.Pending}
            >
                <ManagePropertyForm
                    createProperty={this.handleSaveProperty}
                    createPropertyPending={
                        createPropertyStatus === AsyncActionState.Pending
                    }
                    createPropertyError={
                        createPropertyStatus === AsyncActionState.Error
                    }
                    createPropertySuccess={
                        createPropertyStatus === AsyncActionState.Success
                    }
                    resetManagePropertyFormState={resetManagePropertyFormState}
                    createPropertyVendorRef={createPropertyVendorRef}
                    resetPropertyVendorRef={resetCreatePropertyVendorRef}
                    resetSearchVendorResults={resetSearchVendorResults}
                    saveText="Create property"
                />
                <ActionPrompt
                    show={this.state.showDuplicatePropertyModal === true}
                    title="Existing property found"
                    bodyText={`A property is already registered with your company with this postcode, it was added to your company on Kriva on ${moment(this.state.propertyToSave?.createdon).format("DD/MM/YYYY")}. 
                    
Are you sure you want to create this property?`}
                    actions={[
                        {asyncStatus: createPropertyStatus, title: "Confirm", onClick: this.confirmSaveDuplicateProperty}
                    ]}
                    cancel={this.cancelDuplicatePropertyPrompt}
                />
            </LoadingContainer>
        );
    }
}

export default connector(CreatePropertyContainer);
