import { CSSProperties } from "react";

export default {
    track: (width: number, color: string) =>
        ({
            backgroundColor: color,
            position: "absolute",
            left: `calc(50% - ${width / 2}px)`,
            bottom: "-25px",
            top: "50px",
            width: width,
            right: 0
        } as CSSProperties)
};
