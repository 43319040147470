import React, { Component } from "react";
import { Alert } from "react-bootstrap";

interface IProps {
    message: string;
    variant:
        | "danger"
        | "primary"
        | "secondary"
        | "success"
        | "warning"
        | "info"
        | "dark"
        | "light";
    heading?: string;
    dismissible?: boolean;
    customContent?: any;
    onClose?: () => void;
}
interface IState {}

export default class FeedbackAlert extends Component<IProps, IState> {
    render() {
        const {
            heading,
            message,
            dismissible = true,
            onClose,
            variant,
            customContent
        } = this.props;
        return (
            <Alert
                className="feedback-alert"
                variant={variant}
                dismissible={dismissible}
                onClose={onClose}
            >
                {heading && <Alert.Heading>{heading}</Alert.Heading>}
                <p>{message}</p>
                {customContent && customContent}
            </Alert>
        );
    }
}
