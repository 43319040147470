import moment, { Moment } from "moment";
import React from "react";
import {  Col, Form, Row, DropdownButton, Dropdown } from "react-bootstrap";
import { IBranch } from "../../../../../../../api/_types";
import { ChainStatus } from "../../../../../../../redux/_types/property/types";
import DatePicker from "../../../../../../_sharedComponents/DatePicker";
import PropertyFilter from "../../../../property/components/PropertyListHeaderOptions/PropertyFilter";
import PropertySearchInput from "../../../../property/components/PropertySearchInput";
import { IPropertyFilterKeyVal } from "../../../../property/containers/PropertyList/filters";
import CompanyUserSelect from "../../../containers/CompanyUserSelect";
import { IVisibleGroups, VisibleGroupKey } from "../../../containers/MIReport";
import SendMiPdf from "../../../containers/SendMiPdf";
import WorkflowBranchSelect from "../../WorkflowBranchSelect";

interface IProps {
    branches: IBranch[];
    filterBranches: Array<{ label: string; value: string }>;
    sortOption: any;
    chainStatus: ChainStatus[];
    startDate: string | Date;
    endDate: string | Date;
    filter: IPropertyFilterKeyVal[];
    visibleGroups: IVisibleGroups;
    hideBranchFilter?: boolean;
    bStaffIds: Array<{ label: string; value: string }>;
    searchText?: string;
    search: () => void;
    updateEndDate: (date: string | Date) => void;
    setChainStatus: (status: ChainStatus[]) => void;
    onChangeBStaff: (ids: Array<{ label: string; value: string }>) => void;
    clearAllFilters: () => void;
    updateStartDate: (date: string | Date) => void;
    updateSearchText: (text: string) => void;
    updateBranchFilter: (branches: Array<{ label: string; value: string }>) => void;
    toggleVisibleGroup: (group: VisibleGroupKey) => void;
    updatePropertyFilter: (filters: IPropertyFilterKeyVal[]) => void;
    downloadCsv: () => void;
}

export default function TableOptions(props: IProps) {
    const {
        branches,
        filterBranches,
        filter,
        endDate,
        bStaffIds,
        sortOption,
        visibleGroups,
        chainStatus,
        hideBranchFilter = false,
        startDate,
        searchText,
        search,
        downloadCsv,
        updateEndDate,
        onChangeBStaff,
        clearAllFilters,
        updateStartDate,
        updateSearchText,
        updateBranchFilter,
        toggleVisibleGroup,
        updatePropertyFilter,
    } = props;

    return (
        <>
            <div>

                <DropdownButton
                    id="workflow-actions"
                    size="sm"
                    variant="outline-dark"
                    className="float-right"
                    title="Actions"
                    drop="start"
                >
                    <Dropdown.Item onClick={clearAllFilters}>Clear filters</Dropdown.Item>
                    <Dropdown.Item onClick={downloadCsv}>Download CSV</Dropdown.Item>
                </DropdownButton>
            </div>
            <Row>
                <Col md="6" className="mb-3 pl-0">
                    <PropertySearchInput
                        search={search}
                        inlineForm={false}
                        initialValue={searchText}
                        updateSearchText={updateSearchText}
                    />
                </Col>
            </Row>

            <Row>
                <Col
                    className="mb-3 align-items-center justify-content-center"
                    sm="4"
                >
                    <PropertyFilter
                        active={filter}
                        updatePropertyFilter={updatePropertyFilter}
                    />
                </Col>
                {hideBranchFilter === false && (
                    <Col sm="4">
                        <WorkflowBranchSelect
                            selectedBranches={filterBranches}
                            branches={branches}
                            onChange={updateBranchFilter}
                        />
                    </Col>
                )}
                <Col
                    className="align-items-center justify-content-center"
                    sm="4"
                >
                    <SendMiPdf
                        branches={filterBranches.map(b => b?.value)}
                        chainStatus={chainStatus}
                        sortOption={sortOption}
                        visibleGroups={visibleGroups}
                        startDate={startDate}
                        endDate={endDate}
                        bStaffIds={bStaffIds?.map(
                            (user) => user?.value
                        )}
                        filter={filter}
                    />
                </Col>

                <Col sm="4">
                    <div className="d-flex flex-row">
                        <Form.Group
                            className="pr-1"
                            style={{ flex: 1 }}
                            controlId="effectiveDate"
                        >
                            <Form.Label>Added from</Form.Label>
                            <DatePicker
                                value={moment(startDate)}
                                timeFormat={false}
                                onChange={(e: string | Moment) => {
                                    let momentTime =
                                        typeof e === "string" ? moment(e) : e;
                                    updateStartDate(momentTime.toISOString());
                                }}
                                defaultValue={moment(startDate)}
                            />
                        </Form.Group>
                        <Form.Group
                            className="pr-1"
                            style={{ flex: 1 }}
                            controlId="effectiveDate"
                        >
                            <Form.Label>Until</Form.Label>
                            <DatePicker
                                value={moment(endDate)}
                                timeFormat={false}
                                onChange={(e: string | Moment) => {
                                    let momentTime =
                                        typeof e === "string" ? moment(e) : e;
                                    updateEndDate(momentTime.toISOString());
                                }}
                                defaultValue={moment(endDate)}
                            />
                        </Form.Group>
                    </div>
                </Col>
                <Col sm="4" className="mb-3">
                    <div>
                        <Form.Label>Sales progressor</Form.Label>
                    </div>
                    <CompanyUserSelect
                        onChange={onChangeBStaff}
                        styles={{
                            container: (provided: any) => {
                                return { ...provided, zIndex: 11 };
                            }
                        }}
                        bStaffIds={bStaffIds}
                    />
                </Col>
                <Col
                    className="mb-3 align-items-center justify-content-center"
                    sm="4"
                >
                    <div>
                        <div>
                            <Form.Label>
                                Toggle different sections of the report below
                            </Form.Label>
                        </div>

                        <Form.Check
                            id="sale-progression-check"
                            className="d-inline mr-2"
                            label="Sale progression"
                            checked={visibleGroups.saleProcess}
                            onClick={() => toggleVisibleGroup("saleProcess")}
                            custom={true}
                        />
                        <Form.Check
                            id="financial-check"
                            className="d-inline mr-2"
                            label="Financial"
                            checked={visibleGroups.financial}
                            onClick={() => toggleVisibleGroup("financial")}
                            custom={true}
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
}
