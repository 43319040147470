import React from "react";
import ActionPrompt from "../ActionPrompt";
import { AsyncActionState } from "../../../redux/utils/asyncAction";

interface IProps {
    show: boolean;
    deleteFunction: () => void;
    cancel: () => void;
    deleteStatus: AsyncActionState;
    onSuccess?: () => void;
    onError?: () => void;
}

export default function DeletePhotoPrompt(props: IProps) {
    const {
        cancel,
        deleteFunction,
        deleteStatus,
        onError,
        onSuccess,
        show
    } = props;
    return (
        <ActionPrompt
            title="Are you sure?"
            bodyText="This action cannot be undone."
            show={show}
            actions={[
                {
                    title: "Confirm",
                    asyncStatus: deleteStatus,
                    onClick: deleteFunction,
                    onSuccess,
                    onError
                }
            ]}
            cancel={cancel}
        />
    );
}
