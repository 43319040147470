import React, { FC, FormEvent } from "react";
import { Form } from "react-bootstrap";
import { ChainPropertySaleType } from "../../../../../../../api/modules/property";
import { IPropertyChainItem } from "../../../../../../../api/_types";

interface IProps {
    item: IPropertyChainItem;
    updateItem: (
        item: IPropertyChainItem,
        key: keyof IPropertyChainItem,
        value: any
    ) => void;
}

const SaleTypeDropdown: FC<IProps> = ({ item, updateItem }) => {
    return (
        <Form.Group>
            <Form.Label>
                <strong>Sale type</strong>
            </Form.Label>
            <Form.Control
                as="select"
                value={item.saleType}
                onChange={(e: FormEvent<HTMLInputElement>) =>
                    updateItem(item, "saleType", e.currentTarget.value)
                }
            >
                <option value={ChainPropertySaleType.NotDefined}>
                    Not applicable
                </option>
                <option value={ChainPropertySaleType.FirstTimeBuyer}>
                    First time buyer
                </option>
                <option value={ChainPropertySaleType.Investor}>Investor</option>
                <option value={ChainPropertySaleType.CashBuyer}>
                    Cash buyer
                </option>
                <option value={ChainPropertySaleType.NonDependantBuyer}>
                    Non dependant buyer
                </option>
            </Form.Control>
        </Form.Group>
    );
};

export default SaleTypeDropdown;
