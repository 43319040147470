import {
    ICompany,
    ISearchCompanyMembersResponse,
    IUser
} from "../../api/_types";
import { AsyncActionState } from "../utils/asyncAction";
import { IGetCompaniesResponse } from "../../api/modules/company";
import { IGetPropertiesResponse } from "../../api/modules/property";

export enum CompanyTypes {
    SET_COMPANIES = "SET_COMPANIES",
    SET_VIEWING_COMPANY = "SET_VIEWING_COMPANY",
    SAVE_COMPANIES_STATUS = "SAVE_COMPANIES_STATUS",
    GET_COMPANY_STATUS = "GET_COMPANY_STATUS",
    GET_COMPANIES_STATUS = "GET_COMPANIES_STATUS",
    DELETE_COMPANY_STATUS = "DELETE_COMPANY_STATUS",
    UPDATE_COMPANY_TIMELINE_STATUS = "UPDATE_COMPANY_TIMELINE_STATUS",
    SEARCH_COMPANY_USERS_STATUS = "SEARCH_COMPANY_USERS_STATUS",
    SET_COMPANY_USERS = "SET_COMPANY_USERS",
    CREATE_COMPANY_ADMIN_STATUS = "CREATE_COMPANY_ADMIN_STATUS",
    EDIT_COMPANY_ADMIN_STATUS = "EDIT_COMPANY_ADMIN_STATUS",
    SET_EDIT_COMPANY_ADMIN = "SET_EDIT_COMPANY_ADMIN",
    DELETE_MEMBER_STATUS = "DELETE_MEMBER_STATUS",
    DELETE_COMPANY_PHOTO_STATUS = "DELETE_COMPANY_PHOTO_STATUS",
    GET_COMPANY_PROPERTIES_STATUS = "GET_COMPANY_PROPERTIES_STATUS",
    SET_COMPANY_PROPERTIES = "SET_COMPANY_PROPERTIES"
}

export interface SetCompanies extends ReduxAction {
    type: CompanyTypes.SET_COMPANIES;
    payload: IGetCompaniesResponse;
}

export interface SetViewingCompany extends ReduxAction {
    type: CompanyTypes.SET_VIEWING_COMPANY;
    payload: ICompany | undefined;
}

export interface SaveCompanyStatus extends ReduxAction {
    type: CompanyTypes.SAVE_COMPANIES_STATUS;
    payload: AsyncActionState;
}

export interface GetCompaniesStatus extends ReduxAction {
    type: CompanyTypes.GET_COMPANIES_STATUS;
    payload: AsyncActionState;
}

export interface GetCompanyStatus extends ReduxAction {
    type: CompanyTypes.GET_COMPANY_STATUS;
    payload: AsyncActionState;
}

export interface DeleteCompanyStatus extends ReduxAction {
    type: CompanyTypes.DELETE_COMPANY_STATUS;
    payload: AsyncActionState;
}

export interface UpdateCompanyTimelineStatus extends ReduxAction {
    type: CompanyTypes.UPDATE_COMPANY_TIMELINE_STATUS;
    payload: AsyncActionState;
}

export interface SetSearchCompanyUsersStatus extends ReduxAction {
    type: CompanyTypes.SEARCH_COMPANY_USERS_STATUS;
    payload: AsyncActionState;
}

export interface SetCompanyUsers extends ReduxAction {
    type: CompanyTypes.SET_COMPANY_USERS;
    payload: ISearchCompanyMembersResponse;
}
export interface SetCreateCompanyAdminStatus extends ReduxAction {
    type: CompanyTypes.CREATE_COMPANY_ADMIN_STATUS;
    payload: AsyncActionState;
}

export interface EditCompanyAdminStatus extends ReduxAction {
    type: CompanyTypes.EDIT_COMPANY_ADMIN_STATUS;
    payload: AsyncActionState;
}

export interface SetEditCompanyAdmin extends ReduxAction {
    type: CompanyTypes.SET_EDIT_COMPANY_ADMIN;
    payload: IUser;
}

export interface DeleteCompanyMemberStatus extends ReduxAction {
    type: CompanyTypes.DELETE_MEMBER_STATUS;
    payload: AsyncActionState;
}

export interface DeleteCompanyPhotoStatus extends ReduxAction {
    type: CompanyTypes.DELETE_COMPANY_PHOTO_STATUS;
    payload: AsyncActionState;
}

export interface GetCompanyPropertiesStatus extends ReduxAction {
    type: CompanyTypes.GET_COMPANY_PROPERTIES_STATUS;
    payload: AsyncActionState;
}

export interface SetCompanyProperties extends ReduxAction {
    type: CompanyTypes.SET_COMPANY_PROPERTIES;
    payload: IGetPropertiesResponse;
}
