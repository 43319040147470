import React, { useState, useCallback } from "react";
import {
    ICompanyContactPOC,
    ICompanyContact
} from "../../../../../../api/_types";
import { Form, Col } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import routes from "../../../../../../navigation/routes";
import { setViewingCompanyContact as setViewingCompanyContactType } from "../../../../../../redux/api/companyContacts";

interface IProps {
    selectedPOC: ICompanyContactPOC;
    isShowingUserSearch?: boolean;
    showUserSearch?: (show: boolean) => void;
    setViewingCompanyContact?: typeof setViewingCompanyContactType;
}

function PopulatedContact(props: IProps & RouteComponentProps) {
    const {
        selectedPOC,
        showUserSearch,
        isShowingUserSearch,
        setViewingCompanyContact = (contact: any) => {}
    } = props;
    const [showNote, setShowNote] = useState<boolean>(false);
    const toggleShowNote = useCallback(() => setShowNote(!showNote), [
        showNote
    ]);

    if (selectedPOC === null || selectedPOC === undefined) {
        return null;
    }

    return (
        <>
            <FormGroupWrapper>
                <Form.Row>
                    {selectedPOC &&
                        typeof selectedPOC.companyContact !== "string" && (
                            <Col md="6">
                                <Form.Group controlId="company">
                                    <Form.Label>
                                        <strong>Company</strong>
                                    </Form.Label>
                                    <p
                                        className="text-primary"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setViewingCompanyContact(
                                                selectedPOC.companyContact as any
                                            );

                                            props.history.push(
                                                routes.companyContactPOCs.url
                                            );
                                        }}
                                    >
                                        {
                                            (selectedPOC.companyContact as ICompanyContact)
                                                .name
                                        }
                                    </p>
                                </Form.Group>
                            </Col>
                        )}
                    <Col md="6">
                        <Form.Group controlId="name">
                            <Form.Label>
                                <strong>Name</strong>
                            </Form.Label>
                            <p>{selectedPOC ? selectedPOC.name : "N/A"}</p>
                        </Form.Group>
                    </Col>
                </Form.Row>
            </FormGroupWrapper>

            <FormGroupWrapper>
                <Form.Row>
                    <Col md="6">
                        <Form.Group controlId="email">
                            <Form.Label>
                                <strong>Email</strong>
                            </Form.Label>
                            {selectedPOC ? (
                                <p>
                                    <a href={`mailto:${selectedPOC.email}`}>
                                        {selectedPOC.email}
                                    </a>
                                </p>
                            ) : (
                                <p>N/A"</p>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="tel">
                            <Form.Label>
                                <strong>Telephone</strong>
                            </Form.Label>

                            <p>
                                {selectedPOC !== undefined
                                    ? selectedPOC.tel
                                    : "N/A"}
                            </p>
                        </Form.Group>
                    </Col>
                </Form.Row>
            </FormGroupWrapper>

            <FormGroupWrapper>
                <Form.Row>
                    <Col>
                        <div style={{ flexDirection: "row" }}>
                            {showUserSearch !== undefined &&
                                isShowingUserSearch !== undefined && (
                                    <Form.Label
                                        className="text-primary mr-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            showUserSearch(!isShowingUserSearch)
                                        }
                                    >
                                        {isShowingUserSearch === true
                                            ? "Cancel"
                                            : "Change contact"}
                                    </Form.Label>
                                )}
                            {selectedPOC.note !== undefined &&
                                selectedPOC.note !== "" && (
                                    <Form.Label
                                        className="text-primary"
                                        style={{ cursor: "pointer" }}
                                        onClick={toggleShowNote}
                                    >
                                        {showNote === true
                                            ? "Hide note"
                                            : "Show note"}
                                    </Form.Label>
                                )}
                        </div>

                        {showNote === true && (
                            <Form.Group>
                                <Form.Label>
                                    <strong>Note</strong>
                                </Form.Label>

                                <p>{selectedPOC ? selectedPOC.note : ""}</p>
                            </Form.Group>
                        )}
                    </Col>
                </Form.Row>
            </FormGroupWrapper>
        </>
    );
}

const FormGroupWrapper: React.FunctionComponent = (props) => {
    return (
        <div style={{ marginTop: 10, marginBottom: 10 }}>{props.children}</div>
    );
};

export default withRouter<any, any>(PopulatedContact);
