import { ICompanyContactsGetListResponse } from "../../api/modules/companyContacts";
import { AsyncActionState } from "../utils/asyncAction";
import { ICompanyContact } from "../../api/_types";

export enum CompanyContactTypes {
    GET_COMPANY_CONTACTS_STATUS = "GET_COMPANY_CONTACTS_STATUS",
    SET_COMPANY_CONTACTS = "SET_COMPANY_CONTACTS",
    SET_VIEWING_COMPANY_CONTACT = "SET_VIEWING_COMPANY_CONTACT",
    CREATE_COMPANY_CONTACT_STATUS = "CREATE_COMPANY_CONTACT_STATUS",
    EDIT_COMPANY_CONTACT_STATUS = "EDIT_COMPANY_CONTACT_STATUS",
    DELETE_COMPANY_CONTACT_STATUS = "DELETE_COMPANY_CONTACT_STATUS"
}

export interface SetDeleteCompanyContactStatus extends ReduxAction {
    type: CompanyContactTypes.DELETE_COMPANY_CONTACT_STATUS;
    payload: AsyncActionState;
}

export interface SetCompanyContactsStatus extends ReduxAction {
    type: CompanyContactTypes.GET_COMPANY_CONTACTS_STATUS;
    payload: AsyncActionState;
}

export interface SetCompanyContacts extends ReduxAction {
    type: CompanyContactTypes.SET_COMPANY_CONTACTS;
    payload: ICompanyContactsGetListResponse;
}

export interface CreateCompanyContactsStatus extends ReduxAction {
    type: CompanyContactTypes.CREATE_COMPANY_CONTACT_STATUS;
    payload: AsyncActionState;
}

export interface EditCompanyContactsStatus extends ReduxAction {
    type: CompanyContactTypes.EDIT_COMPANY_CONTACT_STATUS;
    payload: AsyncActionState;
}

export interface SetViewingCompanyContact extends ReduxAction {
    type: CompanyContactTypes.SET_VIEWING_COMPANY_CONTACT;
    payload: ICompanyContact;
}
