import React, { Component } from "react";
import { connect } from "react-redux";
import { IUser } from "../../../../../../api/_types";
import { resetActivateAccountStatus } from "../../../../../../redux/acreators/user";
import { activateAccount } from "../../../../../../redux/api/user";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import AccountActivationForm from "../../components/AccountActivationForm";

interface IProps {
    me?: IUser;
    activateAccountStatus: AsyncActionState;
    activateAccount: (password: string) => void;
    resetActivateAccountStatus: () => void;
}
interface IState {
    password: string;
    passwordConfirm: string;
}

class AccountActivationContainer extends Component<IProps, IState> {
    state: IState = {
        password: "",
        passwordConfirm: ""
    };

    resetState = () => {
        this.setState({
            password: "",
            passwordConfirm: ""
        });

        this.props.resetActivateAccountStatus();
    };
    handleFormSubmit = () => {
        const { password, passwordConfirm } = this.state;
        const { activateAccount } = this.props;

        if (password !== passwordConfirm) {
            return alert("Passwords do not match.");
        }

        activateAccount(password);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(this, this.state, stateField);
    };

    render() {
        const { me, activateAccountStatus } = this.props;
        const { password, passwordConfirm } = this.state;
        if (me === undefined) {
            return null;
        }

        return (
            <AccountActivationForm
                formData={{
                    password,
                    passwordConfirm
                }}
                handleFormSubmit={this.handleFormSubmit}
                onChangeInput={this.onChangeInputField}
                saveError={activateAccountStatus === AsyncActionState.Error}
                savePending={activateAccountStatus === AsyncActionState.Pending}
                saveSuccess={activateAccountStatus === AsyncActionState.Success}
                resetFormData={this.resetState}
            />
        );
    }
}

const mapStateToProps = ({ user }: IStore) => ({
    me: user.me,
    activateAccountStatus: user.activateAccountStatus
});

export default connect(
    mapStateToProps,
    {
        activateAccount,
        resetActivateAccountStatus
    }
)(AccountActivationContainer);
