import React, { FC, FormEvent } from "react";
import { Form } from "react-bootstrap";

interface IProps {
    bedrooms?: number;
    onChangeInputField: (e: FormEvent) => void;
}

const BedroomsInput: FC<IProps> = ({bedrooms, onChangeInputField}) => {
    return (
        <>
            <Form.Label>Bedrooms</Form.Label>
            <Form.Control
                type="number"
                className="mb-2"
                min={0}
                onChange={(e:FormEvent) => onChangeInputField(e)}
                value={bedrooms?.toString()}
            />
        </>
    );
};

export default BedroomsInput;
