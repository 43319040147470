export enum ChainStatus {
    NoChain = "0",
    CompletedChain = "1",
    IncompleteChain = "2",
    Unknown = "3"
}

export enum IPropertyTimelineEntityType {
    SELLING = "PS",
    COMPLETING = "C",
    FINANCIAL = "F",
    SURVEY = "S",
    LEGAL = "L"
}

export enum IPropertyTimelineEntityState {
    COMPLETE = "C",
    NOT_STARTED = "NS",
    IN_PROGRESS = "IP",
    BLOCKED = "B",
    NA = "NA"
}

export const TIMELINE_STATE_NAMES = {
    [IPropertyTimelineEntityState.BLOCKED]: "Delayed",
    [IPropertyTimelineEntityState.NOT_STARTED]: "Not started",
    [IPropertyTimelineEntityState.IN_PROGRESS]: "In progress",
    [IPropertyTimelineEntityState.COMPLETE]: "Completed",
    [IPropertyTimelineEntityState.NA]: "Not applicable"
};
