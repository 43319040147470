import React, { useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ICompanyContact } from "../../../../../../api/_types";
import usePrevious from "../../../../../../lib/utils/hooks/usePrevious";
import routes from "../../../../../../navigation/routes";
import {
    deleteCompanyContact,
    getCompanyContacts,
    setViewingCompanyContact
} from "../../../../../../redux/api/companyContacts";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import DataTable from "../../../../../_sharedComponents/DataTable";
import { IDataTableAction } from "../../../../../_sharedComponents/DataTable/types";
import ContactListHeaderOptions from "../../components/ContactListHeaderOptions";
import EditContactForm from "../EditContactForm";

const mapStateToProps = ({ companyContacts }: IStore) => ({
    viewingContact: companyContacts.viewingContact,
    contacts: companyContacts.contacts.contacts,
    paging: companyContacts.contacts.paging,
    deleteCompanyContactStatus: companyContacts.deleteCompanyContactStatus
});

const connector = connect(
    mapStateToProps,
    { deleteCompanyContact, getCompanyContacts, setViewingCompanyContact }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps, RouteComponentProps {}

function CompanyContactsList(props: IProps) {
    const {
        deleteCompanyContactStatus,
        deleteCompanyContact,
        getCompanyContacts,
        contacts,
        paging
    } = props;
    const [editing, setEditing] = useState(false);
    const [searchText, setSearchText] = useState<string>("");
    const [showDeletePrompt, setShowDeletePrompt] = useState<{
        item?: ICompanyContact;
        show: boolean;
    }>({ show: false });
    const prevDeleteStatus = usePrevious(deleteCompanyContactStatus);
    const tableFields = [
        {
            field: "name",
            displayName: "Name"
        },
        {
            field: "address",
            displayName: "Address",
            mutator: (item: ICompanyContact) =>
                item.address
                    ? `${item.address.line1}-${item.address.postcode}`
                    : "Not known"
        },

        {
            field: "pocs",
            displayName: "Number of contacts",
            mutator: (item: ICompanyContact) => item.pocs.length
        }
    ];

    const getContacts = useCallback(
        () => getCompanyContacts(searchText, paging),
        [searchText, getCompanyContacts, paging]
    );

    const pageContacts = useCallback(
        (desiredPage: number) => {
            getCompanyContacts(searchText, {
                currentPage: desiredPage,
                itemsPerPage: paging.itemsPerPage
            });
        },
        [searchText, getCompanyContacts, paging]
    );

    const actions: IDataTableAction[] = [
        {
            btnTitle: "Manage",
            btnVariant: "light",
            action: (dataItem: ICompanyContact) => {
                props.setViewingCompanyContact(dataItem);
                props.history.push(routes.companyContactPOCs.url);
            }
        },
        {
            btnTitle: "Edit",
            btnVariant: "light",
            action: (dataItem: ICompanyContact) => {
                props.setViewingCompanyContact(dataItem);
                setEditing(true);
            }
        },
        {
            btnTitle: "Delete",
            btnVariant: "danger",
            action: (dataItem: ICompanyContact) => {
                setShowDeletePrompt({ show: true, item: dataItem });
            }
        }
    ];
    useEffect(() => {
        getContacts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            prevDeleteStatus === AsyncActionState.Pending &&
            deleteCompanyContactStatus === AsyncActionState.Success
        ) {
            setShowDeletePrompt({ show: false, item: undefined });
        }
    }, [deleteCompanyContactStatus, prevDeleteStatus]);

    if (props.viewingContact !== undefined && editing === true) {
        return <EditContactForm onCancel={() => setEditing(false)} />;
    }

    return (
        <>
            <ContactListHeaderOptions
                updateSearchText={setSearchText}
                getContacts={getContacts}
            />
            <DataTable
                data={contacts}
                fields={tableFields}
                actions={actions}
                showPager={true}
                pagingElement={paging}
                onPagerPageClick={pageContacts}
            />

            <ActionPrompt
                show={showDeletePrompt.show === true}
                title="Are you sure?"
                bodyText="This cannot be undone. The users account will be deleted, and be removed as the contact for any property to which they are currently assigned."
                confirmText={
                    showDeletePrompt.item && showDeletePrompt.item.name
                }
                confirmTextLabel="Please enter the contacts full name to confirm."
                cancel={() => {
                    setShowDeletePrompt({ show: false, item: undefined });
                }}
                actions={[
                    {
                        title: "Confirm",
                        btnVariant: "danger",
                        useConfirm: true,
                        asyncStatus: deleteCompanyContactStatus,
                        onClick: () => {
                            if (
                                showDeletePrompt.item !== undefined &&
                                showDeletePrompt.item._id !== undefined
                            ) {
                                deleteCompanyContact(showDeletePrompt.item._id);
                            }
                        }
                    }
                ]}
            />
        </>
    );
}

export default connector(withRouter(CompanyContactsList));
