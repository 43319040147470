import { IPagingResponse, ICompanyContactPOC } from "../../../api/_types";
import { createPager } from "../../../lib/utils/pager";
import * as actions from "../../actions/companyContactPOCs";
import { AsyncActionState } from "../../utils/asyncAction";

export interface ICompanyContactPOCsState {
    viewingContactPOC?: ICompanyContactPOC;
    pocs: {
        paging: IPagingResponse;
        pocs: ICompanyContactPOC[];
    };
    getCompanyContactPOCsStatus: AsyncActionState;
    createCompanyContactPOCStatus: AsyncActionState;
    editCompanyContactPOCStatus: AsyncActionState;
    deleteCompanyContactPOCStatus: AsyncActionState;
}

const initialState: ICompanyContactPOCsState = {
    pocs: {
        paging: createPager({ itemsPerPage: 10 }),
        pocs: []
    },

    getCompanyContactPOCsStatus: AsyncActionState.Reset,
    createCompanyContactPOCStatus: AsyncActionState.Reset,
    editCompanyContactPOCStatus: AsyncActionState.Reset,
    deleteCompanyContactPOCStatus: AsyncActionState.Reset
};

export default function companyContactPOCsReducer(
    state: ICompanyContactPOCsState = initialState,
    action:
        | actions.CreateCompanyContactPOCStatus
        | actions.SetCompanyContactPOCS
        | actions.SetCompanyContactPOCStatus
        | actions.SetDeleteCompanyContactPOCStatus
        | actions.EditCompanyContactPOCStatus
        | actions.SetViewingCompanyContactPOC
) {
    switch (action.type) {
        case actions.CompanyContactPOCTypes.SET_VIEWING_COMPANY_CONTACT_POC: {
            return Object.assign({}, state, {
                viewingContactPOC: action.payload
            } as ICompanyContactPOCsState);
        }
        case actions.CompanyContactPOCTypes.SET_DELETE_POC_STATUS: {
            return Object.assign({}, state, {
                deleteCompanyContactPOCStatus: action.payload
            } as ICompanyContactPOCsState);
        }
        case actions.CompanyContactPOCTypes.CREATE_COMPANY_CONTACT_POC_STATUS: {
            return Object.assign({}, state, {
                createCompanyContactPOCStatus: action.payload
            } as ICompanyContactPOCsState);
        }
        case actions.CompanyContactPOCTypes.EDIT_COMPANY_CONTACT_POC_STATUS: {
            return Object.assign({}, state, {
                editCompanyContactPOCStatus: action.payload
            } as ICompanyContactPOCsState);
        }
        case actions.CompanyContactPOCTypes.SET_COMPANY_CONTACT_POCS: {
            return Object.assign({}, state, {
                pocs: action.payload
            } as ICompanyContactPOCsState);
        }
        case actions.CompanyContactPOCTypes.GET_COMPANY_CONTACT_POCS_STATUS: {
            return Object.assign({}, state, {
                getCompanyContactPOCsStatus: action.payload
            } as ICompanyContactPOCsState);
        }

        default: {
            return state;
        }
    }
}
