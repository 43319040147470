import React, { useMemo } from "react";
import Select from "react-select";
import { BoardStatus } from "../../../../../../../api/modules/property";
import { PropertyOwnership } from "../../../../../../../api/_types";
import { FormEventType } from "../../../../../../../lib/types/forms";

interface IProps {
    current?: PropertyOwnership;
    onChangeInputField: (e: FormEventType) => void;
}

export default function BoardStatusSelect(props: IProps) {
    const { onChangeInputField, current } = props;
    const labels: any = useMemo(() => {
        return {
            [BoardStatus.NoBoard]: "No",
            [BoardStatus.BoardOrdered]: "Yes"
        };
    }, []);

    return (
        <>
            <label>For sale board?</label>
            <Select
                value={
                    {
                        label: current
                            ? labels[current]
                            : labels[BoardStatus.NoBoard],
                        value: current
                    } as any
                }
                options={
                    [
                        {
                            label: "Yes",
                            value: BoardStatus.BoardOrdered
                        },
                        {
                            label: "No",
                            value: BoardStatus.NoBoard
                        }
                    ] as Array<{
                        label: string;
                        value: BoardStatus;
                    }>
                }
                //@ts-ignore
                onChange={(option: { label: string; value: BoardStatus }) => {
                    onChangeInputField({
                        currentTarget: {
                            value: option.value
                        }
                    } as any);
                }}
            />
        </>
    );
}
