import React, { Component } from "react";

interface IProps {
    pageTitle?: string;
    pageIntro?: string[];
}
interface IState {}

export default class PageIntro extends Component<IProps, IState> {
    render() {
        const { pageTitle, pageIntro = [] } = this.props;
        return (
            <>
                {pageTitle && <h1 className="my-3">{pageTitle}</h1>}
                {pageIntro.map((pi, i) => (
                    <p key={`pi-${i}`}>{pi}</p>
                ))}

            </>
        );
    }
}
