import React, { FormEvent, FC } from "react";
import { Accordion, Form } from "react-bootstrap";
import { IBuyer, IPropertyContacts } from "../../../../../../../../api/_types";
import ContactCard from "../../../../../contacts/containers/ContactCard";
import { IOnContactSelectResult } from "../../../../../contacts/containers/ContactCard/types";
import ContactLiteCard from "../ContactLiteCard";
import ContactAccordionItem from "../ContactAccordionItem";

interface IBuyersContacts {
    buyer: IBuyer;
    contacts: IPropertyContacts;
    onChangeInputField: (stateField: string) => (event: FormEvent<any>) => void;
    setBuyerDetailsContact: (
        key: "buyer" | "solicitor" | "mBroker"
    ) => ({ contact, poc }: IOnContactSelectResult) => void;
    saveContacts: () => void;
}

const BuyersContacts: FC<IBuyersContacts> = ({
    buyer,
    contacts,
    saveContacts,
    setBuyerDetailsContact,
    onChangeInputField
}) => {
    return (
        <Accordion defaultActiveKey="Buyer's details">
            <ContactAccordionItem title="Buyer's details">
                {buyer === undefined ||
                buyer.name === undefined ||
                buyer.name === "" ? (
                    <p>Currently no buyer for this property</p>
                ) : (
                    <ContactLiteCard contact={buyer} />
                )}
            </ContactAccordionItem>
            <ContactAccordionItem title="Solicitor">
                <ContactCard
                    onSelect={setBuyerDetailsContact("solicitor")}
                    existingPOC={contacts.buyerDetails.solicitor.poc}
                    onCreateSuccess={saveContacts}
                />
            </ContactAccordionItem>
            <ContactAccordionItem title="Mortgage Broker">
                <ContactCard
                    onSelect={setBuyerDetailsContact("mBroker")}
                    existingPOC={contacts.buyerDetails.mBroker.poc}
                    onCreateSuccess={saveContacts}
                />
            </ContactAccordionItem>
            <ContactAccordionItem title="Additional Contacts">
                <Form.Control
                    as="textarea"
                    rows="4"
                    onChange={onChangeInputField("buyerDetails.other")}
                    value={contacts.buyerDetails.other}
                />
            </ContactAccordionItem>
        </Accordion>
    );
};

export default BuyersContacts;
