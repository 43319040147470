import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import branch from "./branch";
import company from "./company";
import companyContacts from "./companyContacts";
import companyContactPOCs from "./companyContactPOCs";
import property from "./property";
import notes from "./property/notes";
import chain from "./property/chain";
import sidebar from "./sidebar";
import propertySearch from "./propertySearch";
import documents from "./property/documents";

export default combineReducers({
    auth,
    user,
    company,
    companyContacts,
    companyContactPOCs,
    branch,
    property,
    propertySearch: propertySearch.reducer,
    notes,
    chain,
    sidebar,
    documents
});
