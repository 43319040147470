import {
    ICompanyContactPOC,
    ICompanyContact
} from "../../../../../../api/_types";

export enum CompanyInputDropdownItemModelType {
    Record,
    NewPOC,
    NewContactAndPOC
}
export interface ICompanyInputDropdownItemModelRecord
    extends ICompanyContactPOC {
    _name: string;
    type: CompanyInputDropdownItemModelType;
}
export interface ICompanyInputDropdownItemModelNewRecord {
    _name: string;
    type: CompanyInputDropdownItemModelType;
    contact?: ICompanyContact;
}

export type CompanyInputArray = Array<
    | ICompanyInputDropdownItemModelRecord
    | ICompanyInputDropdownItemModelNewRecord
>;

export enum NewContactRequired {
    None,
    POCOnly,
    ContactAndPOC
}

export interface IOnContactSelectResult {
    contact?: ICompanyContact;
    poc?: ICompanyContactPOC;
}
