import { api, BASE_URL } from "../index";
import { UserTypes } from "../../redux/_types/user/types";
import { AxiosResponse } from "axios";
import {
    ICompany,
    ISearchVendorsResponse,
    IPagingResponse,
    IPagingParams,
    IPropertyTimelineEntity,
    IUser,
    IAddCompanyAdminResponse,
    IPropertyGetListFilters,
    PropertySortOptionType
} from "../_types";
import { IGetPropertiesResponse } from "./property";

export interface IGetCompaniesResponse {
    paging: IPagingResponse;
    companies: ICompany[];
}

const buildSubmitCompanyPayload = (
    company: ICompany,
    file?: File
): FormData => {
    const data = new FormData();

    if (company._id !== undefined || company._id !== null) {
        data.append("_id", company._id as string);
    }

    data.append(`name`, company.name);
    data.append(`availableLicenses`, company.availableLicenses.toString());

    data.append(`tel`, company.tel);

    data.append(`address[line1]`, company.address.line1);
    data.append(`address[line2]`, company.address.line2);
    data.append(`address[postcode]`, company.address.postcode);
    data.append(`address[town]`, company.address.town);
    data.append(`address[county]`, company.address.county);
    data.append(`address[country]`, company.address.country);

    if (company.branding?.brandColour) {
        data.append("branding[brandColour]", company.branding.brandColour);
    }

    if (company.administrator !== undefined) {
        data.append(
            `administrator[firstName]`,
            company.administrator.firstName
        );
        data.append(`administrator[lastName]`, company.administrator.lastName);
        data.append(`administrator[email]`, company.administrator.email);
        data.append(`administrator[tel]`, company.administrator.tel);

        if (
            company.administrator.password !== undefined &&
            company.administrator.password !== ""
        ) {
            data.append(
                `administrator[password]`,
                company.administrator.password
            );
        }
    }

    if (company.feeDeduction !== undefined) {
        data.append("feeDeduction", company.feeDeduction.toString());
    }

    if (file !== undefined) {
        data.append("file", file);
    }

    return data;
};

export default {
    getCompanyById: (_id: string): Promise<any> => {
        return api.get(BASE_URL + "company/" + _id);
    },
    getCompanies: (
        query: IPagingParams
    ): Promise<AxiosResponse<IGetCompaniesResponse>> => {
        return api.get(BASE_URL + "company/list", {
            params: {
                itemsPerPage: query.itemsPerPage || 10,
                currentPage: query.currentPage
            }
        });
    },
    createCompany: (company: ICompany, file?: File): Promise<any> => {
        const data = buildSubmitCompanyPayload(company, file);

        return api({
            url: BASE_URL + "company",
            method: "POST",
            data,
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    editCompany: (company: ICompany, file?: File): Promise<any> => {
        const data = buildSubmitCompanyPayload(company, file);

        return api({
            url: BASE_URL + "company",
            method: "PUT",
            data,
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    deleteCompany: (companyId: string): Promise<any> => {
        return api.delete(BASE_URL + `company/${companyId}`);
    },

    searchMembers: (
        companyId: string,
        paging: IPagingParams = { itemsPerPage: 10, currentPage: 0 },
        searchString: string = "",
        type?:
            | UserTypes.BRANCH_ADMIN
            | UserTypes.BRANCH_STAFF
            | UserTypes.COMPANY_ADMIN
            | UserTypes[],
        excludeUsersFromBranches: string[] = [],
        idsIn?: string[]
    ): Promise<AxiosResponse<ISearchVendorsResponse>> => {
        return api.get(BASE_URL + `company/members/${companyId}`, {
            params: {
                textSearch: searchString,
                type,
                excludeUsersFromBranches,
                itemsPerPage: paging.itemsPerPage,
                currentPage: paging.currentPage,
                idsIn
            }
        });
    },
    updateTimelineTemplate: (
        timelineEntities: IPropertyTimelineEntity[],
        companyId: string
    ): Promise<AxiosResponse<any>> => {
        return api.patch(`${BASE_URL}company/${companyId}/timeline`, {
            timeline: timelineEntities
        });
    },
    createCompanyAdmin: (
        user: IUser,
        companyId: string
    ): Promise<AxiosResponse<IAddCompanyAdminResponse>> => {
        return api.post(`${BASE_URL}company/companyadmin/${companyId}`, user);
    },
    editCompanyAdmin: (admin: IUser, companyId: string): Promise<any> => {
        return api.patch(
            BASE_URL + `company/companyadmin/${companyId}/${admin._id}`,
            admin
        );
    },
    deleteCompanyMember: (member: IUser, companyId: string): Promise<any> => {
        return api.delete(
            `${BASE_URL}company/member/${companyId}/${member._id}`
        );
    },
    deletePhoto: (companyId: string): Promise<AxiosResponse<ICompany>> => {
        return api.delete(`${BASE_URL}company/photo/${companyId}`);
    },
    getCompanyProperties: (
        companyId: string,
        paging: IPagingParams = { itemsPerPage: 10, currentPage: 0 },
        textSearch?: string,
        filters?: IPropertyGetListFilters,
        branchIds?: string[],
        sortOption?: PropertySortOptionType,
        solicitorCompanyId?: string,
        postcode?: string,
        line1?: string
    ): Promise<AxiosResponse<IGetPropertiesResponse>> => {
        return api.get(`${BASE_URL}company/properties/${companyId}`, {
            params: {
                ...paging,
                textSearch,
                ...filters,
                branchIds,
                sort: sortOption,
                solicitorCompanyId,
                postcode,
                line1
            }
        });
    },
    getCompanyPropertiesCsv: (
        companyId: string,
        paging: IPagingParams = { itemsPerPage: 10, currentPage: 0 },
        textSearch?: string,
        filters?: IPropertyGetListFilters,
        branchIds?: string[],
        sortOption?: PropertySortOptionType,
        solicitorCompanyId?: string,
        postcode?: string,
        line1?: string
    ): Promise<AxiosResponse<IGetPropertiesResponse>> => {
        return api.get(`${BASE_URL}company/properties/csv/${companyId}`, {
            params: {
                ...paging,
                textSearch,
                ...filters,
                branchIds,
                sort: sortOption,
                solicitorCompanyId,
                postcode,
                line1
            }
        });
    }
};
