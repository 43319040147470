import React, { Component } from "react";
import { connect } from "react-redux";
import { IPropertyAskingPrice } from "../../../../../../api/_types";
import { setPropertyAskingPriceToEdit } from "../../../../../../redux/acreators/property";
import {
    resetUpdatePropertyAskingPriceStatus,
    updateMIAskingPrice
} from "../../../../../../redux/api/property";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import BackButton from "../../../../../_sharedComponents/BackButton";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import CreateAskingPriceForm from "../../components/CreateAskingPriceForm";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";

interface IProps {
    askingPrice?: IPropertyAskingPrice;
    updateMIAskingPriceStatus: AsyncActionState;
    updateMIAskingPrice: (askingPrice: IPropertyAskingPrice) => void;
    resetUpdatePropertyAskingPriceStatus: () => void;
    setPropertyAskingPriceToEdit: (askingPrice: IPropertyAskingPrice) => void;
}
interface IState {
    askingPrice: IPropertyAskingPrice;
}

class EditAskingPriceFormContainer extends Component<IProps, IState> {
    generateStateAskingPrice = () => {
        return Object.assign({}, this.props.askingPrice);
    };

    state: IState = {
        askingPrice: this.generateStateAskingPrice()
    };

    componentWillUnmount() {
        this.props.resetUpdatePropertyAskingPriceStatus();
    }

    componentDidUpdate(prevProps: IProps) {
        // Reset form state when a viewing has been edited
        if (
            prevProps.updateMIAskingPriceStatus !== AsyncActionState.Success &&
            this.props.updateMIAskingPriceStatus === AsyncActionState.Success
        ) {
            this.setState({
                askingPrice: this.generateStateAskingPrice()
            });
        }
    }

    handleGoBack = () => {
        this.props.resetUpdatePropertyAskingPriceStatus();
        this.props.setPropertyAskingPriceToEdit(undefined as any);
    };

    handleSave = () => {
        this.props.updateMIAskingPrice(this.state.askingPrice);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "askingPrice"
        );
    };

    render() {
        const { askingPrice } = this.state;
        const {
            updateMIAskingPriceStatus,
            resetUpdatePropertyAskingPriceStatus
        } = this.props;
        if (this.props.askingPrice === undefined) {
            return null;
        }

        return (
            <LoadingContainer
                loading={updateMIAskingPriceStatus === AsyncActionState.Pending}
            >
                <BackButton goBack={this.handleGoBack} />
                <hr />

                <CreateAskingPriceForm
                    askingPrice={askingPrice}
                    save={this.handleSave}
                    onChangeInputField={this.onChangeInputField}
                    saveStatus={updateMIAskingPriceStatus}
                    resetAsyncStatus={resetUpdatePropertyAskingPriceStatus}
                />
            </LoadingContainer>
        );
    }
}

const mapStateToProps = ({ property }: IStore) => ({
    askingPrice: property.askingPriceToEdit,
    updateMIAskingPriceStatus: property.updateMIAskingPriceStatus
});

export default connect(
    mapStateToProps,
    {
        updateMIAskingPrice,
        resetUpdatePropertyAskingPriceStatus,
        setPropertyAskingPriceToEdit
    }
)(EditAskingPriceFormContainer);
