import { api, BASE_URL } from "../index";
import { AxiosResponse } from "axios";
import {
    ISearchVendorsResponse,
    IActivateAccountResponse,
    IUser,
    IPagingParams,
    ISearchGYAAdminsResponse
} from "../_types";

const buildSubmitUserPayload = (user: IUser, file?: File): FormData => {
    const data = new FormData();

    if (user._id !== undefined || user._id !== null) {
        data.append("_id", user._id as string);
    }

    data.append(`firstName`, user.firstName);
    data.append(`lastName`, user.lastName);
    data.append(`email`, user.email);
    data.append(`tel`, user.tel);
    data.append(`type`, user.type);

    if (user.password !== undefined) {
        data.append(`password`, user.password);
    }

    if (file !== undefined) {
        data.append("file", file);
    }

    return data;
};

export default {
    createUser: (user: IUser, file?: File): Promise<any> => {
        const data = buildSubmitUserPayload(user, file);

        return api({
            url: BASE_URL + `user`,
            method: "POST",
            data,
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    updateMe: (user: IUser, file?: File): Promise<any> => {
        const data = buildSubmitUserPayload(user, file);

        return api({
            url: BASE_URL + `user/me`,
            method: "PUT",
            data,
            headers: { "Content-Type": "multipart/form-data" }
        });
    },
    logout: (): Promise<AxiosResponse<{}>> => {
        return api.post(BASE_URL + "user/logout");
    },
    me: (): Promise<AxiosResponse<{}>> => {
        return api.get(BASE_URL + "user/me");
    },
    searchVendors: (
        searchString: string
    ): Promise<AxiosResponse<ISearchVendorsResponse>> => {
        return api.get(BASE_URL + "user/search/vendor", {
            params: {
                email: searchString
            }
        });
    },
    searchGYAAdmins: (
        searchString: string,
        paging: IPagingParams = { itemsPerPage: 10, currentPage: 0 }
    ): Promise<AxiosResponse<ISearchGYAAdminsResponse>> => {
        return api.get(BASE_URL + "user/gyaadmin", {
            params: {
                textSearch: searchString,
                itemsPerPage: paging.itemsPerPage,
                currentPage: paging.currentPage
            }
        });
    },
    activateAccount: (
        password: string
    ): Promise<AxiosResponse<IActivateAccountResponse>> => {
        return api.patch(BASE_URL + "user/activate", { password });
    },
    deleteAccount: (): Promise<AxiosResponse<IActivateAccountResponse>> => {
        return api.delete(BASE_URL + "user");
    },
    deletePhoto: (): Promise<AxiosResponse<IUser>> => {
        return api.delete(BASE_URL + "user/photo");
    },
    resendEmailVerification: (token: string) => {
        return api.post(BASE_URL + "auth/verifyemail/resend", { token });
    },
    getAvatar: async (userId: string): Promise<any> => {
        return api.get(`${BASE_URL}user/avatar/${userId}`, {
            responseType: "arraybuffer"
        });
    }
};
