import { AsyncActionState } from "../utils/asyncAction";
import { IBranch, IUser } from "../../api/_types";
import {
    IGetBranchesResponse,
    ISearchBranchMembersResponse
} from "../../api/modules/branch";

export enum BranchTypes {
    SET_BRANCHES = "SET_BRANCHES",
    SET_VIEWING_BRANCH = "SET_VIEWING_BRANCH",

    CREATE_BRANCH_STATUS = "CREATE_BRANCH_STATUS",

    DELETE_BRANCH_STATUS = "DELETE_BRANCH_STATUS",

    GET_BRANCH_STATUS = "GET_BRANCH_STATUS",

    GET_BRANCHES_STATUS = "GET_BRANCHES_STATUS",

    SEARCH_BRANCH_ADMIN_STATUS = "SEARCH_BRANCH_ADMIN_STATUS",
    SET_SEARCH_BRANCH_ADMIN_RESULT = "SET_SEARCH_BRANCH_ADMIN_RESULT",
    SET_CREATE_BRANCH_ADMINISTRATOR_REF = "SET_CREATE_BRANCH_ADMINISTRATOR_REF",
    ADD_BRANCH_MEMBER_STATUS = "ADD_BRANCH_MEMBER_STATUS",
    REMOVE_BRANCH_MEMBER_STATUS = "REMOVE_BRANCH_MEMBER_STATUS",
    SEARCH_BRANCH_MEMBER_STATUS = "SEARCH_BRANCH_MEMBER_STATUS",
    SEARCH_BRANCH_MEMBER_RESULT = "SEARCH_BRANCH_MEMBER_RESULT",
    UPDATE_BRANCH_TIMELINE_STATUS = "UPDATE_BRANCH_TIMELINE_STATUS",
    EDIT_BRANCH_MEMBER_STATUS = "EDIT_BRANCH_MEMBER_STATUS",
    SET_EDIT_BRANCH_MEMBER = "SET_EDIT_BRANCH_MEMBER",
    DELETE_BRANCH_PHOTO_STATUS = "DELETE_BRANCH_PHOTO_STATUS"
}

export interface SetBranches extends ReduxAction {
    type: BranchTypes.SET_BRANCHES;
    payload: IGetBranchesResponse;
}

export interface SetViewingBranch extends ReduxAction {
    type: BranchTypes.SET_VIEWING_BRANCH;
    payload: IBranch | undefined;
}

export interface CreateBranchStatus extends ReduxAction {
    type: BranchTypes.CREATE_BRANCH_STATUS;
    payload: AsyncActionState;
}

export interface DeleteBranchStatus extends ReduxAction {
    type: BranchTypes.DELETE_BRANCH_STATUS;
    payload: AsyncActionState;
}

export interface GetBranchesStatus extends ReduxAction {
    type: BranchTypes.GET_BRANCHES_STATUS;
    payload: AsyncActionState;
}

export interface GetBranchStatus extends ReduxAction {
    type: BranchTypes.GET_BRANCH_STATUS;
    payload: AsyncActionState;
}

export interface SearchBranchAdminStatus extends ReduxAction {
    type: BranchTypes.SEARCH_BRANCH_ADMIN_STATUS;
    payload: AsyncActionState;
}

export interface SetSearchBranchAdminResult extends ReduxAction {
    type: BranchTypes.SET_SEARCH_BRANCH_ADMIN_RESULT;
    payload: IUser[];
}

export interface SetCreateBranchAdministratorRef {
    type: BranchTypes.SET_CREATE_BRANCH_ADMINISTRATOR_REF;
    payload: IUser | undefined;
}

export interface AddBranchMemberStatus extends ReduxAction {
    type: BranchTypes.ADD_BRANCH_MEMBER_STATUS;
    payload: AsyncActionState;
}

export interface RemoveBranchMemberStatus extends ReduxAction {
    type: BranchTypes.REMOVE_BRANCH_MEMBER_STATUS;
    payload: AsyncActionState;
}

export interface SetSearchBranchMemberResult extends ReduxAction {
    type: BranchTypes.SEARCH_BRANCH_MEMBER_RESULT;
    payload: ISearchBranchMembersResponse;
}

export interface SetSearchBranchMemberStatus extends ReduxAction {
    type: BranchTypes.SEARCH_BRANCH_MEMBER_STATUS;
    payload: AsyncActionState;
}

export interface UpdateBranchTimelineStatus extends ReduxAction {
    type: BranchTypes.UPDATE_BRANCH_TIMELINE_STATUS;
    payload: AsyncActionState;
}

export interface EditBranchMemberStatus extends ReduxAction {
    type: BranchTypes.EDIT_BRANCH_MEMBER_STATUS;
    payload: AsyncActionState;
}

export interface SetEditBranchMember extends ReduxAction {
    type: BranchTypes.SET_EDIT_BRANCH_MEMBER;
    payload: IUser;
}

export interface DeleteBranchPhotoStatus extends ReduxAction {
    type: BranchTypes.DELETE_BRANCH_PHOTO_STATUS;
    payload: AsyncActionState;
}
