import React, { Component } from "react";
import ContactsForm from "../../components/ContactsForm";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import {
    IProperty,
    IPropertyContacts,
    IUser,
    IPagingParams,
    IPagingResponse,
    IBuyer
} from "../../../../../../api/_types";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import {
    updatePropertyContacts,
    resetUpdatePropertyContactsStatus
} from "../../../../../../redux/api/property";
import { searchMembers } from "../../../../../../redux/api/branch";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";

interface IProps {
    property?: IProperty;
    updatePropertyContactsStatus: AsyncActionState;
    searchBranchMemberStatus: AsyncActionState;
    branchMembers: IUser[];
    branchMembersPaging: IPagingResponse;
    searchMembers: (searchString: string, paging: IPagingParams) => void;
    updatePropertyContacts: (contacts: IPropertyContacts) => void;
    resetUpdatePropertyContactsStatus: () => void;
}
interface IState {
    contacts: IPropertyContacts;
    dirty: boolean;
}

function defaultContact() {
    return {
        contact: undefined,
        poc: undefined
    };
}

class ContactsFormContainer extends Component<IProps, IState> {
    generateStateContacts = () => {
        const { property } = this.props;
        if (property === undefined || property.contacts === undefined) {
            return {
                bStaff: undefined,
                solicitor: defaultContact(),
                other: "",
                buyerDetails: {
                    mBroker: defaultContact(),
                    buyer: defaultContact(),
                    solicitor: defaultContact()
                }
            } as IPropertyContacts;
        }
        return Object.assign({}, property.contacts);
    };

    state: IState = {
        contacts: this.generateStateContacts(),
        dirty: false
    };

    componentDidMount() {
        const {
            searchMembers,
            branchMembersPaging,
            resetUpdatePropertyContactsStatus
        } = this.props;
        searchMembers("", branchMembersPaging);
        resetUpdatePropertyContactsStatus();
    }

    componentDidUpdate(prevProps: IProps) {
        if (
            prevProps.updatePropertyContactsStatus !==
                AsyncActionState.Success &&
            this.props.updatePropertyContactsStatus === AsyncActionState.Success
        ) {
            this.setState({
                contacts: this.generateStateContacts(),
                dirty: false
            });
        }
    }

    selectBranchStaffUser = (user: IUser) => {
        const contacts = Object.assign({}, this.state.contacts);

        contacts.bStaff = user;

        this.setState({
            contacts,
            dirty: true
        });
    };

    handlePageBranchMembers = (desiredPage: number) => {
        const { branchMembersPaging, searchMembers } = this.props;
        searchMembers("", {
            currentPage: desiredPage,
            itemsPerPage: branchMembersPaging.itemsPerPage
        });
    };

    save = () => {
        this.props.updatePropertyContacts(this.state.contacts);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "contacts"
        );
    };

    updateContacts = (contacts: IPropertyContacts) =>
        this.setState({ contacts, dirty: true });

    render() {
        const {
            property,
            branchMembers,
            branchMembersPaging,
            updatePropertyContactsStatus,
            resetUpdatePropertyContactsStatus
        } = this.props;
        const { contacts, dirty } = this.state;
        if (property === undefined || contacts === undefined) {
            return null;
        }
        return (
            <>
                <NavigationPrompt when={dirty === true} />
                <ContactsForm
                    contacts={contacts}
                    save={this.save}
                    onChangeInputField={this.onChangeInputField}
                    onSelectBranchStaffContact={this.selectBranchStaffUser}
                    branchMembers={branchMembers}
                    branchMembersPaging={branchMembersPaging}
                    pageBranchMembers={this.handlePageBranchMembers}
                    savePropertyContactsStatus={updatePropertyContactsStatus}
                    resetSavePropertyContactsStatus={
                        resetUpdatePropertyContactsStatus
                    }
                    onChangeContact={this.updateContacts}
                    buyer={property.buyer || ({} as IBuyer)}
                    vendor={property.owner as IUser}
                />
            </>
        );
    }
}

const mapStateToProps = ({ property, branch }: IStore) => ({
    property: property.viewingProperty,
    updatePropertyContactsStatus: property.updatePropertyContactsStatus,
    branchMembers: branch.searchBranchMembersResults.users,
    branchMembersPaging: branch.searchBranchMembersResults.paging,
    searchBranchMemberStatus: branch.searchBranchMembersStatus
});

export default connect(
    mapStateToProps,
    { updatePropertyContacts, searchMembers, resetUpdatePropertyContactsStatus }
)(ContactsFormContainer);
