import React from "react";
import { Form } from "react-bootstrap";
import { ICompanyContactPOC } from "../../../../../../api/_types";

interface IProps {
    poc: ICompanyContactPOC;
    onChangeInputField: (key: string) => (event: React.FormEvent<any>) => void;
}

export default function CreateContactPOCForm(props: IProps) {
    const { poc, onChangeInputField } = props;

    return (
        <>
            <Form.Group id="poc.name">
                <Form.Label>Full name</Form.Label>
                <Form.Control
                    type="name"
                    required={true}
                    onChange={onChangeInputField("name")}
                    value={poc.name}
                />
            </Form.Group>
            <Form.Group id="poc.email">
                <Form.Label>Primary email address</Form.Label>
                <Form.Control
                    type="email"
                    required={true}
                    onChange={onChangeInputField("email")}
                    value={poc.email}
                />
            </Form.Group>
            <Form.Group id="poc.tel">
                <Form.Label>Primary telephone</Form.Label>
                <Form.Control
                    type="tel"
                    onChange={onChangeInputField("tel")}
                    value={poc.tel}
                />
            </Form.Group>
            <Form.Group id="poc.note">
                <Form.Label>Note</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={onChangeInputField("note")}
                    value={poc.note}
                />
            </Form.Group>
        </>
    );
}
