import React, { Component } from "react";
import { IStore } from "../redux/store";
import { connect } from "react-redux";
import { UserTypes } from "../redux/_types/user/types";
import { IUser } from "../api/_types";

import { default as ClientsApp } from "./apps/clients";
import { default as AdminApp } from "./apps/admin";
import { default as AccountActivation } from "./apps/activateAccount";
interface IProps {
    user?: IUser;
}
interface IState {}

class NavigationRouter extends Component<IProps, IState> {
    renderApp = () => {
        const { user } = this.props;

        if (user === undefined) {
            return null;
        }

        if (user.activated === false) {
            return <AccountActivation />;
        }

        switch (user.type) {
            case UserTypes.COMPANY_ADMIN:
            case UserTypes.BRANCH_ADMIN:
            case UserTypes.BRANCH_STAFF: {
                return <ClientsApp />;
            }
            case UserTypes.GYA_ADMIN: {
                return <AdminApp />;
            }
        }
    };
    render() {
        return this.renderApp();
    }
}

const mapStateToProps = ({ user }: IStore) => ({
    user: user.me
});

export default connect(
    mapStateToProps,
    {}
)(NavigationRouter);
