import {
    IPropertyTimelineEntity,
    IPropertyTimelineEntityTemplate
} from "../../../../../../../api/_types";

export interface IAddSurveyState {
    survey?: IPropertyTimelineEntity | IPropertyTimelineEntityTemplate;
}

export const initialState: IAddSurveyState = {
    survey: undefined
};

export interface IAddSurveyAction {
    type: "updateDate" | "updateContact" | "updateSurvey" | "updateRef" | "updateReports" |"reset";
    payload?: any;
}

export function addSurveyReducer(
    state: IAddSurveyState = initialState,
    action: IAddSurveyAction
): IAddSurveyState {

    switch (action.type) {
       
        case "updateSurvey": {
            return { ...state, survey: action.payload };
        }
        case "updateContact": {
            if (state.survey === undefined) {
                return state;
            }
            const survey = state.survey;
            const surveyData = survey.surveyData;
            surveyData.contactPOC = action.payload;
            return { ...state, survey: { ...survey, surveyData } };
        }
        case "updateReports": {
            if (state.survey === undefined) {
                return state;
            }
            const survey = state.survey;
            const surveyData = survey.surveyData;
            surveyData.awaitingReports = action.payload === "1" ? true : false;
            return { ...state, survey: { ...survey, surveyData } };
        }
        case "updateDate": {
            if (state.survey === undefined) {
                return state;
            }
            const survey = state.survey;
            const surveyData = survey.surveyData;
            surveyData.date = action.payload;
            return { ...state, survey: { ...survey, surveyData } };
        }
        case "updateRef": {
            if (state.survey === undefined) {
                return state;
            }
            const survey = state.survey;
            const surveyData = survey.surveyData;
            surveyData.reference = action.payload;
            return { ...state, survey: { ...survey, surveyData } };
        }
        case "reset": {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
