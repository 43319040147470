import React, { Component } from "react";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import CreateGYAAdminForm from "../../containers/CreateGYAAdminForm";
import ManageAdminTabs from "../../components/ManageAdminTabs";
import { Row, Col } from "react-bootstrap";
import TabBody from "../../../../../_sharedComponents/TabBody";

interface IProps {}
interface IState {}

export default class AddGYAAdminScreen extends Component<IProps, IState> {
    render() {
        return (
            <PageWrap>
                <EntityTitle entityName="Manage administrators" />
                <ManageAdminTabs />

                <TabBody>
                    <div className="mt-3">
                        <Row>
                            <Col md="6">
                                <CreateGYAAdminForm />
                            </Col>
                        </Row>
                    </div>
                </TabBody>
            </PageWrap>
        );
    }
}
