import { Form } from "react-bootstrap";
import React, { FC, FormEvent } from "react";

interface IProps {
    value: string;
    onChangeInputField: (stateField: string) => (event: FormEvent<any>) => void;
}

export const testID = "additional-contacts-input";

const OtherContacts:FC<IProps> = ({value, onChangeInputField}) => {
    return (
        <Form.Control
            data-testid="additional-contacts-input"
            as="textarea"
            rows="4"
            onChange={onChangeInputField("other")}
            value={value}
        />
    );
}

export default OtherContacts;
