import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export default () => (
    <FontAwesomeIcon
        style={{
            textAlign: "center",
            marginRight: 5
        }}
        icon={faExclamationTriangle}
        color="#F24236"
    />
);
