import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { default as propertyApi } from "../../../../../../api/modules/property";
import { IPropertyDocument } from "../../../../../../api/_types";
import { downloadFileToClient } from "../../../../../../lib/utils/files";
import usePrevious from "../../../../../../lib/utils/hooks/usePrevious";
import { setCreateDocumentStatus, setEmailDocumentStatus } from "../../../../../../redux/acreators/property/documents";
import { createDocument, deleteDocument, emailDocument, updateDocument } from "../../../../../../redux/api/property/documents";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import ModalTextInput from "../../../../../_sharedComponents/ModalTextInput";
import DocumentEmailForm from "../../components/DocumentEmailForm";
import { IDocumentEmailState } from "../../components/DocumentEmailForm/reducer";
import DocumentListTable from "../../components/DocumentListTable";
import DocumentUpload from "../../components/DocumentUpload";

const mapStateToProps = ({documents, property}: IStore) => ({
    documents: property.viewingProperty?.documents || [],
    companyId: property.viewingProperty?.company as string,
    branchId: property.viewingProperty?.branch as string,
    propertyId: property.viewingProperty?._id as string,

    uploadStatus: documents.createDocumentStatus,
    editStatus: documents.updateDocumentStatus,
    deleteStatus: documents.deleteDocumentStatus,
    emailStatus: documents.emailDocumentStatus
});

const actions = {
    createDocument, 
    deleteDocument, 
    updateDocument, 
    emailDocument, 
    resetUploadStatus: () => (dispatch: Dispatch) => {
        dispatch(setCreateDocumentStatus(AsyncActionState.Reset));
    },
    resetEmailStatus: () => (dispatch: Dispatch) => {
        dispatch(setEmailDocumentStatus(AsyncActionState.Reset));
    }
};

const connector = connect(mapStateToProps, actions);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {}

const DocumentList: FC<IProps> = ({
    branchId, 
    companyId, 
    documents, 
    editStatus, 
    propertyId, 
    uploadStatus, 
    deleteStatus, 
    emailStatus,
    createDocument, 
    emailDocument, 
    updateDocument, 
    deleteDocument, 
    resetEmailStatus,
    resetUploadStatus
}) => {

    const [docToDelete, setDocToDelete] = useState<IPropertyDocument|undefined>();
    const [docToEdit, setDocToEdit] = useState<IPropertyDocument|undefined>();
    const [downloadInProgress, setDownloadInProgress] = useState(false);
    const [docToEmail, setDocToEmail] = useState<IPropertyDocument|undefined>(undefined);
    const prevEmailStatus = usePrevious(emailStatus);
    const closeDocEdit = () => setDocToEdit(undefined);
    const closeDocDelete = () => setDocToDelete(undefined);
    const deleteDoc = () => deleteDocument(docToDelete?._id!);
    const updateDoc = (note: string) => updateDocument(docToEdit?._id!, note);
    const emailDoc = (item: IPropertyDocument) => setDocToEmail(item);
    const sendEmail = (emailParams: IDocumentEmailState) => {
        emailDocument(docToEmail?._id!, emailParams);
        setDocToEmail(undefined);
    };
    const downloadDoc = async (item: IPropertyDocument) => {

        setDownloadInProgress(true);

        try {
            const file = await propertyApi.downloadDocument(companyId, branchId, propertyId, item._id!);

            downloadFileToClient(file.data, item.file.fileName!);
        } catch (e) {
            console.log("Error dowloading file.", e);
            alert("There was an error downloading your file.");
        } finally {
            setDownloadInProgress(false);
        }
    };

    useEffect(() => {
        resetUploadStatus();
        resetEmailStatus();
    }, [resetUploadStatus, resetEmailStatus])

    useEffect(() => {
        const finishedLoading = prevEmailStatus === AsyncActionState.Pending && emailStatus !== AsyncActionState.Pending;
        if (finishedLoading === true && emailStatus === AsyncActionState.Error) {
            alert("There was an error sending your email");
        }
    }, [prevEmailStatus, emailStatus])

    return (
        <>
            <DocumentUpload upload={createDocument}  uploadStatus={uploadStatus} resetUploadStatus={resetUploadStatus}/>
            {documents?.length > 0 && (
                <DocumentListTable
                    documents={documents}
                    downloadDoc={downloadDoc}
                    downloadInProgress={downloadInProgress}
                    updateDoc={setDocToEdit}
                    deleteDoc={setDocToDelete}
                    emailDoc={emailDoc}
                />
            )}
            <ModalTextInput
                title="Edit document note"
                show={docToEdit !== undefined}
                close={closeDocEdit}
                asyncStatus={editStatus}
                onSubmit={updateDoc}
                initialValue={docToEdit?.note}
            />
            <ActionPrompt
                show={docToDelete !== undefined}
                cancel={closeDocDelete}
                title="Are you sure?"
                bodyText="Once this file is deleted, it will be completely removed from Kriva, you will not be able to restore it."
                actions={[
                    {
                        title: "Confirm",
                        btnVariant: "danger",
                        asyncStatus: deleteStatus,
                        onClick: deleteDoc,
                        onSuccess: setDocToDelete.bind(undefined, undefined)
                    }
                ]}
            />
            {docToEmail !== undefined && (  
                <DocumentEmailForm
                    documentName={docToEmail?.file.fileName || ""}
                    show={docToEmail !== undefined}
                    send={sendEmail}
                    close={() => setDocToEmail(undefined)}
                    emailInProgress={emailStatus === AsyncActionState.Pending}
                />
            )}
        </>
    );
};

export default connector(DocumentList);
