import React, { FC, FormEvent } from "react";
import { Form } from "react-bootstrap";
import {
    IProperty,
    IPropertyChainItem,
    IUser
} from "../../../../../../../api/_types";

interface IProps {
    item: IPropertyChainItem;
    property: IProperty;
    firstInChain: boolean;
    secondPropertyIsKrivaManaged?: boolean;
    buyersName?: string;
    updateItem: (
        item: IPropertyChainItem,
        key: keyof IPropertyChainItem,
        value: any
    ) => void;
}

const ChainVendorsName: FC<IProps> = ({ item, property, firstInChain, buyersName, secondPropertyIsKrivaManaged, updateItem }) => {
    const hasPropertyRef = (item.propertyRef as IProperty)?._id !== undefined;
    let value = item.personName;
    let readonly = false;
    let plaintext = false;
    const onChange = (e: FormEvent<HTMLInputElement>) =>
        updateItem(item, "personName", e.currentTarget.value);
    
    if (secondPropertyIsKrivaManaged === true ) {
        value = buyersName;
        readonly = true;
        plaintext = true;
    }

    if (item.thisProperty === true) {
        value = `${(property.owner as IUser).fullName}`;
        readonly = true;
        plaintext = true;
    }

    if (hasPropertyRef) {
        const owner = (item.propertyRef as IProperty).owner as IUser;
        value = owner.fullName;
        readonly = true;
        plaintext = true;
    }   
        
    return (
        <Form.Group>
            <Form.Label>
                <strong>
                    {firstInChain ? "Buyers" : "Vendors"} name
                </strong>
            </Form.Label>
            <Form.Control
                type="text"
                plaintext={plaintext}
                readOnly={readonly}
                onChange={onChange}
                value={value}
            />
        </Form.Group>
    );
};

export default ChainVendorsName;
