import React, { Component } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Typeahead from "../../../../../_sharedComponents/Typeahead";
import { IUser } from "../../../../../../api/_types";

interface IProps {
    users: IUser[];
    searchPending: boolean;
    delay?: number;
    minLength?: number;
    search: (searchText: string) => void;
    onSelect: (user: IUser) => void;
}
interface IState {}

export default class BranchAdminTypeahead extends Component<IProps, IState> {
    handleOnInputChange = (input: string) => {
        this.props.search(input);
    };
    handleOnChange = (users: IUser[]) => {
        if (users && users.length) {
            this.props.onSelect(users[0]);
        }
    };
    render() {
        const { searchPending } = this.props;
        return (
            <Typeahead
                id="branch-member-typeahead"
                displayTitle="Search existing branch administrator"
                displayMessage="Does the branch administrator already have an account on the system? If so, input thier email."
                data={this.props.users}
                multiple={false}
                isLoading={searchPending}
                emptyLabel="No user found."
                inputPlaceholder="Enter an email address"
                labelKey="email"
                handleOnInputChange={this.handleOnInputChange}
                handleOnChange={this.handleOnChange}
                formatItemDisplay={(user: IUser) =>
                    `${user.firstName} ${user.lastName}`
                }
            />
        );
    }
}
