import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
    resetCreateCompanyFormState,
    saveCompany
} from "../../../../../../redux/api/company";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import CreateCompanyForm from "../../components/CreateCompanyForm";

const mapStateToProps = ({ company, user }: IStore) => ({
    user: user.me,
    saveCompanyStatus: company.saveCompanyStatus
});
const connector = connect(
    mapStateToProps,
    {
        saveCompany,
        resetCreateCompanyFormState
    }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {}

class CreateCompanyContainer extends Component<IProps> {
    render() {
        const {
            saveCompany,
            saveCompanyStatus,
            resetCreateCompanyFormState,
            user
        } = this.props;

        if (user === undefined) {
            return null;
        }

        return (
            <LoadingContainer
                loading={saveCompanyStatus === AsyncActionState.Pending}
            >
                <CreateCompanyForm
                    user={user}
                    createCompany={saveCompany}
                    saveCompanyPending={
                        saveCompanyStatus === AsyncActionState.Pending
                    }
                    saveCompanyError={
                        saveCompanyStatus === AsyncActionState.Error
                    }
                    saveCompanySuccess={
                        saveCompanyStatus === AsyncActionState.Success
                    }
                    resetCreateCompanyFormState={resetCreateCompanyFormState}
                    saveText={"Create company"}
                />
            </LoadingContainer>
        );
    }
}

export default connect(
    mapStateToProps,
    {
        saveCompany,
        resetCreateCompanyFormState
    }
)(CreateCompanyContainer);
