import React from "react";

interface IProps {
    color: string;
    label: string;
}

export default function TopBlock(props: IProps) {
    const { color, label } = props;
    return (
        <div
            className="d-flex align-items-center justify-content-center"
            style={{
                height: 34,
                backgroundColor: color
            }}
        >
            <p
                style={{
                    marginBottom: 0,
                    lineHeight: 2,
                    color: "white"
                }}
            >
                {label}
            </p>
        </div>
    );
}
