export default {
    userPanelContent: {
        display: "inline-block",
        verticalAlign: "top",
        lineHeight: "5px",
        color: "#00A9CE"
    },
    userPanelPhoto: {
        marginRight: "10px",
        marginBottom: "10px",
        width: 80,
        height: 80
    },
    userPanelText: {
        marginTop: "2px"
    },
    small: {
        fontSize: "0.7em"
    }
};
