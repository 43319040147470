import React, { FC, useState } from "react";
import { IAdditionalContact } from "../../../../../../../../../api/_types";
import DataTable from "../../../../../../../../_sharedComponents/DataTable";
import { Button } from "react-bootstrap";
import AddContactPrompt from "./AddContactPrompt";
import ActionPrompt from "../../../../../../../../_sharedComponents/ActionPrompt";

interface IProps {
    contacts: IAdditionalContact[];
    addContact: (obj: IAdditionalContact) => void;
    editContact: (obj: IAdditionalContact) => void;
    removeContact: (obj: IAdditionalContact) => void;
}

const AdditionalContacts:FC<IProps> = ({contacts, addContact, editContact, removeContact}) => {

    const [showAddForm, setShowAddForm] = useState(false);
    const [contactToEdit, setContactToEdit]  = useState<IAdditionalContact|undefined>();
    const [contactToDelete, setContactToDelete] = useState<IAdditionalContact|undefined>();

    const openAddForm = () => setShowAddForm(true);
    const closeAddForm = () => setShowAddForm(false);

    const openDeletePrompt = (contactToDelete: IAdditionalContact) => setContactToDelete(contactToDelete);
    const closeDeletePrompt = () => setContactToDelete(undefined);
    const confirmDelete = () => {
        removeContact(contactToDelete!);
        closeDeletePrompt();
    };

    const saveEditContact = (contact: IAdditionalContact) => {
        setContactToEdit(contact);
        openAddForm()
    };

    const saveContact = (contact: IAdditionalContact) => {
        let saveFunc = contactToEdit !== undefined ? editContact : addContact;
        saveFunc(contact);
        setContactToEdit(undefined);
        setShowAddForm(false);
    }

    return (
        <>
            {showAddForm === true && (
                <AddContactPrompt
                    show={true}
                    editingContact={contactToEdit}
                    cancel={closeAddForm}
                    save={saveContact}
                />
            )}

            <ActionPrompt
                testid="delete-prompt"
                show={contactToDelete !== undefined}
                title={"Remove this contact?"}
                bodyText={"You will need to press the save button at the top of the screen to finalise your changes."}
                actions={[
                    {
                        title: "Confirm",
                        onClick: confirmDelete
                    }
                ]}
                cancel={closeDeletePrompt}
            />
            <Button
                className="float-right mb-2"
                onClick={openAddForm}
                variant="primary"
                size={"sm"}
            >
                New contact
            </Button>
            <DataTable
                data={contacts}
                fields={[
                    {field: "firstName", displayName: "First name"},
                    {field: "lastName", displayName: "Last name"},
                    {field: "email", displayName: "Email"},
                    {field: "tel", displayName: "Phone"}
                ]}
                actions={[
                    {
                        action: (item: IAdditionalContact) => saveEditContact(item),
                        btnTitle: "Edit",
                        btnVariant: "primary"
                    },
                    {
                        action: (item: IAdditionalContact) => openDeletePrompt(item),
                        btnTitle: "Delete",
                        btnVariant: "danger",
                    }
                ]}
            />
        </>
    );
}

export default AdditionalContacts;
