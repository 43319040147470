import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { IPropertyChainItem } from "../../../../../../../api/_types";

interface IProps {
    item: IPropertyChainItem;
    updateItem: (
        item: IPropertyChainItem,
        key: keyof IPropertyChainItem,
        value: any
    ) => void;
}

const ChainNotes: FC<IProps> = ({ item, updateItem }) => {
    return (
        <Form.Group>
            <h5>General notes</h5>
            <Form.Control
                as="textarea"
                rows={4}
                onChange={(e) =>
                    updateItem(item, "note", e.currentTarget.value)
                }
                value={item.note}
            />
        </Form.Group>
    );
};

export default ChainNotes;
