import moment, { Moment } from "moment";
import React, { Component } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { IPropertyManagementInformation } from "../../../../../../api/_types";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import { ChainStatus } from "../../../../../../redux/_types/property/types";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import { AgencyFeeType } from "../../../../../../api/modules/property";
import DatePicker from "../../../../../_sharedComponents/DatePicker";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";

interface IProps {
    miModel: IPropertyManagementInformation;
    saveMIStatus: AsyncActionState;
    save: (miObject: IPropertyManagementInformation) => void;
    resetSaveMIState: () => void;
}
interface IState {
    miModel: IPropertyManagementInformation;
    dirty: boolean;
}

interface ITrafficLightLabel {
    label: string;
    className: string;
}
export default class ManagementInformationForm extends Component<
    IProps,
    IState
> {
    state: IState = {
        miModel: Object.assign({}, this.props.miModel),
        dirty: false
    };

    componentDidUpdate(prevProps: IProps) {
        const { miModel } = this.props;

        if (prevProps.miModel !== miModel) {
            this.setState({
                miModel: Object.assign({}, this.props.miModel)
            });
        }

        if (
            prevProps.saveMIStatus !== AsyncActionState.Success &&
            this.props.saveMIStatus === AsyncActionState.Success
        ) {
            this.setState({
                dirty: false
            });
        }
    }

    handleSaveMI = (e: any) => {
        const { save } = this.props;
        const { miModel } = this.state;

        return save(miModel);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "miModel"
        );
    };

    getChainTrafficLightLabel = (): ITrafficLightLabel => {
        const { miModel } = this.props;
        const trafficLightLabel = {
            label: "",
            className: ""
        };

        switch (miModel.chain) {
            case ChainStatus.IncompleteChain: {
                trafficLightLabel.label = "Red";
                trafficLightLabel.className = "text-danger";
                break;
            }

            case ChainStatus.NoChain:
            case ChainStatus.CompletedChain: {
                trafficLightLabel.label = "Green";
                trafficLightLabel.className = "text-success";
                break;
            }
            case ChainStatus.Unknown: {
                trafficLightLabel.label = "Unknown";
                trafficLightLabel.className = "text-warning";
                break;
            }
        }

        return trafficLightLabel;
    };

    render() {
        const { miModel, dirty } = this.state;
        const { saveMIStatus, resetSaveMIState } = this.props;
        let chainTrafficLightLabel = this.getChainTrafficLightLabel();

        return (
            <>
                <NavigationPrompt when={dirty === true} />
                <FormContainer
                    actions={
                        <Button
                            size="lg"
                            type="button"
                            onClick={this.handleSaveMI}
                        >
                            Save
                        </Button>
                    }
                    showSuccess={saveMIStatus === AsyncActionState.Success}
                    showError={saveMIStatus === AsyncActionState.Error}
                    success={{
                        heading: "Management information saved",
                        message: "Your updates have been saved successfully."
                    }}
                    error={{
                        heading: "Error saving Management information",
                        message:
                            "An error occured and your updates were not saved."
                    }}
                    onFeedbackMessageClose={resetSaveMIState}
                >
                    <Form.Row>
                        <Col md={4}>
                            <Form.Group id="createPropertyForm.chain">
                                <Form.Label>Chain status</Form.Label>
                                <p className="text-muted">
                                    Current chain status:{" "}
                                    <strong
                                        className={
                                            chainTrafficLightLabel.className
                                        }
                                    >
                                        {chainTrafficLightLabel.label}
                                    </strong>
                                </p>

                                <Form.Control
                                    as="select"
                                    value={miModel.chain}
                                    onChange={this.onChangeInputField("chain")}
                                >
                                    <option value={ChainStatus.CompletedChain}>
                                        Chain complete
                                    </option>
                                    <option value={ChainStatus.IncompleteChain}>
                                        Chain incomplete
                                    </option>
                                    <option value={ChainStatus.Unknown}>
                                        Chain status unknown
                                    </option>
                                    <option value={ChainStatus.NoChain}>
                                        No chain
                                    </option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group id="createPropertyForm.name">
                                <Form.Label>Contract term </Form.Label>
                                <p className="text-muted">
                                    The term of the contract in weeks
                                </p>
                                <Form.Control
                                    type="number"
                                    min={0}
                                    onChange={this.onChangeInputField("cTerm")}
                                    value={miModel.cTerm.toString()}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group id="createPropertyForm.cSigned">
                                <Form.Label>Contract signed</Form.Label>
                                <p className="text-muted">
                                    The date the contract was signed.
                                </p>

                                <DatePicker
                                    timeFormat={false}
                                    onChange={(e: string | Moment) => {
                                        let momentTime =
                                            typeof e === "string"
                                                ? moment(e)
                                                : e;
                                        this.onChangeInputField("cSigned")({
                                            currentTarget: {
                                                value: momentTime.toISOString()
                                            }
                                        } as any);
                                    }}
                                    defaultValue={
                                        miModel.cSigned &&
                                        moment(miModel.cSigned)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col md={4}>
                            <Form.Group id="createPropertyForm.listed">
                                <Form.Label>Date listed</Form.Label>
                                <p className="text-muted">
                                    The date the property was listed on the
                                    market
                                </p>
                                <DatePicker
                                    timeFormat={false}
                                    onChange={(e: string | Moment) => {
                                        let momentTime =
                                            typeof e === "string"
                                                ? moment(e)
                                                : e;
                                        this.onChangeInputField("listed")({
                                            currentTarget: {
                                                value: momentTime.toISOString()
                                            }
                                        } as any);
                                    }}
                                    defaultValue={moment(miModel.listed)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Row>
                                <Col md={6}>
                                    <Form.Group id="createPropertyForm.agencyFee">
                                        <Form.Label>Agency fee type</Form.Label>
                                        <p className="text-muted">
                                            Fixed fee, or percentage of sold
                                            price?
                                        </p>
                                        <Form.Control
                                            as="select"
                                            onChange={this.onChangeInputField(
                                                "agencyFeeType"
                                            )}
                                            value={miModel.agencyFeeType}
                                        >
                                            <option
                                                value={
                                                    AgencyFeeType.PercentageOfAgreedPrice
                                                }
                                            >
                                                Percentage of sold price
                                            </option>
                                            <option
                                                value={AgencyFeeType.FixedFee}
                                            >
                                                Fixed fee
                                            </option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group id="createPropertyForm.agencyFee">
                                        <Form.Label>Agency fee</Form.Label>
                                        <p className="text-muted">
                                            The agreed fee the agency will take
                                            from this sale
                                        </p>

                                        {miModel.agencyFeeType ===
                                            AgencyFeeType.FixedFee && (
                                            <Form.Control
                                                type="number"
                                                min={0}
                                                onChange={this.onChangeInputField(
                                                    "agencyFee"
                                                )}
                                                value={miModel.agencyFee.toString()}
                                            />
                                        )}
                                        {miModel.agencyFeeType ===
                                            AgencyFeeType.PercentageOfAgreedPrice && (
                                            <Form.Control
                                                type="number"
                                                min={0}
                                                max={100}
                                                onChange={this.onChangeInputField(
                                                    "agencyFeePercent"
                                                )}
                                                value={miModel.agencyFeePercent.toString()}
                                            />
                                        )}
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        </Col>
                    </Form.Row>
                </FormContainer>
            </>
        );
    }
}
