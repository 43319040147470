import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ICompany } from "../../../../../../api/_types";
import routes, { IRouteDefinition } from "../../../../../../navigation/routes";
import {
    getCompanies,
    getCompanyById,
    resetViewingCompany
} from "../../../../../../redux/api/company";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import DataTable from "../../../../../_sharedComponents/DataTable";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";

const mapStateToProps = ({ company }: IStore) => ({
    companies: company.companies.companies,
    companiesPaging: company.companies.paging,
    getCompaniesStatus: company.getCompaniesStatus
});

const connector = connect(
    mapStateToProps,
    { getCompanies, getCompanyById, resetViewingCompany }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps, RouteComponentProps {}

export class CompanyListContainer extends Component<IProps> {
    componentDidMount() {
        const { getCompanies, companiesPaging } = this.props;
        getCompanies({ currentPage: companiesPaging.currentPage });
    }

    navigateToPage = async (companyId: string, route: IRouteDefinition) => {
        this.props.resetViewingCompany();
        this.props.getCompanyById(companyId);
        this.props.history.push(`${route.url}`);
    };

    handlePageCompanies = (desiredPage: number) => {
        this.props.getCompanies({ currentPage: desiredPage });
    };

    render() {
        const { companies, companiesPaging, getCompaniesStatus } = this.props;
        return (
            <LoadingContainer
                loading={getCompaniesStatus === AsyncActionState.Pending}
            >
                <h2>Companies</h2>
                <DataTable
                    data={companies}
                    showPager={true}
                    pagingElement={companiesPaging}
                    onPagerPageClick={this.handlePageCompanies}
                    showLoadingSpinner={
                        getCompaniesStatus === AsyncActionState.Pending
                    }
                    fields={[
                        {
                            field: "name",
                            displayName: "Company"
                        },
                        {
                            field: "branches",
                            displayName: "Branches",
                            mutator: (item: ICompany) =>
                                `${item.branches.length}`
                        }
                    ]}
                    actions={[
                        {
                            btnTitle: "Manage",
                            btnVariant: "light",
                            action: async (dataItem) => {
                                this.navigateToPage(
                                    dataItem._id,
                                    routes.branchManage
                                );
                            }
                        },
                        {
                            btnTitle: "View Company",
                            btnVariant: "light",
                            action: (dataItem) => {
                                this.navigateToPage(
                                    dataItem._id,
                                    routes.companyEdit
                                );
                            }
                        }
                    ]}
                />
            </LoadingContainer>
        );
    }
}

export default connector(withRouter<any, any>(CompanyListContainer));
