import React, { Component } from "react";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import ManageCompanyUsers from "../../containers/ManageCompanyUsers";
import HorizontalTabs from "../../../../../_sharedComponents/HorizontalTabNav";
import CreateCompanyUserForm from "../../containers/CreateCompanyUserForm";
import EditCompanyTopNav from "../../components/EditCompanyTopNav";
import routes from "../../../../../../navigation/routes";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface IProps {}
interface IState {}

class ManageCompanyMembers extends Component<
    IProps & RouteComponentProps,
    IState
> {
    render() {
        return (
            <PageWrap>
                <EntityTitle entityName="Manage users" />
                <EditCompanyTopNav />

                <HorizontalTabs
                    id="gya-users"
                    defaultKey={this.props.match.url}
                    items={[
                        {
                            key: routes.companyUsers.url,
                            title: "Users",
                            content: ManageCompanyUsers
                        },
                        {
                            key: routes.companyCreateUser.url,
                            title: "Create administrator",
                            content: CreateCompanyUserForm
                        }
                    ]}
                />
            </PageWrap>
        );
    }
}

export default withRouter<any, any>(ManageCompanyMembers);
