import React, { Component } from "react";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import UserCard from "../../../user/components/UserCard";

import { searchNewBranchAdmin } from "../../../../../../redux/api/branch";
import {
    resetCreateBranchAdministratorRef,
    setCreateBranchAdministratorRef
} from "../../../../../../redux/acreators/branch";
import BranchMemberTypeahead from "../../components/BranchAdminTypeahead";
import { IUser } from "../../../../../../api/_types";

interface IProps {
    branchAdminSearchResults: IUser[];
    searchBranchAdminStatus: AsyncActionState;
    createBranchAdministratorRef?: string | IUser;
    searchNewBranchAdmin: (searchString: string) => void;
    setCreateBranchAdministratorRef: (user: IUser) => void;
    resetCreateBranchAdministratorRef: () => void;
}
interface IState {}

class BranchAdminTypeahead extends Component<IProps, IState> {
    componentWillMount() {
        this.props.resetCreateBranchAdministratorRef();
    }
    componentWillUnmount() {
        this.props.resetCreateBranchAdministratorRef();
    }
    handleFetchUsers = (searchString: string) => {
        this.props.searchNewBranchAdmin(searchString);
        this.props.resetCreateBranchAdministratorRef();
    };
    handleSelectUser = (user: IUser) => {
        this.props.setCreateBranchAdministratorRef(user);
    };

    render() {
        const {
            createBranchAdministratorRef,
            branchAdminSearchResults,
            searchBranchAdminStatus,
            resetCreateBranchAdministratorRef
        } = this.props;
        const showUserCard =
            createBranchAdministratorRef !== undefined &&
            typeof createBranchAdministratorRef !== "string";
        return (
            <>
                <BranchMemberTypeahead
                    users={branchAdminSearchResults}
                    search={this.handleFetchUsers}
                    searchPending={
                        searchBranchAdminStatus === AsyncActionState.Pending
                    }
                    onSelect={this.handleSelectUser}
                />
                {showUserCard && (
                    <UserCard
                        user={createBranchAdministratorRef as IUser}
                        readonly={createBranchAdministratorRef !== undefined}
                        clearUser={resetCreateBranchAdministratorRef}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = ({ user, branch }: IStore) => ({
    createBranchAdministratorRef: branch.createBranchAdministratorRef,
    branchAdminSearchResults: branch.branchAdminSearchResults,
    searchBranchAdminStatus: branch.searchBranchAdminStatus
});

export default connect(
    mapStateToProps,
    {
        setCreateBranchAdministratorRef,
        searchNewBranchAdmin,
        resetCreateBranchAdministratorRef
    }
)(BranchAdminTypeahead);
