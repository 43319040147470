import React, { Component } from "react";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import EditCompanyTopNav from "../../../company/components/EditCompanyTopNav";
import CompanyTimeline from "../../containers/CompanyTimeline";
import TabBody from "../../../../../_sharedComponents/TabBody";

interface IProps {}

export default class CompanyTimelineScreen extends Component<IProps> {
    render() {
        return (
            <PageWrap>
                <EntityTitle entityName={"Company Timeline"} />
                <EditCompanyTopNav />
                <TabBody>
                    <p>
                        This is your companies property sales timeline. 
                    </p>
                    <p>
                        You should make any changes to this before you create a branch.
                    </p>
                    <p>
                        Changes made here will only affect data created after
                        this point.
                    </p>
                    <CompanyTimeline />
                </TabBody>
            </PageWrap>
        );
    }
}
