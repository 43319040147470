import React, { Component } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { IUser } from "../../../../../../api/_types";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";
import UserAvatar from "../Avatar";

interface IProps {
    user: IUser;
    savePending: boolean;
    saveSuccess: boolean;
    saveError: boolean;
    deleteMeStatus?: AsyncActionState;
    resetUpdateMeState: () => void;
    save: (user: IUser, file?: File) => void;
    deleteAccount?: () => void;
    deletePhoto?: () => void;
}
interface IAdditionalUserFields {}

interface IStateUser extends IUser, IAdditionalUserFields {}

interface IState {
    user: IStateUser;
    file?: File;
    previewURL: string;
    dirty: boolean;
}

export default class UserProfile extends Component<IProps, IState> {
    generateInitialUser = (user?: IUser): IStateUser => {
        let obj = user || {
            firstName: "",
            lastName: "",
            email: "",
            tel: "",
            // @todo Remove GYA_ADMIN default
            type: UserTypes.BRANCH_STAFF
        };
        return {
            ...obj
            // Place any psuedo fields here, e.g. adding to an array
        };
    };

    state: IState = {
        user: this.generateInitialUser(this.props.user),
        file: undefined,
        previewURL: "",
        dirty: false
    };

    componentWillMount() {
        this.props.resetUpdateMeState();
    }

    handlePressFileInput = () => {
        let fileInput = document.getElementById("userFileInput");

        if (fileInput !== null) {
            fileInput.click();
        }
    };
    handleFileChange = (event: any) => {
        this.setState({
            file: (event.target as any).files[0],
            previewURL: window.URL.createObjectURL(event.target.files[0])
        });
    };
    handleFormSubmit = () => {
        this.props.save(this.state.user, this.state.file);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(this, this.state, stateField, "user");
    };

    render() {
        const { user, dirty } = this.state;
        const {
            saveError,
            savePending,
            saveSuccess,
            resetUpdateMeState,
            deleteAccount,
            deletePhoto
        } = this.props;
        return (
            <LoadingContainer loading={savePending}>
                <NavigationPrompt when={dirty === true} />
                <FormContainer
                    submit={(e) => {
                        e.preventDefault();
                        this.handleFormSubmit();
                    }}
                    showSuccess={saveSuccess}
                    showError={saveError}
                    success={{
                        heading: "User updated",
                        message: "Your changes were saved successfully."
                    }}
                    error={{
                        heading: "Error updating user record",
                        message:
                            "The edit to your user account was not performed due to an error with the submission to the API."
                    }}
                    actions={
                        <>
                            <Button size="lg" type="submit" className="mr-2">
                                Submit
                            </Button>
                            {deleteAccount !== undefined && (
                                <Button
                                    size="lg"
                                    type="button"
                                    variant="danger"
                                    onClick={deleteAccount}
                                >
                                    Delete my account
                                </Button>
                            )}
                        </>
                    }
                    useSecondaryActionBar={true}
                    onFeedbackMessageClose={resetUpdateMeState}
                >
                    <Form.Row>
                        <Col md={6}>
                            <Form.Group id="createPropertyForm.email">
                                <Form.Label>Email address</Form.Label>
                                <Form.Text className="text-muted mb-2">
                                    You use your email address to login to the
                                    application.
                                </Form.Text>
                                <Form.Control
                                    type="email"
                                    onChange={this.onChangeInputField("email")}
                                    value={user.email}
                                    required
                                />
                            </Form.Group>
                            <Form.Group id="createPropertyForm.firstName">
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={this.onChangeInputField(
                                        "firstName"
                                    )}
                                    value={user.firstName}
                                    required
                                />
                            </Form.Group>
                            <Form.Group id="createPropertyForm.lastName">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={user.lastName}
                                    onChange={this.onChangeInputField(
                                        "lastName"
                                    )}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="user-card-last-name">
                                <Form.Label>Telephone number</Form.Label>

                                <Form.Control
                                    type="text"
                                    value={user.tel}
                                    onChange={this.onChangeInputField("tel")}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col></Col>
                                <Col>
                                    {this.state.previewURL !== "" ? (
                                        <Image
                                            thumbnail
                                            style={{ maxHeight: "250px" }}
                                            src={this.state.previewURL}
                                        />
                                    ) : (
                                        <UserAvatar user={this.props.user} />
                                    )}
                                    <div className="mt-2">
                                        <Button
                                            className="mr-2"
                                            onClick={this.handlePressFileInput}
                                        >
                                            Select image
                                        </Button>
                                        {this.state.previewURL !== "" && (
                                            <Button
                                                variant="danger"
                                                onClick={() =>
                                                    this.setState({
                                                        file: undefined,
                                                        previewURL: ""
                                                    })
                                                }
                                            >
                                                Clear
                                            </Button>
                                        )}
                                        {user._id !== undefined &&
                                            user.avatar !== undefined &&
                                            this.state.previewURL === "" && (
                                                <Button
                                                    variant="danger"
                                                    onClick={deletePhoto}
                                                >
                                                    Delete image
                                                </Button>
                                            )}
                                    </div>

                                    <Form.Control
                                        id="userFileInput"
                                        hidden
                                        as="input"
                                        type="file"
                                        onChange={this.handleFileChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Form.Row>
                </FormContainer>
            </LoadingContainer>
        );
    }
}
