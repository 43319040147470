import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import {
    IAddress,
    ICompany,
    ICompanyContact
} from "../../../../../../api/_types";
import {
    getCompanyContacts,
    createCompanyContact
} from "../../../../../../redux/api/companyContacts";
import { IStore } from "../../../../../../redux/store";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import { resetCreateCompanyContactsStatus } from "../../../../../../redux/acreators/companyContacts";
import CreateContactForm from "../../components/CreateContactForm";

interface IProps {
    company?: ICompany;
    createCompanyContactStatus: AsyncActionState;
    createCompanyContact: (contact: ICompanyContact) => void;
    resetCreateCompanyContactsStatus: () => void;
}

interface IState {
    contact: {
        name: string;
        tel: string;
        email: string;
        address: IAddress;
    };
    dirty: boolean;
}

class CreateCompanyContact extends Component<IProps, IState> {
    generateInitialContact = () => ({
        name: "",
        tel: "",
        email: "",
        address: {
            line1: "",
            line2: "",
            postcode: "",
            town: "",
            country: "",
            county: ""
        }
    });

    state: IState = {
        contact: this.generateInitialContact(),
        dirty: false
    };

    componentDidUpdate(prevProps: IProps) {
        if (
            prevProps.createCompanyContactStatus === AsyncActionState.Pending &&
            this.props.createCompanyContactStatus === AsyncActionState.Success
        ) {
            this.setState({
                contact: this.generateInitialContact(),
                dirty: false
            });
        }
    }

    componentWillUnmount() {
        this.props.resetCreateCompanyContactsStatus();
    }

    save = (e: React.FormEvent) => {
        const { contact } = this.state;
        const { company, createCompanyContact } = this.props;

        e.preventDefault();

        if (company === undefined) {
            return;
        }

        createCompanyContact({
            name: contact.name,
            tel: contact.tel,
            email: contact.email,
            address: contact.address,
            pocs: [],
            company: company._id as string
        });
    };

    onChangeInputField = (key: string) => {
        return getStateFromOnChangeUpdate(this, this.state, key, "contact");
    };

    render() {
        const { contact, dirty } = this.state;
        const {
            createCompanyContactStatus,
            resetCreateCompanyContactsStatus
        } = this.props;
        return (
            <LoadingContainer
                loading={
                    createCompanyContactStatus === AsyncActionState.Pending
                }
            >
                <NavigationPrompt when={dirty === true} />

                <FormContainer
                    submit={this.save}
                    actions={[
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    ]}
                    onFeedbackMessageClose={resetCreateCompanyContactsStatus}
                    showError={
                        createCompanyContactStatus === AsyncActionState.Error
                    }
                    showSuccess={
                        createCompanyContactStatus === AsyncActionState.Success
                    }
                    success={{
                        heading: "Contact saved",
                        message: "The contact has been saved successfully."
                    }}
                    error={{
                        heading: "Something went wrong",
                        message:
                            "Something went wrong saving this contact, please try again."
                    }}
                >
                    <CreateContactForm
                        contact={contact as ICompanyContact}
                        onChangeInputField={this.onChangeInputField}
                    />
                </FormContainer>
            </LoadingContainer>
        );
    }
}

const mapStateToProps = ({ company, companyContacts }: IStore) => ({
    company: company.viewingCompany,
    contacts: companyContacts.contacts.contacts,
    paging: companyContacts.contacts.paging,
    createCompanyContactStatus: companyContacts.createCompanyContactStatus
});

export default connect(
    mapStateToProps,
    {
        getCompanyContacts,
        createCompanyContact,
        resetCreateCompanyContactsStatus
    }
)(CreateCompanyContact);
