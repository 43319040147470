import { ComponentState, Component, FormEvent } from "react";
import _ from "lodash";

export function getStateFromOnChangeUpdate(
    componentThis: Component,
    state: ComponentState,
    updateField: string,
    baseField?: string,
    strict: boolean = false
) {
    return (event: FormEvent<any>) => {
        let stateRef = state;
        let stateUpdateObj;
        if (baseField !== undefined) {
            stateRef = state[baseField];
        }
        const obj = Object.assign({}, stateRef);

        if (strict === true && _.has(obj, updateField) === false) {
            throw new Error(
                `State does not include field ${updateField} on state`
            );
        }

        _.set(obj, updateField, event.currentTarget.value);
        if (baseField !== undefined) {
            stateUpdateObj = {
                [baseField]: obj
            };
        } else {
            stateUpdateObj = obj;
        }

        stateUpdateObj.dirty = true;

        componentThis.setState(stateUpdateObj);
    };
}
