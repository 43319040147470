import { AxiosResponse } from "axios";
import moment from "moment";
import { IVisibleGroups } from "../../components/modules/core/company/containers/MIReport";
import { api, BASE_URL } from "../index";
import {
    ICreatePropertyNoteFields,
    IDeleteRestorePropertySaleResponse,
    IMIKeyStatsResponse,
    IMIReportResponse,
    IPagingParams,
    IPagingResponse,
    IProperty,
    IPropertyAskingPrice,
    IPropertyChain,
    IPropertyContacts,
    IPropertyGetListFilters,
    IPropertyManagementInformation,
    IPropertyNote,
    IPropertyOffer,
    IPropertyOfferPayload,
    IPropertyTimelineEntity,
    IPropertyViewing,
    IResetPropertySaleResponse,
    IRestorePropertySaleResponse,
    IWorkflowFilters,
    PropertySortOptionType
} from "../_types";

export interface IGetPropertiesResponse {
    paging: IPagingResponse;
    properties: IProperty[];
}

export enum ChainSurveyBooked {
    Yes = "Yes",
    No = "No",
    NA = "NA"
}

export enum ChainMortgageOffer {
    Yes = "Yes",
    No = "No",
    Cash = "Cash"
}

export enum TimelineKeys {
    TakeOn = 1,
    PropertyLaunched = 2,
    Viewings = 3,
    SaleAgreed = 4,
    NotHavingSurvey = 36,
    HomeBuyersSurvey = 5,
    MortgageValuations = 6,
    FullStructural = 7,
    StructualEngineersReport = 8,
    TimberAndDamp = 9,
    ElectricalSurvey = 10,
    RoofReport = 11,
    GasSafety = 12,
    HeatingReport = 13,
    AsbestosReport = 14,
    DrainSurvey = 15,
    WallTieSurvey = 16,
    SepticTankReport = 17,
    RadonSurvey = 18,
    PartWallSurvey = 19,
    TreeSurvey = 20,
    JapaneseKnotWeedSurvey = 21,
    ReportedToBuyer = 22,
    DocumentsAndDepositsReceived = 23,
    Exchanged = 24,
    Completed = 25,
    MortgageAppSubmitted = 26,
    MortgageValuationBooked = 27,
    MortgageValuationCompleted = 28,
    MortgageApproved = 29,
    MemoOfSaleSent = 30,
    DraftContractsIssued = 31,
    SearchesAppliedFor = 32,
    SearchesBack = 33,
    EnquiriesRaised = 34,
    EnquiriesAnswered = 35
}

export enum MIReportSortOption {
    ADDRESS_ASC = "ADDRESS_ASC",
    ADDRESS_DESC = "ADDRESS_DESC",
    OFFER_ACCEPTED_ASC = "OFFER_ACCEPTED_ASC",
    OFFER_ACCEPTED_DESC = "OFFER_ACCEPTED_DESC",
    FEE_PERCENT_ASC = "FEE_PERCENT_ASC",
    FEE_PERCENT_DESC = "FEE_PERCENT_DESC",
    AGREED_PRICE_ASC = "AGREED_PRICE_ASC",
    AGREED_PRICE_DESC = "AGREED_PRICE_DESC",
    SALE_PROGRESS = "SALE_PROGRESS",
    SALE_PROGRESS_ASC = "SALE_PROGRESS_ASC",
    EXPECTED_FEE_ASC = "EXPECTED_FEE_ASC",
    EXPECTED_FEE_DESC = "EXPECTED_FEE_DESC",
    ASKING_PRICE_ASC = "ASKING_PRICE_ASC",
    ASKING_PRICE_DESC = "ASKING_PRICE_DESC",
    SURVEYS_ASC = "SURVEYS_ASC",
    SURVEYS_DESC = "SURVEYS_DESC",
    TAKE_ON_ASC = "TAKE_ON_ASC",
    TAKE_ON_DESC = "TAKE_ON_DESC",

    MEMO_SALE_ASC = "MEMO_SALE_ASC",
    MEMO_SALE_DESC = "MEMO_SALE_DESC",

    MORTGAGE_APP_SUBMITTED_ASC = "MORTGAGE_APP_SUBMITTED_ASC",
    MORTGAGE_APP_SUBMITTED_DESC = "MORTGAGE_APP_SUBMITTED_DESC",

    MORTGAGE_APP_APPROVED_ASC = "MORTGAGE_APP_APPROVED_ASC",
    MORTGAGE_APP_APPROVED_DESC = "MORTGAGE_APP_APPROVED_DESC",

    MORTGAGE_VAL_BOOKED_ASC = "MORTGAGE_VAL_BOOKED_ASC",
    MORTGAGE_VAL_BOOKED_DESC = "MORTGAGE_VAL_BOOKED_DESC",

    MORTGAGE_VAL_COMPLETED_ASC = "MORTGAGE_VAL_COMPLETED_ASC",
    MORTGAGE_VAL_COMPLETED_DESC = "MORTGAGE_VAL_COMPLETED_DESC",

    MORTGAGE_VALUATIONS_ASC = "MORTGAGE_VALUATIONS_ASC",
    MORTGAGE_VALUATIONS_DESC = "MORTGAGE_VALUATIONS_DESC",

    SEARCHES_APPLIED_ASC = "SEARCHES_APPLIED_ASC",
    SEARCHES_APPLIED_DESC = "SEARCHES_APPLIED_DESC",

    SEARCHES_BACK_ASC = "SEARCHES_BACK_ASC",
    SEARCHES_BACK_DESC = "SEARCHES_BACK_DESC",

    DOC_DEPOSITS_ASC = "DOC_DEPOSITS_ASC",
    DOC_DEPOSITS_DESC = "DOC_DEPOSITS_DESC",

    DRAFT_CONTRACT_ASC = "DRAFT_CONTRACT_ASC",
    DRAFT_CONTRACT_DESC = "DRAFT_CONTRACT_DESC",

    ENQUIRIES_ANSWERED_ASC = "ENQUIRIES_ANSWERED_ASC",
    ENQUIRIES_ANSWERED_DESC = "ENQUIRIES_ANSWERED_DESC",

    ENQUIRIES_RAISED_ASC = "ENQUIRIES_RAISED_ASC",
    ENQUIRIES_RAISED_DESC = "ENQUIRIES_RAISED_DESC",

    EXCHANGED_ASC = "EXCHANGED_ASC",
    EXCHANGED_DESC = "EXCHANGED_DESC",

    REPORTED_BUYER_ASC = "REPORTED_BUYER_ASC",
    REPORTED_BUYER_DESC = "REPORTED_BUYER_DESC",

    COMPLETED_ASC = "COMPLETED_ASC",
    COMPLETED_DESC = "COMPLETED_DESC"
}

export enum OfferState {
    Pending = "P",
    Accepted = "A",
    Rejected = "R",
    Withdrawn = "W"
}

export enum PriceOption {
    GuidePrice,
    OffersInRegionOf,
    OffersOver,
    FixedPrice,
    PriceOnApplication
}

export enum AgencyFeeType {
    PercentageOfAgreedPrice = "0",
    FixedFee = "1"
}

export enum ChainPropertySaleType {
    NotDefined = "ND",
    FirstTimeBuyer = "FTB",
    CashBuyer = "CB",
    Investor = "I",
    NonDependantBuyer = "NDB"
}

export enum BoardStatus {
    NoBoard = 0,
    BoardOrdered = 1
}

const buildSubmitPropertyPayload = (
    property: IProperty,
    file?: File
): FormData => {
    const data = new FormData();

    if (property._id !== undefined || property._id !== null) {
        data.append("_id", property._id as string);
    }

    data.append(`address[line1]`, property.address.line1);
    data.append(`address[line2]`, property.address.line2);
    data.append(`address[postcode]`, property.address.postcode);
    data.append(`address[town]`, property.address.town);
    data.append(`address[county]`, property.address.county);
    data.append(`address[country]`, property.address.country);

    if (property.active === true || property.active === false) {
        data.append("active", property.active.toString());
    }

    if (property.bedrooms !== undefined) {
        data.append("bedrooms", property.bedrooms.toString());
    }

    if (property.ownership !== undefined) {
        data.append("ownership", property.ownership);
    }

    if (property.leaseholdPackSent !== undefined) {
        data.append("leaseholdPackSent", property.leaseholdPackSent);
    }

    if (property.boardStatus !== undefined) {
        data.append("boardStatus", property.boardStatus);
    }

    if (property.completionDate !== undefined) {
        let val = property.completionDate;
        if (typeof val !== "string") {
            val = moment(val).toISOString();
        }
        data.append("completionDate", val);
    }

    // Send up either the reference to an owner, or the data-structure to create a new owner (user)
    if (typeof property.owner === "string") {
        data.append(`owner`, property.owner);
    } else if (property.owner._id !== undefined && property.owner._id !== "") {
        data.append(`owner`, property.owner._id);
    } else if (property.owner !== undefined) {
        data.append(`owner[firstName]`, property.owner.firstName);
        data.append(`owner[lastName]`, property.owner.lastName);
        data.append(`owner[tel]`, property.owner.tel);
        data.append(`owner[email]`, property.owner.email);
        data.append(`owner[password]`, (property.owner as any).password);
    }

    if (file !== undefined) {
        data.append("file", file);
    }

    return data;
};
export default {
    getPropertyById: (
        propertyId: string,
        branchId: string,
        companyId: string
    ): Promise<any> => {
        return api.get(
            BASE_URL + `property/${companyId}/${branchId}/${propertyId}`
        );
    },
    getProperties: (
        branchId: string,
        companyId: string,
        paging: IPagingParams = { itemsPerPage: 10, currentPage: 0 },
        textSearch?: string,
        filters?: IPropertyGetListFilters,
        sortOption?: PropertySortOptionType,
        excludeIds?: string[],
        solicitorCompanyId?: string
    ): Promise<AxiosResponse<IGetPropertiesResponse>> => {
        return api.get(BASE_URL + `property/list/${companyId}/${branchId}`, {
            params: {
                ...paging,
                textSearch,
                ...filters,
                sort: sortOption,
                excludeIds,
                solicitorCompanyId
            }
        });
    },
    // TOOD, merge properties getList call with CSV route, CSV controlled via an argument
    getPropertiesCsv: (
        branchId: string,
        companyId: string,
        paging: IPagingParams = { itemsPerPage: 10, currentPage: 0 },
        textSearch?: string,
        filters?: IPropertyGetListFilters,
        sortOption?: PropertySortOptionType,
        excludeIds?: string[],
        solicitorCompanyId?: string
    ): Promise<AxiosResponse<IGetPropertiesResponse>> => {
        return api.get(
            BASE_URL + `property/list/csv/${companyId}/${branchId}`,
            {
                params: {
                    ...paging,
                    textSearch,
                    ...filters,
                    sort: sortOption,
                    excludeIds,
                    solicitorCompanyId
                }
            }
        );
    },
    createProperty: (
        property: IProperty,
        companyId: string,
        branchId: string,
        file?: File
    ): Promise<any> => {
        const data = buildSubmitPropertyPayload(property, file);

        return api({
            url: BASE_URL + `property/${companyId}/${branchId}`,
            method: "POST",
            data,
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    editProperty: (
        property: IProperty,
        companyId: string,
        branchId: string,
        file?: File
    ): Promise<any> => {
        const data = buildSubmitPropertyPayload(property, file);

        return api({
            url: BASE_URL + `property/${companyId}/${branchId}`,
            method: "PUT",
            data,
            headers: { "Content-Type": "multipart/form-data" }
        });
    },
    deleteProperty: (
        propertyId: string,
        branchId: string,
        companyId: string
    ): Promise<any> => {
        return api.delete(
            BASE_URL + `property/${companyId}/${branchId}/${propertyId}`
        );
    },

    addPropertyViewing: (
        viewing: IPropertyViewing,
        propertyId: string,
        branchId: string,
        companyId: string
    ): Promise<any> => {
        return api.post(
            BASE_URL +
                `property/${companyId}/${branchId}/${propertyId}/viewings`,
            viewing
        );
    },
    updatePropertyViewing: (
        offer: IPropertyViewing,
        companyId: string,
        branchId: string,
        propertyId: string
    ): Promise<any> => {
        return api.patch(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/viewing`,
            offer
        );
    },
    removePropertyViewing: (
        viewingId: string,
        propertyId: string,
        branchId: string,
        companyId: string
    ): Promise<any> => {
        return api.delete(
            BASE_URL +
                `property/${companyId}/${branchId}/${propertyId}/viewings/${viewingId}`
        );
    },
    editPropertyMI: (
        miObject: IPropertyManagementInformation,
        propertyId: string,
        branchId: string,
        companyId: string
    ): Promise<any> => {
        return api.patch(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/mi`,
            miObject
        );
    },
    addPropertyMIAskingPrice: (
        askingPrice: IPropertyAskingPrice,
        propertyId: string,
        branchId: string,
        companyId: string
    ): Promise<any> => {
        return api.put(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/askingprice`,
            askingPrice
        );
    },
    updatePropertyMIAskingPrice: (
        askingPrice: IPropertyAskingPrice,
        companyId: string,
        branchId: string,
        propertyId: string
    ): Promise<any> => {
        return api.patch(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/askingprice`,
            askingPrice
        );
    },
    removePropertyMIAskingPrice: (
        askingPriceId: string,
        propertyId: string,
        branchId: string,
        companyId: string
    ): Promise<any> => {
        return api.delete(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/askingprice/${askingPriceId}`
        );
    },
    editPropertyTimeline: (
        timelineEntities: IPropertyTimelineEntity[],
        propertyId: string,
        branchId: string,
        companyId: string
    ): Promise<any> => {
        return api.put(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/timeline`,
            {
                timeline: timelineEntities
            }
        );
    },
    addPropertyOffer: (
        offer: IPropertyOffer,
        propertyId: string,
        branchId: string,
        companyId: string
    ): Promise<any> => {
        return api.post(
            BASE_URL + `property/${companyId}/${branchId}/${propertyId}/offer`,
            offer
        );
    },
    removePropertyOffer: (
        offerId: string,
        propertyId: string,
        branchId: string,
        companyId: string
    ): Promise<any> => {
        return api.delete(
            BASE_URL +
                `property/${companyId}/${branchId}/${propertyId}/offer/${offerId}`
        );
    },
    updatePropertyOffer: (
        offer: IPropertyOfferPayload,
        companyId: string,
        branchId: string,
        propertyId: string
    ): Promise<any> => {
        return api.patch(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/offer`,
            offer
        );
    },

    getMIReport: (
        companyId: string,
        paging: IPagingParams = { itemsPerPage: 10, currentPage: 0 },
        sortOption: MIReportSortOption = MIReportSortOption.SALE_PROGRESS,
        filters?: IWorkflowFilters
    ): Promise<AxiosResponse<IMIReportResponse>> => {
        const params: any = {
            companyId,
            sortOption,
            ...filters,
            ...paging
        };

        return api.get(`${BASE_URL}property/mi/report/${companyId}`, {
            params
        });
    },

    getMIReportCsv: (
        companyId: string,
        paging: IPagingParams = { itemsPerPage: 10, currentPage: 0 },
        sortOption: MIReportSortOption = MIReportSortOption.SALE_PROGRESS,
        filters?: IWorkflowFilters,
        visibleGroups?: IVisibleGroups
    ): Promise<AxiosResponse<IMIReportResponse>> => {
        const params: any = {
            companyId,
            sortOption,
            ...filters,
            ...paging,
            ...visibleGroups
        };

        return api.get(`${BASE_URL}property/mi/report/csv/${companyId}`, {
            params
        });
    },
    getMIKeyStats: (
        companyId: string,
        filters?: IWorkflowFilters
    ): Promise<AxiosResponse<IMIKeyStatsResponse>> => {
        const params: any = {
            companyId,
            ...filters
        };

        return api.get(`${BASE_URL}property/mi/keystats/${companyId}`, {
            params
        });
    },
    sendMIPdf: (options: {
        to: string[];
        companyId: string;
        sortOption: MIReportSortOption;
        filters?: IWorkflowFilters;
        filter: {
            preSale: boolean;
            saleProcess: boolean;
            financial: boolean;
        };
    }): Promise<AxiosResponse<IMIReportResponse>> => {
        const params: any = {
            to: options.to,
            ...options.filters,
            companyId: options.companyId,
            sortOption: options.sortOption || MIReportSortOption.SALE_PROGRESS,
            showFinancial: options.filter.financial,
            showPreSale: options.filter.preSale,
            showSaleProgression: options.filter.saleProcess
        };

        return api.get(`${BASE_URL}property/mi/pdf/${params.companyId}`, {
            params
        });
    },

    updatePropertyContacts: (
        contacts: IPropertyContacts,
        companyId: string,
        branchId: string,
        propertyId: string
    ): Promise<AxiosResponse<{ data: any }>> => {
        return api.patch(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/contacts`,
            contacts
        );
    },
    resetSale: (
        companyId: string,
        branchId: string,
        propertyId: string
    ): Promise<AxiosResponse<IResetPropertySaleResponse>> => {
        return api.post(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/reset`
        );
    },
    restoreSale: (
        companyId: string,
        branchId: string,
        propertyId: string,
        restoreId: string
    ): Promise<AxiosResponse<IRestorePropertySaleResponse>> => {
        return api.post(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/${restoreId}/restore`
        );
    },
    deleteRestoreSale: (
        companyId: string,
        branchId: string,
        propertyId: string,
        restoreId: string
    ): Promise<AxiosResponse<IDeleteRestorePropertySaleResponse>> => {
        return api.delete(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/${restoreId}/restore`
        );
    },
    deletePhoto: (
        companyId: string,
        branchId: string,
        propertyId: string
    ): Promise<AxiosResponse<IProperty>> => {
        return api.delete(
            `${BASE_URL}property/photo/${companyId}/${branchId}/${propertyId}`
        );
    },
    createNote: (
        note: ICreatePropertyNoteFields,
        companyId: string,
        branchId: string,
        propertyId: string
    ): Promise<AxiosResponse<any>> => {
        return api.post(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/note`,
            note
        );
    },
    updateNote: (
        note: IPropertyNote,
        companyId: string,
        branchId: string,
        propertyId: string
    ): Promise<AxiosResponse<any>> => {
        return api.patch(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/note`,
            note
        );
    },
    deleteNote: (
        noteId: string,
        companyId: string,
        branchId: string,
        propertyId: string
    ): Promise<AxiosResponse<any>> => {
        return api.delete(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/note/${noteId}`
        );
    },
    updateChain: (
        chain: IPropertyChain,
        companyId: string,
        branchId: string,
        propertyId: string
    ): Promise<AxiosResponse<{ property: IProperty }>> => {
        return api.patch(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/chain`,
            chain
        );
    },
    updateAllChain: (
        chain: IPropertyChain,
        companyId: string,
        branchId: string,
        propertyId: string,
        resetDeletedPropertyChains?: boolean
    ): Promise<AxiosResponse<{ property: IProperty }>> => {
        return api.patch(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/chainall`,
            { ...chain, resetDeletedPropertyChains }
        );
    },

    getImage: async (
        companyId: string,
        branchId: string,
        propertyId: string
    ): Promise<any> => {
        return api.get(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/image`,
            { responseType: "arraybuffer" }
        );
    },

    addDocument: (
        companyId: string,
        branchId: string,
        propertyId: string,
        file: File,
        note?: string
    ): Promise<any> => {
        const data = new FormData();

        data.append("file", file);

        if (note !== undefined) {
            data.append("note", note);
        }

        return api({
            url: `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/document`,
            method: "POST",
            data,
            headers: { "Content-Type": "multipart/form-data" }
        });
    },
    updateDocument: (
        companyId: string,
        branchId: string,
        propertyId: string,
        documentId: string,
        note?: string
    ): Promise<any> => {
        return api.patch(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/document/${documentId}`,
            { note }
        );
    },
    deleteDocument: (
        companyId: string,
        branchId: string,
        propertyId: string,
        documentId: string
    ): Promise<any> => {
        return api.delete(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/document/${documentId}`
        );
    },

    downloadDocument: async (
        companyId: string,
        branchId: string,
        propertyId: string,
        documentId: string
    ): Promise<any> => {
        return api.get(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/document/${documentId}/download`,
            { responseType: "arraybuffer" }
        );
    },
    emailDocument: async (
        companyId: string,
        branchId: string,
        propertyId: string,
        documentId: string,
        emailParams: {
            to: string[];
            cc?: string[];
            bcc?: string[];
            subject?: string;
            message: string;
            replyTo?: string;
        }
    ): Promise<any> => {
        return api.post(
            `${BASE_URL}property/${companyId}/${branchId}/${propertyId}/document/${documentId}/email`,
            emailParams
        );
    }
};
