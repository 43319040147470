import React, { Component } from "react";
import VendorTypeahead from "../../components/VendorTypeahead";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import { searchVendors } from "../../../../../../redux/api/user";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import UserCard from "../../components/UserCard";
import {
    setCreatePropertyVendorRef,
    resetCreatePropertyVendorRef
} from "../../../../../../redux/api/property";
import { IUser } from "../../../../../../api/_types";

interface IProps {
    vendorSearchResults: IUser[];
    searchVendorStatus: AsyncActionState;
    createPropertyVendorRef?: string | IUser;
    searchVendors: (searchString: string) => void;
    setCreatePropertyVendorRef: (user: IUser) => void;
    resetCreatePropertyVendorRef: () => void;
}
interface IState {}

class VendorTypeaheadContainer extends Component<IProps, IState> {
    componentWillMount() {
        this.props.resetCreatePropertyVendorRef();
    }
    componentWillUnmount() {
        this.props.resetCreatePropertyVendorRef();
    }
    handleFetchUsers = (searchString: string) => {
        this.props.searchVendors(searchString);
        this.props.resetCreatePropertyVendorRef();
    };
    handleSelectUser = (user: IUser) => {
        this.props.setCreatePropertyVendorRef(user);
    };

    render() {
        const {
            createPropertyVendorRef,
            vendorSearchResults,
            searchVendorStatus,
            resetCreatePropertyVendorRef
        } = this.props;
        const showUserCard =
            createPropertyVendorRef !== undefined &&
            typeof createPropertyVendorRef !== "string";
        return (
            <>
                <VendorTypeahead
                    users={vendorSearchResults}
                    search={this.handleFetchUsers}
                    searchPending={
                        searchVendorStatus === AsyncActionState.Pending
                    }
                    onSelect={this.handleSelectUser}
                />
                {showUserCard && (
                    <UserCard
                        user={createPropertyVendorRef as IUser}
                        readonly={createPropertyVendorRef !== undefined}
                        clearUser={resetCreatePropertyVendorRef}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = ({ user, property }: IStore) => ({
    vendorSearchResults: user.vendorSearchResults,
    searchVendorStatus: user.searchVendorStatus,
    createPropertyVendorRef: property.createPropertyVendorRef
});

export default connect(
    mapStateToProps,
    {
        resetCreatePropertyVendorRef,
        searchVendors,
        setCreatePropertyVendorRef
    }
)(VendorTypeaheadContainer);
