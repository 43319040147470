import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { OfferState } from "../../../../../../api/modules/property";
import { IPropertyOffer } from "../../../../../../api/_types";
import { canAcceptOffer } from "../../../../../../lib/core/offers";
import routes from "../../../../../../navigation/routes";
import {
    addPropertyOffer,
    resetAddPropertyOfferStatus
} from "../../../../../../redux/api/property";
import { selectPropertyOffers } from "../../../../../../redux/api/property/selectors/property";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import CreateOfferForm from "../../components/CreateOfferForm";

const mapStateToProps = ({ property }: IStore) => ({
    offers: selectPropertyOffers(property),
    addPropertyOfferStatus: property.addPropertyOfferStatus
});

const connector = connect(
    mapStateToProps,
    { addPropertyOffer, resetAddPropertyOfferStatus }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {}

interface IState {
    offer: IPropertyOffer;
    dirty: boolean;
}

class CreateOfferFormContainer extends Component<
    IProps & RouteComponentProps,
    IState
> {
    private readonly defaultOffer: IPropertyOffer = {
        amount: 0,
        when: new Date().toISOString(),
        state: OfferState.Pending,
        notes: "",
        tel: "",
        history: [],
        email: "",
        depositSize: 0,
        name: "",
        cashBuy: false,
        mip: false,
        chain: false
    };
    generateDefaultOffer = () => {
        return Object.assign({}, this.defaultOffer, {
            amount:
                this.props.offers.length > 0 ? this.props.offers[0].amount : 0
        });
    };
    state: IState = {
        offer: Object.assign({}, this.generateDefaultOffer()),
        dirty: false
    };

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (
            prevProps.addPropertyOfferStatus !== AsyncActionState.Success &&
            this.props.addPropertyOfferStatus === AsyncActionState.Success
        ) {
            this.setState(
                {
                    offer: Object.assign({}, this.generateDefaultOffer()),
                    dirty: false
                },
                () => {
                    this.props.history.push(routes.propertyOffers.url);
                }
            );
        }

        // If cash buy is selected, reset deposit size to 0.
        if (
            (this.state.offer.cashBuy as any) === "1" &&
            this.state.offer.depositSize > 0
        ) {
            this.setState({
                offer: Object.assign({}, this.state.offer, {
                    depositSize: 0
                })
            });
        }
    }

    componentWillUnmount() {
        this.props.resetAddPropertyOfferStatus();
    }

    handleSave = () => {
        const { offer } = this.state;

        const offerPayload = Object.assign({}, offer);

        if (typeof offerPayload.chain === "string") {
            if (offerPayload.chain === "0" || offerPayload.chain === "") {
                offerPayload.chain = false;
            } else if (offerPayload.chain === "1") {
                offerPayload.chain = true;
            }
        }

        if (typeof offerPayload.mip === "string") {
            if (offerPayload.mip === "0" || offerPayload.mip === "") {
                offerPayload.mip = false;
            } else if (offerPayload.mip === "1") {
                offerPayload.mip = true;
            }
        }

        if (typeof offerPayload.cashBuy === "string") {
            if (offerPayload.cashBuy === "0" || offerPayload.cashBuy === "") {
                offerPayload.cashBuy = false;
            } else if (offerPayload.cashBuy === "1") {
                offerPayload.cashBuy = true;
            }
        }

        this.props.addPropertyOffer(offerPayload);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "offer"
        );
    };

    render() {
        const { offer, dirty } = this.state;
        const { offers, addPropertyOfferStatus } = this.props;
        const userCanAcceptOffer = canAcceptOffer(offer, offers);

        return (
            <>
                <NavigationPrompt when={dirty === true} />
                <LoadingContainer
                    loading={
                        addPropertyOfferStatus === AsyncActionState.Pending
                    }
                >
                    <CreateOfferForm
                        offer={offer}
                        save={this.handleSave}
                        onChangeInputField={this.onChangeInputField}
                        canAcceptOffer={userCanAcceptOffer}
                        saveOfferStatus={addPropertyOfferStatus}
                        resetFormState={this.props.resetAddPropertyOfferStatus}
                    />
                </LoadingContainer>
            </>
        );
    }
}

export default connector(withRouter<any, any>(CreateOfferFormContainer));
