import { CSSProperties } from "react";

export default {
    bar: (params: {
        color: string;
        side: "left" | "right";
        top?: "top" | "bottom";
    }) =>
        ({
            position: "absolute",
            top: params.top === "bottom" ? 25 : 70,
            backgroundColor: params.color,
            height: 10,
            width: "100%",
            [params.side]: "calc(-50% + 5px)"
        } as CSSProperties)
};
