import moment from "moment";
import React, { FC } from "react";
import { IOfferHistory, IPropertyOffer } from "../../../../../../api/_types";
import DataTable from "../../../../../_sharedComponents/DataTable";

type OfferFields = Pick<IPropertyOffer, "history">;

interface IProps extends OfferFields {}

const OfferHistoryList: FC<IProps> = ({ history }) => {
    return (
        <DataTable
            data={history}
            fields={[
                { 
                    displayName: "Amount", 
                    field: "amount",
                    mutator: (item: IOfferHistory) =>item.amount?.toLocaleString()  
                },
                {
                    displayName: "When",
                    field: "createdon",
                    mutator: (item: IOfferHistory) =>
                        moment(item.createdon).format("DD/MM/YYYY hh:mma")
                },
                { displayName: "Note", field: "note" }
            ]}
        />
    );
};

export default OfferHistoryList;
