import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { IPropertyOffer } from "../../../../../../../api/_types";

type OfferFields = Pick<IPropertyOffer, "name">;

interface IProps extends OfferFields {
    onChange: (key: string) => (e: any) => void;
}

const OfferName: FC<IProps> = ({ name, onChange }) => {
    return (
        <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control onChange={onChange("name")} value={name} required />
        </Form.Group>
    );
};

export default OfferName;
