import * as actions from "../../actions/branch";
import { AsyncActionState } from "../../utils/asyncAction";
import { IBranch, IUser } from "../../../api/_types";
import {
    IGetBranchesResponse,
    ISearchBranchMembersResponse
} from "../../../api/modules/branch";

export const setBranches = (branches: IGetBranchesResponse) => {
    return {
        type: actions.BranchTypes.SET_BRANCHES,
        payload: branches
    } as actions.SetBranches;
};

export const setViewingBranch = (branch?: IBranch) => {
    return {
        type: actions.BranchTypes.SET_VIEWING_BRANCH,
        payload: branch
    } as actions.SetViewingBranch;
};

export const setCreateBranchStatus = (status: AsyncActionState) => {
    return {
        type: actions.BranchTypes.CREATE_BRANCH_STATUS,
        payload: status
    } as actions.CreateBranchStatus;
};

export const setDeleteBranchStatus = (status: AsyncActionState) => {
    return {
        type: actions.BranchTypes.DELETE_BRANCH_STATUS,
        payload: status
    } as actions.DeleteBranchStatus;
};

export const setGetBranchesStatus = (status: AsyncActionState) => {
    return {
        type: actions.BranchTypes.GET_BRANCHES_STATUS,
        payload: status
    } as actions.GetBranchesStatus;
};

export const setGetBranchStatus = (status: AsyncActionState) => {
    return {
        type: actions.BranchTypes.GET_BRANCH_STATUS,
        payload: status
    } as actions.GetBranchStatus;
};

export const setSearchBranchAdminStatus = (payload: AsyncActionState) => {
    return {
        type: actions.BranchTypes.SEARCH_BRANCH_ADMIN_STATUS,
        payload
    } as actions.SearchBranchAdminStatus;
};

export const setSearchBranchAdminResult = (payload: IUser[]) => {
    return {
        type: actions.BranchTypes.SET_SEARCH_BRANCH_ADMIN_RESULT,
        payload
    } as actions.SetSearchBranchAdminResult;
};

export const setCreateBranchAdministratorRef = (user: IUser) => {
    return {
        type: actions.BranchTypes.SET_CREATE_BRANCH_ADMINISTRATOR_REF,
        payload: user
    } as actions.SetCreateBranchAdministratorRef;
};

export const resetCreateBranchAdministratorRef = () => {
    return {
        type: actions.BranchTypes.SET_CREATE_BRANCH_ADMINISTRATOR_REF,
        payload: undefined
    } as actions.SetCreateBranchAdministratorRef;
};

export const setAddBranchmemberStatus = (payload: AsyncActionState) => {
    return {
        type: actions.BranchTypes.ADD_BRANCH_MEMBER_STATUS,
        payload
    } as actions.AddBranchMemberStatus;
};

export const setRemoveBranchmemberStatus = (payload: AsyncActionState) => {
    return {
        type: actions.BranchTypes.REMOVE_BRANCH_MEMBER_STATUS,
        payload
    } as actions.RemoveBranchMemberStatus;
};

export const setSearchBranchMemberStatus = (payload: AsyncActionState) => {
    return {
        type: actions.BranchTypes.SEARCH_BRANCH_MEMBER_STATUS,
        payload
    } as actions.SetSearchBranchMemberStatus;
};

export const setSearchBranchMemberResult = (
    payload: ISearchBranchMembersResponse
) => {
    return {
        type: actions.BranchTypes.SEARCH_BRANCH_MEMBER_RESULT,
        payload
    } as actions.SetSearchBranchMemberResult;
};

export const setUpdateBranchTimelineStatus = (status: AsyncActionState) => {
    return {
        type: actions.BranchTypes.UPDATE_BRANCH_TIMELINE_STATUS,
        payload: status
    } as actions.UpdateBranchTimelineStatus;
};

export const setUpdateBranchMemberStatus = (status: AsyncActionState) => {
    return {
        type: actions.BranchTypes.EDIT_BRANCH_MEMBER_STATUS,
        payload: status
    } as actions.EditBranchMemberStatus;
};

export const setUpdateBranchMember = (user?: IUser) => {
    return {
        type: actions.BranchTypes.SET_EDIT_BRANCH_MEMBER,
        payload: user
    } as actions.SetEditBranchMember;
};

export const setDeleteBranchPhotoStatus = (status: AsyncActionState) => {
    return {
        type: actions.BranchTypes.DELETE_BRANCH_PHOTO_STATUS,
        payload: status
    } as actions.DeleteBranchPhotoStatus;
};
