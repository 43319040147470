import moment, { Moment } from "moment";

export function roundTimeToMinutes(minutes: number, start: Moment = moment()) {
    const rounded = Math.round(start.minute() / minutes) * minutes;
    const roundedDown = Math.floor(start.minute() / minutes) * minutes;
    const roundedUp = Math.ceil(start.minute() / minutes) * minutes;

    return {
        rounded: moment()
            .minute(rounded)
            .second(0)
            .millisecond(0),
        roundedDown: moment()
            .minute(roundedDown)
            .second(0)
            .millisecond(0),
        roundedUp: moment()
            .minute(roundedUp)
            .second(0)
            .millisecond(0)
    };
}

export default { roundTimeToMinutes };
