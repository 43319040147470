import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { OfferState } from "../../../../../../../api/modules/property";
import { IPropertyOffer } from "../../../../../../../api/_types";

type OfferFields = Pick<IPropertyOffer, "state">;

interface IProps extends OfferFields {
    canAcceptOffer: boolean;
    onChange: (key: string) => (e: any) => void;
}

const OfferStateForm: FC<IProps> = ({ canAcceptOffer, state, onChange }) => {
    return (
        <Form.Group controlId="offerState">
            <Form.Label>Offer status</Form.Label>
            <Form.Control
                as="select"
                value={state}
                onChange={onChange("state")}
            >
                <option value={OfferState.Pending}>Offer pending</option>
                {canAcceptOffer === true && (
                    <option value={OfferState.Accepted}>Offer accepted</option>
                )}
                <option value={OfferState.Rejected}>Offer rejected</option>
                <option value={OfferState.Withdrawn}>Offer withdrawn</option>
            </Form.Control>
        </Form.Group>
    );
};

export default OfferStateForm;
