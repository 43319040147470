import {
    ICompany,
    IBranch,
    IPropertyTimeline,
    IPropertyTimelineTemplate
} from "../../../../api/_types";
import {
    IPropertyTimelineEntityState,
    IPropertyTimelineEntityType
} from "../../../../redux/_types/property/types";

/**
 * @function filterTimeline Filters NA items out of a given timeline.
 * @param timeline
 * @param options
 */
export const filterTimeline = (
    timeline: IPropertyTimeline | IPropertyTimelineTemplate,
    options: {
        checkCompanyTemplate?: boolean;
        checkBranchTemplate?: boolean;
        company?: ICompany;
        branch?: IBranch;
    }
): IPropertyTimeline | IPropertyTimelineTemplate | undefined => {
    const {
        checkCompanyTemplate = false,
        checkBranchTemplate = false,
        company,
        branch
    } = options;

    const copy: IPropertyTimeline | IPropertyTimelineTemplate = Object.assign(
        {},
        timeline
    );
    if (checkCompanyTemplate === true && company === undefined) {
        return timeline;
    }
    if (checkBranchTemplate === true && branch === undefined) {
        return timeline;
    }

    copy.data = copy.data.filter((item) => {
        const companyLocal: ICompany = company as ICompany;
        const branchLocal: IBranch = branch as IBranch;
        let companyItem;
        let branchItem;

        if (
            (checkCompanyTemplate === true &&
                companyLocal.timelineTemplate === undefined) ||
            (checkBranchTemplate === true &&
                branchLocal.timelineTemplate === undefined)
        ) {
            return false;
        }

        if (checkCompanyTemplate === true) {
            companyItem = (companyLocal.timelineTemplate as IPropertyTimeline).data.find(
                (templateItem) => templateItem.key === item.key
            );
            // surveys defaault to N/A so exempt them from the exclusion
            if (
                companyItem !== undefined &&
                companyItem.type === IPropertyTimelineEntityType.SURVEY
            ) {
                return true;
            }
            if (
                companyItem === undefined ||
                companyItem.state === IPropertyTimelineEntityState.NA
            ) {
                return false;
            }
        }

        if (checkBranchTemplate === true) {
            branchItem = (branchLocal.timelineTemplate as IPropertyTimeline).data.find(
                (templateItem) => templateItem.key === item.key
            );

            // surveys defaault to N/A so exempt them from the exclusion
            if (
                branchItem !== undefined &&
                branchItem.type === IPropertyTimelineEntityType.SURVEY
            ) {
                return true;
            }

            if (
                branchItem === undefined ||
                (branchItem.state === IPropertyTimelineEntityState.NA &&
                    item.state === IPropertyTimelineEntityState.NA)
            ) {
                return false;
            }
        }

        return true;
    });

    return copy;
};
