import React, { Component } from "react";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EditBranchTopNav from "../../containers/EditBranchTopNav";
import AddMemberForm from "../../containers/AddMemberForm";
import BranchMemberTable from "../../containers/BranchMemberTable";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import HorizontalTabs, {
    IHorizontalTabsItem
} from "../../../../../_sharedComponents/HorizontalTabNav";
import { IBranch, IUser } from "../../../../../../api/_types";
import routes from "../../../../../../navigation/routes";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AddExistingMemberForm from "../../containers/AddExistingMemberForm";

interface IProps {
    branch?: IBranch;
    user?: IUser;
}
interface IState {}

class ManageBranchUsersScreen extends Component<
    IProps & RouteComponentProps,
    IState
> {
    render() {
        const { user, branch } = this.props;
        const tabs: IHorizontalTabsItem[] = [
            {
                key: routes.branchUsers.url,
                title: "Users",
                content: BranchMemberTable
            }
        ];
        if (user === undefined || branch === undefined) {
            return null;
        }

        if (
            [
                UserTypes.GYA_ADMIN,
                UserTypes.COMPANY_ADMIN,
                UserTypes.BRANCH_ADMIN
            ].includes(user.type) === true
        ) {
            tabs.push(
                {
                    key: routes.branchCreateUser.url,
                    title: "Add new user",
                    content: AddMemberForm
                },
                {
                    key: routes.branchAddExistingUser.url,
                    title: "Add existing user",
                    content: AddExistingMemberForm
                }
            );
        }

        return (
            <PageWrap>
                <EntityTitle entityName={branch.name} />
                <EditBranchTopNav />

                <HorizontalTabs
                    id="manage-branch-users-tabs"
                    defaultKey={this.props.match.url}
                    items={tabs}
                />
            </PageWrap>
        );
    }
}

const mapStateToProps = ({ user, branch }: IStore) => ({
    user: user.me,
    branch: branch.viewingBranch
});

export default connect(
    mapStateToProps,
    {}
)(withRouter<any, any>(ManageBranchUsersScreen));
