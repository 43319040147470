import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    goBack: () => void;
    showLabel?: boolean;
    labelText?: string;
}

export default function BackButton(props: IProps) {
    const { goBack, showLabel = true, labelText = "Back" } = props;

    return (
        <div className="d-flex align-items-center">
            <FontAwesomeIcon
                icon={faArrowLeft}
                size="lg"
                onClick={goBack}
                color="#00A9CE"
            />
            {showLabel && (
                <span onClick={goBack} className="ml-1">
                    {labelText}
                </span>
            )}
        </div>
    );
}
