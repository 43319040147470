import React, { Component } from "react";
import CreateCompanyForm from "../../components/CreateCompanyForm";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import {
    saveCompany,
    getCompanyById,
    resetViewingCompany,
    deleteCompany,
    deletePhoto
} from "../../../../../../redux/api/company";
import { resetCreateCompanyFormState } from "../../../../../../redux/api/company";
import TabBody from "../../../../../_sharedComponents/TabBody";
import { IUser, ICompany } from "../../../../../../api/_types";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import DeletePhotoPrompt from "../../../../../_sharedComponents/DeletePhotoPrompt";

interface IProps {
    user?: IUser;
    companyId: string;
    viewingCompany?: ICompany;
    saveCompanyStatus: AsyncActionState;
    deleteCompanyStatus: AsyncActionState;
    deletePhotoStatus: AsyncActionState;
    getCompanyById: (_id: string) => void;
    saveCompany: (company: ICompany, file?: File) => void;
    resetCreateCompanyFormState: () => void;
    resetViewingCompany: () => void;
    deleteCompany: (companyId: string) => void;
    deletePhoto: () => void;
}
interface IState {
    showDeleteModal: boolean;
    showDeletePhotoPrompt: boolean;
}

class EditCompanyContainer extends Component<IProps, IState> {
    state: IState = {
        showDeleteModal: false,
        showDeletePhotoPrompt: false
    };

    componentDidMount() {
        const { getCompanyById, companyId } = this.props;
        getCompanyById(companyId);
    }

    triggerDeletePhoto = () => this.setState({ showDeletePhotoPrompt: true });
    cancelDeletePhoto = () => this.setState({ showDeletePhotoPrompt: false });

    deletePhotoSuccess = () => {
        this.cancelDeletePhoto();
        setTimeout(() => {
            alert("Company photo was removed successfully.");
        });
    };

    deletePhotoError = () => {
        this.cancelDeletePhoto();
        setTimeout(() => {
            alert("Something went wrong deleting the company photo.");
        });
    };

    showDeleteModal = () =>
        this.setState({
            showDeleteModal: true
        });

    hideDeleteModal = () =>
        this.setState({
            showDeleteModal: false
        });

    handleDeleteCompany = () => {
        const {
            viewingCompany,
            deleteCompany = Function.prototype
        } = this.props;
        if (viewingCompany === undefined || viewingCompany._id === undefined) {
            return;
        }

        deleteCompany(viewingCompany._id);
    };

    onCompanyDeleteSuccess = () => (window.location.href = "/");

    render() {
        const {
            viewingCompany,
            saveCompany,
            saveCompanyStatus,
            resetCreateCompanyFormState,
            deleteCompanyStatus,
            user,
            deletePhotoStatus
        } = this.props;
        const { showDeleteModal } = this.state;

        if (
            viewingCompany === undefined ||
            viewingCompany === null ||
            user === undefined
        ) {
            return null;
        }

        return (
            <TabBody>
                <LoadingContainer
                    loading={
                        saveCompanyStatus === AsyncActionState.Pending ||
                        deleteCompanyStatus === AsyncActionState.Pending
                    }
                >
                    <CreateCompanyForm
                        user={user}
                        company={viewingCompany}
                        createCompany={saveCompany}
                        resetCreateCompanyFormState={
                            resetCreateCompanyFormState
                        }
                        deleteCompany={this.showDeleteModal}
                        saveCompanyPending={
                            saveCompanyStatus === AsyncActionState.Pending
                        }
                        saveCompanyError={
                            saveCompanyStatus === AsyncActionState.Error
                        }
                        saveCompanySuccess={
                            saveCompanyStatus === AsyncActionState.Success
                        }
                        deletePhoto={this.triggerDeletePhoto}
                    />
                </LoadingContainer>

                <DeletePhotoPrompt
                    show={this.state.showDeletePhotoPrompt}
                    deleteFunction={this.props.deletePhoto}
                    cancel={this.cancelDeletePhoto}
                    deleteStatus={deletePhotoStatus}
                    onSuccess={this.deletePhotoSuccess}
                    onError={this.deletePhotoError}
                />

                <ActionPrompt
                    actions={[
                        {
                            title: "Delete",
                            btnVariant: "danger",
                            onClick: this.handleDeleteCompany,
                            asyncStatus: deleteCompanyStatus,
                            onSuccess: this.onCompanyDeleteSuccess,
                            useConfirm: true
                        }
                    ]}
                    title="Delete this company"
                    bodyText="Are you sure you want to delete this company? This cannot be undone. This will permanently delete the company, it's associated branches, properties, and users."
                    show={showDeleteModal}
                    cancel={this.hideDeleteModal}
                    confirmText={viewingCompany.name}
                    confirmTextLabel="Please enter the company name to delete"
                />
            </TabBody>
        );
    }
}

const mapStateToProps = ({ company, user }: IStore) => ({
    user: user.me,
    viewingCompany: company.viewingCompany,
    saveCompanyStatus: company.saveCompanyStatus,
    deleteCompanyStatus: company.deleteCompanyStatus,
    deletePhotoStatus: company.deletePhotoStatus
});

export default connect(
    mapStateToProps,
    {
        saveCompany,
        getCompanyById,
        resetCreateCompanyFormState,
        resetViewingCompany,
        deleteCompany,
        deletePhoto
    }
)(EditCompanyContainer);
