import React, { Component } from "react";
import ManageBranchForm from "../../components/ManageBranchForm";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import {
    saveBranch,
    getBranchById,
    resetViewingBranch,
    deleteBranch,
    resetManageBranchFormState,
    resetBranchAdminSearchResults,
    resetCreateBranchAdminRef,
    deletePhoto
} from "../../../../../../redux/api/branch";
import { IUser, IBranch } from "../../../../../../api/_types";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import { RouteComponentProps, withRouter } from "react-router-dom";
import routes from "../../../../../../navigation/routes";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import DeletePhotoPrompt from "../../../../../_sharedComponents/DeletePhotoPrompt";

interface IProps {
    user?: IUser;
    branchId: string;
    companyId: string;
    viewingBranch?: IBranch;
    createBranchStatus: AsyncActionState;
    deleteBranchStatus: AsyncActionState;
    deletePhotoStatus: AsyncActionState;
    getBranchById: (_id: string, companId: string) => void;
    saveBranch: (branch: IBranch, file?: File) => void;
    resetManageBranchFormState: () => void;
    resetViewingBranch: () => void;
    deleteBranch: (branchId: string) => void;
    resetBranchAdminSearchResults: () => void;
    resetCreateBranchAdminRef: () => void;
    deletePhoto: () => void;
}
interface IState {
    showDeleteModal: boolean;
    showDeletePhotoPrompt: boolean;
}

class EditBranchContainer extends Component<
    IProps & RouteComponentProps,
    IState
> {
    state: IState = {
        showDeleteModal: false,
        showDeletePhotoPrompt: false
    };

    componentDidMount() {
        const { getBranchById, branchId, companyId } = this.props;
        getBranchById(branchId, companyId);
    }

    triggerDeletePhoto = () => this.setState({ showDeletePhotoPrompt: true });
    cancelDeletePhoto = () => this.setState({ showDeletePhotoPrompt: false });

    deletePhotoSuccess = () => {
        this.cancelDeletePhoto();
        setTimeout(() => {
            alert("Branch photo was removed successfully.");
        });
    };

    deletePhotoError = () => {
        this.cancelDeletePhoto();
        setTimeout(() => {
            alert("Something went wrong deleting the branch photo.");
        });
    };

    showDeleteModal = () => {
        this.setState({
            showDeleteModal: true
        });
    };
    hideDeleteModal = () => {
        this.setState({
            showDeleteModal: false
        });
    };

    handleDeleteBranch = () => {
        const { viewingBranch, deleteBranch = Function.prototype } = this.props;
        if (viewingBranch === undefined || viewingBranch._id === undefined) {
            return;
        }

        deleteBranch(viewingBranch._id);
    };

    onDeleteBranchSuccess = () => {
        this.props.history.replace(routes.branchManage.url);
    };

    render() {
        const {
            user,
            viewingBranch,
            saveBranch,
            createBranchStatus,
            deleteBranchStatus,
            resetManageBranchFormState,
            resetBranchAdminSearchResults,
            resetCreateBranchAdminRef,
            deletePhotoStatus
        } = this.props;
        const { showDeleteModal } = this.state;

        if (
            viewingBranch === undefined ||
            viewingBranch === null ||
            user === undefined
        ) {
            return null;
        }

        return (
            <LoadingContainer
                loading={
                    createBranchStatus === AsyncActionState.Pending ||
                    deleteBranchStatus === AsyncActionState.Pending
                }
            >
                <ManageBranchForm
                    user={user}
                    branch={viewingBranch}
                    deleteBranch={this.showDeleteModal}
                    createBranch={saveBranch}
                    createBranchPending={
                        createBranchStatus === AsyncActionState.Pending
                    }
                    createBranchError={
                        createBranchStatus === AsyncActionState.Error
                    }
                    createBranchSuccess={
                        createBranchStatus === AsyncActionState.Success
                    }
                    resetManageBranchFormState={resetManageBranchFormState}
                    resetBranchAdminSearchResults={
                        resetBranchAdminSearchResults
                    }
                    resetCreateBranchAdminRef={resetCreateBranchAdminRef}
                    deletePhoto={this.triggerDeletePhoto}
                />
                <DeletePhotoPrompt
                    show={this.state.showDeletePhotoPrompt}
                    deleteFunction={this.props.deletePhoto}
                    cancel={this.cancelDeletePhoto}
                    deleteStatus={deletePhotoStatus}
                    onSuccess={this.deletePhotoSuccess}
                    onError={this.deletePhotoError}
                />
                <ActionPrompt
                    actions={[
                        {
                            title: "Delete",
                            btnVariant: "danger",
                            onClick: this.handleDeleteBranch,
                            asyncStatus: deleteBranchStatus,
                            onSuccess: this.onDeleteBranchSuccess,
                            useConfirm: true
                        }
                    ]}
                    show={showDeleteModal}
                    cancel={this.hideDeleteModal}
                    title="Delete this branch"
                    bodyText="Are you sure you want to delete this branch? This action cannot be undone. This will permanently delete the branch, it's associated properties, and all data associated with the properties."
                    confirmText={viewingBranch.name}
                    confirmTextLabel="Please enter the branch name to delete"
                />
            </LoadingContainer>
        );
    }
}

const mapStateToProps = ({ branch, user }: IStore) => ({
    user: user.me,
    viewingBranch: branch.viewingBranch,
    createBranchStatus: branch.createBranchStatus,
    deleteBranchStatus: branch.deleteBranchStatus,
    deletePhotoStatus: branch.deletePhotoStatus
});

export default connect(
    mapStateToProps,
    {
        saveBranch,
        getBranchById,
        deleteBranch,
        resetManageBranchFormState,
        resetViewingBranch,
        resetCreateBranchAdminRef,
        resetBranchAdminSearchResults,
        deletePhoto
    }
)(withRouter<any, any>(EditBranchContainer));
