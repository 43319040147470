import * as actions from "../../actions/company";
import {
    ICompany,
    IPagingResponse,
    ISearchCompanyMembersResponse,
    IUser,
    IProperty
} from "../../../api/_types";
import { AsyncActionState } from "../../utils/asyncAction";
import { createPager } from "../../../lib/utils/pager";

export interface ICompanyState {
    companies: {
        paging: IPagingResponse;
        companies: ICompany[];
    };
    properties: {
        paging: IPagingResponse;
        properties: IProperty[];
    };
    viewingCompany?: ICompany;
    saveCompanyStatus: AsyncActionState;
    getCompaniesStatus: AsyncActionState;
    getCompanyStatus: AsyncActionState;
    deleteCompanyStatus: AsyncActionState;
    deleteCompanyMemberStatus: AsyncActionState;
    updateCompanyTimelineStatus: AsyncActionState;
    members: ISearchCompanyMembersResponse;
    searchCompanyMembersStatus: AsyncActionState;
    createCompanyAdminStatus: AsyncActionState;
    editCompanyAdminStatus: AsyncActionState;
    editMember?: IUser;
    deletePhotoStatus: AsyncActionState;
    getCompanyPropertiesStatus: AsyncActionState;
}

const initialState: ICompanyState = {
    companies: {
        paging: createPager({ itemsPerPage: 10 }),
        companies: []
    },
    properties: {
        paging: createPager({ itemsPerPage: 10 }),
        properties: []
    },

    viewingCompany: undefined,
    saveCompanyStatus: AsyncActionState.Reset,
    getCompaniesStatus: AsyncActionState.Reset,
    getCompanyStatus: AsyncActionState.Reset,
    deleteCompanyStatus: AsyncActionState.Reset,
    deleteCompanyMemberStatus: AsyncActionState.Reset,
    updateCompanyTimelineStatus: AsyncActionState.Reset,
    searchCompanyMembersStatus: AsyncActionState.Reset,
    members: {
        paging: createPager({ itemsPerPage: 10 }),
        users: []
    },
    createCompanyAdminStatus: AsyncActionState.Reset,
    editCompanyAdminStatus: AsyncActionState.Reset,
    editMember: undefined,
    deletePhotoStatus: AsyncActionState.Reset,
    getCompanyPropertiesStatus: AsyncActionState.Reset
};

export default function companyReducer(
    state: ICompanyState = initialState,
    action:
        | actions.SetCompanies
        | actions.SetViewingCompany
        | actions.SaveCompanyStatus
        | actions.GetCompaniesStatus
        | actions.GetCompanyStatus
        | actions.DeleteCompanyStatus
        | actions.UpdateCompanyTimelineStatus
        | actions.SetCompanyUsers
        | actions.SetSearchCompanyUsersStatus
        | actions.SetCreateCompanyAdminStatus
        | actions.SetEditCompanyAdmin
        | actions.EditCompanyAdminStatus
        | actions.DeleteCompanyMemberStatus
        | actions.DeleteCompanyPhotoStatus
        | actions.GetCompanyPropertiesStatus
        | actions.SetCompanyProperties
) {
    switch (action.type) {
        case actions.CompanyTypes.GET_COMPANY_PROPERTIES_STATUS: {
            return Object.assign({}, state, {
                getCompanyPropertiesStatus: action.payload
            } as ICompanyState);
        }
        case actions.CompanyTypes.DELETE_COMPANY_PHOTO_STATUS: {
            return Object.assign({}, state, {
                deletePhotoStatus: action.payload
            } as ICompanyState);
        }
        case actions.CompanyTypes.DELETE_MEMBER_STATUS: {
            return Object.assign({}, state, {
                deleteCompanyMemberStatus: action.payload
            } as ICompanyState);
        }
        case actions.CompanyTypes.EDIT_COMPANY_ADMIN_STATUS: {
            return Object.assign({}, state, {
                editCompanyAdminStatus: action.payload
            } as ICompanyState);
        }
        case actions.CompanyTypes.SET_EDIT_COMPANY_ADMIN: {
            return Object.assign({}, state, {
                editMember: action.payload
            } as ICompanyState);
        }
        case actions.CompanyTypes.CREATE_COMPANY_ADMIN_STATUS: {
            return Object.assign({}, state, {
                createCompanyAdminStatus: action.payload
            } as ICompanyState);
        }
        case actions.CompanyTypes.SET_COMPANY_USERS: {
            return Object.assign({}, state, {
                members: action.payload
            } as ICompanyState);
        }
        case actions.CompanyTypes.SEARCH_COMPANY_USERS_STATUS: {
            return Object.assign({}, state, {
                searchCompanyMembersStatus: action.payload
            } as ICompanyState);
        }
        case actions.CompanyTypes.UPDATE_COMPANY_TIMELINE_STATUS: {
            return Object.assign({}, state, {
                updateCompanyTimelineStatus: action.payload
            } as ICompanyState);
        }
        case actions.CompanyTypes.SET_COMPANIES: {
            return Object.assign({}, state, {
                companies: action.payload
            } as ICompanyState);
        }
        case actions.CompanyTypes.SET_VIEWING_COMPANY: {
            return Object.assign({}, state, {
                viewingCompany: action.payload
            } as ICompanyState);
        }

        case actions.CompanyTypes.SAVE_COMPANIES_STATUS: {
            return Object.assign({}, state, {
                saveCompanyStatus: action.payload
            } as ICompanyState);
        }

        case actions.CompanyTypes.GET_COMPANIES_STATUS: {
            return Object.assign({}, state, {
                getCompaniesStatus: action.payload
            } as ICompanyState);
        }

        case actions.CompanyTypes.GET_COMPANY_STATUS: {
            return Object.assign({}, state, {
                getCompanyStatus: action.payload
            } as ICompanyState);
        }

        case actions.CompanyTypes.DELETE_COMPANY_STATUS: {
            return Object.assign({}, state, {
                deleteCompanyStatus: action.payload
            } as ICompanyState);
        }

        case actions.CompanyTypes.SET_COMPANY_PROPERTIES: {
            return Object.assign({}, state, {
                properties: action.payload
            } as ICompanyState);
        }

        default: {
            return state;
        }
    }
}
