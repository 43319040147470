import { AxiosResponse, AxiosPromise } from "axios";
import { api, BASE_URL } from "../index";
import {
    ICompanyContact,
    IPagingParams,
    IPagingResponse,
    IAddress
} from "../_types";

export interface ICompanyContactsGetListResponse {
    paging: IPagingResponse;
    contacts: ICompanyContact[];
}

export default {
    getContacts: (
        companyId: string,
        paging: IPagingParams,
        textSearch?: string
    ): AxiosPromise<AxiosResponse<ICompanyContactsGetListResponse>> => {
        return api.get(`${BASE_URL}companycontacts/list/${companyId}`, {
            params: {
                textSearch,
                itemsPerPage: paging.itemsPerPage || 10,
                currentPage: paging.currentPage
            }
        });
    },
    createContact: (
        companyId: string,
        companyContact: ICompanyContact
    ): AxiosPromise<AxiosResponse<ICompanyContact>> => {
        return api.post(
            `${BASE_URL}companycontacts/${companyId}`,
            companyContact
        );
    },
    editContact: (
        companyId: string,
        companyContactId: string,
        payload: {
            name?: string;
            email?: string;
            tel?: string;
            address?: IAddress;
        }
    ): AxiosPromise<AxiosResponse<{ success: boolean }>> => {
        return api.patch(
            `${BASE_URL}companycontacts/${companyId}/${companyContactId}`,
            payload
        );
    },
    deleteContact: (
        companyId: string,
        contactId: string
    ): AxiosPromise<AxiosResponse<{ success: boolean }>> => {
        return api.delete(
            `${BASE_URL}companycontacts/${companyId}/${contactId}`
        );
    }
};
