import * as actions from "../../actions/property";
import { AsyncActionState } from "../../utils/asyncAction";
import {
    IProperty,
    IUser,
    IPropertyOffer,
    IPropertyViewing,
    IPropertyAskingPrice,
    IMIReportResponse,
    IMIKeyStatsResponse
} from "../../../api/_types";
import { IGetPropertiesResponse } from "../../../api/modules/property";

export const setProperties = (properties: IGetPropertiesResponse) => {
    return {
        type: actions.PropertyTypes.SET_PROPERTIES,
        payload: properties
    } as actions.SetProperties;
};

export const setViewingProperty = (property?: IProperty) => {
    return {
        type: actions.PropertyTypes.SET_VIEWING_PROPERTY,
        payload: property
    } as actions.SetViewingProperty;
};

export const setCreatePropertyStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.CREATE_PROPERTIES_STATUS,
        payload: status
    } as actions.CreatePropertyStatus;
};

export const setDeletePropertyStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.DELETE_PROPERTIES_STATUS,
        payload: status
    } as actions.DeletePropertyStatus;
};

export const setGetPropertiesStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.GET_PROPERTIES_STATUS,
        payload: status
    } as actions.GetPropertiesStatus;
};

export const setGetPropertyStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.GET_PROPERTY_STATUS,
        payload: status
    } as actions.GetPropertyStatus;
};

export const setAddPropertyViewingStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.ADD_PROPERTY_VIEWING_STATUS,
        payload: status
    } as actions.AddPropertyViewingStatus;
};

export const setRemovePropertyViewingStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.REMOVE_PROPERTY_VIEWING_STATUS,
        payload: status
    } as actions.RemovePropertyViewingStatus;
};

export const setSaveMIStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.SAVE_MI_STATUS,
        payload: status
    } as actions.SaveMIStatus;
};

export const setAddMIAskingPriceStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.ADD_MI_ASKING_PRICE_STATUS,
        payload: status
    } as actions.AddMIAskingPriceStatus;
};

export const setRemoveMIAskingPriceStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.REMOVE_MI_ASKING_PRICE_STATUS,
        payload: status
    } as actions.RemoveMIAskingPriceStatus;
};

export const setUpdatePropertyTimelineStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.UPDATE_PROPERTY_TIMELINE_STATUS,
        payload: status
    } as actions.UpdatePropertyTimelineStatus;
};

export const setCreatePropertyVendorRef = (user: IUser) => {
    return {
        type: actions.PropertyTypes.SET_CREATE_PROPERTY_VENDOR_REF,
        payload: user
    } as actions.SetCreatePropertyVendorRef;
};

export const resetCreatePropertyVendorRef = () => {
    return {
        type: actions.PropertyTypes.SET_CREATE_PROPERTY_VENDOR_REF,
        payload: undefined
    } as actions.SetCreatePropertyVendorRef;
};

export const setAddPropertyOfferStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.SET_ADD_PROPERTY_OFFER_STATUS,
        payload: status
    } as actions.SetAddPropertyOfferStatus;
};

export const setRemovePropertyOfferStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.SET_REMOVE_PROPERTY_OFFER_STATUS,
        payload: status
    } as actions.SetRemovePropertyOfferStatus;
};

export const setUpdatePropertyOfferStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.SET_UPDATE_PROPERTY_OFFER_STATUS,
        payload: status
    } as actions.SetUpdatePropertyOfferStatus;
};

export const setPropertyOfferToEdit = (offer: IPropertyOffer) => {
    return {
        type: actions.PropertyTypes.SET_PROPERTY_OFFER_TO_EDIT,
        payload: offer
    } as actions.SetPropertyOfferToEdit;
};

export const setUpdatePropertyViewingStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.SET_UPDATE_PROPERTY_VIEWING_STATUS,
        payload: status
    } as actions.SetUpdatePropertyViewingStatus;
};

export const setPropertyViewingToEdit = (viewing: IPropertyViewing) => {
    return {
        type: actions.PropertyTypes.SET_PROPERTY_VIEWING_TO_EDIT,
        payload: viewing
    } as actions.SetPropertyViewingToEdit;
};

export const setUpdatePropertyAskingPriceStatus = (
    status: AsyncActionState
) => {
    return {
        type: actions.PropertyTypes.SET_UPDATE_PROPERTY_ASKING_PRICE_STATUS,
        payload: status
    } as actions.SetUpdatePropertyAskingPriceStatus;
};

export const setPropertyAskingPriceToEdit = (
    askingPrice: IPropertyAskingPrice
) => {
    return {
        type: actions.PropertyTypes.SET_PROPERTY_ASKING_PRICE_TO_EDIT,
        payload: askingPrice
    } as actions.SetPropertyAskingPriceToEdit;
};

export const setGetMIReportStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.GET_MI_REPORT_STATUS,
        payload: status
    } as actions.GetMIReportStatus;
};

export const setMIReportData = (miReport: IMIReportResponse) => {
    return {
        type: actions.PropertyTypes.SET_MI_REPORT_DATA,
        payload: miReport
    } as actions.SetMIReportData;
};

export const setMIReportKeyStats = (keyStats: IMIKeyStatsResponse) => {
    return {
        type: actions.PropertyTypes.SET_MI_REPORT_KEYSTATS,
        payload: keyStats
    } as actions.SetMIReportKeyStats;
};

export const setUpdatePropertyContactsStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.SET_UPDATE_PROPERTY_CONTACTS_STATUS,
        payload: status
    };
};

export const setResetPropertySaleStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.SET_RESET_PROPERTY_SALE_STATUS,
        payload: status
    } as actions.ResetPropertySaleStatus;
};

export const setRestorePropertySaleStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.SET_RESTORE_PROPERTY_SALE_STATUS,
        payload: status
    } as actions.RestorePropertySaleStatus;
};

export const setDeleteRestorePropertySaleStatus = (
    status: AsyncActionState
) => {
    return {
        type: actions.PropertyTypes.SET_DELETE_RESTORE_PROPERTY_SALE_STATUS,
        payload: status
    } as actions.DeleteRestorePropertySaleStatus;
};

export const setMiPdfStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.SEND_MI_PDF_STATUS,
        payload: status
    } as actions.SendMiPdfStatus;
};

export const setDeletePropertyPhotoStatus = (status: AsyncActionState) => {
    return {
        type: actions.PropertyTypes.DELETE_PROPERTY_PHOTO_STATUS,
        payload: status
    } as actions.DeletePropertyPhotoStatus;
};
