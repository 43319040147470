import moment from "moment";
import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { IPagingResponse, IProperty, IUser } from "../../../../../../api/_types";
import {
    hasLeaseHoldWarning,
    hasVendorMortgageBrokerWarning,
    hasVendorSolicitorWarning
} from "../../../../../../lib/core/warnings";
import routes, { IRouteDefinition } from "../../../../../../navigation/routes";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import { ChainStatus } from "../../../../../../redux/_types/property/types";
import ChainIcon from "../../../../../_sharedComponents/Chain";
import DataTable from "../../../../../_sharedComponents/DataTable";
import { IDataTableField } from "../../../../../_sharedComponents/DataTable/types";
import PropertyThumb from "../../containers/PropertyList/PropertyThumb";
import WarningButton from "./WarningButton";

interface IProps {
    type: "company" | "branch";
    properties: IProperty[];
    paging: IPagingResponse;
    getAsyncStatus: AsyncActionState;
    goToBranch: (branch: any) => void;
    setViewWarningProperty: (property: IProperty) => void;
    setViewOtherWarningProperty: (property: IProperty) => void;
    navigateToPage: (
        propertyId: string,
        branchId: string,
        route: IRouteDefinition
    ) => void;
    onPagerClick: ((pageIndex: number) => void)
    updateItemsPerPage:  ((itemsPerPage: number) => void)
}

const PropertyTable:FC<IProps> = ({getAsyncStatus, paging, properties, type, goToBranch, navigateToPage, setViewWarningProperty, setViewOtherWarningProperty, onPagerClick, updateItemsPerPage}) => {

    let tableFields: IDataTableField[] = [

        {
            field: "logo",
            displayName: " ",
            mutator: (item: IProperty) => <PropertyThumb property={item}/>
        },
        {
            field: "address",
            displayName: "Property",
            mutator: (item: IProperty) =>`${item.address.line1}-${item.address.postcode}`
        },
        {
            field: "mi.chain",
            displayName: "Chain",
            mutator: (item: IProperty) =>
                <div className="text-center">
                    {(item.mi?.chain === ChainStatus.CompletedChain || item.mi?.chain === ChainStatus.IncompleteChain) &&
                        <ChainIcon size={30} title="This property is in a chain." fill={item.mi?.chain === ChainStatus.CompletedChain ? "#326036" : "#F24236"} />
                    }
                </div>
        },
        {
            field: "viewings",
            displayName: "Viewings",
            mutator: (item: IProperty) => item.viewings.length
        },
        {
            field: "offers",
            displayName: "Offers",
            mutator: (item: IProperty) => item.offers.length
        },
        {
            field: "mi.askingPrice",
            displayName: "Asking price",
            mutator: (item: any) =>
                item.mi.askingPrice.length
                    ? `£${item.mi.askingPrice[0].price.toLocaleString()}`
                    : ""
        },
        {
            field: "mi.listed",
            displayName: "Take on",
            mutator: (item: IProperty) => {
                return (
                    <>
                        {item?.mi?.listed !== undefined && (
                            <span>
                                {moment(item.mi.listed).format("ll")}
                            </span>
                        )}
                    </>
                );
            }
        },
        {
            field: "owner",
            displayName: "Using app?",
            mutator: (item: IProperty) => (item?.owner as IUser).lastLogin ? "Yes" : "No"
        },
        {
            field: "",
            displayName: "Warnings",
            mutator: (item: IProperty) => {
                let warningCount = 0;
                let hasRedAlert:boolean = false;

                item.timeline?.data.forEach((x) => {
                    const warningDate = x.warningDate;
                    if (warningDate !== undefined && moment(warningDate).isBefore(moment())) {
                        warningCount++;
                        if (x?.surveyData.awaitingReports === false) {
                            hasRedAlert = true;
                        }
                    }
                });

                return (
                    <WarningButton
                        property={item}
                        hasRedAlert={hasRedAlert}
                        warningCount={warningCount}
                        onClick={setViewWarningProperty}
                    />
                );
            }
        },
        {
            field: "",
            displayName: "Setup warnings",
            mutator: (item: IProperty) => {

                let count = 0;

                if (hasLeaseHoldWarning(item).result === true) {
                    count++;
                }
                if (hasVendorMortgageBrokerWarning(item).result === true) {
                    count++;
                }
                if (hasVendorSolicitorWarning(item).result === true) {
                    count++;
                }

                return <WarningButton
                    property={item}
                    hasRedAlert={true}
                    warningCount={count}
                    onClick={setViewOtherWarningProperty}
                />
            }

        }
    ];

    if (type === "company") {
        tableFields.splice(1, 0, {
            field: "branch",
            displayName: "Branch",
            //@ts-ignore
            mutator: (item: any) => (
                <Button
                    variant="link"
                    style={{ textAlign: "left" }}
                    onClick={() => goToBranch(item.branch)}
                >
                    {item.branch.name}
                </Button>
            )
        });
    }

    return (
        <DataTable
            data={properties}
            showPager={true}
            pagingElement={paging}
            onPagerPageClick={onPagerClick}
            onItemsPerPageChange={updateItemsPerPage}
            showLoadingSpinner={
                getAsyncStatus === AsyncActionState.Pending
            }
            fields={tableFields}
            actions={[
                {
                    btnTitle: "Manage",
                    btnVariant: "light",
                    action: (dataItem) => {
                        navigateToPage(
                            dataItem._id,
                            dataItem.branch._id,
                            routes.propertyEdit
                        );
                    }
                }
            ]}
        />
    );
};

export default PropertyTable;
