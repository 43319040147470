import * as actions from "../../actions/user";
import { AsyncActionState } from "../../utils/asyncAction";
import { IUser, ISearchGYAAdminsResponse } from "../../../api/_types";
import { createPager } from "../../../lib/utils/pager";

export interface IUserState {
    me?: IUser;
    editUser?: IUser;
    editUserStatus: AsyncActionState;
    getMeStatus: AsyncActionState;
    updateMeStatus: AsyncActionState;
    searchVendorStatus: AsyncActionState;
    vendorSearchResults: IUser[];
    activateAccountStatus: AsyncActionState;
    searchGYAAdminUsersStatus: AsyncActionState;
    gyas: ISearchGYAAdminsResponse;
    createGYAAdminStatus: AsyncActionState;
    deleteMeStatus: AsyncActionState;
    deletePhotoStatus: AsyncActionState;
    resendEmailVerificationStatus: AsyncActionState;
}

const initialState: IUserState = {
    me: undefined,
    editUser: undefined,
    editUserStatus: AsyncActionState.Reset,
    getMeStatus: AsyncActionState.Reset,
    updateMeStatus: AsyncActionState.Reset,
    searchVendorStatus: AsyncActionState.Reset,
    vendorSearchResults: [],
    activateAccountStatus: AsyncActionState.Reset,
    searchGYAAdminUsersStatus: AsyncActionState.Reset,
    gyas: {
        paging: createPager({ itemsPerPage: 10 }),
        users: []
    },
    createGYAAdminStatus: AsyncActionState.Reset,
    deleteMeStatus: AsyncActionState.Reset,
    deletePhotoStatus: AsyncActionState.Reset,
    resendEmailVerificationStatus: AsyncActionState.Reset
};

export default function userReducer(
    state: IUserState = initialState,
    action:
        | actions.GetMeStatus
        | actions.SetMe
        | actions.UpdateMeStatus
        | actions.SearchVendorsStatus
        | actions.SetSearchVendorsResult
        | actions.SetActivateAccountStatus
        | actions.SetSearchGYAAdminSearchStatus
        | actions.SetSearchGYAdminUsers
        | actions.SetCreateGYAAdminStatus
        | actions.SetEditUser
        | actions.SetEditUserUpdateStatus
        | actions.DeleteMeStatus
        | actions.DeleteUserPhotoStatus
        | actions.ResendEmailVerificationStatus
) {
    switch (action.type) {
        case actions.UserAccountTypes.DELETE_USER_PHOTO_STATUS: {
            return Object.assign({}, state, {
                deletePhotoStatus: action.payload
            } as IUserState);
        }
        case actions.UserAccountTypes.DELETE_ME_STATUS: {
            return Object.assign({}, state, {
                deleteMeStatus: action.payload
            } as IUserState);
        }
        case actions.UserAccountTypes.SET_EDIT_USER: {
            return Object.assign({}, state, {
                editUser: action.payload
            } as IUserState);
        }
        case actions.UserAccountTypes.SET_EDIT_USER_UPDATE_STATUS: {
            return Object.assign({}, state, {
                editUserStatus: action.payload
            } as IUserState);
        }
        case actions.UserAccountTypes.CREATE_GYA_ADMIN_STATUS: {
            return Object.assign({}, state, {
                createGYAAdminStatus: action.payload
            } as IUserState);
        }
        case actions.UserAccountTypes.SEARCH_GYA_ADMIN_USERS_STATUS: {
            return Object.assign({}, state, {
                searchGYAAdminUsersStatus: action.payload
            } as IUserState);
        }

        case actions.UserAccountTypes.SET_GYA_ADMIN_USERS: {
            return Object.assign({}, state, {
                gyas: action.payload
            } as IUserState);
        }

        case actions.UserAccountTypes.SET_ACTIVATE_ACCOUNT_STATUS: {
            return Object.assign({}, state, {
                activateAccountStatus: action.payload
            } as IUserState);
        }
        case actions.UserAccountTypes.SET_SEARCH_VENDORS_RESULT: {
            return Object.assign({}, state, {
                vendorSearchResults: action.payload
            } as IUserState);
        }
        case actions.UserAccountTypes.SEARCH_VENDORS_STATUS: {
            return Object.assign({}, state, {
                searchVendorStatus: action.payload
            } as IUserState);
        }
        case actions.UserAccountTypes.SET_ME: {
            return Object.assign({}, state, {
                me: action.payload
            } as IUserState);
        }
        case actions.UserAccountTypes.GET_ME_STATUS: {
            return Object.assign({}, state, {
                getMeStatus: action.payload
            } as IUserState);
        }
        case actions.UserAccountTypes.SAVE_ME_STATUS: {
            return Object.assign({}, state, {
                updateMeStatus: action.payload
            } as IUserState);
        }
        case actions.UserAccountTypes.RESEND_EMAIL_VERIFICATION_STATUS: {
            return Object.assign({}, state, {
                resendEmailVerificationStatus: action.payload
            } as IUserState);
        }

        default: {
            return state;
        }
    }
}
