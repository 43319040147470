import React, { FunctionComponent, ReactNode } from "react";
import FeedbackAlert from "../FeedbackAlert";

interface IFeedbackProps {
    show: boolean;
    heading: string;
    message: string;
    customContent?: any;
    onClose?: () => void;
}
interface IProps {
    elements: ReactNode;
    successFeedback?: IFeedbackProps;
    errorFeedback?: IFeedbackProps;
}

const renderFeedbackAlert = (params: {
    variant: "success" | "danger";
    feedbackItem: IFeedbackProps;
}) => (
    <div
        style={{
            marginTop: 10,
            marginBottom: 10
        }}
    >
        <FeedbackAlert
            variant={params.variant}
            heading={params.feedbackItem.heading}
            message={params.feedbackItem.message}
            customContent={params.feedbackItem.customContent}
            onClose={params.feedbackItem.onClose}
        />
    </div>
);

const ActionBar: FunctionComponent<IProps> = function(props) {
    const { children, elements, successFeedback, errorFeedback } = props;

    return (
        <div>
            {children}
            <div>
                {successFeedback !== undefined &&
                    successFeedback.show === true &&
                    renderFeedbackAlert({
                        variant: "success",
                        feedbackItem: successFeedback
                    })}

                {errorFeedback !== undefined &&
                    errorFeedback.show === true &&
                    renderFeedbackAlert({
                        variant: "danger",
                        feedbackItem: errorFeedback
                    })}

                {elements}
            </div>
        </div>
    );
};

export default ActionBar;
