import React, { Component } from "react";
import { connect } from "react-redux";
import { ICompanyContactPOC } from "../../../../../../api/_types";
import {
    setViewingCompanyContactPOC,
    resetEditCompanyContactPOCStatus
} from "../../../../../../redux/acreators/companyContactPOCs";
import { editCompanyContactPOC } from "../../../../../../redux/api/companyContactPOCs";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import HorizontalTabNabSubPage from "../../../../../_sharedComponents/HorizontalTabNavSubPage";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import CreateContactPOCForm from "../../components/CreateContactPOCForm";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import { Button } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";

interface IProps {
    poc?: ICompanyContactPOC;
    editCompanyContactPOCStatus: AsyncActionState;
    editCompanyContactPOC: (payload: {
        name?: string;
        tel?: string;
        email?: string;
        note?: string;
    }) => void;
    setViewingCompanyContactPOC: (poc?: ICompanyContactPOC) => void;
    resetEditCompanyContactPOCStatus: () => void;
}

interface IState {
    poc: ICompanyContactPOC;
    dirty: boolean
}

class EditContactPOCFormContainer extends Component<
    IProps & RouteComponentProps,
    IState
> {
    generateStatePOC = () => {
        return Object.assign({}, this.props.poc);
    };

    state: IState = {
        poc: this.generateStatePOC(),
        dirty: false
    };

    componentDidMount() {
        const { history, resetEditCompanyContactPOCStatus } = this.props;
        history.listen(() => {
            resetEditCompanyContactPOCStatus();
        });
    }

    componentDidUpdate (prevProps: IProps) {
        if (prevProps.editCompanyContactPOCStatus === AsyncActionState.Pending && this.props.editCompanyContactPOCStatus === AsyncActionState.Success) {
            this.setState({
                dirty: false
            });
        }
    }

    handleGoBack = () => {
        this.props.setViewingCompanyContactPOC(undefined);
        this.props.resetEditCompanyContactPOCStatus();
    };

    handleSave = () => {
        const { poc } = this.state;
        const payload = Object.assign(
            {},
            {
                name: poc.name,
                email: poc.email,
                tel: poc.tel,
                note: poc.note,
            }
        );

        this.props.editCompanyContactPOC(payload);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(this, this.state, stateField, "poc");
    };

    render() {
        const {
            editCompanyContactPOCStatus,
            resetEditCompanyContactPOCStatus
        } = this.props;
        const {dirty} = this.state;

        return (
            <HorizontalTabNabSubPage
                loading={
                    this.props.poc === undefined ||
                    editCompanyContactPOCStatus === AsyncActionState.Pending
                }
                goBack={this.handleGoBack}
            >
                <NavigationPrompt when={dirty === true} />

                <FormContainer
                    actions={
                        <Button variant="primary" onClick={this.handleSave}>
                            Save
                        </Button>
                    }
                    showSuccess={
                        editCompanyContactPOCStatus === AsyncActionState.Success
                    }
                    showError={
                        editCompanyContactPOCStatus === AsyncActionState.Error
                    }
                    success={{
                        heading: "Contact updated",
                        message: "Your changes have been saved"
                    }}
                    error={{
                        heading: "An error occurred",
                        message: "Please try again"
                    }}
                    onFeedbackMessageClose={resetEditCompanyContactPOCStatus}
                >
                    <CreateContactPOCForm
                        poc={this.state.poc}
                        onChangeInputField={this.onChangeInputField}
                    />
                </FormContainer>
            </HorizontalTabNabSubPage>
        );
    }
}

const mapStateToProps = ({ companyContactPOCs }: IStore) => ({
    poc: companyContactPOCs.viewingContactPOC,
    editCompanyContactPOCStatus: companyContactPOCs.editCompanyContactPOCStatus
});

export default connect(
    mapStateToProps,
    {
        editCompanyContactPOC,
        setViewingCompanyContactPOC,
        resetEditCompanyContactPOCStatus
    }
)(withRouter<any, any>(EditContactPOCFormContainer));
