import React, { Component } from "react";
import SideBarNav from "./SideBarNav/SideBarNav";
import UserPanel from "./UserPanel/UserPanel";
import "./SideBar.css"; // Brought into a CSS so we can use Media Queries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronCircleLeft,
    faChevronCircleRight
} from "@fortawesome/free-solid-svg-icons";
import { connect, ConnectedProps } from "react-redux";
import { IStore } from "../../../../redux/store";
import cdn from "../../../../api/modules/cdn";



const mapStateToProps = ({company}: IStore) => ({
    companyLogo: company.viewingCompany?.logo,
    companyBranding: company.viewingCompany?.branding
});

const connector = connect(mapStateToProps)

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {
    hideSidebarNav?: boolean;
    hideAll?: boolean;
    toggleMenu?: () => void;
    menuOpen?: boolean;
    hideToggle?: boolean;
}

class SideBar extends Component<IProps> {
    render() {
        const {
            hideSidebarNav = false,
            hideAll,
            hideToggle = false,
            companyBranding = {brandColour: "rgb(21, 30, 74)"},
            companyLogo
        } = this.props;
        return (
            <div className="SideBar" style={{ width: 280, position: "fixed", backgroundColor: companyBranding.brandColour    }}>
                {hideToggle === false && (
                    <div
                        className={"d-flex justify-content-end mb-3 align-items-center"}
                        onClick={this.props.toggleMenu}
                        style={{cursor: "pointer"}}
                    >
                        <FontAwesomeIcon
                            icon={
                                this.props.menuOpen === true
                                    ? faChevronCircleLeft
                                    : faChevronCircleRight
                            }
                            size="1x"
                            color="#f3f3f3"
                            style={{ marginLeft: 15 }}
                        />
                    </div>
                )}
                {hideAll !== true && (
                    <div>

                        {
                            companyLogo !== undefined && (
                                <div
                                    style={{
                                        padding: 2,
                                        backgroundColor: "#f3f3f3",
                                        width: "auto",
                                        display: "inline-block"
                                    }}
                                     className={"mb-3"}
                                >
                                    <img
                                        className="mx-auto img-fluid"
                                        src={cdn.getObjectURL(companyLogo.Key)}
                                        alt="Company logo"
                                        style={{
                                            maxHeight: 180,
                                        }}
                                    />
                                </div>
                            )
                        }

                        <div className={"mb-3"}>
                            <UserPanel/>
                        </div>
                        {hideSidebarNav === false && <SideBarNav />}
                    </div>
                )}
            </div>
        );
    }
}


export default connector(SideBar);
