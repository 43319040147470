import React from "react";
import { IDataTableAction, IDataTableField } from "../types";
import { calcHorizotalStickyleft, ColumnWidthState } from "../utils";

interface ITHeadProps {
    fields: IDataTableField[];
    small?: boolean;
    centered?: boolean;
    actions?: IDataTableAction[];
    stickyHorizontalCols?: number;
    columnWidths: ColumnWidthState;
}

export default function DataTableTHead(props: ITHeadProps) {
    const {
        fields,
        actions,
        small = false,
        centered = false,
        columnWidths
    } = props;
    let tdClasses = "";
    if (centered === true) {
        // tdClasses += "text-center";
    }

    return (
        <tr>
            {fields.map((field, index) => {
                let style = Object.assign({}, field.thStyle || {});
                if (
                    props.stickyHorizontalCols !== undefined &&
                    index <= props.stickyHorizontalCols
                ) {
                    style.position = "sticky";
                    style.left = calcHorizotalStickyleft(index, columnWidths);
                    style.zIndex = 13;
                }

                return (
                    <th
                        className={tdClasses}
                        key={`thr-${index}`}
                        colSpan={field.colspan}
                        style={style}
                        onClick={field.onThClick}
                    >
                        {small === true ? (
                            <span>
                                <small>
                                    {field.displayName || field.field}
                                </small>
                                {field.icon && field.icon()}
                            </span>
                        ) : (
                            <>
                                <span>{field.displayName || field.field}</span>
                                {field.icon && field.icon()}
                            </>
                        )}
                    </th>
                );
            })}
            {actions !== undefined && actions.length > 0 && (
                <th className={tdClasses}>Actions</th>
            )}
        </tr>
    );
}
