import React, { Component } from "react";
import { connect } from "react-redux";
import { IPropertyViewing } from "../../../../../../api/_types";
import {
    addPropertyViewing,
    resetAddPropertyViewing
} from "../../../../../../redux/api/property";
import { IStore } from "../../../../../../redux/store";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import CreateViewingForm from "../../components/CreateViewingForm";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";
import { RouteComponentProps, withRouter } from "react-router-dom";
import routes from "../../../../../../navigation/routes";
import { roundTimeToMinutes } from "../../../../../../lib/utils/dates";

interface IProps {
    addPropertyViewingStatus: AsyncActionState;
    addPropertyViewing: (viewing: IPropertyViewing) => void;
    resetAddPropertyViewing: () => void;
}
interface IState {
    viewing: IPropertyViewing;
    dirty: boolean;
}

class CreateViewingFormContainer extends Component<
    IProps & RouteComponentProps,
    IState
> {
    private readonly defaultViewing: IPropertyViewing = {
        when: new Date().toISOString(),
        time: roundTimeToMinutes(5)
            .rounded.toDate()
            .toISOString(),
        notes: "",
        name: ""
    };
    state: IState = {
        viewing: Object.assign({}, this.defaultViewing),
        dirty: false
    };

    componentDidUpdate(prevProps: IProps) {
        // Reset form state when a viewing has been added
        if (
            prevProps.addPropertyViewingStatus !== AsyncActionState.Success &&
            this.props.addPropertyViewingStatus === AsyncActionState.Success
        ) {
            this.setState(
                {
                    viewing: Object.assign(this.defaultViewing),
                    dirty: false
                },
                () => {
                    this.props.history.push(routes.propertyViewings.url);
                }
            );
        }
    }

    componentDidMount() {
        this.props.resetAddPropertyViewing();
    }

    componentWillUnmount() {
        this.props.resetAddPropertyViewing();
    }

    handleSave = () => {
        this.props.addPropertyViewing(this.state.viewing);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "viewing"
        );
    };

    render() {
        const { viewing, dirty } = this.state;
        const {
            addPropertyViewingStatus,
            resetAddPropertyViewing
        } = this.props;
        return (
            <>
                <NavigationPrompt when={dirty === true} />
                <LoadingContainer
                    loading={
                        addPropertyViewingStatus === AsyncActionState.Pending
                    }
                >
                    <CreateViewingForm
                        viewing={viewing}
                        save={this.handleSave}
                        onChangeInputField={this.onChangeInputField}
                        saveViewingStatus={addPropertyViewingStatus}
                        resetFormState={resetAddPropertyViewing}
                    />
                </LoadingContainer>
            </>
        );
    }
}

const mapStateToProps = ({ property }: IStore) => ({
    addPropertyViewingStatus: property.addPropertyViewingStatus
});

export default connect(
    mapStateToProps,
    { addPropertyViewing, resetAddPropertyViewing }
)(withRouter<any, any>(CreateViewingFormContainer));
