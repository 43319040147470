import React, { Component } from "react";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import { IUser } from "../../../../../../api/_types";
import { UserTypeLabels } from "../../../../../../lib/utils/user";

interface IProps {
    user?: IUser;
}
interface IState {}

class UserTypeContainer extends Component<IProps, IState> {
    render() {
        const { user } = this.props;

        if (user === undefined) {
            return null;
        }
        return <span>{UserTypeLabels[user.type]}</span>;
    }
}

const mapStateToProps = ({ user }: IStore) => ({
    user: user.me
});

export default connect(
    mapStateToProps,
    {}
)(UserTypeContainer);
