import React, { Component } from "react";
import ViewingsList from "../../containers/ViewingsList";
import CreateViewingFormContainer from "../../containers/CreateViewingForm";
import HorizontalTabs from "../../../../../_sharedComponents/HorizontalTabNav";
import EditPropertyTopNav from "../../components/EditPropertyTopNav";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import routes from "../../../../../../navigation/routes";

interface IProps {}

class Viewings extends Component<IProps & RouteComponentProps> {
    render() {
        return (
            <PageWrap>
                <EditPropertyTopNav />

                <HorizontalTabs
                    id="viewings-price-htabs"
                    defaultKey={this.props.match.url}
                    items={[
                        {
                            key: routes.propertyViewings.url,
                            title: "History",
                            content: ViewingsList
                        },
                        {
                            key: routes.propertyCreateViewing.url,
                            title: "Add Viewing",
                            content: CreateViewingFormContainer
                        }
                    ]}
                />
            </PageWrap>
        );
    }
}

export default withRouter<any, any>(Viewings);
