import { Dispatch } from "redux";
import { IStore } from "../../store";
import { validatePropertyEntities } from "../utils";
import { AsyncActionState } from "../../utils/asyncAction";
import {
    getCompanyContactsStatus,
    setCompanyContacts,
    createCompanyContactsStatus,
    setViewingCompanyContact as _setViewingCompanyContact,
    deleteCompanyContactStatus,
    editCompanyContactsStatus
} from "../../acreators/companyContacts";
import api from "../../../api";
import { IPagingParams, ICompanyContact, IAddress } from "../../../api/_types";

export const setViewingCompanyContact = (
    contact: ICompanyContact | undefined
) => _setViewingCompanyContact(contact);

export const getCompanyContacts = (
    textSearch: string,
    paging: IPagingParams = {
        itemsPerPage: 10,
        currentPage: 0
    }
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const storeState = getState();
        const companyCheck = validatePropertyEntities(storeState, {
            company: true
        });

        if (companyCheck === false) {
            return dispatch(getCompanyContactsStatus(AsyncActionState.Error));
        }

        dispatch(getCompanyContactsStatus(AsyncActionState.Pending));

        try {
            const result = await api.companyContacts.getContacts(
                companyCheck.company._id as string,
                paging,
                textSearch
            );
            dispatch(setCompanyContacts(result.data.data));
        } catch (e) {
            return dispatch(getCompanyContactsStatus(AsyncActionState.Error));
        }

        dispatch(getCompanyContactsStatus(AsyncActionState.Success));
    };
};

export const createCompanyContact = (contact: ICompanyContact) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const storeState = getState();
        const companyCheck = validatePropertyEntities(storeState, {
            company: true
        });

        if (companyCheck === false) {
            return dispatch(
                createCompanyContactsStatus(AsyncActionState.Error)
            );
        }

        dispatch(createCompanyContactsStatus(AsyncActionState.Pending));

        try {
            // Create the contact
            await api.companyContacts.createContact(
                companyCheck.company._id as string,
                contact
            );
            //Refresh list of contacts
            await getCompanyContacts("")(dispatch, getState);
        } catch (e) {
            return dispatch(
                createCompanyContactsStatus(AsyncActionState.Error)
            );
        }

        dispatch(createCompanyContactsStatus(AsyncActionState.Success));
    };
};

export const editCompanyContact = (payload: {
    name?: string;
    email?: string;
    tel?: string;
    address?: IAddress;
}) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const storeState = getState();
        const companyCheck = validatePropertyEntities(storeState, {
            company: true
        });
        const viewingContact = storeState.companyContacts.viewingContact;

        if (companyCheck === false || viewingContact === undefined) {
            return dispatch(editCompanyContactsStatus(AsyncActionState.Error));
        }

        dispatch(editCompanyContactsStatus(AsyncActionState.Pending));

        try {
            // Create the contact
            await api.companyContacts.editContact(
                companyCheck.company._id as string,
                viewingContact._id as string,
                payload
            );

            //Refresh list of contacts
            await getCompanyContacts("")(dispatch, getState);
        } catch (e) {
            return dispatch(editCompanyContactsStatus(AsyncActionState.Error));
        }

        dispatch(editCompanyContactsStatus(AsyncActionState.Success));
    };
};

export const deleteCompanyContact = (contactId: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const storeState = getState();
        const companyCheck = validatePropertyEntities(storeState, {
            company: true
        });

        if (companyCheck === false) {
            return dispatch(deleteCompanyContactStatus(AsyncActionState.Error));
        }

        dispatch(deleteCompanyContactStatus(AsyncActionState.Pending));

        try {
            // Delete the POC
            await api.companyContacts.deleteContact(
                companyCheck.company._id as string,
                contactId
            );

            //Refresh list of contacts
            await getCompanyContacts("")(dispatch, getState);
        } catch (e) {
            return dispatch(deleteCompanyContactStatus(AsyncActionState.Error));
        }

        dispatch(deleteCompanyContactStatus(AsyncActionState.Success));
    };
};
