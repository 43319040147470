import React, { Component } from "react";
import EditCompanyTopNav from "../../components/EditCompanyTopNav";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import { IUser, ICompany } from "../../../../../../api/_types";
import MIReportContainer from "../../containers/MIReport";

interface IProps {
    user?: IUser;
    company?: ICompany;
}

interface IState {}

class MIReportScreen extends Component<IProps, IState> {
    render() {
        const { company } = this.props;
        if (
            company === undefined ||
            company === null ||
            company._id === undefined
        ) {
            return null;
        }
        return (
            <PageWrap>
                <EntityTitle entityName={company.name} />
                <EditCompanyTopNav />
                <MIReportContainer type="company" />
            </PageWrap>
        );
    }
}

const mapStateToProps = ({ user, company }: IStore) => ({
    user: user.me,
    company: company.viewingCompany
});

export default connect(
    mapStateToProps,
    {}
)(MIReportScreen);
