import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import api from "../../../api";
import property, {
    MIReportSortOption,
    PriceOption
} from "../../../api/modules/property";
import {
    IBranch,
    IPagingParams,
    IProperty,
    IPropertyAskingPrice,
    IPropertyContacts,
    IPropertyGetListFilters,
    IPropertyManagementInformation,
    IPropertyOffer,
    IPropertyOfferPayload,
    IPropertyTimelineEntity,
    IPropertyViewing,
    IUser,
    IWorkflowFilters,
    PropertySortOptionType
} from "../../../api/_types";
import { IVisibleGroups } from "../../../components/modules/core/company/containers/MIReport";
import { createPager } from "../../../lib/utils/pager";
import * as acreators from "../../acreators/property";
import { IStore } from "../../store";
import { AsyncActionState } from "../../utils/asyncAction";
import { getBranchById } from "../branch";
import { getCompanyById } from "../company";

export const getProperties = (
    query: IPagingParams = { currentPage: 0, itemsPerPage: 10 },
    textSearch?: string,
    filters?: IPropertyGetListFilters,
    sortOption: PropertySortOptionType = "default"
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch } = getState();

        if (
            company.viewingCompany === undefined ||
            branch.viewingBranch === undefined
        ) {
            // @Todo handle error
            return;
        }
        dispatch(acreators.setGetPropertiesStatus(AsyncActionState.Pending));

        try {
            const res = await api.property.getProperties(
                branch.viewingBranch._id as string,
                company.viewingCompany._id as string,
                query,
                textSearch,
                filters,
                sortOption,
            );
            dispatch(acreators.setProperties(res.data));
            dispatch(
                acreators.setGetPropertiesStatus(AsyncActionState.Success)
            );
        } catch (e) {
            dispatch(acreators.setGetPropertiesStatus(AsyncActionState.Error));

            console.log("Error getting properties", e);
        }
    };
};

export const getPropertyById = (_id: string, branchId?: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();
        let branch: IBranch | undefined;

        if (company.viewingCompany === undefined) {
            // @Todo handle error
            return;
        }

        dispatch(acreators.setGetPropertyStatus(AsyncActionState.Pending));

        if (branchId !== undefined) {
            try {
                await getBranchById(branchId, company.viewingCompany
                    ._id as string)(dispatch, getState);
            } catch (e) {
                return dispatch(
                    acreators.setGetPropertyStatus(AsyncActionState.Error)
                );
            }
        }

        branch = getState().branch.viewingBranch;

        if (branch === undefined) {
            return dispatch(
                acreators.setGetPropertyStatus(AsyncActionState.Error)
            );
        }

        try {
            const property = await api.property.getPropertyById(
                _id,
                branch._id as string,
                company.viewingCompany._id as string
            );

            if (
                property.data.data === undefined ||
                property.data.data === null
            ) {
                alert("No property found.");
                return;
            }

            dispatch(
                acreators.setViewingProperty(
                    (property as any).data.data.property
                )
            );
            dispatch(acreators.setGetPropertyStatus(AsyncActionState.Success));
        } catch (e) {
            console.log("Error getting property", e);
            dispatch(acreators.setGetPropertyStatus(AsyncActionState.Error));
        }
    };
};

export const deleteProperty = (propertyId: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch } = getState();

        if (
            company.viewingCompany === undefined ||
            branch.viewingBranch === undefined
        ) {
            return dispatch(
                acreators.setDeletePropertyStatus(AsyncActionState.Error)
            );
        }

        dispatch(acreators.setDeletePropertyStatus(AsyncActionState.Pending));
        if (company.viewingCompany === undefined) {
            return dispatch(
                acreators.setDeletePropertyStatus(AsyncActionState.Error)
            );
        }
        try {
            await api.property.deleteProperty(
                propertyId,
                branch.viewingBranch._id as string,
                company.viewingCompany._id as string
            );

            dispatch(
                acreators.setDeletePropertyStatus(AsyncActionState.Success)
            );
            // Go refresh the list of propertyes
            await getProperties()(dispatch, getState);
        } catch (error) {
            dispatch(acreators.setDeletePropertyStatus(AsyncActionState.Error));
        }
    };
};

export const saveProperty = (property: IProperty, file?: File) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch } = getState();

        if (
            company.viewingCompany === undefined ||
            branch.viewingBranch === undefined
        ) {
            return dispatch(
                acreators.setCreatePropertyStatus(AsyncActionState.Error)
            );
        }

        dispatch(acreators.setCreatePropertyStatus(AsyncActionState.Pending));
        const apiMethod =
            property._id === undefined
                ? api.property.createProperty
                : api.property.editProperty;

        try {
            const apiResult: AxiosResponse<{
                data: IProperty;
            }> = await apiMethod(
                property,
                company.viewingCompany._id as string,
                branch.viewingBranch._id as string,
                file
            );

            let propertyId;

            if (property._id !== undefined) {
                propertyId = property._id;
            } else {
                propertyId = apiResult.data.data._id;
            }

            await getPropertyById(propertyId as string)(dispatch, getState);

            dispatch(
                acreators.setCreatePropertyStatus(AsyncActionState.Success)
            );
        } catch (error) {
            dispatch(acreators.setCreatePropertyStatus(AsyncActionState.Error));
        }
    };
};

export const addPropertyViewing = (viewing: IPropertyViewing) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch, property } = getState();
        if (
            company.viewingCompany === undefined ||
            company.viewingCompany._id === undefined
        ) {
            return dispatch(
                acreators.setAddPropertyViewingStatus(AsyncActionState.Error)
            );
        }
        if (
            branch.viewingBranch === undefined ||
            branch.viewingBranch._id === undefined
        ) {
            return dispatch(
                acreators.setAddPropertyViewingStatus(AsyncActionState.Error)
            );
        }
        if (
            property.viewingProperty === undefined ||
            property.viewingProperty._id === undefined
        ) {
            return dispatch(
                acreators.setAddPropertyViewingStatus(AsyncActionState.Error)
            );
        }
        const companyId = company.viewingCompany._id;
        const branchId = branch.viewingBranch._id;
        const propertyId = property.viewingProperty._id;

        dispatch(
            acreators.setAddPropertyViewingStatus(AsyncActionState.Pending)
        );

        try {
            await api.property.addPropertyViewing(
                viewing,
                propertyId,
                branchId,
                companyId
            );

            await getPropertyById(propertyId)(dispatch, getState);

            dispatch(
                acreators.setAddPropertyViewingStatus(AsyncActionState.Success)
            );
        } catch (e) {
            dispatch(
                acreators.setAddPropertyViewingStatus(AsyncActionState.Error)
            );
        }
    };
};

export const updatePropertyViewing = (viewing: IPropertyViewing) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch, property } = getState();
        if (
            company.viewingCompany === undefined ||
            branch.viewingBranch === undefined ||
            property.viewingProperty === undefined
        ) {
            return dispatch(
                acreators.setUpdatePropertyViewingStatus(AsyncActionState.Error)
            );
        }

        const companyId = company.viewingCompany._id as string;
        const branchId = branch.viewingBranch._id as string;
        const propertyId = property.viewingProperty._id as string;

        dispatch(
            acreators.setUpdatePropertyViewingStatus(AsyncActionState.Pending)
        );

        try {
            await api.property.updatePropertyViewing(
                viewing,
                companyId,
                branchId,
                propertyId
            );

            // Go refresh the property
            await getPropertyById(propertyId)(dispatch, getState);

            dispatch(
                acreators.setUpdatePropertyViewingStatus(
                    AsyncActionState.Success
                )
            );
        } catch (e) {
            dispatch(
                acreators.setUpdatePropertyViewingStatus(AsyncActionState.Error)
            );
        }
    };
};

export const removePropertyViewing = (viewingId: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch, property } = getState();
        if (
            company.viewingCompany === undefined ||
            company.viewingCompany._id === undefined
        ) {
            return dispatch(
                acreators.setRemovePropertyViewingStatus(AsyncActionState.Error)
            );
        }
        if (
            branch.viewingBranch === undefined ||
            branch.viewingBranch._id === undefined
        ) {
            return dispatch(
                acreators.setRemovePropertyViewingStatus(AsyncActionState.Error)
            );
        }
        if (
            property.viewingProperty === undefined ||
            property.viewingProperty._id === undefined
        ) {
            return dispatch(
                acreators.setRemovePropertyViewingStatus(AsyncActionState.Error)
            );
        }
        const companyId = company.viewingCompany._id;
        const branchId = branch.viewingBranch._id;
        const propertyId = property.viewingProperty._id;

        dispatch(
            acreators.setRemovePropertyViewingStatus(AsyncActionState.Pending)
        );

        try {
            await api.property.removePropertyViewing(
                viewingId,
                propertyId,
                branchId,
                companyId
            );

            // Go refresh the property
            await getPropertyById(propertyId)(dispatch, getState);

            return dispatch(
                acreators.setRemovePropertyViewingStatus(
                    AsyncActionState.Success
                )
            );
        } catch (e) {
            return dispatch(
                acreators.setRemovePropertyViewingStatus(AsyncActionState.Error)
            );
        }
    };
};

export const savePropertyMI = (miObject: IPropertyManagementInformation) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch, property } = getState();
        dispatch(acreators.setSaveMIStatus(AsyncActionState.Pending));

        if (
            company.viewingCompany === undefined ||
            company.viewingCompany._id === undefined
        ) {
            return dispatch(acreators.setSaveMIStatus(AsyncActionState.Error));
        }
        if (
            branch.viewingBranch === undefined ||
            branch.viewingBranch._id === undefined
        ) {
            return dispatch(acreators.setSaveMIStatus(AsyncActionState.Error));
        }
        if (
            property.viewingProperty === undefined ||
            property.viewingProperty._id === undefined
        ) {
            return dispatch(acreators.setSaveMIStatus(AsyncActionState.Error));
        }

        // // Process thee agency fee in both percentage form, and whole number form
        // const { agencyFee, agencyFeePercent } = getAgencyFeeFromPropertyMI(
        //     miObject,
        //     miObject.agencyFeeType
        // );
        // miObject.agencyFee = agencyFee;
        // miObject.agencyFeePercent = agencyFeePercent;

        try {
            await api.property.editPropertyMI(
                miObject,
                property.viewingProperty._id,
                branch.viewingBranch._id,
                company.viewingCompany._id
            );
        } catch (e) {
            return dispatch(acreators.setSaveMIStatus(AsyncActionState.Error));
        }

        try {
            await getPropertyById(property.viewingProperty._id)(
                dispatch,
                getState
            );
        } catch (e) {
            return dispatch(acreators.setSaveMIStatus(AsyncActionState.Error));
        }

        dispatch(acreators.setSaveMIStatus(AsyncActionState.Success));
    };
};

export const addMIAskingPrice = (
    price: number,
    effectiveDate: Date | string,
    priceOption: PriceOption
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch, property } = getState();
        dispatch(acreators.setAddMIAskingPriceStatus(AsyncActionState.Pending));

        if (
            company.viewingCompany === undefined ||
            company.viewingCompany._id === undefined
        ) {
            return dispatch(
                acreators.setAddMIAskingPriceStatus(AsyncActionState.Error)
            );
        }
        if (
            branch.viewingBranch === undefined ||
            branch.viewingBranch._id === undefined
        ) {
            return dispatch(
                acreators.setAddMIAskingPriceStatus(AsyncActionState.Error)
            );
        }
        if (
            property.viewingProperty === undefined ||
            property.viewingProperty._id === undefined
        ) {
            return dispatch(
                acreators.setAddMIAskingPriceStatus(AsyncActionState.Error)
            );
        }

        try {
            await api.property.addPropertyMIAskingPrice(
                {
                    price,
                    effectiveDate,
                    priceOption
                },
                property.viewingProperty._id,
                branch.viewingBranch._id,
                company.viewingCompany._id
            );
        } catch (e) {
            return dispatch(
                acreators.setAddMIAskingPriceStatus(AsyncActionState.Error)
            );
        }

        try {
            await getPropertyById(property.viewingProperty._id)(
                dispatch,
                getState
            );
        } catch (e) {
            return dispatch(
                acreators.setAddMIAskingPriceStatus(AsyncActionState.Error)
            );
        }

        dispatch(acreators.setAddMIAskingPriceStatus(AsyncActionState.Success));
    };
};

export const updateMIAskingPrice = (askingPrice: IPropertyAskingPrice) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch, property } = getState();
        if (
            company.viewingCompany === undefined ||
            branch.viewingBranch === undefined ||
            property.viewingProperty === undefined
        ) {
            return dispatch(
                acreators.setUpdatePropertyAskingPriceStatus(
                    AsyncActionState.Error
                )
            );
        }

        const companyId = company.viewingCompany._id as string;
        const branchId = branch.viewingBranch._id as string;
        const propertyId = property.viewingProperty._id as string;

        dispatch(
            acreators.setUpdatePropertyAskingPriceStatus(
                AsyncActionState.Pending
            )
        );

        try {
            await api.property.updatePropertyMIAskingPrice(
                askingPrice,
                companyId,
                branchId,
                propertyId
            );

            // Go refresh the property
            await getPropertyById(propertyId)(dispatch, getState);
        } catch (e) {
            dispatch(
                acreators.setUpdatePropertyAskingPriceStatus(
                    AsyncActionState.Error
                )
            );
        }

        // Now price has been updated, and property retrieved, go and update the asking price set to be edited.
        const updatedProperty = getState().property.viewingProperty;

        if (updatedProperty !== undefined && updatedProperty.mi !== undefined) {
            const updatedPrice = updatedProperty.mi.askingPrice.find(
                (item) => item._id === askingPrice._id
            );
            if (updatedPrice !== undefined) {
                dispatch(acreators.setPropertyAskingPriceToEdit(updatedPrice));
            }
        }

        dispatch(
            acreators.setUpdatePropertyAskingPriceStatus(
                AsyncActionState.Success
            )
        );
    };
};

export const removeMIAskingPrice = (askingPriceId: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch, property } = getState();
        dispatch(
            acreators.setRemoveMIAskingPriceStatus(AsyncActionState.Pending)
        );

        if (
            company.viewingCompany === undefined ||
            company.viewingCompany._id === undefined
        ) {
            return dispatch(
                acreators.setRemoveMIAskingPriceStatus(AsyncActionState.Error)
            );
        }
        if (
            branch.viewingBranch === undefined ||
            branch.viewingBranch._id === undefined
        ) {
            return dispatch(
                acreators.setRemoveMIAskingPriceStatus(AsyncActionState.Error)
            );
        }
        if (
            property.viewingProperty === undefined ||
            property.viewingProperty._id === undefined
        ) {
            return dispatch(
                acreators.setRemoveMIAskingPriceStatus(AsyncActionState.Error)
            );
        }

        try {
            await api.property.removePropertyMIAskingPrice(
                askingPriceId,
                property.viewingProperty._id,
                branch.viewingBranch._id,
                company.viewingCompany._id
            );
        } catch (e) {
            return dispatch(
                acreators.setRemoveMIAskingPriceStatus(AsyncActionState.Error)
            );
        }

        try {
            await getPropertyById(property.viewingProperty._id)(
                dispatch,
                getState
            );
        } catch (e) {
            return dispatch(
                acreators.setRemoveMIAskingPriceStatus(AsyncActionState.Error)
            );
        }

        dispatch(
            acreators.setRemoveMIAskingPriceStatus(AsyncActionState.Success)
        );
    };
};

export const updatePropertyTimeline = (
    timelineItems: IPropertyTimelineEntity[],
    completionDate?: string | Date
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch, property } = getState();

        dispatch(
            acreators.setUpdatePropertyTimelineStatus(AsyncActionState.Pending)
        );

        if (
            company.viewingCompany === undefined ||
            branch.viewingBranch === undefined ||
            property.viewingProperty === undefined
        ) {
            return dispatch(
                acreators.setUpdatePropertyTimelineStatus(
                    AsyncActionState.Error
                )
            );
        }

        // Check if completion date has been changed
        //@ts-ignore
        // eslint-disable-next-line eqeqeq
        if (completionDate !== undefined && property.viewingProperty?.completionDate != completionDate) {

            let payload = Object.assign({}, property.viewingProperty, {
                completionDate
            });
            try {
                await api.property.editProperty(payload as IProperty, company.viewingCompany?._id!, branch.viewingBranch?._id!);
            } catch (error) {
                return dispatch(
                    acreators.setUpdatePropertyTimelineStatus(
                        AsyncActionState.Error
                    )
                );
            }
        }

        try {
            await api.property.editPropertyTimeline(
                timelineItems,
                property.viewingProperty._id as string,
                branch.viewingBranch._id as string,
                company.viewingCompany._id as string
            );
        } catch (e) {
            return dispatch(
                acreators.setUpdatePropertyTimelineStatus(
                    AsyncActionState.Error
                )
            );
        }

        try {
            await getPropertyById(property.viewingProperty._id as string)(
                dispatch,
                getState
            );
        } catch (e) {
            return dispatch(
                acreators.setUpdatePropertyTimelineStatus(
                    AsyncActionState.Error
                )
            );
        }

        dispatch(
            acreators.setUpdatePropertyTimelineStatus(AsyncActionState.Success)
        );
    };
};

export const addPropertyOffer = (offer: IPropertyOffer) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch, property } = getState();
        if (
            company.viewingCompany === undefined ||
            branch.viewingBranch === undefined ||
            property.viewingProperty === undefined
        ) {
            return dispatch(
                acreators.setAddPropertyOfferStatus(AsyncActionState.Error)
            );
        }
        const companyId = company.viewingCompany._id as string;
        const branchId = branch.viewingBranch._id as string;
        const propertyId = property.viewingProperty._id as string;

        dispatch(acreators.setAddPropertyOfferStatus(AsyncActionState.Pending));

        try {
            await api.property.addPropertyOffer(
                offer,
                propertyId,
                branchId,
                companyId
            );

            await getPropertyById(propertyId)(dispatch, getState);

            return dispatch(
                acreators.setAddPropertyOfferStatus(AsyncActionState.Success)
            );
        } catch (e) {
            return dispatch(
                acreators.setAddPropertyOfferStatus(AsyncActionState.Error)
            );
        }
    };
};

export const removePropertyOffer = (offerId: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch, property } = getState();
        if (
            company.viewingCompany === undefined ||
            branch.viewingBranch === undefined ||
            property.viewingProperty === undefined
        ) {
            return dispatch(
                acreators.setRemovePropertyOfferStatus(AsyncActionState.Error)
            );
        }

        const companyId = company.viewingCompany._id as string;
        const branchId = branch.viewingBranch._id as string;
        const propertyId = property.viewingProperty._id as string;

        dispatch(
            acreators.setRemovePropertyOfferStatus(AsyncActionState.Pending)
        );

        try {
            await api.property.removePropertyOffer(
                offerId,
                propertyId,
                branchId,
                companyId
            );

            // Go refresh the property
            await getPropertyById(propertyId)(dispatch, getState);

            return dispatch(
                acreators.setRemovePropertyOfferStatus(AsyncActionState.Success)
            );
        } catch (e) {
            return dispatch(
                acreators.setRemovePropertyOfferStatus(AsyncActionState.Error)
            );
        }
    };
};

export const updatePropertyOffer = (offer: IPropertyOfferPayload) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch, property } = getState();
        if (
            company.viewingCompany === undefined ||
            branch.viewingBranch === undefined ||
            property.viewingProperty === undefined
        ) {
            return dispatch(
                acreators.setUpdatePropertyOfferStatus(AsyncActionState.Error)
            );
        }

        const companyId = company.viewingCompany._id as string;
        const branchId = branch.viewingBranch._id as string;
        const propertyId = property.viewingProperty._id as string;

        dispatch(
            acreators.setUpdatePropertyOfferStatus(AsyncActionState.Pending)
        );

        try {
            await api.property.updatePropertyOffer(
                offer,
                companyId,
                branchId,
                propertyId
            );

            // Go refresh the property
            await getPropertyById(propertyId)(dispatch, getState);

            return dispatch(
                acreators.setUpdatePropertyOfferStatus(AsyncActionState.Success)
            );
        } catch (e) {
            return dispatch(
                acreators.setUpdatePropertyOfferStatus(AsyncActionState.Error)
            );
        }
    };
};

export const getMIReport = (
    paging?: IPagingParams,
    sortOption: MIReportSortOption = MIReportSortOption.SALE_PROGRESS,
    filters?: IWorkflowFilters
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();
        if (company.viewingCompany === undefined) {
            return dispatch(
                acreators.setGetMIReportStatus(AsyncActionState.Error)
            );
        }

        const companyId = company.viewingCompany._id as string;

        dispatch(acreators.setGetMIReportStatus(AsyncActionState.Pending));

        try {
            // @todo make these happen in parrallel
            const reportData = await api.property.getMIReport(
                companyId,
                paging,
                sortOption,
                filters
            );

            const keyStats = await api.property.getMIKeyStats(
                companyId,
                filters
            );

            dispatch(acreators.setMIReportData(reportData.data));
            dispatch(acreators.setMIReportKeyStats(keyStats.data));

            return dispatch(
                acreators.setGetMIReportStatus(AsyncActionState.Success)
            );
        } catch (e) {
            return dispatch(
                acreators.setGetMIReportStatus(AsyncActionState.Error)
            );
        }
    };
};

export const loadPropertyPage = (
    companyId: string,
    branchId: string,
    propertyId: string
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        await getCompanyById(companyId)(dispatch);
        await getBranchById(branchId, companyId)(dispatch, getState);
        await getPropertyById(propertyId)(dispatch, getState);

        window.location.href = "/property/edit";
    };
};

export const updatePropertyContacts = (contacts: IPropertyContacts) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch, property } = getState();
        if (
            company.viewingCompany === undefined ||
            branch.viewingBranch === undefined ||
            property.viewingProperty === undefined
        ) {
            return dispatch(
                acreators.setUpdatePropertyContactsStatus(
                    AsyncActionState.Error
                )
            );
        }

        dispatch(
            acreators.setUpdatePropertyContactsStatus(AsyncActionState.Pending)
        );

        try {
            await api.property.updatePropertyContacts(
                contacts,
                company.viewingCompany._id as string,
                branch.viewingBranch._id as string,
                property.viewingProperty._id as string
            );
        } catch (e) {
            return dispatch(
                acreators.setUpdatePropertyContactsStatus(
                    AsyncActionState.Error
                )
            );
        }

        await getPropertyById(property.viewingProperty._id as string)(
            dispatch,
            getState
        );

        dispatch(
            acreators.setUpdatePropertyContactsStatus(AsyncActionState.Success)
        );
    };
};

export const resetSaleStatus = (
    companyId: string,
    branchId: string,
    propertyId: string
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(
            acreators.setResetPropertySaleStatus(AsyncActionState.Pending)
        );

        try {
            await property.resetSale(companyId, branchId, propertyId);

            await getPropertyById(propertyId)(dispatch, getState);
        } catch (e) {
            dispatch(
                acreators.setResetPropertySaleStatus(AsyncActionState.Error)
            );
        }

        dispatch(
            acreators.setResetPropertySaleStatus(AsyncActionState.Success)
        );
    };
};
export const restoreSaleStatus = (
    companyId: string,
    branchId: string,
    propertyId: string,
    restoreId: string
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(
            acreators.setRestorePropertySaleStatus(AsyncActionState.Pending)
        );

        try {
            await property.restoreSale(
                companyId,
                branchId,
                propertyId,
                restoreId
            );
            await getPropertyById(propertyId)(dispatch, getState);
        } catch (e) {
            dispatch(
                acreators.setRestorePropertySaleStatus(AsyncActionState.Error)
            );
        }

        dispatch(
            acreators.setRestorePropertySaleStatus(AsyncActionState.Success)
        );
    };
};

export const deleteRestoreSale = (
    companyId: string,
    branchId: string,
    propertyId: string,
    restoreId: string
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(
            acreators.setDeleteRestorePropertySaleStatus(
                AsyncActionState.Pending
            )
        );

        try {
            await property.deleteRestoreSale(
                companyId,
                branchId,
                propertyId,
                restoreId
            );
            await getPropertyById(propertyId)(dispatch, getState);
        } catch (e) {
            dispatch(
                acreators.setDeleteRestorePropertySaleStatus(
                    AsyncActionState.Error
                )
            );
        }

        dispatch(
            acreators.setDeleteRestorePropertySaleStatus(
                AsyncActionState.Success
            )
        );
    };
};

export const sendMiPdf = (options: {
    to: string[];
    sortOption: MIReportSortOption;
    filters: IWorkflowFilters;
    filter: IVisibleGroups;
}) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();

        if (
            company.viewingCompany === undefined ||
            company.viewingCompany._id === undefined
        ) {
            return;
        }

        dispatch(acreators.setMiPdfStatus(AsyncActionState.Pending));

        try {
            await property.sendMIPdf({
                companyId: company.viewingCompany._id,
                to: options.to,
                filters: options.filters,
                sortOption:
                    options.sortOption || MIReportSortOption.SALE_PROGRESS,
                filter: options.filter
            });
        } catch (e) {
            dispatch(acreators.setMiPdfStatus(AsyncActionState.Error));
        }

        dispatch(acreators.setMiPdfStatus(AsyncActionState.Success));
    };
};

export const deletePhoto = () => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch, property } = getState();

        if (
            company.viewingCompany === undefined ||
            branch.viewingBranch === undefined ||
            property.viewingProperty === undefined
        ) {
            return;
        }

        dispatch(
            acreators.setDeletePropertyPhotoStatus(AsyncActionState.Pending)
        );

        try {
            await api.property.deletePhoto(
                company.viewingCompany._id as string,
                branch.viewingBranch._id as string,
                property.viewingProperty._id as string
            );

            await getPropertyById(property.viewingProperty._id as string)(
                dispatch,
                getState
            );
        } catch (e) {
            return dispatch(
                acreators.setDeletePropertyPhotoStatus(AsyncActionState.Error)
            );
        }

        return dispatch(
            acreators.setDeletePropertyPhotoStatus(AsyncActionState.Success)
        );
    };
};

export const setPropertyOfferToEdit = (offer: IPropertyOffer) => {
    return (dispatch: Dispatch) => {
        dispatch(acreators.setPropertyOfferToEdit(offer));
    };
};

export const setCreatePropertyVendorRef = (user: IUser) => {
    return acreators.setCreatePropertyVendorRef(user);
};

export const resetManagePropertyFormState = () => {
    return acreators.setCreatePropertyStatus(AsyncActionState.Reset);
};

export const resetViewingProperty = () => {
    return acreators.setViewingProperty(undefined);
};

export const resetDeletePropertyFormState = () => {
    return acreators.setDeletePropertyStatus(AsyncActionState.Reset);
};

export const resetProperties = () => {
    return acreators.setProperties({
        properties: [],
        paging: createPager({ itemsPerPage: 10 })
    });
};

export const resetAddPropertyViewing = () => {
    return acreators.setAddPropertyViewingStatus(AsyncActionState.Reset);
};

export const resetRemovePropertyViewing = () => {
    return acreators.setRemovePropertyViewingStatus(AsyncActionState.Reset);
};

export const resetSavePropertyMI = () => {
    return acreators.setSaveMIStatus(AsyncActionState.Reset);
};

export const resetAddAskingPrice = () => {
    return acreators.setAddMIAskingPriceStatus(AsyncActionState.Reset);
};

export const resetRemoveAskingPrice = () => {
    return acreators.setRemoveMIAskingPriceStatus(AsyncActionState.Reset);
};

export const resetUpdatePropertyTimeline = () => {
    return acreators.setUpdatePropertyTimelineStatus(AsyncActionState.Reset);
};

export const resetCreatePropertyVendorRef = () => {
    return acreators.resetCreatePropertyVendorRef();
};

export const resetAddPropertyOfferStatus = () => {
    return acreators.setAddPropertyOfferStatus(AsyncActionState.Reset);
};

export const resetUpdatePropertyOfferStatus = () => {
    return acreators.setUpdatePropertyOfferStatus(AsyncActionState.Reset);
};

export const resetUpdatePropertyViewingStatus = () => {
    return acreators.setUpdatePropertyViewingStatus(AsyncActionState.Reset);
};

export const resetUpdatePropertyAskingPriceStatus = () => {
    return acreators.setUpdatePropertyAskingPriceStatus(AsyncActionState.Reset);
};

export const resetGetMIReportStatus = () => {
    return acreators.setGetMIReportStatus(AsyncActionState.Reset);
};

export const resetUpdatePropertyContactsStatus = () => {
    return acreators.setUpdatePropertyContactsStatus(AsyncActionState.Reset);
};

export const resetResetPropertySaleStatus = () => {
    return acreators.setResetPropertySaleStatus(AsyncActionState.Reset);
};

export const resetRestorePropertySaleStatus = () => {
    return acreators.setRestorePropertySaleStatus(AsyncActionState.Reset);
};

export const resetDeleteRestorePropertySaleStatus = () => {
    return acreators.setDeleteRestorePropertySaleStatus(AsyncActionState.Reset);
};

export const resetSendMiPdfStatus = () => {
    return acreators.setMiPdfStatus(AsyncActionState.Reset);
};

export const resetDeletePropertyPhotoStatus = () => {
    return acreators.setDeletePropertyPhotoStatus(AsyncActionState.Reset);
};
