import React, { FC } from "react";

const PoweredByKriva: FC = () => {
    return (
        <div style={{
            position: "fixed",
            right: 15,
            top: 10,
            zIndex: 99999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            Powered by

            <img
                className="pl-2 mx-auto img-fluid"
                style={{width: 130}}
                src="https://gya-production.fra1.cdn.digitaloceanspaces.com/Branding/logo-banner-lg.png"
                alt="Kriva logo"
            />
        </div>
    );
};

export default  PoweredByKriva;
