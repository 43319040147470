export const parseCookies = (cookies: string = document.cookie) => {
    return Object.fromEntries(
        document.cookie.split("; ").map((x) => x.split("="))
    );
};

export const setCookie = (
    name: string,
    value: string | undefined,
    days?: number
) => {
    var expires = "";
    var date;
    if (days) {
        date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    } else {
        date = new Date(0);
    }
    expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
