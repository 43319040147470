import React from "react";
import Datetime, { DatetimepickerProps } from "react-datetime";
import "./react-datetime.css";

interface IProps extends DatetimepickerProps {}

const defaultProps: DatetimepickerProps = {
    closeOnSelect: true,
    dateFormat: "DD/MM/YYYY"
};
export default function DatePicker(props: IProps) {
    const processedProps = Object.assign({}, defaultProps, props);
    return <Datetime {...processedProps} />;
}
