import React, { Component } from "react";
import { connect } from "react-redux";
import { IBranch, ICompany, IProperty } from "../../../../../../api/_types";
import { IStore } from "../../../../../../redux/store";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import TabBody from "../../../../../_sharedComponents/TabBody";
import EditPropertyTopNav from "../../components/EditPropertyTopNav";
import EditPropertyContainer from "../../containers/EditProperty";

interface IProps {
    company?: ICompany;
    branch?: IBranch;
    property?: IProperty;
}

class EditPropertyScreen extends Component<IProps> {
    render() {
        const { company, branch, property } = this.props;

        if (
            company === undefined ||
            branch === undefined ||
            property === undefined
        ) {
            return null;
        }

        const companyId = company._id as string;
        const branchId = branch._id as string;
        const propertyId = property._id as string;

        return (
            <PageWrap>
                {propertyId !== undefined && (
                    <>
                        <EditPropertyTopNav />

                        <TabBody>
                            <EditPropertyContainer
                                propertyId={propertyId}
                                branchId={branchId}
                                companyId={companyId}
                            />
                        </TabBody>
                    </>
                )}
            </PageWrap>
        );
    }
}

const mapStateToProps = ({ company, branch, property }: IStore) => ({
    company: company.viewingCompany,
    branch: branch.viewingBranch,
    property: property.viewingProperty
});
export default connect(
    mapStateToProps,
    {}
)(EditPropertyScreen);
