import React from "react";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import { IStore } from "../../../../../../redux/store";
import { ICompany } from "../../../../../../api/_types";
import { connect } from "react-redux";
import EditCompanyTopNav from "../../components/EditCompanyTopNav";
import TabBody from "../../../../../_sharedComponents/TabBody";
import CompanyPropertiesContainer from "../../containers/CompanyProperties";

interface IProps {
    company?: ICompany;
}

function PropertiesScreen(props: IProps) {
    const { company } = props;
    if (company === undefined) {
        return null;
    }
    return (
        <PageWrap>
            <EntityTitle entityName={company.name} />
            <EditCompanyTopNav />
            <TabBody>
                <CompanyPropertiesContainer />
            </TabBody>
        </PageWrap>
    );
}

const mapStateToProps = ({ company }: IStore) => ({
    company: company.viewingCompany
});

export default connect(
    mapStateToProps,
    {}
)(PropertiesScreen);
