import { CSSProperties } from "react";

export default {
    tabNav: (containerRef?: HTMLDivElement) =>
        ({
            borderWidth: "0px",
            position: "fixed",
            zIndex: 1000,
            top: 55,
            backgroundColor: "#fbfbfb",
            width: containerRef ? containerRef.offsetWidth : undefined
        } as CSSProperties),
    tabNavItem: {}
};
