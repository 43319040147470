import React, { Component } from "react";
import OffersListContainer from "../../containers/OffersList";
import CreateOfferFormContainer from "../../containers/CreateOfferForm";
import HorizontalTabs from "../../../../../_sharedComponents/HorizontalTabNav";
import EditPropertyTopNav from "../../components/EditPropertyTopNav";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import routes from "../../../../../../navigation/routes";

interface IProps {}

class Offers extends Component<IProps & RouteComponentProps> {
    render() {
        return (
            <PageWrap>
                <EditPropertyTopNav />

                <HorizontalTabs
                    id="offers-htabs"
                    defaultKey={this.props.match.url}
                    items={[
                        {
                            key: routes.propertyOffers.url,
                            title: "History",
                            content: OffersListContainer
                        },
                        {
                            key: routes.propertyCreateOffer.url,
                            title: "Add offer",
                            content: CreateOfferFormContainer
                        }
                    ]}
                />
            </PageWrap>
        );
    }
}

export default withRouter<any, any>(Offers);
