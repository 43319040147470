import React from "react";
import { Prompt } from "react-router-dom";

interface INavigationPromptProps {
    when: boolean;
    message?: string;
}

const defaultMessage =
    "Are you sure you want to move page? You may have unsaved changes.";

export default function NavigationPrompt(props: INavigationPromptProps) {
    const { when, message = defaultMessage } = props;
    return <Prompt when={when} message={message} />;
}
