import React, { Component } from "react";
import AskingPriceList from "../../containers/AskingPriceList";
import CreateAskingPriceFormContainer from "../../containers/CreateAskingPriceForm";
import HorizontalTabs from "../../../../../_sharedComponents/HorizontalTabNav";
import EditPropertyTopNav from "../../components/EditPropertyTopNav";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import { withRouter, RouteComponentProps } from "react-router-dom";
import routes from "../../../../../../navigation/routes";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import { IProperty } from "../../../../../../api/_types";

interface IProps {
    property?: IProperty;
}

class AskingPrice extends Component<IProps & RouteComponentProps> {
    render() {
        const { property } = this.props;
        let addTitle = "Price reduction";
        let defaultKey = this.props.match.url;
        let askingPriceExists = false;

        if (
            property !== undefined &&
            property.mi !== undefined &&
            property.mi.askingPrice.length > 0
        ) {
            askingPriceExists = true;
        }

        if (askingPriceExists === false) {
            addTitle = "Enter price";
            defaultKey = routes.propertyCreateAskingPrice.url;
        }
        let items: any[] = [
            {
                key: routes.propertyCreateAskingPrice.url,
                title: addTitle,
                content: CreateAskingPriceFormContainer
            }
        ];

        if (askingPriceExists === true) {
            items.unshift({
                key: routes.propertyAskingPrice.url,
                title: "History",
                content: AskingPriceList
            });
        }

        return (
            <PageWrap>
                <EditPropertyTopNav />

                <HorizontalTabs
                    id="asking-price-htabs"
                    defaultKey={defaultKey}
                    items={items}
                />
            </PageWrap>
        );
    }
}

const mapStateToProps = ({ property }: IStore) => ({
    property: property.viewingProperty
});

export default connect(
    mapStateToProps,
    {}
)(withRouter<any, any>(AskingPrice));
