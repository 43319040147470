import React, {  ReactElement } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ChainMortgageOffer, ChainSurveyBooked } from "../../../../../../../../api/modules/property";

interface Props {
    readonly?: boolean;
    mosSent: boolean;
    draftContracts: boolean;
    surveyBooked: string;
    surveyDate: string | undefined;
    searchesApplied: boolean;
    searchesBack: boolean;
    enquiriesRaised: boolean;
    enquiriesAnswered: boolean;
    mortgageOffer: ChainMortgageOffer;
    contractSigned: boolean;
    depositPaid: boolean;
    updateItem?: (key: string, value: any) => void;
}

export default function TimelineChecksForm({
    mosSent,
    draftContracts,
    surveyBooked,
    surveyDate,
    searchesApplied,
    searchesBack,
    enquiriesAnswered,
    enquiriesRaised,
    mortgageOffer,
    contractSigned,
    depositPaid,
    readonly,
    updateItem = () => {}
}: Props): ReactElement {
    const commonProps = {
        className: "mb-2",
        readOnly: readonly ?? false,
        custom: true,
        disabled: readonly ?? false
    };
    const inlineLabelStyle = {color: readonly === true ? "#6c757d" : undefined}

    const generateId = (val: string) => `${Date.now()}-${val}`;

    return (
        <>
            <Row>
                <Col>
                    <Form.Check
                        {...commonProps}
                        label="Memorandum of sale sent"
                        checked={mosSent}
                        id={generateId("mos-sent")}
                        onChange={() =>
                            updateItem("timeline.mosSent", !mosSent)
                        }
                    />
                    <Form.Check
                        {...commonProps}
                        label="Draft contracts"
                        checked={draftContracts}
                        id={generateId("draft-contracts")}
                        onChange={() =>
                            updateItem(
                                "timeline.draftContracts",
                                !draftContracts
                            )
                        }
                    />
                   
                    <Form.Check
                        {...commonProps}
                        label="Searches applied"
                        checked={searchesApplied}
                        id={generateId("searches-applied")}
                        onChange={() =>
                            updateItem(
                                "timeline.searchesApplied",
                                !searchesApplied
                            )
                        }
                    />
                     <Form.Check
                        {...commonProps}
                        label="Searches back"
                        checked={searchesBack}
                        id={generateId("searches-back")}
                        onChange={() =>
                            updateItem(
                                "timeline.searchesBack",
                                !searchesBack
                            )
                        }
                    />
                    <Form.Check
                        {...commonProps}
                        label="Enquiries raised"
                        checked={enquiriesRaised}
                        id={generateId("enquiries-raised")}
                        onChange={() =>
                            updateItem(
                                "timeline.enquiriesRaised",
                                !enquiriesRaised
                            )
                        }
                    />
                    <Form.Check
                        {...commonProps}
                        label="Enquiries answered"
                        checked={enquiriesAnswered}
                        id={generateId("enquiries-answered")}
                        onChange={() =>
                            updateItem(
                                "timeline.enquiriesAnswered",
                                !enquiriesAnswered
                            )
                        }
                    />
                     
                </Col>
                <Col>
                    
                    <Form.Label style={inlineLabelStyle}>Mortgage offer</Form.Label>
                    <div className="ml-2 mb-2">
                        <Form.Check
                            {...commonProps}
                            label="Yes"
                            checked={mortgageOffer === ChainMortgageOffer.Yes}
                            id={generateId("mortgage-offer-0")}
                            className="d-inline"
                            onChange={() =>
                                updateItem(
                                    "timeline.mortgageOffer",
                                    ChainMortgageOffer.Yes
                                )
                            }
                        />
                        <Form.Check
                            {...commonProps}
                            label="No"
                            checked={mortgageOffer === ChainMortgageOffer.No}
                            id={generateId("mortgage-offer-1")}
                            className="d-inline ml-2"
                            onChange={() =>
                                updateItem(
                                    "timeline.mortgageOffer",
                                    ChainMortgageOffer.No
                                )
                            }
                        />
                        <Form.Check
                            {...commonProps}
                            label="Cash"
                            checked={mortgageOffer === ChainMortgageOffer.Cash}
                            id={generateId("mortgage-offer-2")}
                            className="d-inline ml-2"
                            onChange={() =>
                                updateItem(
                                    "timeline.mortgageOffer",
                                    ChainMortgageOffer.Cash
                                )
                            }
                        />
                    </div>
   
                    <Form.Check
                        {...commonProps}
                        label="Contract signed"
                        checked={contractSigned}
                        id={generateId("contract-signed")}
                        onChange={() =>
                            updateItem(
                                "timeline.contractSigned",
                                !contractSigned
                            )
                        }
                    />
                    <Form.Check
                        {...commonProps}
                        label="Deposit paid"
                        checked={depositPaid}
                        id={generateId("deposit-paid")}
                        onChange={() =>
                            updateItem("timeline.depositPaid", !depositPaid)
                        }
                    />
                </Col>
                <Col>
                    <Form.Label style={inlineLabelStyle}>Additional survey</Form.Label>
                    <div className="ml-2 mb-2">

                        <Form.Check
                            {...commonProps}
                            label="Yes"
                            checked={surveyBooked === ChainSurveyBooked.Yes}
                            id={generateId("survey-booked-0")}
                            className="d-inline"
                            onChange={() =>
                                updateItem(
                                    "timeline.surveyBooked",
                                    ChainSurveyBooked.Yes
                                )
                            }
                        />
                        <Form.Check
                            {...commonProps}
                            label="No"
                            checked={surveyBooked === ChainSurveyBooked.No}
                            id={generateId("survey-booked-1")}
                            className="d-inline ml-2"
                            onChange={() =>
                                updateItem(
                                    "timeline.surveyBooked",
                                    ChainSurveyBooked.No
                                )
                            }
                        />
                        <Form.Check
                            {...commonProps}
                            label="NA"
                            checked={surveyBooked === ChainSurveyBooked.NA}
                            id={generateId("survey-booked-2")}
                            className="d-inline ml-2"
                            onChange={() =>
                                updateItem(
                                    "timeline.surveyBooked",
                                    ChainSurveyBooked.NA
                                )
                            }
                        />
                    </div>

                    <Form.Label style={inlineLabelStyle}>Survey notes</Form.Label>
                    <Form.Control
                        {...commonProps}
                        as="textarea"
                        id={generateId("survey-date")}
                        className="d-inline"
                        value={surveyDate}
                        onChange={(e) =>
                            updateItem(
                                "timeline.surveyDate",
                                e.currentTarget.value
                            )
                        }
                    />
                </Col>
                
            </Row>
        </>
    );
}
