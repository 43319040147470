import { Dispatch } from "redux";
import { getPropertyById } from "..";
import {default as propertyApi} from "../../../../api/modules/property";
import * as acreators from "../../../acreators/property/documents";
import { IStore } from "../../../store";
import { AsyncActionState } from "../../../utils/asyncAction";

export const createDocument = (file: File, note?: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {

        const {company, branch, property} = getState();

        const companyId = company.viewingCompany?._id;
        const branchId = branch.viewingBranch?._id;
        const propertyId = property.viewingProperty?._id;

        dispatch(acreators.setCreateDocumentStatus(AsyncActionState.Pending));

        try {
            await propertyApi.addDocument(companyId!, branchId!, propertyId!, file, note);
        } catch (e) {
            return dispatch(acreators.setCreateDocumentStatus(AsyncActionState.Error));
        }

        await getPropertyById(propertyId!, branchId!)(dispatch, getState);

        dispatch(acreators.setCreateDocumentStatus(AsyncActionState.Success));
    };
};

export const updateDocument = (documentId: string, note?: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {

        const {company, branch, property} = getState();

        const companyId = company.viewingCompany?._id;
        const branchId = branch.viewingBranch?._id;
        const propertyId = property.viewingProperty?._id;

        dispatch(acreators.setUpdateDocumentStatus(AsyncActionState.Pending));

        try {
            await propertyApi.updateDocument(companyId!, branchId!, propertyId!, documentId, note);
        } catch (e) {
            return dispatch(acreators.setUpdateDocumentStatus(AsyncActionState.Error));
        }

        await getPropertyById(propertyId!, branchId!)(dispatch, getState);

        dispatch(acreators.setUpdateDocumentStatus(AsyncActionState.Success));
    };
};

export const deleteDocument = (documentId: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {

        const {company, branch, property} = getState();

        const companyId = company.viewingCompany?._id;
        const branchId = branch.viewingBranch?._id;
        const propertyId = property.viewingProperty?._id;

        dispatch(acreators.setDeleteDocumentStatus(AsyncActionState.Pending));

        try {
            await propertyApi.deleteDocument(companyId!, branchId!, propertyId!, documentId);
        } catch (e) {
            return dispatch(acreators.setDeleteDocumentStatus(AsyncActionState.Error));
        }

        await getPropertyById(propertyId!, branchId!)(dispatch, getState);

        dispatch(acreators.setDeleteDocumentStatus(AsyncActionState.Success));
    };
};


export const emailDocument = (documentId: string, params: {
    to: string[],
    cc?: string[],
    bcc?: string[],
    subject?: string,
    message: string,
    replyTo?: string
}) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {

        const {company, branch, property} = getState();
        const companyId = company.viewingCompany?._id;
        const branchId = branch.viewingBranch?._id;
        const propertyId = property.viewingProperty?._id;

        dispatch(acreators.setEmailDocumentStatus(AsyncActionState.Pending));

        try {
            await propertyApi.emailDocument(companyId!, branchId!, propertyId!, documentId, params);
        } catch (e) {
            return dispatch(acreators.setEmailDocumentStatus(AsyncActionState.Error));
        }

        dispatch(acreators.setEmailDocumentStatus(AsyncActionState.Success));
    };
};