import { AsyncActionState } from "../../utils/asyncAction";
import { IPropertyNote } from "../../../api/_types";

export enum PropertyNotesTypes {
    CREATE_NOTE_STATUS = "CREATE_NOTE_STATUS",
    UPDATE_NOTE_STATUS = "UPDATE_NOTE_STATUS",
    DELETE_NOTE_STATUS = "DELETE_NOTE_STATUS",
    SET_NOTE_TO_EDIT = "SET_NOTE_TO_EDIT"
}

export interface CreateNoteStatus extends ReduxAction {
    type: PropertyNotesTypes.CREATE_NOTE_STATUS;
    payload: AsyncActionState;
}

export interface UpdateNoteStatus extends ReduxAction {
    type: PropertyNotesTypes.UPDATE_NOTE_STATUS;
    payload: AsyncActionState;
}

export interface DeleteNoteStatus extends ReduxAction {
    type: PropertyNotesTypes.DELETE_NOTE_STATUS;
    payload: AsyncActionState;
}

export interface SetNoteToEdit extends ReduxAction {
    type: PropertyNotesTypes.SET_NOTE_TO_EDIT;
    payload: IPropertyNote | undefined;
}
