import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import SideBar from "../../components/_sharedComponents/Navigation/SideBar/SideBar";
import { IStore } from "../../redux/store";
import routes, { IRouteDefinition } from "../routes";
import { setSidebarOpenState } from "../../redux/acreators/sidebar";
import PoweredByKriva from "./PoweredByKriva";

interface IProps {
    setSidebarOpenState: (status: boolean) => void;
}

interface IState {
    menuOpen: boolean;
}

class NavigationRouter extends Component<IProps, IState> {
    state: IState = {
        menuOpen: true
    };
    homepageIndex = 0;
    routes = [
        routes.companyManage,
        routes.companyContacts,
        routes.companyCreateContact,
        routes.companyContactPOCs,
        routes.companyCreateContactPOC,
        routes.branchManage,
        routes.branchCreate,
        routes.branchEdit,
        routes.branchUsers,
        routes.branchAddExistingUser,
        routes.branchCreateUser,
        routes.branchMIReport,
        routes.branchTimeline,
        routes.propertyCreate,
        routes.propertyEdit,
        routes.propertyManage,
        routes.propertyAskingPrice,
        routes.propertyCreateAskingPrice,
        routes.propertyContacts,
        routes.propertyMiReport,
        routes.propertyOffers,
        routes.propertyNotes,
        routes.propertyDocuments,
        routes.propertyCreateOffer,
        routes.propertyTimeline,
        routes.propertyViewings,
        routes.propertyCreateViewing,
        routes.propertyChain,
        routes.companyEdit,
        routes.companyTimeline,
        routes.companyCreate,
        routes.companyMIReport,
        routes.companyUsers,
        routes.companyCreateUser,
        routes.companyProperties,
        // routes.companyEditAdmin,
        routes.userProfile,
        routes.userEdit,
        routes.dashboard,
        routes.gyaAdminUsers,
        routes.gyaAddAdminUser
    ];

    renderRoute = (route: IRouteDefinition, index: number) => {
        return (
            <React.Fragment key={`rhome-${index}`}>
                {index === this.homepageIndex && (
                    <Route path={"/"} exact component={route.screen} />
                )}
                <Route path={route.url} exact component={route.screen} />
            </React.Fragment>
        );
    };
    render() {
        const { menuOpen } = this.state;
        const { setSidebarOpenState } = this.props;
        let contentMargin = menuOpen === true ? 300 : 75;
        let sideBarLeft = menuOpen === true ? 0 : -225;
        return (
            <Router>

                <div
                    style={{
                        position: "absolute",
                        width: 300,
                        left: sideBarLeft
                    }}
                >
                    <SideBar
                        hideSidebarNav={menuOpen === false}
                        hideAll={menuOpen === false}
                        toggleMenu={() => {
                            const menuOpen = !this.state.menuOpen;
                            setSidebarOpenState(menuOpen);
                            this.setState({
                                menuOpen
                            });
                        }}
                        menuOpen={this.state.menuOpen}
                    />
                </div>
                <div
                    style={{
                        marginLeft: contentMargin,
                        paddingTop: 15,
                        paddingBottom: 75,
                        paddingLeft: 0,
                        paddingRight: 0
                    }}
                >
                    <PoweredByKriva/>
                    {this.routes.map(this.renderRoute)}
                </div>

            </Router>
        );
    }
}

const mapStateToProps = ({ user }: IStore) => ({
    user: user.me
});

export default connect(
    mapStateToProps,
    { setSidebarOpenState }
)(NavigationRouter);
