import React, { FunctionComponent, ReactNode, useState } from "react";
import FeedbackAlert from "../FeedbackAlert";
import "./styles.scss";

interface IFeedbackProps {
    show: boolean;
    heading: string;
    message: string;
    customContent?: any;
    onClose?: () => void;
}
interface IProps {
    elements: ReactNode;
    successFeedback?: IFeedbackProps;
    errorFeedback?: IFeedbackProps;
}

const renderFeedbackAlert = (params: {
    variant: "success" | "danger";
    feedbackItem: IFeedbackProps;
    containerWidth: number;
}) => (
    <div
        style={{
            marginTop: 10,
            marginBottom: 10,
            maxWidth: params.containerWidth
        }}
    >
        <FeedbackAlert
            variant={params.variant}
            heading={params.feedbackItem.heading}
            message={params.feedbackItem.message}
            customContent={params.feedbackItem.customContent}
            onClose={params.feedbackItem.onClose}
        />
    </div>
);

const FixedBottomActionBar: FunctionComponent<IProps> = function(props) {
    const { children, elements, successFeedback, errorFeedback } = props;
    const [ref, setRef] = useState<HTMLDivElement | null>();
    const refHandler = (divRef: HTMLDivElement) => setRef(divRef);

    let containerPaddingBottom = 0;

    if (
        (successFeedback !== undefined && successFeedback.show === true) ||
        (errorFeedback !== undefined && errorFeedback.show === true)
    ) {
        containerPaddingBottom = 280;
    }

    return (
        <div
            ref={refHandler}
            style={{ paddingBottom: containerPaddingBottom, marginTop: 55 }}
        >
            {ref !== undefined && ref !== null && (
                <div
                    id="fixed-bottom-action-bar-wrapper"
                    style={{
                        position: "fixed",
                        zIndex: 9,
                        right: "30px",
                        padding: "10px 10px",
                        backgroundColor: "rgba(238, 235, 235, 1)",
                        borderRadius: "8px"
                    }}
                >
                    <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        {elements}
                    </div>
                    {successFeedback !== undefined &&
                        successFeedback.show === true &&
                        renderFeedbackAlert({
                            variant: "success",
                            feedbackItem: successFeedback,
                            containerWidth: ref.offsetWidth
                        })}

                    {errorFeedback !== undefined &&
                        errorFeedback.show === true &&
                        renderFeedbackAlert({
                            variant: "danger",
                            feedbackItem: errorFeedback,
                            containerWidth: ref.offsetWidth
                        })}
                </div>
            )}
            {children}
        </div>
    );
};

export default FixedBottomActionBar;
