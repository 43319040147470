export interface IDocumentEmailState {
    to: string[];
    cc: string[];
    bcc: string[];
    message: string;
    subject: string;
    replyTo: string;
}

export type IDocumentEmailAction = {
    type: "to" | "cc" | "bcc" | "message" | "subject" | "replyTo";
    payload: string | string[];
};

export const initialState: IDocumentEmailState = {
    to: [],
    cc: [],
    bcc: [],
    message: "",
    subject: "",
    replyTo: ""
};

export const reducer = (
    state: IDocumentEmailState = initialState,
    action: IDocumentEmailAction
): IDocumentEmailState => {
    switch (action.type) {
        case "to":
            return { ...state, to: action.payload as string[] };
        case "cc":
            return { ...state, cc: action.payload as string[] };
        case "bcc":
            return { ...state, bcc: action.payload as string[] };
        case "message":
            return { ...state, message: action.payload as string };
        case "subject":
            return { ...state, subject: action.payload as string };
        case "replyTo":
            return { ...state, replyTo: action.payload as string };

        default:
            return state;
    }
};

export default { initialState, reducer };
