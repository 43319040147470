import React from "react";
import TopBlock from "./TopBlock";

interface IProps {
    showPreSale: boolean;
    showSalesProcess: boolean;
    showFinancial: boolean;
}

function renderTopBlock(label: string, color: string) {
    return <TopBlock label={label} color={color} />;
}

export default function TableHeadingMeta(props: IProps) {
    const { showFinancial, showSalesProcess } = props;

    return (
        <>
            <tr>
                <td colSpan={2}></td>
                {showFinancial && (
                    <td colSpan={4}>
                        {renderTopBlock("Financial", "#4062bb")}
                    </td>
                )}

                {showSalesProcess && (
                    <td colSpan={15}>
                        {renderTopBlock("Sale process", "#f7567c")}
                    </td>
                )}
            </tr>
        </>
    );
}
