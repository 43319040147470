import React, { Component } from "react";
import { connect } from "react-redux";
import { IStore } from "../../../../../redux/store";
import { Navbar, Nav } from "react-bootstrap";
import "./SideBarNav.css";
import { getCompanies } from "../../../../../redux/api/company";
import ACLUserType from "../../../ACLUserType";
import { UserTypes } from "../../../../../redux/_types/user/types";
import routes from "../../../../../navigation/routes";
import { ICompany, IUser } from "../../../../../api/_types";
import { logout } from "../../../../../redux/api/auth";
import { Link } from "react-router-dom";
import ManageUsers from "../../../Icons/v2/ManageUsers";
import CreateCompany from "../../../Icons/v2/CreateCompany";
import Logout from "../../../Icons/v2/Logout";
import ManageCompanies from "../../../Icons/v2/ManageCompanies";

interface IProps {
    companies: ICompany[];
    user?: IUser;
    getCompanies: () => void;
    logout: () => void;
}

interface IState {}

export class SideBarNav extends Component<IProps, IState> {
    componentDidMount() {
        this.props.getCompanies();
    }

    render() {
        const { user, logout } = this.props;
        if (user === undefined) {
            return null;
        }
        return (
            <Navbar className="sidebarNav" >
                <div className="container">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto flex-column">
                            <ACLUserType
                                types={[
                                    UserTypes.COMPANY_ADMIN,
                                    UserTypes.BRANCH_ADMIN,
                                    UserTypes.BRANCH_STAFF
                                ]}
                            >
                                <Nav.Item>
                                    <Link to={routes.companyEdit.url}>
                                        <Nav.Link as="p">
                                            <ManageCompanies />
                                            <span className="ml-2">
                                                Company
                                            </span>
                                        </Nav.Link>
                                    </Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Link to={routes.branchManage.url}>
                                        <Nav.Link as="p">
                                            <ManageCompanies />
                                            <span className="ml-2">
                                                Branches
                                            </span>
                                        </Nav.Link>
                                    </Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Link to={routes.companyProperties.url}>
                                        <Nav.Link as="p">
                                            <ManageCompanies />
                                            <span className="ml-2">
                                                Properties
                                            </span>
                                        </Nav.Link>
                                    </Link>
                                </Nav.Item>
                            </ACLUserType>
                            <ACLUserType types={[UserTypes.GYA_ADMIN]}>
                                <Nav.Item as="div">
                                    <Link to={routes.companyManage.url}>
                                        <Nav.Link as="p">
                                            <ManageCompanies />
                                            <span className="ml-2">
                                                Manage Companies
                                            </span>
                                        </Nav.Link>
                                    </Link>
                                </Nav.Item>
                                <Nav.Item as="div">
                                    <Link to={routes.companyCreate.url}>
                                        <Nav.Link as="p">
                                            <CreateCompany />
                                            <span className="ml-2">
                                                Create Company
                                            </span>
                                        </Nav.Link>
                                    </Link>
                                </Nav.Item>

                                <Nav.Item as="div">
                                    <Link to={routes.gyaAdminUsers.url}>
                                        <Nav.Link as="p">
                                            <ManageUsers />
                                            <span className="ml-2">
                                                Manage Users
                                            </span>
                                        </Nav.Link>
                                    </Link>
                                </Nav.Item>
                            </ACLUserType>
                            <ACLUserType types={[UserTypes.COMPANY_ADMIN]}>
                                <Nav.Item>
                                    <Link to={routes.companyMIReport.url}>
                                        <Nav.Link as="p">
                                            <ManageCompanies />
                                            <span className="ml-2">
                                                Workflow
                                            </span>
                                        </Nav.Link>
                                    </Link>
                                </Nav.Item>
                            </ACLUserType>

                            <Nav.Item>
                                <Nav.Link onClick={logout}>
                                    <Logout />
                                    <span className="ml-2">Logout</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        );
    }
}

const mapStateToProps = (state: IStore) => ({
    user: state.user.me,
    companies: state.company.companies.companies
});

export default connect(
    mapStateToProps,
    { getCompanies, logout }
)(SideBarNav);
