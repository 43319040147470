const prevDayColour = "rgba(0,169,206, 0.6)";
const prevWeekColour = "rgba(0,169,206, 0.2)";

export default {
    updatedInLastDay: {
        backgroundColor: prevDayColour
    },
    updatedInLastWeek: {
        backgroundColor: prevWeekColour
    }
};
