import React, { Component } from "react";
import EditBranchContainer from "../../containers/EditBranch";
import EditBranchTopNav from "../../containers/EditBranchTopNav";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import TabBody from "../../../../../_sharedComponents/TabBody";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import { IBranch, ICompany } from "../../../../../../api/_types";

interface IProps {
    branch?: IBranch;
    company?: ICompany;
}

class EditBranchScreen extends Component<IProps> {
    render() {
        const { company, branch } = this.props;

        if (company === undefined || branch === undefined) {
            return null;
        }
        const companyId = company._id as string;
        const branchId = branch._id as string;

        return (
            <PageWrap>
                <EntityTitle entityName={branch.name} />
                <EditBranchTopNav />
                <TabBody>
                    {branchId !== null && (
                        <EditBranchContainer
                            branchId={branchId}
                            companyId={companyId}
                        />
                    )}
                </TabBody>
            </PageWrap>
        );
    }
}

const mapStateToProps = ({ branch, company }: IStore) => ({
    company: company.viewingCompany,
    branch: branch.viewingBranch
});
export default connect(
    mapStateToProps,
    {}
)(EditBranchScreen);
