import React, { Component } from "react";
import ContactsFormContainer from "../../containers/ContactsForm";
import TabBody from "../../../../../_sharedComponents/TabBody";
import EditPropertyTopNav from "../../components/EditPropertyTopNav";
import PageWrap from "../../../../../_sharedComponents/PageWrap";

interface IProps {}

class Contacts extends Component<IProps> {
    render() {
        return (
            <PageWrap>
                <EditPropertyTopNav />
                <TabBody>
                    <ContactsFormContainer />
                </TabBody>
            </PageWrap>
        );
    }
}

export default Contacts;
