import React, { CSSProperties, FC, useEffect, useState } from "react";
import { Image, Spinner } from "react-bootstrap";
import {default as propertyApi} from "../../../../../../../api/modules/property";
import { IProperty } from "../../../../../../../api/_types";
import Home from "../../../../../../_sharedComponents/Icons/v2/Home";

interface IProps {
    property: IProperty;
}

const PropertyThumb: FC<IProps> = ({property}) => {

    const size = 80;
    const sizedStyle:CSSProperties = {width: size, height: size};
    const [img, setImg] = useState<string>();
    const [imgLoading, setImgLoading] = useState(true);


    useEffect( () =>{
        if (!property.logo) {
            setImgLoading(false);
            return;
        }
        async function loadImage () {
            let downloadedImage;

            setImgLoading(true);

            try {
                //@ts-ignore
                downloadedImage = await propertyApi.getImage(property.company!, property.branch?._id, property._id!)
            } catch (e) {
                setImgLoading(false);

                return;
            }
            setImgLoading(false);

            let binary = [downloadedImage.data]
            setImg(URL.createObjectURL(new Blob(binary, {type: "image/jpeg"})))
        }

        loadImage();

    }, [property]);

    if (imgLoading === true) {
        return (
            <div style={{...sizedStyle, display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Spinner animation="border" variant="primary"/>
            </div>
        );
    }
    if (!property.logo || !img) {
        return (
            <Home
                size={size}
                style={{padding: 15, backgroundColor: "#f3f3f3"}}
            />
        );
    }

    return <Image
        style={sizedStyle}
        src={img}
    />
}

export default PropertyThumb
