import { AxiosResponse, AxiosPromise } from "axios";
import { api, BASE_URL } from "../index";
import { IPagingParams, IPagingResponse, ICompanyContactPOC } from "../_types";

export interface ICompanyContactPOCGetListResponse {
    paging: IPagingResponse;
    pocs: ICompanyContactPOC[];
}

export default {
    getPOCs: (params: {
        companyId: string;
        paging: IPagingParams;
        contactId?: string;
        textSearch?: string;
    }): AxiosPromise<AxiosResponse<ICompanyContactPOCGetListResponse>> => {
        const { companyId, contactId, textSearch, paging } = params;
        return api.get(`${BASE_URL}companycontactspoc/list/${companyId}`, {
            params: {
                contactId,
                textSearch,
                itemsPerPage: paging.itemsPerPage || 10,
                currentPage: paging.currentPage
            }
        });
    },
    createPOC: (
        companyId: string,
        contactId: string,
        companyContactPOC: ICompanyContactPOC
    ): AxiosPromise<AxiosResponse<ICompanyContactPOC>> => {
        return api.post(
            `${BASE_URL}companycontactspoc/${companyId}/${contactId}`,
            companyContactPOC
        );
    },
    editPOC: (
        companyId: string,
        contactId: string,
        pocId: string,
        payload: {
            name?: string;
            email?: string;
            tel?: string;
        }
    ): AxiosPromise<AxiosResponse<{ success: boolean }>> => {
        return api.patch(
            `${BASE_URL}companycontactspoc/${companyId}/${contactId}/${pocId}`,
            payload
        );
    },
    deletePOC: (
        companyId: string,
        pocId: string,
        contactId: string
    ): AxiosPromise<AxiosResponse<{ success: boolean }>> => {
        return api.delete(
            `${BASE_URL}companycontactspoc/${companyId}/${contactId}/${pocId}`
        );
    }
};
