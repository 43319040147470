import React, { Component } from "react";
import { connect } from "react-redux";
import { IPropertyViewing } from "../../../../../../api/_types";
import { setPropertyViewingToEdit } from "../../../../../../redux/acreators/property";
import {
    resetUpdatePropertyViewingStatus,
    updatePropertyViewing
} from "../../../../../../redux/api/property";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import CreateViewingForm from "../../components/CreateViewingForm";
import BackButton from "../../../../../_sharedComponents/BackButton";

interface IProps {
    viewing?: IPropertyViewing;
    updatePropertyViewingStatus: AsyncActionState;
    updatePropertyViewing: (viewing: IPropertyViewing) => void;
    resetUpdatePropertyViewingStatus: () => void;
    setPropertyViewingToEdit: (viewing: IPropertyViewing) => void;
}
interface IState {
    viewing: IPropertyViewing;
}

class EditViewingFormContainer extends Component<IProps, IState> {
    generateStateViewing = () => {
        return Object.assign({}, this.props.viewing);
    };

    state: IState = {
        viewing: this.generateStateViewing()
    };

    componentDidUpdate(prevProps: IProps) {
        // Reset form state when a viewing has been edited
        if (
            prevProps.updatePropertyViewingStatus !==
                AsyncActionState.Success &&
            this.props.updatePropertyViewingStatus === AsyncActionState.Success
        ) {
            this.setState({
                viewing: this.generateStateViewing()
            });
            this.handleGoBack();
        }
    }

    componentWillUnmount() {
        this.props.resetUpdatePropertyViewingStatus();
        this.props.setPropertyViewingToEdit(undefined as any);
    }

    handleGoBack = () => {
        this.props.setPropertyViewingToEdit(undefined as any);
    };

    handleSave = () => {
        this.props.updatePropertyViewing(this.state.viewing);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "viewing"
        );
    };

    render() {
        const { viewing } = this.state;
        const {
            updatePropertyViewingStatus,
            resetUpdatePropertyViewingStatus
        } = this.props;
        if (this.props.viewing === undefined) {
            return null;
        }

        return (
            <>
                <LoadingContainer
                    loading={
                        this.props.viewing === undefined ||
                        updatePropertyViewingStatus === AsyncActionState.Pending
                    }
                    success={
                        updatePropertyViewingStatus === AsyncActionState.Success
                    }
                    failure={
                        updatePropertyViewingStatus === AsyncActionState.Error
                    }
                    onSuccess={resetUpdatePropertyViewingStatus}
                    onFailure={resetUpdatePropertyViewingStatus}
                >
                    <BackButton goBack={this.handleGoBack} />
                    <hr />

                    <CreateViewingForm
                        viewing={viewing}
                        save={this.handleSave}
                        onChangeInputField={this.onChangeInputField}
                        saveViewingStatus={updatePropertyViewingStatus}
                        resetFormState={resetUpdatePropertyViewingStatus}
                    />
                </LoadingContainer>
            </>
        );
    }
}

const mapStateToProps = ({ property }: IStore) => ({
    viewing: property.viewingToEdit,
    updatePropertyViewingStatus: property.updatePropertyViewingStatus
});

export default connect(
    mapStateToProps,
    {
        updatePropertyViewing,
        resetUpdatePropertyViewingStatus,
        setPropertyViewingToEdit
    }
)(EditViewingFormContainer);
