import React, { Component } from "react";
import { FormControl, Button } from "react-bootstrap";
import { InputGroup, Form } from "react-bootstrap";

interface IProps {
    placeholder?: string;
    updateSearchText: (str: string) => void;
    search: () => void;
}
interface IState {}

export default class ContactSearchInput extends Component<IProps, IState> {
    search = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.search();
    };

    render() {
        const { placeholder } = this.props;

        const defaultPlaceholder =
            "Search by contact name or address line 1 + postcode";
        const _placeholder = placeholder || defaultPlaceholder;

        return (
            <Form onSubmit={this.search}>
                <InputGroup className="">
                    <FormControl
                        onChange={(e: any) =>
                            this.props.updateSearchText(e.currentTarget
                                .value as string)
                        }
                        placeholder={_placeholder}
                        autoComplete="off"
                    />
                    <InputGroup.Append>
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={() => this.props.search()}
                        >
                            Search
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Form>
        );
    }
}
