import React, { Component, ReactNode } from "react";
import loadingContainerStyles from "./loadingContainerStyles";
import { Spinner } from "react-bootstrap";
import FeedbackIcon from "../FeedbackIcon";

interface IProps {
    loading: boolean;
    children: ReactNode;
    spinnerAnimation?: "border" | "glow";
    spinnerVariant?:
        | "primary"
        | "secondary"
        | "success"
        | "danger"
        | "warning"
        | "info"
        | "light"
        | "dark"
        | undefined;
    message?: string;
    success?: boolean;
    successMessage?: string;
    onSuccess?: () => void;
    onSuccessTimeout?: number;
    failure?: boolean;
    failureMessage?: string;
    onFailure?: () => void;
    onFailureTimeout?: number;
}
interface IState {
    showSuccess: boolean;
    showFailure: boolean;
}

export default class LoadingContainer extends Component<IProps, IState> {
    state: IState = {
        showSuccess: false,
        showFailure: false
    };

    private onSuccessRef: number = -1;
    private onFailureRef: number = -1;
    private defaultOnSuccessTimeout = 2000;
    private defaultOnFailureTimeout = 2000;
    private defaultMessage = "Loading, please wait.";
    private defaultSuccessMessage = "Success.";
    private defaultFailureMessage = "Error.";
    private defaultSpinnerAnimation: "border" | "glow" = "border";
    private defaultSpinnerVariant:
        | "primary"
        | "secondary"
        | "success"
        | "danger"
        | "warning"
        | "info"
        | "light"
        | "dark"
        | undefined = "primary";

    componentWillUnmount() {
        // Clear any pending timeouts
        clearTimeout(this.onSuccessRef);
        clearTimeout(this.onFailureRef);
    }
    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const { showSuccess, showFailure } = this.state;
        const {
            success,
            failure,
            onSuccess = Function.prototype,
            onSuccessTimeout = this.defaultOnSuccessTimeout,
            onFailure = Function.prototype,
            onFailureTimeout = this.defaultOnFailureTimeout
        } = this.props;
        if (this.state.showSuccess === true && this.props.success === false) {
            this.setState({
                showSuccess: false
            });
        }
        if (this.state.showFailure === true && this.props.failure === false) {
            this.setState({ showFailure: false });
        }
        if (
            (prevProps.success === undefined || prevProps.success === false) &&
            success === true &&
            showSuccess === false
        ) {
            this.setState({
                showSuccess: true
            });

            // Call the onsuccess function
            this.onSuccessRef = setTimeout(onSuccess, onSuccessTimeout);
        }
        if (
            (prevProps.failure === undefined || prevProps.failure === false) &&
            failure === true &&
            showFailure === false
        ) {
            this.setState({
                showFailure: true
            });

            // Call the onfailure function
            this.onFailureRef = setTimeout(onFailure, onFailureTimeout);
        }
    }

    renderMessage = (variant: "loading" | "success" | "failure") => {
        const {
            message = this.defaultMessage,
            successMessage = this.defaultSuccessMessage,
            failureMessage = this.defaultFailureMessage
        } = this.props;
        let display: string = message;
        switch (variant) {
            case "success": {
                display = successMessage;
                break;
            }
            case "failure": {
                display = failureMessage;
                break;
            }
            case "loading": {
                display = message;
                break;
            }
        }
        return <h3 className="text-center mt-3">{display}</h3>;
    };
    renderFeedback = (variant: "success" | "failure") => {
        return (
            <div style={loadingContainerStyles.loadingContainer}>
                <FeedbackIcon variant={variant} />
                {this.renderMessage(variant)}
            </div>
        );
    };
    renderLoader = () => {
        const {
            spinnerAnimation = this.defaultSpinnerAnimation,
            spinnerVariant = this.defaultSpinnerVariant
        } = this.props;
        return (
            <div style={loadingContainerStyles.loadingContainer}>
                <Spinner
                    style={loadingContainerStyles.spinnerStyle}
                    animation={spinnerAnimation as any}
                    variant={spinnerVariant}
                />
                {this.renderMessage("loading")}
            </div>
        );
    };
    render() {
        const { showSuccess, showFailure } = this.state;
        const { children, loading } = this.props;
        return (
            <div style={loadingContainerStyles.container}>
                {loading === true &&
                    showSuccess === false &&
                    this.renderLoader()}
                {showSuccess === true && this.renderFeedback("success")}
                {showFailure === true && this.renderFeedback("failure")}

                {children}
            </div>
        );
    }
}
