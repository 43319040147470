import { IPropertyOffer } from "../../../api/_types";
import { OfferState } from "../../../api/modules/property";

export function canAcceptOffer(
    offer: IPropertyOffer,
    offers: IPropertyOffer[]
) {
    let canAcceptOffer = false;
    const existingAcceptedOffer = offers.find(
        (o) => o.state === OfferState.Accepted
    );
    const isAcceptedOffer =
        existingAcceptedOffer !== undefined &&
        existingAcceptedOffer._id === offer._id;

    if (existingAcceptedOffer === undefined || isAcceptedOffer === true) {
        canAcceptOffer = true;
    }

    return canAcceptOffer;
}
