import React, { FormEvent } from "react";
import { Form } from "react-bootstrap";
import { IPropertyTimelineEntity } from "../../../../../../../api/_types";

interface IProps {
    item: IPropertyTimelineEntity;
    onChange: (
        timelineEntity: IPropertyTimelineEntity,
        newDaysTillComplete: number
    ) => void;
}

export default function DTCInput(props: IProps) {
    const { item, onChange } = props;
    const onChangeHandler = (e: FormEvent<any>) =>
        onChange(item, parseInt(e.currentTarget.value || "", 10));
    return (
        <>
            <hr />
            <Form.Label>Days till complete</Form.Label>
            <Form.Control
                data-testid="dtc-input"
                type="number"
                min="0"
                max="31"
                onChange={onChangeHandler}
                value={item.daysToComplete.toString()}
            />
        </>
    );
}
