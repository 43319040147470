import React, { Component } from "react";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import {
    addBranchMember,
    resetAddBranchMembersStatus
} from "../../../../../../redux/api/branch";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import {
    ICompany,
    IUser,
    IPagingParams,
    IPagingResponse,
    IBranch
} from "../../../../../../api/_types";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";
import DataTable from "../../../../../_sharedComponents/DataTable";
import { searchCompanyMembers } from "../../../../../../redux/api/company";
import BranchMemberTableInput from "../../components/BranchMemberTableInput";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";

interface IProps {
    company?: ICompany;
    branch?: IBranch;
    addBranchMemberStatus: AsyncActionState;
    users: IUser[];
    paging: IPagingResponse;
    addBranchMember: (member: string | IUser) => void;
    resetAddBranchMembersStatus: () => void;
    searchCompanyMembers: (
        searchString?: string,
        paging?: IPagingParams,
        type?:
            | UserTypes.COMPANY_ADMIN
            | UserTypes.BRANCH_ADMIN
            | UserTypes.BRANCH_STAFF
            | UserTypes[]
            | undefined,
        excludeBranchIds?: string[]
    ) => void;
}
interface IState {
    member?: IUser;
    memberToAdd?: IUser;
    dirty: boolean;
}

class AddExistingMemberFormContainer extends Component<IProps, IState> {
    state: IState = {
        member: undefined,
        memberToAdd: undefined,
        dirty: false
    };

    componentDidMount() {
        this.searchMembers();
    }

    componentDidUpdate(prevProps: IProps) {
        if (
            prevProps.addBranchMemberStatus === AsyncActionState.Pending &&
            this.props.addBranchMemberStatus !== AsyncActionState.Pending
        ) {
            if (this.props.addBranchMemberStatus === AsyncActionState.Success) {
                this.resetFormState();
                this.searchMembers();
            }
        }
    }

    componentWillUnmount() {
        this.props.resetAddBranchMembersStatus();
    }

    setMemberToAdd = (memberToAdd: IUser) => {
        this.setState({ memberToAdd });
    };

    removeMemberToAdd = () => {
        this.setState({ memberToAdd: undefined });
    };

    searchMembers = (searchString = "") => {
        const { branch, searchCompanyMembers } = this.props;
        const branchId = branch ? branch._id : "";

        searchCompanyMembers(
            searchString,
            this.props.paging,
            [UserTypes.BRANCH_ADMIN, UserTypes.BRANCH_STAFF],
            [branchId as string]
        );
    };

    handlePageUsers = (desiredPage: number) => {
        const { searchCompanyMembers, paging, branch } = this.props;
        const branchId = branch ? branch._id : "";

        searchCompanyMembers(
            "",
            {
                currentPage: desiredPage,
                itemsPerPage: paging.itemsPerPage
            },
            [UserTypes.BRANCH_ADMIN, UserTypes.BRANCH_STAFF],
            [branchId as string]
        );
    };

    addMember = () => {
        const member = this.state.memberToAdd;
        if (member !== undefined) {
            this.props.addBranchMember(member._id as string);
        }
    };

    resetFormState = () => {
        this.setState({
            member: undefined,
            dirty: false
        });
    };

    render() {
        const { addBranchMemberStatus, users, paging } = this.props;
        const { dirty, memberToAdd } = this.state;
        return (
            <>
                <NavigationPrompt when={dirty === true} />
                <LoadingContainer
                    loading={addBranchMemberStatus === AsyncActionState.Pending}
                >
                    <div className="mt-2 mb-2">
                        <BranchMemberTableInput search={this.searchMembers} />
                    </div>
                    <DataTable
                        data={users}
                        fields={[
                            { field: "fullName", displayName: "Name" },
                            { field: "email", displayName: "Email address" },
                            { field: "type", displayName: "Type" }
                        ]}
                        actions={[
                            {
                                    btnTitle: "Add to branch",
                                btnVariant: "light",
                                action: (item: IUser) =>
                                    this.setMemberToAdd(item)
                            }
                        ]}
                        pagingElement={paging}
                        showPager={true}
                        onPagerPageClick={this.handlePageUsers}
                    />
                </LoadingContainer>

                <ActionPrompt
                    show={memberToAdd !== undefined}
                    title="Are you sure?"
                    bodyText="This user will retain their privileges in this branch, i.e. if they are an administrator type, then they will have those privileges within this branch."
                    cancel={this.removeMemberToAdd}
                    actions={[
                        {
                            title: "Confirm",
                            btnVariant: "primary",
                            onClick: this.addMember,
                            asyncStatus: this.props.addBranchMemberStatus,
                            onSuccess: this.removeMemberToAdd,
                            onError: this.removeMemberToAdd
                        }
                    ]}
                />
            </>
        );
    }
}

const mapStateToProps = ({ branch, company }: IStore) => ({
    addBranchMemberStatus: branch.addBranchMemberStatus,
    company: company.viewingCompany,
    branch: branch.viewingBranch,
    users: company.members.users,
    paging: company.members.paging
});

export default connect(
    mapStateToProps,
    {
        addBranchMember,
        resetAddBranchMembersStatus,
        searchCompanyMembers
    }
)(AddExistingMemberFormContainer);
