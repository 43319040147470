import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import  { ChainPropertySaleType, ChainMortgageOffer, ChainSurveyBooked } from "../../../../../../api/modules/property";
import {
    IContactSet,
    IProperty,
    IPropertyChain,
    IPropertyChainItem
} from "../../../../../../api/_types";
import { arrayMove } from "../../../../../../lib/utils/array";
import { updateChain } from "../../../../../../redux/api/property/chain";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";
import Chain from "../../components/Chain";
import _ from "lodash";
import { setViewingCompanyContact } from "../../../../../../redux/api/companyContacts";

const mapStateToProps = ({ branch, company, property, chain }: IStore) => ({
    company: company.viewingCompany,
    branch: branch.viewingBranch,
    property: _.cloneDeep(property.viewingProperty),
    updateChainStatus: chain.updateChainStatus
});

const connector = connect(
    mapStateToProps,
    { updateChain, setViewingCompanyContact }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {}
interface IState {
    insertPosition: number;
    chain: IPropertyChain;
    showDeletePrompt: boolean;
    showDeletePropertyRefPrompt: boolean;
    showSaveAllPrompt: boolean;
    showAddPropertyRefPrompt: boolean;
    indexToDelete: number;
    indexToAddPropertyRef: number;
    pendingPropertyRef?: IProperty;
    isDirty: boolean;
    addPropertyRefStatus: AsyncActionState;
    showResetChainPrompt: boolean;
}

class DefaultChainItem implements IPropertyChainItem {
    _id: string;
    thisProperty: boolean;
    solicitor: IContactSet;
    agent: IContactSet;
    note: string;
    propertyName: string;
    personName: string;
    saleType: ChainPropertySaleType;
    timeline: any;

    constructor() {
        this._id = Date.now().toString();
        this.thisProperty = false;
        this.solicitor = {};
        this.agent = {};
        this.note = "";
        this.saleType = ChainPropertySaleType.NotDefined;
        this.propertyName = "";
        this.personName = "";
        this.timeline = {
            mosSent: false,
            draftContracts: false,
            surveyBooked: ChainSurveyBooked.NA,
            searchesApplied: false,
            enquiriesRaised: false,
            enquiriesAnswered: false,
            mortgageOffer: ChainMortgageOffer.No,
            contractSigned: false,
            depositPaid: false
        };
    }
}

class ChainContainer extends Component<IProps, IState> {
    state: IState = {
        insertPosition: 0,
        showDeletePrompt: false,
        showDeletePropertyRefPrompt: false,
        showSaveAllPrompt: false,
        showAddPropertyRefPrompt: false,
        pendingPropertyRef: undefined,
        showResetChainPrompt: false,
        indexToAddPropertyRef: -1,
        indexToDelete: -1,
        chain: {
            items: []
        },
        isDirty: false,
        addPropertyRefStatus: AsyncActionState.Reset
    };

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const { chain } = this.state;

        if (prevState.chain.items.length !== chain.items.length) {
            this.setState({
                insertPosition: chain.items.length
            });
        }

        if (
            prevProps.updateChainStatus === AsyncActionState.Pending &&
            this.props.updateChainStatus === AsyncActionState.Success
        ) {
            this.setState({ isDirty: false });
        }

        // Item was just added to the chain
        if (prevState.chain.items.length < chain.items.length) {
            this.scrollToNewChainLink();
        }
    }

    componentWillMount() {
        const { property } = this.props;
        if (property !== undefined && property.chain !== undefined) {
            this.setState({
                chain: property.chain,
                insertPosition: property.chain.items.length
            });
        }
    }

    scrollToNewChainLink = () => {
        // Because the end/top of the chain is the start of the array, and assigned the top-most position number (e.g 2 items in chain, "Position 2")
        // Check for the insert position being the end of the array, as that will be the item at the top of the screen

        if (this.state.insertPosition === this.state.chain.items.length - 1) {
            return;
        }

        const element = document.getElementById(
            `chain-item-${this.state.chain.items.length -
                1 -
                this.state.insertPosition}`
        );

        if (element !== null) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "center"
            } as ScrollIntoViewOptions);
        }
    };

    addNewItem = (atIndex: number) => {
        const items = [...this.state.chain.items];
        items.splice(items.length - atIndex, 0, new DefaultChainItem());

        this.setState({
            insertPosition: atIndex,
            chain: { ...this.state.chain, items },
            isDirty: true
        });
    };

    moveItem = (fromIndex: number, toIndex: number) => {
        const { chain } = this.state;
        const items = chain.items;

        arrayMove(items, fromIndex, toIndex);

        this.setState({
            chain: { ...chain, items },
            isDirty: true
        });
    };

    showDeletePrompt = (indexToDelete: number) =>
        this.setState({ showDeletePrompt: true, indexToDelete });

    hideDeletePrompt = () => this.setState({ showDeletePrompt: false });

    confirmDelete = (resetDeletedProperties: boolean = false) => {
        const { chain, indexToDelete } = this.state;
        const items = [...chain.items];

        items.splice(indexToDelete, 1);

        this.setState({
            chain: { ...chain, items }
        }, () => {
            this.saveChain(true, resetDeletedProperties);
            this.hideDeletePrompt()
        });
    };

    showDeletePropertyRefPrompt = (indexToDelete: number) =>
        this.setState({ showDeletePropertyRefPrompt: true, indexToDelete });

    hideDeletePropertyRefPrompt = () =>
        this.setState({
            showDeletePropertyRefPrompt: false,
            indexToDelete: -1
        });

    confirmDeletePropertyRef = (resetDeletedProperties: boolean = false) => {
        this.updateItem(
            this.state.chain.items[this.state.indexToDelete],
            "propertyRef",
            undefined
        );

        this.saveChain(true, resetDeletedProperties);

        this.hideDeletePropertyRefPrompt();
    };

    showSaveAllPrompt = () => this.setState({ showSaveAllPrompt: true });

    hideSaveAllPrompt = () =>
        this.setState({
            showSaveAllPrompt: false
        });

    confirmSaveAll = () => {
        this.saveChain(true);
        this.hideSaveAllPrompt();
    }

    showResetChainPrompt = () => this.setState({ showResetChainPrompt: true });

    hideResetChainPrompt = () =>
        this.setState({
            showResetChainPrompt: false
        });

    confirmResetChain = (resetAll: boolean = false) => {
        this.setState({
            chain: {items: []}
        }, ( )=> {
            this.saveChain(true, resetAll);
            this.hideResetChainPrompt();
        });
    }

    showAddPropertyRefPrompt = (
        indexToAddPropertyRef: number,
        pendingPropertyRef: IProperty
    ) =>
        this.setState({
            showAddPropertyRefPrompt: true,
            indexToAddPropertyRef,
            pendingPropertyRef
        });

    hideAddPropertyRefPrompt = () =>
        this.setState({
            showAddPropertyRefPrompt: false,
            indexToAddPropertyRef: -1,
            pendingPropertyRef: undefined
        });


    confirmAddPropertyRef = () => {
        const {chain, indexToAddPropertyRef, pendingPropertyRef} = this.state;
        if (chain === undefined || indexToAddPropertyRef < 0 || pendingPropertyRef === undefined) {
            return;
        }
        let item = chain.items[indexToAddPropertyRef]
        this.updateItem(item, "propertyRef",pendingPropertyRef)
        this.saveChain(true, true);
        this.hideAddPropertyRefPrompt();
    };

    updateItem = (
        item: IPropertyChainItem,
        key: keyof IPropertyChainItem,
        value: any
    ) => {
        const items = [...this.state.chain.items];

        const itemToUpdate = items.find((i) => i._id === item._id);

        if (itemToUpdate === undefined) {
            return;
        }

        _.set(itemToUpdate, key, value);

        this.setState({
            chain: { ...this.state.chain, items },
            isDirty: true
        });
    };

    saveChain = (saveAll?: boolean, resetDeletedProperties?: boolean) => {
        const items = [...this.state.chain.items];
        const chainItems = items.map(
            (item: DefaultChainItem | IPropertyChainItem) => {
                const _id =
                    item instanceof DefaultChainItem === true
                        ? undefined
                        : item._id;
                return {
                    ...item,
                    _id
                };
            }
        );

        this.props.updateChain(
            //@ts-ignore
            { ...this.state.chain, items: chainItems },
            saveAll,
            resetDeletedProperties
        );
    };

    render() {
        const { branch, company, property, updateChainStatus, setViewingCompanyContact } = this.props;
        const {
            chain,
            showDeletePrompt,
            isDirty,
            showDeletePropertyRefPrompt,
            showSaveAllPrompt,
            showAddPropertyRefPrompt,
            showResetChainPrompt
        } = this.state;

        const thisPropertyInChain =
            chain.items.find((item) => item.thisProperty === true) !==
            undefined;

        const actions = [
            <Button
                key="ca-3"
                className="mr-2"
                variant="danger"
                style={{ width: 150 }}
                onClick={this.showResetChainPrompt}
            >
                Reset
            </Button>,
            <Button
                className="mr-2"
                key="ca-4"
                variant="primary"
                style={{ width: 150 }}
                onClick={() => this.saveChain(true, true)}
            >
                Save
            </Button>,
        ];

        if (this.state.chain.items.length === 0) {
            actions.splice(0, 1,
                <Button
                    className="mr-2"
                    variant="success"
                    onClick={() => this.addNewItem(0)}
                >
                    Start chain
                </Button>
            );
        }

        if (
            company === undefined ||
            branch === undefined ||
            property === undefined
        ) {
            return null;
        }

        return (
            <FormContainer actions={actions}>
                <NavigationPrompt when={isDirty === true} />
                <LoadingContainer
                    loading={updateChainStatus === AsyncActionState.Pending}
                >
                    <Chain
                        thisPropertyInChain={thisPropertyInChain}
                        updateItem={this.updateItem}
                        company={company}
                        branch={branch}
                        property={property}
                        moveItem={this.moveItem}
                        chain={chain}
                        deleteItem={this.showDeletePrompt}
                        deletePropertyRef={this.showDeletePropertyRefPrompt}
                        addPropertyRef={this.showAddPropertyRefPrompt}
                        setViewingCompanyContact={setViewingCompanyContact}
                        addNewItem={this.addNewItem}
                    />
                    <ActionPrompt
                        title={`Reset the chain`}
                        bodyText={`Resetting the chain will reset this property chain, along with the chain of any linked properties from your sales pipeline.`}
                        show={showResetChainPrompt === true}
                        cancel={this.hideResetChainPrompt}
                        confirmText={property.address.line1}
                        confirmTextLabel={"Enter the first line of address of this property to confirm"}
                        actions={[
                            {
                                onClick: () => this.confirmResetChain(true),
                                btnVariant: "warning",
                                title: "Reset chain",
                                useConfirm: true
                            }
                        ]}
                    />
                    <ActionPrompt
                        title={`Are you sure`}
                        bodyText={`To add ${this.state.pendingPropertyRef?.address.line1} to this chain click 'Link' button.'

By linking this property, you will be erasing the chain diagram of the property you are importing.

If you are unsure, it's best to review this properties chain diagram before proceeding.`}
                        show={showAddPropertyRefPrompt === true}
                        cancel={this.hideAddPropertyRefPrompt}
                        confirmTextLabel="Please enter the first line of address to confirm"
                        confirmText={this.state.pendingPropertyRef?.address.line1}
                        actions={[
                            {
                                onClick: this.confirmAddPropertyRef,
                                title: "Link",
                                useConfirm: true
                            }
                        ]}
                    />
                    <ActionPrompt
                        title="Are you sure"
                        bodyText="Save all will update all linked properties with this chain."
                        show={showSaveAllPrompt === true}
                        cancel={this.hideSaveAllPrompt}
                        actions={[
                            {
                                onClick: this.confirmSaveAll,
                                title: "Save all"
                            }
                        ]}
                    />
                    <ActionPrompt
                        title="Are you sure"
                        bodyText={`Please confirm you'd like to remove this item from the chain.
                        
This will reset the chain of the property being deleted.`}
                        show={showDeletePrompt === true}
                        cancel={this.hideDeletePrompt}
                        actions={[
                            {
                                onClick: () => this.confirmDelete(true),
                                title: "Confirm"
                            }
                        ]}
                    />
                    <ActionPrompt
                        title="Are you sure"
                        bodyText={`Please confirm you'd like to remove the link to this property.
                        
This will reset the chain of the property being unlinked.`}
                        show={showDeletePropertyRefPrompt === true}
                        cancel={this.hideDeletePropertyRefPrompt}
                        actions={[
                            {
                                onClick: () => this.confirmDeletePropertyRef(true),
                                title: "Confirm"
                            }
                        ]}
                    />
                </LoadingContainer>
            </FormContainer>
        );
    }
}

export default connector(ChainContainer);
