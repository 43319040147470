import { AsyncActionState } from "../utils/asyncAction";
import { IUser, ISearchGYAAdminsResponse } from "../../api/_types";

export enum UserAccountTypes {
    SET_ME = "SET_ME",

    SAVE_ME_STATUS = "SAVE_ME_STATUS",

    GET_ME_STATUS = "GET_ME_STATUS",

    SEARCH_VENDORS_STATUS = "SEARCH_VENDORS_STATUS",
    SET_SEARCH_VENDORS_RESULT = "SET_SEARCH_VENDORS_RESULT",
    SET_ACTIVATE_ACCOUNT_STATUS = "SET_ACTIVATE_ACCOUNT_STATUS",
    SET_GYA_ADMIN_USERS = "SET_GYA_ADMIN_USERS",
    SEARCH_GYA_ADMIN_USERS_STATUS = "SEARCH_GYA_ADMIN_USERS_STATUS",
    CREATE_GYA_ADMIN_STATUS = "CREATE_GYA_ADMIN_STATUS",
    SET_EDIT_USER = "SET_EDIT_USER",
    SET_EDIT_USER_UPDATE_STATUS = "SET_EDIT_USER_UPDATE_STATUS",
    DELETE_ME_STATUS = "DELETE_ME_STATUS",
    DELETE_USER_PHOTO_STATUS = "DELETE_USER_PHOTO_STATUS",
    RESEND_EMAIL_VERIFICATION_STATUS = "RESEND_EMAIL_VERIFICATION_STATUS"
}

export interface SetMe extends ReduxAction {
    type: UserAccountTypes.SET_ME;
    payload: IUser | undefined;
}

export interface UpdateMeStatus extends ReduxAction {
    type: UserAccountTypes.SAVE_ME_STATUS;
    payload: AsyncActionState;
}

export interface GetMeStatus extends ReduxAction {
    type: UserAccountTypes.GET_ME_STATUS;
}

export interface SearchVendorsStatus extends ReduxAction {
    type: UserAccountTypes.SEARCH_VENDORS_STATUS;
    payload: AsyncActionState;
}

export interface SetSearchVendorsResult extends ReduxAction {
    type: UserAccountTypes.SET_SEARCH_VENDORS_RESULT;
    payload: IUser[];
}

export interface SetActivateAccountStatus extends ReduxAction {
    type: UserAccountTypes.SET_ACTIVATE_ACCOUNT_STATUS;
    payload: AsyncActionState;
}

export interface SetSearchGYAdminUsers extends ReduxAction {
    type: UserAccountTypes.SET_GYA_ADMIN_USERS;
    payload: ISearchGYAAdminsResponse;
}

export interface SetSearchGYAAdminSearchStatus extends ReduxAction {
    type: UserAccountTypes.SEARCH_GYA_ADMIN_USERS_STATUS;
    payload: AsyncActionState;
}

export interface SetCreateGYAAdminStatus extends ReduxAction {
    type: UserAccountTypes.CREATE_GYA_ADMIN_STATUS;
    payload: AsyncActionState;
}

export interface SetEditUser extends ReduxAction {
    type: UserAccountTypes.SET_EDIT_USER;
    payload: IUser | undefined;
}

export interface SetEditUserUpdateStatus extends ReduxAction {
    type: UserAccountTypes.SET_EDIT_USER_UPDATE_STATUS;
    payload: AsyncActionState;
}

export interface DeleteMeStatus extends ReduxAction {
    type: UserAccountTypes.DELETE_ME_STATUS;
    payload: AsyncActionState;
}

export interface DeleteUserPhotoStatus extends ReduxAction {
    type: UserAccountTypes.DELETE_USER_PHOTO_STATUS;
    payload: AsyncActionState;
}

export interface ResendEmailVerificationStatus extends ReduxAction {
    type: UserAccountTypes.RESEND_EMAIL_VERIFICATION_STATUS;
    payload: AsyncActionState;
}
