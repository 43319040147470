import React, { CSSProperties } from "react";
import { IDataTableField, IDataTableAction } from "../types";
import { ButtonToolbar, Button } from "react-bootstrap";
import styles from "../dataTableStyles";
import { calcHorizotalStickyleft,  ColumnWidthState } from "../utils";

interface ITBodyProps {
    data: any[];
    fields: IDataTableField[];
    actions?: IDataTableAction[];
    stickyHorizontalCols?: number;
    columnWidths: ColumnWidthState;
    dispatchColumnWidths: React.Dispatch<{
        type: "setWidth";
        payload: {
            index: number;
            width: number;
        };
    }>
}


export default function DataTableTBody(props: ITBodyProps) {
    const { data, fields, actions, dispatchColumnWidths, columnWidths } = props;
    const tdClasses = "";

    return (
        <tbody style={{ position: "relative" }}>
            {data.map((dataItem, index) => (
                <tr key={`dtr-${index}`} style={dataItem.rowStyles}>
                    {fields.map((field, fIndex) => {
                        const style: CSSProperties = {
                            ...styles.td,
                            ...field.cellStyle
                        };

                        if (props.stickyHorizontalCols !== undefined && fIndex <= props.stickyHorizontalCols ) {
                            style.position = "sticky";
                            style.left = calcHorizotalStickyleft(fIndex, columnWidths);
                            style.zIndex = 10;
                        }
                        
                        return (
                            <td
                                className={tdClasses}
                                key={`dtd-${fIndex}`}
                                //@ts-ignore
                                style={style}
                                ref={(node) => {

                                    if (columnWidths[fIndex] === undefined && node?.clientWidth) {
                                        dispatchColumnWidths({type: "setWidth", payload: {index: fIndex, width: node?.clientWidth}})
                                    }
                                }}
                            >
                                {field.mutator
                                    ? field.mutator(dataItem)
                                    : dataItem[field.field]}
                            </td>
                        );
                    })}
                    {actions !== undefined && actions.length > 0 && (
                        <td className={tdClasses}>
                            <ButtonToolbar>
                                {actions.map((a, index) => {
                                    if (
                                        a.condition !== undefined &&
                                        a.condition(dataItem) === false
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <Button
                                            key={`foo-${index}`}
                                            onClick={a.action.bind(
                                                null,
                                                dataItem
                                            )}
                                            variant={a.btnVariant || "primary"}
                                            size="sm"
                                            className="mr-2"
                                            disabled={a.inProgress === true}
                                        >
                                            {a.btnTitle}{" "}
                                            {a.inProgress === true ? "..." : ""}
                                        </Button>
                                    );
                                })}
                            </ButtonToolbar>
                        </td>
                    )}
                </tr>
            ))}
        </tbody>
    );
}
