import React, { FC } from "react";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import TabBody from "../../../../../_sharedComponents/TabBody";
import DocumentList from "../../containers/DocumentList";
import EditPropertyTopNav from "../../components/EditPropertyTopNav";

interface IProps {}

const Documents: FC<IProps> = () => {
    return (
        <PageWrap>
            <EditPropertyTopNav />
            <TabBody>
                <DocumentList />
            </TabBody>
        </PageWrap>
    );
};

export default Documents;
