import { AsyncActionState } from "../../../utils/asyncAction";
import * as types from "../../../actions/property/notes";
import { IPropertyNote } from "../../../../api/_types";

export const setCreateNoteStatus = (status: AsyncActionState) =>
    ({
        type: types.PropertyNotesTypes.CREATE_NOTE_STATUS,
        payload: status
    } as types.CreateNoteStatus);

export const resetCreateNoteStatus = setCreateNoteStatus.bind(
    undefined,
    AsyncActionState.Reset
);

export const setUpdateNoteStatus = (status: AsyncActionState) =>
    ({
        type: types.PropertyNotesTypes.UPDATE_NOTE_STATUS,
        payload: status
    } as types.UpdateNoteStatus);

export const resetUpdateNoteStatus = setUpdateNoteStatus.bind(
    undefined,
    AsyncActionState.Reset
);

export const setDeleteNoteStatus = (status: AsyncActionState) =>
    ({
        type: types.PropertyNotesTypes.DELETE_NOTE_STATUS,
        payload: status
    } as types.DeleteNoteStatus);

export const resetDeleteNoteStatus = setDeleteNoteStatus.bind(
    undefined,
    AsyncActionState.Reset
);

export const setNoteToEdit = (note: IPropertyNote | undefined) =>
    ({
        type: types.PropertyNotesTypes.SET_NOTE_TO_EDIT,
        payload: note
    } as types.SetNoteToEdit);
