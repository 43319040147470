import React, { Component } from "react";
import "./styles.scss";

interface IProps {}
interface IState {}

export default class TabBody extends Component<IProps, IState> {
    render() {
        return <div id="tab-body">{this.props.children}</div>;
    }
}
