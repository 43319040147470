import React from "react";
import styles from "./styles";

interface IProps {
    color?: string;
    width?: number;
}

export default function Track(props: IProps) {
    const { color = "grey", width = 10 } = props;
    return (
        <>
            <div data-testid="track" style={styles.track(width, color)} />
        </>
    );
}
