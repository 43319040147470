import React, { FC, ChangeEvent, useReducer, FormEvent } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { IAdditionalContact, IAdditionalContactOptions } from "../../../../../../../../../../api/_types";

interface IProps {
    show: boolean;
    save: (contact: IAdditionalContact) => void;
    editingContact?: IAdditionalContact;
    cancel: () => void;
}

const initialState: IAdditionalContact = {
    firstName: "",
    lastName: "",
    email: "",
    tel: "",
    options: {memo: false}
}

const AddContactPrompt: FC<IProps> = ({show, editingContact, save, cancel}) => {

    const [state, dispatch] = useReducer((state, action: {type: "update", payload: {
            key: keyof IAdditionalContact,
            value: any
        }}) => {
        switch (action.type) {
            case "update": {
                return Object.assign(state,  {[action.payload.key]: action.payload.value});
            }
            default:
                return state;
        }
    }, editingContact || initialState);
    const submit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        save(state);
        cancel();
    }
    const onChange = ({currentTarget}:ChangeEvent<HTMLInputElement>) => dispatch({
        type: "update",
        payload: {
            key: currentTarget.name as keyof IAdditionalContact,
            value: currentTarget.value
        }
    });
    const renderTextControl = (label: string, name: string, required: boolean = true) => <>
        <Form.Label>
            {label}
        </Form.Label>
        <Form.Control
            type="text"
            name={name}
            required={required}
            onChange={onChange}
            defaultValue={state[name]}
        />
    </>

    return (
        <Modal show={show} onHide={cancel} centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{editingContact === undefined ? "Add the new contact" : "Edit contact"}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={submit}>
                <Modal.Body>
                    {renderTextControl("First name", "firstName")}
                    {renderTextControl("Last name", "lastName")}
                    {renderTextControl("Email", "email")}
                    {renderTextControl("Telephone", "tel")}
                    <Form.Label>
                        Options
                    </Form.Label>
                    <Form.Check
                        label="Memorandum of sale recipient"
                        id="send-memo"
                        onChange={() => {
                            dispatch({
                                type: "update",
                                payload: {
                                    key: "options",
                                    value: {
                                        ...state.options,
                                        memo: !state.options.memo
                                    } as IAdditionalContactOptions
                                }
                            });
                        }}
                        checked={state.options?.memo}
                        custom={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={cancel}>
                        Cancel
                    </Button>
                    <Button variant="outline-primary" type="submit">
                        Confirm
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default AddContactPrompt;
