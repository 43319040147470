import React, { PropsWithChildren } from "react";
import LoadingContainer from "../LoadingContainer";
import BackButton from "../BackButton";

interface IProps {
    loading?: boolean;
    goBack: () => void;
}

export default function HorizontalTabNabSubPage(
    props: PropsWithChildren<IProps>
) {
    const { loading = false, goBack } = props;
    return (
        <LoadingContainer loading={loading}>
            <BackButton goBack={goBack} />
            <hr />

            {props.children}
        </LoadingContainer>
    );
}
