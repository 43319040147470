import React from "react";
import ContactSearchInput from "./SearchInput";

interface IProps {
    placeholder?: string;
    updateSearchText: (text: string) => void;
    getContacts: () => void;
}

export default function ContactListHeaderOptions(props: IProps) {
    const { updateSearchText, getContacts, placeholder } = props;
    return (
        <div className="d-flex flex-row">
            <div style={{ flex: 1, minWidth: "300px", marginBottom: 15 }}>
                <ContactSearchInput
                    updateSearchText={updateSearchText}
                    search={getContacts}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
}
