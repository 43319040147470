import React from "react";

interface IProps {
    size?: number;
}

export default function UserAvatarPlaceholder(props: IProps) {
    const { size = 450 } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 450 450"
        >
            <g id="L2">
                <g id="L21" data-name="Layer 1">
                    <rect fill="#ddd" width="450" height="450" rx="31" />
                    <path
                        fill="#00A9CE"
                        d="M62.38,338.83V374.7A12.93,12.93,0,0,0,75.3,387.62H374.7a12.93,12.93,0,0,0,12.92-12.92V338.83C387.62,229.88,62.38,229.88,62.38,338.83Z"
                    />
                    <circle fill="#00A9CE" cx="225" cy="143.69" r="81.31" />
                </g>
            </g>
        </svg>
    );
}
