import React, { Component } from "react";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import ManagementInformationForm from "../../components/ManagementInformationForm";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import {
    savePropertyMI,
    resetSavePropertyMI
} from "../../../../../../redux/api/property";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import {
    IProperty,
    IPropertyManagementInformation
} from "../../../../../../api/_types";

interface IProps {
    property?: IProperty;
    saveMIStatus: AsyncActionState;
    savePropertyMI: (miObject: IPropertyManagementInformation) => void;
    resetSavePropertyMI: () => void;
}
interface IState {}

class ManagementInformationFormContainer extends Component<IProps, IState> {
    componentDidUpdate(prevProps: IProps) {
        const { property, resetSavePropertyMI } = this.props;
        if (prevProps.property !== property) {
            resetSavePropertyMI();
        }
    }

    resetSaveMIState = () => {
        this.props.resetSavePropertyMI();
    };

    saveMI = (miObject: IPropertyManagementInformation) => {
        return this.props.savePropertyMI(miObject);
    };

    render() {
        const { property, saveMIStatus } = this.props;

        return (
            <>
                <LoadingContainer
                    loading={
                        property === undefined || property.mi === undefined
                    }
                    success={false}
                    failure={false}
                >
                    {property?.mi !== undefined && (
                        <ManagementInformationForm
                            miModel={property.mi}
                            saveMIStatus={saveMIStatus}
                            save={this.saveMI}
                            resetSaveMIState={this.resetSaveMIState}
                        />
                    )}
                </LoadingContainer>
            </>
        );
    }
}

const mapStateToProps = ({ property }: IStore) => ({
    property: property.viewingProperty,
    saveMIStatus: property.saveMIStatus
});

export default connect(
    mapStateToProps,
    { savePropertyMI, resetSavePropertyMI }
)(ManagementInformationFormContainer);
