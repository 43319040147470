import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { IPropertyOffer } from "../../../../../../../api/_types";

type OfferFields = Pick<IPropertyOffer, "tel">;

interface IProps extends OfferFields {
    onChange: (key: string) => (e: any) => void;
}

const OfferTel: FC<IProps> = ({ tel, onChange }) => {
    return (
        <Form.Group controlId="tel">
            <Form.Label>Telephone</Form.Label>
            <Form.Control onChange={onChange("tel")} value={tel} />
        </Form.Group>
    );
};

export default OfferTel;
