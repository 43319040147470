import React, { Component } from "react";
import { connect } from "react-redux";
import { IBranch } from "../../../../../../api/_types";
import { IStore } from "../../../../../../redux/store";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import TabBody from "../../../../../_sharedComponents/TabBody";
import EditBranchTopNav from "../../../branch/containers/EditBranchTopNav";
import PropertyListContainer from "../../containers/PropertyList";

interface IProps {
    branch?: IBranch;
}
interface IState {}

class ManagePropertyScreen extends Component<IProps, IState> {
    render() {
        const { branch } = this.props;

        if (branch === undefined) {
            return null;
        }

        return (
            <PageWrap>
                <EntityTitle entityName={branch.name} />
                <EditBranchTopNav />
                <TabBody>
                    <PropertyListContainer type="branch" />
                </TabBody>
            </PageWrap>
        );
    }
}

const mapStateToProps = ({ user, branch }: IStore) => ({
    user: user.me,
    branch: branch.viewingBranch
});

export default connect(
    mapStateToProps,
    {}
)(ManagePropertyScreen);
