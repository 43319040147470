import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { withRouter, RouteComponentProps } from "react-router";
import routes from "../../../../../../../navigation/routes";

interface IProps {
    itemKey: number;
}

const GoToVendorFeedback: FC<IProps & RouteComponentProps> = ({
    history,
    itemKey
}) => {
    const onClick = () =>
        history.push(`${routes.propertyNotes.url}?itemKey=${itemKey}`);
    return (
        <Button variant="warning" size="sm" onClick={onClick}>
            View feedback
        </Button>
    );
};

export default withRouter(GoToVendorFeedback);
