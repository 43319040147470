import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { IPropertyOffer } from "../../../../../../../api/_types";

type OfferFields = Pick<IPropertyOffer, "notes">;

interface IProps extends OfferFields {
    onChange: (key: string) => (e: any) => void;
}

const OfferNotes: FC<IProps> = ({ notes, onChange }) => {
    return (
        <Form.Group controlId="notes">
            <Form.Label>Notes</Form.Label>
            <Form.Control
                as="textarea"
                onChange={onChange("notes")}
                value={notes}
            />
        </Form.Group>
    );
};

export default OfferNotes;
