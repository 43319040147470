import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { IPropertyOffer } from "../../../../../../../api/_types";

type OfferFields = Pick<IPropertyOffer, "chain">;

interface IProps extends OfferFields {
    canAcceptOffer: boolean;
    onChange: (key: string) => (e: any) => void;
}

const PartOfChain: FC<IProps> = ({ chain, canAcceptOffer, onChange }) => {
    return (
        <Form.Group controlId="chain">
            <Form.Label>Is this offer part of a chain?</Form.Label>
            <Form.Check
                custom={true}
                type="radio"
                disabled={canAcceptOffer === false}
                id="chainYes"
                name="chain"
                label={"Yes"}
                required
                onChange={onChange("chain") as any}
                checked={(chain as any) === "1" || chain === true}
                value={"1"}
            />
            <Form.Check
                custom={true}
                type="radio"
                id="chainNo"
                name="chain"
                label={"No"}
                required
                onChange={onChange("chain") as any}
                checked={(chain as any) === "0" || chain === false}
                value={"0"}
            />
        </Form.Group>
    );
};

export default PartOfChain;
