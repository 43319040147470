import React, { Component } from "react";
import { connect } from "react-redux";
import { IStore } from "../../../redux/store";
import { getCompanies } from "../../../redux/api/company";
import LoadingContainer from "../LoadingContainer";
import { CompaniesByMonthChart } from "./CompanyGrowthChart/CompaniesByMonthChart";
import { BranchesPerCompanyChart } from "./BranchesPerCompanyChart/BranchesPerCompanyChart";
import { ICompany, IPagingParams } from "../../../api/_types";
import { AsyncActionState } from "../../../redux/utils/asyncAction";

interface IProps {
    companies: ICompany[];
    getCompaniesStatus: AsyncActionState;
    getCompanies: (params?: IPagingParams) => void;
}

interface IState {}

export class ChartContainer extends Component<IProps, IState> {
    componentDidMount() {
        this.props.getCompanies();
    }

    render() {
        const { companies, getCompaniesStatus } = this.props;

        return (
            <LoadingContainer
                loading={getCompaniesStatus === AsyncActionState.Pending}
            >
                <div className="">
                    <CompaniesByMonthChart companies={companies} />
                    <CompaniesByMonthChart companies={companies} />
                    <BranchesPerCompanyChart companies={companies} />
                </div>
            </LoadingContainer>
        );
    }
}

const mapStateToProps = ({ company }: IStore) => ({
    companies: company.companies.companies,
    getCompaniesStatus: company.getCompaniesStatus
});

export default connect(
    mapStateToProps,
    { getCompanies }
)(ChartContainer);
