import { Dispatch } from "redux";
import { IStore, persistConfig } from "../../store";
import user from "../../../api/modules/user";
import { purgeStoredState } from "redux-persist";
import { setCookie } from "../../../lib/utils/cookies";
import sessionStorage from "../../../lib/utils/sessionStorage";
export default {};

export const logout = () => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { REACT_APP_LOGOUT_REDIRECT_URL } = process.env;
        if (REACT_APP_LOGOUT_REDIRECT_URL === undefined) {
            throw new Error(
                "Environment variable REACT_APP_LOGOUT_REDIRECT_URL requried."
            );
        }

        // Log the user out of the platform
        try {
            await user.logout();
        } catch (e) {
            console.error("Error logging user out", e);
        }

        // Clear state
        purgeStoredState(persistConfig);

        setCookie("sessionInfo", "");

        // Clear any cached data in session storage
        sessionStorage.clear();

        window.location.href = REACT_APP_LOGOUT_REDIRECT_URL;
    };
};
