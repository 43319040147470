import React, { FC } from "react";
import useQuery from "../../../../../../lib/utils/hooks/useQuery";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import TabBody from "../../../../../_sharedComponents/TabBody";
import EditPropertyTopNav from "../../components/EditPropertyTopNav";
import NotesList from "../../containers/NotesList";

interface IProps {}

const Notes: FC<IProps> = () => {
    const query = useQuery();
    const itemKey = query.get("itemKey");
    let defaultItemKey: number | undefined;
    if (itemKey !== null) {
        defaultItemKey = parseInt(itemKey, 10);
    }

    return (
        <PageWrap>
            <EditPropertyTopNav />
            <TabBody>
                <NotesList defaultFilterKey={defaultItemKey} />
            </TabBody>
        </PageWrap>
    );
};

export default Notes;
