import * as React from "react";
import { FC } from "react";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";

interface IProps {
    onChange: (emails: string[]) => void;
    isValid?: (email: string) => boolean;
    invalidEmail?: string;
}

const EmailInput: FC<IProps> = ({
    invalidEmail,
    onChange,
    isValid = () => true
}) => {
    const [emails, setEmails] = React.useState<string[]>([]);
    const [showInvalidFeedback, setShowInvalidFeedback] = React.useState(false);

    return (
        <>
            <ReactMultiEmail
                placeholder="Email address..."
                emails={emails}
                onChange={(_emails: string[]) => {
                    setEmails(_emails);
                    onChange(_emails);
                }}
                validateEmail={(email) => {
                    const _isValid = isValid(email);
                    setShowInvalidFeedback(!_isValid);
                    return isEmail(email) && _isValid;
                }}
                getLabel={(
                    email: string,
                    index: number,
                    removeEmail: (index: number) => void
                ) => {
                    return (
                        <div data-tag key={index}>
                            {email}
                            <span
                                data-tag-handle
                                onClick={() => {
                                    const newEmails = [...emails];
                                    newEmails.splice(index, 1);
                                    setEmails(newEmails);
                                    onChange(newEmails);
                                }}
                            >
                                ×
                            </span>
                        </div>
                    );
                }}
            />
            {showInvalidFeedback === true && (
                <p>
                    <small>{invalidEmail}</small>
                </p>
            )}
        </>
    );
};

EmailInput.defaultProps = {
    isValid: () => true
};

export default EmailInput;
