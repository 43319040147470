import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { IAddress } from "../../../api/_types";

interface IProps {
    address: IAddress;
    readonly?: boolean;
    disabled?: boolean;
    validateAddress?: boolean;
    onChangeInputField: (stateField: string) => (event: any) => void;
}
interface IState {
    validateAddress: boolean;
}

export default class AddressForm extends Component<IProps, IState> {
    state: IState = {
        validateAddress: true
    };

    render() {
        const {
            address = {} as IAddress,
            onChangeInputField,
            readonly = false,
            disabled = false,
            validateAddress = true
        } = this.props;
        return (
            <>
                <Form.Group>
                    <Form.Label>Address line 1</Form.Label>
                    <Form.Control
                        type="text"
                        className="mb-2"
                        onChange={onChangeInputField("address.line1")}
                        value={address.line1}
                        readOnly={readonly}
                        disabled={disabled}
                        required={validateAddress}
                    />
                    <Form.Label>Address line 2</Form.Label>
                    <Form.Control
                        type="text"
                        className="mb-2"
                        onChange={onChangeInputField("address.line2")}
                        value={address.line2}
                        readOnly={readonly}
                        disabled={disabled}
                    />
                    <Form.Label>Town</Form.Label>
                    <Form.Control
                        type="text"
                        className="mb-2"
                        onChange={onChangeInputField("address.town")}
                        value={address.town}
                        readOnly={readonly}
                        disabled={disabled}
                    />
                    <Form.Label>County</Form.Label>
                    <Form.Control
                        type="text"
                        className="mb-2"
                        onChange={onChangeInputField("address.county")}
                        value={address.county}
                        readOnly={readonly}
                        disabled={disabled}
                    />
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control
                        type="text"
                        className="mb-2"
                        onChange={onChangeInputField("address.postcode")}
                        value={address.postcode}
                        readOnly={readonly}
                        disabled={disabled}
                        required={validateAddress}
                    />
                </Form.Group>
            </>
        );
    }
}
