import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { PriceOption } from "../../../../../../api/modules/property";
import { IPropertyAskingPrice } from "../../../../../../api/_types";
import {
    addMIAskingPrice,
    resetAddAskingPrice
} from "../../../../../../redux/api/property";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import CreateAskingPriceForm from "../../components/CreateAskingPriceForm";

const mapStateToProps = ({ property }: IStore) => ({
    addMIAskingPriceStatus: property.addMIAskingPriceStatus,
    askingPriceList: property?.viewingProperty?.mi?.askingPrice || []
});

const connector = connect(
    mapStateToProps,
    { addMIAskingPrice, resetAddAskingPrice }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {}
interface IState {
    askingPrice: IPropertyAskingPrice;
    dirty: boolean;
}

class CreateAskingPriceFormContainer extends Component<IProps, IState> {
    private readonly defaultAskingPrice: IPropertyAskingPrice = {
        price: 0,
        effectiveDate: new Date().toISOString(),
        priceOption: PriceOption.FixedPrice
    };
    generateDefaultAskingPrice = () => {
        return Object.assign({}, this.defaultAskingPrice, {
            price:
                this.props.askingPriceList.length > 0
                    ? this.props.askingPriceList[0].price
                    : 0
        });
    };
    state: IState = {
        askingPrice: this.generateDefaultAskingPrice(),
        dirty: false
    };

    componentWillUnmount() {
        this.props.resetAddAskingPrice();
    }

    componentDidUpdate(prevProps: IProps) {
        // Reset form state when an asking price has been added
        if (
            prevProps.addMIAskingPriceStatus !== AsyncActionState.Success &&
            this.props.addMIAskingPriceStatus === AsyncActionState.Success
        ) {
            this.setState({
                askingPrice: this.generateDefaultAskingPrice(),
                dirty: false
            });
        }
    }

    handleSave = () => {
        const { askingPrice } = this.state;
        this.props.addMIAskingPrice(
            askingPrice.price,
            askingPrice.effectiveDate,
            askingPrice.priceOption
        );
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "askingPrice"
        );
    };

    render() {
        const { askingPrice, dirty } = this.state;
        const { addMIAskingPriceStatus, resetAddAskingPrice } = this.props;
        return (
            <>
                <NavigationPrompt when={dirty === true} />
                <LoadingContainer
                    loading={
                        addMIAskingPriceStatus === AsyncActionState.Pending
                    }
                >
                    <CreateAskingPriceForm
                        askingPrice={askingPrice}
                        save={this.handleSave}
                        onChangeInputField={this.onChangeInputField}
                        saveStatus={addMIAskingPriceStatus}
                        resetAsyncStatus={resetAddAskingPrice}
                    />
                </LoadingContainer>
            </>
        );
    }
}

export default connector(CreateAskingPriceFormContainer);
