import React from "react";
import FeedbackAlert from "../../../../../../_sharedComponents/FeedbackAlert";

interface IProps {
    currentLimit: number;
    branchJustCreated: boolean;
    closeFeedback?: () => void;
}

export default function AdditionalLicensesRequired(props: IProps) {
    const { currentLimit, branchJustCreated, closeFeedback } = props;

    return (
        <>
            {branchJustCreated && (
                <div className="mb-2">
                    <FeedbackAlert
                        variant="success"
                        heading="Branch created"
                        onClose={closeFeedback}
                        message="Your branch was created sucessfully. You've been redirected to this page due to this being the last branch you can create with your available licenses."
                    />
                </div>
            )}
            <h3>You require additional licenses to create another branch.</h3>
            <p>You currently have {currentLimit} licenses.</p>
            <p>Please contact Kriva to purchase any additional licenses.</p>
        </>
    );
}
