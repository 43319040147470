import React, { Component } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import cdn from "../../../../../../api/modules/cdn";
import { ICompany, IUser } from "../../../../../../api/_types";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import ACLUserType from "../../../../../_sharedComponents/ACLUserType";
import AddressForm from "../../../../../_sharedComponents/AddressForm";
import CompanyImagePlaceholder from "../../../../../_sharedComponents/CompanyImagePlaceholder";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import UserCard from "../../../user/components/UserCard";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import { Link } from "react-router-dom";
import NavigationPrompt from "../../../../../_sharedComponents/NavigationPrompt";
import { ChromePicker } from "react-color";

interface IProps {
    user: IUser;
    company?: ICompany;
    saveCompanyPending: boolean;
    saveCompanyError: boolean;
    saveCompanySuccess: boolean;
    saveText?: string;
    createCompany: (company: ICompany, file?: File) => void;
    resetCreateCompanyFormState: () => void;
    deleteCompany?: () => void;
    deletePhoto?: () => void;
}

interface IAdditionalCompanyFields {}

interface IStateCompany extends ICompany, IAdditionalCompanyFields {}

interface IState {
    company: IStateCompany;
    previewURL: string;
    file?: File;
    dirty: boolean;
}

export default class CreateCompanyForm extends Component<IProps, IState> {
    generateInitialCompany = (company?: ICompany): IStateCompany => {
        let obj = company || {
            name: "",
            availableLicenses: 1,
            address: {
                line1: "",
                line2: "",
                town: "",
                county: "",
                country: "",
                postcode: ""
            },
            tel: "",
            branches: [],
            feeDeduction: undefined,
            branding: {
                brandColour: undefined
            }
        };
        return {
            ...obj,
            administrator: {
                firstName: "",
                lastName: "",
                tel: "",
                type: UserTypes.BRANCH_ADMIN,
                password: "",
                confirmPassword: "",
                email: ""
            }
        };
    };

    state: IState = {
        company: this.generateInitialCompany(this.props.company),
        file: undefined,
        previewURL: "",
        dirty: false
    };

    componentWillMount() {
        this.props.resetCreateCompanyFormState();
    }

    componentDidUpdate(prevProps: IProps) {
        // Reset for create company
        if (
            this.props.company === undefined &&
            prevProps.saveCompanyPending === true &&
            this.props.saveCompanySuccess === true
        ) {
            this.resetFormState();
        }

        // Reset for editing a company
        if (
            this.props.company !== undefined &&
            prevProps.company !== undefined &&
            this.props.company !== prevProps.company
        ) {
            this.resetFormState();
        }
    }

    componentWillUnmount() {
        this.resetFormState();
    }

    resetFormState = () => {
        this.setState({
            company: this.generateInitialCompany(this.props.company),
            file: undefined,
            previewURL: "",
            dirty: false
        });
    };

    handleFormSubmit = () => {
        const { company, file } = this.state;
        const { createCompany } = this.props;

        createCompany(company, file);
    };

    onChangeInputField = (stateField: string) => {
        return getStateFromOnChangeUpdate(
            this,
            this.state,
            stateField,
            "company"
        );
    };

    handleFileChange = (event: any) => {
        this.setState({
            file: (event.target as any).files[0],
            previewURL: window.URL.createObjectURL(event.target.files[0])
        });
    };

    handlePressFileInput = () => {
        let fileInput = document.getElementById("companyFileInput");

        if (fileInput !== null) {
            fileInput.click();
        }
    };

    render() {
        const {
            saveCompanyError,
            saveCompanySuccess,
            resetCreateCompanyFormState,
            user,
            deleteCompany,
            saveText = "Save company",
            deletePhoto
        } = this.props;

        if (user === undefined) {
            return null;
        }
        const { company } = this.state;
        const isCreatingCompany = company._id === undefined;
        const inputsEnabled = [
            UserTypes.GYA_ADMIN,
            UserTypes.COMPANY_ADMIN
        ].includes(user.type);

        return (
            <>
                <NavigationPrompt when={this.state.dirty === true} />

                <FormContainer
                    submit={(e) => {
                        e.preventDefault();
                        this.handleFormSubmit();
                    }}
                    showError={saveCompanyError}
                    showSuccess={saveCompanySuccess}
                    success={{
                        heading: "Company saved",
                        message:
                            "You can now use this company for anything you like, you super hero!",
                        customContent: (
                            <p>
                                <Link
                                    to="/branch/create"
                                    style={{ color: "white" }}
                                    href="#"
                                >
                                    Create a new branch
                                </Link>{" "}
                                for this company?
                            </p>
                        )
                    }}
                    error={{
                        heading: "Error saving company",
                        message:
                            "Your company was not created due to an error with the submission to the API."
                    }}
                    onFeedbackMessageClose={resetCreateCompanyFormState}
                    actions={
                        <>
                            <ACLUserType
                                types={[
                                    UserTypes.GYA_ADMIN,
                                    UserTypes.COMPANY_ADMIN
                                ]}
                            >
                                <Button size="lg" type="submit">
                                    {saveText}
                                </Button>
                            </ACLUserType>
                            {this.props.company !== undefined && (
                                <ACLUserType types={[UserTypes.GYA_ADMIN]}>
                                    <Button
                                        size="lg"
                                        variant="danger"
                                        className="ml-2"
                                        onClick={deleteCompany}
                                    >
                                        Delete company
                                    </Button>
                                </ACLUserType>
                            )}
                        </>
                    }
                >
                    <Form.Row>
                        <Col md={6}>
                            <Form.Group id="createCompanyForm.name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={this.onChangeInputField("name")}
                                    value={company.name}
                                    placeholder=""
                                    required={true}
                                    minLength={1}
                                    disabled={inputsEnabled === false}
                                />
                            </Form.Group>
                            <ACLUserType
                                types={[
                                    UserTypes.GYA_ADMIN,
                                    UserTypes.COMPANY_ADMIN
                                ]}
                            >
                                <Form.Group id="createCompanyForm.tel">
                                    <Form.Label>Telephone number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        onChange={this.onChangeInputField(
                                            "tel"
                                        )}
                                        value={company.tel.toString()}
                                        disabled={inputsEnabled === false}
                                    />
                                </Form.Group>
                                <Form.Group id="createCompanyForm.availableLicenses">
                                    <Form.Label>Available licenses</Form.Label>
                                    <Form.Text className="text-muted mb-2">
                                        A company can only create as many
                                        branches as they have licenses.
                                    </Form.Text>
                                    <Form.Control
                                        type="number"
                                        required={true}
                                        min={1}
                                        onChange={this.onChangeInputField(
                                            "availableLicenses"
                                        )}
                                        value={company.availableLicenses.toString()}
                                        disabled={
                                            inputsEnabled === false ||
                                            user.type ===
                                                UserTypes.COMPANY_ADMIN
                                        }
                                    />
                                </Form.Group>
                            </ACLUserType>
                            <AddressForm
                                address={
                                    this.props.company
                                        ? this.props.company.address
                                        : company.address
                                }
                                onChangeInputField={this.onChangeInputField}
                                disabled={inputsEnabled === false}
                            />

                            <Form.Group id="createCompanyForm.feeDeduction">
                                <Form.Label>Fee deduction </Form.Label>
                                <Form.Text className="text-muted mb-2">
                                    Fee percentage deducted from your the property agency fees, for example franchise fee.
                                </Form.Text>
                                <Form.Control
                                    type="number"
                                    required={false}
                                    min={0}
                                    max={100}
                                    step=".01"
                                    onChange={this.onChangeInputField(
                                        "feeDeduction"
                                    )}
                                    value={company.feeDeduction?.toString()}
                                    disabled={
                                        inputsEnabled === false ||
                                        user.type ===
                                        UserTypes.COMPANY_ADMIN
                                    }
                                />
                            </Form.Group>

                        </Col>
                        <Col md={6}>
                            <div className="ml-2">
                                <Row>
                                    <Col style={{ marginBottom: 5 }}>
                                        {this.state.previewURL !== "" ||
                                        (this.props.company &&
                                            this.props.company.logo) ? (
                                            <Image
                                                thumbnail
                                                style={{ maxHeight: "250px" }}
                                                src={
                                                    this.state.previewURL === ""
                                                        ? cdn.getObjectURL(
                                                              //@ts-ignore
                                                              this.props.company
                                                                  .logo.Key
                                                          )
                                                        : this.state.previewURL
                                                }
                                            />
                                        ) : (
                                            <CompanyImagePlaceholder
                                                size={200}
                                            />
                                        )}

                                        <ACLUserType
                                            types={[
                                                UserTypes.GYA_ADMIN,
                                                UserTypes.COMPANY_ADMIN
                                            ]}
                                        >
                                            <div className="mt-2">
                                                <Button
                                                    className="mr-2"
                                                    onClick={
                                                        this
                                                            .handlePressFileInput
                                                    }
                                                >
                                                    Select image
                                                </Button>
                                                {this.state.previewURL !==
                                                    "" && (
                                                    <Button
                                                        variant="danger"
                                                        onClick={() =>
                                                            this.setState({
                                                                file: undefined,
                                                                previewURL: ""
                                                            })
                                                        }
                                                    >
                                                        Clear
                                                    </Button>
                                                )}
                                                {company._id !== undefined &&
                                                    company.logo !==
                                                        undefined &&
                                                    this.state.previewURL ===
                                                        "" && (
                                                        <Button
                                                            variant="danger"
                                                            onClick={
                                                                deletePhoto
                                                            }
                                                        >
                                                            Delete image
                                                        </Button>
                                                    )}
                                            </div>

                                            <Form.Control
                                                id="companyFileInput"
                                                hidden
                                                as="input"
                                                type="file"
                                                onChange={this.handleFileChange}
                                            />

                                            <Form.Label>Brand colour</Form.Label>
                                            <Form.Text className="text-muted mb-2">
                                                The sidebar of this application will use this colour
                                            </Form.Text>
                                            <ChromePicker
                                                color={company?.branding?.brandColour!}
                                                disableAlpha={true}
                                                onChangeComplete={(color) => {
                                                    this.onChangeInputField("branding.brandColour")({
                                                        currentTarget: {
                                                            value: color.hex
                                                        }
                                                    } as any);
                                                }}
                                            />
                                        </ACLUserType>
                                    </Col>
                                </Row>
                                {isCreatingCompany === true && (
                                    <Row>
                                        <Col>
                                            <h4>Company Administrator</h4>
                                            <p className="text-muted">
                                                Enter the details of the user.
                                            </p>
                                            <UserCard
                                                user={
                                                    this.state.company
                                                        .administrator as IUser
                                                }
                                                updateUserEmail={this.onChangeInputField(
                                                    "administrator.email"
                                                )}
                                                updateUserFirstName={this.onChangeInputField(
                                                    "administrator.firstName"
                                                )}
                                                updateUserLastName={this.onChangeInputField(
                                                    "administrator.lastName"
                                                )}
                                                updateUserPassword={this.onChangeInputField(
                                                    "administrator.password"
                                                )}
                                                updateUserConfirmPassword={this.onChangeInputField(
                                                    "administrator.confirmPassword"
                                                )}
                                                updateUserTel={this.onChangeInputField(
                                                    "administrator.tel"
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        </Col>
                    </Form.Row>
                </FormContainer>
            </>
        );
    }
}
