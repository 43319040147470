import React from "react";
import moment from "moment";
import { IPropertyTimelineEntity } from "../../../../../../../api/_types";

interface IProps {
    item: IPropertyTimelineEntity;
    dateFormat?: string;
}

export default function CompletedOn(props: IProps) {
    const { item, dateFormat = "ll" } = props;

    return (
        <>
            <hr />
            <small className="float-right">
                Completed on: {moment(item.completedOn).format(dateFormat)}
            </small>
        </>
    );
}
