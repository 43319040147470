import React, { Component } from "react";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import {
    IPagingResponse,
    IUser,
    IPagingParams
} from "../../../../../../api/_types";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import CompanyUserList from "../../components/CompanyUserList";
import { searchCompanyMembers } from "../../../../../../redux/api/company";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import { setUpdateCompanyAdmin } from "../../../../../../redux/acreators/company";
import EditCompanyUserContainer from "../EditCompanyUserForm";
import BackButton from "../../../../../_sharedComponents/BackButton";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";

interface IProps {
    me?: IUser;
    editMember?: IUser;
    companyUsers: IUser[];
    companyUsersPaging: IPagingResponse;
    searchStatus: AsyncActionState;
    editMemberStatus: AsyncActionState;
    setUpdateCompanyAdmin: (admin?: IUser) => void;
    searchCompanyMembers: (
        searchString: string,
        paging: IPagingParams,
        type?:
            | UserTypes.BRANCH_ADMIN
            | UserTypes.BRANCH_STAFF
            | UserTypes.COMPANY_ADMIN
    ) => void;
}

class ManageCompanyUsersContainer extends Component<IProps> {
    componentDidMount() {
        this.props.setUpdateCompanyAdmin(undefined);
        this.searchUsers();
    }
    componentWillUnmount() {
        this.props.setUpdateCompanyAdmin(undefined);
    }
    searchUsers = (paging?: IPagingResponse) => {
        const { searchCompanyMembers, companyUsersPaging } = this.props;

        searchCompanyMembers(
            "",
            paging || companyUsersPaging,
            UserTypes.COMPANY_ADMIN
        );
    };

    handlePageUsers = (desiredPage: number) => {
        const { companyUsersPaging } = this.props;

        this.searchUsers({
            currentPage: desiredPage,
            itemsPerPage: companyUsersPaging.itemsPerPage
        } as IPagingResponse);
    };
    render() {
        const {
            me,
            companyUsers,
            companyUsersPaging,
            editMember,
            editMemberStatus
        } = this.props;

        if (me === undefined) {
            return null;
        }

        if (editMember !== undefined) {
            return (
                <LoadingContainer
                    loading={editMemberStatus === AsyncActionState.Pending}
                >
                    <BackButton
                        goBack={() => {
                            this.props.setUpdateCompanyAdmin(undefined);
                        }}
                    />
                    <hr />

                    <EditCompanyUserContainer />
                </LoadingContainer>
            );
        }

        return (
            <CompanyUserList
                me={me}
                users={companyUsers}
                paging={companyUsersPaging}
                pageUsers={this.handlePageUsers}
                setUpdateCompanyAdmin={this.props.setUpdateCompanyAdmin}
            />
        );
    }
}

const mapStateToProps = ({ company, user }: IStore) => {
    return {
        me: user.me,
        companyUsers: company.members.users,
        companyUsersPaging: company.members.paging,
        searchStatus: company.searchCompanyMembersStatus,
        editMember: company.editMember,
        editMemberStatus: company.editCompanyAdminStatus
    };
};

export default connect(
    mapStateToProps,
    { searchCompanyMembers, setUpdateCompanyAdmin }
)(ManageCompanyUsersContainer);
