import { Dispatch } from "redux";
import api from "../../../api";
import { ICompanyContactPOC, IPagingParams } from "../../../api/_types";
import {
    createCompanyContactPOCStatus,
    getCompanyContactPOCsStatus,
    setCompanyContactPOCs,
    deleteCompanyContactPOCStatus,
    editCompanyContactPOCStatus
} from "../../acreators/companyContactPOCs";
import { IStore } from "../../store";
import { AsyncActionState } from "../../utils/asyncAction";
import { validatePropertyEntities } from "../utils";

export const getCompanyContactPOCs = (
    textSearch: string,
    paging: IPagingParams = {
        itemsPerPage: 10,
        currentPage: 0
    }
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const storeState = getState();
        const companyCheck = validatePropertyEntities(storeState, {
            company: true
        });
        const viewingContact = storeState.companyContacts.viewingContact;

        if (companyCheck === false || viewingContact === undefined) {
            return dispatch(
                getCompanyContactPOCsStatus(AsyncActionState.Error)
            );
        }

        dispatch(getCompanyContactPOCsStatus(AsyncActionState.Pending));

        try {
            const result = await api.companyContactPOCs.getPOCs({
                companyId: companyCheck.company._id as string,
                contactId: viewingContact._id as string,
                paging,
                textSearch
            });
            dispatch(setCompanyContactPOCs(result.data.data));
        } catch (e) {
            return dispatch(
                getCompanyContactPOCsStatus(AsyncActionState.Error)
            );
        }

        dispatch(getCompanyContactPOCsStatus(AsyncActionState.Success));
    };
};

export const createCompanyContactPOC = (poc: ICompanyContactPOC) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const storeState = getState();
        const companyCheck = validatePropertyEntities(storeState, {
            company: true
        });
        const viewingContact = storeState.companyContacts.viewingContact;

        if (companyCheck === false || viewingContact === undefined) {
            return dispatch(
                createCompanyContactPOCStatus(AsyncActionState.Error)
            );
        }

        dispatch(createCompanyContactPOCStatus(AsyncActionState.Pending));

        try {
            // Create the contact
            await api.companyContactPOCs.createPOC(
                companyCheck.company._id as string,
                viewingContact._id as string,
                poc
            );
            //Refresh list of contacts POCs
            await getCompanyContactPOCs("")(dispatch, getState);
        } catch (e) {
            return dispatch(
                createCompanyContactPOCStatus(AsyncActionState.Error)
            );
        }

        dispatch(createCompanyContactPOCStatus(AsyncActionState.Success));
    };
};

export const editCompanyContactPOC = (payload: {
    name?: string;
    tel?: string;
    email?: string;
}) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const storeState = getState();
        const companyCheck = validatePropertyEntities(storeState, {
            company: true
        });
        const viewingContact = storeState.companyContacts.viewingContact;
        const viewingContactPOC =
            storeState.companyContactPOCs.viewingContactPOC;

        if (
            companyCheck === false ||
            viewingContact === undefined ||
            viewingContactPOC === undefined
        ) {
            return dispatch(
                editCompanyContactPOCStatus(AsyncActionState.Error)
            );
        }

        dispatch(editCompanyContactPOCStatus(AsyncActionState.Pending));

        try {
            // Create the contact
            await api.companyContactPOCs.editPOC(
                companyCheck.company._id as string,
                viewingContact._id as string,
                viewingContactPOC._id as string,
                payload
            );

            //Refresh list of contacts POCs
            await getCompanyContactPOCs("")(dispatch, getState);
        } catch (e) {
            return dispatch(
                editCompanyContactPOCStatus(AsyncActionState.Error)
            );
        }

        dispatch(editCompanyContactPOCStatus(AsyncActionState.Success));
    };
};

export const deleteCompanyContactPOC = (pocId: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const storeState = getState();
        const companyCheck = validatePropertyEntities(storeState, {
            company: true
        });
        const viewingContact = storeState.companyContacts.viewingContact;

        if (companyCheck === false || viewingContact === undefined) {
            return dispatch(
                deleteCompanyContactPOCStatus(AsyncActionState.Error)
            );
        }

        dispatch(deleteCompanyContactPOCStatus(AsyncActionState.Pending));

        try {
            // Delete the POC
            await api.companyContactPOCs.deletePOC(
                companyCheck.company._id as string,
                pocId,
                viewingContact._id as string
            );

            //Refresh list of contacts POCs
            await getCompanyContactPOCs("")(dispatch, getState);
        } catch (e) {
            return dispatch(
                deleteCompanyContactPOCStatus(AsyncActionState.Error)
            );
        }

        dispatch(deleteCompanyContactPOCStatus(AsyncActionState.Success));
    };
};
