import React, { Component } from "react";
import BranchListContainer from "../../containers/BranchList";
import EditCompanyTopNav from "../../../company/components/EditCompanyTopNav";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import TabBody from "../../../../../_sharedComponents/TabBody";
import { ICompany } from "../../../../../../api/_types";

interface IProps {
    company?: ICompany;
}
interface IState {}

class ManageBranchScreen extends Component<IProps, IState> {
    render() {
        const { company } = this.props;

        if (company === undefined) {
            return null;
        }

        return (
            <PageWrap>
                <EntityTitle entityName={company.name} />
                <EditCompanyTopNav />
                <TabBody>
                    <BranchListContainer />
                </TabBody>
            </PageWrap>
        );
    }
}

const mapStateToProps = ({ user, company }: IStore) => ({
    company: company.viewingCompany
});

export default connect(
    mapStateToProps,
    {}
)(ManageBranchScreen);
