import React, { Component } from "react";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import ChartContainer from "../../../../../_sharedComponents/Charts/ChartContainer";

interface IProps {}
interface IState {}

class DashboardMainScreen extends Component<IProps, IState> {
    render() {
        return (
            <PageWrap>
                <ChartContainer/>
            </PageWrap>
        );
    }
}

export default DashboardMainScreen;