import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { IPagingResponse, IPropertyNote } from "../../../../../../api/_types";
import {
    resetCreateNoteStatus, setNoteToEdit
} from "../../../../../../redux/acreators/property/notes";
import { deleteNote } from "../../../../../../redux/api/property/notes";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import DataTable from "../../../../../_sharedComponents/DataTable";
import CreateNote from "../CreateNote";
import EditNote from "../EditNote";
import NoteFilter from "../NoteFilter";
import AddNoteLink from "./AddNoteLink";

const mapStateToProps = ({ branch, property, notes }: IStore) => ({
    property: property.viewingProperty,
    timelineTemplate: branch.viewingBranch?.timelineTemplate,
    deleteNoteStatus: notes.deleteNoteStatus,
    noteToEdit: notes.noteToEdit
});

const connector = connect(
    mapStateToProps,
    {
        deleteNote,
        setNoteToEdit,
        resetCreateNoteStatus
    }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {
    defaultFilterKey?: number;
}

interface IState {
    showDeleteModal: boolean;
    showCreateModal: boolean;
    noteToDelete?: IPropertyNote;
    filterByTimelineKey?: number;
    notesLocalPaging: IPagingResponse;
    notesLocal: IPropertyNote[];
    notesLocalGrouped: IPropertyNote[][]
}

class NoteListContainer extends Component<IProps, IState> {

    defaultItemsPerPage = 10;

    constructor(props:IProps) {

        super(props);

        const {notesLocalGrouped} = this.mapNotesFromProps(this.defaultItemsPerPage, this.props.defaultFilterKey );

        this.state = {
            showDeleteModal: false,
            showCreateModal: false,
            noteToDelete: undefined,
            filterByTimelineKey: this.props.defaultFilterKey,
            notesLocalGrouped,
            notesLocal: notesLocalGrouped[0] || [],
            notesLocalPaging: {
                nextPage: 1,
                currentPage: 0,
                count: this.props.property?.notes.length || 0,
                totalPages: notesLocalGrouped.length - 1,
                itemsPerPage: this.defaultItemsPerPage,
                prevPage: 0
            }
        }
    }

    componentDidUpdate (prevProps: IProps) {
        if (prevProps.property?.notes !== this.props.property?.notes) {
            this.refreshNotesList();
        }
    }

    componentWillMount() {
        this.props.setNoteToEdit(undefined as any);
    }

    refreshNotesList = () => {
        const {notesLocalGrouped} = this.mapNotesFromProps(this.defaultItemsPerPage, this.props.defaultFilterKey );

        const currentPageIndex = this.state.notesLocalPaging.currentPage;
        const newCurrentPage = Math.min(notesLocalGrouped.length - 1, currentPageIndex);

        this.setState({
            notesLocalGrouped,
            notesLocal: notesLocalGrouped[newCurrentPage] || [],
            notesLocalPaging: {
                ...this.state.notesLocalPaging,
                currentPage: newCurrentPage,
                nextPage: Math.min(newCurrentPage, notesLocalGrouped.length),
                prevPage: newCurrentPage - 1,
                count: this.props.property?.notes.length || 0,
                totalPages: notesLocalGrouped.length - 1,
            }

        });
    }

    setFilterByTimelineKey = (filterByTimelineKey?: number) => {

        const {notesLocalGrouped, notes} = this.mapNotesFromProps(this.state.notesLocalPaging.itemsPerPage, filterByTimelineKey);

        this.setState({
            filterByTimelineKey,
            notesLocalGrouped,
            notesLocal: notesLocalGrouped[0] || [],
            notesLocalPaging: {
                ...this.state.notesLocalPaging,
                totalPages: notesLocalGrouped.length - 1,
                count: notes.length,
                currentPage: 0,
                nextPage: 1,
                prevPage: 0,

            }
        });
    };

    onLocalPagerClick = (pageNumber: number) => {
        const {notesLocalGrouped, notesLocalPaging} = this.state;

        this.setState({
            notesLocal: notesLocalGrouped[pageNumber],
            notesLocalPaging: {...notesLocalPaging, currentPage: pageNumber, nextPage: Math.min(pageNumber, notesLocalGrouped.length), prevPage: pageNumber - 1 }
        });
    }

    onLocalItemsPerPageClick = (itemsPerPage: number) => {

        const {notes,notesLocalGrouped} = this.mapNotesFromProps(itemsPerPage, this.state.filterByTimelineKey);


        this.setState({
            notesLocalGrouped,
            notesLocal: notesLocalGrouped[0],
            notesLocalPaging: {...this.state.notesLocalPaging, count: notes.length, itemsPerPage, totalPages: notesLocalGrouped.length -1, currentPage: 0}
        });
    }

    mapNotesFromProps = (itemsPerPage = this.defaultItemsPerPage, filterKey?: number) => {
        let notes:IPropertyNote[] = _.cloneDeep(this.props.property?.notes) || [];

        if (filterKey !== undefined) {

            notes = notes.filter((n) => {
                // -1 is to handle filtering by notes which aren;t for the timeline
                if (filterKey === -1 ) {
                    return n.timelineKey === undefined;
                }
                return n.timelineKey === filterKey
            });
        }

        const notesLocalGrouped = _.chunk(notes, itemsPerPage);

        return {notesLocalGrouped, notes};
    };

    showCreateModal = () => {
        this.setState({
            showCreateModal: true
        });
    };

    hideCreateModal = () => {
        this.props.resetCreateNoteStatus();
        this.setState({
            showCreateModal: false
        });
    };

    showDeleteModal = (noteToDelete: IPropertyNote) => {
        this.setState({
            showDeleteModal: true,
            noteToDelete
        });
    };

    hideDeleteModal = () => {
        this.props.resetCreateNoteStatus();
        this.setState({
            showDeleteModal: false,
            noteToDelete: undefined
        });
    };

    handleDeleteNote = () => {
        const { noteToDelete } = this.state;
        if (noteToDelete === undefined || noteToDelete._id === undefined) {
            alert("Invalid note.");
            return;
        }
        this.props.deleteNote(noteToDelete._id);
    };

    render() {
        const { property, deleteNoteStatus, timelineTemplate } = this.props;
        const { showDeleteModal, showCreateModal, filterByTimelineKey, notesLocalPaging, notesLocal } = this.state;
        if (property === undefined) {
            return null;
        }

        return (
            <>
                <CreateNote
                    show={showCreateModal === true}
                    close={this.hideCreateModal}
                />

                <EditNote />

                <Row className="mb-2">
                    <Col>
                        <NoteFilter onChange={this.setFilterByTimelineKey} currentKey={filterByTimelineKey}/>
                    </Col>
                    <Col>
                        <AddNoteLink
                            address={property.address}
                            open={this.showCreateModal}
                        />
                    </Col>
                </Row>

                <DataTable
                    data={notesLocal}
                    showLoadingSpinner={
                        deleteNoteStatus === AsyncActionState.Pending
                    }
                    showPager={true}
                    pagingElement={notesLocalPaging}
                    onItemsPerPageChange={this.onLocalItemsPerPageClick}
                    onPagerPageClick={this.onLocalPagerClick}
                    fields={[
                        {
                            field: "createdon",
                            displayName: "Date and time",
                            mutator: (item: IPropertyNote) =>
                                moment(item.updatedon).format("L LT")
                        },
                        {
                            field: "timelineKey",
                            displayName: "Timeline reference",
                            mutator: (item: IPropertyNote) => item.timelineKey ? timelineTemplate?.data.find(templateItem => templateItem.key === item.timelineKey)?.title || "N/A" : "N/A"
                        },
                        {
                            field: "author",
                            displayName: "Author",

                            mutator: (item: IPropertyNote) => {
                                if (typeof item.author === "string") {
                                    return "Unknown.";
                                }
                                return `${item.author.firstName} ${item.author.lastName}`;
                            }
                        },
                        {
                            field: "message",
                            displayName: "Note",
                            cellStyle: {
                                maxWidth: 500,
                                whiteSpace: "pre-line"
                            }
                        }
                    ]}
                    actions={[
                        {
                            btnTitle: "Edit",
                            btnVariant: "light",
                            action: (item) => {
                                this.props.setNoteToEdit(item);
                            }
                        },
                        {
                            btnTitle: "Delete",
                            btnVariant: "danger",
                            action: (item: IPropertyNote) => {
                                this.showDeleteModal(item);
                            }
                        }
                    ]}
                />

                <ActionPrompt
                    actions={[
                        {
                            title: "Delete",
                            btnVariant: "danger",
                            onClick: this.handleDeleteNote,
                            asyncStatus: deleteNoteStatus,
                            onSuccess: this.hideDeleteModal
                        }
                    ]}
                    title="Delete this offer"
                    bodyText="Are you sure you want to delete this offer? This cannot be undone."
                    show={showDeleteModal}
                    cancel={this.hideDeleteModal}
                />
            </>
        );
    }
}

export default connector(NoteListContainer);
