import React, { FC, useMemo } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {  IPropertyNote, IPropertyTimelineEntity, IPropertyTimelineEntityTemplate } from "../../../../../../api/_types";
import { IStore } from "../../../../../../redux/store";

interface IProps {
    currentKey?: number;
    timelineTemplate: IPropertyTimelineEntityTemplate[];
    timelineData: IPropertyTimelineEntity[];
    notes: IPropertyNote[];
    onChange: (key: number) => void;
}

const mapStateToProps = ({property, branch}: IStore) => ({
    timelineTemplate: branch.viewingBranch?.timelineTemplate?.data || [],
    timelineData: property.viewingProperty?.timeline?.data || [],
    notes: property.viewingProperty?.notes || []
});

const actions = {};

const connector = connect(
    mapStateToProps,
    actions
);

const NoteFilter: FC<IProps> = ({currentKey, timelineData, timelineTemplate, notes, onChange}) => {
    const timelineKeys = useMemo(() => timelineData.map(t => t.key), [timelineData]);
    const noteKeys = useMemo(() => new Set(
        notes.filter(n => n.timelineKey).map((n) => n.timelineKey)
    ), [notes]);
    const labels: any = useMemo(() => {
        const obj:any = {
            [-1]: "No timeline reference",
        }

        // Match labels with itess from the template that aree
        // - in the timeline
        // - have at least one note made against it
        timelineData?.forEach((tItem) => {
            obj[tItem.key] = timelineTemplate?.find(
                template => template.key === tItem.key && 
                            noteKeys.has(template.key) && 
                            timelineKeys.includes(template.key)
            )?.title
        });

        return obj;
    }, [timelineData, timelineTemplate, timelineKeys, noteKeys]);

    return (
        <>
            <label>Filter by timeline reference</label>
            <Select
                value={
                    {
                        label: currentKey
                            ? labels[currentKey]
                            : labels[0],
                        value: currentKey
                    } as any
                }
                
                options={
                    [
                        {
                            label: "No timeline reference",
                            value: -1
                        },
                        ...timelineTemplate.filter(item => timelineKeys.includes(item.key) && noteKeys.has(item.key)).map((item) => ({
                        label: item.title,
                        value: item.key
                    })) as Array<{
                        label: string;
                        value: number;
                    }>]
                }
                //@ts-ignore
                onChange={(option: { label: string; value: number }) => onChange(option?.value)}
                isClearable={true}
                
            />
        </>
    );
};

export default connector(NoteFilter);
