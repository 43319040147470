import React, { FC } from "react";
import UserCard from "../../../../../../user/components/UserCard";
import { IPagingResponse, IPropertyContacts, IUser } from "../../../../../../../../../api/_types";
import DataTable from "../../../../../../../../_sharedComponents/DataTable";
import { Form } from "react-bootstrap";
import UserAvatar from "../../../../../../user/components/Avatar";

interface IProps {
    contacts: IPropertyContacts;
    branchMembers: IUser[];
    branchMembersPaging: IPagingResponse;
    checkUser: (user: IUser) => void;
    pageBranchMembers: (index: number) => void;
}

export const bStaffCheckTestId = (userId: string) => `bstaff-check-${userId}`

const BStaffEntry: FC<IProps> = ({
    contacts,
    branchMembers,
    branchMembersPaging,
    checkUser,
    pageBranchMembers
}) => {
    return (
        <>
            <UserCard
                user={(contacts.bStaff || {}) as IUser}
                readonly={true}
            />
            <DataTable
                data={branchMembers}
                showPager={true}
                pagingElement={branchMembersPaging}
                onPagerPageClick={pageBranchMembers}
                fields={[
                    {
                        field: "",
                        displayName: "",
                        mutator: (item) => (
                            <Form.Check
                                onClick={() => checkUser(item)}
                                checked={
                                    contacts.bStaff !== undefined &&
                                    contacts.bStaff !== null &&
                                    item._id ===
                                    (contacts.bStaff as IUser)._id
                                }
                                id={`user-check-${item._id}`}
                                label=""
                                custom={true}
                                data-testid={bStaffCheckTestId(item._id)}
                            />
                        )
                    },
                    {
                        field: "",
                        displayName: "Name",
                        mutator: (item) => (
                            <div className="d-flex flex-direction-row align-items-center">
                                <UserAvatar
                                    user={item}
                                    size={40}
                                    rounded={true}
                                    containerStyle={{ marginRight: 10 }}
                                />
                                {item.firstName} {item.lastName}
                            </div>
                        )
                    },
                    {
                        field: "email",
                        displayName: "Email"
                    }
                ]}
            />
        </>
    );
};

export default BStaffEntry;
