import React, { Component } from "react";
import ManageBranchForm from "../../components/ManageBranchForm";
import { connect } from "react-redux";
import { IStore } from "../../../../../../redux/store";
import {
    saveBranch,
    resetCreateBranchAdminRef
} from "../../../../../../redux/api/branch";
import {
    resetManageBranchFormState,
    resetBranchAdminSearchResults
} from "../../../../../../redux/api/branch";
import TabBody from "../../../../../_sharedComponents/TabBody";
import { IUser, IBranch, ICompany } from "../../../../../../api/_types";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import AdditionalLicensesRequired from "./AdditionalLicensesRequired";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";
import { Link } from "react-router-dom";
import routes from "../../../../../../navigation/routes";

interface IProps {
    user?: IUser;
    company?: ICompany;
    createBranchStatus: AsyncActionState;
    createBranchAdministratorRef?: IUser | string;
    saveBranch: (branch: IBranch, file?: File) => void;
    resetManageBranchFormState: () => void;
    resetCreateBranchAdminRef: () => void;
    resetBranchAdminSearchResults: () => void;
}

interface IState {}

class CreateBranchContainer extends Component<IProps, IState> {
    render() {
        const {
            user,
            saveBranch,
            company,
            createBranchStatus,
            resetManageBranchFormState,
            createBranchAdministratorRef,
            resetBranchAdminSearchResults,
            resetCreateBranchAdminRef
        } = this.props;

        if (user === undefined || company === undefined) {
            return null;
        }

        return (
            <TabBody>
            {company.branches.length === 0 && (
                <h5><Link to={routes.companyTimeline.url}> Review</Link> your company timeline before creating your first branch.</h5>
            )} 
                {company.availableLicenses <= company.branches.length ? (
                    <AdditionalLicensesRequired
                        currentLimit={company.availableLicenses}
                        branchJustCreated={
                            createBranchStatus === AsyncActionState.Success
                        }
                        closeFeedback={resetManageBranchFormState}
                    />
                ) : (
                    <LoadingContainer
                        loading={
                            createBranchStatus === AsyncActionState.Pending
                        }
                    >
                        <ManageBranchForm
                            user={user}
                            createBranch={saveBranch}
                            createBranchPending={
                                createBranchStatus === AsyncActionState.Pending
                            }
                            createBranchError={
                                createBranchStatus === AsyncActionState.Error
                            }
                            createBranchSuccess={
                                createBranchStatus === AsyncActionState.Success
                            }
                            resetManageBranchFormState={
                                resetManageBranchFormState
                            }
                            createBranchAdministratorRef={
                                createBranchAdministratorRef
                            }
                            resetBranchAdminSearchResults={
                                resetBranchAdminSearchResults
                            }
                            resetCreateBranchAdminRef={
                                resetCreateBranchAdminRef
                            }
                            saveText={"Create branch"}
                        />
                    </LoadingContainer>
                )}
            </TabBody>
        );
    }
}

const mapStateToProps = ({ company, branch, user }: IStore) => ({
    user: user.me,
    company: company.viewingCompany,
    createBranchStatus: branch.createBranchStatus,
    createBranchAdministratorRef: branch.createBranchAdministratorRef
});

export default connect(
    mapStateToProps,
    {
        saveBranch,
        resetManageBranchFormState,
        resetCreateBranchAdminRef,
        resetBranchAdminSearchResults
    }
)(CreateBranchContainer);
