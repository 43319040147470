import React from "react";
import Typeahead from "../../../../../_sharedComponents/Typeahead";
import {
    CompanyInputArray,
    ICompanyInputDropdownItemModelRecord,
    ICompanyInputDropdownItemModelNewRecord
} from "../../containers/ContactCard/types";

interface IProps {
    data: CompanyInputArray;
    handleOnInputChange: (str: string) => void;
    handleOnChange: (item: CompanyInputArray) => void;
    formatDisplay: (
        item:
            | ICompanyInputDropdownItemModelRecord
            | ICompanyInputDropdownItemModelNewRecord
    ) => string;
}

export default function CompanyTypeahead(props: IProps) {
    const { data, formatDisplay, handleOnChange, handleOnInputChange } = props;
    return (
        <Typeahead
            id="company-contact-typeahead"
            displayTitle="Search existing company"
            minLength={1}
            data={data}
            multiple={false}
            isLoading={false}
            emptyLabel="No company found."
            inputPlaceholder="Enter company name"
            labelKey="_name"
            handleOnInputChange={handleOnInputChange}
            handleOnChange={handleOnChange}
            formatItemDisplay={formatDisplay}
        />
    );
}
