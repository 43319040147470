import React from "react";
import { IUser } from "../../../../../../api/_types";
import routes from "../../../../../../navigation/routes";
import TabNav from "../../../../../_sharedComponents/TabNav";

interface IProps {
    user?: IUser;
}

export default function ManageAdminTabs(props: IProps) {
    const links = [
        {
            to: routes.gyaAdminUsers.url,
            displayTitle: "Users"
        },
        {
            to: routes.gyaAddAdminUser.url,
            displayTitle: "Create User"
        }
    ];

    return <TabNav links={links} />;
}
