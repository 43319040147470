import { faChevronDown, faChevronRight, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import property, { MIReportSortOption, TimelineKeys } from "../../../../../../api/modules/property";
import {
    IMIReportEntity,
    IPagingParams,
    IPropertyGetListFilters
} from "../../../../../../api/_types";
import { downloadFileToClient } from "../../../../../../lib/utils/files";
import sessionStorage from "../../../../../../lib/utils/sessionStorage";
import { getBranches } from "../../../../../../redux/api/branch";
import {
    getMIReport,
    loadPropertyPage
} from "../../../../../../redux/api/property";
import { IStore } from "../../../../../../redux/store";
import { ChainStatus } from "../../../../../../redux/_types/property/types";
import ChainIcon from "../../../../../_sharedComponents/Chain";
import { IDataTableField } from "../../../../../_sharedComponents/DataTable/types";
import TabBody from "../../../../../_sharedComponents/TabBody";
import { buildFilterObj, IPropertyFilterKeyVal } from "../../../property/containers/PropertyList/filters";
import MIReportTable from "../../components/MIReportTable";
import TimelineTableItem from "../../components/MIReportTable/TimelineTableItem";
import styles from "./styles";

interface IState {
    properties: IMIReportEntity[][];
    currentIndex: number;
    filterBranches: Array<{label: string, value: string}>;
    sortOption: MIReportSortOption;
    visibleGroups: IVisibleGroups;
    chainStatus: ChainStatus[];
    bStaffIds: Array<{label: string, value: string}>;
    startDate: string | Date;
    endDate: string | Date;
    filter: IPropertyFilterKeyVal[];
    searchText?: string;
}

export type VisibleGroupKey = "preSale" | "saleProcess" | "financial";

export interface IVisibleGroups {
    preSale: boolean;
    saleProcess: boolean;
    financial: boolean;
}

export interface IFieldGroups {
    address: IDataTableField[];
    preSale: IDataTableField[];
    saleProcess: IDataTableField[];
    financial: IDataTableField[];
}


const mapStateToProps = ({ company, branch, property }: IStore) => ({
    company: company.viewingCompany,
    miReportData: property.miReportData,
    miKeyStats: property.miReportKeyStats,
    branches: branch.branches.branches
});


const connector = connect(
    mapStateToProps,
    { getMIReport, getBranches, loadPropertyPage }
)

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {
    type: "branch" | "company";
    branchFilter?: {label: string, value: string};
}

const sortIcon = (state: MIReportSortOption, ascState: MIReportSortOption, descState: MIReportSortOption) => {

    let icon = faChevronRight;
    if (state === ascState) {
        icon = faChevronUp;
    } else if (state === descState) {
        icon = faChevronDown;
    }
    return (
        <FontAwesomeIcon
            style={{
                position: "absolute",
                bottom: 10,
                right: 2
            }}
            size="sm"
            icon={icon}
        />
    );
}


class MIReportContainer extends Component<IProps, IState> {
    itemsPerPage = 75;

    fieldGroups: IFieldGroups;

    exchangedCSSPulseAnimation = "3s infinite pulse";

    constructor(props:IProps) {
        super(props);

        const {existingEndDate, existingStartDate, existingBranches, existingBStaffIds, existingFilter, existingSortOption, existingTextSearch} = this.loadCachedSearchParams();


        this.state = {
            properties: [],
            currentIndex: 0,
            searchText: existingTextSearch || undefined,
            bStaffIds: existingBStaffIds || [],
            filterBranches: props.branchFilter
                ? [props.branchFilter]
                : existingBranches || [],
            sortOption: existingSortOption || MIReportSortOption.SALE_PROGRESS,
            visibleGroups: {
                preSale: true,
                saleProcess: true,
                financial: true
            },
            chainStatus: [],
            startDate: existingStartDate ? new Date(existingStartDate) : moment()
                .subtract("12", "month")
                .toDate(),
            endDate: existingEndDate ? new Date(existingEndDate) : moment().toDate(),
            filter: existingFilter || [
                { key: "sold", value: "1" },
                { key: "active", value: "1" },
                { key: "complete", value: "0" }
            ],
        };

        this.fieldGroups = {
            address: [
                {
                    field: "Offer accepted",
                    onThClick: this.onTimelineClick(MIReportSortOption.OFFER_ACCEPTED_ASC, MIReportSortOption.OFFER_ACCEPTED_DESC),
                    thStyle: styles.sortableTh,
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.OFFER_ACCEPTED_ASC, MIReportSortOption.OFFER_ACCEPTED_DESC),
                    mutator: (item: any) =>
                        <div
                        style={{
                            animation:item.timeline.info.exchanged ? this.exchangedCSSPulseAnimation : ""
                        }}>
                            <span className="text-center"> {item.acceptedOfferDate ? moment(item.acceptedOfferDate).format("DD/MM/YYYY") : ""}</span>
                        </div>
                },
                {
                    field: "Address",
                    onThClick: this.onTimelineClick(MIReportSortOption.ADDRESS_ASC, MIReportSortOption.ADDRESS_DESC),
                    thStyle: styles.sortableTh,
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.ADDRESS_ASC, MIReportSortOption.ADDRESS_DESC),
                    mutator: (item: any) => (
                        <div
                            style={{
                                cursor: "pointer",
                                fontWeight: 600,
                                animation:item.timeline.info.exchanged ? this.exchangedCSSPulseAnimation : ""
                            }}
                            className="text-primary"
                            onClick={() => this.goToProperty(item)}
                        >

                            <span
                                style={{
                                    whiteSpace: "nowrap",
                                    color: "#777",
                                    textDecoration: "underline"
                                }}
                            >
                            {[ChainStatus.CompletedChain, ChainStatus.IncompleteChain].includes(item.mi.chain) && (

                                 <ChainIcon size={20} style={{marginRight: 10}} title="This property is in a chain."/>
                            )}

                                {`${_.truncate(item.address.line1, {
                                    length: 70
                                })}`}
                                -{`${item.address.postcode}`}
                            </span>
                        </div>
                    )
                }
            ],
            financial: [

                {
                    field: "Current asking price",
                    onThClick: this.onTimelineClick(MIReportSortOption.ASKING_PRICE_ASC, MIReportSortOption.ASKING_PRICE_DESC),
                    thStyle: styles.sortableTh,
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.ASKING_PRICE_ASC, MIReportSortOption.ASKING_PRICE_DESC),

                    mutator: (item: any) =>
                        `£${item.mi.askingPrice?.length ? item.mi.askingPrice[0].price?.toLocaleString() : "" }`
                },
                {
                    field: "Agreed sale price",
                    onThClick: this.onTimelineClick(MIReportSortOption.AGREED_PRICE_ASC, MIReportSortOption.AGREED_PRICE_DESC),
                    thStyle: styles.sortableTh,
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.AGREED_PRICE_ASC, MIReportSortOption.AGREED_PRICE_DESC),
                    mutator: (item: any) =>
                        `£${item.mi.agreedPrice?.toLocaleString()}`
                },
                {
                    field: "% of sale",
                    onThClick: this.onTimelineClick(MIReportSortOption.FEE_PERCENT_ASC, MIReportSortOption.FEE_PERCENT_DESC),
                    thStyle: styles.sortableTh,
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.FEE_PERCENT_ASC, MIReportSortOption.FEE_PERCENT_DESC),
                    mutator: (item: any) => (
                        <div className="text-center">
                            {`${item.mi.agencyFeePercent}%`}
                        </div>
                    )
                },
                {
                    field: "Expected fee",
                    onThClick: this.onTimelineClick(MIReportSortOption.EXPECTED_FEE_ASC, MIReportSortOption.EXPECTED_FEE_DESC),
                    thStyle: styles.sortableTh,
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.EXPECTED_FEE_ASC, MIReportSortOption.EXPECTED_FEE_DESC),
                    mutator: (item: any) => (
                        <div className="text-center">{`£${item.mi.agencyFee.toLocaleString()}`}</div>
                    )
                }
            ],
            preSale: [],
            saleProcess: [
                {
                    field: "Memo of sale sent",
                    cellStyle: styles.titleStyles.legal,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.MemoOfSaleSent),
                    onThClick: this.onTimelineClick(MIReportSortOption.MEMO_SALE_ASC, MIReportSortOption.MEMO_SALE_DESC ),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.MEMO_SALE_ASC, MIReportSortOption.MEMO_SALE_DESC),
                    thStyle: styles.sortableTh,
                },
                {
                    field: "Draft contracts issued",
                    cellStyle: styles.titleStyles.legal,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.DraftContractsIssued),
                    onThClick: this.onTimelineClick(MIReportSortOption.DRAFT_CONTRACT_ASC, MIReportSortOption.DRAFT_CONTRACT_DESC),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.DRAFT_CONTRACT_ASC, MIReportSortOption.DRAFT_CONTRACT_DESC),
                    thStyle: styles.sortableTh,
                },
                {
                    field: "Searches applied for",
                    cellStyle: styles.titleStyles.legal,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.SearchesAppliedFor),
                    onThClick: this.onTimelineClick(MIReportSortOption.SEARCHES_APPLIED_ASC, MIReportSortOption.SEARCHES_APPLIED_DESC),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.SEARCHES_APPLIED_ASC, MIReportSortOption.SEARCHES_APPLIED_DESC),
                    thStyle: styles.sortableTh,
                },
                {
                    field: "Searches back",
                    cellStyle: styles.titleStyles.legal,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.SearchesBack),
                    onThClick: this.onTimelineClick(MIReportSortOption.SEARCHES_BACK_ASC, MIReportSortOption.SEARCHES_BACK_DESC),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.SEARCHES_BACK_ASC, MIReportSortOption.SEARCHES_BACK_DESC),
                    thStyle: styles.sortableTh,
                },
                {
                    field: "Enquiries raised",
                    cellStyle: styles.titleStyles.legal,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.EnquiriesRaised),
                    onThClick: this.onTimelineClick(MIReportSortOption.ENQUIRIES_RAISED_ASC, MIReportSortOption.ENQUIRIES_RAISED_DESC),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.ENQUIRIES_RAISED_ASC, MIReportSortOption.ENQUIRIES_RAISED_DESC),
                    thStyle: styles.sortableTh,
                },
                {
                    field: "Enquiries answered",
                    cellStyle: styles.titleStyles.legal,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.EnquiriesAnswered),
                    onThClick: this.onTimelineClick(MIReportSortOption.ENQUIRIES_ANSWERED_ASC, MIReportSortOption.ENQUIRIES_ANSWERED_DESC),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.ENQUIRIES_ANSWERED_ASC, MIReportSortOption.ENQUIRIES_ANSWERED_DESC),
                    thStyle: styles.sortableTh,
                },
                {
                    field: "Surveys complete",
                    cellStyle: styles.titleStyles.survey,
                    onThClick: this.onTimelineClick(MIReportSortOption.SURVEYS_ASC, MIReportSortOption.SURVEYS_DESC),
                    thStyle: styles.sortableTh,
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.SURVEYS_ASC, MIReportSortOption.SURVEYS_DESC),
                    mutator: (item: any) => {

                        const now = moment();
                        const warningDate = moment(item?.upcomingSurveyDate?.warning);
                        const surveyKey = item?.upcomingSurveyDate?.key;

                        if (item.upcomingSurveyDate?.date !== undefined && warningDate.isAfter(now)) {
                            const diffInDays = moment(item.upcomingSurveyDate.date).diff(
                                moment(),
                                "days"
                            );

                            return (
                                <TimelineTableItem
                                    item={item}
                                    timelineEntityKey={item.key}
                                    rawVal={`${diffInDays} days`}
                                />
                            );
                        } else if (warningDate.isBefore(now)) {
                            return (
                                <TimelineTableItem
                                    item={item}
                                    timelineEntityKey={surveyKey}
                                />
                            );
                        }

                        if (
                            item.upcomingSurveyDate?.date === undefined &&
                            item.surveyDate !== null &&
                            item.surveyDate !== undefined &&
                            item.surveysR <= 0
                        ) {
                            return (
                                <TimelineTableItem
                                    item={item}
                                    timelineEntityKey={-1}
                                    overrideValue={item.surveyDate}
                                />
                            );
                        }
                        return <></>;
                    }
                },
                {
                    field: "Mortgage app submitted",
                    cellStyle: styles.titleStyles.financial,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.MortgageAppSubmitted),
                    onThClick: this.onTimelineClick(MIReportSortOption.MORTGAGE_APP_SUBMITTED_ASC, MIReportSortOption.MORTGAGE_APP_SUBMITTED_DESC),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.MORTGAGE_APP_SUBMITTED_ASC, MIReportSortOption.MORTGAGE_APP_SUBMITTED_DESC),
                    thStyle: styles.sortableTh,

                },
                {
                    field: "Mortgage valuation booked",
                    cellStyle: styles.titleStyles.financial,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.MortgageValuationBooked),
                    onThClick: this.onTimelineClick(MIReportSortOption.MORTGAGE_VAL_BOOKED_ASC, MIReportSortOption.MORTGAGE_VAL_BOOKED_DESC),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.MORTGAGE_VAL_BOOKED_ASC, MIReportSortOption.MORTGAGE_VAL_BOOKED_DESC),
                    thStyle: styles.sortableTh,

                },

                {
                    field: "Mortgage valuation completed",
                    cellStyle: styles.titleStyles.financial,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.MortgageValuationCompleted),
                    onThClick: this.onTimelineClick(MIReportSortOption.MORTGAGE_VAL_COMPLETED_ASC, MIReportSortOption.MORTGAGE_VAL_COMPLETED_DESC),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.MORTGAGE_VAL_COMPLETED_ASC, MIReportSortOption.MORTGAGE_VAL_COMPLETED_DESC),
                    thStyle: styles.sortableTh,
                },


                {
                    field: "Mortgage approved",
                    cellStyle: styles.titleStyles.financial,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.MortgageApproved),
                    onThClick: this.onTimelineClick(MIReportSortOption.MORTGAGE_APP_APPROVED_ASC, MIReportSortOption.MORTGAGE_APP_APPROVED_DESC),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.MORTGAGE_APP_APPROVED_ASC, MIReportSortOption.MORTGAGE_APP_APPROVED_DESC),
                    thStyle: styles.sortableTh,

                },
                {
                    field: "Reported to buyer",
                    cellStyle: styles.titleStyles.legal,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.ReportedToBuyer),
                    onThClick: this.onTimelineClick(MIReportSortOption.REPORTED_BUYER_ASC, MIReportSortOption.REPORTED_BUYER_DESC),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.REPORTED_BUYER_ASC, MIReportSortOption.REPORTED_BUYER_DESC),
                    thStyle: styles.sortableTh,
                },
                {
                    field: "Docs & deposits received",
                    cellStyle: styles.titleStyles.legal,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.DocumentsAndDepositsReceived),
                    onThClick: this.onTimelineClick(MIReportSortOption.DOC_DEPOSITS_ASC, MIReportSortOption.DOC_DEPOSITS_DESC),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.DOC_DEPOSITS_ASC, MIReportSortOption.DOC_DEPOSITS_DESC),
                    thStyle: styles.sortableTh,

                },
                {
                    field: "Exchanged",
                    cellStyle: styles.titleStyles.legal,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.Exchanged),
                    onThClick: this.onTimelineClick(MIReportSortOption.EXCHANGED_ASC, MIReportSortOption.EXCHANGED_DESC),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.EXCHANGED_ASC, MIReportSortOption.EXCHANGED_DESC),
                    thStyle: styles.sortableTh,

                },
                {
                    field: "Completed",
                    cellStyle: styles.titleStyles.legal,
                    mutator: (item: any) => this.timelineItemMutator(item, TimelineKeys.Completed),
                    onThClick: this.onTimelineClick(MIReportSortOption.COMPLETED_ASC, MIReportSortOption.COMPLETED_DESC),
                    icon: () => sortIcon(this.state.sortOption, MIReportSortOption.COMPLETED_ASC, MIReportSortOption.COMPLETED_DESC),
                    thStyle: styles.sortableTh,
                }
            ]
        } ;
    }

    onTimelineClick = (asc: MIReportSortOption, desc: MIReportSortOption) => () => {
        let current = this.state.sortOption;
        let next =  desc;
        if (current === desc) {
            next = asc;
        }

        this.updateSort(next);
    }



    componentDidMount() {
        this.fetchReport();
        this.props.getBranches({
            itemsPerPage: 1000,
            currentPage: 0
        });

    }

    componentDidUpdate(prevProps: IProps) {
        const { miReportData } = this.props;

        if (
            miReportData !== undefined &&
            miReportData !== prevProps.miReportData
        ) {
            this.setState({
                properties: _.chunk<IMIReportEntity>(
                    miReportData.properties,
                    this.itemsPerPage
                ),
                currentIndex: 0
            });
        }
    }

    resetFiltersToDefault = () => {
        this.setState({
            filterBranches: this.props.branchFilter
            ? [this.props.branchFilter] : [],
            sortOption: MIReportSortOption.SALE_PROGRESS,
            bStaffIds: [],
            filter: [{ key: "active", value: "1" }, {key: "complete", value: "0"}],
            startDate: moment()
                .subtract("12", "month")
                .toDate(),
            endDate: moment().toDate(),
        }, this.fetchReport)
    };

    loadCachedSearchParams() {
        let existingStartDate: any;
        let existingEndDate: any;
        let existingFilter: any;
        let existingSortOption: any;
        let existingBStaffIds: any;
        let existingBranches: any;
        let existingTextSearch: any;

        try {
            existingStartDate = sessionStorage.getItem("workflow-startDate");
            existingEndDate = sessionStorage.getItem("workflow-endDate");
            existingFilter = sessionStorage.getItem("workflow-filter");
            existingBStaffIds = sessionStorage.getItem("workflow-bStaffIds");
            existingSortOption = sessionStorage.getItem("workflow-sort");
            existingBranches = sessionStorage.getItem("workflow-branches");
            existingTextSearch = sessionStorage.getItem("workflow-text-search");
        } catch (e) {
            return {}
        }

        return {existingStartDate, existingBranches, existingEndDate, existingFilter, existingBStaffIds, existingSortOption, existingTextSearch };
    }

    updateSearchText = (searchText: string) => {
        sessionStorage.setItem("workflow-text-search", searchText);
        this.setState({searchText});
    };

    updateBStaffIds = (users: Array<{label: string, value: string}>) => {
        sessionStorage.setItem("workflow-bStaffIds", users);
        this.setState({bStaffIds:users}, this.fetchReport);
    }

    updateFilter = (filter: IPropertyFilterKeyVal[]) => {
        sessionStorage.setItem("workflow-filter", filter);
        this.setState({filter}, this.fetchReport);
    }

    updateStartDate = (startDate: string | Date) => {
        sessionStorage.setItem("workflow-startDate", startDate);
        this.setState({ startDate }, this.fetchReport);
    }

    updateEndDate = (endDate: string | Date) => {
        sessionStorage.setItem("workflow-startDate", endDate);
        this.setState({ endDate }, this.fetchReport);
    }

    updateSort = (option: MIReportSortOption | null) => {
        if (option === null || option === this.state.sortOption) {
            return;
        }

        sessionStorage.setItem("workflow-sort", option);

        this.setState(
            {
                sortOption: option
            },
            this.fetchReport
        );
    };

    updateChainStatus = (status: ChainStatus[]) => {
        this.setState({ chainStatus: status }, this.fetchReport);
    };


    updateFilterBranches = (branches: Array<{ label: string; value: string }>) => {
        sessionStorage.setItem("workflow-branches", branches);
        this.setState(
            {
                filterBranches: branches  || []
            },
            () => this.fetchReport()
        );
    };

    goToProperty = (item: IMIReportEntity) => {
        this.props.loadPropertyPage(item.company, item.branch, item._id);
    };

    timelineItemMutator = (item: any, timelineEntityKey: number) => {
        return (
            <TimelineTableItem
                item={item}
                timelineEntityKey={timelineEntityKey}
            />
        );
    };

    fetchReport = () => {
        const {filterBranches, sortOption, chainStatus, startDate, endDate, bStaffIds, filter, searchText} = this.state;

        const filters = buildFilterObj<IPropertyGetListFilters>(filter);
        const _bStaffIds = bStaffIds?.map(user => user.value) || [];
        const branchIds = filterBranches?.map(branch => branch.value) || [];

        this.props.getMIReport(
            undefined,
            sortOption,
            {
                branchIds,
                chainStatus,
                dateStart: startDate,
                dateEnd: endDate,
                bStaffIds: _bStaffIds,
                searchText,
                ...filters
            }
        );
    };


    downloadCSV = async () => {
        const {company} = this.props;
        const {filterBranches, sortOption, chainStatus, startDate, endDate, bStaffIds, filter, searchText, visibleGroups} = this.state;

        const filters = buildFilterObj<IPropertyGetListFilters>(filter);
        const _bStaffIds = bStaffIds?.map(user => user.value) || [];
        const branchIds = filterBranches?.map(branch => branch.value) || [];

        try {
            const res = await property.getMIReportCsv(
                company?._id!,
                {} as IPagingParams,
                sortOption,
                {
                    branchIds,
                    chainStatus,
                    dateStart: startDate,
                    dateEnd: endDate,
                    bStaffIds: _bStaffIds,
                    searchText,
                    ...filters
                },
                visibleGroups
            )

            downloadFileToClient(res.data, "Workflow.csv");

        } catch (e) {
            alert("Error downloading CSV.");
        }
    };

    pageProperties = (pageIndex: number) => {
        let newPageIndex = pageIndex;
        const numChunks = this.state.properties.length;
        if (newPageIndex < 0) {
            newPageIndex = 0;
        } else if (newPageIndex > numChunks) {
            newPageIndex = numChunks;
        }

        this.setState({
            currentIndex: newPageIndex
        });
    };

    render() {
        const { type } = this.props;
        const {
            properties,
            currentIndex,
            sortOption,
            visibleGroups,
            filterBranches,
            startDate,
            endDate
        } = this.state;
        const { miReportData, miKeyStats, branches } = this.props;

        if (
            miReportData === undefined ||
            miKeyStats === undefined
        ) {
            return null;
        }

        return (
            <TabBody>
                <MIReportTable
                    chainStatus={this.state.chainStatus}
                    setChainStatus={this.updateChainStatus}
                    type={type}
                    properties={properties[currentIndex]}
                    updateBranchFilter={this.updateFilterBranches}
                    branches={branches}
                    miReportData={miReportData}
                    miKeyStats={miKeyStats}
                    pageProperties={this.pageProperties}
                    numChunks={properties.length - 1}
                    updateSortOption={this.updateSort}
                    sortOption={sortOption}
                    filter={this.state.filter}
                    currentIndex={currentIndex}
                    fieldGroups={this.fieldGroups}
                    visibleGroups={visibleGroups}
                    filterBranches={filterBranches}
                    startDate={startDate}
                    endDate={endDate}
                    updateStartDate={this.updateStartDate}
                    updateEndDate={this.updateEndDate}
                    onChangeBStaff={this.updateBStaffIds}
                    updateFilter={this.updateFilter}
                    clearAllFilters={this.resetFiltersToDefault}
                    bStaffIds={this.state.bStaffIds}
                    search={this.fetchReport}
                    searchText={this.state.searchText}
                    updateSearchText={this.updateSearchText}
                    downloadCsv={this.downloadCSV}
                />
            </TabBody>
        );
    }
}

export default connector(MIReportContainer);
