import { Dispatch } from "redux";
import { IStore } from "../../store";
import api from "../../../api";
import * as acreators from "../../acreators/branch";
import { getCompanyById } from "../company";
import { AsyncActionState } from "../../utils/asyncAction";
import { UserTypes } from "../../_types/user/types";
import {
    ICompany,
    IBranch,
    IUser,
    IPagingParams,
    IPropertyTimelineEntity
} from "../../../api/_types";
import { AxiosResponse } from "axios";
import { createPager } from "../../../lib/utils/pager";

export const getBranches = (
    query: IPagingParams = { itemsPerPage: 10, currentPage: 0 }
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();

        if (company.viewingCompany === undefined) {
            // @Todo handle error
            console.warn("No viewing company in getBranches.");
            return;
        }
        dispatch(acreators.setGetBranchesStatus(AsyncActionState.Pending));

        try {
            const res = await api.branch.getBranches(
                company.viewingCompany._id as string,
                query
            );
            dispatch(acreators.setBranches(res.data));
            dispatch(acreators.setGetBranchesStatus(AsyncActionState.Success));
        } catch (e) {
            dispatch(acreators.setGetBranchesStatus(AsyncActionState.Error));

            console.log("Error getting branches", e);
        }
    };
};

export const getBranchById = (_id: string, companyId?: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        let { company } = getState();

        if (company.viewingCompany === undefined && companyId !== undefined) {
            // Go fetch the company.
            await getCompanyById(companyId)(dispatch);

            company = getState().company;
            if (company.viewingCompany === undefined) {
                //@Todo handle error, could not get the company.
                return;
            }
        }

        dispatch(acreators.setGetBranchStatus(AsyncActionState.Pending));

        try {
            const branch = await api.branch.getBranchById(
                _id,
                (company.viewingCompany as ICompany)._id as string
            );

            if (branch.data.data === undefined || branch.data.data === null) {
                alert("No branch found.");
                return;
            }

            dispatch(
                acreators.setViewingBranch((branch as any).data.data.branch)
            );
            dispatch(acreators.setGetBranchStatus(AsyncActionState.Success));
        } catch (e) {
            console.log("Error getting branch", e);
            dispatch(acreators.setGetBranchStatus(AsyncActionState.Error));
        }
    };
};

export const deleteBranch = (branchId: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();

        if (company.viewingCompany === undefined) {
            return dispatch(
                acreators.setDeleteBranchStatus(AsyncActionState.Error)
            );
        }

        if (company.viewingCompany === undefined) {
            return dispatch(
                acreators.setDeleteBranchStatus(AsyncActionState.Error)
            );
        }

        dispatch(acreators.setDeleteBranchStatus(AsyncActionState.Pending));

        try {
            await api.branch.deleteBranch(branchId, company.viewingCompany
                ._id as string);

            dispatch(acreators.setDeleteBranchStatus(AsyncActionState.Success));
            // Go refresh the list of branches
            await getBranches()(dispatch, getState);

            await getCompanyById(company.viewingCompany._id as string)(
                dispatch
            );
        } catch (error) {
            return dispatch(
                acreators.setDeleteBranchStatus(AsyncActionState.Error)
            );
        }
    };
};

export const saveBranch = (branch: IBranch, file?: File) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();

        if (company.viewingCompany === undefined) {
            return dispatch(
                acreators.setCreateBranchStatus(AsyncActionState.Error)
            );
        }

        dispatch(acreators.setCreateBranchStatus(AsyncActionState.Pending));
        const apiMethod =
            branch._id === undefined
                ? api.branch.createBranch
                : api.branch.editBranch;

        try {
            let response: AxiosResponse<{ data: IBranch }> = await apiMethod(
                branch,
                company.viewingCompany._id as string,
                file
            );

            if (branch._id !== undefined) {
                await getBranchById(
                    branch._id as string,
                    company.viewingCompany._id as string
                )(dispatch, getState);
            } else {
                // Fetch the company so the create form can hide if this
                // was the last branch they could create with their current available licenses.
                await getCompanyById(company.viewingCompany._id as string)(
                    dispatch
                );
                dispatch(acreators.setViewingBranch(response.data.data));
            }

            dispatch(acreators.setCreateBranchStatus(AsyncActionState.Success));
        } catch (error) {
            return dispatch(
                acreators.setCreateBranchStatus(AsyncActionState.Error)
            );
        }
    };
};

export const searchNewBranchAdmin = (searchString: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company } = getState();
        const activeCompany = company.viewingCompany;
        if (activeCompany === undefined) {
            return;
        }

        dispatch(
            acreators.setSearchBranchAdminStatus(AsyncActionState.Pending)
        );

        try {
            const searchResult = await api.company.searchMembers(
                activeCompany._id as string,
                { itemsPerPage: 1, currentPage: 0 },
                searchString,
                UserTypes.BRANCH_ADMIN
            );

            if (searchResult.data !== undefined) {
                dispatch(
                    acreators.setSearchBranchAdminResult(
                        searchResult.data.users
                    )
                );
            }
            dispatch(
                acreators.setSearchBranchAdminStatus(AsyncActionState.Success)
            );
        } catch (error) {
            return dispatch(
                acreators.setSearchBranchAdminStatus(AsyncActionState.Error)
            );
        }
    };
};

export const addBranchMember = (member: string | IUser) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { branch } = getState();

        dispatch(acreators.setAddBranchmemberStatus(AsyncActionState.Pending));

        if (
            branch.viewingBranch === undefined ||
            branch.viewingBranch._id === undefined
        ) {
            return dispatch(
                acreators.setAddBranchmemberStatus(AsyncActionState.Error)
            );
        }

        try {
            await api.branch.addMember(branch.viewingBranch._id, member);
        } catch (e) {
            return dispatch(
                acreators.setAddBranchmemberStatus(AsyncActionState.Error)
            );
        }

        try {
            await searchMembers("")(dispatch, getState);
        } catch (e) {}

        return dispatch(
            acreators.setAddBranchmemberStatus(AsyncActionState.Success)
        );
    };
};

export const removeBranchMember = (memberId: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { branch } = getState();

        dispatch(
            acreators.setRemoveBranchmemberStatus(AsyncActionState.Pending)
        );

        if (
            branch.viewingBranch === undefined ||
            branch.viewingBranch._id === undefined
        ) {
            return dispatch(
                acreators.setRemoveBranchmemberStatus(AsyncActionState.Error)
            );
        }

        try {
            await api.branch.removeMember(branch.viewingBranch._id, memberId);
        } catch (e) {
            return dispatch(
                acreators.setRemoveBranchmemberStatus(AsyncActionState.Error)
            );
        }

        try {
            await searchMembers("")(dispatch, getState);
        } catch (e) {}

        return dispatch(
            acreators.setRemoveBranchmemberStatus(AsyncActionState.Success)
        );
    };
};

export const editBranchMember = (member: IUser) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { branch } = getState();

        dispatch(
            acreators.setUpdateBranchMemberStatus(AsyncActionState.Pending)
        );

        if (
            branch.viewingBranch === undefined ||
            branch.viewingBranch._id === undefined
        ) {
            return dispatch(
                acreators.setUpdateBranchMemberStatus(AsyncActionState.Error)
            );
        }

        try {
            let userRes = await api.branch.editMember(
                branch.viewingBranch._id,
                member
            );

            dispatch(acreators.setUpdateBranchMember(userRes.data.data));
        } catch (e) {
            return dispatch(
                acreators.setUpdateBranchMemberStatus(AsyncActionState.Error)
            );
        }

        return dispatch(
            acreators.setUpdateBranchMemberStatus(AsyncActionState.Success)
        );
    };
};

export const searchMembers = (
    searchString: string,
    paging: IPagingParams = { itemsPerPage: 10, currentPage: 0 }
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { branch, company } = getState();
        const activeCompany = company.viewingCompany;
        const activeBranch = branch.viewingBranch;
        if (activeCompany === undefined) {
            return;
        }
        if (activeBranch === undefined) {
            return;
        }

        dispatch(
            acreators.setSearchBranchMemberStatus(AsyncActionState.Pending)
        );

        try {
            const searchResult = await api.branch.searchMembers(
                activeBranch._id as string,
                searchString,
                paging
            );

            dispatch(acreators.setSearchBranchMemberResult(searchResult.data));

            dispatch(
                acreators.setSearchBranchMemberStatus(AsyncActionState.Success)
            );
        } catch (error) {
            return dispatch(
                acreators.setSearchBranchMemberStatus(AsyncActionState.Error)
            );
        }
    };
};

export const updateBranchTimeline = (
    timelineItems: IPropertyTimelineEntity[]
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { branch, company } = getState();
        let companyId: string;
        let branchId: string;

        dispatch(
            acreators.setUpdateBranchTimelineStatus(AsyncActionState.Pending)
        );

        if (
            branch.viewingBranch === undefined ||
            company.viewingCompany === undefined
        ) {
            return dispatch(
                acreators.setUpdateBranchTimelineStatus(AsyncActionState.Error)
            );
        }

        branchId = branch.viewingBranch._id as string;
        companyId = company.viewingCompany._id as string;

        try {
            await api.branch.updateTimelineTemplate(
                timelineItems,
                companyId,
                branchId
            );
        } catch (e) {
            return dispatch(
                acreators.setUpdateBranchTimelineStatus(AsyncActionState.Error)
            );
        }

        try {
            await getBranchById(branchId, companyId)(dispatch, getState);
        } catch (e) {
            return dispatch(
                acreators.setUpdateBranchTimelineStatus(AsyncActionState.Error)
            );
        }

        dispatch(
            acreators.setUpdateBranchTimelineStatus(AsyncActionState.Success)
        );
    };
};

export const deletePhoto = () => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const { company, branch } = getState();

        if (
            company.viewingCompany === undefined ||
            branch.viewingBranch === undefined
        ) {
            return;
        }

        dispatch(
            acreators.setDeleteBranchPhotoStatus(AsyncActionState.Pending)
        );

        try {
            await api.branch.deletePhoto(
                company.viewingCompany._id as string,
                branch.viewingBranch._id as string
            );
            await getBranchById(
                branch.viewingBranch._id as string,
                company.viewingCompany._id as string
            )(dispatch, getState);
        } catch (e) {
            return dispatch(
                acreators.setDeleteBranchPhotoStatus(AsyncActionState.Error)
            );
        }

        return dispatch(
            acreators.setDeleteBranchPhotoStatus(AsyncActionState.Success)
        );
    };
};

export const resetSearchMembersStatus = () => {
    return acreators.setSearchBranchMemberStatus(AsyncActionState.Reset);
};

export const resetAddBranchMembersStatus = () => {
    return acreators.setAddBranchmemberStatus(AsyncActionState.Reset);
};

export const resetEditBranchMembersStatus = () => {
    return acreators.setUpdateBranchMemberStatus(AsyncActionState.Reset);
};
export const resetManageBranchFormState = () => {
    return acreators.setCreateBranchStatus(AsyncActionState.Reset);
};

export const resetViewingBranch = () => {
    return acreators.setViewingBranch(undefined);
};

export const resetDeleteBranchFormState = () => {
    return acreators.setDeleteBranchStatus(AsyncActionState.Pending);
};

export const resetBranchAdminSearchResults = () => {
    return acreators.setSearchBranchAdminResult([]);
};

export const resetCreateBranchAdminRef = () => {
    return acreators.resetCreateBranchAdministratorRef();
};

export const resetBranchMemberSearchResults = () => {
    return acreators.setSearchBranchMemberResult({
        users: [],
        paging: createPager({ itemsPerPage: 10 })
    });
};

export const resetBranches = () => {
    return acreators.setBranches({
        branches: [],
        paging: createPager({ itemsPerPage: 10 })
    });
};

export const resetDeleteBranchPhotoStatus = () => {
    return acreators.setDeleteBranchPhotoStatus(AsyncActionState.Reset);
};
