import React, { Component, ReactNode } from "react";
import { Container } from "react-bootstrap";
import PageIntro from "./PageInto";

interface IProps {
    children: ReactNode;
    pageTitle?: string;
    pageIntro?: string[];
}
interface IState {}

export default class PageWrap extends Component<IProps, IState> {
    render() {
        const { children, pageIntro, pageTitle } = this.props;
        return (
            <Container fluid={true}>
                {(pageIntro || pageIntro) && (
                    <PageIntro pageTitle={pageTitle} pageIntro={pageIntro} />
                )}
                {children}
            </Container>
        );
    }
}
