import { Dispatch } from "redux";
import { IStore } from "../../../store";
import * as acreators from "../../../acreators/property/notes";
import { AsyncActionState } from "../../../utils/asyncAction";
import api from "../../../../api";
import {
    IPropertyNote,
    ICompany,
    IBranch,
    IProperty,
    ICreatePropertyNoteFields
} from "../../../../api/_types";
import { validatePropertyEntities } from "../../utils";
import { getPropertyById } from "..";

export const createNote = (note: ICreatePropertyNoteFields) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const store = getState();

        dispatch(acreators.setCreateNoteStatus(AsyncActionState.Pending));

        const validateEntities = validatePropertyEntities(store, {
            company: true,
            branch: true,
            property: true
        });

        if (validateEntities === false) {
            return dispatch(
                acreators.setCreateNoteStatus(AsyncActionState.Error)
            );
        }

        try {
            await api.property.createNote(
                note,
                validateEntities.company._id as string,
                validateEntities.branch._id as string,
                validateEntities.property._id as string
            );

            await getPropertyById(validateEntities.property._id as string)(
                dispatch,
                getState
            );
        } catch (e) {
            return dispatch(
                acreators.setCreateNoteStatus(AsyncActionState.Error)
            );
        }

        dispatch(acreators.setCreateNoteStatus(AsyncActionState.Success));
    };
};
export const updateNote = (note: IPropertyNote) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const store = getState();
        const { company, branch, property } = store;
        dispatch(acreators.setUpdateNoteStatus(AsyncActionState.Pending));
        const validateEntities = validatePropertyEntities(store, {
            company: true,
            branch: true,
            property: true
        });
        if (validateEntities === false) {
            return dispatch(
                acreators.setUpdateNoteStatus(AsyncActionState.Error)
            );
        }

        try {
            await api.property.updateNote(
                note,
                (company.viewingCompany as ICompany)._id as string,
                (branch.viewingBranch as IBranch)._id as string,
                (property.viewingProperty as IProperty)._id as string
            );
            await getPropertyById(validateEntities.property._id as string)(
                dispatch,
                getState
            );
        } catch (e) {
            return dispatch(
                acreators.setUpdateNoteStatus(AsyncActionState.Error)
            );
        }

        dispatch(acreators.setUpdateNoteStatus(AsyncActionState.Success));
    };
};

export const deleteNote = (noteId: string) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const store = getState();
        dispatch(acreators.setDeleteNoteStatus(AsyncActionState.Pending));
        const validateEntities = validatePropertyEntities(store, {
            company: true,
            branch: true,
            property: true
        });
        if (validateEntities === false) {
            return dispatch(
                acreators.setUpdateNoteStatus(AsyncActionState.Error)
            );
        }

        try {
            await api.property.deleteNote(
                noteId,
                validateEntities.company._id as string,
                validateEntities.branch._id as string,
                validateEntities.property._id as string
            );
            await getPropertyById(validateEntities.property._id as string)(
                dispatch,
                getState
            );
        } catch (e) {
            return dispatch(
                acreators.setDeleteNoteStatus(AsyncActionState.Error)
            );
        }

        dispatch(acreators.setDeleteNoteStatus(AsyncActionState.Success));
    };
};
