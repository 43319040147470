import React from "react";
import styles from "./styles";

interface IProps {
    color: string;
    side: "left" | "right";
    top?: "top" | "bottom";
}

export default function HorizontalBar(props: IProps) {
    const { color, side, top = "top" } = props;
    return (
        <div
            data-testid="horizontal-bar"
            style={styles.bar({ color, top, side })}
        />
    );
}
