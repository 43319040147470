import { IPropertyChain } from "../../../../api/_types";
import { Dispatch } from "redux";
import { IStore } from "../../../store";
import { setUpdateChainStatus } from "../../../acreators/property/chain";
import { AsyncActionState } from "../../../utils/asyncAction";
import api from "../../../../api";
import { validatePropertyEntities } from "../../utils";
import { getPropertyById } from "..";

export const updateChain = (
    chain: IPropertyChain,
    saveAll: boolean = false,
    resetDeletedPropertyChains?: boolean
) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const state = getState();
        const validateChecks = validatePropertyEntities(state, {
            company: true,
            branch: true,
            property: true
        });
        const saveFunction =
            saveAll === true
                ? api.property.updateAllChain
                : api.property.updateChain;

        if (validateChecks === false) {
            return dispatch(setUpdateChainStatus(AsyncActionState.Error));
        }

        dispatch(setUpdateChainStatus(AsyncActionState.Pending));

        try {
            await saveFunction(
                chain,
                validateChecks.company._id as string,
                validateChecks.branch._id as string,
                validateChecks.property._id as string,
                resetDeletedPropertyChains
            );

            await getPropertyById(validateChecks.property._id as string)(
                dispatch,
                getState
            );
        } catch (e) {
            return dispatch(setUpdateChainStatus(AsyncActionState.Error));
        }

        dispatch(setUpdateChainStatus(AsyncActionState.Success));
    };
};
