import {
    faExclamationTriangle,
    faChevronRight,
    faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent } from "react";
import { Form } from "react-bootstrap";
import { IPropertyTimelineEntity } from "../../../../../../../api/_types";
import moment from "moment";
import { IPropertyTimelineEntityState } from "../../../../../../../redux/_types/property/types";

interface IProps {
    title: string;
    item: IPropertyTimelineEntity;
    collapsible?: boolean;
    collapsed: boolean;
    editingTitle: boolean;
    onChangeTitle: (item: IPropertyTimelineEntity, text: string) => void;
}

export default function TimelineItemHeader(props: IProps) {
    const {
        item,
        collapsed,
        collapsible,
        editingTitle,
        title,
        onChangeTitle
    } = props;

    const isItemOverdueSinceStarting =
        item.completedOn === undefined &&
        item.state === IPropertyTimelineEntityState.IN_PROGRESS &&
        moment(item.startedOn)
            .add(item.daysToComplete, "days")
            .isBefore(moment()) === true;

    return (
        <>
            {isItemOverdueSinceStarting === true && (
                <FontAwesomeIcon
                    data-testid="ti-overdue-icon"
                    icon={faExclamationTriangle}
                    color={"#00A9CE"}
                    style={{
                        marginRight: 5
                    }}
                />
            )}
            {collapsible === true && (
                <FontAwesomeIcon
                    data-testid="ti-collapsible-icon"
                    icon={collapsed === true ? faChevronRight : faChevronDown}
                    style={{
                        marginRight: 5,
                        width: 14
                    }}
                />
            )}
            {editingTitle === false && title}
            {!item?.surveyData?.awaitingReports &&
                item.warningDate !== undefined &&
                moment(item.warningDate).diff(moment(), "hours") <= 0 && (
                    <span style={{ textAlign: "right", flex: 1, color: "red" }}>
                        <small>{moment(item.warningDate).fromNow()}</small>
                    </span>
                )}
            {item?.surveyData?.awaitingReports === true && item.warningDate !== undefined && moment(item.warningDate).diff(moment(), "hours") < 0 && (
                <span style={{ textAlign: "right", flex: 1, color: "orange" }}>
                    <small>{moment(item.warningDate).fromNow()}</small>
                </span>
            )}
            {editingTitle === true && (
                <Form.Control
                    data-testid="ti-title-input"
                    value={title}
                    onChange={(e: FormEvent<any>) =>
                        onChangeTitle(item, e.currentTarget.value as string)
                    }
                />
            )}
        </>
    );
}
