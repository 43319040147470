import React, { Component, FormEvent } from "react";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import moment, { Moment } from "moment";
import { IPropertyAskingPrice } from "../../../../../../api/_types";
import DatePicker from "../../../../../_sharedComponents/DatePicker";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import { PriceOption } from "../../../../../../api/modules/property";

interface IProps {
    saveStatus: AsyncActionState;
    askingPrice: IPropertyAskingPrice;
    onChangeInputField: (stateField: string) => (event: FormEvent<any>) => void;
    save: () => void;
    resetAsyncStatus: () => void;
}
interface IState {}

export default class CreateAskingPriceForm extends Component<IProps, IState> {
    handleSubmit = (event: any) => {
        event.preventDefault();
        this.props.save();
    };

    render() {
        const {
            onChangeInputField,
            askingPrice,
            saveStatus,
            resetAsyncStatus
        } = this.props;
        return (
            <FormContainer
                submit={this.handleSubmit}
                showSuccess={saveStatus === AsyncActionState.Success}
                showError={saveStatus === AsyncActionState.Error}
                success={{
                    heading: "Asking price updated",
                    message: "Your update to the asking price has been saved."
                }}
                error={{
                    heading: "Something sent wrong",
                    message:
                        "Something went wrong updating the asking price, please try again."
                }}
                onFeedbackMessageClose={resetAsyncStatus}
                actions={
                    <Button className="mr-2" variant="primary" type="submit">
                        Save price
                    </Button>
                }
            >
                <Form.Group id="createPropertyForm.priceOption">
                    <Form.Label>Price option</Form.Label>

                    <Form.Control
                        as="select"
                        value={askingPrice.priceOption}
                        onChange={onChangeInputField("priceOption")}
                    >
                        <option value={PriceOption.FixedPrice}>
                            Asking price
                        </option>
                        <option value={PriceOption.GuidePrice}>
                            Guide price
                        </option>
                        <option value={PriceOption.OffersInRegionOf}>
                            Offers in region of
                        </option>
                        <option value={PriceOption.OffersOver}>
                            Offers over
                        </option>
                        <option value={PriceOption.PriceOnApplication}>
                            Price on application
                        </option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="price">
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                        type="number"
                        onChange={onChangeInputField("price")}
                        value={askingPrice.price.toString()}
                        required={true}
                    />
                    <Form.Text className="text-muted">
                        The new asking price
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="effectiveDate">
                    <Form.Label>Effective date</Form.Label>
                    <DatePicker
                        timeFormat={false}
                        onChange={(e: string | Moment) => {
                            let momentTime =
                                typeof e === "string" ? moment(e) : e;
                            onChangeInputField("effectiveDate")({
                                currentTarget: {
                                    value: momentTime.toISOString()
                                }
                            } as any);
                        }}
                        defaultValue={moment(askingPrice.effectiveDate)}
                    />

                    <Form.Text className="text-muted">
                        When was the new asking price effective from?
                    </Form.Text>
                </Form.Group>
            </FormContainer>
        );
    }
}
