import moment from "moment";
import React from "react";

interface IProps {
    label: string;
    date: string | Date;
    momentFormat?: string;
}

export default function DateLabel(props: IProps) {
    const { date, label, momentFormat = "DD/MM/YYYY" } = props;
    return (
        <p data-testid="ti-wdate">
            <strong>{label}:</strong> {moment(date).format(momentFormat)}
        </p>
    );
}
