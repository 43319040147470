import { faPlus, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import usePrevious from "../../../../../../lib/utils/hooks/usePrevious";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import FeedbackAlert from "../../../../../_sharedComponents/FeedbackAlert";

interface IProps {
    uploadStatus: AsyncActionState;
    upload: (file: File, note?: string | undefined) => void;
    resetUploadStatus: () => void;
}

const DocumentUpload: FC<IProps> = ({ uploadStatus, upload, resetUploadStatus }) => {
    const [newFile, setNewFile] = useState<File | undefined>();
    const maxSizeInMb = 20;
    const maxAllowedSize = maxSizeInMb * 1024 * 1024; // 20MB
    const prevUploadStatus = usePrevious(uploadStatus)
;    const uploading = uploadStatus === AsyncActionState.Pending;
    const uploadText = uploading ? "Uploading..." : "Upload"
    const uploadContextColour = newFile ? "#00A9CE" : "grey";
    const confirmUpload = () => {
        if (newFile !== undefined) {
            upload(newFile, "");
        }
    };

    const handleFileChange = ({
        target
    }: React.ChangeEvent<HTMLInputElement>) => {
        const files = target.files;

        if (!files || files.length < 1) {
            return;
        }

        const file = files[0];

        if (file.size > maxAllowedSize) {
            alert("File too large.")
            return;
        }

        setNewFile(file);
    };

    const addFile = () => {
        const fileInput = document.getElementById("fileInput");

        if (fileInput !== null) {
            fileInput.click();
        }
    };

    useEffect(() => {

        const didFinishUploading = prevUploadStatus === AsyncActionState.Pending  && uploadStatus !== AsyncActionState.Pending;

        if (didFinishUploading === true && uploadStatus  === AsyncActionState.Success) {
            setNewFile(undefined);
        }

    }, [prevUploadStatus, uploadStatus]);

    return (
        <Row>
            <Col md="4">
                <Button onClick={addFile} disabled={uploading} size={"sm"}>
                    Add new file
                    <FontAwesomeIcon icon={faPlus} className="ml-1" />
                </Button>
                <Form.Control
                    onChange={handleFileChange}
                    id="fileInput"
                    type="file"
                    as="input"
                    hidden
                />
                <p className="text-muted mt-1"><small>Maximum file size: {maxSizeInMb}mb</small></p>

                {newFile && (
                    <div className="my-2">
                        <span className="d-flex align-items-center">
                            <FontAwesomeIcon
                                icon={faFileAlt}
                                color={uploadContextColour}
                                size="2x"
                            />
                            <span className="ml-2">{newFile?.name}</span>
                        </span>

                        <Button
                            className="d-block my-2"
                            onClick={confirmUpload}
                            variant={"warning"}
                            disabled={newFile === undefined || uploading === true}
                        >
                                {uploadText}
                        </Button>
                        {uploading === true && (
                            <p className="text-muted mt-1"><small>Large files may take some time to upload, please stay on the page whilst the file uploads.</small></p>
                        )}
                    </div>
                )}

                {uploadStatus === AsyncActionState.Error && (
                    <FeedbackAlert
                        message="An error occurred"
                        onClose={resetUploadStatus}
                        dismissible={true}
                        variant="danger"

                    />
                )}
            </Col>
        </Row>
    );
}

export default DocumentUpload;
