import moment from "moment";
import React, { FC, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { OfferState } from "../../../../../../../api/modules/property";
import AmendOfferPrompt from "./AmendPrompt";

interface IProps {
    amount?: number;
    amendAmount?: number;
    amendReason?: string;
    increasedOn?: string|Date|undefined;
    offerState: OfferState;
    onChange: (key: string) =>  (e: any) => void;
    save: ()=> void;
}

const OfferAmount:FC<IProps> = ({amount, amendAmount, amendReason, increasedOn, offerState, onChange, save}) => {

    const [showAmendPrompt, setShowAmendPrompt] = useState(false);
    const openAmendPrompt = () => setShowAmendPrompt(true);
    const closeAmendPrompt = () => setShowAmendPrompt(false);

    return (
        <>
            <AmendOfferPrompt 
                show={showAmendPrompt} 
                hide={closeAmendPrompt} 
                onChange={onChange} 
                amount={amount} 
                amendAmount={amendAmount} 
                amendReason={amendReason} 
                save={save}
            />
            <Form.Group controlId="amount">
            
                <Form.Label>
                    Offer amount{" "}
                    {increasedOn && (
                        <span className="text-success ">
                            <small>
                                Increased on{" "}
                                {moment(increasedOn).format(
                                    "DD/MM/YYYY"
                                )}
                            </small>
                        </span>
                    )}
                </Form.Label>
                {offerState === OfferState.Accepted ?       
                (
                    <p>
                        £{amount?.toLocaleString()}
                        <Button onClick={openAmendPrompt} variant="link">Amend offer</Button>
                    </p>
                )
                : (
                    <Form.Control
                        type="number"
                        onChange={onChange("amount")}
                        value={amount?.toString()}
                        required
                        name="amount"
                        min={1}
                    />
                )}
            </Form.Group>
        </>
    );
};

export default OfferAmount;