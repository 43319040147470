import * as actions from "../../actions/property";
import { AsyncActionState } from "../../utils/asyncAction";
import {
    IProperty,
    IUser,
    IPropertyTimeline,
    IPropertyTimelineEntity,
    IPropertyOffer,
    IPropertyViewing,
    IPropertyAskingPrice,
    IPagingResponse,
    IMIReportResponse,
    IMIKeyStatsResponse
} from "../../../api/_types";
import _ from "lodash";
import { IPropertyTimelineEntityType } from "../../_types/property/types";
import { createPager } from "../../../lib/utils/pager";

export interface IPropertyState {
    properties: {
        paging: IPagingResponse;
        properties: IProperty[];
    };
    viewingProperty?: IProperty;
    createPropertyStatus: AsyncActionState;
    deletePropertyStatus: AsyncActionState;
    getPropertiesStatus: AsyncActionState;
    getPropertyStatus: AsyncActionState;
    addPropertyViewingStatus: AsyncActionState;
    updatePropertyViewingStatus: AsyncActionState;
    removePropertyViewingStatus: AsyncActionState;
    saveMIStatus: AsyncActionState;
    addMIAskingPriceStatus: AsyncActionState;
    updateMIAskingPriceStatus: AsyncActionState;
    removeMIAskingPriceStatus: AsyncActionState;
    addPropertyOfferStatus: AsyncActionState;
    removePropertyOfferStatus: AsyncActionState;
    updatePropertyOfferStatus: AsyncActionState;
    updateTimelineStatus: AsyncActionState;
    getMIReportStatus: AsyncActionState;
    updatePropertyContactsStatus: AsyncActionState;
    sendMiPdfStatus: AsyncActionState;
    miReportData?: IMIReportResponse;
    miReportKeyStats?: IMIKeyStatsResponse;
    createPropertyVendorRef?: string | IUser;
    offerToEdit?: IPropertyOffer;
    viewingToEdit?: IPropertyViewing;
    askingPriceToEdit?: IPropertyAskingPrice;
    resetSaleStatus: AsyncActionState;
    restoreSaleStatus: AsyncActionState;
    deleteRestoreSaleStatus: AsyncActionState;
    deletePhotoStatus: AsyncActionState;
}

export interface IProcessedTimeline {
    [IPropertyTimelineEntityType.COMPLETING]: IPropertyTimelineEntity[];
    [IPropertyTimelineEntityType.FINANCIAL]: IPropertyTimelineEntity[];
    [IPropertyTimelineEntityType.LEGAL]: IPropertyTimelineEntity[];
    [IPropertyTimelineEntityType.SELLING]: IPropertyTimelineEntity[];
    [IPropertyTimelineEntityType.SURVEY]: IPropertyTimelineEntity[];
}

export const unpackTimeline = (timeline: IPropertyTimeline) => {
    return _.groupBy(timeline.data, "type") as any;
};

export const packTimeline = (timeline: IProcessedTimeline) => {
    return { data: _.flatMap(timeline) };
};

const initialState: IPropertyState = {
    properties: {
        properties: [],
        paging: createPager({ itemsPerPage: 10 })
    },
    viewingProperty: undefined,
    createPropertyStatus: AsyncActionState.Reset,
    deletePropertyStatus: AsyncActionState.Reset,
    getPropertiesStatus: AsyncActionState.Reset,
    getPropertyStatus: AsyncActionState.Reset,
    addPropertyViewingStatus: AsyncActionState.Reset,
    updatePropertyViewingStatus: AsyncActionState.Reset,
    removePropertyViewingStatus: AsyncActionState.Reset,
    saveMIStatus: AsyncActionState.Reset,
    addMIAskingPriceStatus: AsyncActionState.Reset,
    updateMIAskingPriceStatus: AsyncActionState.Reset,
    removeMIAskingPriceStatus: AsyncActionState.Reset,
    updateTimelineStatus: AsyncActionState.Reset,
    addPropertyOfferStatus: AsyncActionState.Reset,
    removePropertyOfferStatus: AsyncActionState.Reset,
    updatePropertyOfferStatus: AsyncActionState.Reset,
    getMIReportStatus: AsyncActionState.Reset,
    updatePropertyContactsStatus: AsyncActionState.Reset,
    sendMiPdfStatus: AsyncActionState.Reset,
    miReportData: undefined,
    miReportKeyStats: undefined,
    createPropertyVendorRef: undefined,
    offerToEdit: undefined,
    viewingToEdit: undefined,
    resetSaleStatus: AsyncActionState.Reset,
    restoreSaleStatus: AsyncActionState.Reset,
    deleteRestoreSaleStatus: AsyncActionState.Reset,
    deletePhotoStatus: AsyncActionState.Reset
};

export default function propertyReducer(
    state: IPropertyState = initialState,
    action:
        | actions.SetProperties
        | actions.SetViewingProperty
        | actions.CreatePropertyStatus
        | actions.DeletePropertyStatus
        | actions.GetPropertiesStatus
        | actions.GetPropertyStatus
        | actions.AddPropertyViewingStatus
        | actions.SetUpdatePropertyViewingStatus
        | actions.RemovePropertyViewingStatus
        | actions.SaveMIStatus
        | actions.AddMIAskingPriceStatus
        | actions.SetUpdatePropertyAskingPriceStatus
        | actions.RemoveMIAskingPriceStatus
        | actions.UpdatePropertyTimelineStatus
        | actions.SetCreatePropertyVendorRef
        | actions.SetAddPropertyOfferStatus
        | actions.SetRemovePropertyOfferStatus
        | actions.SetUpdatePropertyOfferStatus
        | actions.SetPropertyOfferToEdit
        | actions.SetPropertyViewingToEdit
        | actions.SetPropertyAskingPriceToEdit
        | actions.SetMIReportKeyStats
        | actions.SetMIReportData
        | actions.SendMiPdfStatus
        | actions.GetMIReportStatus
        | actions.UpdatePropertyContactsStatus
        | actions.ResetPropertySaleStatus
        | actions.RestorePropertySaleStatus
        | actions.DeleteRestorePropertySaleStatus
        | actions.DeletePropertyPhotoStatus
) {
    switch (action.type) {
        case actions.PropertyTypes.DELETE_PROPERTY_PHOTO_STATUS: {
            return Object.assign({}, state, {
                deletePhotoStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.SEND_MI_PDF_STATUS: {
            return Object.assign({}, state, {
                sendMiPdfStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.SET_DELETE_RESTORE_PROPERTY_SALE_STATUS: {
            return Object.assign({}, state, {
                deleteRestoreSaleStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.SET_RESTORE_PROPERTY_SALE_STATUS: {
            return Object.assign({}, state, {
                restoreSaleStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.SET_RESET_PROPERTY_SALE_STATUS: {
            return Object.assign({}, state, {
                resetSaleStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.SET_UPDATE_PROPERTY_CONTACTS_STATUS: {
            return Object.assign({}, state, {
                updatePropertyContactsStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.SET_PROPERTY_ASKING_PRICE_TO_EDIT: {
            return Object.assign({}, state, {
                askingPriceToEdit: action.payload
            } as IPropertyState);
        }
        case actions.PropertyTypes.SET_PROPERTY_OFFER_TO_EDIT: {
            return Object.assign({}, state, {
                offerToEdit: action.payload
            } as IPropertyState);
        }
        case actions.PropertyTypes.SET_PROPERTY_VIEWING_TO_EDIT: {
            return Object.assign({}, state, {
                viewingToEdit: action.payload
            } as IPropertyState);
        }
        case actions.PropertyTypes.SET_UPDATE_PROPERTY_OFFER_STATUS: {
            return Object.assign({}, state, {
                updatePropertyOfferStatus: action.payload
            } as IPropertyState);
        }
        case actions.PropertyTypes.SET_REMOVE_PROPERTY_OFFER_STATUS: {
            return Object.assign({}, state, {
                removePropertyOfferStatus: action.payload
            } as IPropertyState);
        }
        case actions.PropertyTypes.SET_ADD_PROPERTY_OFFER_STATUS: {
            return Object.assign({}, state, {
                addPropertyOfferStatus: action.payload
            } as IPropertyState);
        }
        case actions.PropertyTypes.SET_CREATE_PROPERTY_VENDOR_REF: {
            return Object.assign({}, state, {
                createPropertyVendorRef: action.payload
            } as IPropertyState);
        }
        case actions.PropertyTypes.UPDATE_PROPERTY_TIMELINE_STATUS: {
            return Object.assign({}, state, {
                updateTimelineStatus: action.payload
            } as IPropertyState);
        }
        case actions.PropertyTypes.ADD_MI_ASKING_PRICE_STATUS: {
            return Object.assign({}, state, {
                addMIAskingPriceStatus: action.payload
            } as IPropertyState);
        }
        case actions.PropertyTypes.SET_UPDATE_PROPERTY_ASKING_PRICE_STATUS: {
            return Object.assign({}, state, {
                updateMIAskingPriceStatus: action.payload
            } as IPropertyState);
        }
        case actions.PropertyTypes.REMOVE_MI_ASKING_PRICE_STATUS: {
            return Object.assign({}, state, {
                removeMIAskingPriceStatus: action.payload
            } as IPropertyState);
        }
        case actions.PropertyTypes.SAVE_MI_STATUS: {
            return Object.assign({}, state, {
                saveMIStatus: action.payload
            } as IPropertyState);
        }
        case actions.PropertyTypes.SET_PROPERTIES: {
            return Object.assign({}, state, {
                properties: action.payload
            } as IPropertyState);
        }
        case actions.PropertyTypes.SET_VIEWING_PROPERTY: {
            return Object.assign({}, state, {
                viewingProperty: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.CREATE_PROPERTIES_STATUS: {
            return Object.assign({}, state, {
                createPropertyStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.DELETE_PROPERTIES_STATUS: {
            return Object.assign({}, state, {
                deletePropertyStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.GET_PROPERTIES_STATUS: {
            return Object.assign({}, state, {
                getPropertiesStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.GET_PROPERTY_STATUS: {
            return Object.assign({}, state, {
                getPropertyStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.ADD_PROPERTY_VIEWING_STATUS: {
            return Object.assign({}, state, {
                addPropertyViewingStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.SET_UPDATE_PROPERTY_VIEWING_STATUS: {
            return Object.assign({}, state, {
                updatePropertyViewingStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.REMOVE_PROPERTY_VIEWING_STATUS: {
            return Object.assign({}, state, {
                removePropertyViewingStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.GET_MI_REPORT_STATUS: {
            return Object.assign({}, state, {
                getMIReportStatus: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.SET_MI_REPORT_DATA: {
            return Object.assign({}, state, {
                miReportData: action.payload
            } as IPropertyState);
        }

        case actions.PropertyTypes.SET_MI_REPORT_KEYSTATS: {
            return Object.assign({}, state, {
                miReportKeyStats: action.payload
            } as IPropertyState);
        }

        default: {
            return state;
        }
    }
}
