import React, { Component, FormEvent } from "react";
import { Button } from "react-bootstrap";
import {
    IPagingResponse,
    IPropertyContacts,
    IUser,
    IBuyer
} from "../../../../../../api/_types";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import VendorContacts from "./VendorContacts";

interface IProps {
    contacts: IPropertyContacts;
    buyer: IBuyer;
    vendor: IUser;
    branchMembers: IUser[];
    branchMembersPaging: IPagingResponse;
    savePropertyContactsStatus: AsyncActionState;
    onChangeInputField: (stateField: string) => (event: FormEvent<any>) => void;
    onSelectBranchStaffContact: (user: IUser) => void;
    onChangeContact: (contacts: IPropertyContacts) => void;
    pageBranchMembers: (desiredPage: number) => void;
    save: () => void;
    resetSavePropertyContactsStatus?: () => void;
}
interface IState {}

export default class ContactsForm extends Component<IProps, IState> {
    handleSubmit = (event: any) => {
        event.preventDefault();
        this.props.save();
    };

    checkUser = (item: IUser) => {
        const { contacts, onSelectBranchStaffContact } = this.props;
        let updatedItem: any = item;
        if (
            contacts.bStaff !== undefined &&
            contacts.bStaff !== null &&
            item._id === (contacts.bStaff as IUser)._id
        ) {
            updatedItem = undefined;
        }
        onSelectBranchStaffContact(updatedItem);
    };

    render() {
        const {
            savePropertyContactsStatus,
            resetSavePropertyContactsStatus,
            branchMembers,
            branchMembersPaging,
            contacts,
            pageBranchMembers,
            onChangeInputField,
            onChangeContact,
            buyer,
            vendor
        } = this.props;

        return (
            <FormContainer
                submit={this.handleSubmit}
                actions={
                    <>
                        <Button
                            className="mr-2"
                            variant="primary"
                            type="submit"
                        >
                            Save
                        </Button>
                    </>
                }
                showSuccess={
                    savePropertyContactsStatus === AsyncActionState.Success
                }
                showError={
                    savePropertyContactsStatus === AsyncActionState.Error
                }
                success={{
                    heading: "Contacts saved",
                    message:
                        "Property contacts have been saved successfully."
                }}
                error={{
                    heading: "Error saving contacts",
                    message:
                        "Something went wrong saving the contact details"
                }}
                onFeedbackMessageClose={resetSavePropertyContactsStatus}
            >
                <VendorContacts
                    contacts={contacts}
                    checkUser={this.checkUser}
                    branchMembers={branchMembers}
                    branchMembersPaging={branchMembersPaging}
                    pageBranchMembers={pageBranchMembers}
                    onChangeInputField={onChangeInputField}
                    onChangeContact={onChangeContact}
                    buyer={buyer}
                    vendor={vendor}
                    saveContacts={this.props.save}
                />
            </FormContainer>
        );
    }
}
