export interface IPropertyFilterKeyVal {
    key:
        | "active"
        | "preForSale"
        | "complete"
        | "sold"
        | "chain"
        | "chainStatus"
        | "otherWarnings"
        | "pendingOffers";
    value: StringBool | string;
}

type StringBool = "-1" | "0" | "1";
const stringBools: StringBool[] = ["-1", "0", "1"];
const isStringBool = (input: any) => stringBools.includes(input);

export const buildFilterObj = <T = void>(
    filters: IPropertyFilterKeyVal[]
): T => {
    const obj: T = {} as T;

    for (const f of filters) {
        if (isStringBool(f.value) === true) {
            (obj as any)[f.key] = parseValue(f.value);
        } else {
            (obj as any)[f.key] = f.value;
        }
    }

    return obj;
};

function parseValue(val: string) {
    if (val === "0") {
        return false;
    } else if (val === "1") {
        return true;
    }
    return undefined;
}
