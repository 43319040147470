import React, { Component } from "react";
import "./feedbackIcon.css";

interface IProps {
    variant: "success" | "failure";
}
interface IState {}

export default class FeedbackIcon extends Component<IProps, IState> {
    render() {
        const { variant } = this.props;
        return (
            <section className="c-container">
                <div
                    className={`o-circle c-container__circle o-circle__sign--${variant}`}
                >
                    <div className="o-circle__sign"></div>
                </div>
            </section>
        );
    }
}
