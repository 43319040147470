import React, { Component } from "react";
import { MIReportSortOption } from "../../../../../../api/modules/property";
import {
    IBranch,
    IMIKeyStatsResponse,
    IMIReportEntity,
    IMIReportResponse
} from "../../../../../../api/_types";
import { ChainStatus } from "../../../../../../redux/_types/property/types";
import DataTable from "../../../../../_sharedComponents/DataTable";
import { IPropertyFilterKeyVal } from "../../../property/containers/PropertyList/filters";
import {
    IFieldGroups,
    IVisibleGroups,
    VisibleGroupKey
} from "../../containers/MIReport";
import InfoDeck from "./InfoDeck";
import "./styles.css";
import TableHeadingMeta from "./TableHeadingMeta";
import TableOptions from "./TableOptions";

interface IProps {
    type: "branch" | "company";
    branches: IBranch[];
    filterBranches: Array<{ label: string; value: string }>;
    fieldGroups: IFieldGroups;
    visibleGroups: IVisibleGroups;
    chainStatus: ChainStatus[];
    miReportData: IMIReportResponse;
    miKeyStats: IMIKeyStatsResponse;
    properties: IMIReportEntity[];
    numChunks: number;
    currentIndex: number;
    sortOption: MIReportSortOption;
    startDate: string | Date;
    endDate: string | Date;
    filter: IPropertyFilterKeyVal[];
    bStaffIds: Array<{ label: string; value: string }>;
    searchText?: string;
    search: () => void;
    setChainStatus: (status: ChainStatus[]) => void;
    updateBranchFilter: (
        branches: Array<{ label: string; value: string }>
    ) => void;
    updateSearchText: (text: string) => void;
    updateSortOption: (option: MIReportSortOption | null) => void;
    onChangeBStaff: (users: Array<{ label: string; value: string }>) => void;
    pageProperties: (currentIndex: number) => void;
    updateStartDate: (date: string | Date) => void;
    updateEndDate: (date: string | Date) => void;
    updateFilter: (filters: IPropertyFilterKeyVal[]) => void;
    clearAllFilters: () => void;
    downloadCsv: () => void;
}

export default class MIReportTable extends Component<IProps> {
    sortOptions = [
        {
            value: MIReportSortOption.EXPECTED_FEE_ASC,
            label: "Expected fee (lowest first)"
        },
        {
            value: MIReportSortOption.EXPECTED_FEE_DESC,
            label: "Expected fee (highest first)"
        },
        {
            value: MIReportSortOption.TAKE_ON_DESC,
            label: "Take on date (earliest first)"
        },
        {
            value: MIReportSortOption.TAKE_ON_ASC,
            label: "Take on date (latest first)"
        },
        {
            value: MIReportSortOption.SALE_PROGRESS_ASC,
            label: "Sale progress (lowest first)"
        },
        {
            value: MIReportSortOption.SALE_PROGRESS,
            label: "Sale progress (highest first)"
        }
    ];

    toggleVisibleGroup = (group: VisibleGroupKey) => {
        const visibleGroups = this.props.visibleGroups;
        visibleGroups[group] = !visibleGroups[group];
        this.setState({
            visibleGroups
        });
    };

    render() {
        const {
            type,
            filter,
            endDate,
            branches,
            bStaffIds,
            startDate,
            numChunks,
            properties,
            miKeyStats,
            sortOption,
            fieldGroups,
            chainStatus,
            currentIndex,
            visibleGroups,
            filterBranches,
            searchText,
            search,
            updateFilter,
            updateEndDate,
            pageProperties,
            onChangeBStaff,
            setChainStatus,
            clearAllFilters,
            updateStartDate,
            updateSearchText,
            updateBranchFilter,
            downloadCsv
        } = this.props;
        let arr: any[] = [];
        const tableFields: Array<{ field: string; mutator: any }> = arr.concat(
            fieldGroups.address,
            visibleGroups.financial === true ? fieldGroups.financial : [],
            visibleGroups.saleProcess === true ? fieldGroups.saleProcess : [],
            visibleGroups.preSale === true ? fieldGroups.preSale : []
        );

        return (
            <>
                <TableOptions
                    setChainStatus={setChainStatus}
                    branches={branches}
                    sortOption={sortOption}
                    toggleVisibleGroup={this.toggleVisibleGroup}
                    updateBranchFilter={updateBranchFilter}
                    visibleGroups={visibleGroups}
                    hideBranchFilter={type === "branch"}
                    filterBranches={filterBranches}
                    startDate={startDate}
                    endDate={endDate}
                    chainStatus={chainStatus}
                    updateEndDate={updateEndDate}
                    updateStartDate={updateStartDate}
                    onChangeBStaff={onChangeBStaff}
                    updatePropertyFilter={updateFilter}
                    filter={filter}
                    clearAllFilters={clearAllFilters}
                    bStaffIds={bStaffIds}
                    search={search}
                    searchText={searchText}
                    updateSearchText={updateSearchText}
                    downloadCsv={downloadCsv}
                />
                <InfoDeck
                    stats={miKeyStats}
                />
                <div id="mi-report-table-container">
                    <DataTable
                        data={properties || []}
                        showPager={true}
                        pagingElement={{
                            prevPage: 0,
                            nextPage: 0,
                            count: 0,
                            currentPage: currentIndex,
                            totalPages: numChunks,
                            itemsPerPage: 5
                        }}
                        onPagerPageClick={pageProperties}
                        hover={false}
                        size="sm"
                        fields={tableFields}
                        smallHeader={false}
                        centeredHeader={true}
                        aboveTheadContent={
                            <TableHeadingMeta
                                showFinancial={visibleGroups.financial}
                                showPreSale={visibleGroups.preSale}
                                showSalesProcess={visibleGroups.saleProcess}
                            />
                        }
                        pagerStyles={{ marginTop: "1rem" }}
                        stickyHorizontalCols={1}
                    />
                </div>
            </>
        );
    }
}
