import React, { FC } from "react";
import { IPropertyDocument } from "../../../../../../api/_types";
import { fileSizeLabel } from "../../../../../../lib/utils/files";
import DataTable from "../../../../../_sharedComponents/DataTable";

type DocumentAction = (doc: IPropertyDocument) => void;

interface IProps  {
    documents: IPropertyDocument[];
    downloadInProgress: boolean;
    emailDoc: DocumentAction;
    updateDoc: DocumentAction;
    deleteDoc: DocumentAction;
    downloadDoc: DocumentAction;
}

const DocumentList: FC<IProps> = ({documents, downloadInProgress, emailDoc, updateDoc, deleteDoc, downloadDoc}) => {


    return (
        <DataTable
            data={documents}
            showLoadingSpinner={false}
            fields={[
                {
                    field: "file",
                    displayName: "File",
                    mutator: (item:IPropertyDocument) => item.file.fileName || ""
                },
                {
                    field: "note",
                    displayName: "Note"
                },
                {
                    field: "size",
                    displayName: "Size",
                    mutator: (item:IPropertyDocument) => fileSizeLabel(item.file?.originalByteSize!)
                }
            ]}
            actions={[
                {
                    btnTitle: "Download",
                    btnVariant: "light",
                    inProgress: downloadInProgress,
                    action: downloadDoc
                },
                {
                    btnTitle: "Edit comment",
                    btnVariant: "light",
                    action: updateDoc
                },
                {
                    btnTitle: "Send file",
                    btnVariant: "dark",
                    action: emailDoc
                },
                {
                    btnTitle: "Delete file",
                    btnVariant: "danger",
                    action: deleteDoc
                }
            ]}
        />

    );
};

export default DocumentList
