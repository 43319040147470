import React, { PureComponent } from "react";
import { Line } from "react-chartjs-2";
import "../charts.css";
import { ICompany } from "../../../../api/_types";
// import colours from '../ChartColours';

interface IProps {
    companies: any;
}

interface IAdditionalCompanyFields {}

interface IStateCompany extends ICompany, IAdditionalCompanyFields {}

interface IState {
    company: IStateCompany;
}

let data = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
        {
            label: "Example Area Chart",
            backgroundColor: "#00A9CE40", // TODO Convert to variable for code reuse
            borderColor: "#00A9CE", // TODO Convert to variable for code reuse
            borderWidth: 3,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: [0, 7, 16, 29, 45, 60, 91],
            lineTension: 0
        }
    ]
};

export class CompaniesByMonthChart extends PureComponent<IProps, IState> {
    dataToChart = () => {
        this.props.companies.forEach((company: ICompany) => {
            // console.log(data);
            // console.log(company);
        });
    };

    render() {
        this.dataToChart();
        return (
            <div className="chartWrapper">
                <Line
                    data={data}
                    width={50}
                    height={50}
                    options={{
                        maintainAspectRatio: true
                    }}
                />
            </div>
        );
    }
}
