import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { PropertySortOptionType } from "../../../../../../../api/_types";

interface IProps {
    active: PropertySortOptionType;
    updatePropertySort: (sortOption: PropertySortOptionType) => void;
}
interface IState {}

export default class PropertySort extends Component<IProps, IState> {
    filterValues: Array<{ label: string; value: PropertySortOptionType }> = [
        {
            label: "Address - Alphabetical order",
            value: "default"
        },
        {
            label: "Price - Highest first",
            value: "priceHigh"
        },
        {
            label: "Price - Lowest first",
            value: "priceLow"
        }
    ];

    onSelectOption = (event: React.FormEvent<any>) => {
        const { updatePropertySort } = this.props;

        const value = event.currentTarget.value;

        // Check for correct format
        updatePropertySort(value as PropertySortOptionType);
    };

    render() {
        const { active } = this.props;
        return (
            <div data-testid="psort-select">
                <Form.Group controlId="propertySort">
                    <Form.Control
                        as="select"
                        value={active}
                        onChange={this.onSelectOption}
                    >
                        {this.filterValues.map((item, index) => (
                            <option
                                data-testid="psort-select-option"
                                key={`filter-${index}`}
                                value={item.value}
                            >
                                {item.label}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </div>
        );
    }
}
