import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { IUser } from "../../../../../../api/_types";
import {
    editCompanyAdmin,
    resetEditCompanyAdminStatus,
    deleteCompanyMember
} from "../../../../../../redux/api/company";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import { UserTypes } from "../../../../../../redux/_types/user/types";
import FormContainer from "../../../../../_sharedComponents/FormContainer";
import UserCard from "../../../user/components/UserCard";
import { getStateFromOnChangeUpdate } from "../../../utils/state";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {
    user?: IUser;
    me?: IUser;
    editUserStatus: AsyncActionState;
    deleteCompanyMemberStatus: AsyncActionState;
    editCompanyAdmin: (user: IUser) => void;
    resetEditCompanyAdminStatus: () => void;
    deleteCompanyMember: (member: IUser) => void;
}
interface IState {
    user: IUser;
    showDelete: boolean;
}

class EditCompanyUserContainer extends Component<IProps, IState> {
    generateDefaultUser = (user?: IUser): IUser => {
        return (
            user || {
                firstName: "",
                lastName: "",
                type: UserTypes.COMPANY_ADMIN,
                email: "",
                tel: "",
                password: "",
                confirmPassword: ""
            }
        );
    };

    state: IState = {
        user: Object.assign({}, this.generateDefaultUser(this.props.user)),
        showDelete: false
    };

    componentDidUpdate(prevProps: IProps) {
        const currentState = this.props.deleteCompanyMemberStatus;
        if (
            prevProps.deleteCompanyMemberStatus === AsyncActionState.Pending &&
            currentState !== AsyncActionState.Pending
        ) {
            if (currentState === AsyncActionState.Success) {
                this.deleteMemberSuccess();
            } else if (currentState === AsyncActionState.Error) {
                this.deleteMemberError();
            }
        }
        if (
            prevProps.editUserStatus !== AsyncActionState.Success &&
            this.props.editUserStatus === AsyncActionState.Success
        ) {
            this.setState({
                user: Object.assign(
                    {},
                    this.generateDefaultUser(this.props.user)
                )
            });
        }
    }
    componentDidMount() {
        this.props.resetEditCompanyAdminStatus();
    }
    componentWillUnmount() {
        this.props.resetEditCompanyAdminStatus();
    }

    deleteMember = () => {
        this.props.deleteCompanyMember(this.state.user);
    };
    triggerDeleteMember = () => this.setState({ showDelete: true });
    cancelDeleteMember = () => this.setState({ showDelete: false });
    deleteMemberSuccess = () => {
        this.cancelDeleteMember();
        this.props.history.goBack();
    };
    deleteMemberError = () => {
        alert("Something went wrong. Please try again.");
    };

    onChangeInput = (stateField: string) => {
        return getStateFromOnChangeUpdate(this, this.state, stateField, "user");
    };

    createUser = () => {
        this.props.editCompanyAdmin(this.state.user);
    };

    render() {
        const {
            me,
            // user,
            editUserStatus,
            resetEditCompanyAdminStatus,
            deleteCompanyMemberStatus
        } = this.props;
        const { showDelete, user } = this.state;
        if (user === undefined || me === undefined) {
            return null;
        }

        const actions = [
            <Button
                variant="primary"
                onClick={this.createUser}
                className="mr-2"
            >
                Save
            </Button>
        ];

        if (
            [UserTypes.GYA_ADMIN, UserTypes.COMPANY_ADMIN].includes(me.type) ===
            true
        ) {
            actions.push(
                <Button variant="danger" onClick={this.triggerDeleteMember}>
                    Delete
                </Button>
            );
        }

        return (
            <FormContainer
                actions={actions}
                showSuccess={editUserStatus === AsyncActionState.Success}
                showError={editUserStatus === AsyncActionState.Error}
                onFeedbackMessageClose={resetEditCompanyAdminStatus}
                success={{
                    heading: "User account edited",
                    message: "Your changes have been saved."
                }}
                error={{
                    heading: "Error editing user account",
                    message: "Something went wrong editing the user account."
                }}
            >
                <>
                    <UserCard
                        user={this.state.user}
                        updateUserFirstName={this.onChangeInput("firstName")}
                        updateUserLastName={this.onChangeInput("lastName")}
                        updateUserEmail={this.onChangeInput("email")}
                        updateUserTel={this.onChangeInput("tel")}
                    />

                    <ActionPrompt
                        show={showDelete}
                        title="Are you sure?"
                        bodyText="This cannot be undone. The users account will be deleted, and be removed as the contact for any property to which they are currently assigned."
                        confirmText={`${user.firstName} ${user.lastName}`}
                        confirmTextLabel="Please enter the users full name to confirm."
                        cancel={this.cancelDeleteMember}
                        actions={[
                            {
                                title: "Confirm",
                                btnVariant: "danger",
                                useConfirm: true,
                                asyncStatus: deleteCompanyMemberStatus,
                                onClick: this.deleteMember
                            }
                        ]}
                    />
                </>
            </FormContainer>
        );
    }
}

const mapStateToProps = ({ company, user }: IStore) => ({
    me: user.me,
    editUserStatus: company.editCompanyAdminStatus,
    user: company.editMember,
    deleteCompanyMemberStatus: company.deleteCompanyMemberStatus
});

export default connect(
    mapStateToProps,
    { editCompanyAdmin, resetEditCompanyAdminStatus, deleteCompanyMember }
)(withRouter<any, any>(EditCompanyUserContainer));
