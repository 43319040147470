import { AsyncActionState } from "../../utils/asyncAction";

export enum PropertyNotesTypes {
    UPDATE_CHAIN_STATUS = "UPDATE_CHAIN_STATUS"
}

export interface UpdateChainStatus extends ReduxAction {
    type: PropertyNotesTypes.UPDATE_CHAIN_STATUS;
    payload: AsyncActionState;
}
