import React, { FC, useState } from "react";
import { Button } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { deleteRestoreSale, resetSaleStatus, restoreSaleStatus } from "../../../../../../redux/api/property";
import { IStore } from "../../../../../../redux/store";
import ActionPrompt from "../../../../../_sharedComponents/ActionPrompt";

const mapStateToProps = ({company, branch, property}: IStore) => ({
    saleReset: property.viewingProperty?.saleReset,
    addressLine1: property.viewingProperty?.address.line1,
    resetSaleStatus: property.resetSaleStatus,
    companyId: company.viewingCompany?._id,
    branchId: branch.viewingBranch?._id,
    propertyId: property.viewingProperty?._id,
    restoreSaleAsyncStatus: property.restoreSaleStatus,
    resetSaleAsyncStatus: property.resetSaleStatus,
    deleteRestoreSaleAsyncStatus: property.deleteRestoreSaleStatus
});

const actions = {
    resetSaleStatus,
    restoreSaleStatus,
    deleteRestoreSale
};

const connector = connect(mapStateToProps, actions);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps {}

const SaleResetBtnRow: FC<IProps> = ({
    addressLine1,
    saleReset,
    companyId,
    branchId,
    propertyId,
    resetSaleStatus,
    deleteRestoreSaleAsyncStatus,
    resetSaleAsyncStatus,
    restoreSaleStatus,
    deleteRestoreSale,
    restoreSaleAsyncStatus
}) => {

    const [showResetPrompt, setShowResetPrompt] = useState(false);
    const [showRestorePrompt, setShowRestorePrompt] = useState(false);
    const [showDeleteRestorePrompt, setShowDeleteRestorePrompt] = useState(false);
    let btns: JSX.Element;
    const triggerReset = () => setShowResetPrompt(true);
    const cancelReset = () => setShowResetPrompt(false);
    const triggerRestore = () => setShowRestorePrompt(true);
    const cancelRestore = () => setShowRestorePrompt(false);
    const triggerDeleteRestore = () => setShowDeleteRestorePrompt(true);
    const cancelDeleteRestore = () => setShowDeleteRestorePrompt(false);
    const handleResetSale = () => resetSaleStatus(companyId!, branchId!, propertyId!);
    const handleDeleteSaleRestore = () => {
        if (saleReset !== undefined) {
            deleteRestoreSale(
                companyId!,
                branchId!,
                propertyId!,
                saleReset as string
            );
        }
    };
    const handleRestoreSaleStatus = () => {
       console.log(saleReset)
        if (saleReset !== undefined) {
            restoreSaleStatus(
                companyId!,
                branchId!,
                propertyId!,
                saleReset as string
            );
        }
    };

    if (saleReset === undefined) {
        btns =  (
            <Button
                size="lg"
                variant="outline-dark"
                className="ml-2"
                onClick={triggerReset}
            >
                Reset sale progress
            </Button>
        );
    }
    else {
        btns = (
            <>
                <Button
                    size="lg"
                    variant="outline-dark"
                    className="ml-2"
                    onClick={triggerRestore}
                >
                    Restore sale progress
                </Button>
                <Button
                    size="lg"
                    variant="outline-danger"
                    className="ml-2"
                    onClick={triggerDeleteRestore}
                >
                    Delete previous reset
                </Button>
            </>
        );
    }


    return (
        <>
            {btns}

            <ActionPrompt
                actions={[
                    {
                        title: "Confirm",
                        btnVariant: "primary",
                        onClick: handleResetSale,
                        asyncStatus: resetSaleAsyncStatus,
                        onSuccess: cancelReset,
                        useConfirm: true
                    }
                ]}
                show={showResetPrompt}
                cancel={cancelReset}
                title="Reset this properties sales progress"
                bodyText={`This will reset all elements of the sales timeline back to their original state. It will also mark the currently accepted offer as rejected.`}
                confirmText={addressLine1}
                confirmTextLabel="Please enter the first line of address to complete this action."
            />
            <ActionPrompt
                actions={[
                    {
                        title: "Confirm",
                        btnVariant: "primary",
                        onClick: handleRestoreSaleStatus,
                        asyncStatus: restoreSaleAsyncStatus,
                        onSuccess: cancelRestore,
                        useConfirm: true
                    }
                ]}
                show={showRestorePrompt}
                cancel={cancelRestore}
                title="Restore this properties sales progress"
                bodyText={`This will restore this properties sales timeline to the point at which it was last reset.`}
                confirmText={addressLine1}
                confirmTextLabel="Please enter the first line of address to complete this action."
            />
            <ActionPrompt
                    actions={[
                        {
                            title: "Delete",
                            btnVariant: "danger",
                            onClick: handleDeleteSaleRestore,
                            asyncStatus: deleteRestoreSaleAsyncStatus,
                            onSuccess: cancelDeleteRestore,
                            useConfirm: true
                        }
                    ]}
                    show={showDeleteRestorePrompt}
                    cancel={cancelDeleteRestore}
                    title="Delete the saved timeline information?"
                    bodyText={`This will delete the information you have reset previously, this action cannot be undone. This action will allow you to reset this properties sales progress again. `}
                    confirmText={addressLine1}
                    confirmTextLabel="Please enter the first line of address to complete this action."
                />
        </>
    )
};

export default connector(SaleResetBtnRow);
