import React from "react";

export default {
    cellStyle: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0
    } as React.CSSProperties
};
