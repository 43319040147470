import React, { PropsWithChildren } from "react";
import { UserTypes } from "../../../redux/_types/user/types";
import { connect } from "react-redux";
import { IStore } from "../../../redux/store";
import { IUser } from "../../../api/_types";

interface IProps {
    user?: IUser;
    types: UserTypes[];
}
interface IState {}

type Props = PropsWithChildren<IProps>;

class ACLUserType extends React.Component<Props, IState> {
    render() {
        const { types, user } = this.props;

        if (user === undefined) {
            return null;
        }
        if (types.includes(user.type) === false) {
            return null;
        }

        return this.props.children;
    }
}

const mapStateToProps = ({ user }: IStore) => ({
    user: user.me
});

export default connect(
    mapStateToProps,
    {}
)(ACLUserType);
