import React, { FC, useCallback } from "react";
import { Button, Form, Modal } from "react-bootstrap";

interface IProps {
    amendAmount?: number|string;
    amendReason?: string;
    amount?: number;
    show?: boolean;
    hide: () => void;
    onChange: (key: string) => (e: any) => void;
    save: () => void; 
}

const AmendOfferPrompt: FC<IProps> = ({ amendAmount, amendReason, amount, show, hide, onChange, save }) => {

    const focusRef = useCallback((node:any) => setTimeout(() => {node?.focus()}, 200), []);
    const amendAmountNum = parseInt(amendAmount as string, 10);
    const canSave = amendAmount !== undefined && amendAmountNum !== amount && amendReason !== undefined && amendReason !== "";
    return (
        <Modal show={show} onHide={hide} centered backdrop="static">

            <Modal.Header closeButton>
                <Modal.Title>Amending this offer</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form spellCheck={true}>
                    <p>What is the new offer?</p>

                        <Form.Control
                            className="mb-2"
                            as='input'
                            type='number'
                            defaultValue={amount?.toString()}
                            value={amendAmount?.toString()}
                            // Auto focus is buggy inside the modal, so using ref to achieve it
                            ref={focusRef}
                            onChange={onChange("amendAmount")}
                        />
                        <p>Why is the offer being amended?</p>
                        <Form.Control
                            className="mb-2"
                            as="textarea"
                            rows={4}
                            value={amendReason!}
                            onChange={onChange("amendReason")}
                        />
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-primary" onClick={hide}>
                    Close
                </Button>
                <Button variant="primary" onClick={save} disabled={canSave === false}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AmendOfferPrompt;
