import React, { Component } from "react";
import CreatePropertyContainer from "../../containers/CreateProperty";
import EditBranchTopNav from "../../../branch/containers/EditBranchTopNav";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import EntityTitle from "../../../../../_sharedComponents/EntityTitle/EntityTitle";
import TabBody from "../../../../../_sharedComponents/TabBody";
import { IStore } from "../../../../../../redux/store";
import { connect } from "react-redux";
import { IBranch } from "../../../../../../api/_types";

interface IProps {
    branch?: IBranch;
}

class CreatePropertyScreen extends Component<IProps> {
    render() {
        const { branch } = this.props;
        if (branch === undefined) {
            return null;
        }

        return (
            <PageWrap>
                <EntityTitle entityName={branch.name} />
                <EditBranchTopNav />
                <TabBody>
                    <CreatePropertyContainer />
                </TabBody>
            </PageWrap>
        );
    }
}

const mapStateToProps = ({ branch, company }: IStore) => ({
    company: company.viewingCompany,
    branch: branch.viewingBranch
});
export default connect(
    mapStateToProps,
    {}
)(CreatePropertyScreen);
