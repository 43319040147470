import * as actions from "../../actions/sidebar";

export interface ISidebarState {
    isOpen: boolean;
}

const initialState: ISidebarState = {
    isOpen: true
};

export default function sidebarReducer(
    state: ISidebarState = initialState,
    action: actions.SidebarOpenState
) {
    switch (action.type) {
        case actions.SidebarTypes.SET_OPEN_STATE: {
            return Object.assign({}, state, {
                isOpen: action.payload
            } as ISidebarState);
        }
        default: {
            return state;
        }
    }
}
