import React from "react";
import { Form } from "react-bootstrap";
import { IPropertyTimelineEntity } from "../../../../../../../api/_types";

interface IProps {
    item: IPropertyTimelineEntity;
    description: string;
    onChange: (
        timelineEntity: IPropertyTimelineEntity,
        description: string
    ) => void;
}

export default function TimelineItemDescripion(props: IProps) {
    const { description, item, onChange } = props;
    return (
        <Form.Control
            as="textarea"
            rows="8"
            value={description}
            onChange={(e) => onChange(item, e.currentTarget.value)}
        />
    );
}
