import React, { Component } from "react";
import CompanyListContainer from "../../containers/CompanyList";
import PageWrap from "../../../../../_sharedComponents/PageWrap";

interface IProps {}
interface IState {}

class ManageCompanyScreen extends Component<IProps, IState> {
    render() {
        return (
            <PageWrap>
                <CompanyListContainer />
            </PageWrap>
        );
    }
}

export default ManageCompanyScreen;
