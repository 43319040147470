import { AsyncActionState } from "../utils/asyncAction";
import { ICompanyContactPOCGetListResponse } from "../../api/modules/companyContactPOCs";
import { ICompanyContactPOC } from "../../api/_types";

export enum CompanyContactPOCTypes {
    GET_COMPANY_CONTACT_POCS_STATUS = "GET_COMPANY_CONTACT_POCS_STATUS",
    SET_COMPANY_CONTACT_POCS = "SET_COMPANY_CONTACT_POCS",
    SET_VIEWING_COMPANY_CONTACT_POC = "SET_VIEWING_COMPANY_CONTACT_POC",
    CREATE_COMPANY_CONTACT_POC_STATUS = "CREATE_COMPANY_CONTACT_POC_STATUS",
    EDIT_COMPANY_CONTACT_POC_STATUS = "EDIT_COMPANY_CONTACT_POC_STATUS",
    SET_DELETE_POC_STATUS = "SET_DELETE_POC_STATUS"
}

export interface SetCompanyContactPOCStatus extends ReduxAction {
    type: CompanyContactPOCTypes.GET_COMPANY_CONTACT_POCS_STATUS;
    payload: AsyncActionState;
}

export interface SetCompanyContactPOCS extends ReduxAction {
    type: CompanyContactPOCTypes.SET_COMPANY_CONTACT_POCS;
    payload: ICompanyContactPOCGetListResponse;
}

export interface CreateCompanyContactPOCStatus extends ReduxAction {
    type: CompanyContactPOCTypes.CREATE_COMPANY_CONTACT_POC_STATUS;
    payload: AsyncActionState;
}

export interface EditCompanyContactPOCStatus extends ReduxAction {
    type: CompanyContactPOCTypes.EDIT_COMPANY_CONTACT_POC_STATUS;
    payload: AsyncActionState;
}

export interface SetDeleteCompanyContactPOCStatus extends ReduxAction {
    type: CompanyContactPOCTypes.SET_DELETE_POC_STATUS;
    payload: AsyncActionState;
}

export interface SetViewingCompanyContactPOC extends ReduxAction {
    type: CompanyContactPOCTypes.SET_VIEWING_COMPANY_CONTACT_POC;
    payload: ICompanyContactPOC;
}
