import React from "react";
import PageWrap from "../../../../../_sharedComponents/PageWrap";
import AccountActivationContainer from "../../containers/AccountActivation";

interface IProps {}

function AccountActivationScreen() {
    return (
        <PageWrap>
            <AccountActivationContainer />
        </PageWrap>
    );
}

export default AccountActivationScreen;
