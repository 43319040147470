import * as actions from "../../actions/branch";
import { AsyncActionState } from "../../utils/asyncAction";
import { IBranch, IUser, IPagingResponse } from "../../../api/_types";
import { createPager } from "../../../lib/utils/pager";

export interface IBranchState {
    branches: {
        paging: IPagingResponse;
        branches: IBranch[];
    };
    viewingBranch?: IBranch;
    createBranchStatus: AsyncActionState;
    deleteBranchStatus: AsyncActionState;
    getBranchStatus: AsyncActionState;
    getBranchesStatus: AsyncActionState;
    searchBranchAdminStatus: AsyncActionState;
    branchAdminSearchResults: IUser[];
    createBranchAdministratorRef?: string | IUser;
    addBranchMemberStatus: AsyncActionState;
    removeBranchMemberStatus: AsyncActionState;
    editBranchMemberStatus: AsyncActionState;
    editMember?: IUser;
    searchBranchMembersStatus: AsyncActionState;
    searchBranchMembersResults: {
        paging: IPagingResponse;
        users: IUser[];
    };
    updateBranchTimelineStatus: AsyncActionState;
    deletePhotoStatus: AsyncActionState;
}

const initialState: IBranchState = {
    branches: {
        paging: createPager({ itemsPerPage: 10 }),
        branches: []
    },
    viewingBranch: undefined,
    createBranchStatus: AsyncActionState.Reset,
    deleteBranchStatus: AsyncActionState.Reset,
    getBranchesStatus: AsyncActionState.Reset,
    getBranchStatus: AsyncActionState.Reset,
    searchBranchAdminStatus: AsyncActionState.Reset,
    branchAdminSearchResults: [],
    createBranchAdministratorRef: undefined,
    addBranchMemberStatus: AsyncActionState.Reset,
    removeBranchMemberStatus: AsyncActionState.Reset,
    editBranchMemberStatus: AsyncActionState.Reset,
    editMember: undefined,
    searchBranchMembersStatus: AsyncActionState.Reset,
    updateBranchTimelineStatus: AsyncActionState.Reset,
    searchBranchMembersResults: {
        paging: createPager({ itemsPerPage: 10 }),
        users: []
    },
    deletePhotoStatus: AsyncActionState.Reset
};

export default function branchReducer(
    state: IBranchState = initialState,
    action:
        | actions.SetBranches
        | actions.SetViewingBranch
        | actions.CreateBranchStatus
        | actions.DeleteBranchStatus
        | actions.GetBranchesStatus
        | actions.GetBranchStatus
        | actions.SearchBranchAdminStatus
        | actions.SetSearchBranchAdminResult
        | actions.SetCreateBranchAdministratorRef
        | actions.AddBranchMemberStatus
        | actions.RemoveBranchMemberStatus
        | actions.SetSearchBranchMemberStatus
        | actions.SetSearchBranchMemberResult
        | actions.UpdateBranchTimelineStatus
        | actions.EditBranchMemberStatus
        | actions.SetEditBranchMember
        | actions.DeleteBranchPhotoStatus
) {
    switch (action.type) {
        case actions.BranchTypes.DELETE_BRANCH_PHOTO_STATUS: {
            return Object.assign({}, state, {
                deletePhotoStatus: action.payload
            } as IBranchState);
        }
        case actions.BranchTypes.EDIT_BRANCH_MEMBER_STATUS: {
            return Object.assign({}, state, {
                editBranchMemberStatus: action.payload
            } as IBranchState);
        }
        case actions.BranchTypes.SET_EDIT_BRANCH_MEMBER: {
            return Object.assign({}, state, {
                editMember: action.payload
            } as IBranchState);
        }
        case actions.BranchTypes.UPDATE_BRANCH_TIMELINE_STATUS: {
            return Object.assign({}, state, {
                updateBranchTimelineStatus: action.payload
            } as IBranchState);
        }
        case actions.BranchTypes.SEARCH_BRANCH_MEMBER_STATUS: {
            return Object.assign({}, state, {
                searchBranchMembersStatus: action.payload
            } as IBranchState);
        }
        case actions.BranchTypes.SEARCH_BRANCH_MEMBER_RESULT: {
            return Object.assign({}, state, {
                searchBranchMembersResults: action.payload
            } as IBranchState);
        }
        case actions.BranchTypes.ADD_BRANCH_MEMBER_STATUS: {
            return Object.assign({}, state, {
                addBranchMemberStatus: action.payload
            } as IBranchState);
        }
        case actions.BranchTypes.REMOVE_BRANCH_MEMBER_STATUS: {
            return Object.assign({}, state, {
                removeBranchMemberStatus: action.payload
            } as IBranchState);
        }
        case actions.BranchTypes.SET_CREATE_BRANCH_ADMINISTRATOR_REF: {
            return Object.assign({}, state, {
                createBranchAdministratorRef: action.payload
            } as IBranchState);
        }
        case actions.BranchTypes.SET_SEARCH_BRANCH_ADMIN_RESULT: {
            return Object.assign({}, state, {
                branchAdminSearchResults: action.payload
            } as IBranchState);
        }
        case actions.BranchTypes.SEARCH_BRANCH_ADMIN_STATUS: {
            return Object.assign({}, state, {
                searchBranchAdminStatus: action.payload
            } as IBranchState);
        }
        case actions.BranchTypes.SET_BRANCHES: {
            return Object.assign({}, state, {
                branches: action.payload
            } as IBranchState);
        }
        case actions.BranchTypes.SET_VIEWING_BRANCH: {
            return Object.assign({}, state, {
                viewingBranch: action.payload
            } as IBranchState);
        }

        case actions.BranchTypes.CREATE_BRANCH_STATUS: {
            return Object.assign({}, state, {
                createBranchStatus: action.payload
            } as IBranchState);
        }

        case actions.BranchTypes.DELETE_BRANCH_STATUS: {
            return Object.assign({}, state, {
                deleteBranchStatus: action.payload
            } as IBranchState);
        }

        case actions.BranchTypes.GET_BRANCHES_STATUS: {
            return Object.assign({}, state, {
                getBranchesStatus: action.payload
            } as IBranchState);
        }

        case actions.BranchTypes.GET_BRANCH_STATUS: {
            return Object.assign({}, state, {
                getBranchStatus: action.payload
            } as IBranchState);
        }

        default: {
            return state;
        }
    }
}
