import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import routes, { IRouteDefinition } from "../../../../../../navigation/routes";
import {
    getBranchById,
    getBranches,
    resetViewingBranch
} from "../../../../../../redux/api/branch";
import { IStore } from "../../../../../../redux/store";
import { AsyncActionState } from "../../../../../../redux/utils/asyncAction";
import DataTable from "../../../../../_sharedComponents/DataTable";
import LoadingContainer from "../../../../../_sharedComponents/LoadingContainer";

const mapStateToProps = ({ branch }: IStore) => ({
    branches: branch.branches.branches,
    branchesPaging: branch.branches.paging,
    getBranchesStatus: branch.getBranchesStatus
});
const connector = connect(
    mapStateToProps,
    { getBranches, getBranchById, resetViewingBranch }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps, RouteComponentProps {}

export class BranchListContainer extends Component<IProps> {
    componentDidMount() {
        const { getBranches, branchesPaging } = this.props;
        getBranches({
            currentPage: branchesPaging.currentPage,
            itemsPerPage: branchesPaging.itemsPerPage
        });
    }

    navigateToPage = (
        companyId: string,
        branchId: string,
        route: IRouteDefinition
    ) => {
        this.props.resetViewingBranch();
        this.props.getBranchById(branchId);
        this.props.history.push(route.url);
    };

    handlePageBranches = (desiredPage: number) => {
        const { branchesPaging, getBranches } = this.props;
        getBranches({
            currentPage: desiredPage,
            itemsPerPage: branchesPaging.itemsPerPage
        });
    };

    render() {
        const { branches, branchesPaging, getBranchesStatus } = this.props;
        return (
            <LoadingContainer
                loading={getBranchesStatus === AsyncActionState.Pending}
            >
                <DataTable
                    showPager={true}
                    onPagerPageClick={this.handlePageBranches}
                    pagingElement={branchesPaging}
                    data={branches}
                    showLoadingSpinner={
                        getBranchesStatus === AsyncActionState.Pending
                    }
                    fields={[
                        {
                            field: "name",
                            displayName: "Branch"
                        },
                        {
                            field: "address",
                            displayName: "Address",
                            mutator: (field) =>
                                `${field.address.line1} ${field.address.postcode}`
                        },
                        {
                            field: "properties",
                            displayName: "Properties",
                            mutator: (item) => item.properties.length
                        },
                        {
                            field: "tel",
                            displayName: "Contact number"
                        }
                    ]}
                    actions={[
                        {
                            btnTitle: "Manage",
                            btnVariant: "light",
                            action: (dataItem) => {
                                this.navigateToPage(
                                    dataItem.company,
                                    dataItem._id,
                                    routes.propertyManage
                                );
                            }
                        }
                    ]}
                />
            </LoadingContainer>
        );
    }
}

export default connector(withRouter<any, any>(BranchListContainer));
